// backend_router.js

const Translator = require('bazinga-translator');
global.Translator = Translator;
// fos_js_routes.json is the output file for the fos:js-routing:dump command
require('./translations/config.js');
require('./translations/de.js');
require('./translations/fr.js');
require('./translations/it.js');
require('./translations/en.js');

module.exports = Translator;
