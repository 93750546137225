(function (t) {
// en
t.add("vich_uploader.form_label.delete_confirm", "Delete?", "messages", "en");
t.add("vich_uploader.link.download", "Download", "messages", "en");
t.add("councillor.language.de", "German", "messages", "en");
t.add("councillor.language.en", "English", "messages", "en");
t.add("councillor.language.fr", "French", "messages", "en");
t.add("councillor.language.it", "Italian", "messages", "en");
t.add("councillor.activeTrue", "incumbent", "messages", "en");
t.add("councillor.activeFalse", "former member of parliament", "messages", "en");
t.add("councillor.birthDate", "Date of birth", "messages", "en");
t.add("councillor.professions", "Job", "messages", "en");
t.add("councillor.language", "Language", "messages", "en");
t.add("councillor.votes.absent", "Absences", "messages", "en");
t.add("councillor.votes.abstain", "Abstentions", "messages", "en");
t.add("councillor.recentAffairs", "Submitted proposals by %firstName% %lastName%", "messages", "en");
t.add("affair.depositDate", "Date of submission", "messages", "en");
t.add("councillor.affairs", "Proposals", "messages", "en");
t.add("councillor.affairTypes", "Types of proposals", "messages", "en");
t.add("councillor.aTotalOfNAffairs", "Total proposals: %count%", "messages", "en");
t.add("councillor.topics", "Topics of proposals", "messages", "en");
t.add("councillor.affairs.success", "Success of proposals", "messages", "en");
t.add("councillor.absences", "Absences", "messages", "en");
t.add("councillor.absences.absentOn", "Non-attendance in  %count% ouf of %total% votes", "messages", "en");
t.add("councillor.absences.absent", "Non-attendance", "messages", "en");
t.add("councillor.abstentions", "Abstentions", "messages", "en");
t.add("councillor.abstentions.abstainedOn", "Abstentions %count% out of %total% votes", "messages", "en");
t.add("councillor.abstentions.abstained", "Abstentions", "messages", "en");
t.add("layout.source", "Source", "messages", "en");
t.add("layout.source.sentence", "parlamentary services of the Federal Assembly, Bern", "messages", "en");
t.add("layout.lastUpdate", "Last update", "messages", "en");
t.add("profile.settings.save", "Save", "messages", "en");
t.add("factions.title", "Parliamentary groups", "messages", "en");
t.add("factions.nationalCouncil.countMembers", "National Councillor|National Councillors", "messages", "en");
t.add("factions.councilOfStates.countMembers", "Councillor of State|Councillors of State", "messages", "en");
t.add("cantons.title", "Cantons", "messages", "en");
t.add("cantons.nationalCouncil.countMembers", "National Councillor|National Councillors", "messages", "en");
t.add("cantons.councilOfStates.countMembers", "Councillor of State|Councillors of State", "messages", "en");
t.add("layout.account", "User account", "messages", "en");
t.add("layout.userdashboard", "Dashboard", "messages", "en");
t.add("layout.admindashboard", "Admin Dashboard", "messages", "en");
t.add("layout.admindashboard.shortcut", "AD", "messages", "en");
t.add("parties.title", "Parties", "messages", "en");
t.add("parties.nationalCouncil.countMembers", "National Councillor|National Councillors", "messages", "en");
t.add("parties.councilOfStates.countMembers", "Councillor of State|Councillors of State", "messages", "en");
t.add("cantonal.listCouncillors.title", "Members of parliament for the canton of %canton%", "messages", "en");
t.add("affair.status", "Affair status", "messages", "en");
t.add("affair.genericAttachments", "Other appendixes", "messages", "en");
t.add("affair.genericAttachments.download", "download appendix #%number%", "messages", "en");
t.add("affair.originalSource", "Original Document", "messages", "en");
t.add("cantonal.affairs", "Cantonal affairs", "messages", "en");
t.add("cantonal.overview.affairs", "Affairs", "messages", "en");
t.add("cantonal.overview.councillors", "Members of parliament", "messages", "en");
t.add("cantonal.listAffairs.title", "affairs canton %canton%", "messages", "en");
t.add("cantons.nationalcouncil.members", "National Councillors", "messages", "en");
t.add("cantons.councilofstates.members", "Councillors of State", "messages", "en");
t.add("layout.menu.nationalcouncil", "National Council", "messages", "en");
t.add("layout.menu.nationalcouncil.votes", "National council votes", "messages", "en");
t.add("layout.menu.nationalcouncil.votes.shortcut", "A", "messages", "en");
t.add("layout.menu.nationalcouncil.programs", "Session programme NC", "messages", "en");
t.add("layout.menu.nationalcouncil.programs.shortcut", "S", "messages", "en");
t.add("layout.menu.nationalcouncil.rankings", "Rankings NC", "messages", "en");
t.add("layout.menu.nationalcouncil.rankings.shortcut", "R", "messages", "en");
t.add("layout.menu.nationalcouncil.rankings.factions", "Coalitions: Which majorities are successful?", "messages", "en");
t.add("layout.menu.nationalcouncil.rankings.councillors", "Vote ranking for members of parliament", "messages", "en");
t.add("layout.menu.nationalcouncil.rankings.votes", "Vote ranking: close votes", "messages", "en");
t.add("layout.menu.councilofstates", "Council of States", "messages", "en");
t.add("layout.menu.councilofstates.votes", "Votes Council of States", "messages", "en");
t.add("layout.menu.councilofstates.votes.shortcut", "A", "messages", "en");
t.add("layout.menu.councilofstates.programs", "Session programme CoS", "messages", "en");
t.add("layout.menu.councilofstates.programs.shortcut", "S", "messages", "en");
t.add("layout.menu.councilofstates.rankings", "Rankings CoS", "messages", "en");
t.add("layout.menu.councilofstates.rankings.shortcut", "R", "messages", "en");
t.add("layout.menu.councilstates.rankings.councillors", "Vote ranking for members of parliament", "messages", "en");
t.add("layout.menu.councilstates.rankings.votes", "Vote ranking: close votes", "messages", "en");
t.add("layout.menu.councillors", "Members of parliament", "messages", "en");
t.add("layout.menu.cantonalAffairs", "Cantons", "messages", "en");
t.add("layout.search.query", "Search term", "messages", "en");
t.add("layout.search.submit", "Search", "messages", "en");
t.add("parties.nationalcouncil.members", "Party members National Council", "messages", "en");
t.add("parties.councilofstates.members", "Party members Council of State", "messages", "en");
t.add("factions.nationalcouncil.members", "Members of parliamentary group in National Council", "messages", "en");
t.add("factions.councilofstates.members", "Members of parliamentary group in Council of States", "messages", "en");
t.add("vote.decision.yes", "Yes", "messages", "en");
t.add("vote.decision.no", "No", "messages", "en");
t.add("sessions.nocouncil.title", "Votes", "messages", "en");
t.add("form.session.label", "%title% (from %from% to %to%)", "messages", "en");
t.add("vote.session_stats", "Session statistics", "messages", "en");
t.add("vote.new_councillors", "newly sworn in members of parliament", "messages", "en");
t.add("session.dayFilter.all", "all", "messages", "en");
t.add("session.novotes", "This sessions has no votes in this council.", "messages", "en");
t.add("session.nohistoryEvents", "no events", "messages", "en");
t.add("form.firstName", "First name", "messages", "en");
t.add("form.lastName", "Last name", "messages", "en");
t.add("form.company", "Corporation", "messages", "en");
t.add("layout.search.contentType.national_affair", "national search", "messages", "en");
t.add("layout.search.contentType.cantonal_affair", "Cantonal affairs", "messages", "en");
t.add("layout.search.type", "Search national \/ cantonal", "messages", "en");
t.add("profile.edit.firstName", "First name", "messages", "en");
t.add("profile.edit.lastName", "Last name", "messages", "en");
t.add("profile.edit.pictureFile", "Profile picture", "messages", "en");
t.add("profile.settings.notificationPreference.none", "None", "messages", "en");
t.add("profile.settings.notificationPreference.keywords", "Keywords", "messages", "en");
t.add("profile.settings.notificationPreference.all", "all notifications", "messages", "en");
t.add("profile.settings.notificationPreference.yes", "Yes", "messages", "en");
t.add("profile.settings.notificationPreference.no", "No", "messages", "en");
t.add("ranking.narrowvotes", "narrow votes", "messages", "en");
t.add("ranking.absent", "Non-attendance at votes", "messages", "en");
t.add("ranking.coalitions", "Coalitions: Which majorities are successful?", "messages", "en");
t.add("ranking.voteagainst", "Against own parliamentary group (dissenter ranking)", "messages", "en");
t.add("ranking.age", "Year of birth (age)", "messages", "en");
t.add("ranking.membership", "Incumbency (current council)", "messages", "en");
t.add("ranking.abstain", "Abstentions from votes", "messages", "en");
t.add("vote.decision.eh", "Abstained", "messages", "en");
t.add("vote.decision.nt", "Absent", "messages", "en");
t.add("vote.decision.es", "Excused", "messages", "en");
t.add("vote.decision.p", "President", "messages", "en");
t.add("global.dates.format", "d.m.Y", "messages", "en");
t.add("global.dates.dayMonthFormat", "d.m.", "messages", "en");
t.add("global.dates.dateTimeFormat", "d.m.Y H:i", "messages", "en");
t.add("councillor.gender.m", "Men", "messages", "en");
t.add("councillor.gender.f", "Women", "messages", "en");
t.add("layout.login.error.duplicated", "Another device has logged in with your user name and password. To log in again\n                    please enter your login details below. Please note that the other device\n                    will be logged out automatically.", "messages", "en");
t.add("session.stats.totalVotes", "Total votes", "messages", "en");
t.add("session.stats.finalGovernmentVotes", "Final votes", "messages", "en");
t.add("councillor.affairSuccess.noVotes", "Not yet treated", "messages", "en");
t.add("councillor.affairSuccess.positiveVotes", "Successful", "messages", "en");
t.add("councillor.affairSuccess.negativeVotes", "No success", "messages", "en");
t.add("profile.settings.save.success", "Saved successfully", "messages", "en");
t.add("affair.submittedBy", "Submitted by %name% on the %date%", "messages", "en");
t.add("affair.description", "Introduced text", "messages", "en");
t.add("affair.reason", "Justification", "messages", "en");
t.add("affair.nationalCouncilDebate.showmore", "Show more", "messages", "en");
t.add("affair.noVotes", "No votes available.", "messages", "en");
t.add("affair.councilOfStatesVote", "Vote Council of States", "messages", "en");
t.add("affair.nationalCouncilVote", "Vote National Council", "messages", "en");
t.add("affair.nationalCouncilDebate", "Debate National Council", "messages", "en");
t.add("affair.nationalCouncilDebate.showless", "show less", "messages", "en");
t.add("affairs.nationalCouncilDebate.otherAffairs", "Other affairs", "messages", "en");
t.add("affair.nationalCouncilDebate.speeches", "Speeches", "messages", "en");
t.add("affair.nationalCouncilDebate.speechesByCouncillor", "Speeches by members of parliament", "messages", "en");
t.add("vote.date", "Date of vote", "messages", "en");
t.add("vote.submission", "Template", "messages", "en");
t.add("vote.division", "Object", "messages", "en");
t.add("vote.meaning.yes", "Meaning yes", "messages", "en");
t.add("vote.meaning.no", "Meaning no", "messages", "en");
t.add("affair.filters", "Filter", "messages", "en");
t.add("affair.filterby", "Filter by...", "messages", "en");
t.add("affair.filter.all", "All results", "messages", "en");
t.add("affair.filter.factions", "Parliamentary groups", "messages", "en");
t.add("affair.filter.cantons", "Cantons", "messages", "en");
t.add("affair.filter.dissenters", "against own parliamentary group (dissenters)", "messages", "en");
t.add("affair.filter.language", "Language", "messages", "en");
t.add("affair.filter.gender", "Sex", "messages", "en");
t.add("affair.results.all", "all results", "messages", "en");
t.add("affair.results.byfactions", "Results by parliamentary group", "messages", "en");
t.add("affair.results.faction", "Parliamentary group", "messages", "en");
t.add("vote.decision.abstained", "Abst", "messages", "en");
t.add("vote.decision.abs_exc", "Abst \/ DNP", "messages", "en");
t.add("vote.decision.total", "Total", "messages", "en");
t.add("vote.allCouncillors", "all", "messages", "en");
t.add("affair.results.bycantons", "Results by canton", "messages", "en");
t.add("affair.results.canton", "Canton", "messages", "en");
t.add("affair.results.bydissenters", "Results by dissenters", "messages", "en");
t.add("affair.results.bylanguage", "Results by language", "messages", "en");
t.add("affair.results.language", "Language", "messages", "en");
t.add("affair.results.bygender", "Results by sex", "messages", "en");
t.add("affair.results.gender", "Sex", "messages", "en");
t.add("global.filter.placeholder", "filter output...", "messages", "en");
t.add("affair.councillor.name", "Name", "messages", "en");
t.add("affair.councillor.party", "Party", "messages", "en");
t.add("affair.councillor.vote", "Voice", "messages", "en");
t.add("affair.councillor.canton", "Canton", "messages", "en");
t.add("vote.compact.yes", "Yes", "messages", "en");
t.add("vote.compact.no", "No", "messages", "en");
t.add("vote.compact.abstained", "Abstained", "messages", "en");
t.add("vote.compact.notPartecipating", "not attended", "messages", "en");
t.add("affair.results.detailedView", "Detail view", "messages", "en");
t.add("affair.results.compactView", "compact view", "messages", "en");
t.add("rankings.stats", "%affairs% affairs, %votes% votes", "messages", "en");
t.add("ranking.vacantSeat", "vacant seat of", "messages", "en");
t.add("ranking.membership.fromto", "Member of parliament from %from% to %to%", "messages", "en");
t.add("ranking.membership.from", "Member of parliament since %from%", "messages", "en");
t.add("ranking.membership.yearAndDays", "%years% years and %days% days", "messages", "en");
t.add("rankings.title", "Vote rankings %council%", "messages", "en");
t.add("form.submit", "Show", "messages", "en");
t.add("ranking.abstain.in", "Abstained from %hits% out of %total% votes", "messages", "en");
t.add("ranking.absent.percentage", "Non-attendance", "messages", "en");
t.add("ranking.absent.in", "Non-attendance: %hits% ouf of %total% votes", "messages", "en");
t.add("layout.loader.error", "Error loading data", "messages", "en");
t.add("ranking.age.yearAndDays", "%years% years and %days% days", "messages", "en");
t.add("ranking.narrowvotes.difference", "Difference", "messages", "en");
t.add("ranking.voteagainst.in", "%hits% out of %total% against own parliamentary group", "messages", "en");
t.add("ranking.voteagainst.percentage", "%hits%% against", "messages", "en");
t.add("ranking.filter.submit", "filter output", "messages", "en");
t.add("profile.dashboard", "Dashboard", "messages", "en");
t.add("profile.dashboard.sessions", "Votes", "messages", "en");
t.add("profile.dashboard.parties", "Parties: Members of parliament", "messages", "en");
t.add("profile.dashboard.rankings.councillors", "Evaluation: Members of parliament", "messages", "en");
t.add("profile.dashboard.factions", "Parliamentary groups: Members of parliament", "messages", "en");
t.add("profile.dashboard.rankings.factions", "Evaluation: Parliamentary groups", "messages", "en");
t.add("profile.dashboard.cantons", "Cantons: Members of parliament", "messages", "en");
t.add("profile.dashboard.recentVotes", "Latest votes NC", "messages", "en");
t.add("rankings.form.onlyfinalvotes", "Which ballots?", "messages", "en");
t.add("rankings.form.filter.on", "On", "messages", "en");
t.add("rankings.form.filter.off", "Off", "messages", "en");
t.add("rankings.form.legislativeperiod", "Legislative period", "messages", "en");
t.add("rankings.form.session", "Session", "messages", "en");
t.add("rankings.filter.faction.label", "Filter by parliamentary group", "messages", "en");
t.add("rankings.filter.affairtype.label", "Filter by type of affair", "messages", "en");
t.add("rankings.filter.topic.label", "Topic", "messages", "en");
t.add("rankings.form.ranking", "Ranking", "messages", "en");
t.add("profile.show.username", "User name", "messages", "en");
t.add("profile.show.email", "Email", "messages", "en");
t.add("profile.show.firstName", "First name", "messages", "en");
t.add("profile.show.lastName", "Last name", "messages", "en");
t.add("profile.show.edit", "edit", "messages", "en");
t.add("layout.login", "Login", "messages", "en");
t.add("security.forgotpassword", "Forgotten your password?", "messages", "en");
t.add("profile.settings.keywords", "Keywords", "messages", "en");
t.add("results.results", "Results", "messages", "en");
t.add("results.noResultsFound", "no results found", "messages", "en");
t.add("profile.settings.navigation.profile", "Profile", "messages", "en");
t.add("profile.settings.navigation.password", "Password", "messages", "en");
t.add("profile.settings.navigation.notificationPreferences", "Search terms", "messages", "en");
t.add("profile.settings.defaultNotificationPreference", "Standard setting", "messages", "en");
t.add("profile.settings.nationalNotificationPreferences", "Differing national settings", "messages", "en");
t.add("profile.settings.cantonalNotificationPreferences", "Differing cantonal settings", "messages", "en");
t.add("layout.menu.councilstates.rankings.factions", "Coalitions: Which majorities are successful?", "messages", "en");
t.add("cantonal.listCouncillors.deputies.title", "Deputy members of parliament", "messages", "en");
t.add("councillor.role.deputy", "Deputy", "messages", "en");
t.add("layout.search.contentType.all", "Affairs and members of parliament", "messages", "en");
t.add("layout.search.contentType.affair", "affairs only", "messages", "en");
t.add("layout.search.contentType.councillor", "members of parliament only", "messages", "en");
t.add("layout.search.contentType", "search for", "messages", "en");
t.add("layout.search.affairTypeNational", "Type of affair (national)", "messages", "en");
t.add("layout.search.affairTypeCantonal", "Type of affair (cantonal)", "messages", "en");
t.add("layout.search.canton", "Canton", "messages", "en");
t.add("layout.search.affairTypeNational.all", "all", "messages", "en");
t.add("layout.search.affairTypeCantonal.all", "all", "messages", "en");
t.add("layout.search.canton.all", "all cantons", "messages", "en");
t.add("profile.settings.notificationPreference.default", "Standard setting", "messages", "en");
t.add("ranking.narrowvotesguilty", "lost narrow votes", "messages", "en");
t.add("rankings.filter.narrowVotesGuilty.limit.label", "What are narrow votes? Difference in votes:", "messages", "en");
t.add("ranking.narrowvotesguilty.in", "co-resposible in %hits% out of %total% votes", "messages", "en");
t.add("ranking.narrowvotesguilty.percentage", "%hits%% against", "messages", "en");
t.add("ranking.narrowvotesguilty.abstain", "abstained", "messages", "en");
t.add("ranking.narrowvotesguilty.absent", "Absent (non-participation)", "messages", "en");
t.add("ranking.narrowvotesguilty.against", "Against own parliamentary group", "messages", "en");
t.add("ranking.abstain.percentage", "Abstained", "messages", "en");
t.add("profile.settings.baseSettings", "General settings", "messages", "en");
t.add("global.filter.noResults", "no events found", "messages", "en");
t.add("layout.account.shortcut", "A", "messages", "en");
t.add("profile.show.edit.shortcut", "FCh", "messages", "en");
t.add("profile.settings.edit.shortcut", "S", "messages", "en");
t.add("profile.settings.edit", "Settings", "messages", "en");
t.add("layout.logout.shortcut", "L", "messages", "en");
t.add("layout.search", "Search term", "messages", "en");
t.add("layout.menu.councillors.byCanton.shortcut", "C", "messages", "en");
t.add("layout.menu.councillors.byCanton", "by cantons", "messages", "en");
t.add("layout.menu.councillors.byFaction.shortcut", "C", "messages", "en");
t.add("layout.menu.councillors.byFaction", "by parl. Groups", "messages", "en");
t.add("layout.menu.councillors.byParty.shortcut", "P", "messages", "en");
t.add("layout.menu.councillors.byParty", "by parties", "messages", "en");
t.add("rankings.stats.description", "The amount of votes corresponds to the vote difference chosen below.", "messages", "en");
t.add("sessionlist.session.dates", "from %from% to %to%", "messages", "en");
t.add("sessionlist.session.votes", "Votes", "messages", "en");
t.add("sessionlist.session.historyEvents", "Affairs", "messages", "en");
t.add("layout.search.orderBy.none", "Best result", "messages", "en");
t.add("layout.search.orderBy.deposit_date", "Date of submission", "messages", "en");
t.add("layout.search.orderBy.title", "Title", "messages", "en");
t.add("layout.search.orderBy.type", "Type", "messages", "en");
t.add("layout.search.orderBy.first_name", "First name", "messages", "en");
t.add("layout.search.orderBy.last_name", "Last name", "messages", "en");
t.add("layout.search.orderDirection.ascending", "ascending", "messages", "en");
t.add("layout.search.orderDirection.descending", "descending", "messages", "en");
t.add("layout.search.orderBy", "Order by", "messages", "en");
t.add("layout.search.orderDirection", "Sorting order", "messages", "en");
t.add("councillor.affairSuccess.unknownVotes", "Will not be voted on", "messages", "en");
t.add("rankings.cantonal.numberOfAffairs.title", "Number of affairs by canton", "messages", "en");
t.add("layout.menu.cantonalRankings", "Cantonal rankings", "messages", "en");
t.add("layout.politicalcalendar", "Calendar", "messages", "en");
t.add("session.stats.finalPersonalVotes", "Proposals", "messages", "en");
t.add("affair.councilStatesDebate.debate", "Debate", "messages", "en");
t.add("affair.councilStatesDebate.speechesByCouncillor", "Votes members of parliament", "messages", "en");
t.add("affair.councilStatesDebate.speeches", "Votes", "messages", "en");
t.add("affair.submittedByOn", "Submitted by %councillors% on the %date%", "messages", "en");
t.add("rankings.filter.orderDirection.label", "Order", "messages", "en");
t.add("rankings.filter.orderDirection.descending", "descending", "messages", "en");
t.add("rankings.filter.orderDirection.ascending", "ascending", "messages", "en");
t.add("rankings.form.filter.all", "all", "messages", "en");
t.add("rankings.form.filter.finalGovernment", "final votes only", "messages", "en");
t.add("rankings.form.filter.finalPersonal", "proposals only", "messages", "en");
t.add("rankings.form.filter.finalAll", "final votes and proposals only", "messages", "en");
t.add("affairGroups.assign.modal", "assign to group", "messages", "en");
t.add("affairGroups.newGroup.modal", "Create new group", "messages", "en");
t.add("profile.affairGroups", "Groups", "messages", "en");
t.add("layout.affair_groups", "Groups", "messages", "en");
t.add("layout.affair_groups.shortcut", "G", "messages", "en");
t.add("layout.affairs_overview", "Affairs", "messages", "en");
t.add("layout.menu.nationalcouncil.consultations", "Consultations", "messages", "en");
t.add("affairGroups.newGroup.success", "New group successfully created", "messages", "en");
t.add("affairGroups.assign.select", "assign group", "messages", "en");
t.add("affairGroups.assign.success", "Group assignments successfully updated.", "messages", "en");
t.add("affairGroups.assign.error", "Group cannot be assigned", "messages", "en");
t.add("affairGroups.assign.placeholder", "-", "messages", "en");
t.add("affairGroups.assign.label", "assign group", "messages", "en");
t.add("affairGroups.newGroup.title.label", "Name", "messages", "en");
t.add("affairGroups.newGroup.description.label", "Description", "messages", "en");
t.add("affairGroups.new.title", "Create new group", "messages", "en");
t.add("affairGroups.new.save", "Save new group", "messages", "en");
t.add("affairGroups.affair.title", "Affair title", "messages", "en");
t.add("affairGroups.assign.title", "assign affair to a group", "messages", "en");
t.add("affairGroups.assign.save", "save assignments", "messages", "en");
t.add("affairGroups.newGroup.parent.placeholder", "(no parent group)", "messages", "en");
t.add("affairGroups.newGroup.parent.label", "Parent group", "messages", "en");
t.add("affairGroups.affair.existingGroups", "existing groups", "messages", "en");
t.add("profile.settings.nationalNotificationPreferences.affairs", "Affairs", "messages", "en");
t.add("profile.settings.nationalNotificationPreferences.nationalCouncilVotes", "Votes National Council", "messages", "en");
t.add("profile.settings.nationalNotificationPreferences.councilStatesVotes", "Votes Council of States", "messages", "en");
t.add("affairGroups.edit.title", "Edit group", "messages", "en");
t.add("affairGroups.edit.save", "Save", "messages", "en");
t.add("affairGroups.editGroup.tooltip", "Edit group", "messages", "en");
t.add("affairGroups.assign.noGroups", "assigned to no groups", "messages", "en");
t.add("affairGroups.editGroup.success", "Group successfully saved", "messages", "en");
t.add("affair.customEvents.add.button", "Add memo \/ event", "messages", "en");
t.add("affair.customEvents.edit.title", "Edit event", "messages", "en");
t.add("affair.customEvent.button.submit", "Save", "messages", "en");
t.add("affair.customEvents.add.title", "Add event", "messages", "en");
t.add("affair.customEvents.save.success", "Event successfully saved", "messages", "en");
t.add("affairs.customEvents.fields.note.title", "Title", "messages", "en");
t.add("affairs.customEvents.fields.note.abstract", "Summary", "messages", "en");
t.add("affairs.customEvents.fields.note.outputInReports", "Show in documents (exports, reports, invitations, etc.)", "messages", "en");
t.add("affairs.customEvents.fields.note.text", "Text", "messages", "en");
t.add("affairs.customEvents.fields.vote.caption", "Title", "messages", "en");
t.add("affairs.customEvents.fields.vote.description", "Description", "messages", "en");
t.add("affairs.customEvents.fields.vote.yes", "Yes", "messages", "en");
t.add("affairs.customEvents.fields.vote.no", "No", "messages", "en");
t.add("affairs.customEvents.fields.date", "Date", "messages", "en");
t.add("affairs.customEvents.success", "Saved successfully", "messages", "en");
t.add("affair.councilOfStatesVote.tooltip.caption", "Vote Council of States", "messages", "en");
t.add("affair.nationalCouncilVote.tooltip.caption", "Vote National Council", "messages", "en");
t.add("affair.nationalCouncilDebate.tooltip.caption", "Debate National Council", "messages", "en");
t.add("affair.customNote.tooltip.caption", "Memo", "messages", "en");
t.add("affair.customInternalVote.tooltip.caption", "Internal vote", "messages", "en");
t.add("affairs.customEvents.note", "Memo", "messages", "en");
t.add("affairs.customEvents.internalvote", "Internal vote", "messages", "en");
t.add("cantonal.historyevent.deposit.tooltip.caption", "Date of introduction", "messages", "en");
t.add("affair.authorCouncillor", "Submitted by", "messages", "en");
t.add("affairGroup.overview.noContent.title", "no affairs", "messages", "en");
t.add("affairGroup.overview.nationalEvents.title", "National affairs", "messages", "en");
t.add("affairGroup.overview.cantonalEvents.title", "Cantonal affairs", "messages", "en");
t.add("affairGroup.overview.customAffairs.title", "Internal affairs", "messages", "en");
t.add("affairGroup.overview.children", "Subgroups", "messages", "en");
t.add("affairGroup.description.empty", "No description", "messages", "en");
t.add("affairGroups.overviewLink.tooltip", "open group overview", "messages", "en");
t.add("author.party.label", "Party", "messages", "en");
t.add("author.faction.label", "Parliamentary group", "messages", "en");
t.add("author.commission.label", "Commission", "messages", "en");
t.add("author.misc.label", "Author", "messages", "en");
t.add("councillor.affairSuccess.partialPositiveVotes", "Successful in one council", "messages", "en");
t.add("affair.overview.nationalCouncilVote.title", "Vote National Council", "messages", "en");
t.add("affair.overview.internalVoteEvent.title", "Internal vote", "messages", "en");
t.add("affair.overview.noteEvent.title", "Memo", "messages", "en");
t.add("affair.overview.nationalCouncilDebateEvent.title", "Debate", "messages", "en");
t.add("affair.overview.councilStatesVote.title", "Vote Council of States", "messages", "en");
t.add("affair.overview.cantonal.depositEvent.title", "Introduced", "messages", "en");
t.add("affair.overview.events.draft", "Draft details", "messages", "en");
t.add("ranking.councillorscompare", "comparison of members of parliament", "messages", "en");
t.add("ranking.councillorscompare.noVotes", "no differences \/ votes", "messages", "en");
t.add("layout.menu.nationalcouncil.rankings.councillorscompare", "comparison of National Councillors", "messages", "en");
t.add("layout.menu.councilstates.rankings.councillorscompare", "comparison of Councillors of State", "messages", "en");
t.add("rankings.form.councillorsCompare.councillor1", "Members of parliament 1", "messages", "en");
t.add("rankings.form.councillorsCompare.councillor2", "Members of parliament 2", "messages", "en");
t.add("ranking.councillorscompare.topicsStats.title", "Topics of votes with different opionion", "messages", "en");
t.add("ranking.councillorscompare.votesAndAffairs", "%affairs% affairs, %votes% votes", "messages", "en");
t.add("ranking.councillorscompare.overview.title", "comparison of members of parliament", "messages", "en");
t.add("ranking.councillorscompare.description", "Votes in which the two members of parliament voted differently: The graph shows who voted yes, no or abstained in these votes.", "messages", "en");
t.add("ranking.councillorscompare.howmany.yes", "%count% Yes", "messages", "en");
t.add("ranking.councillorscompare.howmany.no", "%count% No", "messages", "en");
t.add("ranking.councillorscompare.howmany.eh", "%count% contained", "messages", "en");
t.add("ranking.councillorscompare.age", "Age", "messages", "en");
t.add("ranking.councillorscompare.yearsInCouncil", "Incumbency in the current council", "messages", "en");
t.add("ranking.councillorscompare.absentRate", "Absences current legislative period", "messages", "en");
t.add("ranking.councillorscompare.abstainRate", "Abstentions current legislative period", "messages", "en");
t.add("ranking.councillorscompare.affairsCount", "Number of proposals current legislative period", "messages", "en");
t.add("national.affair.overview.title", "National affair", "messages", "en");
t.add("sidebar.toggle.collapse", "Collapse", "messages", "en");
t.add("sidebar.toggle.expand", "Expand", "messages", "en");
t.add("cantonal.affair.overview.title", "Cantonal affair", "messages", "en");
t.add("councillor.faction", "Parliamentary group", "messages", "en");
t.add("councillor.party", "Party", "messages", "en");
t.add("councillor.commissionMemberships.current", "Current commission memberships", "messages", "en");
t.add("councillor.commissionMemberships.past", "past memberships commissions", "messages", "en");
t.add("ranking.cantonal.councillorsAffairs", "Cantonal affairs by members of parliament", "messages", "en");
t.add("rankings.cantonal.numberOfAffairs.stats", "statistics about amount of affairs", "messages", "en");
t.add("rankings.cantonal.filter.canton.label", "Filter by canton", "messages", "en");
t.add("rankings.filter.isEffectiveAmount.label", "compare with", "messages", "en");
t.add("rankings.filter.isEffectiveAmount.yes", "absolute number of affairs \/ proposals", "messages", "en");
t.add("rankings.filter.isEffectiveAmount.no", "in relation to amount of cantonal affairs", "messages", "en");
t.add("rankings.filter.includeGovernmentAuthors.label", "Consider affairs by the government", "messages", "en");
t.add("rankings.filter.includeGovernmentAuthors.yes", "Yes", "messages", "en");
t.add("rankings.filter.includeGovernmentAuthors.no", "No", "messages", "en");
t.add("ranking.cantonal.numberOfAffairs.authored.effective", "affairs \/ proposals total", "messages", "en");
t.add("ranking.cantonal.numberOfAffairs.authored.percentage", "Share of", "messages", "en");
t.add("layout.menu.cantonal.rankings.affairsPerCanton", "per canton", "messages", "en");
t.add("cantonal.rankings.title", "Cantonal rankings", "messages", "en");
t.add("layout.menu.cantonal.rankings.affairsPerCouncillor", "Members of parliament", "messages", "en");
t.add("cantonal.overview.commissions", "Commissions", "messages", "en");
t.add("cantonal.listCommissions.title", "Commission %canton%", "messages", "en");
t.add("cantonal.commission.title", "Author %commission%", "messages", "en");
t.add("cantonal.commission.noAffairs", "no affairs available from this commission", "messages", "en");
t.add("cantonal.commission.affairs.title", "Affairs", "messages", "en");
t.add("cantonal.commission.councillors.title", "Members of parliament", "messages", "en");
t.add("cantonal.listCommissions.councillors.title", "Members of parliament", "messages", "en");
t.add("cantonal.listCommissions.parties.title", "Parties", "messages", "en");
t.add("cantonal.listCommissions.factions.title", "Parliamentary groups", "messages", "en");
t.add("cantonal.overview.miscauthors", "Various authors", "messages", "en");
t.add("cantonal.listMiscAuthors.title", "Multiple authors canton %canton%", "messages", "en");
t.add("cantonal.miscauthor.affairs.title", "Affairs", "messages", "en");
t.add("cantonal.miscAuthors.noAffairs", "no affairs available from this author", "messages", "en");
t.add("cantonal.miscauthors.title", "Affairs %miscauthor%", "messages", "en");
t.add("cantonal.overview.factions", "Parliamentary groups", "messages", "en");
t.add("cantonal.factions.noFactions", "No parliamentary groups registered for this canton", "messages", "en");
t.add("cantonal.listFactions.commissionMemberships.title", "Memberships commissions", "messages", "en");
t.add("cantonal.faction.noAffairs", "No affairs for this parliamentary group", "messages", "en");
t.add("cantonal.faction.commissions.title", "Memberships commissions", "messages", "en");
t.add("cantonal.faction.parties.title", "Parties", "messages", "en");
t.add("cantonal.listFactions.title", "Parliamentary groups %canton%", "messages", "en");
t.add("cantonal.faction.title", "%faction%", "messages", "en");
t.add("cantonal.overview.parties", "Parties", "messages", "en");
t.add("cantonal.parties.noParties", "no parties registered for this canton", "messages", "en");
t.add("cantonal.parties.noAffairs", "no affairs from this party", "messages", "en");
t.add("cantonal.parties.commissions.title", "Membership commission", "messages", "en");
t.add("cantonal.party.factionMembership.title", "Membership parliamentary group", "messages", "en");
t.add("cantonal.party.affairs.title", "Affairs", "messages", "en");
t.add("cantonal.party.title", "%party%", "messages", "en");
t.add("cantonal.listParties.title", "Parties canton %canton%", "messages", "en");
t.add("groups.tree.search", "Search...", "messages", "en");
t.add("groups.tree.search.next", "next", "messages", "en");
t.add("groups.tree.search.prev", "previous", "messages", "en");
t.add("groups.tree.search.collapse", "Collapse all", "messages", "en");
t.add("groups.tree.search.expand", "Expand all", "messages", "en");
t.add("groups.tree.group.checkGroup", "Group view", "messages", "en");
t.add("groups.tree.group.deleteGroup", "Delete group", "messages", "en");
t.add("groups.tree.group.deleteAffair", "Remove affair from group", "messages", "en");
t.add("groups.tree.group.checkAffair", "to affair", "messages", "en");
t.add("groups.tree.group.editGroup", "Edit group", "messages", "en");
t.add("groups.tree.sort", "Sorting", "messages", "en");
t.add("groups.tree.sort.alphabetically", "alphabetically", "messages", "en");
t.add("groups.tree.sort.custom", "custom", "messages", "en");
t.add("groups.tree.sort.default", "Standard", "messages", "en");
t.add("groups.tree.sort.byDate", "Date", "messages", "en");
t.add("affairGroup.export.header.id", "ID", "messages", "en");
t.add("affairGroup.export.header.type", "Type", "messages", "en");
t.add("affairGroup.export.header.title", "Title", "messages", "en");
t.add("affairGroup.export.header.description", "Introduced text", "messages", "en");
t.add("affairGroup.export.header.status", "Status", "messages", "en");
t.add("affairGroup.export.header.link", "Source", "messages", "en");
t.add("groups.tree.group.exportGroup", "Excel export", "messages", "en");
t.add("affair.eventAttachments", "appendixes", "messages", "en");
t.add("affair.eventAttachments.download", "download appendix", "messages", "en");
t.add("groups.form.title.cannotBeEmpty", "Title cannot be empty", "messages", "en");
t.add("affair.internalNote.title", "Description", "messages", "en");
t.add("affair.internalNote", "Description", "messages", "en");
t.add("affair.affairOverview.groups", "Groups", "messages", "en");
t.add("affair.affairOverview.groups.empty", "assigned to no groups", "messages", "en");
t.add("affair.affairOverview.meetings.empty", "assigned to no meetings", "messages", "en");
t.add("affair.internalNote.error", "erroneous description", "messages", "en");
t.add("rankings.filter.affairgroup.label", "Group", "messages", "en");
t.add("affairGroups.editGroup.save", "saved", "messages", "en");
t.add("councillor.info.notTreated", "Not yet treated:", "messages", "en");
t.add("councillor.info.notTreated.text", "Proposal submitted", "messages", "en");
t.add("councillor.info.successfully", "Successful:", "messages", "en");
t.add("councillor.info.successfully.text", "The proposal was passed by the National Council and the Council of States", "messages", "en");
t.add("councillor.info.successfullyCouncil", "Successful in one council:", "messages", "en");
t.add("councillor.info.successfullyCouncil.text", "The proposal was passed by one council. The vote in the second council is still out.", "messages", "en");
t.add("councillor.info.noSuccess", "No success:", "messages", "en");
t.add("councillor.info.noSuccess.text", "The proposal was voted down by at least one council", "messages", "en");
t.add("councillor.info.noVote", "Will not be voted on:", "messages", "en");
t.add("councillor.info.noVote.text", "Interpellations or proposals during the question time get listed here.", "messages", "en");
t.add("affair.internalNote.save", "Save", "messages", "en");
t.add("national.affair.originalSource", "Source: Curia Vista", "messages", "en");
t.add("affairs.followingHaveBeenUpdated", "The following affairs have been updated", "messages", "en");
t.add("national.affairs.followingHaveBeenUpdated", "The following national affairs have been updated", "messages", "en");
t.add("affair.customSpeaker.cantonalCouncillor.label", "Speaker", "messages", "en");
t.add("affair.customSpeaker.nationalCouncilCouncillor.label", "Speaker National Council", "messages", "en");
t.add("affair.customSpeaker.councilStatesCouncillor.label", "Speaker Council of States", "messages", "en");
t.add("affair.customSpeaker.button.submit", "Save", "messages", "en");
t.add("affair.customSpeaker.save.success", "Speaker successfully saved", "messages", "en");
t.add("notification.subscription.button.affair.tooltip", "subscribe to \/ unsubscribe from affair", "messages", "en");
t.add("customMeetings.newMeeting.success", "Saved successfully", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.modal", "Create new agenda item", "messages", "en");
t.add("customMeetings.assignAgendaPoint.modal", "Add to agenda item", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.save", "Save", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.success", "Saved successfully", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.noMeetings", "No meeting", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.error", "Error while saving", "messages", "en");
t.add("customMeetings.editMeeting.title", "Edit meeting", "messages", "en");
t.add("customMeetings.editMeeting.save", "Save", "messages", "en");
t.add("customMeetings.editMeeting.success", "Saved successfully", "messages", "en");
t.add("customMeetings.editMeetingAgendaPoint.success", "Saved successfully", "messages", "en");
t.add("customMeetings.assignAgendaPoint.title", "Add to agenda item", "messages", "en");
t.add("customMeetings.assignAgendaPoint.placeholder", "choose agenda item", "messages", "en");
t.add("customMeetings.assignAgendaPoint.label", "Agenda item", "messages", "en");
t.add("customMeetings.assignAgendaPoint.error", "Error while adding", "messages", "en");
t.add("customMeetings.assignAgendaPoint.success", "Meeting assignments successfully updated.", "messages", "en");
t.add("customMeetings.assignAgendaPoint.save", "Save", "messages", "en");
t.add("customMeetings.newMeeting.date.label", "Date", "messages", "en");
t.add("customMeetings.newMeeting.modal", "Create new meeting", "messages", "en");
t.add("customMeetings.newMeeting.title", "Create new meeting", "messages", "en");
t.add("customMeetings.newMeeting.title.label", "Title", "messages", "en");
t.add("customMeetings.newMeeting.subject.label", "Subject", "messages", "en");
t.add("customMeetings.newMeeting.description.label", "Description", "messages", "en");
t.add("customMeetings.newMeeting.location.label", "Location", "messages", "en");
t.add("customMeetings.newMeeting.president.label", "Chair", "messages", "en");
t.add("customMeetings.newMeeting.protocol.label", "Taking of minutes", "messages", "en");
t.add("customMeetings.newMeeting.save", "Save", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.title", "Create new agenda item", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.title.label", "Create new agenda item", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.description.label", "Description", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.parent.placeholder", "choose meeting", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.parent.label", "Meeting", "messages", "en");
t.add("customMeetings.tree.search.expand", "Expand all", "messages", "en");
t.add("customMeetings.tree.search.collapse", "Collapse all", "messages", "en");
t.add("customMeetings.tree.search", "Search", "messages", "en");
t.add("customMeetings.tree.search.prev", "previous", "messages", "en");
t.add("customMeetings.tree.search.next", "next", "messages", "en");
t.add("customMeetings.form.title.cannotBeEmpty", "Please enter a value", "messages", "en");
t.add("customMeetings.tree.customMeeting.createInvitationPDF", "Generate invitation (PDF)", "messages", "en");
t.add("customMeetings.tree.customMeeting.createProtocolPDF", "create minutes (PDF)", "messages", "en");
t.add("customMeetings.tree.sort.default", "Standard", "messages", "en");
t.add("customMeetings.tree.sort.alphabetically", "alphabetically", "messages", "en");
t.add("customMeetings.tree.sort.byDate", "Date", "messages", "en");
t.add("customMeetings.tree.sort.custom", "custom", "messages", "en");
t.add("customMeetings.tree.customMeeting.checkAffair", "to affair", "messages", "en");
t.add("customMeetings.editCustomMeeting.tooltip", "edit", "messages", "en");
t.add("customMeetings.editCustomMeeting.save", "Save", "messages", "en");
t.add("customMeetings.tree.customMeeting.editCustomMeeting", "Edit meeting", "messages", "en");
t.add("customMeetings.tree.customMeeting.deleteCustomMeeting", "Delete meeting", "messages", "en");
t.add("customMeetings.newAgendaPoint.tooltip", "Add agenda Item", "messages", "en");
t.add("customMeetings.tree.customMeeting.newAgendaPoint", "Add agenda item", "messages", "en");
t.add("customMeetings.tree.customMeeting.deleteAffair", "Remove affair", "messages", "en");
t.add("customMeetings.editCustomMeetingAgendaPoint.tooltip", "edit agenda item", "messages", "en");
t.add("customMeetings.tree.customMeeting.editCustomMeetingAgendaPoint", "edit agenda item", "messages", "en");
t.add("customMeetings.tree.customMeeting.deleteCustomMeetingAgendaPoint", "Delete agenda item", "messages", "en");
t.add("customMeetings.deleteCustomMeeting.message", "Delete meeting?", "messages", "en");
t.add("customMeetings.deleteCustomMeeting.confirm", "Confirm", "messages", "en");
t.add("customMeetings.deleteCustomMeeting.cancel", "Abort", "messages", "en");
t.add("customMeetings.deleteCustomMeeting.success", "Deleted successfully", "messages", "en");
t.add("customMeetings.deleteCustomMeeting.error", "Error while deleting", "messages", "en");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.message", "Delete agenda item?", "messages", "en");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.confirm", "Confirm", "messages", "en");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.cancel", "Abort", "messages", "en");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.error", "Error while deleting. Please contact our support team.", "messages", "en");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.success", "Deleted successfully", "messages", "en");
t.add("customMeetings.error.message", "Error", "messages", "en");
t.add("layout.custom_meetings", "Meetings", "messages", "en");
t.add("layout.custom_meetings.shortcut", "M", "messages", "en");
t.add("layout.custom_affairs", "Internal affairs", "messages", "en");
t.add("layout.custom_affairs.shortcut", "IA", "messages", "en");
t.add("layout.menu.management", "Administration", "messages", "en");
t.add("affair.affairOverview.meetings", "Meetings", "messages", "en");
t.add("customMeetings.affair.existingMeetings", "Meetings", "messages", "en");
t.add("profile.customMeetings", "Meetings", "messages", "en");
t.add("profile.settings.navigation.companySettings", "Settings", "messages", "en");
t.add("profile.settings.company.pictureFile.label", "Logo", "messages", "en");
t.add("profile.settings.company.settings.label", "Speakers", "messages", "en");
t.add("profile.settings.company.download.label", "download logo", "messages", "en");
t.add("profile.settings.company.delete.label", "Delete logo", "messages", "en");
t.add("custom_speakers_enabled.label", "show speaker", "messages", "en");
t.add("affair.customSpeakers.save.success", "Saved successfully", "messages", "en");
t.add("custom.affairs.list.pagetitle", "Internal affairs", "messages", "en");
t.add("custom.affairs.list.title", "Internal affairs", "messages", "en");
t.add("custom.affairs.edit.pagetitle", "edit internal affair", "messages", "en");
t.add("custom.affairs.edit.title", "edit internal affair", "messages", "en");
t.add("custom.affairs.backToList", "back", "messages", "en");
t.add("custom.affairs.list.header.title", "Title", "messages", "en");
t.add("custom.affairs.list.header.internalId", "Internal ID", "messages", "en");
t.add("custom.affairs.list.header.createdAt", "created on the", "messages", "en");
t.add("custom.affairs.list.header.actions", "Actions", "messages", "en");
t.add("custom.affairs.list.edit.label", "edit", "messages", "en");
t.add("custom.affairs.list.delete.label", "Delete", "messages", "en");
t.add("custom.affairs.noaffairs.alert", "no internal affairs available", "messages", "en");
t.add("custom.affairs.delete.confirm", "Are you sure you want to delete this internal affair?", "messages", "en");
t.add("custom.affairs.form.title", "Title", "messages", "en");
t.add("custom.affairs.form.internalId", "Internal ID", "messages", "en");
t.add("custom.affairs.form.text", "Text", "messages", "en");
t.add("custom.affairs.form.button.submit", "Save", "messages", "en");
t.add("custom.affairs.overview.title", "Internal affair", "messages", "en");
t.add("custom.affairs.form.create.title", "Create new internal affair", "messages", "en");
t.add("custom.affairs.new.title", "Create new internal affair", "messages", "en");
t.add("custom.affairs.new.error", "Error while saving", "messages", "en");
t.add("custom.affairs.new.pagetitle", "Create new internal affair", "messages", "en");
t.add("cantonalAffairs.deposited.tooltip.title", "Introduced", "messages", "en");
t.add("customAffairs.deposited.tooltip.title", "created", "messages", "en");
t.add("national.affair.draft.federal.council.proposal.tooltip.title", "Draft by the federal council", "messages", "en");
t.add("affairGroup.export.buttonlabel", "Export", "messages", "en");
t.add("customMeetings.editMeetingAgendaPoint.title", "edit agenda item", "messages", "en");
t.add("customMeetings.editMeetingAgendaPoint.save", "Save", "messages", "en");
t.add("customMeetings.assignAgendaPoint.noMeetings", "no meetings registered", "messages", "en");
t.add("customMeetings.assignAgendaPoint.noAgendaPointsLabel", "no agenda items registered yet", "messages", "en");
t.add("customMeetings.assignAgendaPoint.empty", "no meetings \/ agenda items registered", "messages", "en");
t.add("customMeetings.replicateMeeting.copyOf", "copy meeting structure (agenda items)", "messages", "en");
t.add("customMeetings.replicateMeeting.success", "Successful", "messages", "en");
t.add("customMeetings.tree.customMeeting.newCustomAffair", "Create new internal affair", "messages", "en");
t.add("customMeetings.tree.customMeeting.replicateCustomMeeting", "Duplicate", "messages", "en");
t.add("councillor.topic.count.title", "%topic%: %count% Proposals", "messages", "en");
t.add("canton.label", "Meeting settings: Standard canton", "messages", "en");
t.add("cantonal_faction.label", "Meeting settings: Standard parliamentary group", "messages", "en");
t.add("is_private.label", "private group (not visible to all)", "messages", "en");
t.add("affairGroups.newGroup.settings.label", "Group settings", "messages", "en");
t.add("affair.relatedDepartments.title", "Departments", "messages", "en");
t.add("affair.relatedDepartments.desc", "Departments", "messages", "en");
t.add("affair.relatedDepartments.empty", "no assignment", "messages", "en");
t.add("affairs.editor.placeholder", "(Please click here to enter the internal description)", "messages", "en");
t.add("selectpicker.noneSelectedText", "no selection made...", "messages", "en");
t.add("custom.affairs.form.html", "Text", "messages", "en");
t.add("customMeetings.newMeeting.participants.label", "those present", "messages", "en");
t.add("customMeetings.newMeeting.guests.label", "Guests", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.reducedOutputAffairs.label", "reduced output in reports \/ invitations (no history)", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.type.label", "Agenda item type", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.type.regular.label", "Standard", "messages", "en");
t.add("customMeetings.newMeetingAgendaPoint.type.affairsWithoutSpeaker.label", "Affair without a speaker", "messages", "en");
t.add("affair.daysummary", "Day summary", "messages", "en");
t.add("profile.notifications", "Notifications", "messages", "en");
t.add("profile.notifications.edit.shortcut", "N", "messages", "en");
t.add("profile.notifications.edit", "Notifications", "messages", "en");
t.add("profile.notifications.markAllRead", "mark all as read", "messages", "en");
t.add("profile.notifications.loadMore", "Show more", "messages", "en");
t.add("profile.notifications.empty", "No notifications", "messages", "en");
t.add("profile.notifications.noMore", "No notifications", "messages", "en");
t.add("notification.subscription.button.subscribed", "subscribed", "messages", "en");
t.add("notification.subscription.button.unSubscribed", "not subscribed", "messages", "en");
t.add("custom.affairs.form.copiedWithMeeting", "replicate with meeting", "messages", "en");
t.add("customMeetings.pdf.customSpeakers.label", "Speaker", "messages", "en");
t.add("national.committees.list", "List of national commissions", "messages", "en");
t.add("national.committees.show", "National commission", "messages", "en");
t.add("layout.menu.nationalcouncil.commissions", "Commissions NC", "messages", "en");
t.add("layout.menu.nationalcouncil.commissions.shortcut", "C", "messages", "en");
t.add("layout.menu.councilofstates.commissions", "Commissions CoS", "messages", "en");
t.add("layout.menu.councilofstates.commissions.shortcut", "C", "messages", "en");
t.add("customMeetings.pdf.protocol.title", "Minutes", "messages", "en");
t.add("cantonal.recentInsertedAffairs", "Newest cantonal affairs", "messages", "en");
t.add("customMeetings.pdf.invitation.title", "Invitation", "messages", "en");
t.add("vote.novotes", "No detailed voting result available", "messages", "en");
t.add("national.affairs.overview.title", "National affairs", "messages", "en");
t.add("national.affairs.list.header.title", "Title", "messages", "en");
t.add("national.affairs.list.header.author", "Author", "messages", "en");
t.add("national.affairs.list.header.topics", "Topics", "messages", "en");
t.add("national.affairs.list.header.status", "Status", "messages", "en");
t.add("national.affairs.list.header.depositDate", "Date of introduction", "messages", "en");
t.add("reports.overview.title", "Reports", "messages", "en");
t.add("reports.source.label", "data source", "messages", "en");
t.add("reports.from.label", "Affair Output: With which affair (number) should the report begin? Standard: empty = 1", "messages", "en");
t.add("reports.to.label", "Affairs output: Up to which affair (number) should be issued? Default: empty = up to the last affair (all affairs)", "messages", "en");
t.add("reports.source.placeholder", "Please select source...", "messages", "en");
t.add("reports.firstNumber.label", "Affair numbering starting point in the PDF (empty = 1)", "messages", "en");
t.add("reports.groups", "Groups", "messages", "en");
t.add("reports.groups.label", "Group(s)", "messages", "en");
t.add("reports.error.creation", "Error while creating report. Please contact our support team.", "messages", "en");
t.add("layout.menu.reports", "Reports", "messages", "en");
t.add("reports.session.canton.zh", "Affairs Cantonal Council meeting ZH", "messages", "en");
t.add("affair.cantonalHistoryeventOnSessionAgenda.tooltip.caption", "Cantonal event", "messages", "en");
t.add("affair.cantonalHistoryeventStatuschange.tooltip.caption", "Change of status", "messages", "en");
t.add("custom.affairs.new.success", "Internal affair successfully created", "messages", "en");
t.add("custom.affairs.edit.success", "Internal affair successfully saved", "messages", "en");
t.add("custom.affairs.delete.success", "Internal affair successfully deleted", "messages", "en");
t.add("custom.affairs.delete.error", "Error while deleting", "messages", "en");
t.add("affair.draftReference.title", "draft source", "messages", "en");
t.add("affair.draftPreConsultation.title", "Draft preliminary discussion", "messages", "en");
t.add("affair.draftPreConsultation.tooltip.caption", "Draft preliminary discussion", "messages", "en");
t.add("dashboard.updates.update_name", "Title", "messages", "en");
t.add("dashboard.updates.item_type", "Status Quo", "messages", "en");
t.add("dashboard.updates.update_type", "Update", "messages", "en");
t.add("dashboard.updates.update_time", "Appearance", "messages", "en");
t.add("dashboard.updates.type.insert", "New", "messages", "en");
t.add("dashboard.updates.type.update", "updated", "messages", "en");
t.add("dashboard.updates.type.remove", "Removed", "messages", "en");
t.add("dashboard.updates.title", "Affair updates", "messages", "en");
t.add("dashboard.custom_updates.title", "Internal event updates", "messages", "en");
t.add("dashboard.updates.itemType.cantonalAffair", "Cantonal affair", "messages", "en");
t.add("dashboard.updates.itemType.councilStatesVote", "Vote Council of States", "messages", "en");
t.add("dashboard.updates.itemType.nationalAffair", "National affair", "messages", "en");
t.add("dashboard.updates.itemType.nationalCouncilVote", "Vote National Council", "messages", "en");
t.add("dashboard.updates.itemType.customAffair", "Internal affair", "messages", "en");
t.add("dashboard.updates.itemType.customSpeaker", "Speaker", "messages", "en");
t.add("dashboard.updates.itemType.affairGroup", "Group", "messages", "en");
t.add("dashboard.updates.itemType.customMeeting", "Meeting", "messages", "en");
t.add("dashboard.updates.itemType.customMeetingAgendaPoint", "Meeting agenda item", "messages", "en");
t.add("affair.timeline", "Time axis", "messages", "en");
t.add("affair.event.details", "Details about event", "messages", "en");
t.add("actions.default", "Actions", "messages", "en");
t.add("groups.tree.type_select.all", "all groups", "messages", "en");
t.add("groups.tree.type_select.public", "non-private groups only", "messages", "en");
t.add("groups.tree.type_select.private", "private groups only", "messages", "en");
t.add("groups.tree.sort_select.alpha", "sort alphabetically", "messages", "en");
t.add("groups.tree.sort_select.date", "sort by creation date", "messages", "en");
t.add("affairGroups.deleteGroup.success", "Group successfully deleted", "messages", "en");
t.add("layout.affairs_overview.Shortcut", "A", "messages", "en");
t.add("layout.menu.nationalcouncil.consultations.shortcut", "C", "messages", "en");
t.add("dashboard.updates.itemType.internalNote", "Internal memo", "messages", "en");
t.add("dashboard.updates.itemType.internalVoteCustomEvent", "Internal vote", "messages", "en");
t.add("affair.custom.description", "Description", "messages", "en");
t.add("affair.events", "Events", "messages", "en");
t.add("national.consultations.overview.title", "National consultations", "messages", "en");
t.add("national.consultations.details.show_all", "show all", "messages", "en");
t.add("national.consultations.details.planned", "Planned consultations", "messages", "en");
t.add("national.consultations.details.in_progress", "Ongoing consultations", "messages", "en");
t.add("national.consultations.details.closed", "Closed consultations", "messages", "en");
t.add("national.consultations.details.title", "Detail about consultation", "messages", "en");
t.add("national.consultations.details.author", "Author", "messages", "en");
t.add("national.consultations.documents", "Documents", "messages", "en");
t.add("national.consultations.opinions", "Opinions on consultations", "messages", "en");
t.add("national.consultations.details.empty_list", "no consultations", "messages", "en");
t.add("affair.error.get_event", "Error while reading events", "messages", "en");
t.add("affair.customEvents.error", "Error while reading memos", "messages", "en");
t.add("national.consultations.error", "Error while reading consultations", "messages", "en");
t.add("dashboard.updates.itemType.noteCustomEvent", "Memo", "messages", "en");
t.add("affairs.overview.title", "Affairs", "messages", "en");
t.add("affair.customEvent.button.delete", "Delete", "messages", "en");
t.add("notifications.not_seen.description", "NEW", "messages", "en");
t.add("notifications.not_seen.shortcut", "N", "messages", "en");
t.add("ui.modal.warning", "Warning", "messages", "en");
t.add("affair_detail.modals.event_delete.text", "Do you want to delete this event?", "messages", "en");
t.add("ui.confirm", "Yes", "messages", "en");
t.add("ui.abort", "Abort", "messages", "en");
t.add("ui.breadcrumb.home", "Start", "messages", "en");
t.add("ui.affair.managed", "in group", "messages", "en");
t.add("affair.type.abbr", "Abbreviation", "messages", "en");
t.add("affair.code", "Code", "messages", "en");
t.add("affair.shortIdParlService", "Curia Vista number", "messages", "en");
t.add("affair.badge.private", "Commission affair", "messages", "en");
t.add("commission_detail.events.empty", "no entries", "messages", "en");
t.add("commission_detail.lastProgramLink", "Link latest programme (PDF)", "messages", "en");
t.add("commission_detail.overviewPageLink", "Link to official commission page", "messages", "en");
t.add("affairs.list.empty", "no entries", "messages", "en");
t.add("affair_list.select.type.label", "Type", "messages", "en");
t.add("affair_list.type_select.type.all", "all", "messages", "en");
t.add("affair_list.select.type.national", "National affairs", "messages", "en");
t.add("affair_list.select.type.cantonal", "Cantonal affairs", "messages", "en");
t.add("affair_list.select.date.last6months", "Last 6 months", "messages", "en");
t.add("affair_list.select.date.last12months", "Last 12 months", "messages", "en");
t.add("affair_list.select.date_after", "Date of submission", "messages", "en");
t.add("affair_list.select.date_before", "date after", "messages", "en");
t.add("ui.select.groups", "Groups", "messages", "en");
t.add("ui.select.groups.placeholder", "Groups", "messages", "en");
t.add("affair_list.select.events.label", "Memos \/ internal votes", "messages", "en");
t.add("affair_list.select.events.all", "all", "messages", "en");
t.add("affair_list.select.events.note", "with memos", "messages", "en");
t.add("affair_list.select.events.internal_vote", "with internal votes", "messages", "en");
t.add("ui.select.status.placeholder", "Filter by status...", "messages", "en");
t.add("ui.select.status.national", "National status", "messages", "en");
t.add("ui.select.status.cantonal", "cantonal status", "messages", "en");
t.add("panel.detailsLink.label", "Additional Information", "messages", "en");
t.add("timeline.tooltip.affair_link", "to affair", "messages", "en");
t.add("ui.table.sort.asc", "ascending", "messages", "en");
t.add("ui.table.sort.desc", "descending", "messages", "en");
t.add("ui.table.sort.asc.desc", "Sorting descending", "messages", "en");
t.add("dashboard.error.get_entries", "Error while loading entries", "messages", "en");
t.add("notifications.error.get", "Error while loading notifications", "messages", "en");
t.add("notifications.error.set_seen", "Error while setting notification status. Please contact our support team.", "messages", "en");
t.add("councillors.error.get", "Error while getting members of parliament. Please contact our support team.", "messages", "en");
t.add("ui.select.councillors.placeholder", "choose member of parliament", "messages", "en");
t.add("ui.select.councillors.label", "Members of parliament", "messages", "en");
t.add("commissions.link.commission", "to commission", "messages", "en");
t.add("commissions.link.overview", "to commission", "messages", "en");
t.add("commissions.link.program", "to meeting planning", "messages", "en");
t.add("calendar.events.empty", "no entries", "messages", "en");
t.add("security.saml.discovery", "SSO-Login", "messages", "en");
t.add("textEditor.small", "Small", "messages", "en");
t.add("textEditor.large", "Normal", "messages", "en");
t.add("textEditor.huge", "Huge", "messages", "en");
t.add("affair.nationalAffairDraftResolution.tooltip.caption", "Draft document", "messages", "en");
t.add("affair.nationalAffairDraftPreConsultation.tooltip.caption", "Preliminary discussion", "messages", "en");
t.add("event.calendar.title", "Calendar", "messages", "en");
t.add("layout.search.error", "Error while searching", "messages", "en");
t.add("layout.search.recent_affairs_national", "Latest national affairs", "messages", "en");
t.add("layout.search.recent_affairs_cantonal", "Latest cantonal affairs", "messages", "en");
t.add("timeline.tooltip.event_link", "To affair", "messages", "en");
t.add("affair.nationalHistoryeventStatuschange.tooltip.caption", "Status changed", "messages", "en");
t.add("affair.nationalAffairDraftReference.tooltip.caption", "Draft document", "messages", "en");
t.add("affair.nationalHistoryeventSessionProgram.tooltip.caption", "planned: treatment in session", "messages", "en");
t.add("affair.nationalCouncilAffairDebate.tooltip.caption", "Debate NC", "messages", "en");
t.add("affair.nationalAffairDraftFederalCouncilProposal.tooltip.caption", "Draft by the federal council", "messages", "en");
t.add("affair.nationalHistoryeventCommitteeProgram.tooltip.caption", "planned: treatment by the commission", "messages", "en");
t.add("event.reminders.title", "Reminders", "messages", "en");
t.add("event.reminders.button.save", "Save", "messages", "en");
t.add("event.reminders.button.delete", "Delete", "messages", "en");
t.add("event.reminders.button.add", "add", "messages", "en");
t.add("event.reminders.button.subscribed", "Reminder created", "messages", "en");
t.add("event.reminders.button.unsubscribed", "Create reminder", "messages", "en");
t.add("api.error.get_event_reminder", "Error while reading reminders. Please contact our support team.", "messages", "en");
t.add("api.groups.get.error", "Error while reading groups. Please contact our support team.", "messages", "en");
t.add("event.reminders.edit.success", "Saved successfully", "messages", "en");
t.add("event.reminders.edit.error", "Error while saving reminder. Please contact our support team.", "messages", "en");
t.add("event.reminders.create.error", "Error while saving reminder. Please contact our support team.", "messages", "en");
t.add("event.reminders.delete.error", "Error while deleting reminder. Please contact our support team.", "messages", "en");
t.add("event.reminders.empty", "no reminders", "messages", "en");
t.add("event.reminders.input.date", "Date", "messages", "en");
t.add("event.reminders.input.data", "Reminder created", "messages", "en");
t.add("search.error.get_cantons", "Error while seeking search results. Please contact our support team.", "messages", "en");
t.add("search.error.get_national_affair_types", "Error while seeking search results. Please contact our support team.", "messages", "en");
t.add("search.error.get_cantonal_affair_types", "Error while seeking search results. Please contact our support team.", "messages", "en");
t.add("layout.search.pagination.previous", "previous", "messages", "en");
t.add("layout.search.pagination.next", "next", "messages", "en");
t.add("affairs.search.title", "Search", "messages", "en");
t.add("national.consultations.list", "Consultations", "messages", "en");
t.add("national.councillors.list", "Members of parliament", "messages", "en");
t.add("affair.events.custom.create.error", "Error while saving. Please contact our support team.", "messages", "en");
t.add("affair.events.custom.create.success", "Event successfully saved.", "messages", "en");
t.add("reports.groups.placeholder", "Group(s)", "messages", "en");
t.add("eventReminder.event.notFound", "Event not found", "messages", "en");
t.add("reports.groups.outputDescription.label", "Introduced text", "messages", "en");
t.add("reports.groups.outputStatus.label", "Status", "messages", "en");
t.add("reports.groups.outputLink.label", "source link", "messages", "en");
t.add("reports.groups.outputSpeaker.label", "Speaker National Council \/ Council of States", "messages", "en");
t.add("reports.groups.outputInternalDescription.label", "Description", "messages", "en");
t.add("reports.groups.outputHistory.label", "Event time axis", "messages", "en");
t.add("affairGroup.export.header.speaker", "Speaker", "messages", "en");
t.add("affairGroup.export.header.internalDescription", "Description", "messages", "en");
t.add("affairGroup.export.header.history", "Events", "messages", "en");
t.add("reports.groups.outputHistory.none", "no events", "messages", "en");
t.add("reports.groups.outputHistory.customEvents", "Internal events only (memos, votes)", "messages", "en");
t.add("reports.groups.outputHistory.all", "all events", "messages", "en");
t.add("reports.formats.label", "Format", "messages", "en");
t.add("reports.format.excel", "Excel (XLS)", "messages", "en");
t.add("reports.format.pdf", "PDF", "messages", "en");
t.add("affairgroups.pdf.title", "Report", "messages", "en");
t.add("customMeetingAgendaPoints.form.title.cannotBeEmpty", "Title must be set", "messages", "en");
t.add("commission_detail.affairs", "Commission affairs", "messages", "en");
t.add("cantonal.commissions.empty", "no commission", "messages", "en");
t.add("api.councillors.get.error", "Error while getting members of parliament. Please contact our support team.", "messages", "en");
t.add("profile.show.language", "Language", "messages", "en");
t.add("profile.settings.label", "Settings", "messages", "en");
t.add("email_footer.label", "Footer notification email", "messages", "en");
t.add("show_company_logo_and_name.label", "App-Branding (left upper corner)", "messages", "en");
t.add("show_company_logo_and_name.both", "show logo and name", "messages", "en");
t.add("show_company_logo_and_name.logo", "logo only", "messages", "en");
t.add("show_company_logo_and_name.name", "name only", "messages", "en");
t.add("profile.settings.language.label", "Language", "messages", "en");
t.add("profile.settings.language.de", "Deutsch \/ German", "messages", "en");
t.add("profile.settings.language.fr", "French \/ Fran\u00e7ais", "messages", "en");
t.add("affair.title.ratsunterlagen", "Council documents", "messages", "en");
t.add("layout.maintenance.siteIsDown", "We are currently under planned construction work.", "messages", "en");
t.add("layout.maintenance.beBackSoon", "politik.ch will be back soon. If you have any questions, please write us at %email%. We hope you understand.", "messages", "en");
t.add("consultations.nohistoryEvents", "no events", "messages", "en");
t.add("ui.filter.all", "all", "messages", "en");
t.add("customMeetings.editMeeting.error", "Error while editing meetings. Please contact our support team.", "messages", "en");
t.add("profile.settings.navigation.auth", "Two-factor authentication (2FA)", "messages", "en");
t.add("profile.edit.2fa.authMode.label", "Choose second factor", "messages", "en");
t.add("profile.edit.2fa.none", "None (2FA deactivated)", "messages", "en");
t.add("profile.edit.2fa.email", "Email", "messages", "en");
t.add("profile.edit.2fa.google", "OTP (authenticator app, e.g. Google Authenticator)", "messages", "en");
t.add("profile.edit.2fa.pleaseReadThisQRCode", "Please scan the QR code:", "messages", "en");
t.add("profile.edit.2fa.googleCode.label", "Please enter a current code", "messages", "en");
t.add("profile.edit.2fa.thisIsYourGoogleAuthenticationCode", "Backup code:", "messages", "en");
t.add("profile.edit.2fa.pleaseStoreItInASafePlace", "Please store this code in a safe place. It needs to be used to log in if you no longer have access to your codes via the app.", "messages", "en");
t.add("affairs.list.no_access", "No access", "messages", "en");
t.add("profile.edit.2fa.invalidGoogleCode", "Code incorrect. Please enter a valid code from the authenticator app.", "messages", "en");
t.add("national.consultations.events.start", "Start of consultation", "messages", "en");
t.add("national.consultations.events.end", "End of consultation", "messages", "en");
t.add("ranking.councillorscompare.noVotesInSelectedTimespan", "No votes in the chosen period of time.", "messages", "en");
t.add("reports.reportType", "Sources for report", "messages", "en");
t.add("layout.search.contentType.affairConsultations", "Consultations", "messages", "en");
t.add("rankings.form.wholeLegislativeperiod", "Votes of the whole legislative period", "messages", "en");
t.add("affairGroup.overview.consultations.title", "Consultations", "messages", "en");
t.add("dashboard.updates.default_title", "Title", "messages", "en");
t.add("dashboard.updates.itemType.nationalAffairConsultation", "National consultation", "messages", "en");
t.add("ui.table.sort.asc.asc", "Sorting ascending", "messages", "en");
t.add("ui.date_picker.future", "all future", "messages", "en");
t.add("ui.date_picker.past", "all past", "messages", "en");
t.add("ui.date_picker.calendar.ranges.12months", "12 Months", "messages", "en");
t.add("ui.date_picker.calendar.ranges.6months", "6 Months", "messages", "en");
t.add("ui.date_picker.calendar.ranges", "Field", "messages", "en");
t.add("national.affairs.list.header.filter", "filter", "messages", "en");
t.add("ui.text_editor.image.exceeds", "Picture size surpasses the maximum size of 5 MB.", "messages", "en");
t.add("profile.settings.placeholder", "-", "messages", "en");
t.add("layout.search.contentType.affairConsultation", "Consultations", "messages", "en");
t.add("links.type.linker.mentions", "mentioned", "messages", "en");
t.add("links.type.linked.isMentionedBy", "was mentioned", "messages", "en");
t.add("links.type.linker.precedes", "Preceding affair", "messages", "en");
t.add("links.type.linked.succeeds", "Follow-up affair", "messages", "en");
t.add("links.type.linker.relates", "refers to", "messages", "en");
t.add("links.type.linked.relates", "was referred to", "messages", "en");
t.add("links.public", "Official", "messages", "en");
t.add("affair_detail.links.title", "Linked affairs", "messages", "en");
t.add("ui.delete", "Delete", "messages", "en");
t.add("api.links.get.error", "Error while getting affair links. Please contact our support team.", "messages", "en");
t.add("links.edit_form.title", "edit linked affairs", "messages", "en");
t.add("links.edit_form.list_empty", "No linked affairs", "messages", "en");
t.add("api.link_types.get.error", "Error while getting affair links. Please contact our support team.", "messages", "en");
t.add("api.search.error", "Error while getting search results. Please contact our support team.", "messages", "en");
t.add("links.edit_form.input.public.label", "Official?", "messages", "en");
t.add("links.edit_form.input.public.true", "Yes, officially", "messages", "en");
t.add("links.edit_form.input.public.false", "No, only internally (organisation\/corporation)", "messages", "en");
t.add("links.edit_form.input.link_type.label", "Link type", "messages", "en");
t.add("links.edit_form.input.entity.label", "link with", "messages", "en");
t.add("ui.submit", "Save", "messages", "en");
t.add("links.delete.confirm", "Do you really want to delete this link?", "messages", "en");
t.add("ui.select.loading", "loading...", "messages", "en");
t.add("affair.nationalHistoryeventCommitteePressRelease.tooltip.caption", "Press release by commission", "messages", "en");
t.add("committee.press_releases.title", "Press releases by commission", "messages", "en");
t.add("committee.pressRelease", "Press release", "messages", "en");
t.add("api.press_releases.get.error", "Error while loading press releases. Please contact our support team.", "messages", "en");
t.add("press_release.originalSource", "Source", "messages", "en");
t.add("affair_detail.actions.entityLinks", "link affairs", "messages", "en");
t.add("affair.nationalConsultationEndEvent.tooltip.caption", "End of consultation", "messages", "en");
t.add("affair.nationalConsultationStartEvent.tooltip.caption", "Start of consultation", "messages", "en");
t.add("fos_user.password.isPwned", "Password insecure. Please choose a secure password.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.html.get.error", "Error while reading. Please contact our support team.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.html.put.success", "Saved successfully.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.html.put.error", "Error while saving. Please contact our support team.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.html.post.success", "Saved successfully", "messages", "en");
t.add("api.profile.settings.company.internal_fields.html.post.error", "Error while saving. Please contact our support team.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.ratings.get.error", "Error while reading. Please contact our support team.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.ratings.put.error", "Error while saving. Please contact our support team.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.ratings.post.error", "Error while saving. Please contact our support team.", "messages", "en");
t.add("profile.settings.comapny.custom_fields.html.labelDe", "German description", "messages", "en");
t.add("profile.settings.comapny.custom_fields.html.labelFr", "french description", "messages", "en");
t.add("profile.settings.comapny.custom_fields.html.labelIt", "italian description", "messages", "en");
t.add("profile.settings.comapny.custom_fields.rating.labelDe", "German description", "messages", "en");
t.add("profile.settings.comapny.custom_fields.rating.labelFr", "french description", "messages", "en");
t.add("profile.settings.comapny.custom_fields.rating.labelIt", "italian description", "messages", "en");
t.add("profile.settings.company.internal_fields.ratings.choice.default", "Standard setting", "messages", "en");
t.add("ui.add", "add", "messages", "en");
t.add("profile.settings.save.failure", "Error while saving. Please contact our support team.", "messages", "en");
t.add("profile.settings.compay.internal_fields.delete.confirm", "Are you sure you want to delete this field?", "messages", "en");
t.add("profile.settings.company.custom_fields.label", "Custom fields", "messages", "en");
t.add("affair.internal_fields.title", "Others", "messages", "en");
t.add("profile.settings.company.custom_fields.html.add", "New text field", "messages", "en");
t.add("profile.settings.company.custom_fields.rating.add", "New options field", "messages", "en");
t.add("profile.settings.company.internal_fields.rating.choices.add", "Add option", "messages", "en");
t.add("profile.settings.compay.internal_fields.ratings.option.delete.confirm", "Do you really want to delete this option?", "messages", "en");
t.add("layout.search.contentType.affair_consultation", "Consultations", "messages", "en");
t.add("api.cantons.get.error", "Error while seeking search results. Please contact our support team.", "messages", "en");
t.add("ui.select.agenda_points", "Meetings", "messages", "en");
t.add("affair_detail.links.empty", "No linked affairs", "messages", "en");
t.add("forms.speakers.input.councillor.national_council", "National Council", "messages", "en");
t.add("forms.speakers.input.councillor.states_council", "Council of States", "messages", "en");
t.add("profile.settings.company.logo.upload.error.size", "The uploaded picture is too big. Please use a smaller resolution.", "messages", "en");
t.add("profile.settings.company.internal_fields.ratings.labelDe", "Description DE", "messages", "en");
t.add("profile.settings.company.internal_fields.ratings.labelFr", "description FR", "messages", "en");
t.add("profile.settings.company.internal_fields.ratings.labelIt", "description IT", "messages", "en");
t.add("profile.settings.company.internal_fields.html.labelDe", "Description DE", "messages", "en");
t.add("profile.settings.company.internal_fields.html.labelFr", "description FR", "messages", "en");
t.add("profile.settings.company.internal_fields.html.labelIt", "description IT", "messages", "en");
t.add("form.speakers.input.councillor.cantonal", "Cantonal Council \/ Grand Council", "messages", "en");
t.add("national.consultations.description.header", "Description", "messages", "en");
t.add("timeline.settings.timespans", "Events lasting several days", "messages", "en");
t.add("timeline.settings.timespans.showend", "show", "messages", "en");
t.add("timeline.settings.timespans.hideend", "don't show", "messages", "en");
t.add("national.consultations.events.start.timespan", "Period consultation", "messages", "en");
t.add("custom.affairs.table.caption", "Internal affairs", "messages", "en");
t.add("affair.councilStatesVote.tooltip.caption", "Vote Council of States", "messages", "en");
t.add("form.current_password", "Current password", "messages", "en");
t.add("form.new_password", "New password", "messages", "en");
t.add("form.new_password_confirmation", "Confirm new password", "messages", "en");
t.add("affair.nationalHistoryeventCommitteeProgramStart.tooltip.caption", "planned: treatment by the commission", "messages", "en");
t.add("affair.nationalAffairConsultationEndEvent.tooltip.caption", "National consultation: End", "messages", "en");
t.add("affair.nationalAffairConsultationStartEvent.tooltip.caption", "National consultation: Beginning", "messages", "en");
t.add("ui.select.meetings.placeholder", "Meetings", "messages", "en");
t.add("affair.nationalAffairNoteCustomEvent.tooltip.caption", "Internal memo", "messages", "en");
t.add("affair.statusChangeNationalAffairEvent.tooltip.caption", "Change of status", "messages", "en");
t.add("affair.nationalCommitteeProgramStartNationalAffairEvent.tooltip.caption", "Start treatment in commission", "messages", "en");
t.add("affair.nationalCommitteePressReleaseNationalAffairEvent.tooltip.caption", "Press release by commission", "messages", "en");
t.add("affair.nationalHistoryeventCommitteeProgramEnd.tooltip.caption", "planned: treatment by the commission (end)", "messages", "en");
t.add("affair.nationalCommitteeProgramEndNationalAffairEvent.tooltip.caption", "planned: treatment by the commission (end)", "messages", "en");
t.add("affair.nationalSessionProgramNationalAffairEvent.tooltip.caption", "National session programme", "messages", "en");
t.add("affair.nationalCommitteeProgramNationalAffairEvent.tooltip.caption", "National commission programme", "messages", "en");
t.add("affair.NationalAffairInternalVoteCustomEvent.tooltip.caption", "Internal vote", "messages", "en");
t.add("affair.nationalHistoryeventCommitteeProgramRemoved.tooltip.caption", "removed from commission programme", "messages", "en");
t.add("user.password.isPwned", "Password insecure. Please choose a secure password.", "messages", "en");
t.add("cantonal.overview.sessions", "Meetings \/ sessions parliament", "messages", "en");
t.add("cantonal.listSessions.title", "Meetings \/ sessions parliament", "messages", "en");
t.add("cantonal.sessions.table.date", "Date", "messages", "en");
t.add("cantonal.sessions.table.canton", "Canton", "messages", "en");
t.add("cantonal.sessions.table.link", "source link", "messages", "en");
t.add("cantonal.sessions.table.events", "Number of affairs", "messages", "en");
t.add("cantonal.sessions.title", "Detail about session \/ meeting", "messages", "en");
t.add("forms.speakers.input.councillor.cantonal", "Speaker", "messages", "en");
t.add("councils.nr", "in National Council", "messages", "en");
t.add("councils.sr", "in Council of States", "messages", "en");
t.add("cantonal.session.events.empty", "no affairs", "messages", "en");
t.add("api.cantonal.sessions.get.error", "Error while getting cantonal sessions", "messages", "en");
t.add("cantonal.faction.affairs.title", "Affairs of parliamentary group", "messages", "en");
t.add("cantonal.affairs.year.select.label", "Year selection", "messages", "en");
t.add("cantonal.consultations.attachments", "appendixes", "messages", "en");
t.add("affair.CantonalAffairConsultationEndEvent.tooltip.caption", "End of cantonal consultation", "messages", "en");
t.add("affair.CantonalAffairConsultationStartEvent.tooltip.caption", "Start of cantonal consultation", "messages", "en");
t.add("cantonal.consultations.description.header", "Description", "messages", "en");
t.add("cantonal.consultations.get.error", "Error while getting cantonal consultations", "messages", "en");
t.add("dashboard.updates.itemType.cantonalAffairConsultationInternalNote", "Internal memo cantonal consultation", "messages", "en");
t.add("links.edit_form.input.entity.placeholder", "choose affair...", "messages", "en");
t.add("ui.forms.confirm.title", "Title confirmed", "messages", "en");
t.add("event.reminders.create.success", "Reminder successfully saved", "messages", "en");
t.add("cantonal.overview.consultations.planned", "Consultations: Planned", "messages", "en");
t.add("cantonal.overview.consultations.in_progress", "Consultations: Ongoing", "messages", "en");
t.add("cantonal.overview.consultations.closed", "Consultations: Closed", "messages", "en");
t.add("cantonal.consultations.overview.title", "Cantonal consultations", "messages", "en");
t.add("cantonal.consultations.list", "Cantonal consultations", "messages", "en");
t.add("settings.company.affair_meetings_show.label", "Show \/ use meetings?", "messages", "en");
t.add("userGroups.builtIn.companyAdmins.title", "Administrator", "messages", "en");
t.add("userGroups.builtIn.ro.title", "Read-only group", "messages", "en");
t.add("userGroups.builtIn.rw.title", "Group read \/ write", "messages", "en");
t.add("committee.press_releases.table.header.title", "Title", "messages", "en");
t.add("committee.press_releases.table.header.date", "Date", "messages", "en");
t.add("committee.press_releases.table.header.link", "Link", "messages", "en");
t.add("cantonal.councillors.table.name", "Name", "messages", "en");
t.add("cantonal.councillors.table.party", "Party", "messages", "en");
t.add("cantonal.councillors.table.faction", "Parliamentary group", "messages", "en");
t.add("affair.nationalCommitteeAffair.tooltip.caption", "Commission affair", "messages", "en");
t.add("affair.nationalCommitteeProgramRemovedNationalAffairEvent.tooltip.caption", "affair removed from commission programme", "messages", "en");
t.add("affairGroup.export.header.internalFields", "Custom fields", "messages", "en");
t.add("timeline.settings.order.asc", "sort ascending", "messages", "en");
t.add("timeline.settings.order.desc", "sort descending", "messages", "en");
t.add("reports.nationalSessions.label", "Session", "messages", "en");
t.add("reports.format.docx", "Word DOCX", "messages", "en");
t.add("reports.form.error.oneAffairGroupOrNationalSessio", "Please select at least one group or session.", "messages", "en");
t.add("timeline.settings.order", "Sorting", "messages", "en");
t.add("committee.press_releases.table.header.author", "Author", "messages", "en");
t.add("dashboard.updates.itemType.nationalAffairConsultationInternalNote", "Memo national consultation", "messages", "en");
t.add("resetting.check_email", "If a login exists with this email, a link to reset your password has been sent.", "messages", "en");
t.add("reports.nationalsessions.placeholder", "no selection made...", "messages", "en");
t.add("reports.nationSessions.label", "Session", "messages", "en");
t.add("api.keywords.update.success", "Keywords updated successfully.", "messages", "en");
t.add("ui.select.user_groups.label", "User groups", "messages", "en");
t.add("reports.nationalSessionProgram.title", "Session report", "messages", "en");
t.add("reports.nationalSessionProgram.affairInGroups", "Group", "messages", "en");
t.add("reports.nationalSessionProgram", "Session report", "messages", "en");
t.add("layout.affair_groups.created", "Group successfully created", "messages", "en");
t.add("form.label.delete", "Delete", "messages", "en");
t.add("affair.statusChangeCantonalAffairEvent.tooltip.caption", "Change of status", "messages", "en");
t.add("ui.table.header.actions.group_assign.description", "assing group(s)", "messages", "en");
t.add("ui.table.header.actions.group_assign.manage_groups", "edit groups", "messages", "en");
t.add("ui.table.header.actions.group_assign", "add groups", "messages", "en");
t.add("api.user.edit.error", "Error while saving", "messages", "en");
t.add("api.user.edit.success", "Edited successfully", "messages", "en");
t.add("reports.form.error.oneAffairGroupOrNationalSession", "Please select a group or session", "messages", "en");
t.add("cantonal.parties.table.party.label", "Party", "messages", "en");
t.add("cantonal.parties.table.councillors.label", "Members of parliament", "messages", "en");
t.add("timeline.actions.expand", "Expand", "messages", "en");
t.add("ui.table.filter.empty", "no value set", "messages", "en");
t.add("cantonal.consultations.details.title", "Cantonal consultation", "messages", "en");
t.add("affair_list.search", "Search", "messages", "en");
t.add("timeline.actions.collapse", "Collapse", "messages", "en");
t.add("profile.show.actions.download_agb", "Download terms and conditions", "messages", "en");
t.add("committee.events.title", "Commission", "messages", "en");
t.add("committee.events.table.header.date", "Date", "messages", "en");
t.add("committee.events.table.header.title", "Title", "messages", "en");
t.add("committee.events.table.header.status", "Status", "messages", "en");
t.add("ui.date_picker.calendar.options.clear", "reset", "messages", "en");
t.add("reports.groups.outputLinkedAffairs.label", "put out linked affairs", "messages", "en");
t.add("reports.groups.outputLinkedAffairs.none", "don't put out", "messages", "en");
t.add("reports.groups.outputLinkedAffairs.reference", "only with reference", "messages", "en");
t.add("reports.groups.outputLinkedAffairs.full", "complete", "messages", "en");
t.add("reports.affair.section.linkedAffairs.title", "linked affairs", "messages", "en");
t.add("reports.affair.section.customLinkedAffairs.title", "internally linked affairs", "messages", "en");
t.add("ui.date_picker.calendar.input_end.label", "End date", "messages", "en");
t.add("ui.date_picker.calendar.input_start.label", "Start date", "messages", "en");
t.add("cantonal.councillors.table.first_name", "First name", "messages", "en");
t.add("cantonal.councillors.table.last_name", "Last name", "messages", "en");
t.add("reports.groups.outputEmptyGroups.label", "empty groups", "messages", "en");
t.add("reports.groups.outputId.label", "ID", "messages", "en");
t.add("reports.groups.outputCanton.label", "Canton", "messages", "en");
t.add("reports.groups.outputTitle.label", "Title", "messages", "en");
t.add("reports.groups.outputAuthors.label", "Author", "messages", "en");
t.add("reports.groups.outputDepartment.label", "Department", "messages", "en");
t.add("reports.groups.outputDepositDate.label", "Date of submission", "messages", "en");
t.add("reports.groups.outputLastEventDate.label", "Date of last event", "messages", "en");
t.add("reports.depositDate.label", "Date of submission", "messages", "en");
t.add("reports.lastEventDate.label", "Date of last event", "messages", "en");
t.add("reports.affairTypes.label", "Types of affairs", "messages", "en");
t.add("reports.affairTypes.nationalAffair", "National affairs", "messages", "en");
t.add("reports.affairTypes.nationalAffairConsultation", "National consultations", "messages", "en");
t.add("reports.affairTypes.cantonalAffairConsultation", "Cantonal consultations", "messages", "en");
t.add("reports.affairTypes.cantonalAffair", "Cantonal affairs", "messages", "en");
t.add("reports.affairTypes.customAffair", "Internal affairs", "messages", "en");
t.add("reports.affair.depositDate", "Date of submission", "messages", "en");
t.add("reports.affair.lastEventDate", "Date of latest event", "messages", "en");
t.add("profile.settings.company.user_groups.label", "Authorisations \/ User groups", "messages", "en");
t.add("profile.settings.user_groups.user_select", "assigned users", "messages", "en");
t.add("profile.settings.company.user_groups.add", "New user group", "messages", "en");
t.add("reports.customAffair.type", "Internal affair", "messages", "en");
t.add("reports.affair.authors", "Author", "messages", "en");
t.add("reports.affair.department", "Department", "messages", "en");
t.add("reports.section.customHistory", "Internal memos", "messages", "en");
t.add("reports.section.internalFields", "Custom fields", "messages", "en");
t.add("dashboard.updates.itemType.cantonalAffairConsultation", "Cantonal consultation", "messages", "en");
t.add("dashboard.updates.itemType.nationalAffairInternalNote", "Memo", "messages", "en");
t.add("profile.settings.company.terms_of_service.label", "Landing page window", "messages", "en");
t.add("dashboard.terms_of_service.label", "Welcome! Click here to read our welcome message and the general terms and conditions.", "messages", "en");
t.add("groups.tree.sort.alphabetical", "alphabetically", "messages", "en");
t.add("groups.tree.sort.date", "Date", "messages", "en");
t.add("customMeetings.tree.sort.alphabetical", "alphabetically", "messages", "en");
t.add("customMeetings.tree.sort.date", "Date", "messages", "en");
t.add("calendar.type.select.option.full", "Calendar view", "messages", "en");
t.add("calendar.type.select.option.day", "List view", "messages", "en");
t.add("calendar.day.load_history_events", "load previous entries", "messages", "en");
t.add("calendar.day.events.no_more", "No further entries", "messages", "en");
t.add("consultation.submittedByOn", "Submitted by %councillors%. Opened on %date%", "messages", "en");
t.add("calendar.type.select.label", "View", "messages", "en");
t.add("dashboard.updates.itemType.customAffairInternalNote", "Memo", "messages", "en");
t.add("affair.cantonalSessionProgramStartCantonalAffairEvent.tooltip.caption", "Inclusion in session programme \/ agenda", "messages", "en");
t.add("affair.event.date.label", "Date", "messages", "en");
t.add("ui.table.filter.all", "select all", "messages", "en");
t.add("ui.table.filter.clear", "select none", "messages", "en");
t.add("ui.reject", "Abort", "messages", "en");
t.add("layout.affair_groups.description", "Description", "messages", "en");
t.add("draft.title", "Draft", "messages", "en");
t.add("reports.cantonalsessions.placeholder", "choose cantonal session \/ meeting...", "messages", "en");
t.add("reports.cantonal.sessions", "Cantonal session \/ meeting", "messages", "en");
t.add("api.groups.delete.success", "Group successfully deleted", "messages", "en");
t.add("affair_groups_sorting.label", "Group: Standard sorting", "messages", "en");
t.add("affair_groups_sorting.default", "Standard setting", "messages", "en");
t.add("affair_groups_sorting.alphabetical", "sort alphabetically", "messages", "en");
t.add("affair_groups_sorting.custom", "sort user-defined", "messages", "en");
t.add("affair_groups_sorting.date", "sort by date of creation", "messages", "en");
t.add("profile.settings.company.terms_of_service_title.label", "Title", "messages", "en");
t.add("national.affair.drafts.title", "Drafts", "messages", "en");
t.add("ui.table.header.actions.size.label", "show max number of elements", "messages", "en");
t.add("list_size.label", "Number of entries", "messages", "en");
t.add("event.reminders.delete.success", "Reminder successfully deleted", "messages", "en");
t.add("reports.internal_fields.label", "Custom fields", "messages", "en");
t.add("affair.preConsultation.tooltip.caption", "Preliminary discussion", "messages", "en");
t.add("reports.sessionProgram.affairInGroups", "Group", "messages", "en");
t.add("profile.show.list_size", "Default list length", "messages", "en");
t.add("profile.edit.logo.download", "download profile picture", "messages", "en");
t.add("api.sessions.get.error", "Error while getting sessions. Please contact our support team.", "messages", "en");
t.add("reports.source.output_format", "File type", "messages", "en");
t.add("reports.config.save.form.title", "Update report template", "messages", "en");
t.add("reports.config.form.description", "Save these report settings in a report template:", "messages", "en");
t.add("reports.config.form.title.input.label", "description", "messages", "en");
t.add("reports.source.elements", "Elements", "messages", "en");
t.add("reports.config.form.output_format", "File type", "messages", "en");
t.add("reports.title.label", "Title", "messages", "en");
t.add("reports.date.label", "Date", "messages", "en");
t.add("reports.config.save", "Update report template", "messages", "en");
t.add("api.reports.config.post.success", "Report template successfully saved", "messages", "en");
t.add("api.reports.config.post.error", "Error while saving report template. Please contact our support team.", "messages", "en");
t.add("ui.select_multiple.placeholder", "Nothing selected", "messages", "en");
t.add("reports.nationalAffairConsultation.status.closed", "Closed", "messages", "en");
t.add("reports.nationalAffairConsultation.in_progres", "ongoing", "messages", "en");
t.add("affair.custom.submittedByOn", "created", "messages", "en");
t.add("affair.CouncilStatesVote", "Vote Council of States", "messages", "en");
t.add("affair.NationalCouncilVote", "Vote National Council", "messages", "en");
t.add("profile.settings.nationalNotificationPreferences.NationalCouncilVotes", "Votes National Council", "messages", "en");
t.add("profile.settings.nationalNotificationPreferences.CouncilStatesVotes", "Votes Council of States", "messages", "en");
t.add("affair.CouncilStatesVote.tooltip.caption", "Vote Council of States", "messages", "en");
t.add("affair.NationalCouncilVote.tooltip.caption", "Vote National Council", "messages", "en");
t.add("affair.overview.NationalCouncilVote.title", "Vote National Council", "messages", "en");
t.add("affair.overview.CouncilStatesVote.title", "Vote Council of States", "messages", "en");
t.add("affair.StatusChangeCantonalAffairEvent.tooltip.caption", "Change of status", "messages", "en");
t.add("dashboard.updates.itemType.CouncilStatesVote", "Vote Council of States", "messages", "en");
t.add("dashboard.updates.itemType.NationalCouncilVote", "Vote National Council", "messages", "en");
t.add("affair.NationalAffairDraftResolution.tooltip.caption", "Draft document", "messages", "en");
t.add("affair.PreConsultationNationalAffairEvent.tooltip.caption", "Preliminary discussion", "messages", "en");
t.add("affair.StatusChangeNationalAffairEvent.tooltip.caption", "Status changed", "messages", "en");
t.add("affair.NationalAffairDraftReference.tooltip.caption", "Draft document", "messages", "en");
t.add("affair.NationalSessionProgramNationalAffairEvent.tooltip.caption", "planned: treatment in session", "messages", "en");
t.add("affair.NationalCouncilDebateEvent.tooltip.caption", "Debate NC", "messages", "en");
t.add("affair.FederalCouncilProposalNationalAffairEvent.tooltip.caption", "Draft by the federal council", "messages", "en");
t.add("affair.NationalCommitteePressReleaseNationalAffairEvent.tooltip.caption", "Press release by commission", "messages", "en");
t.add("affair.NationalAffairConsultationEndEvent.tooltip.caption", "End of consultation", "messages", "en");
t.add("affair.NationalAffairConsultationStartEvent.tooltip.caption", "Start of consultation", "messages", "en");
t.add("affair.NationalCommitteeProgramEndNationalAffairEvent.tooltip.caption", "planned: treatment by the commission (end)", "messages", "en");
t.add("affair.NationalCommitteeProgramStartNationalAffairEvent.tooltip.caption", "planned: treatment by the commission", "messages", "en");
t.add("affair.NationalCommitteeProgramRemovedNationalAffairEvent.tooltip.caption", "removed from commission programme", "messages", "en");
t.add("affair.CantonalSessionProgramStartCantonalAffairEvent.tooltip.caption", "Inclusion in session programme \/ agenda", "messages", "en");
t.add("layout.search.content_type", "Type", "messages", "en");
t.add("reports.source.content", "Which visual elements would this report contain?", "messages", "en");
t.add("national.affairs.list.header.date", "Date of introduction", "messages", "en");
t.add("affair.NationalAffairConsultationSpanEvent.tooltip.caption", "National consultation: Period", "messages", "en");
t.add("affair.CantonalAffairConsultationSpanEvent.tooltip.caption", "Period cantonal consultation", "messages", "en");
t.add("ui.error.crash.title", "Unexpected error occurred", "messages", "en");
t.add("ui.error.crash.message", "Unfortunately, an error occurred. Please contact our support team: support@politik.ch", "messages", "en");
t.add("ui.error.crash.goto_dashboard", "to Dashboard", "messages", "en");
t.add("ui.error.crash.reload", "Reload this page", "messages", "en");
t.add("api.2fa.put.success", "Two-factor authentication settings saved successfully.", "messages", "en");
t.add("profile.edit.2fa.title", "Two-factor authentication (2FA)", "messages", "en");
t.add("profile.edit.2fa.google.secret_received", "Key", "messages", "en");
t.add("api.2fa.put.error", "Error while reading API (Two-factor authentication)", "messages", "en");
t.add("profile.settings.password.error.match", "Passwords not identical", "messages", "en");
t.add("profile.settings.password.error.api", "The password is too insecure. Please choose another password.", "messages", "en");
t.add("profile.password_reset.form.title", "Set new password", "messages", "en");
t.add("layout.search.orderBy.canton", "Canton", "messages", "en");
t.add("layout.search.show_advanced", "Show further options", "messages", "en");
t.add("layout.search.hide_advanced", "Hide further options", "messages", "en");
t.add("layout.search.filters.internal_fields", "Custom fields", "messages", "en");
t.add("profile.password.submit", "Save password", "messages", "en");
t.add("profile.settings.password.error.format", "Password too insecure. Please type at least 12 characters.", "messages", "en");
t.add("customMeetings.delete.message", "Do you really want to delete the meeting?", "messages", "en");
t.add("api.meetings.delete.success", "Meeting deleted successfully", "messages", "en");
t.add("api.meetings.delete.error", "Error while deleting the meeting.", "messages", "en");
t.add("layout.custom_meetings.time", "Meeting date", "messages", "en");
t.add("customMeetings.add_agenda.title", "Create new agenda item", "messages", "en");
t.add("layout.custom_meetings.description", "Description", "messages", "en");
t.add("customMeetings.edit.title", "edit", "messages", "en");
t.add("customMeetings.agenda_points.card.title", "Agenda items", "messages", "en");
t.add("customMeetings.participants.card.title", "Participants", "messages", "en");
t.add("api.affairs.get.error", "Error while getting affairs.", "messages", "en");
t.add("customMeetings.agenda_points.empty", "No agenda items recorded", "messages", "en");
t.add("dashboard.updates.itemType.cantonalAffairInternalNote", "Memo cantonal affair", "messages", "en");
t.add("national.affairs.list.header.canton", "Canton", "messages", "en");
t.add("national.affairs.list.header.actions", "Action", "messages", "en");
t.add("calendar.nav.prev", "previous", "messages", "en");
t.add("calendar.nav.next", "next", "messages", "en");
t.add("calendar.nav.today", "Today", "messages", "en");
t.add("ui.select_input.options.none", "Nothing selected", "messages", "en");
t.add("reports.source.filters", "Filter", "messages", "en");
t.add("reports.config.save_as", "Save new report template", "messages", "en");
t.add("reports.affair.link", "Link", "messages", "en");
t.add("reports.affair.codeAndType", "Nr. \/ type", "messages", "en");
t.add("reports.format.oldDocx", "Word DOCX", "messages", "en");
t.add("reports.format.oldPdf", "PDF", "messages", "en");
t.add("reports.tableOfContents", "Table of contents", "messages", "en");
t.add("reports.groups.outputTableOfContents.label", "Table of contents", "messages", "en");
t.add("reports.tableofcontents", "Table of contents", "messages", "en");
t.add("api.legislative_periods.get.error", "Error while loading legislature. Please contact our support team.", "messages", "en");
t.add("api.councillor.stats.get.error", "Error while getting parlamentarian statistics. Please contact our support team.", "messages", "en");
t.add("reports.config.delete", "Delete report template", "messages", "en");
t.add("reports.config.load", "Report remplates", "messages", "en");
t.add("affair.NationalCommitteeProgramSpanNationalAffairEvent.tooltip.caption", "Change of status", "messages", "en");
t.add("affair.CustomAffairNoteCustomEvent.tooltip.caption", "Internal memo", "messages", "en");
t.add("api.file.delete.error", "Error while deleting the picture. Please contact our support team.", "messages", "en");
t.add("reports.cantonalSessionProgram.title", "Cantonal session programme \/ agenda", "messages", "en");
t.add("affair.nationalAffairConsultationSpanEvent.tooltip.caption", "National consultation: Period", "messages", "en");
t.add("profile.settings.reports.affair.template", "Standard report template for affairs", "messages", "en");
t.add("api.reports.get.error", "Error while getting reports. Please contact our support team.", "messages", "en");
t.add("profile.settings.reports.affair.default", "choose standard report...", "messages", "en");
t.add("profile.edit.pictureFile.select", "choose picture...", "messages", "en");
t.add("profile.edit.pictureFile.not_selected", "no picture selected.", "messages", "en");
t.add("affair.title.report.download", "Export \/ download affair", "messages", "en");
t.add("profile.settings.commpany.user.delete.confirm", "Really delete:", "messages", "en");
t.add("affair.NationalAffairNoteCustomEvent.tooltip.caption", "Memo", "messages", "en");
t.add("affair.FederalCouncilResponseNationalAffairEvent.tooltip.caption", "Answer by the federal council", "messages", "en");
t.add("affair.NationalCommitteeAffair.tooltip.caption", "National commission affair", "messages", "en");
t.add("customMeetings.tree.customMeeting.goto", "Detail view", "messages", "en");
t.add("reports.affair", "Single affair", "messages", "en");
t.add("reports.affair.title", "Affair details", "messages", "en");
t.add("api.reports.config.put.success", "Report template successfully saved.", "messages", "en");
t.add("reports.config.delete.confirm.message", "Do you really want to delete this report template?", "messages", "en");
t.add("forms.custom_note_event.delete.success", "Memo successfully deleted", "messages", "en");
t.add("reports.affairSelect.placeholder", "choose affair...", "messages", "en");
t.add("reports.affairSelect.label", "Affair", "messages", "en");
t.add("profile.settings.company.user_groups.titleDe", "Group name GE", "messages", "en");
t.add("profile.settings.company.user_groups.titleFr", "Group name FR", "messages", "en");
t.add("profile.settings.company.user_groups.titleIt", "Group name IT", "messages", "en");
t.add("user.company.settings.user_group.roles", "Authorisations", "messages", "en");
t.add("customDataRoles.customEvents", "internal events", "messages", "en");
t.add("customDataRoles.customSpeakers", "Referees", "messages", "en");
t.add("customDataRoles.affairGroups", "Groups", "messages", "en");
t.add("customDataRoles.customAffairs", "internal affairs", "messages", "en");
t.add("customDataRoles.meetings", "Meetings", "messages", "en");
t.add("customDataRoles.customLinks", "Linked affairs", "messages", "en");
t.add("customDataRoles.internalFields", "Custom fields", "messages", "en");
t.add("customDataRoles.mediaObjects", "Files \/ appendixes", "messages", "en");
t.add("customDataRoles.userGroupItems", "Affair entry authorisations (memos etc.)", "messages", "en");
t.add("draft_0.title", "Message \/ report by the federal council", "messages", "en");
t.add("api.reports.confi.put.success", "Report template successfully saved", "messages", "en");
t.add(" api.reports.confi.delete.success", "Report template successfully deleted", "messages", "en");
t.add("reports.affairSelect.placeholde\ufffcr", "choose affair...", "messages", "en");
t.add("affair.internalNote.edit", "edit", "messages", "en");
t.add("affair.CantonalAffairInternalVoteCustomEvent.tooltip.caption", "Internal vote", "messages", "en");
t.add("profile.password.new.label", "New password", "messages", "en");
t.add("user.company.settings.user_group.role.read", "Read", "messages", "en");
t.add("user.company.settings.user_group.role.write", "Write", "messages", "en");
t.add("user.company.settings.user_group.role.none", "None", "messages", "en");
t.add("author.national_committee", "Author: Commission", "messages", "en");
t.add("layout.search.nationalCommitteesWithPrograms", "Commission programme", "messages", "en");
t.add("layout.search.nationalSessions", "National session", "messages", "en");
t.add("reports.committee", "Commission report", "messages", "en");
t.add("groups.tree.group.deleteAffair.confirm", "Do you really want to remove this affair from this group?", "messages", "en");
t.add("reports.nationalCommitteesPrograms.title", "Commission report", "messages", "en");
t.add("reports.cantonalSessionPrograms.label", "Cantonal session", "messages", "en");
t.add("Reports.committee", "Commission report", "messages", "en");
t.add("api.timelines.get.error", "Error while loading time axis. Please contact our support team.", "messages", "en");
t.add("api.parties.get.error", "Error while getting parties. Please contact our support team.", "messages", "en");
t.add("api.factions.get.error", "Error while getting data about parliamentary groups. Please contact our support team: support@politik.ch", "messages", "en");
t.add("api.parties.stats.get.error", "Error while getting party statistics. Please contact our support team.", "messages", "en");
t.add("api.factions.stats.get.error", "Error while getting statistics about parliamentary group. Please contact our support team: support@politik.ch", "messages", "en");
t.add("affairGroup.archive", "Archive group", "messages", "en");
t.add("affairGroup.archive.confirm", "Do you really want to archive this group?", "messages", "en");
t.add("customMeetings.archive", "Archive meeting", "messages", "en");
t.add("customMeetings.archive.confirm", "Do you really want to archive this meeting?", "messages", "en");
t.add("layout.search.subscribed.label", "Only include subscribed affairs", "messages", "en");
t.add("Schreiben", "user.company.settings.user_group.role.write", "messages", "en");
t.add("api.groups.error.affair_delete", "Error while deleting assignments to a group. Please contact our support team.", "messages", "en");
t.add("event.reminders.error.get", "Error while getting reminders. Please contact our support team.", "messages", "en");
t.add("api.committees.get.error", "Error while getting commissions. Please contact our support team.", "messages", "en");
t.add("affairGroups.archive", "Archive", "messages", "en");
t.add("menu.company.choose", "Change organisation\/corporation", "messages", "en");
t.add("api.user.companies.get.error", "Error while getting organisations\/corporations. Please contact our support team.", "messages", "en");
t.add("user.company.set.form.title", "Choose organisation\/corporation", "messages", "en");
t.add("reports.title", "Create a report", "messages", "en");
t.add("reports.sections.type.title", "What kind of report do you want to create?", "messages", "en");
t.add("reports.sections.groups.title", "From which groups should the report be created?", "messages", "en");
t.add("reports.sections.official.title", "Which official details about affairs should the report contain?", "messages", "en");
t.add("reports.sections.custom.title", "Which custom contents about affairs should the report contain?", "messages", "en");
t.add("reports.sections.filters.title", "Which filters should be applied to the report?", "messages", "en");
t.add("reports.sections.format.title", "What filetype does the report need to be?", "messages", "en");
t.add("reports.sections.basic.title", "Which visual elements should this report contain?", "messages", "en");
t.add("reports.sections.presets.title", "Do you want to save these report settings as a report template?", "messages", "en");
t.add("reports.sections.submit.title", "Ready to generate the report?", "messages", "en");
t.add("security.saml.loginError", "Error while logging in with SAML.", "messages", "en");
t.add("author.CantonalCouncillor", "Author cantonal affair", "messages", "en");
t.add("reports.action.header", "Saved report templates", "messages", "en");
t.add("reports.action.label", "Current template", "messages", "en");
t.add("user.company.select_input.label", "Choose organisation\/corporation", "messages", "en");
t.add("layout.search.contentType.custom_affair", "Internal affairs", "messages", "en");
t.add("footer.label", "Imprint and Privacy Policy", "messages", "en");
t.add("reports.author.label", "Author", "messages", "en");
t.add("reports.subtitle.label", "Preliminary remark", "messages", "en");
t.add("file_upload.no_file", "No logo uploaded", "messages", "en");
t.add("file_upload.btn_label", "Upload logo", "messages", "en");
t.add("reports.national_session", "National session report", "messages", "en");
t.add("reports.national_committee", "National commission report", "messages", "en");
t.add("profile.settings.company.title", "Organisation\/corporation settings", "messages", "en");
t.add("profile.settings.title", "Profile settings", "messages", "en");
t.add("profile.settings.navigation.user_company_custom_fields", "Custom fields", "messages", "en");
t.add("profile.settings.navigation.user_company_user_groups", "Authorisations \/ User groups", "messages", "en");
t.add("NoteCustomEvent.is_private.label", "private memo", "messages", "en");
t.add("profile.settings.company.shortcut", "S", "messages", "en");
t.add("profile.settings.company.affair_edit.label", "Edit organisation\/corporation", "messages", "en");
t.add("reports.section.main_section", "Which commissions should be considered?", "messages", "en");
t.add("customMeetings.meetingAgendaPoint.type.affairsWithSpeakerWithoutCustomNote.label", "Affair with a speaker but without notes", "messages", "en");
t.add("councillor.language.D", "German", "messages", "en");
t.add("councillor.language.F", "French", "messages", "en");
t.add("councillor.language.I", "Italian", "messages", "en");
t.add("api.reports.config.delete.success", "Report template successfully deleted", "messages", "en");
t.add("profile.settings.company.meetings", "Meetings", "messages", "en");
t.add("ui.select.all", "Select all", "messages", "en");
t.add("affairs.customEvents.is_private.label", "private memo (only visible to you)", "messages", "en");
t.add("reports.national_council.label", "Speaker National Council", "messages", "en");
t.add("reports.states_council.label", "Speaker Council of States", "messages", "en");
t.add("ui.text_editor.link_label", "Link:", "messages", "en");
t.add("ui.text_editor.save_btn", "Save", "messages", "en");
t.add("not_found.title", "The page you're looking for could not be found.", "messages", "en");
t.add("not_found.subtitle", "This is not the page you're looking for. It may have been moved or deleted.", "messages", "en");
t.add("not_found.link.label", "Back", "messages", "en");
t.add("layout.search.affairStatesNational", "Latest Event", "messages", "en");
t.add("vote.id", "Voting number", "messages", "en");
t.add("api.subscriptions.get.error", "Error while getting subscribed affairs. Please contact our support team.", "messages", "en");
t.add("api.subscriptions.delete.error", "Error while removing subscribed affairs. Please contact our support team.", "messages", "en");
t.add("api.subscriptions.post.error", "Error while saving subscribed affairs. Please contact our support team.", "messages", "en");
t.add("reports.meeting_protocol", "Meeting protocols", "messages", "en");
t.add("reports.meeting_invitation", "Meeting invitations", "messages", "en");
t.add("reports.sections.meeting_invitation.title", "For which meeting do you want to create an invitation?", "messages", "en");
t.add("reports.sections.meeting_protocol.title", "For which meeting do you want to create a protocol?", "messages", "en");
t.add("profile.settings.reports.invitation.template", "Standard template for meeting invitation", "messages", "en");
t.add("profile.settings.reports.protocol.template", "Standard template for meeting protocol", "messages", "en");
t.add("select_input.not_selected", "Nothing selected...", "messages", "en");
t.add("layout.search.meetings.label", "Choose meeting", "messages", "en");
t.add("api.user.companies.put.success", "Organisation\/corporation has been set", "messages", "en");
t.add("affair.nationalAffairInternalVoteCustomEvent.tooltip.caption", "Internal vote", "messages", "en");
t.add("user.company_select_input.label", "Choose organisation\/corporation", "messages", "en");
t.add("reports.section.session.title", "Which session should the report contain?", "messages", "en");
t.add("reports.nationalCommitteeProgram.title", "Commission Report", "messages", "en");
t.add("affair.actions_card.title", "Additional Information", "messages", "en");
t.add("file_upload.remove", "Delete", "messages", "en");
t.add("reports.meetings.sections.meeting_invitation.title", "For which meeting is this document?", "messages", "en");
t.add("reports.meetings.sections.official.title", "Which official details should the document contain?", "messages", "en");
t.add("reports.meetings.sections.custom.title", "Which custom content should the document contain?", "messages", "en");
t.add("reports.meetings.sections.filters.title", "Which filters should be applied?", "messages", "en");
t.add("reports.meetings.sections.basic.title", "Which visual elements should be produced?", "messages", "en");
t.add("reports.meetings.sections.format.title", "What filetype does the document need to be?", "messages", "en");
t.add("reports.meetings.sections.presets.title", "Do you want to save these settings as a template?", "messages", "en");
t.add("reports.meetings.sections.submit.title", "Ready to generate the document?", "messages", "en");
t.add("customMeetings.newMeeting.error", "Error while saving. Please contact our support team.", "messages", "en");
t.add("reports.nationalCommitteePrograms.title", "Commission report", "messages", "en");
t.add("dashboard.updates.itemType.nationalAffairCustomSpeaker", "Speaker", "messages", "en");
t.add("events.termine.categories.all", "all", "messages", "en");
t.add("events.termine.categories.none", "none", "messages", "en");
t.add("events.termine.categories.1", "Public Vote", "messages", "en");
t.add("events.termine.categories.2", "Elections", "messages", "en");
t.add("events.termine.categories.3", "Cantonal assemblies (\"Landsgemeinden\")", "messages", "en");
t.add("events.termine.categories.4", "Sessions", "messages", "en");
t.add("events.termine.categories.5", "Commissions", "messages", "en");
t.add("events.termine.categories.6", "Others", "messages", "en");
t.add("affair_list.select.label", "Date of submission", "messages", "en");
t.add("layout.affair_groups.updated", "Latest Event", "messages", "en");
t.add("dashboard.updates.user", "User", "messages", "en");
t.add("file_upload.file_too_big.error", "The file you want to upload is too large. Please reduce the file size to less then 2 MB or contact our support team.", "messages", "en");
t.add("affair.CantonalSessionProgramSpanCantonalAffairEvent.tooltip.caption", "Session programme \/ Agenda", "messages", "en");
t.add("events.termine.categories", "Date categories", "messages", "en");
t.add("calendar.type.select.option.general_dates", "General political dates", "messages", "en");
t.add("api.rankings.get.error", "Error while getting rankings. Please contact our support team.", "messages", "en");
t.add("rankings.affairs", "Rankings", "messages", "en");
t.add("rankings.filter.group.label", "Groups", "messages", "en");
t.add("api.topics.get.error", "Error while getting topics. Please contact our support team.", "messages", "en");
t.add("rankings.list.empty", "No ranking available.", "messages", "en");
t.add("affair.CantonalSessionProgramEndCantonalAffairEvent.tooltip.caption", "Inclusion in session programme \/ agenda", "messages", "en");
t.add("layout.search.orderBy.lastEventDate", "Most recent event", "messages", "en");
t.add("profile.customMeetings.archived", "Archived meetings", "messages", "en");
t.add("api.cantonal.councillor.get.error", "Error while getting cantonal Members of Parliamen. Please contact our support team.", "messages", "en");
t.add("profile.affairGroups.archived", "Archived groups", "messages", "en");
t.add("councils.br", "Federal Council", "messages", "en");
t.add("affair.FederalCouncilMediaReleaseNationalAffairEvent.tooltip.caption", "Press release by the Federal Council", "messages", "en");
t.add("affair.SdaMediaReleaseNationalAffairEvent.tooltip.caption", "Press release by SDA", "messages", "en");
t.add("profile.settings.company.custom_fields.html.labelDe", "Description DE", "messages", "en");
t.add("profile.settings.company.custom_fields.html.labelFr", "Description FR", "messages", "en");
t.add("profile.settings.company.custom_fields.html.labelIt", "description IT", "messages", "en");
t.add("ranking.against", "Dissenters \/ against own parliamentary group", "messages", "en");
t.add("api.cantonal.consultations.get.error", "Error while getting cantonal consultations. Please contact our support team.", "messages", "en");
t.add("reports.groups.outputAssignedGroups.label", "Output assigned groups", "messages", "en");
t.add("api.login.error", "Login failed.", "messages", "en");
t.add("security.login.welcome", "Welcome to politik.ch", "messages", "en");
t.add("security.login.auth_code", "Auth-Code", "messages", "en");
t.add("cantonal.column", "Source", "messages", "en");
t.add("cantonal.dataQuality.sourceType.affairs", "A", "messages", "en");
t.add("cantonal.dataQuality.sourceType.consultations", "C", "messages", "en");
t.add("affairGroup.linkToAffairGroup", "Group view", "messages", "en");
t.add("customMeetings.linkToDocument", "To affair", "messages", "en");
t.add("not_found.suggested_searches", "Suggested affairs", "messages", "en");
t.add("affair.results.bydissenters.none", "Without dissenters", "messages", "en");
t.add("layout.search.contentType.national_councillor", "National Members of Parliament", "messages", "en");
t.add("layout.search.contentType.cantonal_councillor", "Cantonal Members of Parliament", "messages", "en");
t.add("layout.search.contentType.national_affair_consultation", "National consultation", "messages", "en");
t.add("layout.search.contentType.cantonal_affair_consultation", "Cantonal consultation", "messages", "en");
t.add("layout.search.filters", "Filter", "messages", "en");
t.add("affair.sdaMediaReleaseNationalAffairEvent.title.prefix", "Press release by SDA", "messages", "en");
t.add("affair.federalCouncilMediaReleaseNationalAffairEvent.title.prefix", "Initial situation", "messages", "en");
t.add("layout.search.type.national", "National affairs", "messages", "en");
t.add("layout.search.type.cantonal", "Cantonal affairs", "messages", "en");
t.add("layout.search.type.custom", "Interal affairs", "messages", "en");
t.add("not_found.no_similar_results_found", "We also didn't find any similar affairs.", "messages", "en");
t.add("parties.noMembersFound", "None", "messages", "en");
t.add("reports.groups.numberingTableOfContents.label", "Numbering", "messages", "en");
t.add("layout.search.orderBy.lastStatus", "Last Status", "messages", "en");
t.add("layout.search.orderBy.author", "Author", "messages", "en");
t.add("affair.actions_card.auto_save.label", "Saving in progress...", "messages", "en");
t.add("affairGroup.linkToDocument", "Group view", "messages", "en");
t.add("vote.decision.others", "Abstained\/Absent etc.", "messages", "en");
t.add("auth.password_reset.success", "Password change successful", "messages", "en");
t.add("user.password.missingdata", "Missing data", "messages", "en");
t.add("user.password.fieldsnotequal", "Passwords not identical", "messages", "en");
t.add("user.password.short", "Password too short", "messages", "en");
t.add("consultation.endDate", "ends on", "messages", "en");
t.add("security.login.submit", "Login", "messages", "en");
t.add("security.wrong_password", "Unknown user or wrong password", "messages", "en");
t.add("affair.nationalAffairConsultationOpinionsEvent.tooltip.caption", "Statement", "messages", "en");
t.add("affair.nationalAffairConsultationReportsEvent.tooltip.caption", "Result", "messages", "en");
t.add("profile.settings.language.it", "Italian \/ Italiano", "messages", "en");
t.add("profile.settings.language.en", "English", "messages", "en");
t.add("profile.settings.data_language.label", "Language for data \/ affairs (if available)", "messages", "en");
t.add("profile.settings.data_language.sameAsLanguage", "Same as user language", "messages", "en");
t.add("affair.NationalAffairCouncilDocumentsEvent.tooltip.caption", "Council documents", "messages", "en");
t.add("national.affairs.status.added_to_session_program", "added to session programme", "messages", "en");
t.add("national.affairs.status.removed_from_session_program", "Deleted \/ moved from session programme", "messages", "en");
t.add("national.affairs.status.deposited", "Submitted", "messages", "en");
t.add("national.affairs.status.added_to_committee_program", "Added to commission program", "messages", "en");
t.add("national.affairs.status.removed_from_committee_program", "Deleted \/ moved from commission programme", "messages", "en");
t.add("national.affairs.status.mentioned_in_press_release", "Press release published by commission", "messages", "en");
t.add("national.affairs.status.federal_council_proposal_added", "Proposal published by the Federal Council", "messages", "en");
t.add("national.affairs.status.federal_council_answer_added", "Answer published by the Federal Council", "messages", "en");
t.add("national.affairs.status.federal_council_opionion_added", "Opinion published by the Federal Council", "messages", "en");
t.add("national.affairs.status.preconsultation_added", "Preliminary discussion", "messages", "en");
t.add("national.affairs.status.federal_council_media_release", "Status quo defined", "messages", "en");
t.add("national.affairs.status.sda_media_release", "Press release by SDA published", "messages", "en");
t.add("national.affairs.status.council_documents_event", "Council documents published", "messages", "en");
t.add("national.affairs.status.unreachable", "Not available from official sources", "messages", "en");
t.add("national.affairs.status.deleted", "Deleted from official source", "messages", "en");
t.add("cantonal.affairs.type.vor.title", "Proposal (government)", "messages", "en");
t.add("cantonal.affairs.type.vop.title", "Proposal (parliament)", "messages", "en");
t.add("cantonal.affairs.type.mo.title", "Motion", "messages", "en");
t.add("cantonal.affairs.type.po.title", "Postulate", "messages", "en");
t.add("cantonal.affairs.type.ip.title", "Interpellation", "messages", "en");
t.add("cantonal.affairs.type.kla.title", "Small \/ simple question", "messages", "en");
t.add("cantonal.affairs.type.vo.title", "People's motion", "messages", "en");
t.add("cantonal.affairs.type.au.title", "Mandate", "messages", "en");
t.add("cantonal.affairs.type.pai.title", "Parliamentary initiative", "messages", "en");
t.add("cantonal.affairs.type.ktiv.title", "Cantonal initiative", "messages", "en");
t.add("cantonal.affairs.type.wah.title", "Election", "messages", "en");
t.add("cantonal.affairs.type.pe.title", "Petition", "messages", "en");
t.add("cantonal.affairs.type.ber.title", "Report", "messages", "en");
t.add("cantonal.affairs.type.pae.title", "Parliamentary recommendation", "messages", "en");
t.add("cantonal.affairs.type.an.title", "Proposal", "messages", "en");
t.add("cantonal.affairs.type.div.title", "Miscellaneous", "messages", "en");
t.add("cantonal.affairs.type.in.title", "Initiative", "messages", "en");
t.add("cantonal.affairs.type.beg.title", "Pardon", "messages", "en");
t.add("cantonal.affairs.type.bes.title", "Complaint", "messages", "en");
t.add("cantonal.affairs.type.veg.title", "Swearing in", "messages", "en");
t.add("cantonal.affairs.type.vem.title", "Consultation", "messages", "en");
t.add("cantonal.affairs.type.ver.title", "Ordinances and decrees", "messages", "en");
t.add("cantonal.affairs.type.fra.title", "Question time", "messages", "en");
t.add("cantonal.affairs.type.res.title", "Resolution", "messages", "en");
t.add("cantonal.affairs.type.ges.title", "Business report", "messages", "en");
t.add("cantonal.affairs.type.bre.title", "Citizenship", "messages", "en");
t.add("cantonal.affairs.type.ive.title", "Inter-cantonal agreement", "messages", "en");
t.add("cantonal.affairs.type.ref.title", "Referendum", "messages", "en");
t.add("cantonal.affairs.type.bel.title", "Decision", "messages", "en");
t.add("cantonal.affairs.type.dek.title", "Decree", "messages", "en");
t.add("cantonal.affairs.type.svo.title", "Collection proposal", "messages", "en");
t.add("cantonal.affairs.type.eiv.title", "Single initiative", "messages", "en");
t.add("cantonal.affairs.type.vor.abbr", "Prop.gov.", "messages", "en");
t.add("cantonal.affairs.type.vop.abbr", "Prop.parl.", "messages", "en");
t.add("cantonal.affairs.type.mo.abbr", "Mo.", "messages", "en");
t.add("cantonal.affairs.type.po.abbr", "Po.", "messages", "en");
t.add("cantonal.affairs.type.ip.abbr", "Interpellation", "messages", "en");
t.add("cantonal.affairs.type.kla.abbr", "Q.", "messages", "en");
t.add("cantonal.affairs.type.vo.abbr", "Ppl.mo.", "messages", "en");
t.add("cantonal.affairs.type.au.abbr", "Man.", "messages", "en");
t.add("cantonal.affairs.type.pai.abbr", "Pa.Iv.", "messages", "en");
t.add("cantonal.affairs.type.ktiv.abbr", "Ct.Iv.", "messages", "en");
t.add("cantonal.affairs.type.wah.abbr", "El.", "messages", "en");
t.add("cantonal.affairs.type.pe.abbr", "Pe.", "messages", "en");
t.add("cantonal.affairs.type.ber.abbr", "Rep.", "messages", "en");
t.add("cantonal.affairs.type.pae.abbr", "Parl.rec.", "messages", "en");
t.add("cantonal.affairs.type.an.abbr", "Prop.", "messages", "en");
t.add("cantonal.affairs.type.div.abbr", "Misc.", "messages", "en");
t.add("cantonal.affairs.type.in.abbr", "Iv.", "messages", "en");
t.add("cantonal.affairs.type.beg.abbr", "Pard.", "messages", "en");
t.add("cantonal.affairs.type.bes.abbr", "Compl.", "messages", "en");
t.add("cantonal.affairs.type.veg.abbr", "Swear.", "messages", "en");
t.add("cantonal.affairs.type.vem.abbr", "Cons.", "messages", "en");
t.add("cantonal.affairs.type.ver.abbr", "O.", "messages", "en");
t.add("cantonal.affairs.type.fra.abbr", "Q.t.", "messages", "en");
t.add("cantonal.affairs.type.res.abbr", "Res.", "messages", "en");
t.add("cantonal.affairs.type.ges.abbr", "Bus.rep.", "messages", "en");
t.add("cantonal.affairs.type.bre.abbr", "Cit.", "messages", "en");
t.add("cantonal.affairs.type.ive.abbr", "Ict.agr.", "messages", "en");
t.add("cantonal.affairs.type.ref.abbr", "Ref.", "messages", "en");
t.add("cantonal.affairs.type.bel.abbr", "Dec.", "messages", "en");
t.add("cantonal.affairs.type.dek.abbr", "D.", "messages", "en");
t.add("cantonal.affairs.type.svo.abbr", "Coll.prop.", "messages", "en");
t.add("cantonal.affairs.type.eiv.abbr", "Sin.Iv.", "messages", "en");
t.add("cantonal.affairs.status.first_reading", "First reading", "messages", "en");
t.add("cantonal.affairs.status.second_reading", "Second reading", "messages", "en");
t.add("cantonal.affairs.status.rejected", "Rejection", "messages", "en");
t.add("cantonal.affairs.status.rejected_not_definitely_supported", "Rejection - not definitely supported", "messages", "en");
t.add("cantonal.affairs.status.refusal_not_supported_for_the_time_being", "Rejection - not supported temporarily", "messages", "en");
t.add("cantonal.affairs.status.depreciation", "Abandonment", "messages", "en");
t.add("cantonal.affairs.status.application", "Proposal", "messages", "en");
t.add("cantonal.affairs.status.proposal_for_rejection", "Proposal to reject", "messages", "en");
t.add("cantonal.affairs.status.application_for_depreciation", "Proposal to abandon", "messages", "en");
t.add("cantonal.affairs.status.application_for_depreciation_in_the_annual_report", "Proposal to abandon in the business report", "messages", "en");
t.add("cantonal.affairs.status.proposal_for_discussion", "Proposal to discuss", "messages", "en");
t.add("cantonal.affairs.status.proposal_for_urgency", "Proposal of urgency", "messages", "en");
t.add("cantonal.affairs.status.application_for_completion", "Proposal of completion", "messages", "en");
t.add("cantonal.affairs.status.application_for_extension_of_the_deadline", "Proposal to extend deadline", "messages", "en");
t.add("cantonal.affairs.status.proposal_for_consent", "Proposal to accept", "messages", "en");
t.add("cantonal.affairs.status.application_bank_council", "Proposal by bank council", "messages", "en");
t.add("cantonal.affairs.status.proposal_court", "Proposal by court", "messages", "en");
t.add("cantonal.affairs.status.proposal_commission", "Proposal by commission", "messages", "en");
t.add("cantonal.affairs.status.proposal_government_council", "Proposal by Executive Council", "messages", "en");
t.add("cantonal.affairs.status.response_management", "Answer by the management", "messages", "en");
t.add("cantonal.affairs.status.government_council_answer", "Answer by Executive Council", "messages", "en");
t.add("cantonal.affairs.status.commencement_of_consultations", "Start of the deliberations", "messages", "en");
t.add("cantonal.affairs.status.beginning_discussion", "Start of discussions", "messages", "en");
t.add("cantonal.affairs.status.report_government_council", "Executive Council Report", "messages", "en");
t.add("cantonal.affairs.status.report_and_proposal_government_council", "Report and proposal by Executive Council", "messages", "en");
t.add("cantonal.affairs.status.definitely_supported", "Definitely supported", "messages", "en");
t.add("cantonal.affairs.status.discussed", "Discussed", "messages", "en");
t.add("cantonal.affairs.status.urgency_approved", "Urgent", "messages", "en");
t.add("cantonal.affairs.status.deposited", "Submitted", "messages", "en");
t.add("cantonal.affairs.status.receipt", "Reception", "messages", "en");
t.add("cantonal.affairs.status.supplementary_report", "Supplementary report", "messages", "en");
t.add("cantonal.affairs.status.significantly_explained", "Declared substantially", "messages", "en");
t.add("cantonal.affairs.status.done", "Done", "messages", "en");
t.add("cantonal.affairs.status.continuation_of_consultations", "Continuation of deliberations", "messages", "en");
t.add("cantonal.affairs.status.continuation_of_detailed_advice", "Continuation of detailled discussion", "messages", "en");
t.add("cantonal.affairs.status.deadline_extends", "Deadline extended", "messages", "en");
t.add("cantonal.affairs.status.perusal", "Acknowledgement", "messages", "en");
t.add("cantonal.affairs.status.urgency_not_approved", "Not declared urgent", "messages", "en");
t.add("cantonal.affairs.status.do_not_enter", "Not entered into debate", "messages", "en");
t.add("cantonal.affairs.status.come_back_proposal", "Motion to return", "messages", "en");
t.add("cantonal.affairs.status.rejection", "Rejection", "messages", "en");
t.add("cantonal.affairs.status.retired", "Withdrawal", "messages", "en");
t.add("cantonal.affairs.status.opinion_management", "Opinion by Management", "messages", "en");
t.add("cantonal.affairs.status.opinion_government_council", "Opinion by Executive Council", "messages", "en");
t.add("cantonal.affairs.status.part_invalidation", "Partially declared void", "messages", "en");
t.add("cantonal.affairs.status.referral_as_a_postulate", "Transfer as a postulate", "messages", "en");
t.add("cantonal.affairs.status.transfer_to_the_management", "Transfer to the management \/ council office", "messages", "en");
t.add("cantonal.affairs.status.transfer_to_cantonal_council", "Transfer to Cantonal Council", "messages", "en");
t.add("cantonal.affairs.status.transfer_to_government_council", "Transfer to Executive Council", "messages", "en");
t.add("cantonal.affairs.status.transformation_into_a_postulate", "Conversion into a postulate", "messages", "en");
t.add("cantonal.affairs.status.invalid_declared", "Declared void", "messages", "en");
t.add("cantonal.affairs.status.varia", "Miscellaneous", "messages", "en");
t.add("cantonal.affairs.status.refusal_to_accept_section_25_3_krg", "Refusal to accept (\u00a7 25 para. 3 KRG)", "messages", "en");
t.add("cantonal.affairs.status.provisionally_supported", "Preliminarily supported", "messages", "en");
t.add("cantonal.affairs.status.choice", "Election", "messages", "en");
t.add("cantonal.affairs.status.loss", "Omission", "messages", "en");
t.add("cantonal.affairs.status.resumption", "Resumption", "messages", "en");
t.add("cantonal.affairs.status.approval", "Approval", "messages", "en");
t.add("cantonal.affairs.status.consent_minority_proposal", "Approval of the minority amendment", "messages", "en");
t.add("cantonal.affairs.status.on_session_agenda", "On the agenda in parliament", "messages", "en");
t.add("cantonal.affairs.status.unknown", "Not defined", "messages", "en");
t.add("cantonal.affairs.status.divergent_minority_applications_from_the_commission", "Deviating amendments by the commission minority", "messages", "en");
t.add("cantonal.affairs.status.divergent_requests_from_the_commission", "Deviating commission amendments", "messages", "en");
t.add("cantonal.affairs.status.interim_report", "Interim report", "messages", "en");
t.add("cantonal.affairs.status.different_applications", "Deviating amendments", "messages", "en");
t.add("cantonal.affairs.status.nonoccurrence_request", "Motion to not enter into debate", "messages", "en");
t.add("cantonal.affairs.status.activity_report_of_financial_control", "Activity report of the financial control", "messages", "en");
t.add("cantonal.affairs.status.validating", "Declaration of validity", "messages", "en");
t.add("cantonal.affairs.status.enter", "Entry debate", "messages", "en");
t.add("cantonal.affairs.status.final_vote", "Final Vote", "messages", "en");
t.add("cantonal.affairs.status.transfer_to_commission", "Transfer to commission", "messages", "en");
t.add("cantonal.affairs.status.commission_report", "Commission report", "messages", "en");
t.add("cantonal.affairs.status.resignation", "Resignation", "messages", "en");
t.add("cantonal.affairs.status.moved_up", "Replacing \/ succeeding", "messages", "en");
t.add("cantonal.affairs.status.approved_with_changes", "Approval with changes", "messages", "en");
t.add("cantonal.affairs.status.session_agenda", "added to Agenda \/ Session programme", "messages", "en");
t.add("cantonal.affairs.status.transfer", "Transfer", "messages", "en");
t.add("cantonal.affairs.status.unmapped", "New Event", "messages", "en");
t.add("cantonal.affairs.status.shift_advice_cantonal_council", "Discussion in the Cantonal Council postponed", "messages", "en");
t.add("cantonal.affairs.status.decision_commission", "Decision by commission", "messages", "en");
t.add("cantonal.affairs.status.depreciation_rejected", "Abandonment rejected", "messages", "en");
t.add("cantonal.affairs.status.embassy_submission_government_council", "Message \/ Proposal Executive Council", "messages", "en");
t.add("cantonal.affairs.status.decision_cantonal_council_grossrat_landrat", "Decision by Grand Council \/ Cantonal Council", "messages", "en");
t.add("cantonal.affairs.status.come_in_1st_reading", "Entry debate, 1st reading", "messages", "en");
t.add("cantonal.affairs.status.part_significantly_declaration", "Partially declared substantial", "messages", "en");
t.add("cantonal.affairs.status.commission_appointed", "Commission ordered", "messages", "en");
t.add("cantonal.affairs.status.referendum_deadline", "Referendum deadline ongoing", "messages", "en");
t.add("cantonal.affairs.status.referendum_deadline_expired", "Referendum deadline expired", "messages", "en");
t.add("cantonal.affairs.status.non_declaration_significantly", "Delcaration of non-substantiation", "messages", "en");
t.add("cantonal.affairs.status.come_into_effect", "Taking effect", "messages", "en");
t.add("cantonal.affairs.status.plebiscite", "Referendum \/ popular vote", "messages", "en");
t.add("cantonal.affairs.status.report", "Report published", "messages", "en");
t.add("cantonal.affairs.status.nomination", "Election suggestion", "messages", "en");
t.add("cantonal.affairs.status.vote", "Vote", "messages", "en");
t.add("cantonal.affairs.status.faction_request", "Proposal by parliamentary group", "messages", "en");
t.add("cantonal.affairs.status.accepted_vote", "Vote succeeded", "messages", "en");
t.add("cantonal.affairs.status.rejected_vote", "Vote rejected", "messages", "en");
t.add("cantonal.affairs.status.sistiert", "Suspended", "messages", "en");
t.add("cantonal.affairs.status.medienmitteilung", "Press release published", "messages", "en");
t.add("dashboard.updates.itemType.customAffairCustomSpeaker", "Speaker memo", "messages", "en");
t.add("dashboard.updates.itemType.cantonalAffairCustomSpeaker", "Speaker cantonal affair", "messages", "en");
t.add("profile.settings.notificationPreference.keywords.german", "Keywords German", "messages", "en");
t.add("profile.settings.notificationPreference.keywords.french", "Keywords French", "messages", "en");
t.add("profile.settings.notificationPreference.keywords.italian", "Keywords Italian", "messages", "en");
t.add("layout.search.config.save_as", "Save new search template", "messages", "en");
t.add("layout.search.config.save", "Update search template", "messages", "en");
t.add("layout.search.config.form.description", "Save your search settings in a search template:", "messages", "en");
t.add("layout.search.config.form.title.input.label", "Description", "messages", "en");
t.add("layout.search.config.form.title.error.message", "Error while saving search template", "messages", "en");
t.add("layout.search.config.save.form.title", "Update search template", "messages", "en");
t.add("layout.search.config.load", "Search templates", "messages", "en");
t.add("layout.search.config.delete", "Delete search template", "messages", "en");
t.add("layout.search.config.invalid", "Invalid settings for search template", "messages", "en");
t.add("api.search.config.save.success", "Search template successfully saved", "messages", "en");
t.add("api.search.config.save.error", "Error while saving search template", "messages", "en");
t.add("api.search.config.delete.success", "Search template successfully deleted", "messages", "en");
t.add("api.search.config.delete.error", "Error while deleting search template", "messages", "en");
t.add("api.search.config.get.error", "Error while getting search templates", "messages", "en");
t.add("api.search.config.put.success", "Search template successfully saved", "messages", "en");
t.add("api.search.config.put.error", "Error while saving search template", "messages", "en");
t.add("layout.search.config.action.header", "Saved search templates", "messages", "en");
t.add("layout.search.config.action.label", "current search template", "messages", "en");
t.add("layout.search.config.delete.confirm.message", "Do you really want to delete this search template?", "messages", "en");
t.add("profile.settings.company.custom_fields.html.labelEn", "Description EN", "messages", "en");
t.add("profile.settings.company.internal_fields.html.labelEn", "Description EN", "messages", "en");
t.add("profile.settings.company.internal_fields.ratings.labelEn", "Description EN", "messages", "en");
t.add("profile.settings.company.custom_fields.rating.labelEn", "Description EN", "messages", "en");
t.add("cantonal.affairs.type.bot.title", "Message", "messages", "en");
t.add("cantonal.affairs.type.bot.abbr", "Bot", "messages", "en");
t.add("untranslated.original.prefix", "[Original title]", "messages", "en");
t.add("customMeetings.pdf.assignedUsers.label", "Assigned users", "messages", "en");
t.add("affairGroup.export.header.assignedUsers", "Assigned to", "messages", "en");
t.add("affairGroup.export.header.assignedUser", "Assigned to", "messages", "en");
t.add("cantonal.affairs.status.initiationOfConsultation", "Begin of consultation process", "messages", "en");
t.add("cantonal.affairs.status.applicationFromCantonalManagement", "Proposal cantonal management", "messages", "en");
t.add("cantonal.affairs.status.answerFromCantonalManagement", "Answer cantonal management", "messages", "en");
t.add("cantonal.affairs.status.reportFromCantonalManagement", "Report cantonal management", "messages", "en");
t.add("layout.search.affairTypes.national", "Type of affair (national)", "messages", "en");
t.add("layout.search.affairTypes.cantonal", "Type of affair (cantonal)", "messages", "en");
t.add("reports.groups.outputInternalLink.label", "Link to politik.ch", "messages", "en");
t.add("reports.affair.internalLink", "Link politik.ch", "messages", "en");
t.add("profile.settings.company.custom_fields.attachment.add", "New attachment field", "messages", "en");
t.add("affair.internalAttachment.uploadFiles", "Add files", "messages", "en");
t.add("affair.internalAttachment.renameModal.label", "Rename file", "messages", "en");
t.add("affair.internalAttachment.renameModal.description", "What should the new file's name be?", "messages", "en");
t.add("affair.internalAttachment.renameModal.input.label", "New name of the file", "messages", "en");
t.add("affair.internalAttachment.renameModal.error.message", "Error while renaming the files.", "messages", "en");
t.add("affair.internalAttachment.renameModal.button.rename_attachment", "Rename", "messages", "en");
t.add("api.profile.settings.company.internal_fields.attachment.put.success", "File successfully removed.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.attachment.put.error", "Error while removing the files. Please contact our support team.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.attachment.post.success", "File successfully saved.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.attachment.post.error", "Error while saving the files. Please contact our support team.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.attachment.rename.success", "File successfully renamed.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.attachment.rename.error", "Error while renaming the files. Please contact our support team.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.attachments.get.error", "Error while getting the files. Please contact our support team.", "messages", "en");
t.add("profile.settings.company.custom_fields.attachment.labelDe", "Description DE", "messages", "en");
t.add("profile.settings.company.custom_fields.attachment.labelFr", "Description FR", "messages", "en");
t.add("profile.settings.company.custom_fields.attachment.labelIt", "Description IT", "messages", "en");
t.add("profile.settings.company.custom_fields.attachment.labelEn", "Description EN", "messages", "en");
t.add("api.file.upload.error", "Error while uploading the file.", "messages", "en");
t.add("reports.config.form.checkbox_private", "Private template (not visible to others)", "messages", "en");
t.add("councillor.citizenship", "Citizenship", "messages", "en");
t.add("councillor.civil.status", "Civil status", "messages", "en");
t.add("councillor.kids", "Kids", "messages", "en");
t.add("councillor.address", "Address", "messages", "en");
t.add("councillor.website", "Website", "messages", "en");
t.add("profile.settings.company.internal_fields.attachment.labelDe", "Description DE", "messages", "en");
t.add("profile.settings.company.internal_fields.attachment.labelFr", "Description FR", "messages", "en");
t.add("profile.settings.company.internal_fields.attachment.labelIt", "Description IT", "messages", "en");
t.add("profile.settings.company.internal_fields.attachment.labelEn", "Description EN", "messages", "en");
t.add("councillor.military.rank", "Military rank", "messages", "en");
t.add("api.profile.settings.company.internal_fields.attachment.delete.success", "Successfully deleted", "messages", "en");
t.add("reports.config.form.title.error.message", "Error while saving", "messages", "en");
t.add("councillor.personal.details", "Particulars", "messages", "en");
t.add("layout.search.start_search", "Start search", "messages", "en");
t.add("councillor.submitted", "Introduced", "messages", "en");
t.add("councillor.subscribed", "Subscribed", "messages", "en");
t.add("councillor.submitted.proposals", "Proposals introduced", "messages", "en");
t.add("button.show.all", "Show all", "messages", "en");
t.add("councillor.advances.topics", "Topics of proposals", "messages", "en");
t.add("councillor.success.advances", "Success of proposals", "messages", "en");
t.add("councillor.title.topics.success", "Topics and successes", "messages", "en");
t.add("councillor.absences.and.abstentions", "Voting behaviour", "messages", "en");
t.add("councillor.absences.from.votes", "Absences for %count% votes", "messages", "en");
t.add("councillor.parliamentary.group", "parliamentary groups", "messages", "en");
t.add("councillor.actual.title", "Current", "messages", "en");
t.add("councillor.previous.title", "Previous", "messages", "en");
t.add("affairs.assign_users.add_teammembers.button_title", "Assign user", "messages", "en");
t.add("affairs.assign_users.add_teammembers.label", "Assign user", "messages", "en");
t.add("affairs.assign_users.unassign_teammember.button_title", "Remove user assignment", "messages", "en");
t.add("api.affair.assigned_users.get.error", "Assigned users could not be loaded.", "messages", "en");
t.add("affairs.assign_users.unassign_teammember.modal.title", "Are you sure you want to remove following users:", "messages", "en");
t.add("affairs.assign_users.form.title", "Assign user", "messages", "en");
t.add("affairs.assign_users.form.groups_more", "others", "messages", "en");
t.add("api.user.assign_affair.success", "User successfully assigned", "messages", "en");
t.add("api.user.assign_affair.error", "Error when assigning users. Please contact our support team.", "messages", "en");
t.add("reports.groups.outputAssignedUsers.label", "Users assigned", "messages", "en");
t.add("reports.assigned_users.label", "Users assigned", "messages", "en");
t.add("api.basic_users.get.error", "Users could not be loaded. Please contact the support.", "messages", "en");
t.add("affair_list.select.assigned_users.label", "Users assigned", "messages", "en");
t.add("councillor.aTotalOf", "overall", "messages", "en");
t.add("affair_list.select.authors.label", "Author", "messages", "en");
t.add("reports.authors.label", "Author", "messages", "en");
t.add("national.consultations.details.cancelled", "Cancelled Consultations", "messages", "en");
t.add("cantonal.affairs.status.inProgress", "in review \/ under treatment", "messages", "en");
t.add("cantonal.affairs.status.courtDecisions", "Decision by court", "messages", "en");
t.add("cantonal.affairs.status.disagreeWithConversion", "Disagree with conversion", "messages", "en");
t.add("cantonal.affairs.status.meeting", "Meeting", "messages", "en");
t.add("cantonal.affairs.status.treatmentByTheCouncils", "Treatment by the councils", "messages", "en");
t.add("profile.settings.company.custom_fields.affairs_label", "Affairs", "messages", "en");
t.add("profile.settings.company.custom_fields.meetings_label", "Meetings", "messages", "en");
t.add("profile.settings.compay.custom_meeting_html_fields_setting.delete.confirm", "Delete?", "messages", "en");
t.add("profile.settings.company.custom_meetings_html_fields.add", "Add", "messages", "en");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderDe", "Description DE", "messages", "en");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderFr", "Description FR", "messages", "en");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderIt", "Description IT", "messages", "en");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderEn", "Description EN", "messages", "en");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelDe", "Description DE", "messages", "en");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelFr", "Description FR", "messages", "en");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelIt", "Description IT", "messages", "en");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelEn", "Description EN", "messages", "en");
t.add("customMeetings.editMeeting.date.label", "Date \/ Time", "messages", "en");
t.add("customMeetings.editMeeting.subject.label", "Subject", "messages", "en");
t.add("customMeetings.editMeeting.description.label", "Description", "messages", "en");
t.add("customMeetings.editMeeting.location.label", "Location", "messages", "en");
t.add("customMeetings.editMeeting.president.label", "Presidency", "messages", "en");
t.add("customMeetings.editMeeting.protocol.label", "Protocol", "messages", "en");
t.add("customMeetings.editMeeting.guests.label", "Guests", "messages", "en");
t.add("api.meeting.get.error", "Error while getting meetings. Please contact our support team.", "messages", "en");
t.add("layout.custom_meetings.custom_fields", "Details", "messages", "en");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.get.error", "Error while getting internal fields of meetings. Please contact our support team.", "messages", "en");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.put.success", "Saved successfully", "messages", "en");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.put.error", "Error while getting internal fields of meetings. Please contact our support team.", "messages", "en");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.post.success", "Saved successfully", "messages", "en");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.post.error", "Error while saving. Please try again or contact our support team: support@politik.ch", "messages", "en");
t.add("custom_meeting.internalHtmlField.save", "Save", "messages", "en");
t.add("custom_meeting.internalHtmlField.edit", "Edit", "messages", "en");
t.add("custom_meeting.internalHtmlField.error", "Error", "messages", "en");
t.add("cantonal.affairs.status.forProcessingByTheDepartment", "for review by the management", "messages", "en");
t.add("layout.reports.config.save_as", "Save new report template", "messages", "en");
t.add("layout.reports.update.config", "Update report template", "messages", "en");
t.add("reports.affairStates.label", "National affair's status", "messages", "en");
t.add("cantonal.affair.attachment", "Attachment", "messages", "en");
t.add("security.login.auth_msg", "Please enter the code of your Authenticator app.", "messages", "en");
t.add("security.login.trusted", "Trust this device for 30 days", "messages", "en");
t.add("profile.edit.2fa.status", "Status", "messages", "en");
t.add("profile.edit.2fa.activated", "is activated", "messages", "en");
t.add("affairs.actors.and.roles", "Co-signers", "messages", "en");
t.add("affairs.actor.and.role.cosigners", "Co-signer", "messages", "en");
t.add("affairs.actor.and.role.adapters", "Adopters", "messages", "en");
t.add("affairs.actor.and.role.opponents", "Opponents", "messages", "en");
t.add("cantonal.affairs.status.decision", "Decision", "messages", "en");
t.add("cantonal.affairs.status.nominations", "Nomination", "messages", "en");
t.add("event.reminders.button.edit", "Edit notifications", "messages", "en");
t.add("calendar.event.status.share", "Add to calendar", "messages", "en");
t.add("calendar.event.tooltip.unshare", "Remove event from shared calendar", "messages", "en");
t.add("calendar.event.tooltip.share", "Add event to shared calendar", "messages", "en");
t.add("calendar.share.error", "Error when sharing the calendar. Please try again or contact our support team: support@politik.ch", "messages", "en");
t.add("calendar.settings.title", "Shared Calendar Settings", "messages", "en");
t.add("calendar.settings.status.enabled", "Enable shared calendar", "messages", "en");
t.add("calendar.settings.status.disabled", "Disable shared calendar", "messages", "en");
t.add("calendar.settings.placeholder", "To see the address of the shared calendar, you must first activate it.", "messages", "en");
t.add("profile.settings.navigation.calendar", "Shared calendar", "messages", "en");
t.add("ui.link.copy", "Copy link", "messages", "en");
t.add("ui.link.copied", "Link copied", "messages", "en");
t.add("affair.events.custom.attachment.post.error", "Error while uploading the file. Please try again or contact our support team: support@politik.ch", "messages", "en");
t.add("affairs.customEvents.fields.note.attachments", "Files", "messages", "en");
t.add("affairs.customEvents.fields.note.uploadFiles", "Upload file", "messages", "en");
t.add("national.parliamentaryGroup.membership.function.president", "President", "messages", "en");
t.add("national.parliamentaryGroup.membership.function.coPresident", "Co-President", "messages", "en");
t.add("national.parliamentaryGroup.membership.function.member", "Member", "messages", "en");
t.add("interests.tooltip.organ.function", "Organ \/ Function", "messages", "en");
t.add("councillor.interests.title", "Ties of interest", "messages", "en");
t.add("councillor.title.current.mandate", "Current parliamentary mandates", "messages", "en");
t.add("councillors.interests.paid", "paid", "messages", "en");
t.add("councillors.interests.unpaid", "unpaid", "messages", "en");
t.add("national.notifications.preferences.label", "Specify whether you want to receive notifications about the federal affairs, and if so, which ones.", "messages", "en");
t.add("cantonal.notifications.preferences.label", "Specify whether you want to receive notifications about the cantonal affairs, and if so, which ones.", "messages", "en");
t.add("get.national.notifications.label", "Receive notifications for the federal government", "messages", "en");
t.add("get.cantonal.notifications.label", "Receive notifications for the cantons", "messages", "en");
t.add("search.terms.notifications.preferences", "Notify me only about my search terms", "messages", "en");
t.add("search.terms.restore", "Reset", "messages", "en");
t.add("search.terms.page.title", "Keywords", "messages", "en");
t.add("search.terms.page.define.message", "Define all your relevant search terms here. We will notify you whenever they appear in new affairs or updates.", "messages", "en");
t.add("search.terms.select.all.types", "Select all types", "messages", "en");
t.add("attachment.delete.confirm", "Are you sure you want to delete this attachment?", "messages", "en");
t.add("cantonal.affairs.status.answerCovenant", "Reply federation", "messages", "en");
t.add("councillor.parliamentary.groups", "Parliamentary groups", "messages", "en");
t.add("councillor.former.commissions", "Previous parliamentary mandates", "messages", "en");
t.add("councillor.proposals.submitted", "Submitted", "messages", "en");
t.add("api.links.delete.success", "The link between the affairs has been deleted.", "messages", "en");
t.add("api.links.delete.error", "Error while deleting linked affairs. Please try again.", "messages", "en");
t.add("groups.link.to_unarchived_groups.title", "To the groups", "messages", "en");
t.add("groups.link.to_archived_groups.title", "To the archived groups", "messages", "en");
t.add("affairGroups.unarchive", "Restore Group", "messages", "en");
t.add("affairGroup.unarchive.confirm", "Are you sure that you want to restore this group?", "messages", "en");
t.add("api.groups.put.unarchive.success", "The group has been successfully restored.", "messages", "en");
t.add("api.groups.put.unarchive.error", "An error occurred while restoring the group. Please try again.", "messages", "en");
t.add("api.groups.put.archive.success", "The group has been archived.", "messages", "en");
t.add("api.groups.put.archive.error", "The group could not be archived. Please try again.", "messages", "en");
t.add("customMeetings.unarchive", "Restore Meeting", "messages", "en");
t.add("api.meetings.put.unarchive.success", "The meeting has been successfully restored.", "messages", "en");
t.add("api.meetings.put.unarchive.error", "The meeting could not be restored. Please try again.", "messages", "en");
t.add("api.meetings.put.archive.success", "The meeting has been archived.", "messages", "en");
t.add("api.meetings.put.archive.error", "The meeting could not be archived. Please try again.", "messages", "en");
t.add("customMeetings.link.to_unarchived_groups.title", "To the meetings", "messages", "en");
t.add("customMeetings.link.to_archived_groups.title", "To the archived meetings", "messages", "en");
t.add("calendar.modal.text.not.shared", "The event has been added to your shared calendar. To see it, you need to turn on the Shared Calendar.\u2028 You can do this now or later, under \"Your name\" > User account > Shared calendar.", "messages", "en");
t.add("calendar.modal.not.active", "Shared Calendar ist not activated", "messages", "en");
t.add("calendar.modal.activate.later", "Activate later", "messages", "en");
t.add("calendar.modal.activate.now", "Take me to the settings", "messages", "en");
t.add("profile.settings.nationalNotificationPreferences.pressRelease", "Press releases", "messages", "en");
t.add("affair.councilStatesDebate.tooltip.caption", "Council of States debates", "messages", "en");
t.add("cosigners.empty.list", "This affair has no co-signers", "messages", "en");
t.add("search.terms.apply.all.cantons", "Apply to all cantons", "messages", "en");
t.add("cantons.settings.reset.confirm", "This will overwrite the settings for all the cantons. Do you want to proceed?", "messages", "en");
t.add("councillor.phone", "Phone number", "messages", "en");
t.add("cantonal.overview.government", "Government", "messages", "en");
t.add("api.government.author.get.error", "Error while getting government author.", "messages", "en");
t.add("custom.affairs.form.edit.title", "Edit internal affair", "messages", "en");
t.add("affair.actions_card.attachments_title", "Attachments", "messages", "en");
t.add("affair.actions_card.visual_report_section_title", "Visual Report", "messages", "en");
t.add("api.profile.settings.company.internal_fields.builtin.get.error", "Error while reading. Please contact our support team.", "messages", "en");
t.add("affair.builtin_fields.time_axis.beginning_label", "At the beginning", "messages", "en");
t.add("affair.builtin_fields.time_axis.end_label", "Close to end", "messages", "en");
t.add("affair.builtin_fields.need_for_action.low_prio_label", "Monitoring", "messages", "en");
t.add("affair.builtin_fields.need_for_action.high_prio_label", "Urgent need for action", "messages", "en");
t.add("api.profile.settings.company.internal_fields.ratings.put.sucess", "The field has been updated successfully.", "messages", "en");
t.add("api.profile.settings.company.internal_fields.ratings.post.sucess", "The field has been saved successfully.", "messages", "en");
t.add("reports.visual_report", "Visual Report", "messages", "en");
t.add("reports.visual_report.title", "Create a Visual Report", "messages", "en");
t.add("reports.sections.visible_internal_fields.title", "Which internal data would you like to make visible?", "messages", "en");
t.add("reports.sections.visible_internal_fields.description", "Please do not share sensible data. This report will be visible over a public URL and your internal data will not be protected. The internal data fields \"Status\", \"Urgency\" and \"Impact\" are mandatory and will be needed to build the Visual Report. The internal fields you select as filters will also be shown by default.", "messages", "en");
t.add("reports.sections.visual_report_filter_fields.title", "Which custom fields would you like to use as filters? (max. 2 filters)", "messages", "en");
t.add("reports.sections.make_report_public.toggle.title", "Make this report public", "messages", "en");
t.add("reports.sections.save_report.title", "Save the report", "messages", "en");
t.add("form.visual_report.submit", "Save Visual Report", "messages", "en");
t.add("reports.save_visual_report.error", "The Visual Report could not be saved. Please try again.", "messages", "en");
t.add("reports.save_visual_report.success_modal.title", "The Visual Report has been created successfully.", "messages", "en");
t.add("reports.save_visual_report.success_modal.description", "The report <strong>%reportName%<\/strong> was successfully created with <strong>%numberOfGroups% groups<\/strong> and a total of <strong>%numberOfAffairs% affairs<\/strong>.", "messages", "en");
t.add("reports.save_visual_report.success_modal.public_url", "Public URL:", "messages", "en");
t.add("reports.save_visual_report.success_modal.copy_url_button.title", "Copy URL", "messages", "en");
t.add("reports.save_visual_report.success_modal.open_report_button.title", "Open report in new window", "messages", "en");
t.add("internalFieldDefinitions.builtIn.size.low.label", "Low", "messages", "en");
t.add("internalFieldDefinitions.builtIn.size.medium.label", "Medium", "messages", "en");
t.add("internalFieldDefinitions.builtIn.size.high.label", "High", "messages", "en");
t.add("internalFieldDefinitions.builtIn.size.veryHigh.label", "Very high", "messages", "en");
t.add("cantonal.affairs.status.changeRequest", "Change request", "messages", "en");
t.add("cantonal.affairs.status.cantonalInitiativeSubmission", "Cantonal initiative submission", "messages", "en");
t.add("vote.national_council.error", "National Council votes could not be loaded. Please try again.", "messages", "en");
t.add("vote.council_of_states.error", "Council of States votes could not be loaded. Please try again.", "messages", "en");
t.add("groups.tree.group.openGroupDetail", "Go to group page", "messages", "en");
t.add("reports.save_visual_report.success_modal.copied_to_clipboard", "Link copied to clipboard", "messages", "en");
t.add("committee.private.affairs", "Private Affairs", "messages", "en");
t.add("reports.visual_report.not_complete_affairs", "The report contains affairs for which no data on relevance, status or need for action has been defined.", "messages", "en");
t.add("reports.visual_report.exceeding_limit", "There are more than 35 affairs in the report. We recommend you to limit your selection for a better visualization.", "messages", "en");
t.add("reports.visual_report.create_anyway", "Ignore and continue", "messages", "en");
t.add("reports.visual_report.back_to_reports", "Back to the report settings", "messages", "en");
t.add("reports.sections.make_report_public.title", "Would you like to make this report public now?", "messages", "en");
t.add("reports.sections.make_report_public.description", "This Visual Report will be visible to everyone you shared the public URL with. You can change this setting any time.", "messages", "en");
t.add("reports.sections.report_name.title", "What is the name for your Visual Report?", "messages", "en");
t.add("reports.report_name_input.label", "Report name", "messages", "en");
t.add("api.reports.visual_repor_config.get.error", "Recent Visual Reports could not be loaded. Please try again.", "messages", "en");
t.add("reports.visual_report_config.delete.confirm.message", "Are you sure you want to delete this visual report?", "messages", "en");
t.add("api.reports.visual_report_config.delete.success", "Visual Report has been deleted.", "messages", "en");
t.add("api.reports.visual_report_config.delete.error", "The Visual Report could not be deleted. Please try again.", "messages", "en");
t.add("reports.load_visual_report.error", "The Visual Report could not be opened. Please try again.", "messages", "en");
t.add("reports.update_visual_report.error", "The Visual Report could not be updated. Please try again.", "messages", "en");
t.add("reports.update_visual_report.success", "The Visual Report has been updated.", "messages", "en");
t.add("cantonal.affairs.status.opinion", "Opinion", "messages", "en");
t.add("internalFieldDefinitions.builtIn.y.label", "Need for action", "messages", "en");
t.add("internalFieldDefinitions.builtIn.x.label", "Progress", "messages", "en");
t.add("internalFieldDefinitions.builtIn.size.label", "Relevance", "messages", "en");
t.add("visualiser.beginning", "At the beginning", "messages", "en");
t.add("visualiser.end", "Close to end", "messages", "en");
t.add("visualiser.monitoring", "Monitoring", "messages", "en");
t.add("visualiser.urgency", "Urgent need for action", "messages", "en");
t.add("dashboard.newest_affairs", "Newest Affairs", "messages", "en");
t.add("dashboard.updated_affairs", "Updates", "messages", "en");
t.add("dashboard.assigned_affairs", "Assigned", "messages", "en");
t.add("dashboard.team_actions", "Team Updates", "messages", "en");
t.add("shared.affair.type", "Type of affair", "messages", "en");
t.add("shared.affair.lastupdate", "Last update", "messages", "en");
t.add("shared.affair.linkedaffairs", "Linked affairs", "messages", "en");
t.add("shared.further.info", "Further information", "messages", "en");
t.add("shared.affair.linkedaffairs.none", "No linked affairs", "messages", "en");
t.add("reports.visual_report_menu.header", "Recent Visual Reports", "messages", "en");
t.add("cantonal.affairAuthorRole.signer", "First signer", "messages", "en");
t.add("cantonal.affairAuthorRole.co_signer", "Co-signatories", "messages", "en");
t.add("cantonal.affairAuthorRole.commission", "Pre-consultant commission", "messages", "en");
t.add("consultation.submittedByOn.exist", "Submitted by %councillors%. Opened on %date%", "messages", "en");
t.add("consultation.submittedByOn.none", "Submitted by %councillors%. Opened on %date%", "messages", "en");
t.add("shared.affair.status.none", "Status not available", "messages", "en");
t.add("customMeetings.tree.customMeeting.openMeeting", "Go to meeting page", "messages", "en");
t.add("cantonal.affairs.status.urgentInterpellation", "Urgent interpellation", "messages", "en");
t.add("shared.affair.affairGroups", "Group", "messages", "en");
t.add("reports.save_visual_report.success_copied_url_button.title", "Copied!", "messages", "en");
t.add("affair.customShortTitle.information", "Max. 40 characters", "messages", "en");
t.add("customMeetings.unarchive.confirm", "Do you want to restore the custom meeting?", "messages", "en");
t.add("cantonal.affairs.status.removedFromSource", "Affair was deleted on the cantonal website", "messages", "en");
t.add("internalFieldDefinitions.builtIn.shortTitle.label", "Short title", "messages", "en");
t.add("share.filters.groups", "Groups", "messages", "en");
t.add("dashboard.updates.item_topics", "Topics", "messages", "en");
t.add("shared.filters.show.all", "Hide all", "messages", "en");
t.add("shared.filters.hide.all", "Show all", "messages", "en");
t.add("dashboard.newest_affairs.tooltip", "All affairs imported during the last 7 days, matching your search terms. If you didn't have set up any search terms so far we show you at least the 20 most recent affairs", "messages", "en");
t.add("dashboard.updated_affairs.tooltip", "All updates from the last 4 weeks, of the affairs you are following or which are added to a group that you are following", "messages", "en");
t.add("dashboard.assigned_affairs.tooltip", "All affairs which are assigned to you right now", "messages", "en");
t.add("dashboard.team_actions.tooltip", "Internal affairs created inside your organisation and affairs added to a group of your organisation", "messages", "en");
t.add("national.affairs.list.header.lastEventDate", "End date", "messages", "en");
t.add("dashboard.custom.tooltip", "Customised Quicklink", "messages", "en");
t.add("dashboard.quicklinks.failed_to_save", "Quicklink could not be saved. Please try again.", "messages", "en");
t.add("dashboard.quicklinks.failed_to_fetch", "Quicklink could not be opened. Please try again.", "messages", "en");
t.add("dashboard.edit_customize", "Edit", "messages", "en");
t.add("dashboard.restore_default", "Restore Default", "messages", "en");
t.add("dashboard.restore.default", "Do you want to reset the Quicklink to the default settings? This cannot be undone.", "messages", "en");
t.add("dashboard.edit.shortcut", "Edit custom Quicklink", "messages", "en");
t.add("dashboard.custom.quicklink.name", "Quicklink name", "messages", "en");
t.add("dashboard.custom.quicklink.search.template", "Select a search template", "messages", "en");
t.add("dashboard.custom.quicklink.icon", "Icon", "messages", "en");
t.add("dashboard.custom.quicklink.show_updates", "Show updates only", "messages", "en");
t.add("dashboard.custom.quicklink.show_all", "Show all Affairs", "messages", "en");
t.add("national.swiss.affairs.consultations.caption", "Switzerland", "messages", "en");
t.add("share.filters", "Filters", "messages", "en");
t.add("dashboard.custom.quicklink.no.search.template", "No Search Template available", "messages", "en");
t.add("cantonal.affairs.status.addedToCommitteeProgram", "Included in the commission program", "messages", "en");
t.add("cantonal.affairs.status.initiativeToTheFederalAssembly", "Initiative to the Federal Assembly", "messages", "en");
t.add("cantonal.affairs.status.annualReport", "Annual report", "messages", "en");
t.add("affair.event.pressRelease.link.tooltip", "Open original source", "messages", "en");
t.add("pages.access_restricted.no_permission", "No permission to access the content of this page", "messages", "en");
t.add("pages.access_restricted.contact_admin", "Please contact your company admin for help.", "messages", "en");
t.add("pages.access_restricted.interested_in_access", "If you are interested in a license for politik.ch, it would be a pleasure for us to give you a presentation and invite you to try our product.", "messages", "en");
t.add("pages.access_restricted.contact_us", "Please contact us via email at info@politik.ch or via phone (+41) 044 512 12 60.", "messages", "en");
t.add("cantonal.affairs.status.swearingIn", "Swearing-in", "messages", "en");
t.add("shared.filters.show.linked_affairs", "Show all links between affairs", "messages", "en");
t.add("reports.sections.report_name.error.name_exists", "A report with this name already exists. Please choose another name.", "messages", "en");
t.add("dashboard.table.type.insert", "Insert", "messages", "en");
t.add("dashboard.table.type.update", "Update", "messages", "en");
t.add("dashboard.table.type.remove", "Remove", "messages", "en");
t.add("layout.search.type.allAffairs", "All Affairs", "messages", "en");
t.add("layout.search.config.delete.exists_in_dashboard.message", "This search template is used for one Quicklink of the Dashboard. Are you sure you want to delete it? The default Quicklink will then be restored for the Dashboard.", "messages", "en");
t.add("shared.affair.commissions", "authoring commission", "messages", "en");
t.add("shared.affair.departments", "Department responsible", "messages", "en");
t.add("shared.affair.agenda", "On the agenda", "messages", "en");
t.add("shared.affair.description", "Introduced text", "messages", "en");
t.add("visualizer_intro.label", "Visual Report Intro-Text", "messages", "en");
t.add("visualizer_intro_title.label", "Title of the Visual Report Intro-Text", "messages", "en");
t.add("reports.sections.sessions.title", "Which session should this report refer to?", "messages", "en");
t.add("customField.setings.affairType.label", "Used in Affair Types", "messages", "en");
t.add("internalFields.TextField.label", "Text field", "messages", "en");
t.add("internalFields.OptionsField.label", "Option field", "messages", "en");
t.add("internalFields.AttachmentField.label", "Attached files", "messages", "en");
t.add("layout.affair.stat.label", "Affair state", "messages", "en");
t.add("layout.affair.latestEvent.name", "Title of latest event", "messages", "en");
t.add("reports.sessionProgram.council.notDefined", "Primary Council not defined", "messages", "en");
t.add("api.profile.settings.company.internal_fields.html.delete.success", "The custom field has been deleted", "messages", "en");
t.add("api.profile.settings.company.internal_fields.html.delete.error", "An error occurred while trying to delete the custom field. Please try again.", "messages", "en");
t.add("panel.additionalInfoLink.label", "Details", "messages", "en");
t.add("outputInternalAffairAuthors", "internal author (creator)", "messages", "en");
t.add("outputAuthors", "Author", "messages", "en");
t.add("reports.sections.show_agenda.toggle.title", "Show agenda for selected session", "messages", "en");
t.add("shared.agenda.session_program.title", "Affairs which are on the agenda of or treated during the session:", "messages", "en");
t.add("shared.agenda.deposited.title", "Deposit Affairs of the Session:", "messages", "en");
t.add("shared.agenda.confirm_dialog.title", "Affair can not be shown", "messages", "en");
t.add("shared.agenda.confirm_dialog.description", "This affair was scheduled or treated during the session. But the autor of this report did not added any information about relevance and timeline to the affair so far so it is not shown in the visual report. This data can only be added by the author of the report.", "messages", "en");
t.add("shared.agenda.confirm_dialog.button.text", "I understand!", "messages", "en");
t.add("shared.agenda.deposited.no_data_available.text", "None of the shown affairs was submitted during the session.", "messages", "en");
t.add("shared.agenda.session_program.no_data_available.text", "None of the affairs is scheduled for the selected Session or was treated during the Session.", "messages", "en");
t.add("reports.groups.outputInternalAffairAuthors.label", "Author of Custom Affairs", "messages", "en");
t.add("affair.SRDebateEvent.tooltip.caption", "Debate Council of States", "messages", "en");
t.add("cantonal.affairs.status.replyDemanded", "Reply demanded", "messages", "en");
t.add("affair.SRCouncilVote.tooltip.caption", "Voting", "messages", "en");
t.add("affair.NationalDebateEvent.tooltip.caption", "Debate National Council", "messages", "en");
t.add("affair.NRCouncilVote.tooltip.caption", "Vote National Council", "messages", "en");
t.add("custom.affairs.form.link", "Source Link", "messages", "en");
t.add("affair.NationalAffairConsultationNoteCustomEvent.tooltip.caption", "Internal memo", "messages", "en");
t.add("affair.CantonalAffairConsultationNoteCustomEvent.tooltip.caption", "Internal memo", "messages", "en");
t.add("cantonal.affairs.status.postponed", "Postponed", "messages", "en");
t.add("api.links.post.success", "Affairs linked successfully", "messages", "en");
t.add("cantonal.affairs.type.regbes.title", "Government Decision", "messages", "en");
t.add("cantonal.media_releases.title", "Media Releases", "messages", "en");
t.add("cantonal.media_releases.table.header.title", "Title", "messages", "en");
t.add("cantonal.media_releases.table.header.date", "published date", "messages", "en");
t.add("cantonal.media_releases.table.header.link", "Link to Source", "messages", "en");
t.add("cantonal.overview.mediaRelease", "Press releases", "messages", "en");
t.add("api.cantonal_media_release.get.error", "Press release could not be loaded", "messages", "en");
t.add("notification.tableOfContents.cantonalMediaRelease.new", "New press release published", "messages", "en");
t.add("notification.tableOfContents.cantonalMediaRelease.updated", "Press release updated", "messages", "en");
t.add("cantonal.mediaRelease.itemAdded", "Media Release published", "messages", "en");
t.add("cantonal.affairs.type.regbes.abbr", "Government Decision", "messages", "en");
t.add("national.affairs.author.published.by", "Published by", "messages", "en");
t.add("helpScout.suggestedForYou", "Instant Answers", "messages", "en");
t.add("helpScout.getInTouch", "Get in touch", "messages", "en");
t.add("helpScout.searchLabel", "What can we help you with?", "messages", "en");
t.add("helpScout.tryAgain", "Try again", "messages", "en");
t.add("helpScout.defaultMessageErrorText", "There was a problem sending your message. Please try again in a moment.", "messages", "en");
t.add("helpScout.beaconButtonClose", "Close", "messages", "en");
t.add("helpScout.beaconButtonChatMinimize", "Minimise chat", "messages", "en");
t.add("helpScout.beaconButtonChatOpen", "Open chat", "messages", "en");
t.add("helpScout.answer", "Answers", "messages", "en");
t.add("helpScout.ask", "Ask", "messages", "en");
t.add("helpScout.messageButtonLabel", "Email", "messages", "en");
t.add("helpScout.noTimeToWaitAround", "No time to wait around? We usually respond within a few hours", "messages", "en");
t.add("helpScout.chatButtonLabel", "Chat", "messages", "en");
t.add("helpScout.chatButtonDescription", "We\u2019re online right now, talk with our team in real-time", "messages", "en");
t.add("helpScout.wereHereToHelp", "Start a conversation", "messages", "en");
t.add("helpScout.whatMethodWorks", "What channel do you prefer?", "messages", "en");
t.add("helpScout.previousMessages", "Previous Conversations", "messages", "en");
t.add("helpScout.cantFindAnswer", "Can\u2019t find an answer?", "messages", "en");
t.add("helpScout.relatedArticles", "Related Articles", "messages", "en");
t.add("helpScout.nothingFound", "Hmm\u2026", "messages", "en");
t.add("helpScout.docsSearchEmptyText", "We couldn\u2019t find any articles that match your search.", "messages", "en");
t.add("helpScout.tryBroaderTerm", "Try searching a broader term, or", "messages", "en");
t.add("helpScout.docsArticleErrorText", "There was a problem retrieving this article. Please double-check your internet connection and try again.", "messages", "en");
t.add("helpScout.docsSearchErrorText", "There was a problem retrieving articles. Please double-check your internet connection and try again.", "messages", "en");
t.add("helpScout.escalationQuestionFeedback", "Did this answer your question?", "messages", "en");
t.add("helpScout.escalationQuestionFeedbackNo", "No", "messages", "en");
t.add("helpScout.escalationQuestionFeedbackYes", "Yes", "messages", "en");
t.add("helpScout.escalationSearchText", "Browse our help docs for an answer to your question", "messages", "en");
t.add("helpScout.escalationSearchTitle", "Keep searching", "messages", "en");
t.add("helpScout.escalationTalkText", "Talk with a friendly member of our support team", "messages", "en");
t.add("helpScout.escalationTalkTitle", "Talk to us", "messages", "en");
t.add("helpScout.escalationThanksFeedback", "Thanks for the feedback", "messages", "en");
t.add("helpScout.escalationWhatNext", "What next?", "messages", "en");
t.add("helpScout.sendAMessage", "Send a message", "messages", "en");
t.add("helpScout.firstAFewQuestions", "Let\u2019s begin with a few questions", "messages", "en");
t.add("helpScout.howCanWeHelp", "How can we help?", "messages", "en");
t.add("helpScout.responseTime", "We usually respond in a few hours", "messages", "en");
t.add("helpScout.history", "History", "messages", "en");
t.add("helpScout.uploadAnImage", "Upload an image", "messages", "en");
t.add("helpScout.attachAFile", "Attach a file", "messages", "en");
t.add("helpScout.continueEditing", "Continue writing\u2026", "messages", "en");
t.add("helpScout.lastUpdated", "Last updated", "messages", "en");
t.add("helpScout.you", "You", "messages", "en");
t.add("helpScout.nameLabel", "Name", "messages", "en");
t.add("helpScout.subjectLabel", "Subject", "messages", "en");
t.add("helpScout.emailLabel", "Email address", "messages", "en");
t.add("helpScout.messageLabel", "How can we help?", "messages", "en");
t.add("helpScout.messageSubmitLabel", "Send a message", "messages", "en");
t.add("helpScout.next", "Next", "messages", "en");
t.add("helpScout.weAreOnIt", "We\u2019re on it!", "messages", "en");
t.add("helpScout.messageConfirmationText", "You\u2019ll receive an email reply within a few hours.", "messages", "en");
t.add("helpScout.viewAndUpdateMessage", "You can view and update your message in", "messages", "en");
t.add("helpScout.mayNotBeEmpty", "May not be empty", "messages", "en");
t.add("helpScout.customFieldsValidationLabel", "Please complete all fields", "messages", "en");
t.add("helpScout.emailValidationLabel", "Please use a valid email address", "messages", "en");
t.add("helpScout.attachmentErrorText", "There was a problem uploading your attachment. Please try again in a moment.", "messages", "en");
t.add("helpScout.attachmentSizeErrorText", "Attachments may be no larger than 10MB", "messages", "en");
t.add("helpScout.addReply", "Add a reply", "messages", "en");
t.add("helpScout.addYourMessageHere", "Add your message here...", "messages", "en");
t.add("helpScout.sendMessage", "Send message", "messages", "en");
t.add("helpScout.received", "Received", "messages", "en");
t.add("helpScout.waitingForAnAnswer", "Waiting for an answer", "messages", "en");
t.add("helpScout.previousMessageErrorText", "There was a problem retrieving this message. Please double-check your Internet connection and try again.", "messages", "en");
t.add("helpScout.justNow", "Just Now", "messages", "en");
t.add("helpScout.chatHeadingTitle", "Chat with our team", "messages", "en");
t.add("helpScout.chatHeadingSublabel", "We\u2019ll be with you soon", "messages", "en");
t.add("helpScout.chatEndCalloutHeading", "All done!", "messages", "en");
t.add("helpScout.chatEndCalloutMessage", "A copy of this conversation will land in your inbox shortly.", "messages", "en");
t.add("helpScout.chatEndCalloutLink", "Return home", "messages", "en");
t.add("helpScout.chatEndUnassignedCalloutHeading", "Sorry about that", "messages", "en");
t.add("helpScout.chatEndUnassignedCalloutMessage", "It looks like nobody made it to your chat. We\u2019ll send you an email response as soon as possible.", "messages", "en");
t.add("helpScout.chatEndWaitingCustomerHeading", "Sorry about that", "messages", "en");
t.add("helpScout.chatEndWaitingCustomerMessage", "Your question has been added to our email queue and we\u2019ll get back to you shortly.", "messages", "en");
t.add("helpScout.ending", "Ending...", "messages", "en");
t.add("helpScout.endChat", "End chat", "messages", "en");
t.add("helpScout.chatEnded", " ended the chat", "messages", "en");
t.add("helpScout.chatConnected", "Connected to", "messages", "en");
t.add("helpScout.chatbotName", "Help Bot", "messages", "en");
t.add("helpScout.chatbotGreet", "Hi there! You can begin by asking your question below. Someone will be with you shortly.", "messages", "en");
t.add("helpScout.chatbotPromptEmail", "Got it. Real quick, what\u2019s your email address? We\u2019ll use it for any follow-up messages.", "messages", "en");
t.add("helpScout.chatbotConfirmationMessage", "Thanks! Someone from our team will jump into the chat soon.", "messages", "en");
t.add("helpScout.chatbotGenericErrorMessage", "Something went wrong sending your message, please try again in a few minutes.", "messages", "en");
t.add("helpScout.chatbotInactivityPrompt", "Since the chat has gone idle, I\u2019ll end this chat in a few minutes.", "messages", "en");
t.add("helpScout.chatbotInvalidEmailMessage", "Looks like you\u2019ve entered an invalid email address. Want to try again?", "messages", "en");
t.add("helpScout.chatbotAgentDisconnectedMessage", " has disconnected from the chat. It\u2019s possible they lost their internet connection, so I\u2019m looking for someone else to take over. Sorry for the delay!", "messages", "en");
t.add("helpScout.chatAvailabilityChangeMessage", "Our team\u2019s availability has changed and there\u2019s no longer anyone available to chat. Send us a message instead and we\u2019ll get back to you.", "messages", "en");
t.add("helpScout.emailHeading", "Today\u2019s chat with", "messages", "en");
t.add("helpScout.emailGreeting", "Hey", "messages", "en");
t.add("helpScout.emailCopyOfDiscussion", "Here\u2019s a copy of your discussion", "messages", "en");
t.add("helpScout.emailContinueConversation", "If you\u2019ve got any other questions, feel free to hit reply and continue the conversation.", "messages", "en");
t.add("helpScout.emailJoinedLineItem", " joined the chat", "messages", "en");
t.add("helpScout.emailEndedLineItem", " ended the chat", "messages", "en");
t.add("helpScout.emailYou", "You", "messages", "en");
t.add("affairs.followingHaveBeenUpdated", "The following affairs have been updated", "email", "en");
t.add("national.affairs.followingHaveBeenUpdated", "The following national affairs have been updated", "email", "en");
t.add("newAffairs.subject", "politik.ch - updates cantonal affairs", "email", "en");
t.add("notification.openingGreeting", "Hello", "email", "en");
t.add("votes.followingHaveBeenAdded", "The following votes have been added to politik.ch", "email", "en");
t.add("votes.followingHaveBeenUpdated", "The following votes have been updated on politik.ch", "email", "en");
t.add("votes.votedOn", "voted on", "email", "en");
t.add("readMoreOn", "Read more about this here", "email", "en");
t.add("affairs.followingHaveBeenAdded", "The following affairs have been added to politik.ch", "email", "en");
t.add("affairs.cantonal.itemAdded", "Canton %abbr%: %title% (%type%)", "email", "en");
t.add("national.affairs.followingHaveBeenAdded", "The following affairs have been added to politik.ch", "email", "en");
t.add("national.affairs.itemAdded", "%idParlService%: %title% (%type%)", "email", "en");
t.add("affairGroups.followingHaveBeenAdded", "The following groups have been added to politik.ch", "email", "en");
t.add("affairGroups.followingHaveBeenUpdated", "The following groups have been updated", "email", "en");
t.add("affairGroups.itemAdded", "%title%", "email", "en");
t.add("national.affairs.new.subject", "politik.ch - updates national affairs", "email", "en");
t.add("national.affairConsultations.followingHaveBeenAdded", "The following national consultations have been added", "email", "en");
t.add("national.affairConsultations.followingHaveBeenUpdated", "The following national consultations have been updated", "email", "en");
t.add("national.affairConsultations.itemAdded", "%title%", "email", "en");
t.add("national.affairConsultations.new.subject", "politik.ch - New consultations", "email", "en");
t.add("national.votes.nationalcouncil.new.subject", "politik.ch - New votes National Council", "email", "en");
t.add("national.votes.councilstates.new.subject", "politik.ch - New votes Council of States", "email", "en");
t.add("eventReminders.heading", "We'd like to remind you of the following events", "email", "en");
t.add("eventReminders.new.subject", "politik.ch - New reminders", "email", "en");
t.add("eventReminders.link.label", "Reminder on the %eventTitle% am %dateTime%", "email", "en");
t.add("notification.operation.add", "added", "email", "en");
t.add("notification.operation.replace", "replaced", "email", "en");
t.add("notification.operation.remove", "Deleted", "email", "en");
t.add("notification.author_full_name", "Author", "email", "en");
t.add("notification.title_de", "Title GE", "email", "en");
t.add("notification.title_fr", "Title FR", "email", "en");
t.add("notification.title_it", "Title IT", "email", "en");
t.add("notification.description_de", "Description DE", "email", "en");
t.add("notification.description_fr", "Description FR", "email", "en");
t.add("notification.description_it", "Description IT", "email", "en");
t.add("notification.reason_de", "Justification GE", "email", "en");
t.add("notification.reason_fr", "Justification FR", "email", "en");
t.add("notification.reason_it", "Justification IT", "email", "en");
t.add("notification.short_id_parl_service", "parlamentary services ID", "email", "en");
t.add("notification.affair_type", "Type", "email", "en");
t.add("notification.priority_council", "Council", "email", "en");
t.add("notification.affair_state", "Status", "email", "en");
t.add("notification.national_council_votes", "Vote National Council", "email", "en");
t.add("notification.council_states_votes", "Vote Council of States", "email", "en");
t.add("notification.history_events", "Event", "email", "en");
t.add("notification.history_events.status", "Status event", "email", "en");
t.add("notification.history_events.council", "Event: Council", "email", "en");
t.add("notification.history_events.status_text", "Text event", "email", "en");
t.add("notification.history_events.date_time", "Date\/Time event", "email", "en");
t.add("notification.history_events.national_session", "Event: National session", "email", "en");
t.add("notification.history_events.national_committee", "Event: National commission", "email", "en");
t.add("notification.drafts", "Draft", "email", "en");
t.add("notification.drafts.texts", "Draft text", "email", "en");
t.add("notification.drafts.texts.value_de", "Draft text GE", "email", "en");
t.add("notification.drafts.texts.value_fr", "Draft text FR", "email", "en");
t.add("notification.drafts.texts.value_it", "Draft text IT", "email", "en");
t.add("notification.drafts.related_departments", "Draft: responsible departments", "email", "en");
t.add("notification.drafts.resolutions", "Resolution", "email", "en");
t.add("notification.drafts.resolutions.date", "Date of decision", "email", "en");
t.add("notification.drafts.resolutions.resolution_text", "text resolution", "email", "en");
t.add("notification.drafts.resolutions.resolution_text.text_de", "text resolution GE", "email", "en");
t.add("notification.drafts.resolutions.resolution_text.text_fr", "text resolution FR", "email", "en");
t.add("notification.drafts.resolutions.resolution_text.text_it", "text resolution IT", "email", "en");
t.add("notification.drafts.pre_consultations", "Draft preliminary discussion", "email", "en");
t.add("notification.drafts.references", "Draft", "email", "en");
t.add("notification.drafts.references.title_de", "Reference and title of the draft GE", "email", "en");
t.add("notification.drafts.references.title_fr", "Reference and title of the draft GE", "email", "en");
t.add("notification.drafts.references.title_it", "Reference and title of the draft IT", "email", "en");
t.add("notification.drafts.federal_council_proposal", "Draft by the federal council", "email", "en");
t.add("notification.drafts.federal_council_proposal.date", "Date of draft by the federal council", "email", "en");
t.add("notification.drafts.federal_council_proposal.summary_de", "Summary draft by the Federal Council GE", "email", "en");
t.add("notification.drafts.federal_council_proposal.summary_fr", "Summary draft by the Federal Council FR", "email", "en");
t.add("notification.drafts.federal_council_proposal.summary_it", "Summary draft by the Federal Council IT", "email", "en");
t.add("notification.drafts.federal_council_proposal.text_de", "Draft by the federal council GE", "email", "en");
t.add("notification.drafts.federal_council_proposal.text_fr", "Draft by the federal council FR", "email", "en");
t.add("notification.drafts.federal_council_proposal.text_it", "Draft by the federal council IT", "email", "en");
t.add("notification.title", "Title", "email", "en");
t.add("notification.text", "Text", "email", "en");
t.add("notification.code", "Code", "email", "en");
t.add("notification.internal_id", "Internal ID", "email", "en");
t.add("notification.status", "Status of consultation", "email", "en");
t.add("notification.main_attachment", "appendix", "email", "en");
t.add("notification.attachments", "further appendix", "email", "en");
t.add("notification.attachments.real_hash", "appendix content", "email", "en");
t.add("notification.authors", "Author", "email", "en");
t.add("notification.history_events.cantonal_session_program", "Cantonal session programme \/ agenda", "email", "en");
t.add("notification.history_events.attachments", "appendix event", "email", "en");
t.add("notification.history_events.attachments.real_hash", "Document \/ File", "email", "en");
t.add("notification.affair_groups", "Group assignments", "email", "en");
t.add("notification.affair_groups.parentId", "Parent group assignments", "email", "en");
t.add("notification.children", "Group assignments", "email", "en");
t.add("notification.documents", "Document consultation", "email", "en");
t.add("notification.opinions", "Answer on consultation", "email", "en");
t.add("notification.description", "Consultation description", "email", "en");
t.add("notification.office", "Office consultation", "email", "en");
t.add("notification.author", "Consultation Author", "email", "en");
t.add("notification.author.full_name", "Consultation Author", "email", "en");
t.add("notification.author.type", "Author type of consultation", "email", "en");
t.add("notification.laws", "consultation law", "email", "en");
t.add("notification.laws.law_id", "consultation law", "email", "en");
t.add("notification.remarks", "Consultation remark", "email", "en");
t.add("notification.affair_groups.parent", "Parent group assignments", "email", "en");
t.add("2fa.authCode.subject", "your Auth-Code for politik.ch", "email", "en");
t.add("notification.custom_links", "Linked affair", "email", "en");
t.add("notification.tableOfContents.cantonalAffairConsultations.new", "New cantonal consultation", "email", "en");
t.add("notification.tableOfContents.cantonalAffairConsultations.updated", "Cantonal consultation updated", "email", "en");
t.add("affairConsultations.cantonal.itemAdded", "Canton %abbr%: %title%", "email", "en");
t.add("notification.links", "notification.links", "email", "en");
t.add("notification.tableOfContents.cantonalAffairs.new", "New cantonal affairs", "email", "en");
t.add("notification.tableOfContents.cantonalAffairs.updated", "updated cantonal affairs", "email", "en");
t.add("notification.tableOfContents.nationalAffairs.new", "New national affairs", "email", "en");
t.add("notification.tableOfContents.nationalAffairs.updated", "updated national affairs", "email", "en");
t.add("notification.tableOfContents.nationalAffairConsultations.new", "New national consultations", "email", "en");
t.add("notification.tableOfContents.nationalAffairConsultations.updated", "updated national consultations", "email", "en");
t.add("notification.tableOfContents.affairGroups.new", "New groups", "email", "en");
t.add("notification.tableOfContents.affairGroups.updated", "updated groups", "email", "en");
t.add("notification.followingThemes", "We're pleased to inform you about the following topics:", "email", "en");
t.add("national.affairs.itemAddedLink", "%idParlService%", "email", "en");
t.add("national.affairs.itemAddedTitle", ": %title% (%type%)", "email", "en");
t.add("resetting.email.subject", "Reset password", "email", "en");
t.add("resetting.email.message", "Hello %username%!\n\nPlease visit the following site to reset your password: %confirmationUrl%\n\nKind regards,\npolitik.ch", "email", "en");
t.add("affairs.custom.itemAdded", "%title%", "email", "en");
t.add("notification.tableOfContents.eventReminders.new", "Reminders", "email", "en");
t.add("notification.tableOfContents.nationalVotes.new", "National parliament: Votes", "email", "en");
t.add("notification.national_council_debates", "Debate National Council", "email", "en");
t.add("notification.council_states_debates", "Debate Council of States", "email", "en");
t.add("notification.assignedAffairs.itemAdded", "User %assignedUser% has been added to affair %affair%.", "email", "en");
t.add("notification.tableOfContents.assignedAffairs.new", "New affairs assigned", "email", "en");
t.add("notification.tableOfContents.assignedAffairs.updated", "Assigned affairs updated", "email", "en");
t.add("national.committeePressReleases.itemAdded", "%title%", "email", "en");
t.add("notification.tableOfContents.nationalCommitteePressReleases.new", "New press releases commissions", "email", "en");
t.add("notification.tableOfContents.nationalCommitteePressReleases.updated", "Updated press releases commissions", "email", "en");
t.add("notification.tableOfContents.nationalAffairsDepositTexts.new", "National affairs with newly submitted text", "email", "en");
t.add("notification.tableOfContents.cantonalMediaRelease.new", "New press release published", "email", "en");
t.add("notification.tableOfContents.cantonalMediaRelease.updated", "Press release updated", "email", "en");
t.add("groups.form.title.cannotBeEmpty", "Title cannot be empty", "javascript", "en");
t.add("affair.internalNote.error", "erroneous description", "javascript", "en");
t.add("modal.error.title", "Oh! This is an error!", "javascript", "en");
t.add("modal.error.message", "An error just occured, we are very sorry. Our developers have already been notified. Please check back later it this functionality works or contact us directly: info@politik.ch or 044 512 12 60.", "javascript", "en");
t.add("affairs.error.message", "Error while loading affairs", "javascript", "en");
t.add("groups.error.message", "Error while loading groups", "javascript", "en");
t.add("groups.delete.message", "Are you sure you want to delete this group?", "javascript", "en");
t.add("groups.delete.confirm", "Delete", "javascript", "en");
t.add("groups.delete.cancel", "Abort", "javascript", "en");
t.add("ranking.filter.groups.tooltip", "Only groups which contain at least one affair corresponding to the chosen filters (e.g. type of affair) are shown.", "javascript", "en");
t.add("affair.internalNote.saved", "saved", "javascript", "en");
t.add("datatables.language.emptyTable", "no data available in table", "javascript", "en");
t.add("datatables.language.info", "_START_ until _END_ of _TOTAL_ entries", "javascript", "en");
t.add("datatables.language.infoEmpty", "no data available", "javascript", "en");
t.add("datatables.language.infoFiltered", "(filtered from _MAX_ entries)", "javascript", "en");
t.add("datatables.language.lengthMenu", "_MENU_ show entries", "javascript", "en");
t.add("datatables.language.loadingRecords", "loading...", "javascript", "en");
t.add("datatables.language.processing", "Please wait...", "javascript", "en");
t.add("datatables.language.search", "Search", "javascript", "en");
t.add("datatables.language.zeroRecords", "no entries available", "javascript", "en");
t.add("datatables.language.paginate.first", "First", "javascript", "en");
t.add("datatables.language.paginate.last", "Last", "javascript", "en");
t.add("datatables.language.paginate.next", "next", "javascript", "en");
t.add("datatables.language.paginate.previous", "previous", "javascript", "en");
t.add("datatables.language.aria.", ": activate in order to sort the row descending", "javascript", "en");
t.add("charts.men", "Men", "javascript", "en");
t.add("charts.women", "Women", "javascript", "en");
t.add("charts.gender.title", "Sex", "javascript", "en");
t.add("layout.logout", "Log out", "user", "en");
t.add("profile.edit.submit", "Update user", "user", "en");
t.add("security.login.email", "Email", "user", "en");
t.add("security.login.password", "Password", "user", "en");
t.add("security.login.forgotpassword", "Forgotten your password?", "user", "en");
t.add("security.login.remember_me", "Remind me", "user", "en");
t.add("resetting.reset.submit", "Change password", "user", "en");
t.add("resetting.request.username", "Email adress", "user", "en");
t.add("resetting.request.submit", "Reset Password", "user", "en");
t.add("change_password.submit", "Change password", "user", "en");
})(Translator);
