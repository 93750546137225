(function (t) {
// fr
t.add("vich_uploader.form_label.delete_confirm", "Supprimer?", "messages", "fr");
t.add("vich_uploader.link.download", "T\u00e9l\u00e9charger", "messages", "fr");
t.add("councillor.language.de", "Allemand", "messages", "fr");
t.add("councillor.language.en", "Anglais", "messages", "fr");
t.add("councillor.language.fr", "Fran\u00e7ais", "messages", "fr");
t.add("councillor.language.it", "Italien", "messages", "fr");
t.add("councillor.activeTrue", "en fonction", "messages", "fr");
t.add("councillor.activeFalse", "ancien membre du Parlement", "messages", "fr");
t.add("councillor.birthDate", "Date de naissance", "messages", "fr");
t.add("councillor.professions", "Profession", "messages", "fr");
t.add("councillor.language", "Langue", "messages", "fr");
t.add("councillor.votes.absent", "Absences", "messages", "fr");
t.add("councillor.votes.abstain", "Abstentions", "messages", "fr");
t.add("councillor.recentAffairs", "Interventions d\u00e9pos\u00e9es par %firstName% %lastName%", "messages", "fr");
t.add("affair.depositDate", "Date de d\u00e9p\u00f4t", "messages", "fr");
t.add("councillor.affairs", "Interventions", "messages", "fr");
t.add("councillor.affairTypes", "Types d\u2019interventions", "messages", "fr");
t.add("councillor.aTotalOfNAffairs", "%count% interventions au total", "messages", "fr");
t.add("councillor.topics", "Sujets des interventions", "messages", "fr");
t.add("councillor.affairs.success", "Succ\u00e8s des interventions", "messages", "fr");
t.add("councillor.absences", "Absences", "messages", "fr");
t.add("councillor.absences.absentOn", "Non-participation %count% sur %total% votes", "messages", "fr");
t.add("councillor.absences.absent", "Non-participation", "messages", "fr");
t.add("councillor.abstentions", "Abstentions", "messages", "fr");
t.add("councillor.abstentions.abstainedOn", "Abstentions %count% sur %total% votes", "messages", "fr");
t.add("councillor.abstentions.abstained", "Abstentions", "messages", "fr");
t.add("layout.source", "Source", "messages", "fr");
t.add("layout.source.sentence", "Services du Parlement de l\u2019Assembl\u00e9e f\u00e9d\u00e9rale, Berne", "messages", "fr");
t.add("layout.lastUpdate", "Derni\u00e8re mise \u00e0 jour", "messages", "fr");
t.add("profile.settings.save", "Enregistrer", "messages", "fr");
t.add("factions.title", "Groupes parlementaires", "messages", "fr");
t.add("factions.nationalCouncil.countMembers", "{1} Conseiller national|[2, Inf[ Conseillers nationaux", "messages", "fr");
t.add("factions.councilOfStates.countMembers", "{1} Conseiller aux Etats|[2, Inf[ Conseillers aux Etats", "messages", "fr");
t.add("cantons.title", "Cantons", "messages", "fr");
t.add("cantons.nationalCouncil.countMembers", "Conseiller national|Conseillers nationaux", "messages", "fr");
t.add("cantons.councilOfStates.countMembers", "Conseiller aux Etats|Conseillers aux Etats", "messages", "fr");
t.add("layout.account", "Compte", "messages", "fr");
t.add("layout.userdashboard", "Tableau de bord", "messages", "fr");
t.add("layout.admindashboard", "Tableau de bord admin", "messages", "fr");
t.add("layout.admindashboard.shortcut", "TB", "messages", "fr");
t.add("parties.title", "Partis", "messages", "fr");
t.add("parties.nationalCouncil.countMembers", "{1} Conseiller national|[2, Inf[ Conseillers nationaux", "messages", "fr");
t.add("parties.councilOfStates.countMembers", "{1} Conseiller aux Etats|[2, Inf[ Conseillers aux Etats", "messages", "fr");
t.add("cantonal.listCouncillors.title", "Parlementaire canton %canton%", "messages", "fr");
t.add("affair.status", "Statut dossier", "messages", "fr");
t.add("affair.genericAttachments", "Autres annexes", "messages", "fr");
t.add("affair.genericAttachments.download", "T\u00e9l\u00e9chargement pi\u00e8ce jointe #%number%", "messages", "fr");
t.add("affair.originalSource", "Vers le document original", "messages", "fr");
t.add("cantonal.affairs", "Dossiers cantonaux", "messages", "fr");
t.add("cantonal.overview.affairs", "Dossiers", "messages", "fr");
t.add("cantonal.overview.councillors", "Parlementaires", "messages", "fr");
t.add("cantonal.listAffairs.title", "Dossier canton %canton%", "messages", "fr");
t.add("cantons.nationalcouncil.members", "Conseillers nationaux", "messages", "fr");
t.add("cantons.councilofstates.members", "Conseillers aux Etats", "messages", "fr");
t.add("layout.menu.nationalcouncil", "Conseil national", "messages", "fr");
t.add("layout.menu.nationalcouncil.votes", "Votes CN", "messages", "fr");
t.add("layout.menu.nationalcouncil.votes.shortcut", "A", "messages", "fr");
t.add("layout.menu.nationalcouncil.programs", "Programme de session CN", "messages", "fr");
t.add("layout.menu.nationalcouncil.programs.shortcut", "S", "messages", "fr");
t.add("layout.menu.nationalcouncil.rankings", "Classement CN", "messages", "fr");
t.add("layout.menu.nationalcouncil.rankings.shortcut", "R", "messages", "fr");
t.add("layout.menu.nationalcouncil.rankings.factions", "Coalitions: quelles majorit\u00e9s sont efficaces?", "messages", "fr");
t.add("layout.menu.nationalcouncil.rankings.councillors", "Classement des votes parlementaires", "messages", "fr");
t.add("layout.menu.nationalcouncil.rankings.votes", "Classement des votes: votes serr\u00e9s", "messages", "fr");
t.add("layout.menu.councilofstates", "Conseil des Etats", "messages", "fr");
t.add("layout.menu.councilofstates.votes", "Votes CE", "messages", "fr");
t.add("layout.menu.councilofstates.votes.shortcut", "A", "messages", "fr");
t.add("layout.menu.councilofstates.programs", "Programme de session CE", "messages", "fr");
t.add("layout.menu.councilofstates.programs.shortcut", "S", "messages", "fr");
t.add("layout.menu.councilofstates.rankings", "Classement SR", "messages", "fr");
t.add("layout.menu.councilofstates.rankings.shortcut", "C", "messages", "fr");
t.add("layout.menu.councilstates.rankings.councillors", "Classement des votes parlementaires", "messages", "fr");
t.add("layout.menu.councilstates.rankings.votes", "Classement des votes: votes serr\u00e9s", "messages", "fr");
t.add("layout.menu.councillors", "Parlementaires", "messages", "fr");
t.add("layout.menu.cantonalAffairs", "Cantons", "messages", "fr");
t.add("layout.search.query", "Terme recherch\u00e9", "messages", "fr");
t.add("layout.search.submit", "Rechercher", "messages", "fr");
t.add("parties.nationalcouncil.members", "Membres de parti Conseil national", "messages", "fr");
t.add("parties.councilofstates.members", "Membres de parti Conseil des Etats", "messages", "fr");
t.add("factions.nationalcouncil.members", "Membres de groupe parlementaire Conseil national", "messages", "fr");
t.add("factions.councilofstates.members", "Membres de groupe parlementaire Conseil des Etats", "messages", "fr");
t.add("vote.decision.yes", "Oui", "messages", "fr");
t.add("vote.decision.no", "Non", "messages", "fr");
t.add("sessions.nocouncil.title", "Votes", "messages", "fr");
t.add("form.session.label", "%title% (du %from% au %to%)", "messages", "fr");
t.add("vote.session_stats", "Statistiques de la session", "messages", "fr");
t.add("vote.new_councillors", "Parlementaires r\u00e9cemment asserment\u00e9s", "messages", "fr");
t.add("session.dayFilter.all", "Tous", "messages", "fr");
t.add("session.novotes", "Il n\u2019y a pas de votes dans cette session au sein de ce Conseil.", "messages", "fr");
t.add("session.nohistoryEvents", "Aucun \u00e9v\u00e9nement", "messages", "fr");
t.add("form.firstName", "Pr\u00e9nom", "messages", "fr");
t.add("form.lastName", "Nom", "messages", "fr");
t.add("form.company", "Soci\u00e9t\u00e9", "messages", "fr");
t.add("layout.search.contentType.national_affair", "Recherche nationale", "messages", "fr");
t.add("layout.search.contentType.cantonal_affair", "Recherche cantonale", "messages", "fr");
t.add("layout.search.type", "Recherche nationale \/ cantonale", "messages", "fr");
t.add("profile.edit.firstName", "Pr\u00e9nom", "messages", "fr");
t.add("profile.edit.lastName", "Nom", "messages", "fr");
t.add("profile.edit.pictureFile", "Photo de profil", "messages", "fr");
t.add("profile.settings.notificationPreference.none", "Aucune", "messages", "fr");
t.add("profile.settings.notificationPreference.keywords", "Mots-cl\u00e9s", "messages", "fr");
t.add("profile.settings.notificationPreference.all", "Toutes les notifications", "messages", "fr");
t.add("profile.settings.notificationPreference.yes", "Oui", "messages", "fr");
t.add("profile.settings.notificationPreference.no", "Non", "messages", "fr");
t.add("ranking.narrowvotes", "Votes serr\u00e9s", "messages", "fr");
t.add("ranking.absent", "Non-participation aux votes", "messages", "fr");
t.add("ranking.coalitions", "Coalitions: quelles majorit\u00e9s sont efficaces?", "messages", "fr");
t.add("ranking.voteagainst", "Contre son propre groupe parlementaire (classement des dissidents)", "messages", "fr");
t.add("ranking.age", "Ann\u00e9e de naissance (\u00e2ge)", "messages", "fr");
t.add("ranking.membership", "Dur\u00e9e du mandat (au sein du Conseil actuel)", "messages", "fr");
t.add("ranking.abstain", "Abstentions lors de votes", "messages", "fr");
t.add("vote.decision.eh", "S\u2019est abstenu", "messages", "fr");
t.add("vote.decision.nt", "Absent", "messages", "fr");
t.add("vote.decision.es", "Excus\u00e9", "messages", "fr");
t.add("vote.decision.p", "Pr\u00e9sident", "messages", "fr");
t.add("global.dates.format", "d.m.Y", "messages", "fr");
t.add("global.dates.dayMonthFormat", "d.m.", "messages", "fr");
t.add("global.dates.dateTimeFormat", "d.m.Y H:i", "messages", "fr");
t.add("councillor.gender.m", "Hommes", "messages", "fr");
t.add("councillor.gender.f", "Femmes", "messages", "fr");
t.add("layout.login.error.duplicated", "Un autre appareil s\u2019est connect\u00e9 avec votre nom d\u2019utilisateur et votre mot de passe. Pour vous connecter \u00e0 nouveau, veuillez saisir vos donn\u00e9es d\u2019acc\u00e8s ci-dessous. Veuillez noter que l\u2019autre appareil sera d\u00e9connect\u00e9.", "messages", "fr");
t.add("session.stats.totalVotes", "Total des votes", "messages", "fr");
t.add("session.stats.finalGovernmentVotes", "Votes finaux", "messages", "fr");
t.add("councillor.affairSuccess.noVotes", "Pas encore trait\u00e9", "messages", "fr");
t.add("councillor.affairSuccess.positiveVotes", "R\u00e9ussi", "messages", "fr");
t.add("councillor.affairSuccess.negativeVotes", "Non r\u00e9ussi", "messages", "fr");
t.add("profile.settings.save.success", "Enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("affair.submittedBy", "D\u00e9pos\u00e9 par %name% le %date%", "messages", "fr");
t.add("affair.description", "Texte d\u00e9pos\u00e9", "messages", "fr");
t.add("affair.reason", "Justification", "messages", "fr");
t.add("affair.nationalCouncilDebate.showmore", "Afficher plus", "messages", "fr");
t.add("affair.noVotes", "Aucun vote existant.", "messages", "fr");
t.add("affair.councilOfStatesVote", "Vote au Conseil des Etats", "messages", "fr");
t.add("affair.nationalCouncilVote", "Vote au Conseil national", "messages", "fr");
t.add("affair.nationalCouncilDebate", "D\u00e9bat au Conseil national", "messages", "fr");
t.add("affair.nationalCouncilDebate.showless", "Afficher moins", "messages", "fr");
t.add("affairs.nationalCouncilDebate.otherAffairs", "Autres dossiers", "messages", "fr");
t.add("affair.nationalCouncilDebate.speeches", "Discours", "messages", "fr");
t.add("affair.nationalCouncilDebate.speechesByCouncillor", "Discours par parlementaire", "messages", "fr");
t.add("vote.date", "Date de vote", "messages", "fr");
t.add("vote.submission", "Projet", "messages", "fr");
t.add("vote.division", "Objet", "messages", "fr");
t.add("vote.meaning.yes", "Importance Oui", "messages", "fr");
t.add("vote.meaning.no", "Importance Non", "messages", "fr");
t.add("affair.filters", "Filtre", "messages", "fr");
t.add("affair.filterby", "Filtrer par...", "messages", "fr");
t.add("affair.filter.all", "Tous les r\u00e9sultats", "messages", "fr");
t.add("affair.filter.factions", "Groupes parlementaires", "messages", "fr");
t.add("affair.filter.cantons", "Cantons", "messages", "fr");
t.add("affair.filter.dissenters", "contre son propre groupe parlementaire (dissidents)", "messages", "fr");
t.add("affair.filter.language", "Langue", "messages", "fr");
t.add("affair.filter.gender", "Sexe", "messages", "fr");
t.add("affair.results.all", "Tous les r\u00e9sultats", "messages", "fr");
t.add("affair.results.byfactions", "R\u00e9sultat par groupe parlementaire", "messages", "fr");
t.add("affair.results.faction", "Groupe parlementaire", "messages", "fr");
t.add("vote.decision.abstained", "EH", "messages", "fr");
t.add("vote.decision.abs_exc", "ES\/NT", "messages", "fr");
t.add("vote.decision.total", "Total", "messages", "fr");
t.add("vote.allCouncillors", "Tous", "messages", "fr");
t.add("affair.results.bycantons", "R\u00e9sultat par canton", "messages", "fr");
t.add("affair.results.canton", "Canton", "messages", "fr");
t.add("affair.results.bydissenters", "R\u00e9sultat par dissident", "messages", "fr");
t.add("affair.results.bylanguage", "R\u00e9sultat par langue", "messages", "fr");
t.add("affair.results.language", "Langue", "messages", "fr");
t.add("affair.results.bygender", "R\u00e9sultat par sexe", "messages", "fr");
t.add("affair.results.gender", "Sexe", "messages", "fr");
t.add("global.filter.placeholder", "Filtrer les r\u00e9sultats...", "messages", "fr");
t.add("affair.councillor.name", "Nom", "messages", "fr");
t.add("affair.councillor.party", "Parti", "messages", "fr");
t.add("affair.councillor.vote", "Voix", "messages", "fr");
t.add("affair.councillor.canton", "Canton", "messages", "fr");
t.add("vote.compact.yes", "Oui", "messages", "fr");
t.add("vote.compact.no", "Non", "messages", "fr");
t.add("vote.compact.abstained", "S\u2019est abstenu", "messages", "fr");
t.add("vote.compact.notPartecipating", "N\u2019a pas particip\u00e9", "messages", "fr");
t.add("affair.results.detailedView", "Vue d\u00e9taill\u00e9e", "messages", "fr");
t.add("affair.results.compactView", "Vue condens\u00e9e", "messages", "fr");
t.add("rankings.stats", "%affairs% dossiers, %votes% votes", "messages", "fr");
t.add("ranking.vacantSeat", "Si\u00e8ge vacant de", "messages", "fr");
t.add("ranking.membership.fromto", "Parlementaire depuis le %from% jusqu\u2019au %to%", "messages", "fr");
t.add("ranking.membership.from", "Parlementaire depuis le %from%", "messages", "fr");
t.add("ranking.membership.yearAndDays", "%years% ans et %days% jours", "messages", "fr");
t.add("rankings.title", "Classements des votes %council%", "messages", "fr");
t.add("form.submit", "Afficher", "messages", "fr");
t.add("ranking.abstain.in", "S\u2019est abstenu lors de %hits% sur %total% votes", "messages", "fr");
t.add("ranking.absent.percentage", "Non-participation", "messages", "fr");
t.add("ranking.absent.in", "Non-participation: %hits% sur %total% votes", "messages", "fr");
t.add("layout.loader.error", "Erreur lors du chargement des donn\u00e9es", "messages", "fr");
t.add("ranking.age.yearAndDays", "%years% ans et %days% jours", "messages", "fr");
t.add("ranking.narrowvotes.difference", "Diff\u00e9rence", "messages", "fr");
t.add("ranking.voteagainst.in", "%hits% sur %total% contre son propre groupe parlementaire", "messages", "fr");
t.add("ranking.voteagainst.percentage", "%hits%% dissident", "messages", "fr");
t.add("ranking.filter.submit", "Filtrer les r\u00e9sultats", "messages", "fr");
t.add("profile.dashboard", "Tableau de bord", "messages", "fr");
t.add("profile.dashboard.sessions", "Votes", "messages", "fr");
t.add("profile.dashboard.parties", "Partis: parlementaires", "messages", "fr");
t.add("profile.dashboard.rankings.councillors", "Evaluation: parlementaires", "messages", "fr");
t.add("profile.dashboard.factions", "Groupes parlementaires: parlementaires", "messages", "fr");
t.add("profile.dashboard.rankings.factions", "Evaluation: groupes parlementaires", "messages", "fr");
t.add("profile.dashboard.cantons", "Cantons: parlementaires", "messages", "fr");
t.add("profile.dashboard.recentVotes", "Derniers votes CN", "messages", "fr");
t.add("rankings.form.onlyfinalvotes", "Quels votes?", "messages", "fr");
t.add("rankings.form.filter.on", "A", "messages", "fr");
t.add("rankings.form.filter.off", "De", "messages", "fr");
t.add("rankings.form.legislativeperiod", "P\u00e9riode l\u00e9gislative", "messages", "fr");
t.add("rankings.form.session", "Session", "messages", "fr");
t.add("rankings.filter.faction.label", "Filtrer par groupe parlementaire", "messages", "fr");
t.add("rankings.filter.affairtype.label", "Filtrer par type de dossier", "messages", "fr");
t.add("rankings.filter.topic.label", "Sujet", "messages", "fr");
t.add("rankings.form.ranking", "Classement", "messages", "fr");
t.add("profile.show.username", "Nom d\u2019utilisateur", "messages", "fr");
t.add("profile.show.email", "E-mail", "messages", "fr");
t.add("profile.show.firstName", "Pr\u00e9nom", "messages", "fr");
t.add("profile.show.lastName", "Nom", "messages", "fr");
t.add("profile.show.edit", "Modifier", "messages", "fr");
t.add("layout.login", "Se connecter", "messages", "fr");
t.add("security.forgotpassword", "Mot de passe oubli\u00e9?", "messages", "fr");
t.add("profile.settings.keywords", "Mots-cl\u00e9s", "messages", "fr");
t.add("results.results", "R\u00e9sultats", "messages", "fr");
t.add("results.noResultsFound", "Aucun r\u00e9sultat trouv\u00e9", "messages", "fr");
t.add("profile.settings.navigation.profile", "Profil", "messages", "fr");
t.add("profile.settings.navigation.password", "Mot de passe", "messages", "fr");
t.add("profile.settings.navigation.notificationPreferences", "Terme recherch\u00e9", "messages", "fr");
t.add("profile.settings.defaultNotificationPreference", "Param\u00e8tre standard", "messages", "fr");
t.add("profile.settings.nationalNotificationPreferences", "Param\u00e8tres divergents Conf\u00e9d\u00e9ration", "messages", "fr");
t.add("profile.settings.cantonalNotificationPreferences", "Param\u00e8tres divergents cantons", "messages", "fr");
t.add("layout.menu.councilstates.rankings.factions", "Coalitions: quelles majorit\u00e9s sont efficaces?", "messages", "fr");
t.add("cantonal.listCouncillors.deputies.title", "Parlementaire suppl\u00e9ant", "messages", "fr");
t.add("councillor.role.deputy", "Suppl\u00e9ant", "messages", "fr");
t.add("layout.search.contentType.all", "Dossiers et parlementaires", "messages", "fr");
t.add("layout.search.contentType.affair", "Uniquement dossiers", "messages", "fr");
t.add("layout.search.contentType.councillor", "Uniquement parlementaires", "messages", "fr");
t.add("layout.search.contentType", "Rechercher par", "messages", "fr");
t.add("layout.search.affairTypeNational", "Type de dossier (national)", "messages", "fr");
t.add("layout.search.affairTypeCantonal", "Type de dossier (cantonal)", "messages", "fr");
t.add("layout.search.canton", "Canton", "messages", "fr");
t.add("layout.search.affairTypeNational.all", "Tous", "messages", "fr");
t.add("layout.search.affairTypeCantonal.all", "Tous", "messages", "fr");
t.add("layout.search.canton.all", "Tous les cantons", "messages", "fr");
t.add("profile.settings.notificationPreference.default", "Param\u00e8tre standard", "messages", "fr");
t.add("ranking.narrowvotesguilty", "Votes serr\u00e9s perdus", "messages", "fr");
t.add("rankings.filter.narrowVotesGuilty.limit.label", "Que sont les votes serr\u00e9s? Diff\u00e9rence de voix:", "messages", "fr");
t.add("ranking.narrowvotesguilty.in", "Impliqu\u00e9 dans %hits% sur %total% votes", "messages", "fr");
t.add("ranking.narrowvotesguilty.percentage", "%hits%% dissident", "messages", "fr");
t.add("ranking.narrowvotesguilty.abstain", "Abstention de vote", "messages", "fr");
t.add("ranking.narrowvotesguilty.absent", "Absent (non-participation)", "messages", "fr");
t.add("ranking.narrowvotesguilty.against", "Contre son propre groupe parlementaire", "messages", "fr");
t.add("ranking.abstain.percentage", "Abstention", "messages", "fr");
t.add("profile.settings.baseSettings", "Param\u00e8tres g\u00e9n\u00e9raux", "messages", "fr");
t.add("global.filter.noResults", "Aucun r\u00e9sultat trouv\u00e9", "messages", "fr");
t.add("layout.account.shortcut", "C", "messages", "fr");
t.add("profile.show.edit.shortcut", "M", "messages", "fr");
t.add("profile.settings.edit.shortcut", "P", "messages", "fr");
t.add("profile.settings.edit", "Param\u00e8tres", "messages", "fr");
t.add("layout.logout.shortcut", "D", "messages", "fr");
t.add("layout.search", "Terme recherch\u00e9", "messages", "fr");
t.add("layout.menu.councillors.byCanton.shortcut", "K", "messages", "fr");
t.add("layout.menu.councillors.byCanton", "par cantons", "messages", "fr");
t.add("layout.menu.councillors.byFaction.shortcut", "F", "messages", "fr");
t.add("layout.menu.councillors.byFaction", "par groupes parlementaires", "messages", "fr");
t.add("layout.menu.councillors.byParty.shortcut", "P", "messages", "fr");
t.add("layout.menu.councillors.byParty", "par partis", "messages", "fr");
t.add("rankings.stats.description", "Le nombre de votes se rapporte \u00e0 la diff\u00e9rence de voix s\u00e9lectionn\u00e9e ci-dessous.", "messages", "fr");
t.add("sessionlist.session.dates", "du %from% au %to%", "messages", "fr");
t.add("sessionlist.session.votes", "Votes", "messages", "fr");
t.add("sessionlist.session.historyEvents", "Dossiers", "messages", "fr");
t.add("layout.search.orderBy.none", "Meilleur r\u00e9sultat", "messages", "fr");
t.add("layout.search.orderBy.deposit_date", "Date de d\u00e9p\u00f4t", "messages", "fr");
t.add("layout.search.orderBy.title", "Titre", "messages", "fr");
t.add("layout.search.orderBy.type", "Type", "messages", "fr");
t.add("layout.search.orderBy.first_name", "Pr\u00e9nom", "messages", "fr");
t.add("layout.search.orderBy.last_name", "Nom", "messages", "fr");
t.add("layout.search.orderDirection.ascending", "Croissant", "messages", "fr");
t.add("layout.search.orderDirection.descending", "D\u00e9croissant", "messages", "fr");
t.add("layout.search.orderBy", "Trier par", "messages", "fr");
t.add("layout.search.orderDirection", "Ordre de tri", "messages", "fr");
t.add("councillor.affairSuccess.unknownVotes", "Ne fait pas l\u2019objet d\u2019un vote", "messages", "fr");
t.add("rankings.cantonal.numberOfAffairs.title", "Nombre de dossiers par canton", "messages", "fr");
t.add("layout.menu.cantonalRankings", "Classements cantons", "messages", "fr");
t.add("layout.politicalcalendar", "Calendrier", "messages", "fr");
t.add("session.stats.finalPersonalVotes", "Interventions", "messages", "fr");
t.add("affair.councilStatesDebate.debate", "D\u00e9bat", "messages", "fr");
t.add("affair.councilStatesDebate.speechesByCouncillor", "Votes parlementaires", "messages", "fr");
t.add("affair.councilStatesDebate.speeches", "Votes", "messages", "fr");
t.add("affair.submittedByOn", "D\u00e9pos\u00e9 par %councillors% le %date%", "messages", "fr");
t.add("rankings.filter.orderDirection.label", "Ordre", "messages", "fr");
t.add("rankings.filter.orderDirection.descending", "D\u00e9croissant", "messages", "fr");
t.add("rankings.filter.orderDirection.ascending", "Croissant", "messages", "fr");
t.add("rankings.form.filter.all", "Tous", "messages", "fr");
t.add("rankings.form.filter.finalGovernment", "Uniquement votes finaux", "messages", "fr");
t.add("rankings.form.filter.finalPersonal", "Uniquement interventions", "messages", "fr");
t.add("rankings.form.filter.finalAll", "Uniquement votes finaux et interventions", "messages", "fr");
t.add("affairGroups.assign.modal", "Attribuer au groupe", "messages", "fr");
t.add("affairGroups.newGroup.modal", "Cr\u00e9er un nouveau groupe", "messages", "fr");
t.add("profile.affairGroups", "Groupes", "messages", "fr");
t.add("layout.affair_groups", "Groupes", "messages", "fr");
t.add("layout.affair_groups.shortcut", "G", "messages", "fr");
t.add("layout.affairs_overview", "Dossiers", "messages", "fr");
t.add("layout.menu.nationalcouncil.consultations", "Consultations", "messages", "fr");
t.add("affairGroups.newGroup.success", "Nouveau groupe cr\u00e9\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("affairGroups.assign.select", "Attribuer un groupe", "messages", "fr");
t.add("affairGroups.assign.success", "Attributions aux groupes mises \u00e0 jour.", "messages", "fr");
t.add("affairGroups.assign.error", "Le groupe ne peut pas \u00eatre attribu\u00e9", "messages", "fr");
t.add("affairGroups.assign.placeholder", "-", "messages", "fr");
t.add("affairGroups.assign.label", "Attribuer un groupe", "messages", "fr");
t.add("affairGroups.newGroup.title.label", "Nom", "messages", "fr");
t.add("affairGroups.newGroup.description.label", "Description", "messages", "fr");
t.add("affairGroups.new.title", "Cr\u00e9er un nouveau groupe", "messages", "fr");
t.add("affairGroups.new.save", "Enregistrer un nouveau groupe", "messages", "fr");
t.add("affairGroups.affair.title", "Titre du dossier", "messages", "fr");
t.add("affairGroups.assign.title", "Attribuer le dossier \u00e0 un groupe", "messages", "fr");
t.add("affairGroups.assign.save", "Enregistrer l\u2019attribution", "messages", "fr");
t.add("affairGroups.newGroup.parent.placeholder", "(pas de groupe sup\u00e9rieur)", "messages", "fr");
t.add("affairGroups.newGroup.parent.label", "Groupe sup\u00e9rieur", "messages", "fr");
t.add("affairGroups.affair.existingGroups", "Groupes existants", "messages", "fr");
t.add("profile.settings.nationalNotificationPreferences.affairs", "Dossiers", "messages", "fr");
t.add("profile.settings.nationalNotificationPreferences.nationalCouncilVotes", "Votes Conseil national", "messages", "fr");
t.add("profile.settings.nationalNotificationPreferences.councilStatesVotes", "Votes Conseil des Etats", "messages", "fr");
t.add("affairGroups.edit.title", "Modifier le groupe", "messages", "fr");
t.add("affairGroups.edit.save", "Enregistrer", "messages", "fr");
t.add("affairGroups.editGroup.tooltip", "Modifier le groupe", "messages", "fr");
t.add("affairGroups.assign.noGroups", "Attribu\u00e9 \u00e0 aucun groupe", "messages", "fr");
t.add("affairGroups.editGroup.success", "Groupe enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("affair.customEvents.add.button", "Ajouter une note\/un \u00e9v\u00e9nement", "messages", "fr");
t.add("affair.customEvents.edit.title", "Modifier l\u2019\u00e9v\u00e9nement", "messages", "fr");
t.add("affair.customEvent.button.submit", "Enregistrer", "messages", "fr");
t.add("affair.customEvents.add.title", "Ajouter un \u00e9v\u00e9nement", "messages", "fr");
t.add("affair.customEvents.save.success", "Ev\u00e9nement enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("affairs.customEvents.fields.note.title", "Titre", "messages", "fr");
t.add("affairs.customEvents.fields.note.abstract", "R\u00e9sum\u00e9", "messages", "fr");
t.add("affairs.customEvents.fields.note.outputInReports", "Editer dans les documents (exportations, rapports, invitations, etc.)", "messages", "fr");
t.add("affairs.customEvents.fields.note.text", "Texte", "messages", "fr");
t.add("affairs.customEvents.fields.vote.caption", "Titre", "messages", "fr");
t.add("affairs.customEvents.fields.vote.description", "Description", "messages", "fr");
t.add("affairs.customEvents.fields.vote.yes", "Oui", "messages", "fr");
t.add("affairs.customEvents.fields.vote.no", "Non", "messages", "fr");
t.add("affairs.customEvents.fields.date", "Date", "messages", "fr");
t.add("affairs.customEvents.success", "Enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("affair.councilOfStatesVote.tooltip.caption", "Vote Conseil des Etats", "messages", "fr");
t.add("affair.nationalCouncilVote.tooltip.caption", "Vote Conseil national", "messages", "fr");
t.add("affair.nationalCouncilDebate.tooltip.caption", "D\u00e9bat Conseil national", "messages", "fr");
t.add("affair.customNote.tooltip.caption", "Note", "messages", "fr");
t.add("affair.customInternalVote.tooltip.caption", "Vote interne", "messages", "fr");
t.add("affairs.customEvents.note", "Note", "messages", "fr");
t.add("affairs.customEvents.internalvote", "Vote interne", "messages", "fr");
t.add("cantonal.historyevent.deposit.tooltip.caption", "Date de d\u00e9p\u00f4t", "messages", "fr");
t.add("affair.authorCouncillor", "D\u00e9pos\u00e9 par", "messages", "fr");
t.add("affairGroup.overview.noContent.title", "Aucun dossier", "messages", "fr");
t.add("affairGroup.overview.nationalEvents.title", "Dossiers nationaux", "messages", "fr");
t.add("affairGroup.overview.cantonalEvents.title", "Dossiers cantonaux", "messages", "fr");
t.add("affairGroup.overview.customAffairs.title", "Dossiers internes", "messages", "fr");
t.add("affairGroup.overview.children", "Sous-groupes", "messages", "fr");
t.add("affairGroup.description.empty", "Pas de description", "messages", "fr");
t.add("affairGroups.overviewLink.tooltip", "Ouvrir la vue d\u2019ensemble des groupes", "messages", "fr");
t.add("author.party.label", "Parti", "messages", "fr");
t.add("author.faction.label", "Groupe parlementaire", "messages", "fr");
t.add("author.commission.label", "Commission", "messages", "fr");
t.add("author.misc.label", "Auteur", "messages", "fr");
t.add("councillor.affairSuccess.partialPositiveVotes", "Succ\u00e8s au sein d\u2019un conseil", "messages", "fr");
t.add("affair.overview.nationalCouncilVote.title", "Vote Conseil national", "messages", "fr");
t.add("affair.overview.internalVoteEvent.title", "Vote interne", "messages", "fr");
t.add("affair.overview.noteEvent.title", "Note", "messages", "fr");
t.add("affair.overview.nationalCouncilDebateEvent.title", "D\u00e9bat", "messages", "fr");
t.add("affair.overview.councilStatesVote.title", "Vote Conseil des Etats", "messages", "fr");
t.add("affair.overview.cantonal.depositEvent.title", "D\u00e9pos\u00e9", "messages", "fr");
t.add("affair.overview.events.draft", "D\u00e9tails du projet", "messages", "fr");
t.add("ranking.councillorscompare", "Comparaison parlementaires", "messages", "fr");
t.add("ranking.councillorscompare.noVotes", "Aucune diff\u00e9rence \/ aucun vote", "messages", "fr");
t.add("layout.menu.nationalcouncil.rankings.councillorscompare", "Comparaison conseillers nationaux", "messages", "fr");
t.add("layout.menu.councilstates.rankings.councillorscompare", "Comparaison conseillers aux Etats", "messages", "fr");
t.add("rankings.form.councillorsCompare.councillor1", "Parlementaire 1", "messages", "fr");
t.add("rankings.form.councillorsCompare.councillor2", "Parlementaire 2", "messages", "fr");
t.add("ranking.councillorscompare.topicsStats.title", "Sujet des votes avec opinion divergente", "messages", "fr");
t.add("ranking.councillorscompare.votesAndAffairs", "%affairs% dossiers, %votes% votes", "messages", "fr");
t.add("ranking.councillorscompare.overview.title", "Comparaison parlementaires", "messages", "fr");
t.add("ranking.councillorscompare.description", "Votes lors desquels les deux parlementaires ont vot\u00e9 diff\u00e9remment: le graphique montre qui a vot\u00e9 oui, non ou s\u2019est abstenu lors de ces votes.", "messages", "fr");
t.add("ranking.councillorscompare.howmany.yes", "%count% Oui", "messages", "fr");
t.add("ranking.councillorscompare.howmany.no", "%count% Non", "messages", "fr");
t.add("ranking.councillorscompare.howmany.eh", "%count% Abstentions", "messages", "fr");
t.add("ranking.councillorscompare.age", "Age", "messages", "fr");
t.add("ranking.councillorscompare.yearsInCouncil", "Dur\u00e9e du mandat au sein du Conseil actuel", "messages", "fr");
t.add("ranking.councillorscompare.absentRate", "Absences l\u00e9gislature actuelle", "messages", "fr");
t.add("ranking.councillorscompare.abstainRate", "Abstentions l\u00e9gislature actuelle", "messages", "fr");
t.add("ranking.councillorscompare.affairsCount", "Nombre d\u2019interventions l\u00e9gislature actuelle", "messages", "fr");
t.add("national.affair.overview.title", "Dossier national", "messages", "fr");
t.add("sidebar.toggle.collapse", "Fermer", "messages", "fr");
t.add("sidebar.toggle.expand", "Ouvrir", "messages", "fr");
t.add("cantonal.affair.overview.title", "Dossier cantonal", "messages", "fr");
t.add("councillor.faction", "Groupe parlementaire", "messages", "fr");
t.add("councillor.party", "Parti", "messages", "fr");
t.add("councillor.commissionMemberships.current", "Appartenances actuelles commissions", "messages", "fr");
t.add("councillor.commissionMemberships.past", "Appartenances pass\u00e9es commissions", "messages", "fr");
t.add("ranking.cantonal.councillorsAffairs", "Dossiers cantonaux par parlementaire", "messages", "fr");
t.add("rankings.cantonal.numberOfAffairs.stats", "Statistiques nombre de dossiers", "messages", "fr");
t.add("rankings.cantonal.filter.canton.label", "Filtrer par canton", "messages", "fr");
t.add("rankings.filter.isEffectiveAmount.label", "Comparer avec", "messages", "fr");
t.add("rankings.filter.isEffectiveAmount.yes", "Nombre absolu de dossiers\/d\u2019interventions", "messages", "fr");
t.add("rankings.filter.isEffectiveAmount.no", "Relativement au nombre de dossiers dans le canton", "messages", "fr");
t.add("rankings.filter.includeGovernmentAuthors.label", "Prendre en compte les dossiers du gouvernement", "messages", "fr");
t.add("rankings.filter.includeGovernmentAuthors.yes", "Oui", "messages", "fr");
t.add("rankings.filter.includeGovernmentAuthors.no", "Non", "messages", "fr");
t.add("ranking.cantonal.numberOfAffairs.authored.effective", "Dossiers\/interventions absolu", "messages", "fr");
t.add("ranking.cantonal.numberOfAffairs.authored.percentage", "Part de", "messages", "fr");
t.add("layout.menu.cantonal.rankings.affairsPerCanton", "Par canton", "messages", "fr");
t.add("cantonal.rankings.title", "Classements cantonaux", "messages", "fr");
t.add("layout.menu.cantonal.rankings.affairsPerCouncillor", "Parlementaires", "messages", "fr");
t.add("cantonal.overview.commissions", "Commissions", "messages", "fr");
t.add("cantonal.listCommissions.title", "Commission %canton%", "messages", "fr");
t.add("cantonal.commission.title", "Auteur: %commission%", "messages", "fr");
t.add("cantonal.commission.noAffairs", "Aucun dossier de cette commission existant", "messages", "fr");
t.add("cantonal.commission.affairs.title", "Dossiers", "messages", "fr");
t.add("cantonal.commission.councillors.title", "Parlementaires", "messages", "fr");
t.add("cantonal.listCommissions.councillors.title", "Parlementaires", "messages", "fr");
t.add("cantonal.listCommissions.parties.title", "Partis", "messages", "fr");
t.add("cantonal.listCommissions.factions.title", "Groupes parlementaires", "messages", "fr");
t.add("cantonal.overview.miscauthors", "Divers auteurs", "messages", "fr");
t.add("cantonal.listMiscAuthors.title", "Divers auteurs %canton%", "messages", "fr");
t.add("cantonal.miscauthor.affairs.title", "Dossiers", "messages", "fr");
t.add("cantonal.miscAuthors.noAffairs", "Aucun dossier disponible pour cet auteur", "messages", "fr");
t.add("cantonal.miscauthors.title", "Dossiers %miscauthor%", "messages", "fr");
t.add("cantonal.overview.factions", "Groupes parlementaires", "messages", "fr");
t.add("cantonal.factions.noFactions", "Aucun groupe parlementaire saisi pour ce canton", "messages", "fr");
t.add("cantonal.listFactions.commissionMemberships.title", "Appartenances commissions", "messages", "fr");
t.add("cantonal.faction.noAffairs", "Aucun dossier pour ce groupe parlementaire", "messages", "fr");
t.add("cantonal.faction.commissions.title", "Appartenances commissions", "messages", "fr");
t.add("cantonal.faction.parties.title", "Partis", "messages", "fr");
t.add("cantonal.listFactions.title", "Groupes parlementaires %canton%", "messages", "fr");
t.add("cantonal.faction.title", "%faction%", "messages", "fr");
t.add("cantonal.overview.parties", "Partis", "messages", "fr");
t.add("cantonal.parties.noParties", "Aucun parti saisi pour ce canton", "messages", "fr");
t.add("cantonal.parties.noAffairs", "Aucun dossier de ce parti", "messages", "fr");
t.add("cantonal.parties.commissions.title", "Appartenance commission", "messages", "fr");
t.add("cantonal.party.factionMembership.title", "Appartenance groupe parlementaire", "messages", "fr");
t.add("cantonal.party.affairs.title", "Dossiers", "messages", "fr");
t.add("cantonal.party.title", "%party%", "messages", "fr");
t.add("cantonal.listParties.title", "Partis %canton%", "messages", "fr");
t.add("groups.tree.search", "Recherche...", "messages", "fr");
t.add("groups.tree.search.next", "suivant", "messages", "fr");
t.add("groups.tree.search.prev", "pr\u00e9c\u00e9dent", "messages", "fr");
t.add("groups.tree.search.collapse", "Fermer tout", "messages", "fr");
t.add("groups.tree.search.expand", "Ouvrir tout", "messages", "fr");
t.add("groups.tree.group.checkGroup", "Vers la vue des groupes", "messages", "fr");
t.add("groups.tree.group.deleteGroup", "Effacer le groupe", "messages", "fr");
t.add("groups.tree.group.deleteAffair", "Supprimer le dossier du groupe", "messages", "fr");
t.add("groups.tree.group.checkAffair", "Vers le dossier", "messages", "fr");
t.add("groups.tree.group.editGroup", "Modifier le groupe", "messages", "fr");
t.add("groups.tree.sort", "Tri", "messages", "fr");
t.add("groups.tree.sort.alphabetically", "Alphab\u00e9tique", "messages", "fr");
t.add("groups.tree.sort.custom", "personnalis\u00e9", "messages", "fr");
t.add("groups.tree.sort.default", "Standard", "messages", "fr");
t.add("groups.tree.sort.byDate", "Date", "messages", "fr");
t.add("affairGroup.export.header.id", "ID", "messages", "fr");
t.add("affairGroup.export.header.type", "Type", "messages", "fr");
t.add("affairGroup.export.header.title", "Titre", "messages", "fr");
t.add("affairGroup.export.header.description", "Description", "messages", "fr");
t.add("affairGroup.export.header.status", "Statut", "messages", "fr");
t.add("affairGroup.export.header.link", "Lien source", "messages", "fr");
t.add("groups.tree.group.exportGroup", "Exportation Excel", "messages", "fr");
t.add("affair.eventAttachments", "Annexes", "messages", "fr");
t.add("affair.eventAttachments.download", "T\u00e9l\u00e9charger fichier", "messages", "fr");
t.add("groups.form.title.cannotBeEmpty", "Le titre ne peut pas \u00eatre vide", "messages", "fr");
t.add("affair.internalNote.title", "Description", "messages", "fr");
t.add("affair.internalNote", "Description", "messages", "fr");
t.add("affair.affairOverview.groups", "Groupes", "messages", "fr");
t.add("affair.affairOverview.groups.empty", "Aucun groupe attribu\u00e9", "messages", "fr");
t.add("affair.affairOverview.meetings.empty", "Aucune s\u00e9ance attribu\u00e9e", "messages", "fr");
t.add("affair.internalNote.error", "Erreur description", "messages", "fr");
t.add("rankings.filter.affairgroup.label", "Groupe", "messages", "fr");
t.add("affairGroups.editGroup.save", "Enregistr\u00e9", "messages", "fr");
t.add("councillor.info.notTreated", "Pas encore trait\u00e9:", "messages", "fr");
t.add("councillor.info.notTreated.text", "Intervention d\u00e9pos\u00e9e", "messages", "fr");
t.add("councillor.info.successfully", "R\u00e9ussite:", "messages", "fr");
t.add("councillor.info.successfully.text", "L\u2019intervention a \u00e9t\u00e9 approuv\u00e9e au Conseil national et au Conseil des Etats", "messages", "fr");
t.add("councillor.info.successfullyCouncil", "R\u00e9ussite au sein d\u2019un Conseil:", "messages", "fr");
t.add("councillor.info.successfullyCouncil.text", "L\u2019intervention a \u00e9t\u00e9 approuv\u00e9e par un Conseil. Le vote au sein du deuxi\u00e8me Conseil peut encore \u00eatre ouvert.", "messages", "fr");
t.add("councillor.info.noSuccess", "Echec:", "messages", "fr");
t.add("councillor.info.noSuccess.text", "L\u2019intervention a \u00e9t\u00e9 rejet\u00e9e par au moins un Conseil", "messages", "fr");
t.add("councillor.info.noVote", "Ne fait pas l\u2019objet d\u2019un vote:", "messages", "fr");
t.add("councillor.info.noVote.text", "Les interpellations ou interventions pendant l\u2019heure des questions sont list\u00e9es ici.", "messages", "fr");
t.add("affair.internalNote.save", "Enregistrer", "messages", "fr");
t.add("national.affair.originalSource", "Source: Curia Vista", "messages", "fr");
t.add("affairs.followingHaveBeenUpdated", "Les dossiers suivants ont \u00e9t\u00e9 mis \u00e0 jour", "messages", "fr");
t.add("national.affairs.followingHaveBeenUpdated", "Les dossiers nationaux suivants ont \u00e9t\u00e9 mis \u00e0 jour", "messages", "fr");
t.add("affair.customSpeaker.cantonalCouncillor.label", "Intervenant(e)", "messages", "fr");
t.add("affair.customSpeaker.nationalCouncilCouncillor.label", "Intervenant(e) Conseil national", "messages", "fr");
t.add("affair.customSpeaker.councilStatesCouncillor.label", "Intervenant(e) Conseil des Etats", "messages", "fr");
t.add("affair.customSpeaker.button.submit", "Enregistrer", "messages", "fr");
t.add("affair.customSpeaker.save.success", "Enregistrer l\u2019intervenant(e)", "messages", "fr");
t.add("notification.subscription.button.affair.tooltip", "S\u2019abonner \/ se d\u00e9sabonner au dossier", "messages", "fr");
t.add("customMeetings.newMeeting.success", "Enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.modal", "Cr\u00e9er un nouveau sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.assignAgendaPoint.modal", "Ajouter au sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.save", "Enregistrer", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.success", "Enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.noMeetings", "Aucune s\u00e9ance", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.error", "Erreur lors de l\u2019enregistrement", "messages", "fr");
t.add("customMeetings.editMeeting.title", "Modifier la s\u00e9ance", "messages", "fr");
t.add("customMeetings.editMeeting.save", "Enregistrer", "messages", "fr");
t.add("customMeetings.editMeeting.success", "Enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("customMeetings.editMeetingAgendaPoint.success", "Enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("customMeetings.assignAgendaPoint.title", "Ajouter au sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.assignAgendaPoint.placeholder", "S\u00e9lectionner le sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.assignAgendaPoint.label", "Sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.assignAgendaPoint.error", "Erreur lors de l\u2019ajout", "messages", "fr");
t.add("customMeetings.assignAgendaPoint.success", "Attributions aux s\u00e9ances mises \u00e0 jour.", "messages", "fr");
t.add("customMeetings.assignAgendaPoint.save", "Enregistrer", "messages", "fr");
t.add("customMeetings.newMeeting.date.label", "Date", "messages", "fr");
t.add("customMeetings.newMeeting.modal", "Cr\u00e9er une nouvelle s\u00e9ance", "messages", "fr");
t.add("customMeetings.newMeeting.title", "Cr\u00e9er une nouvelle s\u00e9ance", "messages", "fr");
t.add("customMeetings.newMeeting.title.label", "Titre", "messages", "fr");
t.add("customMeetings.newMeeting.subject.label", "Objet", "messages", "fr");
t.add("customMeetings.newMeeting.description.label", "Description", "messages", "fr");
t.add("customMeetings.newMeeting.location.label", "Lieu", "messages", "fr");
t.add("customMeetings.newMeeting.president.label", "Pr\u00e9sidence", "messages", "fr");
t.add("customMeetings.newMeeting.protocol.label", "Tenue du proc\u00e8s-verbal", "messages", "fr");
t.add("customMeetings.newMeeting.save", "Enregistrer", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.title", "Cr\u00e9er un nouveau sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.title.label", "Cr\u00e9er un nouveau sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.description.label", "Description", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.parent.placeholder", "S\u00e9lectionner la s\u00e9ance", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.parent.label", "S\u00e9ance", "messages", "fr");
t.add("customMeetings.tree.search.expand", "Ouvrir tout", "messages", "fr");
t.add("customMeetings.tree.search.collapse", "Fermer tout", "messages", "fr");
t.add("customMeetings.tree.search", "Recherche", "messages", "fr");
t.add("customMeetings.tree.search.prev", "Pr\u00e9c\u00e9dente", "messages", "fr");
t.add("customMeetings.tree.search.next", "Suivant", "messages", "fr");
t.add("customMeetings.form.title.cannotBeEmpty", "Veuillez saisir une valeur", "messages", "fr");
t.add("customMeetings.tree.customMeeting.createInvitationPDF", "Cr\u00e9er l\u2019invitation (PDF)", "messages", "fr");
t.add("customMeetings.tree.customMeeting.createProtocolPDF", "Cr\u00e9er le proc\u00e8s-verbal (PDF)", "messages", "fr");
t.add("customMeetings.tree.sort.default", "Standard", "messages", "fr");
t.add("customMeetings.tree.sort.alphabetically", "Alphab\u00e9tique", "messages", "fr");
t.add("customMeetings.tree.sort.byDate", "Date", "messages", "fr");
t.add("customMeetings.tree.sort.custom", "Personnalis\u00e9", "messages", "fr");
t.add("customMeetings.tree.customMeeting.checkAffair", "Vers le dossier", "messages", "fr");
t.add("customMeetings.editCustomMeeting.tooltip", "Modifier", "messages", "fr");
t.add("customMeetings.editCustomMeeting.save", "Enregistrer", "messages", "fr");
t.add("customMeetings.tree.customMeeting.editCustomMeeting", "Modifier la s\u00e9ance", "messages", "fr");
t.add("customMeetings.tree.customMeeting.deleteCustomMeeting", "Supprimer la s\u00e9ance", "messages", "fr");
t.add("customMeetings.newAgendaPoint.tooltip", "Ajouter un sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.tree.customMeeting.newAgendaPoint", "Ajouter un sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.tree.customMeeting.deleteAffair", "Supprimer le dossier", "messages", "fr");
t.add("customMeetings.editCustomMeetingAgendaPoint.tooltip", "Modifier le sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.tree.customMeeting.editCustomMeetingAgendaPoint", "Modifier le sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.tree.customMeeting.deleteCustomMeetingAgendaPoint", "Supprimer le sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.deleteCustomMeeting.message", "Supprimer la s\u00e9ance?", "messages", "fr");
t.add("customMeetings.deleteCustomMeeting.confirm", "Confirmer", "messages", "fr");
t.add("customMeetings.deleteCustomMeeting.cancel", "Annuler", "messages", "fr");
t.add("customMeetings.deleteCustomMeeting.success", "Supprim\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("customMeetings.deleteCustomMeeting.error", "Erreur lors de la suppression", "messages", "fr");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.message", "Supprimer le sujet \u00e0 l\u2019ordre du jour?", "messages", "fr");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.confirm", "Confirmer", "messages", "fr");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.cancel", "Annuler", "messages", "fr");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.error", "Erreur lors de la suppression. Veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch", "messages", "fr");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.success", "Supprim\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("customMeetings.error.message", "Erreur", "messages", "fr");
t.add("layout.custom_meetings", "S\u00e9ances", "messages", "fr");
t.add("layout.custom_meetings.shortcut", "S", "messages", "fr");
t.add("layout.custom_affairs", "Dossiers internes", "messages", "fr");
t.add("layout.custom_affairs.shortcut", "DI", "messages", "fr");
t.add("layout.menu.management", "Administration", "messages", "fr");
t.add("affair.affairOverview.meetings", "S\u00e9ances", "messages", "fr");
t.add("customMeetings.affair.existingMeetings", "S\u00e9ances", "messages", "fr");
t.add("profile.customMeetings", "S\u00e9ances", "messages", "fr");
t.add("profile.settings.navigation.companySettings", "Param\u00e8tres organisation\/entreprise", "messages", "fr");
t.add("profile.settings.company.pictureFile.label", "Logo", "messages", "fr");
t.add("profile.settings.company.settings.label", "Autres param\u00e8tres", "messages", "fr");
t.add("profile.settings.company.download.label", "T\u00e9l\u00e9charger un logo", "messages", "fr");
t.add("profile.settings.company.delete.label", "Supprimer le logo", "messages", "fr");
t.add("custom_speakers_enabled.label", "Afficher l\u2019intervenant(e)", "messages", "fr");
t.add("affair.customSpeakers.save.success", "Enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("custom.affairs.list.pagetitle", "Dossiers internes", "messages", "fr");
t.add("custom.affairs.list.title", "Dossiers internes", "messages", "fr");
t.add("custom.affairs.edit.pagetitle", "Modifier le dossier interne", "messages", "fr");
t.add("custom.affairs.edit.title", "Modifier le dossier interne", "messages", "fr");
t.add("custom.affairs.backToList", "pr\u00e9c\u00e9dent", "messages", "fr");
t.add("custom.affairs.list.header.title", "Titre", "messages", "fr");
t.add("custom.affairs.list.header.internalId", "ID interne", "messages", "fr");
t.add("custom.affairs.list.header.createdAt", "Cr\u00e9\u00e9 le", "messages", "fr");
t.add("custom.affairs.list.header.actions", "Actions", "messages", "fr");
t.add("custom.affairs.list.edit.label", "modifier", "messages", "fr");
t.add("custom.affairs.list.delete.label", "supprimer", "messages", "fr");
t.add("custom.affairs.noaffairs.alert", "Aucun dossier interne existant", "messages", "fr");
t.add("custom.affairs.delete.confirm", "Etes-vous s\u00fbr de vouloir supprimer le dossier interne?", "messages", "fr");
t.add("custom.affairs.form.title", "Titre", "messages", "fr");
t.add("custom.affairs.form.internalId", "ID interne", "messages", "fr");
t.add("custom.affairs.form.text", "Texte", "messages", "fr");
t.add("custom.affairs.form.button.submit", "Enregistrer", "messages", "fr");
t.add("custom.affairs.overview.title", "Dossier interne", "messages", "fr");
t.add("custom.affairs.form.create.title", "Cr\u00e9er un nouveau dossier interne", "messages", "fr");
t.add("custom.affairs.new.title", "Cr\u00e9er un nouveau dossier interne", "messages", "fr");
t.add("custom.affairs.new.error", "Erreur lors de l\u2019enregistrement", "messages", "fr");
t.add("custom.affairs.new.pagetitle", "Cr\u00e9er un nouveau dossier interne", "messages", "fr");
t.add("cantonalAffairs.deposited.tooltip.title", "D\u00e9pos\u00e9", "messages", "fr");
t.add("customAffairs.deposited.tooltip.title", "D\u00e9pos\u00e9", "messages", "fr");
t.add("national.affair.draft.federal.council.proposal.tooltip.title", "Projet Conseil f\u00e9d\u00e9ral", "messages", "fr");
t.add("affairGroup.export.buttonlabel", "Exportation", "messages", "fr");
t.add("customMeetings.editMeetingAgendaPoint.title", "Modifier le sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.editMeetingAgendaPoint.save", "Enregistrer", "messages", "fr");
t.add("customMeetings.assignAgendaPoint.noMeetings", "Aucune s\u00e9ance saisie", "messages", "fr");
t.add("customMeetings.assignAgendaPoint.noAgendaPointsLabel", "Aucun sujet \u00e0 l\u2019ordre du jour saisi", "messages", "fr");
t.add("customMeetings.assignAgendaPoint.empty", "Aucun(e) s\u00e9ance \/ sujet \u00e0 l\u2019ordre du jour saisi(e)", "messages", "fr");
t.add("customMeetings.replicateMeeting.copyOf", "Copier la structure de la s\u00e9ance (sujets \u00e0 l\u2019ordre du jour)", "messages", "fr");
t.add("customMeetings.replicateMeeting.success", "R\u00e9ussi", "messages", "fr");
t.add("customMeetings.tree.customMeeting.newCustomAffair", "Cr\u00e9er un nouveau dossier interne", "messages", "fr");
t.add("customMeetings.tree.customMeeting.replicateCustomMeeting", "Dupliquer", "messages", "fr");
t.add("councillor.topic.count.title", "{1} %topic%: 1 intervention|[2, Inf[ %topic%: %count% interventions", "messages", "fr");
t.add("canton.label", "Param\u00e8tres de la s\u00e9ance: canton standard", "messages", "fr");
t.add("cantonal_faction.label", "Param\u00e8tres de la s\u00e9ance: groupe parlementaire standard", "messages", "fr");
t.add("is_private.label", "Groupe priv\u00e9 (non visible pour tous)", "messages", "fr");
t.add("affairGroups.newGroup.settings.label", "Param\u00e8tres du groupe", "messages", "fr");
t.add("affair.relatedDepartments.title", "D\u00e9partements", "messages", "fr");
t.add("affair.relatedDepartments.desc", "D\u00e9partements", "messages", "fr");
t.add("affair.relatedDepartments.empty", "Aucune attribution", "messages", "fr");
t.add("affairs.editor.placeholder", "(Veuillez cliquer ici pour saisir une description interne)", "messages", "fr");
t.add("selectpicker.noneSelectedText", "Aucune s\u00e9lection effectu\u00e9e...", "messages", "fr");
t.add("custom.affairs.form.html", "Texte", "messages", "fr");
t.add("customMeetings.newMeeting.participants.label", "Absents", "messages", "fr");
t.add("customMeetings.newMeeting.guests.label", "Invit\u00e9s", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.reducedOutputAffairs.label", "R\u00e9sultats limit\u00e9s aux rapports \/ invitations (pas d\u2019historique)", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.type.label", "Type de sujet \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.type.regular.label", "Standard", "messages", "fr");
t.add("customMeetings.newMeetingAgendaPoint.type.affairsWithoutSpeaker.label", "Dossiers sans intervenant(e)", "messages", "fr");
t.add("affair.daysummary", "R\u00e9sum\u00e9 jour", "messages", "fr");
t.add("profile.notifications", "Notifications", "messages", "fr");
t.add("profile.notifications.edit.shortcut", "N", "messages", "fr");
t.add("profile.notifications.edit", "Notifications", "messages", "fr");
t.add("profile.notifications.markAllRead", "Tout marquer comme lu", "messages", "fr");
t.add("profile.notifications.loadMore", "Afficher plus", "messages", "fr");
t.add("profile.notifications.empty", "Aucune notification", "messages", "fr");
t.add("profile.notifications.noMore", "Aucune notification", "messages", "fr");
t.add("notification.subscription.button.subscribed", "Abonn\u00e9", "messages", "fr");
t.add("notification.subscription.button.unSubscribed", "Pas abonn\u00e9", "messages", "fr");
t.add("custom.affairs.form.copiedWithMeeting", "Dupliquer avec s\u00e9ance", "messages", "fr");
t.add("customMeetings.pdf.customSpeakers.label", "Intervenant(e)", "messages", "fr");
t.add("national.committees.list", "Liste des commissions nationales", "messages", "fr");
t.add("national.committees.show", "Commission nationale", "messages", "fr");
t.add("layout.menu.nationalcouncil.commissions", "Commissions CN", "messages", "fr");
t.add("layout.menu.nationalcouncil.commissions.shortcut", "K", "messages", "fr");
t.add("layout.menu.councilofstates.commissions", "Commissions CE", "messages", "fr");
t.add("layout.menu.councilofstates.commissions.shortcut", "K", "messages", "fr");
t.add("customMeetings.pdf.protocol.title", "Proc\u00e8s-verbal", "messages", "fr");
t.add("cantonal.recentInsertedAffairs", "Derniers dossiers cantonaux", "messages", "fr");
t.add("customMeetings.pdf.invitation.title", "Invitation", "messages", "fr");
t.add("vote.novotes", "Aucun r\u00e9sultat de vote d\u00e9taill\u00e9 existant", "messages", "fr");
t.add("national.affairs.overview.title", "Dossiers nationaux", "messages", "fr");
t.add("national.affairs.list.header.title", "Titre", "messages", "fr");
t.add("national.affairs.list.header.author", "Auteur", "messages", "fr");
t.add("national.affairs.list.header.topics", "Sujets", "messages", "fr");
t.add("national.affairs.list.header.status", "Statut", "messages", "fr");
t.add("national.affairs.list.header.depositDate", "Date de d\u00e9p\u00f4t", "messages", "fr");
t.add("reports.overview.title", "Rapports", "messages", "fr");
t.add("reports.source.label", "Source des donn\u00e9es", "messages", "fr");
t.add("reports.from.label", "R\u00e9sultats des dossiers: \u00e0 quel dossier (num\u00e9ro) faut-il commencer? Par d\u00e9faut: vide = 1", "messages", "fr");
t.add("reports.to.label", "R\u00e9sultats des dossiers: \u00e0 quel dossier (num\u00e9ro) faut-il s\u2019arr\u00eater? Par d\u00e9faut: vide = au dernier dossier (tous les dossiers)", "messages", "fr");
t.add("reports.source.placeholder", "Veuillez choisir une source...", "messages", "fr");
t.add("reports.firstNumber.label", "D\u00e9but de la num\u00e9rotation des dossiers dans le PDF (vide=1)", "messages", "fr");
t.add("reports.groups", "Groupes", "messages", "fr");
t.add("reports.groups.label", "Groupe(s)", "messages", "fr");
t.add("reports.error.creation", "Erreur lors de la cr\u00e9ation du rapport. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("layout.menu.reports", "Rapports", "messages", "fr");
t.add("reports.session.canton.zh", "Dossiers s\u00e9ance du Grand Conseil ZH", "messages", "fr");
t.add("affair.cantonalHistoryeventOnSessionAgenda.tooltip.caption", "Ev\u00e9nement cantonal", "messages", "fr");
t.add("affair.cantonalHistoryeventStatuschange.tooltip.caption", "Modification du statut", "messages", "fr");
t.add("custom.affairs.new.success", "Dossier interne cr\u00e9\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("custom.affairs.edit.success", "Dossier interne enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("custom.affairs.delete.success", "Dossier interne supprim\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("custom.affairs.delete.error", "Erreur lors de la suppression", "messages", "fr");
t.add("affair.draftReference.title", "Source du projet", "messages", "fr");
t.add("affair.draftPreConsultation.title", "Examen pr\u00e9alable du projet", "messages", "fr");
t.add("affair.draftPreConsultation.tooltip.caption", "Examen pr\u00e9alable du projet", "messages", "fr");
t.add("dashboard.updates.update_name", "Titre", "messages", "fr");
t.add("dashboard.updates.item_type", "le statu quo", "messages", "fr");
t.add("dashboard.updates.update_type", "Mise \u00e0 jour", "messages", "fr");
t.add("dashboard.updates.update_time", "Date de parution", "messages", "fr");
t.add("dashboard.updates.type.insert", "Nouveau", "messages", "fr");
t.add("dashboard.updates.type.update", "Mis \u00e0 jour", "messages", "fr");
t.add("dashboard.updates.type.remove", "Supprim\u00e9", "messages", "fr");
t.add("dashboard.updates.title", "Mises \u00e0 jour dossiers", "messages", "fr");
t.add("dashboard.custom_updates.title", "Mises \u00e0 jour \u00e9v\u00e9nements", "messages", "fr");
t.add("dashboard.updates.itemType.cantonalAffair", "Dossier cantonal", "messages", "fr");
t.add("dashboard.updates.itemType.councilStatesVote", "Vote Conseil des Etats", "messages", "fr");
t.add("dashboard.updates.itemType.nationalAffair", "Dossier national", "messages", "fr");
t.add("dashboard.updates.itemType.nationalCouncilVote", "Vote Conseil national", "messages", "fr");
t.add("dashboard.updates.itemType.customAffair", "Dossier interne", "messages", "fr");
t.add("dashboard.updates.itemType.customSpeaker", "Intervenant(e)", "messages", "fr");
t.add("dashboard.updates.itemType.affairGroup", "Groupe", "messages", "fr");
t.add("dashboard.updates.itemType.customMeeting", "S\u00e9ance", "messages", "fr");
t.add("dashboard.updates.itemType.customMeetingAgendaPoint", "Sujet \u00e0 l\u2019ordre du jour s\u00e9ance", "messages", "fr");
t.add("affair.timeline", "Axe temporel", "messages", "fr");
t.add("affair.event.details", "D\u00e9tails \u00e9v\u00e9nement", "messages", "fr");
t.add("actions.default", "Actions", "messages", "fr");
t.add("groups.tree.type_select.all", "Tous les groupes", "messages", "fr");
t.add("groups.tree.type_select.public", "Uniquement les groupes non priv\u00e9s", "messages", "fr");
t.add("groups.tree.type_select.private", "Uniquement les groupes priv\u00e9s", "messages", "fr");
t.add("groups.tree.sort_select.alpha", "Trier par ordre alphab\u00e9tique", "messages", "fr");
t.add("groups.tree.sort_select.date", "Trier par date de cr\u00e9ation", "messages", "fr");
t.add("affairGroups.deleteGroup.success", "Groupe supprim\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("layout.affairs_overview.Shortcut", "G", "messages", "fr");
t.add("layout.menu.nationalcouncil.consultations.shortcut", "V", "messages", "fr");
t.add("dashboard.updates.itemType.internalNote", "Note interne", "messages", "fr");
t.add("dashboard.updates.itemType.internalVoteCustomEvent", "Vote interne", "messages", "fr");
t.add("affair.custom.description", "Description", "messages", "fr");
t.add("affair.events", "Ev\u00e9nements", "messages", "fr");
t.add("national.consultations.overview.title", "Consultations nationales", "messages", "fr");
t.add("national.consultations.details.show_all", "Afficher tout", "messages", "fr");
t.add("national.consultations.details.planned", "Consultations pr\u00e9vues", "messages", "fr");
t.add("national.consultations.details.in_progress", "Consultations en cours", "messages", "fr");
t.add("national.consultations.details.closed", "Consultations termin\u00e9es", "messages", "fr");
t.add("national.consultations.details.title", "D\u00e9tails de la consultation", "messages", "fr");
t.add("national.consultations.details.author", "Auteur", "messages", "fr");
t.add("national.consultations.documents", "Documents", "messages", "fr");
t.add("national.consultations.opinions", "Avis \u00e0 les consultations", "messages", "fr");
t.add("national.consultations.details.empty_list", "Aucune consultation", "messages", "fr");
t.add("affair.error.get_event", "Erreur lors de la lecture de l\u2019\u00e9v\u00e9nement", "messages", "fr");
t.add("affair.customEvents.error", "Erreur lors de la lecture des notes", "messages", "fr");
t.add("national.consultations.error", "Erreur lors de la lecture des consultations", "messages", "fr");
t.add("dashboard.updates.itemType.noteCustomEvent", "Note", "messages", "fr");
t.add("affairs.overview.title", "Dossiers", "messages", "fr");
t.add("affair.customEvent.button.delete", "Supprimer", "messages", "fr");
t.add("notifications.not_seen.description", "NOUVEAU", "messages", "fr");
t.add("notifications.not_seen.shortcut", "N", "messages", "fr");
t.add("ui.modal.warning", "Avertissement", "messages", "fr");
t.add("affair_detail.modals.event_delete.text", "Voulez-vous supprimer l\u2019\u00e9v\u00e9nement?", "messages", "fr");
t.add("ui.confirm", "Oui", "messages", "fr");
t.add("ui.abort", "Annuler", "messages", "fr");
t.add("ui.breadcrumb.home", "D\u00e9but", "messages", "fr");
t.add("ui.affair.managed", "dans le groupe", "messages", "fr");
t.add("affair.type.abbr", "Abr\u00e9viation", "messages", "fr");
t.add("affair.code", "Code", "messages", "fr");
t.add("affair.shortIdParlService", "Num\u00e9ro Curia Vista", "messages", "fr");
t.add("affair.badge.private", "Dossier commission", "messages", "fr");
t.add("commission_detail.events.empty", "Aucune entr\u00e9e", "messages", "fr");
t.add("commission_detail.lastProgramLink", "Lien vers le dernier programme (PDF)", "messages", "fr");
t.add("commission_detail.overviewPageLink", "Lien vers la page officielle de la Commission", "messages", "fr");
t.add("affairs.list.empty", "Aucune entr\u00e9e", "messages", "fr");
t.add("affair_list.select.type.label", "Type", "messages", "fr");
t.add("affair_list.type_select.type.all", "Tous", "messages", "fr");
t.add("affair_list.select.type.national", "Dossiers nationaux", "messages", "fr");
t.add("affair_list.select.type.cantonal", "Dossiers cantonaux", "messages", "fr");
t.add("affair_list.select.date.last6months", "6 derniers mois", "messages", "fr");
t.add("affair_list.select.date.last12months", "12 derniers mois", "messages", "fr");
t.add("affair_list.select.date_after", "La date se situe avant", "messages", "fr");
t.add("affair_list.select.date_before", "La date se situe apr\u00e8s", "messages", "fr");
t.add("ui.select.groups", "Groupes", "messages", "fr");
t.add("ui.select.groups.placeholder", "Groupes", "messages", "fr");
t.add("affair_list.select.events.label", "Ev\u00e9nements", "messages", "fr");
t.add("affair_list.select.events.all", "Tous", "messages", "fr");
t.add("affair_list.select.events.note", "Avec notes", "messages", "fr");
t.add("affair_list.select.events.internal_vote", "Avec votes internes", "messages", "fr");
t.add("ui.select.status.placeholder", "Filtrer par statut...", "messages", "fr");
t.add("ui.select.status.national", "Statut national", "messages", "fr");
t.add("ui.select.status.cantonal", "Statut cantonal", "messages", "fr");
t.add("panel.detailsLink.label", "D\u00e9tails", "messages", "fr");
t.add("timeline.tooltip.affair_link", "Vers le dossier", "messages", "fr");
t.add("ui.table.sort.asc", "Croissant", "messages", "fr");
t.add("ui.table.sort.desc", "D\u00e9croissant", "messages", "fr");
t.add("ui.table.sort.asc.desc", "Tri d\u00e9croissant", "messages", "fr");
t.add("dashboard.error.get_entries", "Erreur lors du chargement des entr\u00e9es", "messages", "fr");
t.add("notifications.error.get", "Erreur lors du chargement des notifications", "messages", "fr");
t.add("notifications.error.set_seen", "Erreur lors du r\u00e9glage du statut des notifications", "messages", "fr");
t.add("councillors.error.get", "Erreur lors de la recherche du parlementaire", "messages", "fr");
t.add("ui.select.councillors.placeholder", "Choisir parlementaire", "messages", "fr");
t.add("ui.select.councillors.label", "Parlementaires", "messages", "fr");
t.add("commissions.link.commission", "Vers la commission", "messages", "fr");
t.add("commissions.link.overview", "Vers la commission", "messages", "fr");
t.add("commissions.link.program", "Vers la planification de s\u00e9ance", "messages", "fr");
t.add("calendar.events.empty", "Aucune entr\u00e9e", "messages", "fr");
t.add("security.saml.discovery", "Login SSO", "messages", "fr");
t.add("textEditor.small", "Petit", "messages", "fr");
t.add("textEditor.large", "Normal", "messages", "fr");
t.add("textEditor.huge", "Grand", "messages", "fr");
t.add("affair.nationalAffairDraftResolution.tooltip.caption", "Projet document", "messages", "fr");
t.add("affair.nationalAffairDraftPreConsultation.tooltip.caption", "Examen pr\u00e9alable", "messages", "fr");
t.add("event.calendar.title", "Calendrier", "messages", "fr");
t.add("layout.search.error", "Erreur lors de la recherche", "messages", "fr");
t.add("layout.search.recent_affairs_national", "Derniers dossiers national", "messages", "fr");
t.add("layout.search.recent_affairs_cantonal", "Derniers dossiers cantonal", "messages", "fr");
t.add("timeline.tooltip.event_link", "Vers le dossier", "messages", "fr");
t.add("affair.nationalHistoryeventStatuschange.tooltip.caption", "Statut modifi\u00e9", "messages", "fr");
t.add("affair.nationalAffairDraftReference.tooltip.caption", "Projet document", "messages", "fr");
t.add("affair.nationalHistoryeventSessionProgram.tooltip.caption", "Pr\u00e9vu: traitement en session", "messages", "fr");
t.add("affair.nationalCouncilAffairDebate.tooltip.caption", "D\u00e9bat CN", "messages", "fr");
t.add("affair.nationalAffairDraftFederalCouncilProposal.tooltip.caption", "Projet Conseil f\u00e9d\u00e9ral", "messages", "fr");
t.add("affair.nationalHistoryeventCommitteeProgram.tooltip.caption", "Pr\u00e9vu: traitement en commission", "messages", "fr");
t.add("event.reminders.title", "Rappels", "messages", "fr");
t.add("event.reminders.button.save", "Enregistrer", "messages", "fr");
t.add("event.reminders.button.delete", "Supprimer", "messages", "fr");
t.add("event.reminders.button.add", "Ajouter", "messages", "fr");
t.add("event.reminders.button.subscribed", "Rappel cr\u00e9\u00e9", "messages", "fr");
t.add("event.reminders.button.unsubscribed", "Cr\u00e9er un rappel", "messages", "fr");
t.add("api.error.get_event_reminder", "Erreur lors de la lecture des rappels. Veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch", "messages", "fr");
t.add("api.groups.get.error", "Erreur lors de la lecture des groupes. Veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch", "messages", "fr");
t.add("event.reminders.edit.success", "Enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("event.reminders.edit.error", "Erreur lors de l\u2019enregistrement du rappel. Veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch", "messages", "fr");
t.add("event.reminders.create.error", "Erreur lors de l\u2019enregistrement du rappel. Veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch", "messages", "fr");
t.add("event.reminders.delete.error", "Erreur lors de la suppression du rappel. Veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch", "messages", "fr");
t.add("event.reminders.empty", "Aucun rappel", "messages", "fr");
t.add("event.reminders.input.date", "Date", "messages", "fr");
t.add("event.reminders.input.data", "Rappel cr\u00e9\u00e9", "messages", "fr");
t.add("search.error.get_cantons", "Erreur lors de l\u2019\u00e9tablissement de la recherche. Veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch", "messages", "fr");
t.add("search.error.get_national_affair_types", "Erreur lors de l\u2019\u00e9tablissement de la recherche. Veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch", "messages", "fr");
t.add("search.error.get_cantonal_affair_types", "Erreur lors de l\u2019\u00e9tablissement de la recherche. Veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch", "messages", "fr");
t.add("layout.search.pagination.previous", "Pr\u00e9c\u00e9dent", "messages", "fr");
t.add("layout.search.pagination.next", "Suivante", "messages", "fr");
t.add("affairs.search.title", "Recherche", "messages", "fr");
t.add("national.consultations.list", "Consultations", "messages", "fr");
t.add("national.councillors.list", "Parlementaires", "messages", "fr");
t.add("affair.events.custom.create.error", "Erreur lors de l\u2019enregistrement. Veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch", "messages", "fr");
t.add("affair.events.custom.create.success", "Ev\u00e9nement enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("reports.groups.placeholder", "Groupe(s)", "messages", "fr");
t.add("eventReminder.event.notFound", "Ev\u00e9nement non trouv\u00e9", "messages", "fr");
t.add("reports.groups.outputDescription.label", "Description", "messages", "fr");
t.add("reports.groups.outputStatus.label", "Statut", "messages", "fr");
t.add("reports.groups.outputLink.label", "Lien vers la source", "messages", "fr");
t.add("reports.groups.outputSpeaker.label", "Intervenant(e)", "messages", "fr");
t.add("reports.groups.outputInternalDescription.label", "Description", "messages", "fr");
t.add("reports.groups.outputHistory.label", "Ev\u00e9nements", "messages", "fr");
t.add("affairGroup.export.header.speaker", "Intervenant(e)", "messages", "fr");
t.add("affairGroup.export.header.internalDescription", "Description", "messages", "fr");
t.add("affairGroup.export.header.history", "Ev\u00e9nements", "messages", "fr");
t.add("reports.groups.outputHistory.none", "Aucun \u00e9v\u00e9nement", "messages", "fr");
t.add("reports.groups.outputHistory.customEvents", "Uniquement \u00e9v\u00e9nements internes (notes, votes)", "messages", "fr");
t.add("reports.groups.outputHistory.all", "Tous les \u00e9v\u00e9nements", "messages", "fr");
t.add("reports.formats.label", "Format", "messages", "fr");
t.add("reports.format.excel", "Excel (XLS)", "messages", "fr");
t.add("reports.format.pdf", "PDF", "messages", "fr");
t.add("affairgroups.pdf.title", "Rapport", "messages", "fr");
t.add("customMeetingAgendaPoints.form.title.cannotBeEmpty", "Un titre doit \u00eatre d\u00e9fini", "messages", "fr");
t.add("commission_detail.affairs", "Dossiers des commissions", "messages", "fr");
t.add("cantonal.commissions.empty", "Pas de commissions", "messages", "fr");
t.add("api.councillors.get.error", "Erreur lors de la consultation du parlementaire. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("profile.show.language", "Langue", "messages", "fr");
t.add("profile.settings.label", "Param\u00e8tres", "messages", "fr");
t.add("email_footer.label", "Footer de l\u2019e-mail de notification", "messages", "fr");
t.add("show_company_logo_and_name.label", "Branding de l\u2019application (coin sup\u00e9rieur gauche)", "messages", "fr");
t.add("show_company_logo_and_name.both", "Afficher le logo et le nom", "messages", "fr");
t.add("show_company_logo_and_name.logo", "Uniquement le logo", "messages", "fr");
t.add("show_company_logo_and_name.name", "Uniquement le nom", "messages", "fr");
t.add("profile.settings.language.label", "Langue", "messages", "fr");
t.add("profile.settings.language.de", "Allemand \/ Deutsch", "messages", "fr");
t.add("profile.settings.language.fr", "Fran\u00e7ais \/ Franz\u00f6sisch", "messages", "fr");
t.add("affair.title.ratsunterlagen", "Documents du Conseil", "messages", "fr");
t.add("layout.maintenance.siteIsDown", "Nous proc\u00e9dons actuellement \u00e0 des travaux de maintenance pr\u00e9vus.", "messages", "fr");
t.add("layout.maintenance.beBackSoon", "politique.ch sera \u00e0 nouveau disponible dans les plus brefs d\u00e9lais. Pour toute question, vous pouvez contacter %email%. Nous vous remercions de votre compr\u00e9hension.", "messages", "fr");
t.add("consultations.nohistoryEvents", "Aucun \u00e9v\u00e9nement", "messages", "fr");
t.add("ui.filter.all", "Tous", "messages", "fr");
t.add("customMeetings.editMeeting.error", "Erreur lors de l\u2019\u00e9dition des sessions. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("profile.settings.navigation.auth", "Authentification \u00e0 2 facteurs", "messages", "fr");
t.add("profile.edit.2fa.authMode.label", "S\u00e9lectionner le deuxi\u00e8me facteur", "messages", "fr");
t.add("profile.edit.2fa.none", "Aucun (d\u00e9sactiver l\u2019authentification \u00e0 2 facteurs)", "messages", "fr");
t.add("profile.edit.2fa.email", "E-mail", "messages", "fr");
t.add("profile.edit.2fa.google", "OTP (application d\u2019authentification, p. ex. Google Authenticator)", "messages", "fr");
t.add("profile.edit.2fa.pleaseReadThisQRCode", "Veuillez scanner le code QR:", "messages", "fr");
t.add("profile.edit.2fa.googleCode.label", "Veuillez saisir un code actuel", "messages", "fr");
t.add("profile.edit.2fa.thisIsYourGoogleAuthenticationCode", "Code de sauvegarde:", "messages", "fr");
t.add("profile.edit.2fa.pleaseStoreItInASafePlace", "veuillez enregistrer ce code dans un endroit s\u00fbr. Vous pouvez l\u2019utiliser pour vous connecter si vous n\u2019avez plus acc\u00e8s \u00e0 vos codes via l\u2019application.", "messages", "fr");
t.add("affairs.list.no_access", "Aucun acc\u00e8s", "messages", "fr");
t.add("profile.edit.2fa.invalidGoogleCode", "Code invalide. Veuillez saisir un code valide de l\u2019application d\u2019authentification.", "messages", "fr");
t.add("national.consultations.events.start", "D\u00e9but de la consultation", "messages", "fr");
t.add("national.consultations.events.end", "Fin de la consultation", "messages", "fr");
t.add("ranking.councillorscompare.noVotesInSelectedTimespan", "Aucun vote au cours de la p\u00e9riode s\u00e9lectionn\u00e9e.", "messages", "fr");
t.add("reports.reportType", "Sources du rapport", "messages", "fr");
t.add("layout.search.contentType.affairConsultations", "Consultations", "messages", "fr");
t.add("rankings.form.wholeLegislativeperiod", "Votes de toute la l\u00e9gislature", "messages", "fr");
t.add("affairGroup.overview.consultations.title", "Consultations", "messages", "fr");
t.add("dashboard.updates.default_title", "Titre", "messages", "fr");
t.add("dashboard.updates.itemType.nationalAffairConsultation", "Consultation nationale", "messages", "fr");
t.add("ui.table.sort.asc.asc", "Tri croissant", "messages", "fr");
t.add("ui.date_picker.future", "toutes les consultations futures", "messages", "fr");
t.add("ui.date_picker.past", "toutes les consultations pass\u00e9es", "messages", "fr");
t.add("ui.date_picker.calendar.ranges.12months", "12 mois", "messages", "fr");
t.add("ui.date_picker.calendar.ranges.6months", "6 mois", "messages", "fr");
t.add("ui.date_picker.calendar.ranges", "Domaine", "messages", "fr");
t.add("national.affairs.list.header.filter", "filtrer", "messages", "fr");
t.add("ui.text_editor.image.exceeds", "La taille de l\u2019image d\u00e9passe le maximum de 5 Mo.", "messages", "fr");
t.add("profile.settings.placeholder", "-", "messages", "fr");
t.add("layout.search.contentType.affairConsultation", "Consultations", "messages", "fr");
t.add("links.type.linker.mentions", "mentionn\u00e9", "messages", "fr");
t.add("links.type.linked.isMentionedBy", "a \u00e9t\u00e9 mentionn\u00e9", "messages", "fr");
t.add("links.type.linker.precedes", "dossier ant\u00e9rieur", "messages", "fr");
t.add("links.type.linked.succeeds", "dossier suivant", "messages", "fr");
t.add("links.type.linker.relates", "Se r\u00e9f\u00e8re \u00e0", "messages", "fr");
t.add("links.type.linked.relates", "a \u00e9t\u00e9 cit\u00e9 en r\u00e9f\u00e9rence", "messages", "fr");
t.add("links.public", "Officiel", "messages", "fr");
t.add("affair_detail.links.title", "Dossiers li\u00e9s", "messages", "fr");
t.add("ui.delete", "supprimer", "messages", "fr");
t.add("api.links.get.error", "Erreur lors de la consultation des liens relatifs aux dossiers. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("links.edit_form.title", "Modifier les dossiers li\u00e9s", "messages", "fr");
t.add("links.edit_form.list_empty", "Aucun dossier li\u00e9", "messages", "fr");
t.add("api.link_types.get.error", "Erreur lors de la consultation des liens relatifs aux dossiers. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("api.search.error", "Erreur lors de la consultation des r\u00e9sultats de recherche. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("links.edit_form.input.public.label", "Officiel?", "messages", "fr");
t.add("links.edit_form.input.public.true", "Oui, officiel", "messages", "fr");
t.add("links.edit_form.input.public.false", "Non, seulement interne (organisation\/entreprise)", "messages", "fr");
t.add("links.edit_form.input.link_type.label", "Type de lien", "messages", "fr");
t.add("links.edit_form.input.entity.label", "Lier avec", "messages", "fr");
t.add("ui.submit", "Enregistrer", "messages", "fr");
t.add("links.delete.confirm", "Voulez-vous vraiment supprimer le lien?", "messages", "fr");
t.add("ui.select.loading", "chargement en cours...", "messages", "fr");
t.add("affair.nationalHistoryeventCommitteePressRelease.tooltip.caption", "Communiqu\u00e9 de presse Commission", "messages", "fr");
t.add("committee.press_releases.title", "Communiqu\u00e9s de presse Commission", "messages", "fr");
t.add("committee.pressRelease", "Communiqu\u00e9 de presse", "messages", "fr");
t.add("api.press_releases.get.error", "Erreur lors du chargement des communiqu\u00e9s de presse. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("press_release.originalSource", "Source", "messages", "fr");
t.add("affair_detail.actions.entityLinks", "Lier des dossier", "messages", "fr");
t.add("affair.nationalConsultationEndEvent.tooltip.caption", "Fin de la consultation", "messages", "fr");
t.add("affair.nationalConsultationStartEvent.tooltip.caption", "D\u00e9but de la consultation", "messages", "fr");
t.add("fos_user.password.isPwned", "Mot de passe non s\u00fbr \/ apparu dans des piratages", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.html.get.error", "Erreur de lecture. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.html.put.success", "Enregistr\u00e9 avec succ\u00e8s.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.html.put.error", "Erreur lors de l\u2019enregistrement. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.html.post.success", "Enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.html.post.error", "Erreur lors de l\u2019enregistrement. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.ratings.get.error", "Erreur de lecture. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.ratings.put.error", "Erreur lors de l\u2019enregistrement. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.ratings.post.error", "Erreur lors de l\u2019enregistrement. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("profile.settings.comapny.custom_fields.html.labelDe", "D\u00e9signation Allemand", "messages", "fr");
t.add("profile.settings.comapny.custom_fields.html.labelFr", "D\u00e9signation Fran\u00e7ais", "messages", "fr");
t.add("profile.settings.comapny.custom_fields.html.labelIt", "D\u00e9signation Italien", "messages", "fr");
t.add("profile.settings.comapny.custom_fields.rating.labelDe", "D\u00e9signation Allemand", "messages", "fr");
t.add("profile.settings.comapny.custom_fields.rating.labelFr", "D\u00e9signation Fran\u00e7ais", "messages", "fr");
t.add("profile.settings.comapny.custom_fields.rating.labelIt", "D\u00e9signation Italien", "messages", "fr");
t.add("profile.settings.company.internal_fields.ratings.choice.default", "Valeur par d\u00e9faut", "messages", "fr");
t.add("ui.add", "Ajouter", "messages", "fr");
t.add("profile.settings.save.failure", "Erreur lors de l\u2019enregistrement. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("profile.settings.compay.internal_fields.delete.confirm", "Etes-vous s\u00fbr de vouloir supprimer le champ?", "messages", "fr");
t.add("profile.settings.company.custom_fields.label", "Champs d\u00e9finis par l\u2019utilisateur", "messages", "fr");
t.add("affair.internal_fields.title", "Autres", "messages", "fr");
t.add("profile.settings.company.custom_fields.html.add", "Nouveau champ de texte", "messages", "fr");
t.add("profile.settings.company.custom_fields.rating.add", "Nouveau champ d\u2019options", "messages", "fr");
t.add("profile.settings.company.internal_fields.rating.choices.add", "Ajouter une option", "messages", "fr");
t.add("profile.settings.compay.internal_fields.ratings.option.delete.confirm", "Voulez-vous vraiment supprimer l\u2019option?", "messages", "fr");
t.add("layout.search.contentType.affair_consultation", "Consultations", "messages", "fr");
t.add("api.cantons.get.error", "Erreur lors de l\u2019\u00e9tablissement de la recherche. Veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch", "messages", "fr");
t.add("ui.select.agenda_points", "S\u00e9ances", "messages", "fr");
t.add("affair_detail.links.empty", "Aucun dossier li\u00e9", "messages", "fr");
t.add("forms.speakers.input.councillor.national_council", "Conseiller national", "messages", "fr");
t.add("forms.speakers.input.councillor.states_council", "Conseil des Etats", "messages", "fr");
t.add("profile.settings.company.logo.upload.error.size", "L\u2019image t\u00e9l\u00e9charg\u00e9e est trop grande. Veuillez utiliser une r\u00e9solution plus petite.", "messages", "fr");
t.add("profile.settings.company.internal_fields.ratings.labelDe", "D\u00e9signation DE", "messages", "fr");
t.add("profile.settings.company.internal_fields.ratings.labelFr", "D\u00e9signation FR", "messages", "fr");
t.add("profile.settings.company.internal_fields.ratings.labelIt", "D\u00e9signation IT", "messages", "fr");
t.add("profile.settings.company.internal_fields.html.labelDe", "D\u00e9signation DE", "messages", "fr");
t.add("profile.settings.company.internal_fields.html.labelFr", "D\u00e9signation FR", "messages", "fr");
t.add("profile.settings.company.internal_fields.html.labelIt", "D\u00e9signation IT", "messages", "fr");
t.add("form.speakers.input.councillor.cantonal", "Conseil cantonal \/ Grand Conseil", "messages", "fr");
t.add("national.consultations.description.header", "Description", "messages", "fr");
t.add("timeline.settings.timespans", "Ev\u00e9nements de plusieurs jours", "messages", "fr");
t.add("timeline.settings.timespans.showend", "Afficher", "messages", "fr");
t.add("timeline.settings.timespans.hideend", "Ne pas afficher", "messages", "fr");
t.add("national.consultations.events.start.timespan", "P\u00e9riode de consultation", "messages", "fr");
t.add("custom.affairs.table.caption", "Dossiers internes", "messages", "fr");
t.add("affair.councilStatesVote.tooltip.caption", "Vote Conseil des Etats", "messages", "fr");
t.add("form.current_password", "Mot de passe actuel", "messages", "fr");
t.add("form.new_password", "Nouveau mot de passe", "messages", "fr");
t.add("form.new_password_confirmation", "Confirmer le nouveau mot de passe", "messages", "fr");
t.add("affair.nationalHistoryeventCommitteeProgramStart.tooltip.caption", "Pr\u00e9vu: traitement en commission", "messages", "fr");
t.add("affair.nationalAffairConsultationEndEvent.tooltip.caption", "Consultation nationale: fin", "messages", "fr");
t.add("affair.nationalAffairConsultationStartEvent.tooltip.caption", "Consultation nationale: d\u00e9but", "messages", "fr");
t.add("ui.select.meetings.placeholder", "S\u00e9ances", "messages", "fr");
t.add("affair.nationalAffairNoteCustomEvent.tooltip.caption", "Note interne", "messages", "fr");
t.add("affair.statusChangeNationalAffairEvent.tooltip.caption", "Modification du statut", "messages", "fr");
t.add("affair.nationalCommitteeProgramStartNationalAffairEvent.tooltip.caption", "D\u00e9but du traitement en commission", "messages", "fr");
t.add("affair.nationalCommitteePressReleaseNationalAffairEvent.tooltip.caption", "Communiqu\u00e9 de presse Commission", "messages", "fr");
t.add("affair.nationalHistoryeventCommitteeProgramEnd.tooltip.caption", "Pr\u00e9vu: traitement en commission (fin)", "messages", "fr");
t.add("affair.nationalCommitteeProgramEndNationalAffairEvent.tooltip.caption", "Pr\u00e9vu: traitement en commission (fin)", "messages", "fr");
t.add("affair.nationalSessionProgramNationalAffairEvent.tooltip.caption", "Programme de session national", "messages", "fr");
t.add("affair.nationalCommitteeProgramNationalAffairEvent.tooltip.caption", "Programme de commission national", "messages", "fr");
t.add("affair.NationalAffairInternalVoteCustomEvent.tooltip.caption", "Vote interne", "messages", "fr");
t.add("affair.nationalHistoryeventCommitteeProgramRemoved.tooltip.caption", "Supprim\u00e9 du programme de commission", "messages", "fr");
t.add("user.password.isPwned", "Mot de passe non s\u00fbr \/ apparu dans des piratages", "messages", "fr");
t.add("cantonal.overview.sessions", "S\u00e9ances \/ sessions du Parlement", "messages", "fr");
t.add("cantonal.listSessions.title", "S\u00e9ances \/ sessions du Parlement", "messages", "fr");
t.add("cantonal.sessions.table.date", "Date", "messages", "fr");
t.add("cantonal.sessions.table.canton", "Canton", "messages", "fr");
t.add("cantonal.sessions.table.link", "Lien source", "messages", "fr");
t.add("cantonal.sessions.table.events", "Nombre de dossiers", "messages", "fr");
t.add("cantonal.sessions.title", "D\u00e9tails de la session \/ s\u00e9ance", "messages", "fr");
t.add("forms.speakers.input.councillor.cantonal", "Intervenant(e)", "messages", "fr");
t.add("councils.nr", "au Conseil National", "messages", "fr");
t.add("councils.sr", "au Conseil des Etats", "messages", "fr");
t.add("cantonal.session.events.empty", "Aucun dossier", "messages", "fr");
t.add("api.cantonal.sessions.get.error", "Erreur lors de la consultation des sessions\/s\u00e9ances cantonales", "messages", "fr");
t.add("cantonal.faction.affairs.title", "Dossiers du groupe parlementaire", "messages", "fr");
t.add("cantonal.affairs.year.select.label", "S\u00e9lection de l\u2019ann\u00e9e", "messages", "fr");
t.add("cantonal.consultations.attachments", "Annexes", "messages", "fr");
t.add("affair.CantonalAffairConsultationEndEvent.tooltip.caption", "Fin de la consultation cantonale", "messages", "fr");
t.add("affair.CantonalAffairConsultationStartEvent.tooltip.caption", "D\u00e9but de la consultation cantonale", "messages", "fr");
t.add("cantonal.consultations.description.header", "Description", "messages", "fr");
t.add("cantonal.consultations.get.error", "Erreur lors de la consultation des consultations cantonales", "messages", "fr");
t.add("dashboard.updates.itemType.cantonalAffairConsultationInternalNote", "Note interne relative \u00e0 la consultation cantonale", "messages", "fr");
t.add("links.edit_form.input.entity.placeholder", "S\u00e9lectionner un dossier...", "messages", "fr");
t.add("ui.forms.confirm.title", "Titre confirm\u00e9", "messages", "fr");
t.add("event.reminders.create.success", "Rappel enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("cantonal.overview.consultations.planned", "Consultations: pr\u00e9vues", "messages", "fr");
t.add("cantonal.overview.consultations.in_progress", "Consultations: en cours", "messages", "fr");
t.add("cantonal.overview.consultations.closed", "Consultations: termin\u00e9es", "messages", "fr");
t.add("cantonal.consultations.overview.title", "Consultations cantonales", "messages", "fr");
t.add("cantonal.consultations.list", "Consultations cantonales", "messages", "fr");
t.add("settings.company.affair_meetings_show.label", "Afficher \/ utiliser les s\u00e9ances?", "messages", "fr");
t.add("userGroups.builtIn.companyAdmins.title", "Administrateur", "messages", "fr");
t.add("userGroups.builtIn.ro.title", "Lecture seule groupe", "messages", "fr");
t.add("userGroups.builtIn.rw.title", "Lecture \/ \u00e9criture groupe", "messages", "fr");
t.add("committee.press_releases.table.header.title", "Titre", "messages", "fr");
t.add("committee.press_releases.table.header.date", "Date", "messages", "fr");
t.add("committee.press_releases.table.header.link", "Lien", "messages", "fr");
t.add("cantonal.councillors.table.name", "Nom", "messages", "fr");
t.add("cantonal.councillors.table.party", "Parti", "messages", "fr");
t.add("cantonal.councillors.table.faction", "Groupe parlementaire", "messages", "fr");
t.add("affair.nationalCommitteeAffair.tooltip.caption", "Dossier commission", "messages", "fr");
t.add("affair.nationalCommitteeProgramRemovedNationalAffairEvent.tooltip.caption", "Dossier du programme de commission supprim\u00e9", "messages", "fr");
t.add("affairGroup.export.header.internalFields", "Champs d\u00e9finis par l\u2019utilisateur", "messages", "fr");
t.add("timeline.settings.order.asc", "Trier dans l\u2019ordre croissant", "messages", "fr");
t.add("timeline.settings.order.desc", "Trier dans l\u2019ordre d\u00e9croissant", "messages", "fr");
t.add("reports.nationalSessions.label", "Session", "messages", "fr");
t.add("reports.format.docx", "Word (DOCX)", "messages", "fr");
t.add("reports.form.error.oneAffairGroupOrNationalSessio", "Veuillez s\u00e9lectionner au moins un groupe ou une session.", "messages", "fr");
t.add("timeline.settings.order", "Tri", "messages", "fr");
t.add("committee.press_releases.table.header.author", "Auteur", "messages", "fr");
t.add("dashboard.updates.itemType.nationalAffairConsultationInternalNote", "Note relative \u00e0 une consultation nationale", "messages", "fr");
t.add("resetting.check_email", "Si l\u2019identifiant existe, un lien pour r\u00e9initialiser le mot de passe sera envoy\u00e9.", "messages", "fr");
t.add("reports.nationalsessions.placeholder", "Aucune s\u00e9lection effectu\u00e9e...", "messages", "fr");
t.add("reports.nationSessions.label", "Session", "messages", "fr");
t.add("api.keywords.update.success", "Mots-cl\u00e9s mis \u00e0 jour avec succ\u00e8s.", "messages", "fr");
t.add("ui.select.user_groups.label", "Groupes d\u2019utilisateurs", "messages", "fr");
t.add("reports.nationalSessionProgram.title", "Rapport de la session", "messages", "fr");
t.add("reports.nationalSessionProgram.affairInGroups", "Groupe", "messages", "fr");
t.add("reports.nationalSessionProgram", "Rapport de la session", "messages", "fr");
t.add("layout.affair_groups.created", "Groupe cr\u00e9\u00e9", "messages", "fr");
t.add("form.label.delete", "Supprimer", "messages", "fr");
t.add("affair.statusChangeCantonalAffairEvent.tooltip.caption", "Modification du statut", "messages", "fr");
t.add("ui.table.header.actions.group_assign.description", "Attribuer un\/des groupe(s)", "messages", "fr");
t.add("ui.table.header.actions.group_assign.manage_groups", "Modifier les groupes", "messages", "fr");
t.add("ui.table.header.actions.group_assign", "Ajouter des groupes", "messages", "fr");
t.add("api.user.edit.error", "Erreur lors de l\u2019enregistrement", "messages", "fr");
t.add("api.user.edit.success", "Edit\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("reports.form.error.oneAffairGroupOrNationalSession", "Veuillez s\u00e9lectionner un groupe ou une session", "messages", "fr");
t.add("cantonal.parties.table.party.label", "Parti", "messages", "fr");
t.add("cantonal.parties.table.councillors.label", "Parlementaires", "messages", "fr");
t.add("timeline.actions.expand", "Ouvrir", "messages", "fr");
t.add("ui.table.filter.empty", "Aucune valeur d\u00e9finie", "messages", "fr");
t.add("cantonal.consultations.details.title", "Consultation cantonale", "messages", "fr");
t.add("affair_list.search", "Recherche", "messages", "fr");
t.add("timeline.actions.collapse", "Fermer", "messages", "fr");
t.add("profile.show.actions.download_agb", "T\u00e9l\u00e9charger les CG", "messages", "fr");
t.add("committee.events.title", "Commission", "messages", "fr");
t.add("committee.events.table.header.date", "Date", "messages", "fr");
t.add("committee.events.table.header.title", "Titre", "messages", "fr");
t.add("committee.events.table.header.status", "Statut", "messages", "fr");
t.add("ui.date_picker.calendar.options.clear", "R\u00e9initialiser", "messages", "fr");
t.add("reports.groups.outputLinkedAffairs.label", "Editer les dossiers li\u00e9s", "messages", "fr");
t.add("reports.groups.outputLinkedAffairs.none", "Ne pas \u00e9diter", "messages", "fr");
t.add("reports.groups.outputLinkedAffairs.reference", "Uniquement avec r\u00e9f\u00e9rence", "messages", "fr");
t.add("reports.groups.outputLinkedAffairs.full", "Complet", "messages", "fr");
t.add("reports.affair.section.linkedAffairs.title", "Dossiers li\u00e9s", "messages", "fr");
t.add("reports.affair.section.customLinkedAffairs.title", "Dossiers li\u00e9s en interne", "messages", "fr");
t.add("ui.date_picker.calendar.input_end.label", "Date de fin", "messages", "fr");
t.add("ui.date_picker.calendar.input_start.label", "Date de d\u00e9but", "messages", "fr");
t.add("cantonal.councillors.table.first_name", "Pr\u00e9nom", "messages", "fr");
t.add("cantonal.councillors.table.last_name", "Nom", "messages", "fr");
t.add("reports.groups.outputEmptyGroups.label", "Groupes sans contenu", "messages", "fr");
t.add("reports.groups.outputId.label", "ID", "messages", "fr");
t.add("reports.groups.outputCanton.label", "Canton", "messages", "fr");
t.add("reports.groups.outputTitle.label", "Titre", "messages", "fr");
t.add("reports.groups.outputAuthors.label", "Auteur", "messages", "fr");
t.add("reports.groups.outputDepartment.label", "D\u00e9partement", "messages", "fr");
t.add("reports.groups.outputDepositDate.label", "Date de d\u00e9p\u00f4t", "messages", "fr");
t.add("reports.groups.outputLastEventDate.label", "Date du dernier \u00e9v\u00e9nement", "messages", "fr");
t.add("reports.depositDate.label", "Date de d\u00e9p\u00f4t", "messages", "fr");
t.add("reports.lastEventDate.label", "Date du dernier \u00e9v\u00e9nement", "messages", "fr");
t.add("reports.affairTypes.label", "Types de dossiers", "messages", "fr");
t.add("reports.affairTypes.nationalAffair", "Dossiers nationaux", "messages", "fr");
t.add("reports.affairTypes.nationalAffairConsultation", "Consultations nationales", "messages", "fr");
t.add("reports.affairTypes.cantonalAffairConsultation", "Consultations cantonales", "messages", "fr");
t.add("reports.affairTypes.cantonalAffair", "Dossiers cantonaux", "messages", "fr");
t.add("reports.affairTypes.customAffair", "Dossiers internes", "messages", "fr");
t.add("reports.affair.depositDate", "Date de d\u00e9p\u00f4t", "messages", "fr");
t.add("reports.affair.lastEventDate", "Date du dernier \u00e9v\u00e9nement", "messages", "fr");
t.add("profile.settings.company.user_groups.label", "Autorisations \/ Groupes d\u2019utilisateurs", "messages", "fr");
t.add("profile.settings.user_groups.user_select", "Utilisateurs attribu\u00e9s", "messages", "fr");
t.add("profile.settings.company.user_groups.add", "Enregistrer le groupe d\u2019utilisateurs", "messages", "fr");
t.add("reports.customAffair.type", "Dossier interne", "messages", "fr");
t.add("reports.affair.authors", "Auteur", "messages", "fr");
t.add("reports.affair.department", "D\u00e9partement", "messages", "fr");
t.add("reports.section.customHistory", "Notes internes", "messages", "fr");
t.add("reports.section.internalFields", "Champs d\u00e9finis par l\u2019utilisateur", "messages", "fr");
t.add("dashboard.updates.itemType.cantonalAffairConsultation", "Consultation cantonale", "messages", "fr");
t.add("dashboard.updates.itemType.nationalAffairInternalNote", "Note", "messages", "fr");
t.add("profile.settings.company.terms_of_service.label", "Fen\u00eatre de notification page d\u2019accueil", "messages", "fr");
t.add("dashboard.terms_of_service.label", "Bienvenue! Veuillez cliquer ici pour lire le message d\u2019accueil et les CG.", "messages", "fr");
t.add("groups.tree.sort.alphabetical", "Alphab\u00e9tique", "messages", "fr");
t.add("groups.tree.sort.date", "Date", "messages", "fr");
t.add("customMeetings.tree.sort.alphabetical", "Alphab\u00e9tique", "messages", "fr");
t.add("customMeetings.tree.sort.date", "Date", "messages", "fr");
t.add("calendar.type.select.option.full", "Mode calendrier", "messages", "fr");
t.add("calendar.type.select.option.day", "Mode liste", "messages", "fr");
t.add("calendar.day.load_history_events", "Charger les entr\u00e9es ant\u00e9rieures", "messages", "fr");
t.add("calendar.day.events.no_more", "Pas d\u2019autres entr\u00e9es", "messages", "fr");
t.add("consultation.submittedByOn", "D\u00e9pos\u00e9 par %councillors%. Ouverture le %date%", "messages", "fr");
t.add("calendar.type.select.label", "Mode", "messages", "fr");
t.add("dashboard.updates.itemType.customAffairInternalNote", "Note", "messages", "fr");
t.add("affair.cantonalSessionProgramStartCantonalAffairEvent.tooltip.caption", "Enregistrement programme de session \/ ordre du jour", "messages", "fr");
t.add("affair.event.date.label", "Date", "messages", "fr");
t.add("ui.table.filter.all", "S\u00e9lectionner tout", "messages", "fr");
t.add("ui.table.filter.clear", "Ne rien s\u00e9lectionner", "messages", "fr");
t.add("ui.reject", "Annuler", "messages", "fr");
t.add("layout.affair_groups.description", "Description", "messages", "fr");
t.add("draft.title", "Projet", "messages", "fr");
t.add("reports.cantonalsessions.placeholder", "Choisir session\/s\u00e9ance cantonale...", "messages", "fr");
t.add("reports.cantonal.sessions", "Session\/s\u00e9ance cantonale", "messages", "fr");
t.add("api.groups.delete.success", "Groupe supprim\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("affair_groups_sorting.label", "Groupes: tri standard", "messages", "fr");
t.add("affair_groups_sorting.default", "Param\u00e8tre standard", "messages", "fr");
t.add("affair_groups_sorting.alphabetical", "Trier par ordre alphab\u00e9tique", "messages", "fr");
t.add("affair_groups_sorting.custom", "Trier de fa\u00e7on personnalis\u00e9e", "messages", "fr");
t.add("affair_groups_sorting.date", "Trier par date de cr\u00e9ation", "messages", "fr");
t.add("profile.settings.company.terms_of_service_title.label", "Titre", "messages", "fr");
t.add("national.affair.drafts.title", "Projets", "messages", "fr");
t.add("ui.table.header.actions.size.label", "Afficher nombre d\u2019\u00e9l\u00e9ments maximal", "messages", "fr");
t.add("list_size.label", "Nombre d\u2019entr\u00e9es", "messages", "fr");
t.add("event.reminders.delete.success", "Rappel supprim\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("reports.internal_fields.label", "Champs d\u00e9finis par l\u2019utilisateur", "messages", "fr");
t.add("affair.preConsultation.tooltip.caption", "Examen pr\u00e9alable", "messages", "fr");
t.add("reports.sessionProgram.affairInGroups", "Groupe", "messages", "fr");
t.add("profile.show.list_size", "Longueur de liste standard", "messages", "fr");
t.add("profile.edit.logo.download", "T\u00e9l\u00e9charger un logo", "messages", "fr");
t.add("api.sessions.get.error", "Erreur lors de la consultation des sessions. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("reports.source.output_format", "Format d\u2019\u00e9dition", "messages", "fr");
t.add("reports.config.save.form.title", "Mise \u00e0 jour du projet", "messages", "fr");
t.add("reports.config.form.description", "Enregistrer les param\u00e8tres du rapport dans un mod\u00e8le:", "messages", "fr");
t.add("reports.config.form.title.input.label", "D\u00e9signation", "messages", "fr");
t.add("reports.source.elements", "El\u00e9ments", "messages", "fr");
t.add("reports.config.form.output_format", "Format d\u2019\u00e9dition", "messages", "fr");
t.add("reports.title.label", "Titre", "messages", "fr");
t.add("reports.date.label", "Date", "messages", "fr");
t.add("reports.config.save", "Mise \u00e0 jour du mod\u00e8le de rapport", "messages", "fr");
t.add("api.reports.config.post.success", "Mod\u00e8le de rapport enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("api.reports.config.post.error", "Erreur lors de l\u2019enregistrement du mod\u00e8le de rapport. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("ui.select_multiple.placeholder", "Rien n\u2019est s\u00e9lectionn\u00e9", "messages", "fr");
t.add("reports.nationalAffairConsultation.status.closed", "Termin\u00e9", "messages", "fr");
t.add("reports.nationalAffairConsultation.in_progres", "En cours", "messages", "fr");
t.add("affair.custom.submittedByOn", "Cr\u00e9\u00e9", "messages", "fr");
t.add("affair.CouncilStatesVote", "Vote au Conseil des Etats", "messages", "fr");
t.add("affair.NationalCouncilVote", "Vote au Conseil national", "messages", "fr");
t.add("profile.settings.nationalNotificationPreferences.NationalCouncilVotes", "Votes Conseil national", "messages", "fr");
t.add("profile.settings.nationalNotificationPreferences.CouncilStatesVotes", "Votes Conseil des Etats", "messages", "fr");
t.add("affair.CouncilStatesVote.tooltip.caption", "Vote Conseil des Etats", "messages", "fr");
t.add("affair.NationalCouncilVote.tooltip.caption", "Vote Conseil national", "messages", "fr");
t.add("affair.overview.NationalCouncilVote.title", "Vote Conseil national", "messages", "fr");
t.add("affair.overview.CouncilStatesVote.title", "Vote Conseil des Etats", "messages", "fr");
t.add("affair.StatusChangeCantonalAffairEvent.tooltip.caption", "Modification du statut", "messages", "fr");
t.add("dashboard.updates.itemType.CouncilStatesVote", "Vote Conseil des Etats", "messages", "fr");
t.add("dashboard.updates.itemType.NationalCouncilVote", "Vote Conseil national", "messages", "fr");
t.add("affair.NationalAffairDraftResolution.tooltip.caption", "Projet document", "messages", "fr");
t.add("affair.PreConsultationNationalAffairEvent.tooltip.caption", "Examen pr\u00e9alable", "messages", "fr");
t.add("affair.StatusChangeNationalAffairEvent.tooltip.caption", "Statut modifi\u00e9", "messages", "fr");
t.add("affair.NationalAffairDraftReference.tooltip.caption", "Projet document", "messages", "fr");
t.add("affair.NationalSessionProgramNationalAffairEvent.tooltip.caption", "Pr\u00e9vu: traitement en session", "messages", "fr");
t.add("affair.NationalCouncilDebateEvent.tooltip.caption", "D\u00e9bat CN", "messages", "fr");
t.add("affair.FederalCouncilProposalNationalAffairEvent.tooltip.caption", "Projet Conseil f\u00e9d\u00e9ral", "messages", "fr");
t.add("affair.NationalCommitteePressReleaseNationalAffairEvent.tooltip.caption", "Communiqu\u00e9 de presse Commission", "messages", "fr");
t.add("affair.NationalAffairConsultationEndEvent.tooltip.caption", "Fin de la consultation", "messages", "fr");
t.add("affair.NationalAffairConsultationStartEvent.tooltip.caption", "D\u00e9but de la consultation", "messages", "fr");
t.add("affair.NationalCommitteeProgramEndNationalAffairEvent.tooltip.caption", "Pr\u00e9vu: traitement en commission (fin)", "messages", "fr");
t.add("affair.NationalCommitteeProgramStartNationalAffairEvent.tooltip.caption", "Pr\u00e9vu: traitement en commission", "messages", "fr");
t.add("affair.NationalCommitteeProgramRemovedNationalAffairEvent.tooltip.caption", "Supprim\u00e9 du programme de commission", "messages", "fr");
t.add("affair.CantonalSessionProgramStartCantonalAffairEvent.tooltip.caption", "Ev\u00e9nement cantonal", "messages", "fr");
t.add("layout.search.content_type", "Type", "messages", "fr");
t.add("reports.source.content", "Zone d\u2019en-t\u00eate", "messages", "fr");
t.add("national.affairs.list.header.date", "Date de d\u00e9p\u00f4t", "messages", "fr");
t.add("affair.NationalAffairConsultationSpanEvent.tooltip.caption", "Consultation nationale: p\u00e9riode", "messages", "fr");
t.add("affair.CantonalAffairConsultationSpanEvent.tooltip.caption", "P\u00e9riode de consultation cantonale", "messages", "fr");
t.add("ui.error.crash.title", "Erreur inattendue", "messages", "fr");
t.add("ui.error.crash.message", "Une erreur s\u2019est malheureusement produite. Pour en savoir plus, veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch", "messages", "fr");
t.add("ui.error.crash.goto_dashboard", "Vers le tableau de bord", "messages", "fr");
t.add("ui.error.crash.reload", "Charger \u00e0 nouveau cette page", "messages", "fr");
t.add("api.2fa.put.success", "Param\u00e8tres de l\u2019authentification \u00e0 2 facteurs enregistr\u00e9s avec succ\u00e8s.", "messages", "fr");
t.add("profile.edit.2fa.title", "Authentification \u00e0 2 facteurs", "messages", "fr");
t.add("profile.edit.2fa.google.secret_received", "Cl\u00e9", "messages", "fr");
t.add("api.2fa.put.error", "Erreur lors de la lecture de l\u2019API (auth. 2FA)", "messages", "fr");
t.add("profile.settings.password.error.match", "Les mots de passe sont diff\u00e9rents", "messages", "fr");
t.add("profile.settings.password.error.api", "Le mot de passe n\u2019est pas s\u00fbr. Veuillez choisir un autre mot de passe.", "messages", "fr");
t.add("profile.password_reset.form.title", "Saisir un nouveau mot de passe", "messages", "fr");
t.add("layout.search.orderBy.canton", "Canton", "messages", "fr");
t.add("layout.search.show_advanced", "Afficher d\u2019autres options", "messages", "fr");
t.add("layout.search.hide_advanced", "Cacher les autres options", "messages", "fr");
t.add("layout.search.filters.internal_fields", "Champs d\u00e9finis par l\u2019utilisateur", "messages", "fr");
t.add("profile.password.submit", "Enregistrer le mot de passe", "messages", "fr");
t.add("profile.settings.password.error.format", "Mot de passe non s\u00fbr. Veuillez choisir un mot de passe compos\u00e9 d\u2019au moins 12 caract\u00e8res.", "messages", "fr");
t.add("customMeetings.delete.message", "Supprimer", "messages", "fr");
t.add("api.meetings.delete.success", "S\u00e9ance supprim\u00e9e avec succ\u00e8s", "messages", "fr");
t.add("api.meetings.delete.error", "Erreur lors de la suppression de la s\u00e9ance.", "messages", "fr");
t.add("layout.custom_meetings.time", "Date de la s\u00e9ance", "messages", "fr");
t.add("customMeetings.add_agenda.title", "Titre", "messages", "fr");
t.add("layout.custom_meetings.description", "Description", "messages", "fr");
t.add("customMeetings.edit.title", "Titre", "messages", "fr");
t.add("customMeetings.agenda_points.card.title", "Sujets \u00e0 l\u2019ordre du jour", "messages", "fr");
t.add("customMeetings.participants.card.title", "Participants", "messages", "fr");
t.add("api.affairs.get.error", "Erreur lors de la consultation des dossiers.", "messages", "fr");
t.add("customMeetings.agenda_points.empty", "Aucun sujet \u00e0 l\u2019ordre du jour saisi", "messages", "fr");
t.add("dashboard.updates.itemType.cantonalAffairInternalNote", "Note relative au dossier cantonal", "messages", "fr");
t.add("national.affairs.list.header.canton", "Canton", "messages", "fr");
t.add("national.affairs.list.header.actions", "Action", "messages", "fr");
t.add("calendar.nav.prev", "pr\u00e9c\u00e9dent", "messages", "fr");
t.add("calendar.nav.next", "suivant", "messages", "fr");
t.add("calendar.nav.today", "Aujourd\u2019hui", "messages", "fr");
t.add("ui.select_input.options.none", "Aucun \u00e9l\u00e9ment s\u00e9lectionn\u00e9", "messages", "fr");
t.add("reports.source.filters", "Filtre", "messages", "fr");
t.add("reports.config.save_as", "Enregistrer le nouveau projet", "messages", "fr");
t.add("reports.affair.link", "Lien", "messages", "fr");
t.add("reports.affair.codeAndType", "N\u00b0 \/ Type", "messages", "fr");
t.add("reports.format.oldDocx", "Word DOCX", "messages", "fr");
t.add("reports.format.oldPdf", "PDF", "messages", "fr");
t.add("reports.tableOfContents", "Table des mati\u00e8res", "messages", "fr");
t.add("reports.groups.outputTableOfContents.label", "Table des mati\u00e8res", "messages", "fr");
t.add("reports.tableofcontents", "Table des mati\u00e8res", "messages", "fr");
t.add("api.legislative_periods.get.error", "Erreur lors du chargement de la l\u00e9gislature. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("api.councillor.stats.get.error", "Erreur lors du chargement des statistiques relatives aux parlementaires. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("reports.config.delete", "Supprimer le projet", "messages", "fr");
t.add("reports.config.load", "Projets", "messages", "fr");
t.add("affair.NationalCommitteeProgramSpanNationalAffairEvent.tooltip.caption", "Modification du statut", "messages", "fr");
t.add("affair.CustomAffairNoteCustomEvent.tooltip.caption", "Note interne", "messages", "fr");
t.add("api.file.delete.error", "Erreur lors de la suppression de l\u2019image. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("reports.cantonalSessionProgram.title", "Programme des sessions cantonales", "messages", "fr");
t.add("affair.nationalAffairConsultationSpanEvent.tooltip.caption", "Consultation nationale: p\u00e9riode", "messages", "fr");
t.add("profile.settings.reports.affair.template", "Projet de rapport par d\u00e9faut", "messages", "fr");
t.add("api.reports.get.error", "Erreur lors de la consultation des rapports. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("profile.settings.reports.affair.default", "S\u00e9lectionner le rapport par d\u00e9faut...", "messages", "fr");
t.add("profile.edit.pictureFile.select", "S\u00e9lectionner l\u2019image...", "messages", "fr");
t.add("profile.edit.pictureFile.not_selected", "Aucune image s\u00e9lectionn\u00e9e.", "messages", "fr");
t.add("affair.title.report.download", "Exporter \/ t\u00e9l\u00e9charger le dossier", "messages", "fr");
t.add("profile.settings.commpany.user.delete.confirm", "Confirmer la suppression:", "messages", "fr");
t.add("affair.NationalAffairNoteCustomEvent.tooltip.caption", "Note", "messages", "fr");
t.add("affair.FederalCouncilResponseNationalAffairEvent.tooltip.caption", "R\u00e9ponse du Conseil f\u00e9d\u00e9ral", "messages", "fr");
t.add("affair.NationalCommitteeAffair.tooltip.caption", "Dossier de la commission nationale", "messages", "fr");
t.add("customMeetings.tree.customMeeting.goto", "Vue d\u00e9taill\u00e9e", "messages", "fr");
t.add("reports.affair", "Dossier unique", "messages", "fr");
t.add("reports.affair.title", "D\u00e9tails du dossier", "messages", "fr");
t.add("api.reports.config.put.success", "Projet de rapport enregistr\u00e9 avec succ\u00e8s.", "messages", "fr");
t.add("reports.config.delete.confirm.message", "Voulez-vous vraiment supprimer le projet de rapport?", "messages", "fr");
t.add("forms.custom_note_event.delete.success", "Note supprim\u00e9e avec succ\u00e8s", "messages", "fr");
t.add("reports.affairSelect.placeholder", "S\u00e9lectionner un dossier...", "messages", "fr");
t.add("reports.affairSelect.label", "Dossier", "messages", "fr");
t.add("profile.settings.company.user_groups.titleDe", "Nom du groupe DE", "messages", "fr");
t.add("profile.settings.company.user_groups.titleFr", "Nom du groupe FR", "messages", "fr");
t.add("profile.settings.company.user_groups.titleIt", "Nom du groupe IT", "messages", "fr");
t.add("user.company.settings.user_group.roles", "Autorisations", "messages", "fr");
t.add("customDataRoles.customEvents", "\u00e9v\u00e9nements internes", "messages", "fr");
t.add("customDataRoles.customSpeakers", "Intervenant(e)s", "messages", "fr");
t.add("customDataRoles.affairGroups", "Groupes", "messages", "fr");
t.add("customDataRoles.customAffairs", "Dossiers internes", "messages", "fr");
t.add("customDataRoles.meetings", "S\u00e9ances", "messages", "fr");
t.add("customDataRoles.customLinks", "Dossiers li\u00e9s", "messages", "fr");
t.add("customDataRoles.internalFields", "Champs d\u00e9finis par l\u2019utilisateur", "messages", "fr");
t.add("customDataRoles.mediaObjects", "Fichiers \/ annexes", "messages", "fr");
t.add("customDataRoles.userGroupItems", "Autorisations de saisie dans les dossier (notes, etc.)", "messages", "fr");
t.add("draft_0.title", "Message \/ rapport du Conseil f\u00e9d\u00e9ral", "messages", "fr");
t.add("api.reports.confi.put.success", "Projet de rapport enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add(" api.reports.confi.delete.success", "Projet de rapport supprim\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("reports.affairSelect.placeholde\ufffcr", "S\u00e9lectionner un dossier...", "messages", "fr");
t.add("affair.internalNote.edit", "Modifier", "messages", "fr");
t.add("affair.CantonalAffairInternalVoteCustomEvent.tooltip.caption", "Vote interne", "messages", "fr");
t.add("profile.password.new.label", "Nouveau mot de passe", "messages", "fr");
t.add("user.company.settings.user_group.role.read", "Lire", "messages", "fr");
t.add("user.company.settings.user_group.role.write", "Ecrire", "messages", "fr");
t.add("user.company.settings.user_group.role.none", "Aucun(e)", "messages", "fr");
t.add("author.national_committee", "Auteur: commission", "messages", "fr");
t.add("layout.search.nationalCommitteesWithPrograms", "Programme de la commission", "messages", "fr");
t.add("layout.search.nationalSessions", "Session Conf\u00e9d\u00e9ration", "messages", "fr");
t.add("reports.committee", "Rapport de la commission", "messages", "fr");
t.add("groups.tree.group.deleteAffair.confirm", "Souhaitez-vous vraiment supprimer le dossier du groupe?", "messages", "fr");
t.add("reports.nationalCommitteesPrograms.title", "Rapport de la commission %committeeAbbreviation%", "messages", "fr");
t.add("reports.cantonalSessionPrograms.label", "Session cantonale", "messages", "fr");
t.add("Reports.committee", "Rapport de la commission", "messages", "fr");
t.add("api.timelines.get.error", "Erreur lors de la consultation de l\u2019axe temporel. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("api.parties.get.error", "Erreur lors du chargement des partis. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("api.factions.get.error", "Erreur lors du chargement des groupes parlementaires. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("api.parties.stats.get.error", "Erreur lors du chargement des statistiques relatives au parti. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("api.factions.stats.get.error", "Erreur lors du chargement des statistiques relatives au groupe parlementaire. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("affairGroup.archive", "Archiver le groupe.", "messages", "fr");
t.add("affairGroup.archive.confirm", "Souhaitez-vous vraiment supprimer ce groupe?", "messages", "fr");
t.add("customMeetings.archive", "Archiver la s\u00e9ance", "messages", "fr");
t.add("customMeetings.archive.confirm", "Voulez-vous vraiment archiver la s\u00e9ance?", "messages", "fr");
t.add("layout.search.subscribed.label", "Prendre uniquement en compte les dossiers auxquels je suis abonn\u00e9.", "messages", "fr");
t.add("Schreiben", "user.company.settings.user_group.role.write", "messages", "fr");
t.add("api.groups.error.affair_delete", "Erreur lors de la suppression des affectations d'un groupe. Veuillez contacter le service d'assistance.", "messages", "fr");
t.add("event.reminders.error.get", "Rappels de chargement d'erreur. Veuillez contacter le support.", "messages", "fr");
t.add("api.committees.get.error", "Erreur de chargement des commissions. Veuillez contacter le support.", "messages", "fr");
t.add("affairGroups.archive", "Archives", "messages", "fr");
t.add("menu.company.choose", "Changer d'organisation\/entreprise", "messages", "fr");
t.add("api.user.companies.get.error", "Erreur lors du chargement des entreprises\/organisations. Veuillez contacter le support.", "messages", "fr");
t.add("user.company.set.form.title", "S\u00e9lectionnez une organisation\/entreprise", "messages", "fr");
t.add("reports.title", "Cr\u00e9er un rapport", "messages", "fr");
t.add("reports.sections.type.title", "Quel rapport voulez-vous cr\u00e9er ?", "messages", "fr");
t.add("reports.sections.groups.title", "De quels groupes le rapport doit-il \u00eatre \u00e9labor\u00e9 ?", "messages", "fr");
t.add("reports.sections.official.title", "Quels sont les d\u00e9tails officiels que les transactions doivent contenir dans le rapport ?", "messages", "fr");
t.add("reports.sections.custom.title", "Quel est votre propre contenu sur les transactions qui devrait \u00eatre inclus dans le rapport ?", "messages", "fr");
t.add("reports.sections.filters.title", "Quels filtres doivent \u00eatre appliqu\u00e9s au rapport ?", "messages", "fr");
t.add("reports.sections.format.title", "Quel doit \u00eatre le format de sortie du rapport ?", "messages", "fr");
t.add("reports.sections.basic.title", "Quels devraient \u00eatre les \u00e9l\u00e9ments de conception de ce rapport ?", "messages", "fr");
t.add("reports.sections.presets.title", "Souhaitez-vous enregistrer les param\u00e8tres de ce rapport comme mod\u00e8le ?", "messages", "fr");
t.add("reports.sections.submit.title", "\u00cates-vous pr\u00eat \u00e0 produire le rapport ?", "messages", "fr");
t.add("security.saml.loginError", "Erreur lors de la connexion avec SAML.", "messages", "fr");
t.add("author.CantonalCouncillor", "Auteur dossier cantonal", "messages", "fr");
t.add("reports.action.header", "Mod\u00e8le enregistr\u00e9", "messages", "fr");
t.add("reports.action.label", "Mod\u00e8le actuel", "messages", "fr");
t.add("user.company.select_input.label", "S\u00e9lectionnez une organisation \/ entreprise", "messages", "fr");
t.add("layout.search.contentType.custom_affair", "Dossiers internes", "messages", "fr");
t.add("footer.label", "Mentions l\u00e9gales et protection des donn\u00e9es", "messages", "fr");
t.add("reports.author.label", "Auteur", "messages", "fr");
t.add("reports.subtitle.label", "Remarque", "messages", "fr");
t.add("file_upload.no_file", "pas de logo t\u00e9l\u00e9charg\u00e9", "messages", "fr");
t.add("file_upload.btn_label", "T\u00e9l\u00e9charger le logo", "messages", "fr");
t.add("reports.national_session", "Rapport de la session nationale", "messages", "fr");
t.add("reports.national_committee", "Rapport de la Commission nationale", "messages", "fr");
t.add("profile.settings.company.title", "Param\u00e8tres Organisation \/ Entreprise", "messages", "fr");
t.add("profile.settings.title", "Param\u00e8tres du profil", "messages", "fr");
t.add("profile.settings.navigation.user_company_custom_fields", "Champs personnalis\u00e9s", "messages", "fr");
t.add("profile.settings.navigation.user_company_user_groups", "Autorisations \/ Groupes d'utilisateurs", "messages", "fr");
t.add("NoteCustomEvent.is_private.label", "Note priv\u00e9e", "messages", "fr");
t.add("profile.settings.company.shortcut", "O", "messages", "fr");
t.add("profile.settings.company.affair_edit.label", "Edit Company\/Organisation", "messages", "fr");
t.add("reports.section.main_section", "Quelles sont les donn\u00e9es \u00e0 prendre en compte ?", "messages", "fr");
t.add("customMeetings.meetingAgendaPoint.type.affairsWithSpeakerWithoutCustomNote.label", "Dossiers avec intervenant(e) mais sans notes", "messages", "fr");
t.add("councillor.language.D", "Allemand", "messages", "fr");
t.add("councillor.language.F", "Fran\u00e7ais", "messages", "fr");
t.add("councillor.language.I", "Italien", "messages", "fr");
t.add("api.reports.config.delete.success", "Projet de rapport supprim\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("profile.settings.company.meetings", "S\u00e9ances", "messages", "fr");
t.add("ui.select.all", "S\u00e9lectionner tout", "messages", "fr");
t.add("affairs.customEvents.is_private.label", "note priv\u00e9e (visible uniquement pour moi)", "messages", "fr");
t.add("reports.national_council.label", "Intervenant(e) du Conseil national", "messages", "fr");
t.add("reports.states_council.label", "Intervenant(e) du Conseil des Etats", "messages", "fr");
t.add("ui.text_editor.link_label", "Lien:", "messages", "fr");
t.add("ui.text_editor.save_btn", "Enregistrer", "messages", "fr");
t.add("not_found.title", "La page que vous recherchez n'a pas pu \u00eatre trouv\u00e9e.", "messages", "fr");
t.add("not_found.subtitle", "La page que vous recherchez n'a pas pu \u00eatre trouv\u00e9e. Il peut avoir \u00e9t\u00e9 d\u00e9plac\u00e9 ou supprim\u00e9.", "messages", "fr");
t.add("not_found.link.label", "Retour", "messages", "fr");
t.add("layout.search.affairStatesNational", "Stand national", "messages", "fr");
t.add("vote.id", "Num\u00e9ro de vote", "messages", "fr");
t.add("api.subscriptions.get.error", "Erreur lors de la r\u00e9cup\u00e9ration des dossiers souscrites. Veuillez contacter le service d'assistance.", "messages", "fr");
t.add("api.subscriptions.delete.error", "Erreur lors de la suppression des dossiers souscrites. Veuillez contacter le support.", "messages", "fr");
t.add("api.subscriptions.post.error", "Erreur de sauvegarde des dossiers souscrites. Veuillez contacter le support.", "messages", "fr");
t.add("reports.meeting_protocol", "Proc\u00e8s-verbaux des S\u00e9ances", "messages", "fr");
t.add("reports.meeting_invitation", "Invitations aux S\u00e9ances", "messages", "fr");
t.add("reports.sections.meeting_invitation.title", "Pour quelle s\u00e9ance souhaitez-vous cr\u00e9er l'invitation ?", "messages", "fr");
t.add("reports.sections.meeting_protocol.title", "Pour quelle s\u00e9ance souhaitez-vous \u00e9tablir le proc\u00e8s-verbal ?", "messages", "fr");
t.add("profile.settings.reports.invitation.template", "Mod\u00e8le de rapport standard invitation \u00e0 la s\u00e9ance", "messages", "fr");
t.add("profile.settings.reports.protocol.template", "Mod\u00e8le de rapport standard proc\u00e8s-verbal \u00e0 la s\u00e9ance", "messages", "fr");
t.add("select_input.not_selected", "Rien n'a encore \u00e9t\u00e9 s\u00e9lectionn\u00e9...", "messages", "fr");
t.add("layout.search.meetings.label", "S\u00e9lectionnez une s\u00e9ance", "messages", "fr");
t.add("api.user.companies.put.success", "Entreprise \/ organisation mise en place avec succ\u00e8s", "messages", "fr");
t.add("affair.nationalAffairInternalVoteCustomEvent.tooltip.caption", "Vote interne", "messages", "fr");
t.add("user.company_select_input.label", "S\u00e9lectionnez une organisation \/ entreprise", "messages", "fr");
t.add("reports.section.session.title", "\u00c0 propos de quelle session le rapport devrait-il \u00eatre cr\u00e9\u00e9 ?", "messages", "fr");
t.add("reports.nationalCommitteeProgram.title", "Rapport de la commission %committeeAbbreviation%", "messages", "fr");
t.add("affair.actions_card.title", "Informations compl\u00e9mentaires", "messages", "fr");
t.add("file_upload.remove", "Supprimer", "messages", "fr");
t.add("reports.meetings.sections.meeting_invitation.title", "Pour quelle session le document doit-il \u00eatre cr\u00e9\u00e9 ?", "messages", "fr");
t.add("reports.meetings.sections.official.title", "Quelles informations officielles doivent figurer dans le document ?", "messages", "fr");
t.add("reports.meetings.sections.custom.title", "Quel contenu propre doit \u00eatre inclus dans le document ?", "messages", "fr");
t.add("reports.meetings.sections.filters.title", "Quels sont les filtres \u00e0 appliquer ?", "messages", "fr");
t.add("reports.meetings.sections.basic.title", "Quels sont les \u00e9l\u00e9ments de conception qui doivent \u00eatre produits ?", "messages", "fr");
t.add("reports.meetings.sections.format.title", "Quel doit \u00eatre le format de sortie du document ?", "messages", "fr");
t.add("reports.meetings.sections.presets.title", "Voulez-vous enregistrer les param\u00e8tres comme mod\u00e8le ?", "messages", "fr");
t.add("reports.meetings.sections.submit.title", "\u00cates-vous pr\u00eat \u00e0 g\u00e9n\u00e9rer le document ?", "messages", "fr");
t.add("customMeetings.newMeeting.error", "Erreur lors de l'enregistrement. Veuillez contacter le support.", "messages", "fr");
t.add("reports.nationalCommitteePrograms.title", "Rapport de la commission %committeeAbbreviation%", "messages", "fr");
t.add("dashboard.updates.itemType.nationalAffairCustomSpeaker", "Porte-parole", "messages", "fr");
t.add("events.termine.categories.all", "tous", "messages", "fr");
t.add("events.termine.categories.none", "aucun", "messages", "fr");
t.add("events.termine.categories.1", "Votations", "messages", "fr");
t.add("events.termine.categories.2", "\u00c9lections", "messages", "fr");
t.add("events.termine.categories.3", "commune rurale", "messages", "fr");
t.add("events.termine.categories.4", "Sessions", "messages", "fr");
t.add("events.termine.categories.5", "Commissions", "messages", "fr");
t.add("events.termine.categories.6", "Autre", "messages", "fr");
t.add("affair_list.select.label", "Date de soumission", "messages", "fr");
t.add("layout.affair_groups.updated", "Dernier \u00e9v\u00e9nement", "messages", "fr");
t.add("dashboard.updates.user", "Utilisateur", "messages", "fr");
t.add("file_upload.file_too_big.error", "Le fichier que vous voulez t\u00e9l\u00e9charger est trop volumineux. Veuillez r\u00e9duire la taille du fichier ou contacter le service d'assistance.", "messages", "fr");
t.add("affair.CantonalSessionProgramSpanCantonalAffairEvent.tooltip.caption", "Programme de session \/ ordre du jour", "messages", "fr");
t.add("events.termine.categories", "Cat\u00e9gorie de date", "messages", "fr");
t.add("calendar.type.select.option.general_dates", "Dates de politique g\u00e9n\u00e9rale", "messages", "fr");
t.add("api.rankings.get.error", "Erreur de r\u00e9cup\u00e9ration des classements. Veuillez contacter le support.", "messages", "fr");
t.add("rankings.affairs", "Classements", "messages", "fr");
t.add("rankings.filter.group.label", "Groupe", "messages", "fr");
t.add("api.topics.get.error", "Erreur de r\u00e9cup\u00e9ration des sujets. Veuillez contacter le support.", "messages", "fr");
t.add("rankings.list.empty", "Aucun classement n'est disponible.", "messages", "fr");
t.add("affair.CantonalSessionProgramEndCantonalAffairEvent.tooltip.caption", "Enregistrement programme de session \/ ordre du jour", "messages", "fr");
t.add("layout.search.orderBy.lastEventDate", "Dernier \u00e9v\u00e9nement", "messages", "fr");
t.add("profile.customMeetings.archived", "Groupes archiv\u00e9s", "messages", "fr");
t.add("api.cantonal.councillor.get.error", "Erreur de r\u00e9cup\u00e9ration des parlementaires cantonaux. Veuillez contacter le support.", "messages", "fr");
t.add("profile.affairGroups.archived", "Groupes archiv\u00e9s", "messages", "fr");
t.add("councils.br", "Conseil f\u00e9d\u00e9ral", "messages", "fr");
t.add("affair.FederalCouncilMediaReleaseNationalAffairEvent.tooltip.caption", "Communiqu\u00e9 de presse du Conseil f\u00e9d\u00e9ral", "messages", "fr");
t.add("affair.SdaMediaReleaseNationalAffairEvent.tooltip.caption", "Communiqu\u00e9 de presse du SDA", "messages", "fr");
t.add("profile.settings.company.custom_fields.html.labelDe", "D\u00e9signation DE", "messages", "fr");
t.add("profile.settings.company.custom_fields.html.labelFr", "D\u00e9signation FR", "messages", "fr");
t.add("profile.settings.company.custom_fields.html.labelIt", "D\u00e9signation IT", "messages", "fr");
t.add("ranking.against", "Dissidents \/ Contre leur propre groupe", "messages", "fr");
t.add("api.cantonal.consultations.get.error", "Erreur lors de la r\u00e9cup\u00e9ration des consultations cantonales. Veuillez contacter l'\u00e9quipe de soutien.", "messages", "fr");
t.add("reports.groups.outputAssignedGroups.label", "Groupes affect\u00e9s aux sorties", "messages", "fr");
t.add("api.login.error", "Connexion impossible.", "messages", "fr");
t.add("security.login.welcome", "Bienvenue sur politique.ch", "messages", "fr");
t.add("security.login.auth_code", "Auth-Code", "messages", "fr");
t.add("cantonal.column", "Source", "messages", "fr");
t.add("cantonal.dataQuality.sourceType.affairs", "D", "messages", "fr");
t.add("cantonal.dataQuality.sourceType.consultations", "C", "messages", "fr");
t.add("affairGroup.linkToAffairGroup", "Vers la vue du groupe", "messages", "fr");
t.add("customMeetings.linkToDocument", "Au Dossier", "messages", "fr");
t.add("not_found.suggested_searches", "Dossiers propos\u00e9es", "messages", "fr");
t.add("affair.results.bydissenters.none", "Sans d\u00e9viants", "messages", "fr");
t.add("layout.search.contentType.national_councillor", "Parlementaire nationale", "messages", "fr");
t.add("layout.search.contentType.cantonal_councillor", "Parlementaire cantonale", "messages", "fr");
t.add("layout.search.contentType.national_affair_consultation", "Consultation nationale", "messages", "fr");
t.add("layout.search.contentType.cantonal_affair_consultation", "Consultation cantonale", "messages", "fr");
t.add("layout.search.filters", "filtre", "messages", "fr");
t.add("affair.sdaMediaReleaseNationalAffairEvent.title.prefix", "Message de la Sda", "messages", "fr");
t.add("affair.federalCouncilMediaReleaseNationalAffairEvent.title.prefix", "Situation initiale", "messages", "fr");
t.add("layout.search.type.national", "Recherche nationale", "messages", "fr");
t.add("layout.search.type.cantonal", "Recherche cantonale", "messages", "fr");
t.add("layout.search.type.custom", "Dossiers internes", "messages", "fr");
t.add("not_found.no_similar_results_found", "Nous n'avons pas non plus trouv\u00e9 de dossiers similaires.", "messages", "fr");
t.add("parties.noMembersFound", "Aucun", "messages", "fr");
t.add("reports.groups.numberingTableOfContents.label", "Num\u00e9rotation", "messages", "fr");
t.add("layout.search.orderBy.lastStatus", "Dernier statut", "messages", "fr");
t.add("layout.search.orderBy.author", "Auteur", "messages", "fr");
t.add("affair.actions_card.auto_save.label", "est enregistr\u00e9...", "messages", "fr");
t.add("affairGroup.linkToDocument", "Vers la vue du groupe", "messages", "fr");
t.add("vote.decision.others", "Abstenu\/absent, etc.", "messages", "fr");
t.add("auth.password_reset.success", "Le mot de passe a \u00e9t\u00e9 r\u00e9initialis\u00e9 avec succ\u00e8s.", "messages", "fr");
t.add("user.password.missingdata", "Donn\u00e9es incorrectes", "messages", "fr");
t.add("user.password.fieldsnotequal", "Les valeurs ne correspondent pas", "messages", "fr");
t.add("user.password.short", "Mot de passe trop court", "messages", "fr");
t.add("consultation.endDate", "se termine le", "messages", "fr");
t.add("security.login.submit", "Connexion", "messages", "fr");
t.add("security.wrong_password", "Utilisateur inconnu ou mot de passe incorrect", "messages", "fr");
t.add("affair.nationalAffairConsultationOpinionsEvent.tooltip.caption", "Opinion", "messages", "fr");
t.add("affair.nationalAffairConsultationReportsEvent.tooltip.caption", "R\u00e9sultat", "messages", "fr");
t.add("profile.settings.language.it", "Italienisch \/ Italiano", "messages", "fr");
t.add("profile.settings.language.en", "Englisch \/ Anglais", "messages", "fr");
t.add("profile.settings.data_language.label", "Langue pour les donn\u00e9es (si disponible)", "messages", "fr");
t.add("profile.settings.data_language.sameAsLanguage", "Identique \u00e0 la langue de l'utilisateur", "messages", "fr");
t.add("affair.NationalAffairCouncilDocumentsEvent.tooltip.caption", "Documents du Conseil", "messages", "fr");
t.add("national.affairs.status.added_to_session_program", "Dans le programme de la session", "messages", "fr");
t.add("national.affairs.status.removed_from_session_program", "Retir\u00e9 du programme de la session", "messages", "fr");
t.add("national.affairs.status.deposited", "D\u00e9pos\u00e9", "messages", "fr");
t.add("national.affairs.status.added_to_committee_program", "Dans le programme de la Commission", "messages", "fr");
t.add("national.affairs.status.removed_from_committee_program", "Retir\u00e9 du programme de la Commission", "messages", "fr");
t.add("national.affairs.status.mentioned_in_press_release", "Communiqu\u00e9 de presse publi\u00e9 (commission)", "messages", "fr");
t.add("national.affairs.status.federal_council_proposal_added", "Proposition du Conseil f\u00e9d\u00e9ral publi\u00e9", "messages", "fr");
t.add("national.affairs.status.federal_council_answer_added", "R\u00e9ponse du Conseil f\u00e9d\u00e9ral publi\u00e9e", "messages", "fr");
t.add("national.affairs.status.federal_council_opionion_added", "Avis du Conseil f\u00e9d\u00e9ral publi\u00e9", "messages", "fr");
t.add("national.affairs.status.preconsultation_added", "Examen pr\u00e9alable", "messages", "fr");
t.add("national.affairs.status.federal_council_media_release", "Situation initiale formul\u00e9e", "messages", "fr");
t.add("national.affairs.status.sda_media_release", "Annoncement ATS publi\u00e9", "messages", "fr");
t.add("national.affairs.status.council_documents_event", "Documents du Conseil publi\u00e9s", "messages", "fr");
t.add("national.affairs.status.unreachable", "Pas disponible dans la source officielle", "messages", "fr");
t.add("national.affairs.status.deleted", "Retir\u00e9 de la source officielle", "messages", "fr");
t.add("cantonal.affairs.type.vor.title", "Projet (Gouvernement)", "messages", "fr");
t.add("cantonal.affairs.type.vop.title", "Projet (Parlement)", "messages", "fr");
t.add("cantonal.affairs.type.mo.title", "Motion", "messages", "fr");
t.add("cantonal.affairs.type.po.title", "Postulat", "messages", "fr");
t.add("cantonal.affairs.type.ip.title", "Interpellation", "messages", "fr");
t.add("cantonal.affairs.type.kla.title", "Question simple", "messages", "fr");
t.add("cantonal.affairs.type.vo.title", "Mouvement populaire", "messages", "fr");
t.add("cantonal.affairs.type.au.title", "Mandat", "messages", "fr");
t.add("cantonal.affairs.type.pai.title", "Initiative parlamentaire", "messages", "fr");
t.add("cantonal.affairs.type.ktiv.title", "Initiative d\u00e9pos\u00e9e par un Canton", "messages", "fr");
t.add("cantonal.affairs.type.wah.title", "\u00c9lection", "messages", "fr");
t.add("cantonal.affairs.type.pe.title", "P\u00e9tition", "messages", "fr");
t.add("cantonal.affairs.type.ber.title", "Rapport", "messages", "fr");
t.add("cantonal.affairs.type.pae.title", "Avis parlementaire", "messages", "fr");
t.add("cantonal.affairs.type.an.title", "Proposition", "messages", "fr");
t.add("cantonal.affairs.type.div.title", "Divers", "messages", "fr");
t.add("cantonal.affairs.type.in.title", "Initiative", "messages", "fr");
t.add("cantonal.affairs.type.beg.title", "Gr\u00e2ce", "messages", "fr");
t.add("cantonal.affairs.type.bes.title", "Recours", "messages", "fr");
t.add("cantonal.affairs.type.veg.title", "Prestation de serment", "messages", "fr");
t.add("cantonal.affairs.type.vem.title", "Proc\u00e9dure de consultation", "messages", "fr");
t.add("cantonal.affairs.type.ver.title", "Ordonnances et actes", "messages", "fr");
t.add("cantonal.affairs.type.fra.title", "Heure des questions", "messages", "fr");
t.add("cantonal.affairs.type.res.title", "R\u00e9solution", "messages", "fr");
t.add("cantonal.affairs.type.ges.title", "Rapport de gestion", "messages", "fr");
t.add("cantonal.affairs.type.bre.title", "Nationalit\u00e9", "messages", "fr");
t.add("cantonal.affairs.type.ive.title", "Accord intercantonal", "messages", "fr");
t.add("cantonal.affairs.type.ref.title", "R\u00e9f\u00e9rendum", "messages", "fr");
t.add("cantonal.affairs.type.bel.title", "D\u00e9cision", "messages", "fr");
t.add("cantonal.affairs.type.dek.title", "D\u00e9cret", "messages", "fr");
t.add("cantonal.affairs.type.svo.title", "Projet unique", "messages", "fr");
t.add("cantonal.affairs.type.eiv.title", "Initiative individuelle", "messages", "fr");
t.add("cantonal.affairs.type.vor.abbr", "Pr. G.", "messages", "fr");
t.add("cantonal.affairs.type.vop.abbr", "Pr. Pa.", "messages", "fr");
t.add("cantonal.affairs.type.mo.abbr", "Mo.", "messages", "fr");
t.add("cantonal.affairs.type.po.abbr", "Po.", "messages", "fr");
t.add("cantonal.affairs.type.ip.abbr", "Interpellation", "messages", "fr");
t.add("cantonal.affairs.type.kla.abbr", "Q.", "messages", "fr");
t.add("cantonal.affairs.type.vo.abbr", "Mo.pop.", "messages", "fr");
t.add("cantonal.affairs.type.au.abbr", "Man.", "messages", "fr");
t.add("cantonal.affairs.type.pai.abbr", "Iv. Pa.", "messages", "fr");
t.add("cantonal.affairs.type.ktiv.abbr", "Iv. Ca.", "messages", "fr");
t.add("cantonal.affairs.type.wah.abbr", "\u00c9l.", "messages", "fr");
t.add("cantonal.affairs.type.pe.abbr", "P\u00e9.", "messages", "fr");
t.add("cantonal.affairs.type.ber.abbr", "Rap.", "messages", "fr");
t.add("cantonal.affairs.type.pae.abbr", "Av. Pa.", "messages", "fr");
t.add("cantonal.affairs.type.an.abbr", "Pro.", "messages", "fr");
t.add("cantonal.affairs.type.div.abbr", "Div.", "messages", "fr");
t.add("cantonal.affairs.type.in.abbr", "Iv.", "messages", "fr");
t.add("cantonal.affairs.type.beg.abbr", "Gr.", "messages", "fr");
t.add("cantonal.affairs.type.bes.abbr", "Rec.", "messages", "fr");
t.add("cantonal.affairs.type.veg.abbr", "Ser.", "messages", "fr");
t.add("cantonal.affairs.type.vem.abbr", "P.C.", "messages", "fr");
t.add("cantonal.affairs.type.ver.abbr", "O.", "messages", "fr");
t.add("cantonal.affairs.type.fra.abbr", "H.q.", "messages", "fr");
t.add("cantonal.affairs.type.res.abbr", "R\u00e9s.", "messages", "fr");
t.add("cantonal.affairs.type.ges.abbr", "Rap.ges.", "messages", "fr");
t.add("cantonal.affairs.type.bre.abbr", "Nat.", "messages", "fr");
t.add("cantonal.affairs.type.ive.abbr", "Ac.ic.", "messages", "fr");
t.add("cantonal.affairs.type.ref.abbr", "R\u00e9f.", "messages", "fr");
t.add("cantonal.affairs.type.bel.abbr", "D\u00e9c.", "messages", "fr");
t.add("cantonal.affairs.type.dek.abbr", "D.", "messages", "fr");
t.add("cantonal.affairs.type.svo.abbr", "Pr.u.", "messages", "fr");
t.add("cantonal.affairs.type.eiv.abbr", "Iv.ind.", "messages", "fr");
t.add("cantonal.affairs.status.first_reading", "Premi\u00e8re lecture", "messages", "fr");
t.add("cantonal.affairs.status.second_reading", "Seconde lecture", "messages", "fr");
t.add("cantonal.affairs.status.rejected", "Rejet", "messages", "fr");
t.add("cantonal.affairs.status.rejected_not_definitely_supported", "Rejet - d\u00e9finitivement non pris en charge", "messages", "fr");
t.add("cantonal.affairs.status.refusal_not_supported_for_the_time_being", "Rejet - provisoirement non pris en charge", "messages", "fr");
t.add("cantonal.affairs.status.depreciation", "Classement", "messages", "fr");
t.add("cantonal.affairs.status.application", "Proposition", "messages", "fr");
t.add("cantonal.affairs.status.proposal_for_rejection", "Proposition de rejet", "messages", "fr");
t.add("cantonal.affairs.status.application_for_depreciation", "Proposition de classement", "messages", "fr");
t.add("cantonal.affairs.status.application_for_depreciation_in_the_annual_report", "Proposition de classement dans le rapport de gestion", "messages", "fr");
t.add("cantonal.affairs.status.proposal_for_discussion", "Proposition de discussion", "messages", "fr");
t.add("cantonal.affairs.status.proposal_for_urgency", "Proposition d'urgence", "messages", "fr");
t.add("cantonal.affairs.status.application_for_completion", "Proposition de liquidation", "messages", "fr");
t.add("cantonal.affairs.status.application_for_extension_of_the_deadline", "Proposition d'extension du d\u00e9lai", "messages", "fr");
t.add("cantonal.affairs.status.proposal_for_consent", "Proposition d'adh\u00e9sion", "messages", "fr");
t.add("cantonal.affairs.status.application_bank_council", "Proposition du Conseil de banque", "messages", "fr");
t.add("cantonal.affairs.status.proposal_court", "Proposition du Tribunal", "messages", "fr");
t.add("cantonal.affairs.status.proposal_commission", "Proposition de la Commission", "messages", "fr");
t.add("cantonal.affairs.status.proposal_government_council", "Proposition du Conseil d'\u00c9tat", "messages", "fr");
t.add("cantonal.affairs.status.response_management", "R\u00e9ponse de la Direction de l'enterprise", "messages", "fr");
t.add("cantonal.affairs.status.government_council_answer", "R\u00e9ponse du Conseil d'\u00c9tat", "messages", "fr");
t.add("cantonal.affairs.status.commencement_of_consultations", "Enregistrement des d\u00e9lib\u00e9rations", "messages", "fr");
t.add("cantonal.affairs.status.beginning_discussion", "D\u00e9but de la discussion", "messages", "fr");
t.add("cantonal.affairs.status.report_government_council", "Rapport du Conseil d'\u00c9tat", "messages", "fr");
t.add("cantonal.affairs.status.report_and_proposal_government_council", "Rapport et Proposition du Conseil d'\u00c9tat", "messages", "fr");
t.add("cantonal.affairs.status.definitely_supported", "Soutenu d\u00e9finitivement", "messages", "fr");
t.add("cantonal.affairs.status.discussed", "Discut\u00e9", "messages", "fr");
t.add("cantonal.affairs.status.urgency_approved", "Urgente", "messages", "fr");
t.add("cantonal.affairs.status.deposited", "D\u00e9pos\u00e9", "messages", "fr");
t.add("cantonal.affairs.status.receipt", "Prise de connaissance", "messages", "fr");
t.add("cantonal.affairs.status.supplementary_report", "Rapport compl\u00e9mentaire", "messages", "fr");
t.add("cantonal.affairs.status.significantly_explained", "D\u00e9clar\u00e9 pertinent\/conforme", "messages", "fr");
t.add("cantonal.affairs.status.done", "Termin\u00e9", "messages", "fr");
t.add("cantonal.affairs.status.continuation_of_consultations", "Suite des d\u00e9lib\u00e9rations", "messages", "fr");
t.add("cantonal.affairs.status.continuation_of_detailed_advice", "Suite de la d\u00e9lib\u00e9ration d\u00e9taill\u00e9e", "messages", "fr");
t.add("cantonal.affairs.status.deadline_extends", "D\u00e9lai prolong\u00e9", "messages", "fr");
t.add("cantonal.affairs.status.perusal", "Prise d'acte", "messages", "fr");
t.add("cantonal.affairs.status.urgency_not_approved", "D\u00e9clar\u00e9 non urgente", "messages", "fr");
t.add("cantonal.affairs.status.do_not_enter", "Non-entr\u00e9e en mati\u00e8re", "messages", "fr");
t.add("cantonal.affairs.status.come_back_proposal", "Proposition de r\u00e9examen", "messages", "fr");
t.add("cantonal.affairs.status.rejection", "Renvoi d'un projet", "messages", "fr");
t.add("cantonal.affairs.status.retired", "Retrait", "messages", "fr");
t.add("cantonal.affairs.status.opinion_management", "Avis de la direction", "messages", "fr");
t.add("cantonal.affairs.status.opinion_government_council", "Avis du Conseil d'\u00c9tat", "messages", "fr");
t.add("cantonal.affairs.status.part_invalidation", "D\u00e9claration d'invalidit\u00e9 partielle", "messages", "fr");
t.add("cantonal.affairs.status.referral_as_a_postulate", "Transmission en tant que postulat", "messages", "fr");
t.add("cantonal.affairs.status.transfer_to_the_management", "Transmission \u00e0 la direction de l'enterprise \/ au bureaux des Conseils", "messages", "fr");
t.add("cantonal.affairs.status.transfer_to_cantonal_council", "Transmission au Grand Conseil", "messages", "fr");
t.add("cantonal.affairs.status.transfer_to_government_council", "Transmission au Conseil d'\u00c9tat", "messages", "fr");
t.add("cantonal.affairs.status.transformation_into_a_postulate", "Conversion en postulat", "messages", "fr");
t.add("cantonal.affairs.status.invalid_declared", "D\u00e9clar\u00e9 invalide", "messages", "fr");
t.add("cantonal.affairs.status.varia", "Varia", "messages", "fr");
t.add("cantonal.affairs.status.refusal_to_accept_section_25_3_krg", "Refus prise de connaissance (\u00a7 25 Abs. 3 KRG)", "messages", "fr");
t.add("cantonal.affairs.status.provisionally_supported", "Soutenu provisoirement", "messages", "fr");
t.add("cantonal.affairs.status.choice", "\u00c9lection", "messages", "fr");
t.add("cantonal.affairs.status.loss", "Suppression", "messages", "fr");
t.add("cantonal.affairs.status.resumption", "Reprise", "messages", "fr");
t.add("cantonal.affairs.status.approval", "Adh\u00e9sion", "messages", "fr");
t.add("cantonal.affairs.status.consent_minority_proposal", "Proposition d'adh\u00e9sion minoritaire", "messages", "fr");
t.add("cantonal.affairs.status.on_session_agenda", "Trait\u00e9 au Parlement (\u00e0 l'ordre du jour)", "messages", "fr");
t.add("cantonal.affairs.status.unknown", "Non d\u00e9fini", "messages", "fr");
t.add("cantonal.affairs.status.divergent_minority_applications_from_the_commission", "Propositions div\u00e9rgentes de la minorit\u00e9 de la commission", "messages", "fr");
t.add("cantonal.affairs.status.divergent_requests_from_the_commission", "Propositions div\u00e9rgentes de la commission", "messages", "fr");
t.add("cantonal.affairs.status.interim_report", "Rapport interm\u00e9diaire", "messages", "fr");
t.add("cantonal.affairs.status.different_applications", "Propositions div\u00e9rgentes", "messages", "fr");
t.add("cantonal.affairs.status.nonoccurrence_request", "Proposition de Non-entr\u00e9e en mati\u00e8re", "messages", "fr");
t.add("cantonal.affairs.status.activity_report_of_financial_control", "Rapport d'activit\u00e9 du contr\u00f4le financier", "messages", "fr");
t.add("cantonal.affairs.status.validating", "D\u00e9claration de validit\u00e9", "messages", "fr");
t.add("cantonal.affairs.status.enter", "Entr\u00e9e en mati\u00e8re", "messages", "fr");
t.add("cantonal.affairs.status.final_vote", "Vote final", "messages", "fr");
t.add("cantonal.affairs.status.transfer_to_commission", "Transmission \u00e0 la commission", "messages", "fr");
t.add("cantonal.affairs.status.commission_report", "Rapport de la commission", "messages", "fr");
t.add("cantonal.affairs.status.resignation", "D\u00e9mission", "messages", "fr");
t.add("cantonal.affairs.status.moved_up", "Succession\/remplacement", "messages", "fr");
t.add("cantonal.affairs.status.approved_with_changes", "Adh\u00e9sion avec modifications", "messages", "fr");
t.add("cantonal.affairs.status.session_agenda", "Ordre du jour \/ Programme de la session", "messages", "fr");
t.add("cantonal.affairs.status.transfer", "Transmission", "messages", "fr");
t.add("cantonal.affairs.status.unmapped", "Non d\u00e9fini", "messages", "fr");
t.add("cantonal.affairs.status.shift_advice_cantonal_council", "Report d\u00e9liberation Grand Conseil", "messages", "fr");
t.add("cantonal.affairs.status.decision_commission", "D\u00e9cision de la commission", "messages", "fr");
t.add("cantonal.affairs.status.depreciation_rejected", "Classement rejet\u00e9", "messages", "fr");
t.add("cantonal.affairs.status.embassy_submission_government_council", "Message \/ Projet du Conseil d'\u00c9tat", "messages", "fr");
t.add("cantonal.affairs.status.decision_cantonal_council_grossrat_landrat", "D\u00e9cision du Grand Conseil", "messages", "fr");
t.add("cantonal.affairs.status.come_in_1st_reading", "Entr\u00e9e en mati\u00e8re, premi\u00e8re lecture", "messages", "fr");
t.add("cantonal.affairs.status.part_significantly_declaration", "D\u00e9claration de pertinence\/conformit\u00e9 partielle", "messages", "fr");
t.add("cantonal.affairs.status.commission_appointed", "Commission d\u00e9sign\u00e9e", "messages", "fr");
t.add("cantonal.affairs.status.referendum_deadline", "D\u00e9lai r\u00e9f\u00e9rendaire en cours", "messages", "fr");
t.add("cantonal.affairs.status.referendum_deadline_expired", "D\u00e9lai r\u00e9f\u00e9rendaire exipr\u00e9", "messages", "fr");
t.add("cantonal.affairs.status.non_declaration_significantly", "D\u00e9claration de non-pertinence\/non-conformit\u00e9", "messages", "fr");
t.add("cantonal.affairs.status.come_into_effect", "Entr\u00e9e en vigueur", "messages", "fr");
t.add("cantonal.affairs.status.plebiscite", "Vote populaire", "messages", "fr");
t.add("cantonal.affairs.status.report", "Rapport", "messages", "fr");
t.add("cantonal.affairs.status.nomination", "Proposition d'\u00e9lection", "messages", "fr");
t.add("cantonal.affairs.status.vote", "Vote", "messages", "fr");
t.add("cantonal.affairs.status.faction_request", "Proposition de fraction", "messages", "fr");
t.add("cantonal.affairs.status.accepted_vote", "Vote adopt\u00e9e", "messages", "fr");
t.add("cantonal.affairs.status.rejected_vote", "Vote rejet\u00e9", "messages", "fr");
t.add("cantonal.affairs.status.sistiert", "Suspendu", "messages", "fr");
t.add("cantonal.affairs.status.medienmitteilung", "Communiqu\u00e9 de presse publi\u00e9", "messages", "fr");
t.add("dashboard.updates.itemType.customAffairCustomSpeaker", "Porte-parole note interne", "messages", "fr");
t.add("dashboard.updates.itemType.cantonalAffairCustomSpeaker", "Porte-parole note relative au dossier cantonal", "messages", "fr");
t.add("profile.settings.notificationPreference.keywords.german", "Mots-cl\u00e9s en allemand", "messages", "fr");
t.add("profile.settings.notificationPreference.keywords.french", "Mots-cl\u00e9s en fran\u00e7ais", "messages", "fr");
t.add("profile.settings.notificationPreference.keywords.italian", "Mots-cl\u00e9s en italien", "messages", "fr");
t.add("layout.search.config.save_as", "Enregistrer le nouveau mod\u00e8le de recherche", "messages", "fr");
t.add("layout.search.config.save", "Mise \u00e0 jour du mod\u00e8le de recherche", "messages", "fr");
t.add("layout.search.config.form.description", "Enregistrer les param\u00e8tres de recherche dans un mod\u00e8le de recherche :", "messages", "fr");
t.add("layout.search.config.form.title.input.label", "D\u00e9signation", "messages", "fr");
t.add("layout.search.config.form.title.error.message", "Erreur lors de l\u2019enregistrement du mod\u00e8le de recherche", "messages", "fr");
t.add("layout.search.config.save.form.title", "Mise \u00e0 jour du mod\u00e8le de recherche", "messages", "fr");
t.add("layout.search.config.load", "Mod\u00e8les de recherche", "messages", "fr");
t.add("layout.search.config.delete", "Supprimer le mod\u00e8le de recherche", "messages", "fr");
t.add("layout.search.config.invalid", "Param\u00e8tres invalides pour le mod\u00e8le de recherche", "messages", "fr");
t.add("api.search.config.save.success", "Mod\u00e8le de recherche enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("api.search.config.save.error", "Erreur lors de l\u2019enregistrement du mod\u00e8le de recherche", "messages", "fr");
t.add("api.search.config.delete.success", "Mod\u00e8le de recherche supprim\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("api.search.config.delete.error", "Erreur lors de la suppression du mod\u00e8le de recherche", "messages", "fr");
t.add("api.search.config.get.error", "Erreur lors de la consultation des mod\u00e8les de recherche", "messages", "fr");
t.add("api.search.config.put.success", "Mod\u00e8le de recherche enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("api.search.config.put.error", "Erreur lors de l\u2019enregistrement du mod\u00e8le de recherche", "messages", "fr");
t.add("layout.search.config.action.header", "Mod\u00e8les de recherche enregistr\u00e9s", "messages", "fr");
t.add("layout.search.config.action.label", "Mod\u00e8le de recherche actuel", "messages", "fr");
t.add("layout.search.config.delete.confirm.message", "Voulez-vous vraiment supprimer le mod\u00e8le de recherche?", "messages", "fr");
t.add("profile.settings.company.custom_fields.html.labelEn", "D\u00e9signation EN", "messages", "fr");
t.add("profile.settings.company.internal_fields.html.labelEn", "D\u00e9signation EN", "messages", "fr");
t.add("profile.settings.company.internal_fields.ratings.labelEn", "D\u00e9signation EN", "messages", "fr");
t.add("profile.settings.company.custom_fields.rating.labelEn", "D\u00e9signation EN", "messages", "fr");
t.add("cantonal.affairs.type.bot.title", "Message", "messages", "fr");
t.add("cantonal.affairs.type.bot.abbr", "Bot", "messages", "fr");
t.add("untranslated.original.prefix", "[Titre original]", "messages", "fr");
t.add("customMeetings.pdf.assignedUsers.label", "Utilisateurs assign\u00e9s", "messages", "fr");
t.add("affairGroup.export.header.assignedUsers", "Assign\u00e9 \u00e0", "messages", "fr");
t.add("affairGroup.export.header.assignedUser", "Attribu\u00e9 \u00e0", "messages", "fr");
t.add("cantonal.affairs.status.initiationOfConsultation", "D\u00e9but de la proc\u00e9dure de consultation", "messages", "fr");
t.add("cantonal.affairs.status.applicationFromCantonalManagement", "Proposition de la Direction cantonale", "messages", "fr");
t.add("cantonal.affairs.status.answerFromCantonalManagement", "R\u00e9ponse de la Direction cantonale", "messages", "fr");
t.add("cantonal.affairs.status.reportFromCantonalManagement", "Rapport de la Direction cantonale", "messages", "fr");
t.add("layout.search.affairTypes.national", "Type de dossier (national)", "messages", "fr");
t.add("layout.search.affairTypes.cantonal", "Type de dossier (cantonal)", "messages", "fr");
t.add("reports.groups.outputInternalLink.label", "Lien vers politique.ch", "messages", "fr");
t.add("reports.affair.internalLink", "Lien politique.ch", "messages", "fr");
t.add("profile.settings.company.custom_fields.attachment.add", "Nouveau champ de fichier", "messages", "fr");
t.add("affair.internalAttachment.uploadFiles", "Ajouter des fichiers", "messages", "fr");
t.add("affair.internalAttachment.renameModal.label", "Renommer le fichier", "messages", "fr");
t.add("affair.internalAttachment.renameModal.description", "Quel doit \u00eatre le nouveau nom du fichier?", "messages", "fr");
t.add("affair.internalAttachment.renameModal.input.label", "Nouveau nom de fichier", "messages", "fr");
t.add("affair.internalAttachment.renameModal.error.message", "Erreur lors de renommer le fichier.", "messages", "fr");
t.add("affair.internalAttachment.renameModal.button.rename_attachment", "Renommer", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.attachment.put.success", "Fichier supprim\u00e9 avec succ\u00e8s.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.attachment.put.error", "Erreur lors de la suppression des fichiers. Veuillez contacter le service d'assistance.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.attachment.post.success", "Fichier enregistr\u00e9 avec succ\u00e8s.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.attachment.post.error", "Erreur lors de l'enregistrement des fichiers. Veuillez contacter le service d'assistance.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.attachment.rename.success", "Fichier renomm\u00e9 avec succ\u00e8s.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.attachment.rename.error", "Erreur lors de renommer le fichier. Veuillez contacter le service d'assistance.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.attachments.get.error", "Erreur lors de la consultation des fichiers. Veuillez contacter le service d'assistance.", "messages", "fr");
t.add("profile.settings.company.custom_fields.attachment.labelDe", "D\u00e9signation DE", "messages", "fr");
t.add("profile.settings.company.custom_fields.attachment.labelFr", "D\u00e9signation FR", "messages", "fr");
t.add("profile.settings.company.custom_fields.attachment.labelIt", "D\u00e9signation IT", "messages", "fr");
t.add("profile.settings.company.custom_fields.attachment.labelEn", "D\u00e9signation EN", "messages", "fr");
t.add("api.file.upload.error", "Erreur de t\u00e9l\u00e9chargement du fichier.", "messages", "fr");
t.add("reports.config.form.checkbox_private", "Projet priv\u00e9 (non visible pour les autres)", "messages", "fr");
t.add("councillor.citizenship", "Citoyennet\u00e9", "messages", "fr");
t.add("councillor.civil.status", "Etat civil", "messages", "fr");
t.add("councillor.kids", "Enfants", "messages", "fr");
t.add("councillor.address", "Adresse", "messages", "fr");
t.add("councillor.website", "Site Internet", "messages", "fr");
t.add("profile.settings.company.internal_fields.attachment.labelDe", "D\u00e9signation DE", "messages", "fr");
t.add("profile.settings.company.internal_fields.attachment.labelFr", "D\u00e9signation FR", "messages", "fr");
t.add("profile.settings.company.internal_fields.attachment.labelIt", "D\u00e9signation IT", "messages", "fr");
t.add("profile.settings.company.internal_fields.attachment.labelEn", "D\u00e9signation EN", "messages", "fr");
t.add("councillor.military.rank", "Grade militaire", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.attachment.delete.success", "Supprim\u00e9(e) avec succ\u00e8s", "messages", "fr");
t.add("reports.config.form.title.error.message", "Erreur lors de l\u2019enregistrement", "messages", "fr");
t.add("councillor.personal.details", "Donn\u00e9es personnelles", "messages", "fr");
t.add("layout.search.start_search", "D\u00e9marrer la recherche", "messages", "fr");
t.add("councillor.submitted", "D\u00e9pos\u00e9", "messages", "fr");
t.add("councillor.subscribed", "Abonn\u00e9", "messages", "fr");
t.add("councillor.submitted.proposals", "Interventions d\u00e9pos\u00e9es", "messages", "fr");
t.add("button.show.all", "Afficher tout", "messages", "fr");
t.add("councillor.advances.topics", "Sujets des interventions", "messages", "fr");
t.add("councillor.success.advances", "Succ\u00e8s des interventions", "messages", "fr");
t.add("councillor.title.topics.success", "Sujets et r\u00e9ussite", "messages", "fr");
t.add("councillor.absences.and.abstentions", "Comportement de vote", "messages", "fr");
t.add("councillor.absences.from.votes", "Absences pour %count% votes", "messages", "fr");
t.add("councillor.parliamentary.group", "Groupes parlementaires", "messages", "fr");
t.add("councillor.actual.title", "Actuel", "messages", "fr");
t.add("councillor.previous.title", "Ant\u00e9rieures", "messages", "fr");
t.add("affairs.assign_users.add_teammembers.button_title", "Assigner utilisateur", "messages", "fr");
t.add("affairs.assign_users.add_teammembers.label", "Assigner utilisateur", "messages", "fr");
t.add("affairs.assign_users.unassign_teammember.button_title", "Supprimer assignation \u00e0 l'utilisateur", "messages", "fr");
t.add("api.affair.assigned_users.get.error", "Utilisateurs assign\u00e9s n'ont pas pu \u00eatre charg\u00e9s.", "messages", "fr");
t.add("affairs.assign_users.unassign_teammember.modal.title", "\u00cates-vous s\u00fbr de vouloir supprimer les utilisateurs suivant :", "messages", "fr");
t.add("affairs.assign_users.form.title", "Assigner utilisateur", "messages", "fr");
t.add("affairs.assign_users.form.groups_more", "autres", "messages", "fr");
t.add("api.user.assign_affair.success", "Utilisateur assign\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("api.user.assign_affair.error", "Erreur lors de l'assignation \u00e0 l'utilisateur", "messages", "fr");
t.add("reports.groups.outputAssignedUsers.label", "Utilisateurs assign\u00e9s", "messages", "fr");
t.add("reports.assigned_users.label", "Utilisateurs assign\u00e9s", "messages", "fr");
t.add("api.basic_users.get.error", "Les utilisateurs ne peuvent pas \u00eatre charg\u00e9s. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("affair_list.select.assigned_users.label", "Utilisateurs assign\u00e9s", "messages", "fr");
t.add("councillor.aTotalOf", "dans l'ensemble", "messages", "fr");
t.add("affair_list.select.authors.label", "Auteur", "messages", "fr");
t.add("reports.authors.label", "Auteur", "messages", "fr");
t.add("national.consultations.details.cancelled", "Consultations arr\u00eat\u00e9es", "messages", "fr");
t.add("cantonal.affairs.status.inProgress", "en cours \/ en traitement", "messages", "fr");
t.add("cantonal.affairs.status.courtDecisions", "D\u00e9cision du Tribunal", "messages", "fr");
t.add("cantonal.affairs.status.disagreeWithConversion", "Pas d'accord avec la conversion", "messages", "fr");
t.add("cantonal.affairs.status.meeting", "S\u00e9ance", "messages", "fr");
t.add("cantonal.affairs.status.treatmentByTheCouncils", "Traitement par les Conseils", "messages", "fr");
t.add("profile.settings.company.custom_fields.affairs_label", "Dossiers", "messages", "fr");
t.add("profile.settings.company.custom_fields.meetings_label", "S\u00e9ances", "messages", "fr");
t.add("profile.settings.compay.custom_meeting_html_fields_setting.delete.confirm", "Supprimer?", "messages", "fr");
t.add("profile.settings.company.custom_meetings_html_fields.add", "Ajouter", "messages", "fr");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderDe", "D\u00e9signation DE", "messages", "fr");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderFr", "D\u00e9signation FR", "messages", "fr");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderIt", "D\u00e9signation IT", "messages", "fr");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderEn", "D\u00e9signation EN", "messages", "fr");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelDe", "D\u00e9signation DE", "messages", "fr");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelFr", "D\u00e9signation FR", "messages", "fr");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelIt", "D\u00e9signation IT", "messages", "fr");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelEn", "D\u00e9signation EN", "messages", "fr");
t.add("customMeetings.editMeeting.date.label", "Date \/ Heure", "messages", "fr");
t.add("customMeetings.editMeeting.subject.label", "Objet", "messages", "fr");
t.add("customMeetings.editMeeting.description.label", "Description", "messages", "fr");
t.add("customMeetings.editMeeting.location.label", "Lieu", "messages", "fr");
t.add("customMeetings.editMeeting.president.label", "Pr\u00e9sidence", "messages", "fr");
t.add("customMeetings.editMeeting.protocol.label", "Protocole", "messages", "fr");
t.add("customMeetings.editMeeting.guests.label", "Invit\u00e9s", "messages", "fr");
t.add("api.meeting.get.error", "Erreur lors du chargement des s\u00e9ances. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("layout.custom_meetings.custom_fields", "D\u00e9tails", "messages", "fr");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.get.error", "Erreur de chargement des champs interns des s\u00e9ances. Veuillez contacter le support.", "messages", "fr");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.put.success", "Enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.put.error", "Erreur de chargement des champs internes des s\u00e9ances. Veuillez contacter le support.", "messages", "fr");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.post.success", "Enregistr\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.post.error", "Erreur lors de l'enregistrement. Veuillez contacter le support.", "messages", "fr");
t.add("custom_meeting.internalHtmlField.save", "Enregistrer", "messages", "fr");
t.add("custom_meeting.internalHtmlField.edit", "modifier", "messages", "fr");
t.add("custom_meeting.internalHtmlField.error", "Erreur", "messages", "fr");
t.add("cantonal.affairs.status.forProcessingByTheDepartment", "\u00c0 la direction pour traitement", "messages", "fr");
t.add("layout.reports.config.save_as", "Enregistrer le nouveau projet", "messages", "fr");
t.add("layout.reports.update.config", "Mise \u00e0 jour du mod\u00e8le de rapport", "messages", "fr");
t.add("reports.affairStates.label", "Statut de dossier", "messages", "fr");
t.add("cantonal.affair.attachment", "Annexe", "messages", "fr");
t.add("security.login.auth_msg", "Veuillez introduire le code de votre application d'authentification.", "messages", "fr");
t.add("security.login.trusted", "Faire confiance \u00e0 ce dispositif pour 30 jours", "messages", "fr");
t.add("profile.edit.2fa.status", "Statut", "messages", "fr");
t.add("profile.edit.2fa.activated", "est activ\u00e9", "messages", "fr");
t.add("affairs.actors.and.roles", "Cosignataire", "messages", "fr");
t.add("affairs.actor.and.role.cosigners", "Cosignataire", "messages", "fr");
t.add("affairs.actor.and.role.adapters", "Repreneur", "messages", "fr");
t.add("affairs.actor.and.role.opponents", "Opposant", "messages", "fr");
t.add("cantonal.affairs.status.decision", "D\u00e9cision", "messages", "fr");
t.add("cantonal.affairs.status.nominations", "Nomination", "messages", "fr");
t.add("event.reminders.button.edit", "Modifier les rappels", "messages", "fr");
t.add("calendar.event.status.share", "Ajouter au calendrier", "messages", "fr");
t.add("calendar.event.tooltip.unshare", "Supprimer \u00e9v\u00e9nement du calendrier partag\u00e9", "messages", "fr");
t.add("calendar.event.tooltip.share", "Ajouter \u00e9v\u00e9nement au calendrier partag\u00e9", "messages", "fr");
t.add("calendar.share.error", "Erreur lors du partage du calendrier. Veuillez contacter le service d\u2019assistance \u00e0 l\u2019adresse support@politique.ch.", "messages", "fr");
t.add("calendar.settings.title", "Param\u00e8tres du calendrier partag\u00e9", "messages", "fr");
t.add("calendar.settings.status.enabled", "Activer le calendrier partag\u00e9", "messages", "fr");
t.add("calendar.settings.status.disabled", "D\u00e9sactiver le calendrier partag\u00e9", "messages", "fr");
t.add("calendar.settings.placeholder", "Pour voir l'adresse du calendrier partag\u00e9, vous devez d'abord l'activer.", "messages", "fr");
t.add("profile.settings.navigation.calendar", "Calendrier partag\u00e9", "messages", "fr");
t.add("ui.link.copy", "Copier le lien", "messages", "fr");
t.add("ui.link.copied", "Lien copi\u00e9", "messages", "fr");
t.add("affair.events.custom.attachment.post.error", "Erreur lors du t\u00e9l\u00e9chargement du fichier. Veuillez contacter le support: support@politique.ch", "messages", "fr");
t.add("affairs.customEvents.fields.note.attachments", "Fichiers", "messages", "fr");
t.add("affairs.customEvents.fields.note.uploadFiles", "T\u00e9l\u00e9charger le fichier", "messages", "fr");
t.add("national.parliamentaryGroup.membership.function.president", "Pr\u00e9sident", "messages", "fr");
t.add("national.parliamentaryGroup.membership.function.coPresident", "Copr\u00e9sident", "messages", "fr");
t.add("national.parliamentaryGroup.membership.function.member", "Membre", "messages", "fr");
t.add("interests.tooltip.organ.function", "Organe \/ Fonction", "messages", "fr");
t.add("councillor.interests.title", "Liens d'int\u00e9r\u00eats", "messages", "fr");
t.add("councillor.title.current.mandate", "Mandats parlementaires actuels", "messages", "fr");
t.add("councillors.interests.paid", "pay\u00e9", "messages", "fr");
t.add("councillors.interests.unpaid", "b\u00e9n\u00e9vole", "messages", "fr");
t.add("national.notifications.preferences.label", "Sp\u00e9cifiez si vous souhaitez recevoir des notifications pour les affaires f\u00e9d\u00e9rals et, le cas \u00e9ch\u00e9ant, lesquelles.", "messages", "fr");
t.add("cantonal.notifications.preferences.label", "Sp\u00e9cifiez si vous souhaitez recevoir des notifications sur les affaires des cantons et, le cas \u00e9ch\u00e9ant, lesquelles.", "messages", "fr");
t.add("get.national.notifications.label", "Recevoir des notifications pour le Conseil f\u00e9d\u00e9ral", "messages", "fr");
t.add("get.cantonal.notifications.label", "Recevoir des notifications pour les cantons", "messages", "fr");
t.add("search.terms.notifications.preferences", "Notifier uniquement mes termes de recherche", "messages", "fr");
t.add("search.terms.restore", "R\u00e9tablir", "messages", "fr");
t.add("search.terms.page.title", "Terme Recherch\u00e9", "messages", "fr");
t.add("search.terms.page.define.message", "D\u00e9finissez ici tous vos termes de recherche pertinents. Nous vous informerons \u00e0 chaque fois qu'ils appara\u00eetront dans de nouvelles dossiers ou mises \u00e0 jour.", "messages", "fr");
t.add("search.terms.select.all.types", "Choisir tous les types", "messages", "fr");
t.add("attachment.delete.confirm", "\u00cates-vous s\u00fbr de vouloir supprimer cette fiche?", "messages", "fr");
t.add("cantonal.affairs.status.answerCovenant", "R\u00e9ponse Conseil f\u00e9d\u00e9ral", "messages", "fr");
t.add("councillor.parliamentary.groups", "Groupes parlementaires", "messages", "fr");
t.add("councillor.former.commissions", "Mandats parlementaires pr\u00e9c\u00e9dents", "messages", "fr");
t.add("councillor.proposals.submitted", "D\u00e9pos\u00e9", "messages", "fr");
t.add("api.links.delete.success", "Le dossier li\u00e9 a \u00e9t\u00e9 supprim\u00e9.", "messages", "fr");
t.add("api.links.delete.error", "Erreur lors de la suppression des dossiers li\u00e9es. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("groups.link.to_unarchived_groups.title", "Vers les groups", "messages", "fr");
t.add("groups.link.to_archived_groups.title", "Vers les groups archiv\u00e9s", "messages", "fr");
t.add("affairGroups.unarchive", "R\u00e9tablir le groupe", "messages", "fr");
t.add("affairGroup.unarchive.confirm", "\u00cates-vous s\u00fbr de vouloir d\u00e9sarchiver ce groupe ?", "messages", "fr");
t.add("api.groups.put.unarchive.success", "Le groupe a \u00e9t\u00e9 r\u00e9tabli avec succ\u00e8s.", "messages", "fr");
t.add("api.groups.put.unarchive.error", "Le groupe n'a pas pu \u00eatre r\u00e9tabli. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("api.groups.put.archive.success", "Le groupe a \u00e9t\u00e9 archiv\u00e9.", "messages", "fr");
t.add("api.groups.put.archive.error", "Le groupe n'a pas pu \u00eatre archiv\u00e9. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("customMeetings.unarchive", "R\u00e9tablir la s\u00e9ance", "messages", "fr");
t.add("api.meetings.put.unarchive.success", "La s\u00e9ance a \u00e9t\u00e9 r\u00e9tabli avec succ\u00e8s.", "messages", "fr");
t.add("api.meetings.put.unarchive.error", "La s\u00e9ance n'a pas pu \u00eatre r\u00e9tablie. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("api.meetings.put.archive.success", "La s\u00e9ance a \u00e9t\u00e9 archiv\u00e9e.", "messages", "fr");
t.add("api.meetings.put.archive.error", "La s\u00e9ance n'a pas pu \u00eatre archiv\u00e9e. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("customMeetings.link.to_unarchived_groups.title", "Vers les s\u00e9ances", "messages", "fr");
t.add("customMeetings.link.to_archived_groups.title", "Vers les s\u00e9ances archiv\u00e9es", "messages", "fr");
t.add("calendar.modal.text.not.shared", "L'\u00e9v\u00e9nement a \u00e9t\u00e9 ajout\u00e9 \u00e0 votre calendrier partag\u00e9. Pour le voir, vous devez activer le calendrier partag\u00e9.\u2028 Vous pouvez le faire maintenant ou plus tard, sous \"Votre nom\" > Compte utilisateur > Calendrier partag\u00e9.", "messages", "fr");
t.add("calendar.modal.not.active", "Le calendrier partag\u00e9 n'est pas activ\u00e9", "messages", "fr");
t.add("calendar.modal.activate.later", "Activer plus tard", "messages", "fr");
t.add("calendar.modal.activate.now", "Aller aux param\u00e8tres", "messages", "fr");
t.add("profile.settings.nationalNotificationPreferences.pressRelease", "Communiqu\u00e9s de presse", "messages", "fr");
t.add("affair.councilStatesDebate.tooltip.caption", "D\u00e9bats du Conseil des Etats", "messages", "fr");
t.add("cosigners.empty.list", "Ce dossier n'a pas de cosignataire", "messages", "fr");
t.add("search.terms.apply.all.cantons", "Appliquer \u00e0 tous les cantons", "messages", "fr");
t.add("cantons.settings.reset.confirm", "Les param\u00e8tres de tous les cantons seront ainsi remplac\u00e9s. Voulez-vous continuer ?", "messages", "fr");
t.add("councillor.phone", "Num\u00e9ro de t\u00e9l\u00e9phone", "messages", "fr");
t.add("cantonal.overview.government", "Gouvernement", "messages", "fr");
t.add("api.government.author.get.error", "Erreur lors de la consultation de l'auteur du gouvernement", "messages", "fr");
t.add("custom.affairs.form.edit.title", "Modifier les dossiers internes", "messages", "fr");
t.add("affair.actions_card.attachments_title", "Annexes", "messages", "fr");
t.add("affair.actions_card.visual_report_section_title", "Rapport visuel", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.builtin.get.error", "Erreur de lecture. Veuillez contacter le service d\u2019assistance.", "messages", "fr");
t.add("affair.builtin_fields.time_axis.beginning_label", "Au d\u00e9but", "messages", "fr");
t.add("affair.builtin_fields.time_axis.end_label", "Juste avant la cl\u00f4ture", "messages", "fr");
t.add("affair.builtin_fields.need_for_action.low_prio_label", "Monitoring", "messages", "fr");
t.add("affair.builtin_fields.need_for_action.high_prio_label", "Urgente n\u00e9cessit\u00e9 d'agir", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.ratings.put.sucess", "Le champ a \u00e9t\u00e9 mis \u00e0 jour avec succ\u00e8s.", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.ratings.post.sucess", "Le champ a \u00e9t\u00e9 enregistr\u00e9 avec succ\u00e8s.", "messages", "fr");
t.add("reports.visual_report", "Rapport visuel", "messages", "fr");
t.add("reports.visual_report.title", "Cr\u00e9er un rapport visuel", "messages", "fr");
t.add("reports.sections.visible_internal_fields.title", "Quelles sont les donn\u00e9es internes que vous souhaitez rendre visibles ?", "messages", "fr");
t.add("reports.sections.visible_internal_fields.description", "Veuillez ne pas partager les donn\u00e9es sensibles. Ce rapport sera visible sur une URL publique et vos donn\u00e9es internes ne seront pas prot\u00e9g\u00e9es. Les champs de donn\u00e9es internes \"Statut\", \"Urgence\" et \"Impact\" sont obligatoires et seront n\u00e9cessaires pour construire le rapport visuel. Les champs internes que vous s\u00e9lectionnez comme filtres seront \u00e9galement affich\u00e9s par d\u00e9faut.", "messages", "fr");
t.add("reports.sections.visual_report_filter_fields.title", "Quels champs d\u00e9finis par l'utilisateur souhaitez-vous utiliser comme filtre ? (max. 2 filtres)", "messages", "fr");
t.add("reports.sections.make_report_public.toggle.title", "Publier le rapport", "messages", "fr");
t.add("reports.sections.save_report.title", "Enregistrer le rapport", "messages", "fr");
t.add("form.visual_report.submit", "Enregistrer le rapport visuel", "messages", "fr");
t.add("reports.save_visual_report.error", "Une erreur est survenue lors de l'enregistrement du rapport visuel. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("reports.save_visual_report.success_modal.title", "Le rapport visuel a \u00e9t\u00e9 cr\u00e9\u00e9 avec succ\u00e8s.", "messages", "fr");
t.add("reports.save_visual_report.success_modal.description", "Le rapport visuel <strong>%reportName%<\/strong> a \u00e9t\u00e9 cr\u00e9\u00e9 avec succ\u00e8s avec <strong>%numberOfGroups% Gruppen<\/strong>.", "messages", "fr");
t.add("reports.save_visual_report.success_modal.public_url", "URL publique :", "messages", "fr");
t.add("reports.save_visual_report.success_modal.copy_url_button.title", "Copier l'URL", "messages", "fr");
t.add("reports.save_visual_report.success_modal.open_report_button.title", "Ouvrir le rapport dans une nouvelle fen\u00eatre", "messages", "fr");
t.add("internalFieldDefinitions.builtIn.size.low.label", "Faible", "messages", "fr");
t.add("internalFieldDefinitions.builtIn.size.medium.label", "Moyenne", "messages", "fr");
t.add("internalFieldDefinitions.builtIn.size.high.label", "Elev\u00e9e", "messages", "fr");
t.add("internalFieldDefinitions.builtIn.size.veryHigh.label", "Tr\u00e8s \u00e9lev\u00e9e", "messages", "fr");
t.add("cantonal.affairs.status.changeRequest", "Demande de changement", "messages", "fr");
t.add("cantonal.affairs.status.cantonalInitiativeSubmission", "Initiative d\u00e9pos\u00e9e par un canton", "messages", "fr");
t.add("vote.national_council.error", "Les votes du Conseil national n'ont pas pu \u00eatre consult\u00e9s. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("vote.council_of_states.error", "Les votes du Conseil des Etats n'ont pas pu \u00eatre consult\u00e9s. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("groups.tree.group.openGroupDetail", "A la page des groupes", "messages", "fr");
t.add("reports.save_visual_report.success_modal.copied_to_clipboard", "Lien copi\u00e9 dans le presse-papiers", "messages", "fr");
t.add("committee.private.affairs", "Dossiers priv\u00e9s", "messages", "fr");
t.add("reports.visual_report.not_complete_affairs", "Le rapport contient des dossiers pour lesquels aucune information sur la pertinence, le statut ou la n\u00e9cessit\u00e9 d'agir n'a encore \u00e9t\u00e9 donn\u00e9e.", "messages", "fr");
t.add("reports.visual_report.exceeding_limit", "Le rapport contient plus de 35 dossiers. Pour une meilleure vue d'ensemble, nous vous recommandons de restreindre votre s\u00e9lection.", "messages", "fr");
t.add("reports.visual_report.create_anyway", "Ignorer et continuer", "messages", "fr");
t.add("reports.visual_report.back_to_reports", "Retour aux param\u00e8tres", "messages", "fr");
t.add("reports.sections.make_report_public.title", "Souhaitez-vous publier le rapport maintenant ?", "messages", "fr");
t.add("reports.sections.make_report_public.description", "Ce rapport visuel sera visible pour tous ceux avec qui vous avez partag\u00e9 l'URL publique. Vous pouvez modifier ce param\u00e8tre \u00e0 tout moment.", "messages", "fr");
t.add("reports.sections.report_name.title", "Quel nom voulez-vous donner \u00e0 votre rapport visuel ?", "messages", "fr");
t.add("reports.report_name_input.label", "Nom du rapport", "messages", "fr");
t.add("api.reports.visual_repor_config.get.error", "Les derniers rapports visuels n'ont pas pu \u00eatre charg\u00e9s. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("reports.visual_report_config.delete.confirm.message", "\u00cates-vous s\u00fbr de vouloir supprimer ce rapport visuel ?", "messages", "fr");
t.add("api.reports.visual_report_config.delete.success", "Le rapport visuel a \u00e9t\u00e9 supprim\u00e9.", "messages", "fr");
t.add("api.reports.visual_report_config.delete.error", "Une erreur est survenue lors de la suppression du rapport visuel. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("reports.load_visual_report.error", "Le rapport visuel n'a pas pu \u00eatre charg\u00e9. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("reports.update_visual_report.error", "Le rapport visuel n'a pas pu \u00eatre mis \u00e0 jour. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("reports.update_visual_report.success", "Le rapport visuel a \u00e9t\u00e9 mis \u00e0 jour.", "messages", "fr");
t.add("cantonal.affairs.status.opinion", "Avis", "messages", "fr");
t.add("internalFieldDefinitions.builtIn.y.label", "N\u00e9cessit\u00e9 d'agir", "messages", "fr");
t.add("internalFieldDefinitions.builtIn.x.label", "Progr\u00e8s", "messages", "fr");
t.add("internalFieldDefinitions.builtIn.size.label", "Pertinence", "messages", "fr");
t.add("visualiser.beginning", "Au d\u00e9but", "messages", "fr");
t.add("visualiser.end", "Juste avant la fin", "messages", "fr");
t.add("visualiser.monitoring", "Observer", "messages", "fr");
t.add("visualiser.urgency", "Urgente n\u00e9cessit\u00e9 d'agir", "messages", "fr");
t.add("dashboard.newest_affairs", "Derniers dossiers", "messages", "fr");
t.add("dashboard.updated_affairs", "Mises \u00e0 jour", "messages", "fr");
t.add("dashboard.assigned_affairs", "Attribu\u00e9", "messages", "fr");
t.add("dashboard.team_actions", "Mises \u00e0 jour du team", "messages", "fr");
t.add("shared.affair.type", "Type de dossier", "messages", "fr");
t.add("shared.affair.lastupdate", "Dernier \u00e9v\u00e9nement le", "messages", "fr");
t.add("shared.affair.linkedaffairs", "Dossiers li\u00e9s", "messages", "fr");
t.add("shared.further.info", "Plus d'informations", "messages", "fr");
t.add("shared.affair.linkedaffairs.none", "Aucun dossier li\u00e9", "messages", "fr");
t.add("reports.visual_report_menu.header", "Derniers rapports visuels", "messages", "fr");
t.add("cantonal.affairAuthorRole.signer", "Premier signataire", "messages", "fr");
t.add("cantonal.affairAuthorRole.co_signer", "Cosignataire", "messages", "fr");
t.add("cantonal.affairAuthorRole.commission", "Commission pr\u00e9liminaire", "messages", "fr");
t.add("consultation.submittedByOn.exist", "D\u00e9pos\u00e9 par %councillors%. Ouverture le %date%", "messages", "fr");
t.add("consultation.submittedByOn.none", "D\u00e9pos\u00e9 par %councillors%. Ouverture le %date%", "messages", "fr");
t.add("shared.affair.status.none", "Statut pas disponible", "messages", "fr");
t.add("customMeetings.tree.customMeeting.openMeeting", "Aller \u00e0 la page de la s\u00e9ance", "messages", "fr");
t.add("cantonal.affairs.status.urgentInterpellation", "Interpellation urgente", "messages", "fr");
t.add("shared.affair.affairGroups", "Groupe", "messages", "fr");
t.add("reports.save_visual_report.success_copied_url_button.title", "Copi\u00e9 !", "messages", "fr");
t.add("affair.customShortTitle.information", "Max. 40 caract\u00e8res", "messages", "fr");
t.add("customMeetings.unarchive.confirm", "Souhaitez-vous r\u00e9tablir la s\u00e9ance ?", "messages", "fr");
t.add("cantonal.affairs.status.removedFromSource", "Le dossier a \u00e9t\u00e9 retir\u00e9 du site web cantonal", "messages", "fr");
t.add("internalFieldDefinitions.builtIn.shortTitle.label", "Titre abr\u00e9g\u00e9", "messages", "fr");
t.add("share.filters.groups", "Groupes", "messages", "fr");
t.add("dashboard.updates.item_topics", "Sujets", "messages", "fr");
t.add("shared.filters.show.all", "Afficher tout", "messages", "fr");
t.add("shared.filters.hide.all", "Cacher tout", "messages", "fr");
t.add("dashboard.newest_affairs.tooltip", "Tous les dossiers qui ont \u00e9t\u00e9 import\u00e9s au cours des 7 derniers jours et qui correspondent \u00e0 vos termes de recherche. Si vous n'avez pas encore d\u00e9fini de termes de recherche, nous vous montrons au moins les 20 derniers dossiers.", "messages", "fr");
t.add("dashboard.updated_affairs.tooltip", "Mises \u00e0 jour des 4 derni\u00e8res semaines, des affairs que vous suivez ou qui ont \u00e9t\u00e9 ajout\u00e9s \u00e0 un groupe que vous suivez", "messages", "fr");
t.add("dashboard.assigned_affairs.tooltip", "Toutes vos dossiers attribu\u00e9s", "messages", "fr");
t.add("dashboard.team_actions.tooltip", "Dossiers internes cr\u00e9\u00e9s au sein de votre entreprise et dossiers ajout\u00e9s \u00e0 un groupe au sein de votre entreprise", "messages", "fr");
t.add("national.affairs.list.header.lastEventDate", "Date de fin", "messages", "fr");
t.add("dashboard.custom.tooltip", "Lien rapide d\u00e9fini par l'utilisateur", "messages", "fr");
t.add("dashboard.quicklinks.failed_to_save", "Le lien rapide n'a pas pu \u00eatre enregistr\u00e9. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("dashboard.quicklinks.failed_to_fetch", "Le lien rapide n'a pas pu \u00eatre ouvert. Veuillez r\u00e9essayer.", "messages", "fr");
t.add("dashboard.edit_customize", "Modifier", "messages", "fr");
t.add("dashboard.restore_default", "R\u00e9tablir", "messages", "fr");
t.add("dashboard.restore.default", "Souhaitez-vous r\u00e9initialiser le lien rapide \u00e0 ses param\u00e8tres par d\u00e9faut ? Cela ne peut pas \u00eatre annul\u00e9.", "messages", "fr");
t.add("dashboard.edit.shortcut", "Lien rapide d\u00e9fini par l'utilisateur", "messages", "fr");
t.add("dashboard.custom.quicklink.name", "Nom du lien rapide", "messages", "fr");
t.add("dashboard.custom.quicklink.search.template", "Choisir un mod\u00e8le de recherche", "messages", "fr");
t.add("dashboard.custom.quicklink.icon", "Ic\u00f4ne", "messages", "fr");
t.add("dashboard.custom.quicklink.show_updates", "Afficher seulement les mises \u00e0 jour", "messages", "fr");
t.add("dashboard.custom.quicklink.show_all", "Afficher tous les dossiers", "messages", "fr");
t.add("national.swiss.affairs.consultations.caption", "Suisse", "messages", "fr");
t.add("share.filters", "Filtre", "messages", "fr");
t.add("dashboard.custom.quicklink.no.search.template", "Aucun mod\u00e8le de recherche disponible", "messages", "fr");
t.add("cantonal.affairs.status.addedToCommitteeProgram", "Inscrit au programme de la commission", "messages", "fr");
t.add("cantonal.affairs.status.initiativeToTheFederalAssembly", "Initiative aupr\u00e8s de l'Assembl\u00e9e f\u00e9d\u00e9rale", "messages", "fr");
t.add("cantonal.affairs.status.annualReport", "Rapport annuel", "messages", "fr");
t.add("affair.event.pressRelease.link.tooltip", "Ouvrir la source officielle", "messages", "fr");
t.add("pages.access_restricted.no_permission", "Pas d'acc\u00e8s au contenu de cette page", "messages", "fr");
t.add("pages.access_restricted.contact_admin", "Pour toute assistance, veuillez vous adresser \u00e0 l'administration de votre entreprise.", "messages", "fr");
t.add("pages.access_restricted.interested_in_access", "Si vous \u00eates int\u00e9ress\u00e9 par une politique.ch licence, nous serons tr\u00e8s heureux de vous pr\u00e9senter notre produit et de vous inviter \u00e0 le tester.", "messages", "fr");
t.add("pages.access_restricted.contact_us", "Veuillez nous contacter par e-mail \u00e0 info@politique.ch ou par t\u00e9l\u00e9phone (+41) 044 512 12 60.", "messages", "fr");
t.add("cantonal.affairs.status.swearingIn", "Assermentation", "messages", "fr");
t.add("shared.filters.show.linked_affairs", "Afficher tous les liens entre les dossiers", "messages", "fr");
t.add("reports.sections.report_name.error.name_exists", "Un rapport portant ce nom existe d\u00e9j\u00e0. Veuillez choisir un autre nom.", "messages", "fr");
t.add("dashboard.table.type.insert", "Ajouter", "messages", "fr");
t.add("dashboard.table.type.update", "Mise \u00e0 jour", "messages", "fr");
t.add("dashboard.table.type.remove", "Supprimer", "messages", "fr");
t.add("layout.search.type.allAffairs", "Tous les dossiers", "messages", "fr");
t.add("layout.search.config.delete.exists_in_dashboard.message", "Ce mod\u00e8le de recherche est utilis\u00e9 dans les liens rapides du tableau de bord. \u00cates-vous s\u00fbr de vouloir le supprimer ? Pour les liens rapides du tableau de bord, le d\u00e9faut sera alors r\u00e9tabli.", "messages", "fr");
t.add("shared.affair.commissions", "Commission d\u00e9posante", "messages", "fr");
t.add("shared.affair.departments", "D\u00e9partements responsables", "messages", "fr");
t.add("shared.affair.agenda", "\u00c0 l'ordre du jour", "messages", "fr");
t.add("shared.affair.description", "Texte d\u00e9pos\u00e9", "messages", "fr");
t.add("visualizer_intro.label", "Texte d'accueil pour le rapport visuel", "messages", "fr");
t.add("visualizer_intro_title.label", "Titre pour le texte d'introduction du rapport visuel", "messages", "fr");
t.add("reports.sections.sessions.title", "\u00c0 quelle session ce rapport doit-il se r\u00e9f\u00e9rer ?", "messages", "fr");
t.add("customField.setings.affairType.label", "Utilis\u00e9 dans les types de dossier", "messages", "fr");
t.add("internalFields.TextField.label", "Champ de texte", "messages", "fr");
t.add("internalFields.OptionsField.label", "Champ de s\u00e9lection", "messages", "fr");
t.add("internalFields.AttachmentField.label", "Fichiers attach\u00e9s", "messages", "fr");
t.add("layout.affair.stat.label", "Statut du dossier", "messages", "fr");
t.add("layout.affair.latestEvent.name", "Titre du dernier \u00e9v\u00e9nement", "messages", "fr");
t.add("reports.sessionProgram.council.notDefined", "Conseil de premi\u00e8re instance non d\u00e9fini", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.html.delete.success", "Le champ interne a \u00e9t\u00e9 supprim\u00e9", "messages", "fr");
t.add("api.profile.settings.company.internal_fields.html.delete.error", "Erreur lors de la suppression du champ interne, veuillez r\u00e9essayer", "messages", "fr");
t.add("panel.additionalInfoLink.label", "D\u00e9tails", "messages", "fr");
t.add("outputInternalAffairAuthors", "Auteur interne (cr\u00e9ateur)", "messages", "fr");
t.add("outputAuthors", "Auteur", "messages", "fr");
t.add("reports.sections.show_agenda.toggle.title", "Afficher l'aper\u00e7u de la session", "messages", "fr");
t.add("shared.agenda.session_program.title", "Dossiers inscrits \u00e0 l'ordre du jour et trait\u00e9s pendant la session :", "messages", "fr");
t.add("shared.agenda.deposited.title", "Nouveaux dossiers d\u00e9pos\u00e9s pendant la session :", "messages", "fr");
t.add("shared.agenda.confirm_dialog.title", "Le dossier ne peut pas \u00eatre affich\u00e9", "messages", "fr");
t.add("shared.agenda.confirm_dialog.description", "Ce dossier a \u00e9t\u00e9 trait\u00e9 pendant la session et l'auteur du rapport l'a \u00e9galement attribu\u00e9 \u00e0 ce rapport. Il manque cependant les donn\u00e9es n\u00e9cessaires pour l'afficher dans le graphique. Seulement l'auteur du dossier peut adapter cela.", "messages", "fr");
t.add("shared.agenda.confirm_dialog.button.text", "J'ai compris!", "messages", "fr");
t.add("shared.agenda.deposited.no_data_available.text", "Aucun des dossiers pr\u00e9sent\u00e9s ici n'a \u00e9t\u00e9 soumis pendant la session s\u00e9lectionn\u00e9e.", "messages", "fr");
t.add("shared.agenda.session_program.no_data_available.text", "Aucun des dossiers affich\u00e9s n'a \u00e9t\u00e9 trait\u00e9 pendant la session.", "messages", "fr");
t.add("reports.groups.outputInternalAffairAuthors.label", "Auteurs\/Cr\u00e9ateurs des dossiers interns", "messages", "fr");
t.add("affair.SRDebateEvent.tooltip.caption", "D\u00e9bat Conseil des Etats", "messages", "fr");
t.add("cantonal.affairs.status.replyDemanded", "R\u00e9ponse exig\u00e9e", "messages", "fr");
t.add("affair.SRCouncilVote.tooltip.caption", "Vote Conseil des Etats", "messages", "fr");
t.add("affair.NationalDebateEvent.tooltip.caption", "D\u00e9bat Conseil national", "messages", "fr");
t.add("affair.NRCouncilVote.tooltip.caption", "Vote Conseil national", "messages", "fr");
t.add("custom.affairs.form.link", "Lien vers la source", "messages", "fr");
t.add("affair.NationalAffairConsultationNoteCustomEvent.tooltip.caption", "Note interne", "messages", "fr");
t.add("affair.CantonalAffairConsultationNoteCustomEvent.tooltip.caption", "Note interne", "messages", "fr");
t.add("cantonal.affairs.status.postponed", "Report\u00e9", "messages", "fr");
t.add("api.links.post.success", "Dossier li\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("cantonal.affairs.type.regbes.title", "D\u00e9cision du Conseil d'Etat", "messages", "fr");
t.add("cantonal.media_releases.title", "Communiqu\u00e9s de presse", "messages", "fr");
t.add("cantonal.media_releases.table.header.title", "Titre", "messages", "fr");
t.add("cantonal.media_releases.table.header.date", "Publi\u00e9 le", "messages", "fr");
t.add("cantonal.media_releases.table.header.link", "Lien vers le site du canton", "messages", "fr");
t.add("cantonal.overview.mediaRelease", "Communiqu\u00e9s de presse", "messages", "fr");
t.add("api.cantonal_media_release.get.error", "Le communiqu\u00e9 de presse n'a pas pu \u00eatre charg\u00e9", "messages", "fr");
t.add("notification.tableOfContents.cantonalMediaRelease.new", "Nouveau communiqu\u00e9 de presse publi\u00e9", "messages", "fr");
t.add("notification.tableOfContents.cantonalMediaRelease.updated", "Communiqu\u00e9 de presse actualis\u00e9", "messages", "fr");
t.add("cantonal.mediaRelease.itemAdded", "Communiqu\u00e9 de presse publi\u00e9", "messages", "fr");
t.add("cantonal.affairs.type.regbes.abbr", "D\u00e9cision du Conseil d'Etat", "messages", "fr");
t.add("national.affairs.author.published.by", "Publi\u00e9 par", "messages", "fr");
t.add("helpScout.suggestedForYou", "R\u00e9ponses imm\u00e9diates", "messages", "fr");
t.add("helpScout.getInTouch", "Nous contacter", "messages", "fr");
t.add("helpScout.searchLabel", "Que pouvons-nous faire pour toi ?", "messages", "fr");
t.add("helpScout.tryAgain", "Essayer \u00e0 nouveau", "messages", "fr");
t.add("helpScout.defaultMessageErrorText", "Un probl\u00e8me est survenu lors de l'envoi de ton message. Merci de r\u00e9essayer dans quelques instants.", "messages", "fr");
t.add("helpScout.beaconButtonClose", "Fermer", "messages", "fr");
t.add("helpScout.beaconButtonChatMinimize", "Minimiser le chat", "messages", "fr");
t.add("helpScout.beaconButtonChatOpen", "Ouvrir le chat", "messages", "fr");
t.add("helpScout.answer", "R\u00e9ponses", "messages", "fr");
t.add("helpScout.ask", "Fermer", "messages", "fr");
t.add("helpScout.messageButtonLabel", "Email", "messages", "fr");
t.add("helpScout.noTimeToWaitAround", "Tu n'as pas le temps d'attendre ? Nous r\u00e9pondons g\u00e9n\u00e9ralement dans les heures qui suivent", "messages", "fr");
t.add("helpScout.chatButtonLabel", "Chat", "messages", "fr");
t.add("helpScout.chatButtonDescription", "Nous sommes en ligne en ce moment m\u00eame, parle \u00e0 notre \u00e9quipe en temps r\u00e9el.", "messages", "fr");
t.add("helpScout.wereHereToHelp", "Entamer une conversation", "messages", "fr");
t.add("helpScout.whatMethodWorks", "Quel canal pr\u00e9f\u00e9res-tu ?", "messages", "fr");
t.add("helpScout.previousMessages", "Pr\u00e9c\u00e9dentes conversations", "messages", "fr");
t.add("helpScout.cantFindAnswer", "Pas de r\u00e9ponse ?", "messages", "fr");
t.add("helpScout.relatedArticles", "Articles apparent\u00e9s", "messages", "fr");
t.add("helpScout.nothingFound", "Hmm...", "messages", "fr");
t.add("helpScout.docsSearchEmptyText", "Nous n'avons trouv\u00e9 aucun article correspondant \u00e0 ta recherche.", "messages", "fr");
t.add("helpScout.tryBroaderTerm", "Essaye de rechercher un terme plus large, ou", "messages", "fr");
t.add("helpScout.docsArticleErrorText", "Il est impossible d'acc\u00e9der \u00e0 cet article. V\u00e9rifie ta connexion Internet et r\u00e9essaie.", "messages", "fr");
t.add("helpScout.docsSearchErrorText", "Il est impossible de trouver l'article en question. V\u00e9rifie ta connexion Internet et r\u00e9essaie.", "messages", "fr");
t.add("helpScout.escalationQuestionFeedback", "Cet article a-t-il r\u00e9pondu \u00e0 ta question ?", "messages", "fr");
t.add("helpScout.escalationQuestionFeedbackNo", "Non", "messages", "fr");
t.add("helpScout.escalationQuestionFeedbackYes", "Oui", "messages", "fr");
t.add("helpScout.escalationSearchText", "Chercher la r\u00e9ponse \u00e0 ta question dans les documents d'aide.", "messages", "fr");
t.add("helpScout.escalationSearchTitle", "Poursuivre la recherche", "messages", "fr");
t.add("helpScout.escalationTalkText", "Parler \u00e0 un membre de notre \u00e9quipe de support", "messages", "fr");
t.add("helpScout.escalationTalkTitle", "Nous contacter", "messages", "fr");
t.add("helpScout.escalationThanksFeedback", "Merci de nous avoir fait part de tes commentaires", "messages", "fr");
t.add("helpScout.escalationWhatNext", "Que faire ensuite ?", "messages", "fr");
t.add("helpScout.sendAMessage", "Envoyer un message", "messages", "fr");
t.add("helpScout.firstAFewQuestions", "Commen\u00e7ons par quelques questions", "messages", "fr");
t.add("helpScout.howCanWeHelp", "Comment pouvons-nous t'aider ?", "messages", "fr");
t.add("helpScout.responseTime", "Nous r\u00e9pondons g\u00e9n\u00e9ralement dans les heures qui suivent", "messages", "fr");
t.add("helpScout.history", "Histoire", "messages", "fr");
t.add("helpScout.uploadAnImage", "T\u00e9l\u00e9charger une image", "messages", "fr");
t.add("helpScout.attachAFile", "Joindre un fichier", "messages", "fr");
t.add("helpScout.continueEditing", "Continuer \u00e0 \u00e9crire...", "messages", "fr");
t.add("helpScout.lastUpdated", "Derni\u00e8re mise \u00e0 jour", "messages", "fr");
t.add("helpScout.you", "Ton nom", "messages", "fr");
t.add("helpScout.nameLabel", "Nom de la personne", "messages", "fr");
t.add("helpScout.subjectLabel", "Sujet", "messages", "fr");
t.add("helpScout.emailLabel", "Ton adresse e-mail", "messages", "fr");
t.add("helpScout.messageLabel", "Comment pouvons-nous t'aider ?", "messages", "fr");
t.add("helpScout.messageSubmitLabel", "Envoyer un message", "messages", "fr");
t.add("helpScout.next", "Suivant", "messages", "fr");
t.add("helpScout.weAreOnIt", "Nous y travaillons !", "messages", "fr");
t.add("helpScout.messageConfirmationText", "Nous te r\u00e9pondrons par e-mail dans les heures \u00e0 venir.", "messages", "fr");
t.add("helpScout.viewAndUpdateMessage", "Il est possible de consulter et de mettre \u00e0 jour ce message \u00e0 l'adresse suivante", "messages", "fr");
t.add("helpScout.mayNotBeEmpty", "Ne peut \u00eatre vide", "messages", "fr");
t.add("helpScout.customFieldsValidationLabel", "Merci de remplir tous les champs", "messages", "fr");
t.add("helpScout.emailValidationLabel", "Merci d'utiliser une adresse e-mail valide", "messages", "fr");
t.add("helpScout.attachmentErrorText", "Il y a eu un probl\u00e8me de chargement de la pi\u00e8ce jointe. R\u00e9essayer plus tard.", "messages", "fr");
t.add("helpScout.attachmentSizeErrorText", "Les pi\u00e8ces jointes ne peuvent pas d\u00e9passer 10 MB", "messages", "fr");
t.add("helpScout.addReply", "Ajouter une r\u00e9ponse", "messages", "fr");
t.add("helpScout.addYourMessageHere", "Ajouter ton message ici...", "messages", "fr");
t.add("helpScout.sendMessage", "Envoyer le message", "messages", "fr");
t.add("helpScout.received", "Re\u00e7u", "messages", "fr");
t.add("helpScout.waitingForAnAnswer", "En attente de r\u00e9ponse", "messages", "fr");
t.add("helpScout.previousMessageErrorText", "Un probl\u00e8me est survenu lors de la r\u00e9cup\u00e9ration de ce message. V\u00e9rifie ta connexion Internet et r\u00e9essaie.", "messages", "fr");
t.add("helpScout.justNow", "Maintenant", "messages", "fr");
t.add("helpScout.chatHeadingTitle", "Chat avec notre \u00e9quipe", "messages", "fr");
t.add("helpScout.chatHeadingSublabel", "Nous serons bient\u00f4t \u00e0 ta disposition", "messages", "fr");
t.add("helpScout.chatEndCalloutHeading", "C'est fait !", "messages", "fr");
t.add("helpScout.chatEndCalloutMessage", "Une copie de cette conversation sera envoy\u00e9e dans ta bo\u00eete mail dans les plus brefs d\u00e9lais.", "messages", "fr");
t.add("helpScout.chatEndCalloutLink", "Retour \u00e0 l'accueil", "messages", "fr");
t.add("helpScout.chatEndUnassignedCalloutHeading", "D\u00e9sol\u00e9", "messages", "fr");
t.add("helpScout.chatEndUnassignedCalloutMessage", "Il semble que personne n'ait pu joindre ton chat. Nous t'enverrons une r\u00e9ponse par e-mail dans les plus brefs d\u00e9lais.", "messages", "fr");
t.add("helpScout.chatEndWaitingCustomerHeading", "D\u00e9sol\u00e9", "messages", "fr");
t.add("helpScout.chatEndWaitingCustomerMessage", "Ta question a \u00e9t\u00e9 ajout\u00e9e \u00e0 notre file d'attente d'e-mails et nous te r\u00e9pondrons d\u00e8s que possible.", "messages", "fr");
t.add("helpScout.ending", "Fin...", "messages", "fr");
t.add("helpScout.endChat", "Fin du chat", "messages", "fr");
t.add("helpScout.chatEnded", " a mis fin au chat", "messages", "fr");
t.add("helpScout.chatConnected", "Connect\u00e9 \u00e0", "messages", "fr");
t.add("helpScout.chatbotName", "Bot d'aide", "messages", "fr");
t.add("helpScout.chatbotGreet", "Bonjour ! Tu peux poser ta question ci-dessous. Quelqu'un te r\u00e9pondra dans les plus brefs d\u00e9lais.", "messages", "fr");
t.add("helpScout.chatbotPromptEmail", "Je comprends. Quelle est ton adresse e-mail ? Elle nous permettra d'assurer le suivi de ta demande.", "messages", "fr");
t.add("helpScout.chatbotConfirmationMessage", "Merci. Un membre de notre \u00e9quipe va bient\u00f4t rejoindre le chat.", "messages", "fr");
t.add("helpScout.chatbotGenericErrorMessage", "L'envoi de ton message n'a pas abouti, merci de r\u00e9essayer dans quelques minutes.", "messages", "fr");
t.add("helpScout.chatbotInactivityPrompt", "Le chat \u00e9tant inactif, il sera ferm\u00e9 dans quelques minutes.", "messages", "fr");
t.add("helpScout.chatbotInvalidEmailMessage", "Il semble que cette adresse e-mail ne soit pas valide. Veux-tu r\u00e9essayer ?", "messages", "fr");
t.add("helpScout.chatbotAgentDisconnectedMessage", " s'est d\u00e9connect\u00e9 du chat. Il est possible que la connexion Internet ait \u00e9t\u00e9 interrompue, je cherche donc quelqu'un d'autre. Nous nous excusons pour cette interruption!", "messages", "fr");
t.add("helpScout.chatAvailabilityChangeMessage", "La disponibilit\u00e9 de notre \u00e9quipe a chang\u00e9 et il n'y a plus personne de disponible pour le chat. Envoie-nous un message et nous te r\u00e9pondrons.", "messages", "fr");
t.add("helpScout.emailHeading", "Le chat d'aujourd'hui est avec", "messages", "fr");
t.add("helpScout.emailGreeting", "Bonjour", "messages", "fr");
t.add("helpScout.emailCopyOfDiscussion", "Voici une copie de la conversation", "messages", "fr");
t.add("helpScout.emailContinueConversation", "Si tu as d'autres questions, r\u00e9ponds et poursuis la conversation.", "messages", "fr");
t.add("helpScout.emailJoinedLineItem", " a rejoint le chat", "messages", "fr");
t.add("helpScout.emailEndedLineItem", " a termin\u00e9 le chat", "messages", "fr");
t.add("helpScout.emailYou", "Tu", "messages", "fr");
t.add("Audit entry item class", "", "admin", "fr");
t.add("Audit entry item id", "", "admin", "fr");
t.add("Select audit entry item class", "", "admin", "fr");
t.add("affairs.followingHaveBeenUpdated", "Les dossiers suivants ont \u00e9t\u00e9 mis \u00e0 jour", "email", "fr");
t.add("national.affairs.followingHaveBeenUpdated", "Les dossiers nationaux suivants ont \u00e9t\u00e9 mis \u00e0 jour", "email", "fr");
t.add("newAffairs.subject", "politique.ch - Mises \u00e0 jour dossiers cantonaux", "email", "fr");
t.add("notification.openingGreeting", "Bonjour", "email", "fr");
t.add("votes.followingHaveBeenAdded", "Les votes suivants ont \u00e9t\u00e9 ajout\u00e9s sur politique.ch", "email", "fr");
t.add("votes.followingHaveBeenUpdated", "Les votes suivants ont \u00e9t\u00e9 mis \u00e0 jour sur politique.ch", "email", "fr");
t.add("votes.votedOn", "vot\u00e9 le", "email", "fr");
t.add("readMoreOn", "Lisez plus sur", "email", "fr");
t.add("affairs.followingHaveBeenAdded", "Les dossiers suivants ont \u00e9t\u00e9 ajout\u00e9s sur politique.ch", "email", "fr");
t.add("affairs.cantonal.itemAdded", "Canton %abbr%: %title% (%type%)", "email", "fr");
t.add("national.affairs.followingHaveBeenAdded", "Les dossiers suivants ont \u00e9t\u00e9 ajout\u00e9s sur politique.ch", "email", "fr");
t.add("national.affairs.itemAdded", "%idParlService%: %title% (%type%)", "email", "fr");
t.add("affairGroups.followingHaveBeenAdded", "Les groupes suivants ont \u00e9t\u00e9 ajout\u00e9s sur politique.ch", "email", "fr");
t.add("affairGroups.followingHaveBeenUpdated", "Les groupes suivants ont \u00e9t\u00e9 mis \u00e0 jour", "email", "fr");
t.add("affairGroups.itemAdded", "%title%", "email", "fr");
t.add("national.affairs.new.subject", "politique.ch - Mises \u00e0 jour dossiers nationaux", "email", "fr");
t.add("national.affairConsultations.followingHaveBeenAdded", "Les consultations nationales suivantes ont \u00e9t\u00e9 ajout\u00e9es", "email", "fr");
t.add("national.affairConsultations.followingHaveBeenUpdated", "Les consultations nationales suivantes ont \u00e9t\u00e9 mises \u00e0 jour", "email", "fr");
t.add("national.affairConsultations.itemAdded", "%title%", "email", "fr");
t.add("national.affairConsultations.new.subject", "politique.ch - Nouvelles consultations", "email", "fr");
t.add("national.votes.nationalcouncil.new.subject", "politique.ch - Nouveaux votes Conseil national", "email", "fr");
t.add("national.votes.councilstates.new.subject", "politique.ch - Nouveaux votes Conseil des Etats", "email", "fr");
t.add("eventReminders.heading", "Nous souhaitons vous rappeler les \u00e9v\u00e9nements suivants", "email", "fr");
t.add("eventReminders.new.subject", "politique.ch - Nouveaux rappels", "email", "fr");
t.add("eventReminders.link.label", "Rappel concernant %eventTitle% le %dateTime%", "email", "fr");
t.add("notification.operation.add", "ajout\u00e9", "email", "fr");
t.add("notification.operation.replace", "remplac\u00e9", "email", "fr");
t.add("notification.operation.remove", "supprim\u00e9", "email", "fr");
t.add("notification.author_full_name", "Auteur", "email", "fr");
t.add("notification.title_de", "Titre DE", "email", "fr");
t.add("notification.title_fr", "Titre FR", "email", "fr");
t.add("notification.title_it", "Titre IT", "email", "fr");
t.add("notification.description_de", "Description DE", "email", "fr");
t.add("notification.description_fr", "Description FR", "email", "fr");
t.add("notification.description_it", "Description IT", "email", "fr");
t.add("notification.reason_de", "D\u00e9veloppement DE", "email", "fr");
t.add("notification.reason_fr", "D\u00e9veloppement FR", "email", "fr");
t.add("notification.reason_it", "D\u00e9veloppement IT", "email", "fr");
t.add("notification.short_id_parl_service", "ID Services du Parlement", "email", "fr");
t.add("notification.affair_type", "Type", "email", "fr");
t.add("notification.priority_council", "Conseil", "email", "fr");
t.add("notification.affair_state", "Statut", "email", "fr");
t.add("notification.national_council_votes", "Vote Conseil national", "email", "fr");
t.add("notification.council_states_votes", "Vote Conseil des Etats", "email", "fr");
t.add("notification.history_events", "Ev\u00e9nement", "email", "fr");
t.add("notification.history_events.status", "Statut de l\u2019\u00e9v\u00e9nement", "email", "fr");
t.add("notification.history_events.council", "Ev\u00e9nement: Conseil", "email", "fr");
t.add("notification.history_events.status_text", "Texte de l\u2019\u00e9v\u00e9nement", "email", "fr");
t.add("notification.history_events.date_time", "Date\/heure de l\u2019\u00e9v\u00e9nement", "email", "fr");
t.add("notification.history_events.national_session", "Ev\u00e9nement: Session nationale", "email", "fr");
t.add("notification.history_events.national_committee", "Ev\u00e9nement: Commission nationale", "email", "fr");
t.add("notification.drafts", "Projet", "email", "fr");
t.add("notification.drafts.texts", "Texte du projet", "email", "fr");
t.add("notification.drafts.texts.value_de", "Texte du projet DE", "email", "fr");
t.add("notification.drafts.texts.value_fr", "Texte du projet FR", "email", "fr");
t.add("notification.drafts.texts.value_it", "Texte du projet IT", "email", "fr");
t.add("notification.drafts.related_departments", "Projet: D\u00e9partements responsables", "email", "fr");
t.add("notification.drafts.resolutions", "D\u00e9cision", "email", "fr");
t.add("notification.drafts.resolutions.date", "Date de la d\u00e9cision", "email", "fr");
t.add("notification.drafts.resolutions.resolution_text", "Texte de la d\u00e9cision", "email", "fr");
t.add("notification.drafts.resolutions.resolution_text.text_de", "Texte de la d\u00e9cision DE", "email", "fr");
t.add("notification.drafts.resolutions.resolution_text.text_fr", "Texte de la d\u00e9cision FR", "email", "fr");
t.add("notification.drafts.resolutions.resolution_text.text_it", "Texte de la d\u00e9cision IT", "email", "fr");
t.add("notification.drafts.pre_consultations", "Examen pr\u00e9alable du projet", "email", "fr");
t.add("notification.drafts.references", "R\u00e9f\u00e9rence du projet", "email", "fr");
t.add("notification.drafts.references.title_de", "R\u00e9f\u00e9rence et titre du projet DE", "email", "fr");
t.add("notification.drafts.references.title_fr", "R\u00e9f\u00e9rence et titre du projet DE", "email", "fr");
t.add("notification.drafts.references.title_it", "R\u00e9f\u00e9rence et titre du projet IT", "email", "fr");
t.add("notification.drafts.federal_council_proposal", "Projet Conseil f\u00e9d\u00e9ral", "email", "fr");
t.add("notification.drafts.federal_council_proposal.date", "Date du projet du Conseil f\u00e9d\u00e9ral", "email", "fr");
t.add("notification.drafts.federal_council_proposal.summary_de", "R\u00e9sum\u00e9 du projet du Conseil f\u00e9d\u00e9ral DE", "email", "fr");
t.add("notification.drafts.federal_council_proposal.summary_fr", "R\u00e9sum\u00e9 du projet du Conseil f\u00e9d\u00e9ral FR", "email", "fr");
t.add("notification.drafts.federal_council_proposal.summary_it", "R\u00e9sum\u00e9 du projet du Conseil f\u00e9d\u00e9ral IT", "email", "fr");
t.add("notification.drafts.federal_council_proposal.text_de", "Projet du Conseil f\u00e9d\u00e9ral DE", "email", "fr");
t.add("notification.drafts.federal_council_proposal.text_fr", "Projet du Conseil f\u00e9d\u00e9ral FR", "email", "fr");
t.add("notification.drafts.federal_council_proposal.text_it", "Projet du Conseil f\u00e9d\u00e9ral IT", "email", "fr");
t.add("notification.title", "Titre", "email", "fr");
t.add("notification.text", "Texte", "email", "fr");
t.add("notification.code", "Code", "email", "fr");
t.add("notification.internal_id", "ID interne", "email", "fr");
t.add("notification.status", "Statut de la consultation", "email", "fr");
t.add("notification.main_attachment", "Annexe", "email", "fr");
t.add("notification.attachments", "Autre annexe", "email", "fr");
t.add("notification.attachments.real_hash", "Contenu de l\u2019annexe", "email", "fr");
t.add("notification.authors", "Auteur", "email", "fr");
t.add("notification.history_events.cantonal_session_program", "Programme des sessions cantonales", "email", "fr");
t.add("notification.history_events.attachments", "Annexe \u00e0 l\u2019\u00e9v\u00e9nement", "email", "fr");
t.add("notification.history_events.attachments.real_hash", "Document \/ fichier", "email", "fr");
t.add("notification.affair_groups", "Affectation au groupe", "email", "fr");
t.add("notification.affair_groups.parentId", "Affectation au groupe sup\u00e9rieur", "email", "fr");
t.add("notification.children", "Affectation au groupe", "email", "fr");
t.add("notification.documents", "Document consultation", "email", "fr");
t.add("notification.opinions", "R\u00e9ponses consultation", "email", "fr");
t.add("notification.description", "Description consultation", "email", "fr");
t.add("notification.office", "Office consultation", "email", "fr");
t.add("notification.author", "Auteur consultation", "email", "fr");
t.add("notification.author.full_name", "Auteur consultation", "email", "fr");
t.add("notification.author.type", "Auteur type de consultation", "email", "fr");
t.add("notification.laws", "Consultation loi", "email", "fr");
t.add("notification.laws.law_id", "Consultation loi", "email", "fr");
t.add("notification.remarks", "Remarque consultation", "email", "fr");
t.add("notification.affair_groups.parent", "Affectation au groupe sup\u00e9rieur", "email", "fr");
t.add("2fa.authCode.subject", "Votre code d\u2019autorisation pour politique.ch", "email", "fr");
t.add("notification.custom_links", "Dossier li\u00e9", "email", "fr");
t.add("notification.tableOfContents.cantonalAffairConsultations.new", "Nouvelle consultation cantonale", "email", "fr");
t.add("notification.tableOfContents.cantonalAffairConsultations.updated", "Consultation cantonale actualis\u00e9e", "email", "fr");
t.add("affairConsultations.cantonal.itemAdded", "Canton %abbr%: %title%", "email", "fr");
t.add("notification.links", "notification.links", "email", "fr");
t.add("notification.tableOfContents.cantonalAffairs.new", "Nouveaux dossiers cantonaux", "email", "fr");
t.add("notification.tableOfContents.cantonalAffairs.updated", "Dossiers cantonaux mis \u00e0 jour", "email", "fr");
t.add("notification.tableOfContents.nationalAffairs.new", "Nouveaux dossiers nationaux", "email", "fr");
t.add("notification.tableOfContents.nationalAffairs.updated", "Dossiers nationaux mis \u00e0 jour", "email", "fr");
t.add("notification.tableOfContents.nationalAffairConsultations.new", "Nouvelles consultations nationales", "email", "fr");
t.add("notification.tableOfContents.nationalAffairConsultations.updated", "Consultations nationales mises \u00e0 jour", "email", "fr");
t.add("notification.tableOfContents.affairGroups.new", "Nouveaux groupes", "email", "fr");
t.add("notification.tableOfContents.affairGroups.updated", "Groupes mis \u00e0 jour", "email", "fr");
t.add("notification.followingThemes", "Nous vous informons volontiers sur les th\u00e8mes suivants", "email", "fr");
t.add("national.affairs.itemAddedLink", "%idParlService%", "email", "fr");
t.add("national.affairs.itemAddedTitle", ": %title% (%type%)", "email", "fr");
t.add("resetting.email.subject", "R\u00e9initialiser le mot de passe", "email", "fr");
t.add("resetting.email.message", "Bonjour %username%,\n\nVeuillez vous rendre sur la page suivante pour r\u00e9initialiser votre mot de passe: %confirmationUrl%\n\nMeilleures salutations,\npolitique.ch", "email", "fr");
t.add("affairs.custom.itemAdded", "%title%", "email", "fr");
t.add("notification.tableOfContents.eventReminders.new", "Rappels", "email", "fr");
t.add("notification.tableOfContents.nationalVotes.new", "Parlement national: votes", "email", "fr");
t.add("notification.national_council_debates", "D\u00e9bat Conseil national", "email", "fr");
t.add("notification.council_states_debates", "D\u00e9bat Conseil des Etats", "email", "fr");
t.add("notification.assignedAffairs.itemAdded", "L'utilisateur %assignedUser% a \u00e9t\u00e9 ajout\u00e9 au dossier %affair%.", "email", "fr");
t.add("notification.tableOfContents.assignedAffairs.new", "Nouveaux dossiers attribu\u00e9s", "email", "fr");
t.add("notification.tableOfContents.assignedAffairs.updated", "Dossiers attribu\u00e9s mis \u00e0 jour", "email", "fr");
t.add("national.committeePressReleases.itemAdded", "%title%", "email", "fr");
t.add("notification.tableOfContents.nationalCommitteePressReleases.new", "Nouvelles communiqu\u00e9s de presse Commissions", "email", "fr");
t.add("notification.tableOfContents.nationalCommitteePressReleases.updated", "Communiqu\u00e9s de presse actualis\u00e9s Commissions", "email", "fr");
t.add("notification.tableOfContents.nationalAffairsDepositTexts.new", "Dossiers nationaux avec nouveau texte d\u00e9pos\u00e9", "email", "fr");
t.add("notification.tableOfContents.cantonalMediaRelease.new", "Nouveau communiqu\u00e9 de presse publi\u00e9", "email", "fr");
t.add("notification.tableOfContents.cantonalMediaRelease.updated", "Communiqu\u00e9 de presse actualis\u00e9", "email", "fr");
t.add("groups.form.title.cannotBeEmpty", "Le titre ne peut pas \u00eatre vide", "javascript", "fr");
t.add("affair.internalNote.error", "Erreur description", "javascript", "fr");
t.add("modal.error.title", "Une erreur s\u2019est produite", "javascript", "fr");
t.add("modal.error.message", "Une erreur vient de se produire, et nous vous prions de nous en excuser. Nos d\u00e9veloppeurs en ont \u00e9t\u00e9 inform\u00e9s. Veuillez r\u00e9essayer cette fonctionnalit\u00e9 plus tard ou vous adresser directement \u00e0 notre service d\u2019assistance: info@politik.ch ou 043 443 02 06.", "javascript", "fr");
t.add("affairs.error.message", "Erreur lors du chargement des dossiers", "javascript", "fr");
t.add("groups.error.message", "Erreur lors du chargement des groupes", "javascript", "fr");
t.add("groups.delete.message", "Etes-vous s\u00fbr de vouloir supprimer le groupe?", "javascript", "fr");
t.add("groups.delete.confirm", "Supprimer", "javascript", "fr");
t.add("groups.delete.cancel", "Annuler", "javascript", "fr");
t.add("ranking.filter.groups.tooltip", "Seuls les groupes contenant des dossiers compris dans la s\u00e9lection de base sont affich\u00e9s.", "javascript", "fr");
t.add("affair.internalNote.saved", "Enregistr\u00e9", "javascript", "fr");
t.add("datatables.language.emptyTable", "Le tableau ne contient aucune donn\u00e9e", "javascript", "fr");
t.add("datatables.language.info", "_START_ \u00e0 _END_ sur _TOTAL_ entr\u00e9es", "javascript", "fr");
t.add("datatables.language.infoEmpty", "Aucune donn\u00e9e existante", "javascript", "fr");
t.add("datatables.language.infoFiltered", "(filtr\u00e9 \u00e0 partir de _MAX_ entr\u00e9es)", "javascript", "fr");
t.add("datatables.language.lengthMenu", "_MENU_ Afficher les entr\u00e9es", "javascript", "fr");
t.add("datatables.language.loadingRecords", "Chargement en cours...", "javascript", "fr");
t.add("datatables.language.processing", "Veuillez patienter...", "javascript", "fr");
t.add("datatables.language.search", "Rechercher", "javascript", "fr");
t.add("datatables.language.zeroRecords", "Aucune entr\u00e9e existante", "javascript", "fr");
t.add("datatables.language.paginate.first", "Premi\u00e8re", "javascript", "fr");
t.add("datatables.language.paginate.last", "Derni\u00e8re", "javascript", "fr");
t.add("datatables.language.paginate.next", "Suivante", "javascript", "fr");
t.add("datatables.language.paginate.previous", "Pr\u00e9c\u00e9dente", "javascript", "fr");
t.add("datatables.language.aria.", ": activer pour trier la colonne dans l\u2019ordre d\u00e9croissant", "javascript", "fr");
t.add("charts.men", "Homme", "javascript", "fr");
t.add("charts.women", "Femme", "javascript", "fr");
t.add("charts.gender.title", "Sexe", "javascript", "fr");
t.add("layout.logout", "Se d\u00e9connecter", "user", "fr");
t.add("profile.edit.submit", "Mettre \u00e0 jour l\u2019utilisateur", "user", "fr");
t.add("security.login.email", "E-mail", "user", "fr");
t.add("security.login.password", "Mot de passe", "user", "fr");
t.add("security.login.forgotpassword", "Mot de passe oubli\u00e9?", "user", "fr");
t.add("security.login.remember_me", "Se souvenir de moi", "user", "fr");
t.add("resetting.reset.submit", "Modifier le mot de passe", "user", "fr");
t.add("resetting.request.username", "Adresse e-mail", "user", "fr");
t.add("resetting.request.submit", "R\u00e9initialiser le mot de passe", "user", "fr");
t.add("change_password.submit", "Modifier le mot de passe", "user", "fr");
})(Translator);
