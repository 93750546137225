(function (t) {
// de
t.add("vich_uploader.form_label.delete_confirm", "L\u00f6schen?", "messages", "de");
t.add("vich_uploader.link.download", "herunterladen", "messages", "de");
t.add("councillor.language.de", "Deutsch", "messages", "de");
t.add("councillor.language.en", "Englisch", "messages", "de");
t.add("councillor.language.fr", "Franz\u00f6sisch", "messages", "de");
t.add("councillor.language.it", "Italienisch", "messages", "de");
t.add("councillor.activeTrue", "im Amt", "messages", "de");
t.add("councillor.activeFalse", "ehemaliges Parlamentsmitglied", "messages", "de");
t.add("councillor.birthDate", "Geburtsdatum", "messages", "de");
t.add("councillor.professions", "Beruf", "messages", "de");
t.add("councillor.language", "Sprache", "messages", "de");
t.add("councillor.votes.absent", "Abwesenheiten", "messages", "de");
t.add("councillor.votes.abstain", "Enthaltungen", "messages", "de");
t.add("councillor.recentAffairs", "Eingereichte Vorst\u00f6sse von %firstName% %lastName%", "messages", "de");
t.add("affair.depositDate", "Einreichungsdatum", "messages", "de");
t.add("councillor.affairs", "Vorst\u00f6sse", "messages", "de");
t.add("councillor.affairTypes", "Vorstoss-Arten", "messages", "de");
t.add("councillor.aTotalOfNAffairs", "Insgesamt %count% Vorst\u00f6sse", "messages", "de");
t.add("councillor.topics", "Themen der Vorst\u00f6sse", "messages", "de");
t.add("councillor.affairs.success", "Erfolg der Vorst\u00f6sse", "messages", "de");
t.add("councillor.absences", "Absenzen", "messages", "de");
t.add("councillor.absences.absentOn", "Nicht-Teilnahme %count% von %total% Abstimmungen", "messages", "de");
t.add("councillor.absences.absent", "Nicht-Teilnahme", "messages", "de");
t.add("councillor.abstentions", "Enthaltungen", "messages", "de");
t.add("councillor.abstentions.abstainedOn", "Enthaltungen %count% von %total% Abstimmungen", "messages", "de");
t.add("councillor.abstentions.abstained", "Enthaltungen", "messages", "de");
t.add("layout.source", "Quelle", "messages", "de");
t.add("layout.source.sentence", "Parlamentsdienste der Bundesversammlung, Bern", "messages", "de");
t.add("layout.lastUpdate", "Letzte Aktualisierung", "messages", "de");
t.add("profile.settings.save", "Speichern", "messages", "de");
t.add("factions.title", "Fraktionen", "messages", "de");
t.add("factions.nationalCouncil.countMembers", "Nationalrat|Nationalr\u00e4te", "messages", "de");
t.add("factions.councilOfStates.countMembers", "St\u00e4nderat|St\u00e4nder\u00e4te", "messages", "de");
t.add("cantons.title", "Kantone", "messages", "de");
t.add("cantons.nationalCouncil.countMembers", "Nationalrat|Nationalr\u00e4te", "messages", "de");
t.add("cantons.councilOfStates.countMembers", "St\u00e4nderat|St\u00e4nder\u00e4te", "messages", "de");
t.add("layout.account", "Benutzerkonto", "messages", "de");
t.add("layout.userdashboard", "Dashboard", "messages", "de");
t.add("layout.admindashboard", "Admin Dashboard", "messages", "de");
t.add("layout.admindashboard.shortcut", "AD", "messages", "de");
t.add("parties.title", "Parteien", "messages", "de");
t.add("parties.nationalCouncil.countMembers", "Nationalrat|Nationalr\u00e4te", "messages", "de");
t.add("parties.councilOfStates.countMembers", "St\u00e4nderat|St\u00e4nder\u00e4te", "messages", "de");
t.add("cantonal.listCouncillors.title", "Parlamentarier Kanton %canton%", "messages", "de");
t.add("affair.status", "Status Gesch\u00e4ft", "messages", "de");
t.add("affair.genericAttachments", "Weitere Anh\u00e4nge", "messages", "de");
t.add("affair.genericAttachments.download", "Anhang #%number% herunterladen", "messages", "de");
t.add("affair.originalSource", "Originaldokument", "messages", "de");
t.add("cantonal.affairs", "Kantonale Gesch\u00e4fte", "messages", "de");
t.add("cantonal.overview.affairs", "Gesch\u00e4fte", "messages", "de");
t.add("cantonal.overview.councillors", "Parlamentarier", "messages", "de");
t.add("cantonal.listAffairs.title", "Gesch\u00e4fte Kanton %canton%", "messages", "de");
t.add("cantons.nationalcouncil.members", "Nationalr\u00e4te", "messages", "de");
t.add("cantons.councilofstates.members", "St\u00e4nder\u00e4te", "messages", "de");
t.add("layout.menu.nationalcouncil", "Nationalrat", "messages", "de");
t.add("layout.menu.nationalcouncil.votes", "Abstimmungen NR", "messages", "de");
t.add("layout.menu.nationalcouncil.votes.shortcut", "A", "messages", "de");
t.add("layout.menu.nationalcouncil.programs", "Sessionsprogramm NR", "messages", "de");
t.add("layout.menu.nationalcouncil.programs.shortcut", "S", "messages", "de");
t.add("layout.menu.nationalcouncil.rankings", "Rankings NR", "messages", "de");
t.add("layout.menu.nationalcouncil.rankings.shortcut", "R", "messages", "de");
t.add("layout.menu.nationalcouncil.rankings.factions", "Koalitionen: Welche Mehrheiten sind erfolgreich?", "messages", "de");
t.add("layout.menu.nationalcouncil.rankings.councillors", "Abstimmungs-Ranking Parlamentarier", "messages", "de");
t.add("layout.menu.nationalcouncil.rankings.votes", "Abstimmungs-Ranking: Knappe Abstimmungen", "messages", "de");
t.add("layout.menu.councilofstates", "St\u00e4nderat", "messages", "de");
t.add("layout.menu.councilofstates.votes", "Abstimmungen SR", "messages", "de");
t.add("layout.menu.councilofstates.votes.shortcut", "A", "messages", "de");
t.add("layout.menu.councilofstates.programs", "Sessionsprogramm SR", "messages", "de");
t.add("layout.menu.councilofstates.programs.shortcut", "S", "messages", "de");
t.add("layout.menu.councilofstates.rankings", "Rankings SR", "messages", "de");
t.add("layout.menu.councilofstates.rankings.shortcut", "R", "messages", "de");
t.add("layout.menu.councilstates.rankings.councillors", "Abstimmungs-Ranking Parlamentarier", "messages", "de");
t.add("layout.menu.councilstates.rankings.votes", "Abstimmungs-Ranking: Knappe Abstimmungen", "messages", "de");
t.add("layout.menu.councillors", "Parlamentarier", "messages", "de");
t.add("layout.menu.cantonalAffairs", "Kantone", "messages", "de");
t.add("layout.search.query", "Suchbegriff", "messages", "de");
t.add("layout.search.submit", "Suchen", "messages", "de");
t.add("parties.nationalcouncil.members", "Parteimitglieder Nationalrat", "messages", "de");
t.add("parties.councilofstates.members", "Parteimitglieder St\u00e4nderat", "messages", "de");
t.add("factions.nationalcouncil.members", "Fraktionsmitglieder Nationalrat", "messages", "de");
t.add("factions.councilofstates.members", "Fraktionsmitglieder St\u00e4nderat", "messages", "de");
t.add("vote.decision.yes", "Ja", "messages", "de");
t.add("vote.decision.no", "Nein", "messages", "de");
t.add("sessions.nocouncil.title", "Abstimmungen", "messages", "de");
t.add("form.session.label", "%title% (%from% bis %to%)", "messages", "de");
t.add("vote.session_stats", "Sessions-Statistik", "messages", "de");
t.add("vote.new_councillors", "Neu vereidigte Parlamentarier", "messages", "de");
t.add("session.dayFilter.all", "Alle", "messages", "de");
t.add("session.novotes", "In dieser Session gibt es keine Abstimmungen in diesem Rat.", "messages", "de");
t.add("session.nohistoryEvents", "Keine Ereignisse", "messages", "de");
t.add("form.firstName", "Vorname", "messages", "de");
t.add("form.lastName", "Nachname", "messages", "de");
t.add("form.company", "Firma", "messages", "de");
t.add("layout.search.contentType.national_affair", "Suche national", "messages", "de");
t.add("layout.search.contentType.cantonal_affair", "Gesch\u00e4fte kantonal", "messages", "de");
t.add("layout.search.type", "Suche national \/ kantonal", "messages", "de");
t.add("profile.edit.firstName", "Vorname", "messages", "de");
t.add("profile.edit.lastName", "Nachname", "messages", "de");
t.add("profile.edit.pictureFile", "Profil-Bild", "messages", "de");
t.add("profile.settings.notificationPreference.none", "Keine", "messages", "de");
t.add("profile.settings.notificationPreference.keywords", "Suchbegriffe", "messages", "de");
t.add("profile.settings.notificationPreference.all", "Alle Benachrichtigungen", "messages", "de");
t.add("profile.settings.notificationPreference.yes", "Ja", "messages", "de");
t.add("profile.settings.notificationPreference.no", "Nein", "messages", "de");
t.add("ranking.narrowvotes", "Knappe Abstimmungen", "messages", "de");
t.add("ranking.absent", "Nicht-Teilnahme an Abstimmungen", "messages", "de");
t.add("ranking.coalitions", "Koalitionen: Welche Mehrheiten sind erfolgreich?", "messages", "de");
t.add("ranking.voteagainst", "Gegen eigene Fraktion (Abweichler-Ranking)", "messages", "de");
t.add("ranking.age", "Jahrgang (Alter)", "messages", "de");
t.add("ranking.membership", "Amtsdauer (im aktuellen Rat)", "messages", "de");
t.add("ranking.abstain", "Enthaltungen bei Abstimmungen", "messages", "de");
t.add("vote.decision.eh", "Enthalten", "messages", "de");
t.add("vote.decision.nt", "Abwesend", "messages", "de");
t.add("vote.decision.es", "Entschuldigt", "messages", "de");
t.add("vote.decision.p", "Pr\u00e4sident", "messages", "de");
t.add("global.dates.format", "d.m.Y", "messages", "de");
t.add("global.dates.dayMonthFormat", "d.m.", "messages", "de");
t.add("global.dates.dateTimeFormat", "d.m.Y H:i", "messages", "de");
t.add("councillor.gender.m", "M\u00e4nner", "messages", "de");
t.add("councillor.gender.f", "Frauen", "messages", "de");
t.add("layout.login.error.duplicated", "Ein anderes Ger\u00e4t hat sich mit Ihrem Benutzernamen und Ihrem Passwort angemeldet. Um sich erneut\n                    anzumelden, geben Sie bitte Ihre Zugangsdaten unten ein. Bitte beachten Sie, dass das andere Ger\u00e4t\n                    abgemeldet wird.", "messages", "de");
t.add("session.stats.totalVotes", "Total Abstimmungen", "messages", "de");
t.add("session.stats.finalGovernmentVotes", "Schlussabstimmungen", "messages", "de");
t.add("councillor.affairSuccess.noVotes", "Noch nicht behandelt", "messages", "de");
t.add("councillor.affairSuccess.positiveVotes", "Erfolgreich", "messages", "de");
t.add("councillor.affairSuccess.negativeVotes", "Kein Erfolg", "messages", "de");
t.add("profile.settings.save.success", "Erfolgreich gespeichert", "messages", "de");
t.add("affair.submittedBy", "Eingereicht von %name% am %date%", "messages", "de");
t.add("affair.description", "Eingereichter Text", "messages", "de");
t.add("affair.reason", "Begr\u00fcndung", "messages", "de");
t.add("affair.nationalCouncilDebate.showmore", "Mehr anzeigen", "messages", "de");
t.add("affair.noVotes", "Keine Abstimmungen vorhanden.", "messages", "de");
t.add("affair.councilOfStatesVote", "Abstimmung im St\u00e4nderat", "messages", "de");
t.add("affair.nationalCouncilVote", "Abstimmung im Nationalrat", "messages", "de");
t.add("affair.nationalCouncilDebate", "Debatte im Nationalrat", "messages", "de");
t.add("affair.nationalCouncilDebate.showless", "Weniger anzeigen", "messages", "de");
t.add("affairs.nationalCouncilDebate.otherAffairs", "Weitere Gesch\u00e4fte", "messages", "de");
t.add("affair.nationalCouncilDebate.speeches", "Reden", "messages", "de");
t.add("affair.nationalCouncilDebate.speechesByCouncillor", "Reden nach Parlamentarier", "messages", "de");
t.add("vote.date", "Datum Abstimmung", "messages", "de");
t.add("vote.submission", "Vorlage", "messages", "de");
t.add("vote.division", "Gegenstand", "messages", "de");
t.add("vote.meaning.yes", "Bedeutung Ja", "messages", "de");
t.add("vote.meaning.no", "Bedeutung Nein", "messages", "de");
t.add("affair.filters", "Filter", "messages", "de");
t.add("affair.filterby", "Filtern nach...", "messages", "de");
t.add("affair.filter.all", "S\u00e4mtliche Resultate", "messages", "de");
t.add("affair.filter.factions", "Fraktionen", "messages", "de");
t.add("affair.filter.cantons", "Kantone", "messages", "de");
t.add("affair.filter.dissenters", "gegen eigene Fraktion (Abweichler)", "messages", "de");
t.add("affair.filter.language", "Sprache", "messages", "de");
t.add("affair.filter.gender", "Geschlecht", "messages", "de");
t.add("affair.results.all", "Alle Resultate", "messages", "de");
t.add("affair.results.byfactions", "Resultat nach Fraktion", "messages", "de");
t.add("affair.results.faction", "Fraktion", "messages", "de");
t.add("vote.decision.abstained", "EH", "messages", "de");
t.add("vote.decision.abs_exc", "ES\/NT", "messages", "de");
t.add("vote.decision.total", "Total", "messages", "de");
t.add("vote.allCouncillors", "Alle", "messages", "de");
t.add("affair.results.bycantons", "Resultat nach Kanton", "messages", "de");
t.add("affair.results.canton", "Kanton", "messages", "de");
t.add("affair.results.bydissenters", "Resultat nach Abweichler", "messages", "de");
t.add("affair.results.bylanguage", "Resultat nach Sprache", "messages", "de");
t.add("affair.results.language", "Sprache", "messages", "de");
t.add("affair.results.bygender", "Resultat nach Geschlecht", "messages", "de");
t.add("affair.results.gender", "Geschlecht", "messages", "de");
t.add("global.filter.placeholder", "Ausgabe filtern...", "messages", "de");
t.add("affair.councillor.name", "Name", "messages", "de");
t.add("affair.councillor.party", "Partei", "messages", "de");
t.add("affair.councillor.vote", "Stimme", "messages", "de");
t.add("affair.councillor.canton", "Kanton", "messages", "de");
t.add("vote.compact.yes", "Ja", "messages", "de");
t.add("vote.compact.no", "Nein", "messages", "de");
t.add("vote.compact.abstained", "Enthalten", "messages", "de");
t.add("vote.compact.notPartecipating", "nicht teilgenommen", "messages", "de");
t.add("affair.results.detailedView", "Detailansicht", "messages", "de");
t.add("affair.results.compactView", "Kompaktansicht", "messages", "de");
t.add("rankings.stats", "%affairs% Gesch\u00e4fte, %votes% Abstimmungen", "messages", "de");
t.add("ranking.vacantSeat", "Vakanter Sitz von", "messages", "de");
t.add("ranking.membership.fromto", "Parlamentarier seit %from% bis %to%", "messages", "de");
t.add("ranking.membership.from", "Parlamentarier seit %from%", "messages", "de");
t.add("ranking.membership.yearAndDays", "%years% Jahre und %days% Tage", "messages", "de");
t.add("rankings.title", "Rankings Abstimmungen %council%", "messages", "de");
t.add("form.submit", "Anzeigen", "messages", "de");
t.add("ranking.abstain.in", "Enthalten bei %hits% von %total% Abstimmungen", "messages", "de");
t.add("ranking.absent.percentage", "Nicht-Teilnahme", "messages", "de");
t.add("ranking.absent.in", "Nicht-Teilnahme: %hits% von %total% Abstimmungen", "messages", "de");
t.add("layout.loader.error", "Error loading data", "messages", "de");
t.add("ranking.age.yearAndDays", "%years% Jahre und %days% Tage", "messages", "de");
t.add("ranking.narrowvotes.difference", "Differenz", "messages", "de");
t.add("ranking.voteagainst.in", "%hits% von %total% gegen eigene Fraktion", "messages", "de");
t.add("ranking.voteagainst.percentage", "%hits%% abweichend", "messages", "de");
t.add("ranking.filter.submit", "Ausgabe filtern", "messages", "de");
t.add("profile.dashboard", "Dashboard", "messages", "de");
t.add("profile.dashboard.sessions", "Abstimmungen", "messages", "de");
t.add("profile.dashboard.parties", "Parteien: Parlamentarier", "messages", "de");
t.add("profile.dashboard.rankings.councillors", "Auswertung: Parlamentarier", "messages", "de");
t.add("profile.dashboard.factions", "Fraktionen: Parlamentarier", "messages", "de");
t.add("profile.dashboard.rankings.factions", "Auswertung: Fraktionen", "messages", "de");
t.add("profile.dashboard.cantons", "Kantone: Parlamentarier", "messages", "de");
t.add("profile.dashboard.recentVotes", "Aktuellste Abstimmungen NR", "messages", "de");
t.add("rankings.form.onlyfinalvotes", "Welche Abstimmungen?", "messages", "de");
t.add("rankings.form.filter.on", "An", "messages", "de");
t.add("rankings.form.filter.off", "Aus", "messages", "de");
t.add("rankings.form.legislativeperiod", "Legislaturperiode", "messages", "de");
t.add("rankings.form.session", "Session", "messages", "de");
t.add("rankings.filter.faction.label", "Filtern nach Fraktion", "messages", "de");
t.add("rankings.filter.affairtype.label", "Filtern nach Gesch\u00e4ftstyp", "messages", "de");
t.add("rankings.filter.topic.label", "Thema", "messages", "de");
t.add("rankings.form.ranking", "Ranking", "messages", "de");
t.add("profile.show.username", "Benutzername", "messages", "de");
t.add("profile.show.email", "E-Mail", "messages", "de");
t.add("profile.show.firstName", "Vorname", "messages", "de");
t.add("profile.show.lastName", "Nachname", "messages", "de");
t.add("profile.show.edit", "Bearbeiten", "messages", "de");
t.add("layout.login", "Anmelden", "messages", "de");
t.add("security.forgotpassword", "Passwort vergessen?", "messages", "de");
t.add("profile.settings.keywords", "Suchbegriffe", "messages", "de");
t.add("results.results", "Resultate", "messages", "de");
t.add("results.noResultsFound", "Keine Resultate gefunden", "messages", "de");
t.add("profile.settings.navigation.profile", "Profil", "messages", "de");
t.add("profile.settings.navigation.password", "Passwort", "messages", "de");
t.add("profile.settings.navigation.notificationPreferences", "Suchbegriffe", "messages", "de");
t.add("profile.settings.defaultNotificationPreference", "Standard-Einstellung", "messages", "de");
t.add("profile.settings.nationalNotificationPreferences", "Abweichende Einstellungen Bund", "messages", "de");
t.add("profile.settings.cantonalNotificationPreferences", "Abweichende Einstellungen Kantone", "messages", "de");
t.add("layout.menu.councilstates.rankings.factions", "Koalitionen: Welche Mehrheiten sind erfolgreich?", "messages", "de");
t.add("cantonal.listCouncillors.deputies.title", "Parlamentarier-Stellvertreter", "messages", "de");
t.add("councillor.role.deputy", "Stellvertreter", "messages", "de");
t.add("layout.search.contentType.all", "Gesch\u00e4fte und Parlamentarier", "messages", "de");
t.add("layout.search.contentType.affair", "Nur Gesch\u00e4fte", "messages", "de");
t.add("layout.search.contentType.councillor", "Nur Parlamentarier", "messages", "de");
t.add("layout.search.contentType", "Suchen nach", "messages", "de");
t.add("layout.search.affairTypeNational", "Gesch\u00e4ftsart (national)", "messages", "de");
t.add("layout.search.affairTypeCantonal", "Gesch\u00e4ftsart (kantonal)", "messages", "de");
t.add("layout.search.canton", "Kanton", "messages", "de");
t.add("layout.search.affairTypeNational.all", "Alle", "messages", "de");
t.add("layout.search.affairTypeCantonal.all", "Alle", "messages", "de");
t.add("layout.search.canton.all", "Alle Kantone", "messages", "de");
t.add("profile.settings.notificationPreference.default", "Standard-Einstellung", "messages", "de");
t.add("ranking.narrowvotesguilty", "Knappe Abstimmungen verloren", "messages", "de");
t.add("rankings.filter.narrowVotesGuilty.limit.label", "Was sind knappe Abstimmungen? Stimmenunterschied:", "messages", "de");
t.add("ranking.narrowvotesguilty.in", "Mitverantwortlich in %hits% von %total% Abstimmungen", "messages", "de");
t.add("ranking.narrowvotesguilty.percentage", "%hits%% abweichend", "messages", "de");
t.add("ranking.narrowvotesguilty.abstain", "Stimme enthalten", "messages", "de");
t.add("ranking.narrowvotesguilty.absent", "Abwesend (Nicht-Teilnahme)", "messages", "de");
t.add("ranking.narrowvotesguilty.against", "Gegen eigene Fraktion", "messages", "de");
t.add("ranking.abstain.percentage", "Enhalten", "messages", "de");
t.add("profile.settings.baseSettings", "Generelle Einstellungen", "messages", "de");
t.add("global.filter.noResults", "Keine Ergebnisse gefunden", "messages", "de");
t.add("layout.account.shortcut", "K", "messages", "de");
t.add("profile.show.edit.shortcut", "BK", "messages", "de");
t.add("profile.settings.edit.shortcut", "S", "messages", "de");
t.add("profile.settings.edit", "Einstellungen", "messages", "de");
t.add("layout.logout.shortcut", "A", "messages", "de");
t.add("layout.search", "Suchbegriff", "messages", "de");
t.add("layout.menu.councillors.byCanton.shortcut", "K", "messages", "de");
t.add("layout.menu.councillors.byCanton", "nach Kantonen", "messages", "de");
t.add("layout.menu.councillors.byFaction.shortcut", "F", "messages", "de");
t.add("layout.menu.councillors.byFaction", "nach Fraktionen", "messages", "de");
t.add("layout.menu.councillors.byParty.shortcut", "P", "messages", "de");
t.add("layout.menu.councillors.byParty", "nach Parteien", "messages", "de");
t.add("rankings.stats.description", "Die Anzahl Abstimmungen bezieht sich auf den unten gew\u00e4hlten Stimmenunterschied.", "messages", "de");
t.add("sessionlist.session.dates", "%from% bis %to%", "messages", "de");
t.add("sessionlist.session.votes", "Abstimmungen", "messages", "de");
t.add("sessionlist.session.historyEvents", "Gesch\u00e4fte", "messages", "de");
t.add("layout.search.orderBy.none", "Bestes Ergebnis", "messages", "de");
t.add("layout.search.orderBy.deposit_date", "Einreichungsdatum", "messages", "de");
t.add("layout.search.orderBy.title", "Titel", "messages", "de");
t.add("layout.search.orderBy.type", "Typ", "messages", "de");
t.add("layout.search.orderBy.first_name", "Vorname", "messages", "de");
t.add("layout.search.orderBy.last_name", "Nachname", "messages", "de");
t.add("layout.search.orderDirection.ascending", "Aufsteigend", "messages", "de");
t.add("layout.search.orderDirection.descending", "Absteigend", "messages", "de");
t.add("layout.search.orderBy", "Sortieren nach", "messages", "de");
t.add("layout.search.orderDirection", "Sortier-Reihenfolge", "messages", "de");
t.add("councillor.affairSuccess.unknownVotes", "Wird nicht abgestimmt", "messages", "de");
t.add("rankings.cantonal.numberOfAffairs.title", "Anzahl Gesch\u00e4fte nach Kanton", "messages", "de");
t.add("layout.menu.cantonalRankings", "Rankings Kantone", "messages", "de");
t.add("layout.politicalcalendar", "Kalender", "messages", "de");
t.add("session.stats.finalPersonalVotes", "Vorst\u00f6sse", "messages", "de");
t.add("affair.councilStatesDebate.debate", "Debatte", "messages", "de");
t.add("affair.councilStatesDebate.speechesByCouncillor", "Voten Parlamentarier", "messages", "de");
t.add("affair.councilStatesDebate.speeches", "Voten", "messages", "de");
t.add("affair.submittedByOn", "Eingereicht von %councillors% am %date%", "messages", "de");
t.add("rankings.filter.orderDirection.label", "Reihenfolge", "messages", "de");
t.add("rankings.filter.orderDirection.descending", "Absteigend", "messages", "de");
t.add("rankings.filter.orderDirection.ascending", "Aufsteigend", "messages", "de");
t.add("rankings.form.filter.all", "Alle", "messages", "de");
t.add("rankings.form.filter.finalGovernment", "Nur Schlussabstimmungen", "messages", "de");
t.add("rankings.form.filter.finalPersonal", "Nur Vorst\u00f6sse", "messages", "de");
t.add("rankings.form.filter.finalAll", "Nur Schlussabstimmungen und Vorst\u00f6sse", "messages", "de");
t.add("affairGroups.assign.modal", "An Gruppe zuweisen", "messages", "de");
t.add("affairGroups.newGroup.modal", "Neue Gruppe erstellen", "messages", "de");
t.add("profile.affairGroups", "Gruppen", "messages", "de");
t.add("layout.affair_groups", "Gruppen", "messages", "de");
t.add("layout.affair_groups.shortcut", "G", "messages", "de");
t.add("layout.affairs_overview", "Gesch\u00e4fte", "messages", "de");
t.add("layout.menu.nationalcouncil.consultations", "Vernehmlassungen", "messages", "de");
t.add("affairGroups.newGroup.success", "Neue Gruppe erfolgreich erstellt", "messages", "de");
t.add("affairGroups.assign.select", "Gruppe zuweisen", "messages", "de");
t.add("affairGroups.assign.success", "Zuweisungen zu Gruppen erfolgreich aktualisiert.", "messages", "de");
t.add("affairGroups.assign.error", "Gruppe kann nicht zugewiesen werden", "messages", "de");
t.add("affairGroups.assign.placeholder", "-", "messages", "de");
t.add("affairGroups.assign.label", "Gruppe zuweisen", "messages", "de");
t.add("affairGroups.newGroup.title.label", "Name", "messages", "de");
t.add("affairGroups.newGroup.description.label", "Beschreibung", "messages", "de");
t.add("affairGroups.new.title", "Neue Gruppe erstellen", "messages", "de");
t.add("affairGroups.new.save", "Neue Gruppe speichern", "messages", "de");
t.add("affairGroups.affair.title", "Titel des Gesch\u00e4fts", "messages", "de");
t.add("affairGroups.assign.title", "Gesch\u00e4ft einer Gruppe zuweisen", "messages", "de");
t.add("affairGroups.assign.save", "Zuweisung speichern", "messages", "de");
t.add("affairGroups.newGroup.parent.placeholder", "(keine \u00fcbergeordnete Gruppe)", "messages", "de");
t.add("affairGroups.newGroup.parent.label", "\u00dcbergeordnete Gruppe", "messages", "de");
t.add("affairGroups.affair.existingGroups", "Bestehende Gruppen", "messages", "de");
t.add("profile.settings.nationalNotificationPreferences.affairs", "Gesch\u00e4fte", "messages", "de");
t.add("profile.settings.nationalNotificationPreferences.nationalCouncilVotes", "Abstimmungen Nationalrat", "messages", "de");
t.add("profile.settings.nationalNotificationPreferences.councilStatesVotes", "Abstimmungen St\u00e4nderat", "messages", "de");
t.add("affairGroups.edit.title", "Gruppe bearbeiten", "messages", "de");
t.add("affairGroups.edit.save", "Speichern", "messages", "de");
t.add("affairGroups.editGroup.tooltip", "Gruppe bearbeiten", "messages", "de");
t.add("affairGroups.assign.noGroups", "Keiner Gruppe zugewiesen", "messages", "de");
t.add("affairGroups.editGroup.success", "Gruppe erfolgreich gespeichert", "messages", "de");
t.add("affair.customEvents.add.button", "Notiz\/Ereignis hinzuf\u00fcgen", "messages", "de");
t.add("affair.customEvents.edit.title", "Ereignis bearbeiten", "messages", "de");
t.add("affair.customEvent.button.submit", "Speichern", "messages", "de");
t.add("affair.customEvents.add.title", "Ereignis hinzuf\u00fcgen", "messages", "de");
t.add("affair.customEvents.save.success", "Ereignis erfolgreich gespeichert", "messages", "de");
t.add("affairs.customEvents.fields.note.title", "Titel", "messages", "de");
t.add("affairs.customEvents.fields.note.abstract", "Zusammenfassung", "messages", "de");
t.add("affairs.customEvents.fields.note.outputInReports", "In Dokumenten (Exports, Berichte, Einladungen etc.) ausgeben", "messages", "de");
t.add("affairs.customEvents.fields.note.text", "Text", "messages", "de");
t.add("affairs.customEvents.fields.vote.caption", "Titel", "messages", "de");
t.add("affairs.customEvents.fields.vote.description", "Beschreibung", "messages", "de");
t.add("affairs.customEvents.fields.vote.yes", "Ja", "messages", "de");
t.add("affairs.customEvents.fields.vote.no", "Nein", "messages", "de");
t.add("affairs.customEvents.fields.date", "Datum", "messages", "de");
t.add("affairs.customEvents.success", "Erfolgreich gespeichert", "messages", "de");
t.add("affair.councilOfStatesVote.tooltip.caption", "Abstimmung St\u00e4nderat", "messages", "de");
t.add("affair.nationalCouncilVote.tooltip.caption", "Abstimmung Nationalrat", "messages", "de");
t.add("affair.nationalCouncilDebate.tooltip.caption", "Debatte Nationalrat", "messages", "de");
t.add("affair.customNote.tooltip.caption", "Notiz", "messages", "de");
t.add("affair.customInternalVote.tooltip.caption", "Interne Abstimmung", "messages", "de");
t.add("affairs.customEvents.note", "Notiz", "messages", "de");
t.add("affairs.customEvents.internalvote", "Interne Abstimmung", "messages", "de");
t.add("cantonal.historyevent.deposit.tooltip.caption", "Datum Einreichung", "messages", "de");
t.add("affair.authorCouncillor", "Eingereicht von", "messages", "de");
t.add("affairGroup.overview.noContent.title", "Keine Gesch\u00e4fte", "messages", "de");
t.add("affairGroup.overview.nationalEvents.title", "Nationale Gesch\u00e4fte", "messages", "de");
t.add("affairGroup.overview.cantonalEvents.title", "Kantonale Gesch\u00e4fte", "messages", "de");
t.add("affairGroup.overview.customAffairs.title", "Interne Gesch\u00e4fte", "messages", "de");
t.add("affairGroup.overview.children", "Untergruppen", "messages", "de");
t.add("affairGroup.description.empty", "Keine Beschreibung", "messages", "de");
t.add("affairGroups.overviewLink.tooltip", "Gruppen-\u00dcbersicht \u00f6ffnen", "messages", "de");
t.add("author.party.label", "Partei", "messages", "de");
t.add("author.faction.label", "Fraktion", "messages", "de");
t.add("author.commission.label", "Kommission", "messages", "de");
t.add("author.misc.label", "Urheber", "messages", "de");
t.add("councillor.affairSuccess.partialPositiveVotes", "Erfolgreich in einem Rat", "messages", "de");
t.add("affair.overview.nationalCouncilVote.title", "Abstimmung Nationalrat", "messages", "de");
t.add("affair.overview.internalVoteEvent.title", "Interne Abstimmung", "messages", "de");
t.add("affair.overview.noteEvent.title", "Notiz", "messages", "de");
t.add("affair.overview.nationalCouncilDebateEvent.title", "Debatte", "messages", "de");
t.add("affair.overview.councilStatesVote.title", "Abstimmung St\u00e4nderat", "messages", "de");
t.add("affair.overview.cantonal.depositEvent.title", "Eingereicht", "messages", "de");
t.add("affair.overview.events.draft", "Entwurf-Detail", "messages", "de");
t.add("ranking.councillorscompare", "Vergleich Parlamentarier", "messages", "de");
t.add("ranking.councillorscompare.noVotes", "Keine Unterschiede \/ Abstimmungen", "messages", "de");
t.add("layout.menu.nationalcouncil.rankings.councillorscompare", "Vergleich Nationalr\u00e4te", "messages", "de");
t.add("layout.menu.councilstates.rankings.councillorscompare", "Vergleich St\u00e4nder\u00e4te", "messages", "de");
t.add("rankings.form.councillorsCompare.councillor1", "Parlamentarier 1", "messages", "de");
t.add("rankings.form.councillorsCompare.councillor2", "Parlamentarier 2", "messages", "de");
t.add("ranking.councillorscompare.topicsStats.title", "Themen der Abstimmungen mit unterschiedlicher Meinung", "messages", "de");
t.add("ranking.councillorscompare.votesAndAffairs", "%affairs% Gesch\u00e4fte, %votes% Abstimmungen", "messages", "de");
t.add("ranking.councillorscompare.overview.title", "Vergleich Parlamentarier", "messages", "de");
t.add("ranking.councillorscompare.description", "Abstimmungen, bei denen die zwei Parlamentarier unterschiedlich gestimmt haben: Die Grafik\n                    zeigt, wer bei diesen Abstimmungen Ja, Nein gestimmt oder sich enthalten hat.", "messages", "de");
t.add("ranking.councillorscompare.howmany.yes", "%count% Ja", "messages", "de");
t.add("ranking.councillorscompare.howmany.no", "%count% Nein", "messages", "de");
t.add("ranking.councillorscompare.howmany.eh", "%count% Enthalten", "messages", "de");
t.add("ranking.councillorscompare.age", "Alter", "messages", "de");
t.add("ranking.councillorscompare.yearsInCouncil", "Amtsdauer im aktuellen Rat", "messages", "de");
t.add("ranking.councillorscompare.absentRate", "Abwesenheiten aktuelle Legislatur", "messages", "de");
t.add("ranking.councillorscompare.abstainRate", "Enthaltungen aktuelle Legislatur", "messages", "de");
t.add("ranking.councillorscompare.affairsCount", "Anzahl Vorst\u00f6sse aktuelle Legislatur", "messages", "de");
t.add("national.affair.overview.title", "Nationales Gesch\u00e4ft", "messages", "de");
t.add("sidebar.toggle.collapse", "Zuklappen", "messages", "de");
t.add("sidebar.toggle.expand", "Aufklappen", "messages", "de");
t.add("cantonal.affair.overview.title", "Kantonales Gesch\u00e4ft", "messages", "de");
t.add("councillor.faction", "Fraktion", "messages", "de");
t.add("councillor.party", "Partei", "messages", "de");
t.add("councillor.commissionMemberships.current", "Aktuelle Mitgliedschaften Kommissionen", "messages", "de");
t.add("councillor.commissionMemberships.past", "Vergangene Mitgliedschaften Kommissionen", "messages", "de");
t.add("ranking.cantonal.councillorsAffairs", "Kantonale Gesch\u00e4fte nach Parlamentarier", "messages", "de");
t.add("rankings.cantonal.numberOfAffairs.stats", "Statistik Anzahl Gesch\u00e4fte", "messages", "de");
t.add("rankings.cantonal.filter.canton.label", "Filtern nach Kanton", "messages", "de");
t.add("rankings.filter.isEffectiveAmount.label", "Vergleichen mit", "messages", "de");
t.add("rankings.filter.isEffectiveAmount.yes", "Effektive Anzahl Gesch\u00e4fte\/Vorst\u00f6sse", "messages", "de");
t.add("rankings.filter.isEffectiveAmount.no", "Relativ zu Anzahl Gesch\u00e4fte im Kanton", "messages", "de");
t.add("rankings.filter.includeGovernmentAuthors.label", "Gesch\u00e4fte der Regierung ber\u00fccksichtigen", "messages", "de");
t.add("rankings.filter.includeGovernmentAuthors.yes", "Ja", "messages", "de");
t.add("rankings.filter.includeGovernmentAuthors.no", "Nein", "messages", "de");
t.add("ranking.cantonal.numberOfAffairs.authored.effective", "Gesch\u00e4fte\/Vorst\u00f6sse effektiv", "messages", "de");
t.add("ranking.cantonal.numberOfAffairs.authored.percentage", "Anteil von", "messages", "de");
t.add("layout.menu.cantonal.rankings.affairsPerCanton", "Per Kanton", "messages", "de");
t.add("cantonal.rankings.title", "Kantonale Rankings", "messages", "de");
t.add("layout.menu.cantonal.rankings.affairsPerCouncillor", "Parlamentarier", "messages", "de");
t.add("cantonal.overview.commissions", "Kommissionen", "messages", "de");
t.add("cantonal.listCommissions.title", "Kommission mit eigenen Gesch\u00e4ften %canton%", "messages", "de");
t.add("cantonal.commission.title", "Urheber: %commission%", "messages", "de");
t.add("cantonal.commission.noAffairs", "Keine Gesch\u00e4fte dieser Kommission vorhanden.", "messages", "de");
t.add("cantonal.commission.affairs.title", "Gesch\u00e4fte", "messages", "de");
t.add("cantonal.commission.councillors.title", "Parlamentarier", "messages", "de");
t.add("cantonal.listCommissions.councillors.title", "Parlamentarier", "messages", "de");
t.add("cantonal.listCommissions.parties.title", "Parteien", "messages", "de");
t.add("cantonal.listCommissions.factions.title", "Fraktionen", "messages", "de");
t.add("cantonal.overview.miscauthors", "Diverse Autoren", "messages", "de");
t.add("cantonal.listMiscAuthors.title", "Diverse Autoren %canton%", "messages", "de");
t.add("cantonal.miscauthor.affairs.title", "Gesch\u00e4fte", "messages", "de");
t.add("cantonal.miscAuthors.noAffairs", "Keine Gesch\u00e4fte f\u00fcr diesen Autor verf\u00fcgbar.", "messages", "de");
t.add("cantonal.miscauthors.title", "Gesch\u00e4fte %miscauthor%", "messages", "de");
t.add("cantonal.overview.factions", "Fraktionen", "messages", "de");
t.add("cantonal.factions.noFactions", "Keine Fraktionen f\u00fcr diesen Kanton erfasst", "messages", "de");
t.add("cantonal.listFactions.commissionMemberships.title", "Mitgliedschaften Kommissionen", "messages", "de");
t.add("cantonal.faction.noAffairs", "Keine Gesch\u00e4fte f\u00fcr diese Fraktion", "messages", "de");
t.add("cantonal.faction.commissions.title", "Mitdliedschaften Kommissionen", "messages", "de");
t.add("cantonal.faction.parties.title", "Parteien", "messages", "de");
t.add("cantonal.listFactions.title", "Fraktionen %canton%", "messages", "de");
t.add("cantonal.faction.title", "%faction%", "messages", "de");
t.add("cantonal.overview.parties", "Parteien", "messages", "de");
t.add("cantonal.parties.noParties", "Keine Parteien erfasst f\u00fcr diesen Kanton", "messages", "de");
t.add("cantonal.parties.noAffairs", "Keine Gesch\u00e4fte dieser Partei", "messages", "de");
t.add("cantonal.parties.commissions.title", "Mitgliedschaft Kommission", "messages", "de");
t.add("cantonal.party.factionMembership.title", "Mitgliedschaft Fraktion", "messages", "de");
t.add("cantonal.party.affairs.title", "Gesch\u00e4fte", "messages", "de");
t.add("cantonal.party.title", "%party%", "messages", "de");
t.add("cantonal.listParties.title", "Parteien %canton%", "messages", "de");
t.add("groups.tree.search", "Suche...", "messages", "de");
t.add("groups.tree.search.next", "weiter", "messages", "de");
t.add("groups.tree.search.prev", "zur\u00fcck", "messages", "de");
t.add("groups.tree.search.collapse", "Alle zuklappen", "messages", "de");
t.add("groups.tree.search.expand", "Alle aufklappen", "messages", "de");
t.add("groups.tree.group.checkGroup", "Zur Gruppen-Ansicht", "messages", "de");
t.add("groups.tree.group.deleteGroup", "Gruppe l\u00f6schen", "messages", "de");
t.add("groups.tree.group.deleteAffair", "Gesch\u00e4ft aus Gruppe entfernen", "messages", "de");
t.add("groups.tree.group.checkAffair", "Zum Gesch\u00e4ft", "messages", "de");
t.add("groups.tree.group.editGroup", "Gruppe bearbeiten", "messages", "de");
t.add("groups.tree.sort", "Sortierung", "messages", "de");
t.add("groups.tree.sort.alphabetically", "alphabetisch", "messages", "de");
t.add("groups.tree.sort.custom", "benutzerdefiniert", "messages", "de");
t.add("groups.tree.sort.default", "Standard", "messages", "de");
t.add("groups.tree.sort.byDate", "Datum", "messages", "de");
t.add("affairGroup.export.header.id", "ID", "messages", "de");
t.add("affairGroup.export.header.type", "Typ", "messages", "de");
t.add("affairGroup.export.header.title", "Titel", "messages", "de");
t.add("affairGroup.export.header.description", "Eingereichter Text", "messages", "de");
t.add("affairGroup.export.header.status", "Status", "messages", "de");
t.add("affairGroup.export.header.link", "Quelle", "messages", "de");
t.add("groups.tree.group.exportGroup", "Export Excel", "messages", "de");
t.add("affair.eventAttachments", "Anh\u00e4nge", "messages", "de");
t.add("affair.eventAttachments.download", "Anhang herunterladen", "messages", "de");
t.add("groups.form.title.cannotBeEmpty", "Titel kann nicht leer sein", "messages", "de");
t.add("affair.internalNote.title", "Beschreibung", "messages", "de");
t.add("affair.internalNote", "Beschreibung", "messages", "de");
t.add("affair.affairOverview.groups", "Gruppen", "messages", "de");
t.add("affair.affairOverview.groups.empty", "Keinen Gruppen zugewiesen", "messages", "de");
t.add("affair.affairOverview.meetings.empty", "Keinen Sitzungen zugewiesen", "messages", "de");
t.add("affair.internalNote.error", "Fehler Beschreibung", "messages", "de");
t.add("rankings.filter.affairgroup.label", "Gruppe", "messages", "de");
t.add("affairGroups.editGroup.save", "Gespeichert", "messages", "de");
t.add("councillor.info.notTreated", "Noch nicht behandelt:", "messages", "de");
t.add("councillor.info.notTreated.text", "Vorstoss eingereicht", "messages", "de");
t.add("councillor.info.successfully", "Erfolgreich:", "messages", "de");
t.add("councillor.info.successfully.text", "Der Vorstoss wurde in National- und St\u00e4nderat zugestimmt", "messages", "de");
t.add("councillor.info.successfullyCouncil", "Erfolgreich in einem Rat:", "messages", "de");
t.add("councillor.info.successfullyCouncil.text", "Dem Vorstoss wurde in einem Rat zugestimmt. Die Abstimmung im zweiten Rat ist allenfalls noch offen.", "messages", "de");
t.add("councillor.info.noSuccess", "Kein Erfolg:", "messages", "de");
t.add("councillor.info.noSuccess.text", "Der Vorstoss wurde in mindestens einem Rat abgelehnt", "messages", "de");
t.add("councillor.info.noVote", "Wird nicht abgestimmt:", "messages", "de");
t.add("councillor.info.noVote.text", "Interpellationen oder Vorst\u00f6sse in der Fragestunde werden hier aufgelistet.", "messages", "de");
t.add("affair.internalNote.save", "Speichern", "messages", "de");
t.add("national.affair.originalSource", "Quelle: Curia Vista", "messages", "de");
t.add("affairs.followingHaveBeenUpdated", "Die folgenden Gesch\u00e4fte wurden aktualisiert", "messages", "de");
t.add("national.affairs.followingHaveBeenUpdated", "Die folgenden nationalen Gesch\u00e4fte wurden aktualisiert", "messages", "de");
t.add("affair.customSpeaker.cantonalCouncillor.label", "Referent\/in", "messages", "de");
t.add("affair.customSpeaker.nationalCouncilCouncillor.label", "Referent\/in Nationalrat", "messages", "de");
t.add("affair.customSpeaker.councilStatesCouncillor.label", "Referent\/in St\u00e4nderat", "messages", "de");
t.add("affair.customSpeaker.button.submit", "Speichern", "messages", "de");
t.add("affair.customSpeaker.save.success", "Referent\/in gespeichert", "messages", "de");
t.add("notification.subscription.button.affair.tooltip", "Gesch\u00e4ft abonnieren \/ abbestellen", "messages", "de");
t.add("customMeetings.newMeeting.success", "Erfolgreich gespeichert", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.modal", "Neues Traktandum erstellen", "messages", "de");
t.add("customMeetings.assignAgendaPoint.modal", "Zu Traktandum hinzuf\u00fcgen", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.save", "Speichern", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.success", "Erfolgreich gespeichert", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.noMeetings", "Keine Sitzung", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.error", "Fehler beim Speichern", "messages", "de");
t.add("customMeetings.editMeeting.title", "Titel", "messages", "de");
t.add("customMeetings.editMeeting.save", "Speichern", "messages", "de");
t.add("customMeetings.editMeeting.success", "Erfolgreich gespeichert", "messages", "de");
t.add("customMeetings.editMeetingAgendaPoint.success", "Erfolgreich gespeichert", "messages", "de");
t.add("customMeetings.assignAgendaPoint.title", "Zu Traktandum hinzuf\u00fcgen", "messages", "de");
t.add("customMeetings.assignAgendaPoint.placeholder", "Traktandum ausw\u00e4hlen", "messages", "de");
t.add("customMeetings.assignAgendaPoint.label", "Traktandum", "messages", "de");
t.add("customMeetings.assignAgendaPoint.error", "Fehler beim Hinzuf\u00fcgen", "messages", "de");
t.add("customMeetings.assignAgendaPoint.success", "Zuweisungen zu Sitzungen erfolgreich aktualisiert.", "messages", "de");
t.add("customMeetings.assignAgendaPoint.save", "Speichern", "messages", "de");
t.add("customMeetings.newMeeting.date.label", "Datum", "messages", "de");
t.add("customMeetings.newMeeting.modal", "Neue Sitzung erstellen", "messages", "de");
t.add("customMeetings.newMeeting.title", "Neue Sitzung erstellen", "messages", "de");
t.add("customMeetings.newMeeting.title.label", "Titel", "messages", "de");
t.add("customMeetings.newMeeting.subject.label", "Betreff", "messages", "de");
t.add("customMeetings.newMeeting.description.label", "Beschreibung", "messages", "de");
t.add("customMeetings.newMeeting.location.label", "Ort", "messages", "de");
t.add("customMeetings.newMeeting.president.label", "Vorsitz", "messages", "de");
t.add("customMeetings.newMeeting.protocol.label", "Protokollf\u00fchrung", "messages", "de");
t.add("customMeetings.newMeeting.save", "Speichern", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.title", "Neues Traktandum erstellen", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.title.label", "Neues Traktandum erstellen", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.description.label", "Beschreibung", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.parent.placeholder", "Sitzung ausw\u00e4hlen", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.parent.label", "Sitzung", "messages", "de");
t.add("customMeetings.tree.search.expand", "Alle aufklappen", "messages", "de");
t.add("customMeetings.tree.search.collapse", "Alle zuklappen", "messages", "de");
t.add("customMeetings.tree.search", "Suche", "messages", "de");
t.add("customMeetings.tree.search.prev", "Zur\u00fcck", "messages", "de");
t.add("customMeetings.tree.search.next", "Weiter", "messages", "de");
t.add("customMeetings.form.title.cannotBeEmpty", "Bitte f\u00fcllen Sie einen Wert ein", "messages", "de");
t.add("customMeetings.tree.customMeeting.createInvitationPDF", "Einladung erzeugen (PDF)", "messages", "de");
t.add("customMeetings.tree.customMeeting.createProtocolPDF", "Protokoll erzeugen (PDF)", "messages", "de");
t.add("customMeetings.tree.sort.default", "Standard", "messages", "de");
t.add("customMeetings.tree.sort.alphabetically", "alphabetisch", "messages", "de");
t.add("customMeetings.tree.sort.byDate", "Datum", "messages", "de");
t.add("customMeetings.tree.sort.custom", "Benutzerdefiniert", "messages", "de");
t.add("customMeetings.tree.customMeeting.checkAffair", "Zum Gesch\u00e4ft", "messages", "de");
t.add("customMeetings.editCustomMeeting.tooltip", "Bearbeiten", "messages", "de");
t.add("customMeetings.editCustomMeeting.save", "Speichern", "messages", "de");
t.add("customMeetings.tree.customMeeting.editCustomMeeting", "Sitzung bearbeiten", "messages", "de");
t.add("customMeetings.tree.customMeeting.deleteCustomMeeting", "Sitzung l\u00f6schen", "messages", "de");
t.add("customMeetings.newAgendaPoint.tooltip", "Traktandum hinzuf\u00fcgen", "messages", "de");
t.add("customMeetings.tree.customMeeting.newAgendaPoint", "Traktandum hinzuf\u00fcgen", "messages", "de");
t.add("customMeetings.tree.customMeeting.deleteAffair", "Gesch\u00e4ft entfernen", "messages", "de");
t.add("customMeetings.editCustomMeetingAgendaPoint.tooltip", "Traktandum bearbeiten", "messages", "de");
t.add("customMeetings.tree.customMeeting.editCustomMeetingAgendaPoint", "Traktandum bearbeiten", "messages", "de");
t.add("customMeetings.tree.customMeeting.deleteCustomMeetingAgendaPoint", "Traktandum l\u00f6schen", "messages", "de");
t.add("customMeetings.deleteCustomMeeting.message", "Sitzung l\u00f6schen?", "messages", "de");
t.add("customMeetings.deleteCustomMeeting.confirm", "Best\u00e4tigen", "messages", "de");
t.add("customMeetings.deleteCustomMeeting.cancel", "Abbrechen", "messages", "de");
t.add("customMeetings.deleteCustomMeeting.success", "Erfolgreich gel\u00f6scht", "messages", "de");
t.add("customMeetings.deleteCustomMeeting.error", "Fehler beim L\u00f6schen", "messages", "de");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.message", "Traktandum l\u00f6schen?", "messages", "de");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.confirm", "Best\u00e4tigen", "messages", "de");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.cancel", "Abbrechen", "messages", "de");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.error", "Fehler beim L\u00f6schen. Bitte kontaktieren Sie den Support unter support@politik.ch", "messages", "de");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.success", "Erfolgreich gel\u00f6scht", "messages", "de");
t.add("customMeetings.error.message", "Fehler", "messages", "de");
t.add("layout.custom_meetings", "Sitzungen", "messages", "de");
t.add("layout.custom_meetings.shortcut", "S", "messages", "de");
t.add("layout.custom_affairs", "Interne Gesch\u00e4fte", "messages", "de");
t.add("layout.custom_affairs.shortcut", "IG", "messages", "de");
t.add("layout.menu.management", "Verwaltung", "messages", "de");
t.add("affair.affairOverview.meetings", "Sitzungen", "messages", "de");
t.add("customMeetings.affair.existingMeetings", "Sitzungen", "messages", "de");
t.add("profile.customMeetings", "Sitzungen", "messages", "de");
t.add("profile.settings.navigation.companySettings", "Einstellungen", "messages", "de");
t.add("profile.settings.company.pictureFile.label", "Logo", "messages", "de");
t.add("profile.settings.company.settings.label", "Referenten", "messages", "de");
t.add("profile.settings.company.download.label", "Logo herunterladen", "messages", "de");
t.add("profile.settings.company.delete.label", "Logo l\u00f6schen", "messages", "de");
t.add("custom_speakers_enabled.label", "Referent\/in anzeigen", "messages", "de");
t.add("affair.customSpeakers.save.success", "Erfolgreich gespeichert", "messages", "de");
t.add("custom.affairs.list.pagetitle", "Interne Gesch\u00e4fte", "messages", "de");
t.add("custom.affairs.list.title", "Interne Gesch\u00e4fte", "messages", "de");
t.add("custom.affairs.edit.pagetitle", "Internes Gesch\u00e4ft bearbeiten", "messages", "de");
t.add("custom.affairs.edit.title", "Internes Gesch\u00e4ft bearbeiten", "messages", "de");
t.add("custom.affairs.backToList", "zur\u00fcck", "messages", "de");
t.add("custom.affairs.list.header.title", "Titel", "messages", "de");
t.add("custom.affairs.list.header.internalId", "Interne ID", "messages", "de");
t.add("custom.affairs.list.header.createdAt", "Erzeugt am", "messages", "de");
t.add("custom.affairs.list.header.actions", "Aktionen", "messages", "de");
t.add("custom.affairs.list.edit.label", "bearbeiten", "messages", "de");
t.add("custom.affairs.list.delete.label", "L\u00f6schen", "messages", "de");
t.add("custom.affairs.noaffairs.alert", "Keine internen Gesch\u00e4fte vorhanden", "messages", "de");
t.add("custom.affairs.delete.confirm", "Sind Sie sicher, dass Sie das interne Gesch\u00e4ft l\u00f6schen wollen?", "messages", "de");
t.add("custom.affairs.form.title", "Titel", "messages", "de");
t.add("custom.affairs.form.internalId", "Interne ID", "messages", "de");
t.add("custom.affairs.form.text", "Text", "messages", "de");
t.add("custom.affairs.form.button.submit", "Speichern", "messages", "de");
t.add("custom.affairs.overview.title", "Internes Gesch\u00e4ft", "messages", "de");
t.add("custom.affairs.form.create.title", "Neues internes Gesch\u00e4ft erstellen", "messages", "de");
t.add("custom.affairs.new.title", "Neues interne Gesch\u00e4ft erstellen", "messages", "de");
t.add("custom.affairs.new.error", "Fehler beim Speichern", "messages", "de");
t.add("custom.affairs.new.pagetitle", "Neues interne Gesch\u00e4ft erstellen", "messages", "de");
t.add("cantonalAffairs.deposited.tooltip.title", "Eingereicht", "messages", "de");
t.add("customAffairs.deposited.tooltip.title", "Erstellt", "messages", "de");
t.add("national.affair.draft.federal.council.proposal.tooltip.title", "Entwurf Bundesrat", "messages", "de");
t.add("affairGroup.export.buttonlabel", "Export", "messages", "de");
t.add("customMeetings.editMeetingAgendaPoint.title", "Traktandum bearbeiten", "messages", "de");
t.add("customMeetings.editMeetingAgendaPoint.save", "Speichern", "messages", "de");
t.add("customMeetings.assignAgendaPoint.noMeetings", "Keine Sitzungen erfasst", "messages", "de");
t.add("customMeetings.assignAgendaPoint.noAgendaPointsLabel", "Noch keine Traktanden erfasst", "messages", "de");
t.add("customMeetings.assignAgendaPoint.empty", "Keine Sitzungen \/ Traktanden erfasst.", "messages", "de");
t.add("customMeetings.replicateMeeting.copyOf", "Sitzungsstruktur kopieren (Traktanden)", "messages", "de");
t.add("customMeetings.replicateMeeting.success", "Erfolgreich", "messages", "de");
t.add("customMeetings.tree.customMeeting.newCustomAffair", "Neues internes Gesch\u00e4ft erstellen", "messages", "de");
t.add("customMeetings.tree.customMeeting.replicateCustomMeeting", "Duplizieren", "messages", "de");
t.add("councillor.topic.count.title", "%topic%: %count% Vorst\u00f6sse", "messages", "de");
t.add("canton.label", "Sitzungs-Einstellungen: Standard-Kanton", "messages", "de");
t.add("cantonal_faction.label", "Sitzungs-Einstellungen: Standard-Fraktion", "messages", "de");
t.add("is_private.label", "Private Gruppe (nicht f\u00fcr alle sichtbar)", "messages", "de");
t.add("affairGroups.newGroup.settings.label", "Gruppen-Einstellungen", "messages", "de");
t.add("affair.relatedDepartments.title", "Departemente", "messages", "de");
t.add("affair.relatedDepartments.desc", "Departemente", "messages", "de");
t.add("affair.relatedDepartments.empty", "Keine Zuweisung", "messages", "de");
t.add("affairs.editor.placeholder", "(Bitte hier klicken, um interne Beschreibung zu erfassen)", "messages", "de");
t.add("selectpicker.noneSelectedText", "keine Auswahl getroffen...", "messages", "de");
t.add("custom.affairs.form.html", "Text", "messages", "de");
t.add("customMeetings.newMeeting.participants.label", "Anwesende", "messages", "de");
t.add("customMeetings.newMeeting.guests.label", "G\u00e4ste", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.reducedOutputAffairs.label", "Reduzierte Ausgabe auf Berichten\/Einladungen (keine History)", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.type.label", "Traktandum-Typ", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.type.regular.label", "Standard", "messages", "de");
t.add("customMeetings.newMeetingAgendaPoint.type.affairsWithoutSpeaker.label", "Gesch\u00e4fte ohne Referent\/in", "messages", "de");
t.add("affair.daysummary", "Zusammenfassung Tag", "messages", "de");
t.add("profile.notifications", "Benachrichtigungen", "messages", "de");
t.add("profile.notifications.edit.shortcut", "B", "messages", "de");
t.add("profile.notifications.edit", "Benachrichtigungen", "messages", "de");
t.add("profile.notifications.markAllRead", "Alle als gelesen markieren", "messages", "de");
t.add("profile.notifications.loadMore", "Mehr anzeigen", "messages", "de");
t.add("profile.notifications.empty", "Keine Benachrichtigungen", "messages", "de");
t.add("profile.notifications.noMore", "Keine Benachrichtigungen", "messages", "de");
t.add("notification.subscription.button.subscribed", "Abonniert", "messages", "de");
t.add("notification.subscription.button.unSubscribed", "Nicht abonniert", "messages", "de");
t.add("custom.affairs.form.copiedWithMeeting", "Mit Sitzung replizieren", "messages", "de");
t.add("customMeetings.pdf.customSpeakers.label", "Referent\/in", "messages", "de");
t.add("national.committees.list", "Liste nationale Kommissionen", "messages", "de");
t.add("national.committees.show", "Nationale Kommission", "messages", "de");
t.add("layout.menu.nationalcouncil.commissions", "Kommissionen NR", "messages", "de");
t.add("layout.menu.nationalcouncil.commissions.shortcut", "K", "messages", "de");
t.add("layout.menu.councilofstates.commissions", "Kommissionen SR", "messages", "de");
t.add("layout.menu.councilofstates.commissions.shortcut", "K", "messages", "de");
t.add("customMeetings.pdf.protocol.title", "Protokoll", "messages", "de");
t.add("cantonal.recentInsertedAffairs", "Neuste kantonale Gesch\u00e4fte", "messages", "de");
t.add("customMeetings.pdf.invitation.title", "Einladung", "messages", "de");
t.add("vote.novotes", "Kein detailliertes Abstimmungsresultat vorhanden", "messages", "de");
t.add("national.affairs.overview.title", "Nationale Gesch\u00e4fte", "messages", "de");
t.add("national.affairs.list.header.title", "Titel", "messages", "de");
t.add("national.affairs.list.header.author", "Autor\/in", "messages", "de");
t.add("national.affairs.list.header.topics", "Themen", "messages", "de");
t.add("national.affairs.list.header.status", "Status", "messages", "de");
t.add("national.affairs.list.header.depositDate", "Datum Einreichung", "messages", "de");
t.add("reports.overview.title", "Berichte", "messages", "de");
t.add("reports.source.label", "Datenquelle", "messages", "de");
t.add("reports.from.label", "Ausgabe Gesch\u00e4fte: Mit welchem Gesch\u00e4ft (Nummer) soll gestartet werden? Standard: leer = 1", "messages", "de");
t.add("reports.to.label", "Ausgabe Gesch\u00e4fte: Bis zu welchem Gesch\u00e4ft (Nummer) sollen ausgegeben werden? Standard: leer = bis zum letzten Gesch\u00e4ft (alle Gesch\u00e4fte)", "messages", "de");
t.add("reports.source.placeholder", "Bitte Quelle w\u00e4hlen...", "messages", "de");
t.add("reports.firstNumber.label", "Start Nummerierung Gesch\u00e4fte im PDF (leer = 1)", "messages", "de");
t.add("reports.groups", "Gruppen", "messages", "de");
t.add("reports.groups.label", "Gruppe(n)", "messages", "de");
t.add("reports.error.creation", "Fehler beim Erzeugen des Berichts. Bitte wenden Sie sich an den Support.", "messages", "de");
t.add("layout.menu.reports", "Berichte", "messages", "de");
t.add("reports.session.canton.zh", "Gesch\u00e4fte Kantonsratssitzung ZH", "messages", "de");
t.add("affair.cantonalHistoryeventOnSessionAgenda.tooltip.caption", "Kantonales Ereignis", "messages", "de");
t.add("affair.cantonalHistoryeventStatuschange.tooltip.caption", "Status\u00e4nderung", "messages", "de");
t.add("custom.affairs.new.success", "Internes Gesch\u00e4ft erfolgreich erstellt", "messages", "de");
t.add("custom.affairs.edit.success", "Internes Gesch\u00e4ft erfolgreich gespeichert", "messages", "de");
t.add("custom.affairs.delete.success", "Internes Gesch\u00e4ft erfolgreich gel\u00f6scht", "messages", "de");
t.add("custom.affairs.delete.error", "Fehler beim L\u00f6schen", "messages", "de");
t.add("affair.draftReference.title", "Quelle Entwurf", "messages", "de");
t.add("affair.draftPreConsultation.title", "Entwurf Vorberatung", "messages", "de");
t.add("affair.draftPreConsultation.tooltip.caption", "Entwurf Vorberatung", "messages", "de");
t.add("dashboard.updates.update_name", "Titel", "messages", "de");
t.add("dashboard.updates.item_type", "Stand", "messages", "de");
t.add("dashboard.updates.update_type", "Aktualisierung", "messages", "de");
t.add("dashboard.updates.update_time", "Erscheinungsdatum", "messages", "de");
t.add("dashboard.updates.type.insert", "Neu", "messages", "de");
t.add("dashboard.updates.type.update", "Aktualisiert", "messages", "de");
t.add("dashboard.updates.type.remove", "Entfernt", "messages", "de");
t.add("dashboard.updates.title", "Updates Gesch\u00e4fte", "messages", "de");
t.add("dashboard.custom_updates.title", "Updates interne Ereignisse", "messages", "de");
t.add("dashboard.updates.itemType.cantonalAffair", "Kantonales Gesch\u00e4ft", "messages", "de");
t.add("dashboard.updates.itemType.councilStatesVote", "Abstimmung St\u00e4nderat", "messages", "de");
t.add("dashboard.updates.itemType.nationalAffair", "Nationales Gesch\u00e4ft", "messages", "de");
t.add("dashboard.updates.itemType.nationalCouncilVote", "Abstimmung Nationalrat", "messages", "de");
t.add("dashboard.updates.itemType.customAffair", "Internes Gesch\u00e4ft", "messages", "de");
t.add("dashboard.updates.itemType.customSpeaker", "Referent\/in", "messages", "de");
t.add("dashboard.updates.itemType.affairGroup", "Gruppe", "messages", "de");
t.add("dashboard.updates.itemType.customMeeting", "Sitzung", "messages", "de");
t.add("dashboard.updates.itemType.customMeetingAgendaPoint", "Traktandum Sitzung", "messages", "de");
t.add("affair.timeline", "Zeitachse", "messages", "de");
t.add("affair.event.details", "Details Ereignis", "messages", "de");
t.add("actions.default", "Aktionen", "messages", "de");
t.add("groups.tree.type_select.all", "Alle Gruppen", "messages", "de");
t.add("groups.tree.type_select.public", "Nur nicht-private Gruppen", "messages", "de");
t.add("groups.tree.type_select.private", "Nur private Gruppen", "messages", "de");
t.add("groups.tree.sort_select.alpha", "alphabetisch sortieren", "messages", "de");
t.add("groups.tree.sort_select.date", "Nach Erstelldatum sortieren", "messages", "de");
t.add("affairGroups.deleteGroup.success", "Gruppe erfolgreich gel\u00f6scht", "messages", "de");
t.add("layout.affairs_overview.Shortcut", "G", "messages", "de");
t.add("layout.menu.nationalcouncil.consultations.shortcut", "V", "messages", "de");
t.add("dashboard.updates.itemType.internalNote", "Interne Notiz", "messages", "de");
t.add("dashboard.updates.itemType.internalVoteCustomEvent", "Interne Abstimmung", "messages", "de");
t.add("affair.custom.description", "Beschreibung", "messages", "de");
t.add("affair.events", "Ereignisse", "messages", "de");
t.add("national.consultations.overview.title", "Nationale Vernehmlassungen", "messages", "de");
t.add("national.consultations.details.show_all", "Alle anzeigen", "messages", "de");
t.add("national.consultations.details.planned", "Geplante VNL", "messages", "de");
t.add("national.consultations.details.in_progress", "Laufende VNL", "messages", "de");
t.add("national.consultations.details.closed", "Abgeschlossene VNL", "messages", "de");
t.add("national.consultations.details.title", "Detail zur Vernehmlassung", "messages", "de");
t.add("national.consultations.details.author", "Urheber", "messages", "de");
t.add("national.consultations.documents", "Dokumente", "messages", "de");
t.add("national.consultations.opinions", "VNL-Stellungnahmen", "messages", "de");
t.add("national.consultations.details.empty_list", "Keine Vernehmlassungen", "messages", "de");
t.add("affair.error.get_event", "Fehler beim Lesen der Ereignisse", "messages", "de");
t.add("affair.customEvents.error", "Fehler beim Lesen von Notizen", "messages", "de");
t.add("national.consultations.error", "Fehler beim Lesen von Vernehmlassungen", "messages", "de");
t.add("dashboard.updates.itemType.noteCustomEvent", "Notiz", "messages", "de");
t.add("affairs.overview.title", "Gesch\u00e4fte", "messages", "de");
t.add("affair.customEvent.button.delete", "L\u00f6schen", "messages", "de");
t.add("notifications.not_seen.description", "NEU", "messages", "de");
t.add("notifications.not_seen.shortcut", "N", "messages", "de");
t.add("ui.modal.warning", "Warnung", "messages", "de");
t.add("affair_detail.modals.event_delete.text", "Wollen Sie das Ereignis l\u00f6schen?", "messages", "de");
t.add("ui.confirm", "Ja", "messages", "de");
t.add("ui.abort", "Abbrechen", "messages", "de");
t.add("ui.breadcrumb.home", "Start", "messages", "de");
t.add("ui.affair.managed", "in Gruppe", "messages", "de");
t.add("affair.type.abbr", "Abk\u00fcrzung", "messages", "de");
t.add("affair.code", "Code", "messages", "de");
t.add("affair.shortIdParlService", "Curia Vista Nummer", "messages", "de");
t.add("affair.badge.private", "Gesch\u00e4ft Kommission", "messages", "de");
t.add("commission_detail.events.empty", "Keine Eintr\u00e4ge", "messages", "de");
t.add("commission_detail.lastProgramLink", "Link letztes Programm (PDF)", "messages", "de");
t.add("commission_detail.overviewPageLink", "Link offizielle Seite der Kommission", "messages", "de");
t.add("affairs.list.empty", "Keine Eintr\u00e4ge", "messages", "de");
t.add("affair_list.select.type.label", "Typ", "messages", "de");
t.add("affair_list.type_select.type.all", "Alle", "messages", "de");
t.add("affair_list.select.type.national", "Nationale Gesch\u00e4fte", "messages", "de");
t.add("affair_list.select.type.cantonal", "Kantonale Gesch\u00e4fte", "messages", "de");
t.add("affair_list.select.date.last6months", "Letzte 6 Monate", "messages", "de");
t.add("affair_list.select.date.last12months", "Letzte 12 Monate", "messages", "de");
t.add("affair_list.select.date_after", "Einreichungsdatum", "messages", "de");
t.add("affair_list.select.date_before", "Datum liegt nach", "messages", "de");
t.add("ui.select.groups", "Gruppen", "messages", "de");
t.add("ui.select.groups.placeholder", "Gruppen", "messages", "de");
t.add("affair_list.select.events.label", "Notizen \/ interne Abstimmungen", "messages", "de");
t.add("affair_list.select.events.all", "Alle", "messages", "de");
t.add("affair_list.select.events.note", "Mit Notizen", "messages", "de");
t.add("affair_list.select.events.internal_vote", "Mit internen Abstimmungen", "messages", "de");
t.add("ui.select.status.placeholder", "Nach Status filtern...", "messages", "de");
t.add("ui.select.status.national", "Status national", "messages", "de");
t.add("ui.select.status.cantonal", "Status kantonal", "messages", "de");
t.add("panel.detailsLink.label", "Interne Erg\u00e4nzungen", "messages", "de");
t.add("timeline.tooltip.affair_link", "Zum Gesch\u00e4ft", "messages", "de");
t.add("ui.table.sort.asc", "Aufsteigend", "messages", "de");
t.add("ui.table.sort.desc", "Absteigend", "messages", "de");
t.add("ui.table.sort.asc.desc", "Sortierung absteigend", "messages", "de");
t.add("dashboard.error.get_entries", "Fehler beim Laden der Eintr\u00e4ge", "messages", "de");
t.add("notifications.error.get", "Fehler beim Laden der Benachrichtigungen", "messages", "de");
t.add("notifications.error.set_seen", "Fehler beim Setzen des Benachrichtigungsstatus", "messages", "de");
t.add("councillors.error.get", "Fehler beim Abruf Parlamentarier", "messages", "de");
t.add("ui.select.councillors.placeholder", "Parlamentarier w\u00e4hlen", "messages", "de");
t.add("ui.select.councillors.label", "Parlamentarier", "messages", "de");
t.add("commissions.link.commission", "Zur Kommission", "messages", "de");
t.add("commissions.link.overview", "Zur Kommission", "messages", "de");
t.add("commissions.link.program", "zur Sitzungsplanung", "messages", "de");
t.add("calendar.events.empty", "Keine Eintr\u00e4ge", "messages", "de");
t.add("security.saml.discovery", "SSO-Login", "messages", "de");
t.add("textEditor.small", "Klein", "messages", "de");
t.add("textEditor.large", "Normal", "messages", "de");
t.add("textEditor.huge", "Gross", "messages", "de");
t.add("affair.nationalAffairDraftResolution.tooltip.caption", "Entwurf Dokument", "messages", "de");
t.add("affair.nationalAffairDraftPreConsultation.tooltip.caption", "Vorberatung", "messages", "de");
t.add("event.calendar.title", "Kalender", "messages", "de");
t.add("layout.search.error", "Fehler bei der Suche", "messages", "de");
t.add("layout.search.recent_affairs_national", "Aktuellste Gesch\u00e4fte national", "messages", "de");
t.add("layout.search.recent_affairs_cantonal", "Aktuellste Gesch\u00e4fte kantonal", "messages", "de");
t.add("timeline.tooltip.event_link", "Zum Gesch\u00e4ft", "messages", "de");
t.add("affair.nationalHistoryeventStatuschange.tooltip.caption", "Status ge\u00e4ndert", "messages", "de");
t.add("affair.nationalAffairDraftReference.tooltip.caption", "Entwurf Dokument", "messages", "de");
t.add("affair.nationalHistoryeventSessionProgram.tooltip.caption", "Geplant: Behandlung in Session", "messages", "de");
t.add("affair.nationalCouncilAffairDebate.tooltip.caption", "Debatte NR", "messages", "de");
t.add("affair.nationalAffairDraftFederalCouncilProposal.tooltip.caption", "Entwurf Bundesrat", "messages", "de");
t.add("affair.nationalHistoryeventCommitteeProgram.tooltip.caption", "Geplant: Behandlung in Kommission", "messages", "de");
t.add("event.reminders.title", "Erinnerungen", "messages", "de");
t.add("event.reminders.button.save", "Speichern", "messages", "de");
t.add("event.reminders.button.delete", "L\u00f6schen", "messages", "de");
t.add("event.reminders.button.add", "Hinzuf\u00fcgen", "messages", "de");
t.add("event.reminders.button.subscribed", "Erinnerung erstellt", "messages", "de");
t.add("event.reminders.button.unsubscribed", "Erinnerung erstellen", "messages", "de");
t.add("api.error.get_event_reminder", "Fehler beim Lesen von Erinnerungen. Bitte kontaktieren Sie den Support: support@politik.ch", "messages", "de");
t.add("api.groups.get.error", "Fehler beim Lesen von Gruppen. Bitte kontaktieren Sie den Support: support@politik.ch", "messages", "de");
t.add("event.reminders.edit.success", "Erfolgreich gespeichert", "messages", "de");
t.add("event.reminders.edit.error", "Fehler beim Speichern der Erinnerung. Bitte kontaktieren Sie den Support: support@politik.ch", "messages", "de");
t.add("event.reminders.create.error", "Fehler beim Speichern der Erinnerung. Bitte kontaktieren Sie den Support: support@politik.ch", "messages", "de");
t.add("event.reminders.delete.error", "Fehler beim L\u00f6schen der Erinnerung. Bitte kontaktieren Sie den Support: support@politik.ch", "messages", "de");
t.add("event.reminders.empty", "Keine Erinnerungen", "messages", "de");
t.add("event.reminders.input.date", "Datum", "messages", "de");
t.add("event.reminders.input.data", "Erinnerung erstellt", "messages", "de");
t.add("search.error.get_cantons", "Fehler beim Aufbau der Suche. Bitte kontaktieren Sie den Support: support@politik.ch", "messages", "de");
t.add("search.error.get_national_affair_types", "Fehler beim Aufbau der Suche. Bitte kontaktieren Sie den Support: support@politik.ch", "messages", "de");
t.add("search.error.get_cantonal_affair_types", "Fehler beim Aufbau der Suche. Bitte kontaktieren Sie den Support: support@politik.ch", "messages", "de");
t.add("layout.search.pagination.previous", "Vorherige", "messages", "de");
t.add("layout.search.pagination.next", "N\u00e4chste", "messages", "de");
t.add("affairs.search.title", "Suche", "messages", "de");
t.add("national.consultations.list", "Vernehmlassungen", "messages", "de");
t.add("national.councillors.list", "Parlamentarier", "messages", "de");
t.add("affair.events.custom.create.error", "Fehler beim Speichern. Bitte kontaktieren Sie den Support: support@politik.ch", "messages", "de");
t.add("affair.events.custom.create.success", "Ereignis erfolgreich gespeichert.", "messages", "de");
t.add("reports.groups.placeholder", "Gruppe(n)", "messages", "de");
t.add("eventReminder.event.notFound", "Ereignis nicht gefunden", "messages", "de");
t.add("reports.groups.outputDescription.label", "Eingereichter Text", "messages", "de");
t.add("reports.groups.outputStatus.label", "Status", "messages", "de");
t.add("reports.groups.outputLink.label", "Link zur Quelle", "messages", "de");
t.add("reports.groups.outputSpeaker.label", "Referent\/in National-\/St\u00e4nderat", "messages", "de");
t.add("reports.groups.outputInternalDescription.label", "Beschreibung", "messages", "de");
t.add("reports.groups.outputHistory.label", "Ereignisse Zeitachse", "messages", "de");
t.add("affairGroup.export.header.speaker", "Referent\/in", "messages", "de");
t.add("affairGroup.export.header.internalDescription", "Beschreibung", "messages", "de");
t.add("affairGroup.export.header.history", "Ereignisse", "messages", "de");
t.add("reports.groups.outputHistory.none", "Keine Ereignisse", "messages", "de");
t.add("reports.groups.outputHistory.customEvents", "nur interne Ereignisse (Notizen, Abstimmungen)", "messages", "de");
t.add("reports.groups.outputHistory.all", "Alle Ereignisse", "messages", "de");
t.add("reports.formats.label", "Format", "messages", "de");
t.add("reports.format.excel", "Excel (XLS)", "messages", "de");
t.add("reports.format.pdf", "PDF", "messages", "de");
t.add("affairgroups.pdf.title", "Bericht", "messages", "de");
t.add("customMeetingAgendaPoints.form.title.cannotBeEmpty", "Titel muss gesetzt werden", "messages", "de");
t.add("commission_detail.affairs", "Kommissionsgesch\u00e4fte", "messages", "de");
t.add("cantonal.commissions.empty", "Keine Kommissionen", "messages", "de");
t.add("api.councillors.get.error", "Fehler beim Abruf von Parlamentarier. Bitte wenden Sie sich an den Support.", "messages", "de");
t.add("profile.show.language", "Sprache", "messages", "de");
t.add("profile.settings.label", "Einstellungen", "messages", "de");
t.add("email_footer.label", "Footer Benachrichtigungs-E-Mail", "messages", "de");
t.add("show_company_logo_and_name.label", "App-Branding (Ecke links oben)", "messages", "de");
t.add("show_company_logo_and_name.both", "Zeige Logo und Namen", "messages", "de");
t.add("show_company_logo_and_name.logo", "Nur Logo", "messages", "de");
t.add("show_company_logo_and_name.name", "Nur Name", "messages", "de");
t.add("profile.settings.language.label", "Sprache", "messages", "de");
t.add("profile.settings.language.de", "Deutsch \/ Allemand", "messages", "de");
t.add("profile.settings.language.fr", "Franz\u00f6sisch \/ Fran\u00e7ais", "messages", "de");
t.add("affair.title.ratsunterlagen", "Ratsunterlagen", "messages", "de");
t.add("layout.maintenance.siteIsDown", "Wir f\u00fchren derzeit geplante Wartungsarbeiten aus.", "messages", "de");
t.add("layout.maintenance.beBackSoon", "politik.ch wird in kurzer Zeit wieder zur Verf\u00fcgung stehen. Bei Fragen k\u00f6nnen Sie sich jederzeit an %email% wenden. Wir bitten Sie um Verst\u00e4ndnis.", "messages", "de");
t.add("consultations.nohistoryEvents", "Keine Ereignisse", "messages", "de");
t.add("ui.filter.all", "Alle", "messages", "de");
t.add("customMeetings.editMeeting.error", "Fehler beim Bearbeiten von Sitzungen. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("profile.settings.navigation.auth", "Zwei-Faktor-Authentifizierung (2FA)", "messages", "de");
t.add("profile.edit.2fa.authMode.label", "Zweiten Faktor w\u00e4hlen", "messages", "de");
t.add("profile.edit.2fa.none", "Keinen (2FA deaktiviert)", "messages", "de");
t.add("profile.edit.2fa.email", "E-Mail", "messages", "de");
t.add("profile.edit.2fa.google", "OTP (Authenticator-App, z.B. Google Authenticator)", "messages", "de");
t.add("profile.edit.2fa.pleaseReadThisQRCode", "Bitte QR-Code scannen:", "messages", "de");
t.add("profile.edit.2fa.googleCode.label", "Bitte einen aktuellen Code eingeben", "messages", "de");
t.add("profile.edit.2fa.thisIsYourGoogleAuthenticationCode", "Backup Code:", "messages", "de");
t.add("profile.edit.2fa.pleaseStoreItInASafePlace", "Bitte speichern Sie diesen Code an einem sicheren Ort. Er kann dazu verwendet werden, sich einzuloggen, falls Sie via App keinen Zugriff mehr auf Ihre Codes haben sollten.", "messages", "de");
t.add("affairs.list.no_access", "Kein Zugriff", "messages", "de");
t.add("profile.edit.2fa.invalidGoogleCode", "Ung\u00fcltiger Code. Bitte aus der Authenticator-App einen g\u00fcltigen Code eingeben.", "messages", "de");
t.add("national.consultations.events.start", "Start Vernehmlassung", "messages", "de");
t.add("national.consultations.events.end", "Ende Vernehmlassung", "messages", "de");
t.add("ranking.councillorscompare.noVotesInSelectedTimespan", "Keine Abstimmungen in der gew\u00e4hlten Zeitspanne.", "messages", "de");
t.add("reports.reportType", "Quellen f\u00fcr Bericht", "messages", "de");
t.add("layout.search.contentType.affairConsultations", "Vernehmlassungen", "messages", "de");
t.add("rankings.form.wholeLegislativeperiod", "Abstimmungen der gesamten Legislatur", "messages", "de");
t.add("affairGroup.overview.consultations.title", "Vernehmlassungen", "messages", "de");
t.add("dashboard.updates.default_title", "Titel", "messages", "de");
t.add("dashboard.updates.itemType.nationalAffairConsultation", "Nationale Vernehmlassung", "messages", "de");
t.add("ui.table.sort.asc.asc", "Sortierung aufsteigend", "messages", "de");
t.add("ui.date_picker.future", "alle k\u00fcnftigen", "messages", "de");
t.add("ui.date_picker.past", "alle vergangenen", "messages", "de");
t.add("ui.date_picker.calendar.ranges.12months", "12 Monate", "messages", "de");
t.add("ui.date_picker.calendar.ranges.6months", "6 Monate", "messages", "de");
t.add("ui.date_picker.calendar.ranges", "Bereich", "messages", "de");
t.add("national.affairs.list.header.filter", "filtern", "messages", "de");
t.add("ui.text_editor.image.exceeds", "Gr\u00f6sse des Bildes \u00fcbersteigt Maximum von 5 MB.", "messages", "de");
t.add("profile.settings.placeholder", "-", "messages", "de");
t.add("layout.search.contentType.affairConsultation", "Vernehmlassungen", "messages", "de");
t.add("links.type.linker.mentions", "erw\u00e4hnt", "messages", "de");
t.add("links.type.linked.isMentionedBy", "wurde erw\u00e4hnt", "messages", "de");
t.add("links.type.linker.precedes", "Vorg\u00e4ngergesch\u00e4ft", "messages", "de");
t.add("links.type.linked.succeeds", "Folgegesch\u00e4ft", "messages", "de");
t.add("links.type.linker.relates", "Nimmt Bezug auf", "messages", "de");
t.add("links.type.linked.relates", "wurde in Bezug genommen", "messages", "de");
t.add("links.public", "Offiziell", "messages", "de");
t.add("affair_detail.links.title", "Verlinkte Gesch\u00e4fte", "messages", "de");
t.add("ui.delete", "L\u00f6schen", "messages", "de");
t.add("api.links.get.error", "Fehler beim Abruf der Gesch\u00e4fts-Links. Bitte beim Support melden.", "messages", "de");
t.add("links.edit_form.title", "Verlinkte Gesch\u00e4fte bearbeiten", "messages", "de");
t.add("links.edit_form.list_empty", "Keine verlinkten Gesch\u00e4fte", "messages", "de");
t.add("api.link_types.get.error", "Fehler beim Abruf der Gesch\u00e4fts-Links. Bitte beim Support melden.", "messages", "de");
t.add("api.search.error", "Fehler beim Abruf von Such-Resultaten. Bitte beim Support melden.", "messages", "de");
t.add("links.edit_form.input.public.label", "Offiziell?", "messages", "de");
t.add("links.edit_form.input.public.true", "Ja, offiziell", "messages", "de");
t.add("links.edit_form.input.public.false", "Nein, nur intern (Organisation\/Firma)", "messages", "de");
t.add("links.edit_form.input.link_type.label", "Verlinkungs-Typ", "messages", "de");
t.add("links.edit_form.input.entity.label", "Verlinken mit", "messages", "de");
t.add("ui.submit", "Speichern", "messages", "de");
t.add("links.delete.confirm", "Wollen Sie die Verkn\u00fcpfung wirklich l\u00f6schen?", "messages", "de");
t.add("ui.select.loading", "wird geladen...", "messages", "de");
t.add("affair.nationalHistoryeventCommitteePressRelease.tooltip.caption", "Medienmitteilung Kommission", "messages", "de");
t.add("committee.press_releases.title", "Medienmitteilungen Kommission", "messages", "de");
t.add("committee.pressRelease", "Medienmitteilung", "messages", "de");
t.add("api.press_releases.get.error", "Fehler beim Laden der Medienmitteilungen. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("press_release.originalSource", "Quelle", "messages", "de");
t.add("affair_detail.actions.entityLinks", "Gesch\u00e4fte verlinken", "messages", "de");
t.add("affair.nationalConsultationEndEvent.tooltip.caption", "Ende Vernehmlassung", "messages", "de");
t.add("affair.nationalConsultationStartEvent.tooltip.caption", "Start Vernehmlassung", "messages", "de");
t.add("fos_user.password.isPwned", "Passwort unsicher. Bitte w\u00e4hlen Sie ein sicheres Passwort.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.html.get.error", "Fehler beim Lesen. Bitte kontaktieren Sie den Support.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.html.put.success", "Erfolgreich gespeichert.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.html.put.error", "Fehler beim Speichern. Bitte kontaktieren Sie den Support.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.html.post.success", "Erfolgreich gespeichert", "messages", "de");
t.add("api.profile.settings.company.internal_fields.html.post.error", "Fehler beim Speichern. Bitte kontaktieren Sie den Support.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.ratings.get.error", "Fehler beim Lesen. Bitte kontaktieren Sie den Support.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.ratings.put.error", "Fehler beim Speichern. Bitte kontaktieren Sie den Support.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.ratings.post.error", "Fehler beim Speichern. Bitte kontaktieren Sie den Support.", "messages", "de");
t.add("profile.settings.comapny.custom_fields.html.labelDe", "Bezeichnung Deutsch", "messages", "de");
t.add("profile.settings.comapny.custom_fields.html.labelFr", "Bezeichnung Franz\u00f6sisch", "messages", "de");
t.add("profile.settings.comapny.custom_fields.html.labelIt", "Bezeichnung Italienisch", "messages", "de");
t.add("profile.settings.comapny.custom_fields.rating.labelDe", "Bezeichnung Deutsch", "messages", "de");
t.add("profile.settings.comapny.custom_fields.rating.labelFr", "Bezeichnung Franz\u00f6sisch", "messages", "de");
t.add("profile.settings.comapny.custom_fields.rating.labelIt", "Bezeichnung Italienisch", "messages", "de");
t.add("profile.settings.company.internal_fields.ratings.choice.default", "Standard-Wert", "messages", "de");
t.add("ui.add", "Hinzuf\u00fcgen", "messages", "de");
t.add("profile.settings.save.failure", "Fehler beim Speichern. Bitte kontaktieren Sie den Support.", "messages", "de");
t.add("profile.settings.compay.internal_fields.delete.confirm", "Sind Sie sicher, dass Sie das Feld l\u00f6schen m\u00f6chten?", "messages", "de");
t.add("profile.settings.company.custom_fields.label", "Benutzerdefinierte Felder", "messages", "de");
t.add("affair.internal_fields.title", "Weitere", "messages", "de");
t.add("profile.settings.company.custom_fields.html.add", "Neues Text-Feld", "messages", "de");
t.add("profile.settings.company.custom_fields.rating.add", "Neues Optionen-Feld", "messages", "de");
t.add("profile.settings.company.internal_fields.rating.choices.add", "Option hinzuf\u00fcgen", "messages", "de");
t.add("profile.settings.compay.internal_fields.ratings.option.delete.confirm", "Wollen Sie die Option wirklich l\u00f6schen?", "messages", "de");
t.add("layout.search.contentType.affair_consultation", "Vernehmlassungen", "messages", "de");
t.add("api.cantons.get.error", "Fehler beim Aufbau der Suche. Bitte kontaktieren Sie den Support: support@politik.ch", "messages", "de");
t.add("ui.select.agenda_points", "Sitzungen", "messages", "de");
t.add("affair_detail.links.empty", "Keine verlinkten Gesch\u00e4fte", "messages", "de");
t.add("forms.speakers.input.councillor.national_council", "Nationalrat", "messages", "de");
t.add("forms.speakers.input.councillor.states_council", "St\u00e4nderat", "messages", "de");
t.add("profile.settings.company.logo.upload.error.size", "Das hochgeladene Bild ist zu gross. Bitte kleinere Aufl\u00f6sung verwenden.", "messages", "de");
t.add("profile.settings.company.internal_fields.ratings.labelDe", "Bezeichnung DE", "messages", "de");
t.add("profile.settings.company.internal_fields.ratings.labelFr", "Bezeichnung FR", "messages", "de");
t.add("profile.settings.company.internal_fields.ratings.labelIt", "Bezeichnung IT", "messages", "de");
t.add("profile.settings.company.internal_fields.html.labelDe", "Bezeichnung DE", "messages", "de");
t.add("profile.settings.company.internal_fields.html.labelFr", "Bezeichnung FR", "messages", "de");
t.add("profile.settings.company.internal_fields.html.labelIt", "Bezeichnung IT", "messages", "de");
t.add("form.speakers.input.councillor.cantonal", "Kantonsrat \/ Grossrat", "messages", "de");
t.add("national.consultations.description.header", "Beschreibung", "messages", "de");
t.add("timeline.settings.timespans", "Mehrt\u00e4gige Ereignisse", "messages", "de");
t.add("timeline.settings.timespans.showend", "anzeigen", "messages", "de");
t.add("timeline.settings.timespans.hideend", "nicht anzeigen", "messages", "de");
t.add("national.consultations.events.start.timespan", "Zeitraum Vernehmlassung", "messages", "de");
t.add("custom.affairs.table.caption", "Interne Gesch\u00e4fte", "messages", "de");
t.add("affair.councilStatesVote.tooltip.caption", "Abstimmung St\u00e4nderat", "messages", "de");
t.add("form.current_password", "Aktuelles Passwort", "messages", "de");
t.add("form.new_password", "Neues Passwort", "messages", "de");
t.add("form.new_password_confirmation", "Neues Passwort best\u00e4tigen", "messages", "de");
t.add("affair.nationalHistoryeventCommitteeProgramStart.tooltip.caption", "Geplant: Behandlung in Kommission", "messages", "de");
t.add("affair.nationalAffairConsultationEndEvent.tooltip.caption", "Nationale Vernehmlassung: Ende", "messages", "de");
t.add("affair.nationalAffairConsultationStartEvent.tooltip.caption", "Nationale Vernehmlassung: Start", "messages", "de");
t.add("ui.select.meetings.placeholder", "Sitzungen", "messages", "de");
t.add("affair.nationalAffairNoteCustomEvent.tooltip.caption", "Interne Notiz", "messages", "de");
t.add("affair.statusChangeNationalAffairEvent.tooltip.caption", "Status\u00e4nderung", "messages", "de");
t.add("affair.nationalCommitteeProgramStartNationalAffairEvent.tooltip.caption", "Start Behandlung in Kommission", "messages", "de");
t.add("affair.nationalCommitteePressReleaseNationalAffairEvent.tooltip.caption", "Medienmitteilung Kommission", "messages", "de");
t.add("affair.nationalHistoryeventCommitteeProgramEnd.tooltip.caption", "Geplant: Behandlung in Kommission (Ende)", "messages", "de");
t.add("affair.nationalCommitteeProgramEndNationalAffairEvent.tooltip.caption", "Geplant: Behandlung in Kommission (Ende)", "messages", "de");
t.add("affair.nationalSessionProgramNationalAffairEvent.tooltip.caption", "Nationales Sessionsprogramm", "messages", "de");
t.add("affair.nationalCommitteeProgramNationalAffairEvent.tooltip.caption", "Nationales Kommissionsprogramm", "messages", "de");
t.add("affair.NationalAffairInternalVoteCustomEvent.tooltip.caption", "Interne Abstimmung", "messages", "de");
t.add("affair.nationalHistoryeventCommitteeProgramRemoved.tooltip.caption", "Aus Kommissionsprogramm entfernt", "messages", "de");
t.add("user.password.isPwned", "Passwort unsicher. Bitte w\u00e4hlen Sie ein sicheres Passwort.", "messages", "de");
t.add("cantonal.overview.sessions", "Sitzungen \/ Sessionen Parlament", "messages", "de");
t.add("cantonal.listSessions.title", "Sitzungen \/ Sessionen Parlament", "messages", "de");
t.add("cantonal.sessions.table.date", "Datum", "messages", "de");
t.add("cantonal.sessions.table.canton", "Kanton", "messages", "de");
t.add("cantonal.sessions.table.link", "Link Quelle", "messages", "de");
t.add("cantonal.sessions.table.events", "Anzahl Gesch\u00e4fte", "messages", "de");
t.add("cantonal.sessions.title", "Detail zu Session \/ Sitzung", "messages", "de");
t.add("forms.speakers.input.councillor.cantonal", "Referent\/in", "messages", "de");
t.add("councils.nr", "im Nationalrat", "messages", "de");
t.add("councils.sr", "im St\u00e4nderat", "messages", "de");
t.add("cantonal.session.events.empty", "Keine Gesch\u00e4fte", "messages", "de");
t.add("api.cantonal.sessions.get.error", "Fehler beim Abrufen der kantonalen Sessionen\/Sitzungen", "messages", "de");
t.add("cantonal.faction.affairs.title", "Fraktionsgesch\u00e4fte", "messages", "de");
t.add("cantonal.affairs.year.select.label", "Auswahl Jahr", "messages", "de");
t.add("cantonal.consultations.attachments", "Anh\u00e4nge", "messages", "de");
t.add("affair.CantonalAffairConsultationEndEvent.tooltip.caption", "Ende kantonale Vernehmlassung", "messages", "de");
t.add("affair.CantonalAffairConsultationStartEvent.tooltip.caption", "Start kantonale Vernehmlassung", "messages", "de");
t.add("cantonal.consultations.description.header", "Beschreibung", "messages", "de");
t.add("cantonal.consultations.get.error", "Fehler beim Abrufen der kantonalen Vernehmlassungen", "messages", "de");
t.add("dashboard.updates.itemType.cantonalAffairConsultationInternalNote", "Interne Notiz kantonale Vernehmlassung", "messages", "de");
t.add("links.edit_form.input.entity.placeholder", "Gesch\u00e4ft ausw\u00e4hlen...", "messages", "de");
t.add("ui.forms.confirm.title", "Titel best\u00e4tigt", "messages", "de");
t.add("event.reminders.create.success", "Erinnerung erfolgreich gespeichert", "messages", "de");
t.add("cantonal.overview.consultations.planned", "Vernehmlassungen: Geplant", "messages", "de");
t.add("cantonal.overview.consultations.in_progress", "Vernehmlassungen: Laufende", "messages", "de");
t.add("cantonal.overview.consultations.closed", "Vernehmlassungen: Abgeschlossene", "messages", "de");
t.add("cantonal.consultations.overview.title", "Kantonale Vernehmlassungen", "messages", "de");
t.add("cantonal.consultations.list", "Kantonale Vernehmlassungen", "messages", "de");
t.add("settings.company.affair_meetings_show.label", "Sitzungen anzeigen \/ verwenden?", "messages", "de");
t.add("userGroups.builtIn.companyAdmins.title", "Administrator", "messages", "de");
t.add("userGroups.builtIn.ro.title", "Gruppe Read-Only", "messages", "de");
t.add("userGroups.builtIn.rw.title", "Gruppe Lesen\/Schreiben", "messages", "de");
t.add("committee.press_releases.table.header.title", "Titel", "messages", "de");
t.add("committee.press_releases.table.header.date", "Datum", "messages", "de");
t.add("committee.press_releases.table.header.link", "Link", "messages", "de");
t.add("cantonal.councillors.table.name", "Name", "messages", "de");
t.add("cantonal.councillors.table.party", "Partei", "messages", "de");
t.add("cantonal.councillors.table.faction", "Fraktion", "messages", "de");
t.add("affair.nationalCommitteeAffair.tooltip.caption", "Gesch\u00e4ft Kommission", "messages", "de");
t.add("affair.nationalCommitteeProgramRemovedNationalAffairEvent.tooltip.caption", "Gesch\u00e4ft von Kommissionsprogramm entfernt", "messages", "de");
t.add("affairGroup.export.header.internalFields", "Benutzerdefinierte Felder", "messages", "de");
t.add("timeline.settings.order.asc", "aufsteigend sortieren", "messages", "de");
t.add("timeline.settings.order.desc", "absteigend sortieren", "messages", "de");
t.add("reports.nationalSessions.label", "Session", "messages", "de");
t.add("reports.format.docx", "Word DOCX", "messages", "de");
t.add("reports.form.error.oneAffairGroupOrNationalSessio", "Bitte w\u00e4hlen Sie mindestens eine Gruppe oder eine Session.", "messages", "de");
t.add("timeline.settings.order", "Sortierung", "messages", "de");
t.add("committee.press_releases.table.header.author", "Urheber", "messages", "de");
t.add("dashboard.updates.itemType.nationalAffairConsultationInternalNote", "Notiz Nationale Vernehmlassung", "messages", "de");
t.add("resetting.check_email", "Sofern der Login existiert, wurde der Link zum Zur\u00fccksetzen des Passworts zugestellt.", "messages", "de");
t.add("reports.nationalsessions.placeholder", "Keine Auswahl getroffen...", "messages", "de");
t.add("reports.nationSessions.label", "Session", "messages", "de");
t.add("api.keywords.update.success", "Suchbegriffe erfolgreich aktualisiert.", "messages", "de");
t.add("ui.select.user_groups.label", "Benutzergruppen", "messages", "de");
t.add("reports.nationalSessionProgram.title", "Sessions-Bericht", "messages", "de");
t.add("reports.nationalSessionProgram.affairInGroups", "Gruppe", "messages", "de");
t.add("reports.nationalSessionProgram", "Sessions-Bericht", "messages", "de");
t.add("layout.affair_groups.created", "Gruppe erstellt", "messages", "de");
t.add("form.label.delete", "L\u00f6schen", "messages", "de");
t.add("affair.statusChangeCantonalAffairEvent.tooltip.caption", "Status\u00e4nderung", "messages", "de");
t.add("ui.table.header.actions.group_assign.description", "Gruppe(n) zuweisen", "messages", "de");
t.add("ui.table.header.actions.group_assign.manage_groups", "Gruppen bearbeiten", "messages", "de");
t.add("ui.table.header.actions.group_assign", "Gruppen hinzuf\u00fcgen", "messages", "de");
t.add("api.user.edit.error", "Fehler beim Speichern", "messages", "de");
t.add("api.user.edit.success", "Erfolgreich ge\u00e4ndert", "messages", "de");
t.add("reports.form.error.oneAffairGroupOrNationalSession", "Bitte w\u00e4hlen Sie eine Gruppe oder Session", "messages", "de");
t.add("cantonal.parties.table.party.label", "Partei", "messages", "de");
t.add("cantonal.parties.table.councillors.label", "Parlamentarier", "messages", "de");
t.add("timeline.actions.expand", "Aufklappen", "messages", "de");
t.add("ui.table.filter.empty", "kein Wert gesetzt", "messages", "de");
t.add("cantonal.consultations.details.title", "Kantonale Vernehmlassung", "messages", "de");
t.add("affair_list.search", "Suche", "messages", "de");
t.add("timeline.actions.collapse", "einklappen", "messages", "de");
t.add("profile.show.actions.download_agb", "AGB herunterladen", "messages", "de");
t.add("committee.events.title", "Kommission", "messages", "de");
t.add("committee.events.table.header.date", "Datum", "messages", "de");
t.add("committee.events.table.header.title", "Titel", "messages", "de");
t.add("committee.events.table.header.status", "Status", "messages", "de");
t.add("ui.date_picker.calendar.options.clear", "zur\u00fccksetzen", "messages", "de");
t.add("reports.groups.outputLinkedAffairs.label", "Verkn\u00fcpfte Gesch\u00e4fte ausgeben", "messages", "de");
t.add("reports.groups.outputLinkedAffairs.none", "nicht ausgeben", "messages", "de");
t.add("reports.groups.outputLinkedAffairs.reference", "nur mit Referenz", "messages", "de");
t.add("reports.groups.outputLinkedAffairs.full", "vollst\u00e4ndig", "messages", "de");
t.add("reports.affair.section.linkedAffairs.title", "Verkn\u00fcpfte Gesch\u00e4fte", "messages", "de");
t.add("reports.affair.section.customLinkedAffairs.title", "Intern verkn\u00fcpfte Gesch\u00e4fte", "messages", "de");
t.add("ui.date_picker.calendar.input_end.label", "Enddatum", "messages", "de");
t.add("ui.date_picker.calendar.input_start.label", "Start-Datum", "messages", "de");
t.add("cantonal.councillors.table.first_name", "Vorname", "messages", "de");
t.add("cantonal.councillors.table.last_name", "Nachname", "messages", "de");
t.add("reports.groups.outputEmptyGroups.label", "Gruppen ohne Inhalt", "messages", "de");
t.add("reports.groups.outputId.label", "ID", "messages", "de");
t.add("reports.groups.outputCanton.label", "Kanton", "messages", "de");
t.add("reports.groups.outputTitle.label", "Titel", "messages", "de");
t.add("reports.groups.outputAuthors.label", "Urheber", "messages", "de");
t.add("reports.groups.outputDepartment.label", "Departement", "messages", "de");
t.add("reports.groups.outputDepositDate.label", "Einreichungsdatum", "messages", "de");
t.add("reports.groups.outputLastEventDate.label", "Datum letztes Ereignis", "messages", "de");
t.add("reports.depositDate.label", "Einreichungsdatum", "messages", "de");
t.add("reports.lastEventDate.label", "Datum letztes Ereignis", "messages", "de");
t.add("reports.affairTypes.label", "Datenbereich", "messages", "de");
t.add("reports.affairTypes.nationalAffair", "Nationale Gesch\u00e4fte", "messages", "de");
t.add("reports.affairTypes.nationalAffairConsultation", "Nationale Vernehmlassungen", "messages", "de");
t.add("reports.affairTypes.cantonalAffairConsultation", "Kantonale Vernehmlassungen", "messages", "de");
t.add("reports.affairTypes.cantonalAffair", "Kantonale Gesch\u00e4fte", "messages", "de");
t.add("reports.affairTypes.customAffair", "Interne Gesch\u00e4fte", "messages", "de");
t.add("reports.affair.depositDate", "Einreichungsdatum", "messages", "de");
t.add("reports.affair.lastEventDate", "Datum letztes Ereignis", "messages", "de");
t.add("profile.settings.company.user_groups.label", "Berechtigungen \/ Benutzergruppen", "messages", "de");
t.add("profile.settings.user_groups.user_select", "Zugewiesene Benutzer", "messages", "de");
t.add("profile.settings.company.user_groups.add", "Neue Benutzergruppe", "messages", "de");
t.add("reports.customAffair.type", "Internes Gesch\u00e4ft", "messages", "de");
t.add("reports.affair.authors", "Urheber", "messages", "de");
t.add("reports.affair.department", "Departement", "messages", "de");
t.add("reports.section.customHistory", "Interne Notizen", "messages", "de");
t.add("reports.section.internalFields", "Benutzerdefinierte Felder", "messages", "de");
t.add("dashboard.updates.itemType.cantonalAffairConsultation", "Kantonale Vernehmlassung", "messages", "de");
t.add("dashboard.updates.itemType.nationalAffairInternalNote", "Notiz", "messages", "de");
t.add("profile.settings.company.terms_of_service.label", "Hinweis-Fenster Startseite", "messages", "de");
t.add("dashboard.terms_of_service.label", "Willkommen! Klicken Sie hier, um den Begr\u00fcssungstext und die AGB zu lesen.", "messages", "de");
t.add("groups.tree.sort.alphabetical", "alphabetisch", "messages", "de");
t.add("groups.tree.sort.date", "Datum", "messages", "de");
t.add("customMeetings.tree.sort.alphabetical", "alphabetisch", "messages", "de");
t.add("customMeetings.tree.sort.date", "Datum", "messages", "de");
t.add("calendar.type.select.option.full", "Kalender-Ansicht", "messages", "de");
t.add("calendar.type.select.option.day", "Listen-Ansicht", "messages", "de");
t.add("calendar.day.load_history_events", "Fr\u00fchere Eintr\u00e4ge laden", "messages", "de");
t.add("calendar.day.events.no_more", "Keine weiteren Eintr\u00e4ge", "messages", "de");
t.add("consultation.submittedByOn", "Eingereicht von %councillors%. Er\u00f6ffnung am %date%", "messages", "de");
t.add("calendar.type.select.label", "Ansicht", "messages", "de");
t.add("dashboard.updates.itemType.customAffairInternalNote", "Notiz", "messages", "de");
t.add("affair.cantonalSessionProgramStartCantonalAffairEvent.tooltip.caption", "Aufnahme Sessionsprogramm\/Tagesordnung", "messages", "de");
t.add("affair.event.date.label", "Datum", "messages", "de");
t.add("ui.table.filter.all", "alle ausw\u00e4hlen", "messages", "de");
t.add("ui.table.filter.clear", "keine ausw\u00e4hlen", "messages", "de");
t.add("ui.reject", "Abbrechen", "messages", "de");
t.add("layout.affair_groups.description", "Beschreibung", "messages", "de");
t.add("draft.title", "Entwurf", "messages", "de");
t.add("reports.cantonalsessions.placeholder", "Kantonale Session\/Sitzung w\u00e4hlen...", "messages", "de");
t.add("reports.cantonal.sessions", "Kantonale Session\/Sitzung", "messages", "de");
t.add("api.groups.delete.success", "Gruppe erfolgreich gel\u00f6scht", "messages", "de");
t.add("affair_groups_sorting.label", "Gruppen: Standard-Sortierung", "messages", "de");
t.add("affair_groups_sorting.default", "Standard-Einstellung", "messages", "de");
t.add("affair_groups_sorting.alphabetical", "alphabetisch sortieren", "messages", "de");
t.add("affair_groups_sorting.custom", "benutzerdefiniert sortieren", "messages", "de");
t.add("affair_groups_sorting.date", "Nach Erstelldatum sortieren", "messages", "de");
t.add("profile.settings.company.terms_of_service_title.label", "Titel", "messages", "de");
t.add("national.affair.drafts.title", "Entw\u00fcrfe", "messages", "de");
t.add("ui.table.header.actions.size.label", "Max. Anzahl Elemente anzeigen", "messages", "de");
t.add("list_size.label", "Anzahl Eintr\u00e4ge", "messages", "de");
t.add("event.reminders.delete.success", "Erinnerung erfolgreich gel\u00f6scht", "messages", "de");
t.add("reports.internal_fields.label", "Benutzerdefinierte Felder", "messages", "de");
t.add("affair.preConsultation.tooltip.caption", "Vorberatung", "messages", "de");
t.add("reports.sessionProgram.affairInGroups", "Gruppe", "messages", "de");
t.add("profile.show.list_size", "Standard Listenl\u00e4nge", "messages", "de");
t.add("profile.edit.logo.download", "Profil-Bild herunterladen", "messages", "de");
t.add("api.sessions.get.error", "Fehler beim Abruf der Sessionen. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("reports.source.output_format", "Ausgabe-Format", "messages", "de");
t.add("reports.config.save.form.title", "Berichtsvorlage aktualisieren", "messages", "de");
t.add("reports.config.form.description", "Speichern Sie die Berichtseinstellungen in einer Berichtsvorlage:", "messages", "de");
t.add("reports.config.form.title.input.label", "Bezeichnung", "messages", "de");
t.add("reports.source.elements", "Elemente", "messages", "de");
t.add("reports.config.form.output_format", "Ausgabe-Format", "messages", "de");
t.add("reports.title.label", "Titel", "messages", "de");
t.add("reports.date.label", "Datum", "messages", "de");
t.add("reports.config.save", "Berichtsvorlage aktualisieren", "messages", "de");
t.add("api.reports.config.post.success", "Berichtsvorlage erfolgreich gespeichert", "messages", "de");
t.add("api.reports.config.post.error", "Fehler beim Speichern der Berichtsvorlage. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("ui.select_multiple.placeholder", "Nichts ausgew\u00e4hlt", "messages", "de");
t.add("reports.nationalAffairConsultation.status.closed", "abgeschlossen", "messages", "de");
t.add("reports.nationalAffairConsultation.in_progres", "laufend", "messages", "de");
t.add("affair.custom.submittedByOn", "Erstellt", "messages", "de");
t.add("affair.CouncilStatesVote", "Abstimmung im St\u00e4nderat", "messages", "de");
t.add("affair.NationalCouncilVote", "Abstimmung im Nationalrat", "messages", "de");
t.add("profile.settings.nationalNotificationPreferences.NationalCouncilVotes", "Abstimmungen Nationalrat", "messages", "de");
t.add("profile.settings.nationalNotificationPreferences.CouncilStatesVotes", "Abstimmungen St\u00e4nderat", "messages", "de");
t.add("affair.CouncilStatesVote.tooltip.caption", "Abstimmung St\u00e4nderat", "messages", "de");
t.add("affair.NationalCouncilVote.tooltip.caption", "Abstimmung Nationalrat", "messages", "de");
t.add("affair.overview.NationalCouncilVote.title", "Abstimmung Nationalrat", "messages", "de");
t.add("affair.overview.CouncilStatesVote.title", "Abstimmung St\u00e4nderat", "messages", "de");
t.add("affair.StatusChangeCantonalAffairEvent.tooltip.caption", "Status\u00e4nderung", "messages", "de");
t.add("dashboard.updates.itemType.CouncilStatesVote", "Abstimmung St\u00e4nderat", "messages", "de");
t.add("dashboard.updates.itemType.NationalCouncilVote", "Abstimmung Nationalrat", "messages", "de");
t.add("affair.NationalAffairDraftResolution.tooltip.caption", "Entwurf Dokument", "messages", "de");
t.add("affair.PreConsultationNationalAffairEvent.tooltip.caption", "Vorberatung", "messages", "de");
t.add("affair.StatusChangeNationalAffairEvent.tooltip.caption", "Status ge\u00e4ndert", "messages", "de");
t.add("affair.NationalAffairDraftReference.tooltip.caption", "Entwurf Dokument", "messages", "de");
t.add("affair.NationalSessionProgramNationalAffairEvent.tooltip.caption", "Geplant: Behandlung in Session", "messages", "de");
t.add("affair.NationalCouncilDebateEvent.tooltip.caption", "Debatte NR", "messages", "de");
t.add("affair.FederalCouncilProposalNationalAffairEvent.tooltip.caption", "Entwurf Bundesrat", "messages", "de");
t.add("affair.NationalCommitteePressReleaseNationalAffairEvent.tooltip.caption", "Medienmitteilung Kommission", "messages", "de");
t.add("affair.NationalAffairConsultationEndEvent.tooltip.caption", "Ende Vernehmlassung", "messages", "de");
t.add("affair.NationalAffairConsultationStartEvent.tooltip.caption", "Start Vernehmlassung", "messages", "de");
t.add("affair.NationalCommitteeProgramEndNationalAffairEvent.tooltip.caption", "Geplant: Behandlung in Kommission (Ende)", "messages", "de");
t.add("affair.NationalCommitteeProgramStartNationalAffairEvent.tooltip.caption", "Geplant: Behandlung in Kommission", "messages", "de");
t.add("affair.NationalCommitteeProgramRemovedNationalAffairEvent.tooltip.caption", "Aus Kommissionsprogramm entfernt", "messages", "de");
t.add("affair.CantonalSessionProgramStartCantonalAffairEvent.tooltip.caption", "Aufnahme Sessionsprogramm\/Tagesordnung", "messages", "de");
t.add("layout.search.content_type", "Typ", "messages", "de");
t.add("reports.source.content", "Welche Gestaltungselemente soll dieser Bericht haben?", "messages", "de");
t.add("national.affairs.list.header.date", "Datum Einreichung", "messages", "de");
t.add("affair.NationalAffairConsultationSpanEvent.tooltip.caption", "Nationale Vernehmlassung: Zeitraum", "messages", "de");
t.add("affair.CantonalAffairConsultationSpanEvent.tooltip.caption", "Zeitraum kantonale Vernehmlassung", "messages", "de");
t.add("ui.error.crash.title", "Unerwarteter Fehler aufgetreten", "messages", "de");
t.add("ui.error.crash.message", "Leider ist ein Fehler aufgetreten. Bitte melden Sie sich mit weiteren Details beim Support: support@politik.ch", "messages", "de");
t.add("ui.error.crash.goto_dashboard", "zum Dashboard", "messages", "de");
t.add("ui.error.crash.reload", "Diese Seite neu laden", "messages", "de");
t.add("api.2fa.put.success", "Einstellungen Zwei-Faktor-Authentifizierung erfolgreich gespeichert.", "messages", "de");
t.add("profile.edit.2fa.title", "Zwei-Faktor-Authentifizierung (2FA)", "messages", "de");
t.add("profile.edit.2fa.google.secret_received", "Schl\u00fcssel", "messages", "de");
t.add("api.2fa.put.error", "Fehler beim Lesen der API (2FA-Auth)", "messages", "de");
t.add("profile.settings.password.error.match", "Passw\u00f6rter stimmen nicht \u00fcberein", "messages", "de");
t.add("profile.settings.password.error.api", "Das Passwort ist unsicher. Bitte w\u00e4hlen Sie ein anderes Passwort.", "messages", "de");
t.add("profile.password_reset.form.title", "Passwort neu setzen", "messages", "de");
t.add("layout.search.orderBy.canton", "Kanton", "messages", "de");
t.add("layout.search.show_advanced", "Weitere Optionen anzeigen", "messages", "de");
t.add("layout.search.hide_advanced", "Weitere Optionen verbergen", "messages", "de");
t.add("layout.search.filters.internal_fields", "Benutzerdefinierte Felder", "messages", "de");
t.add("profile.password.submit", "Passwort speichern", "messages", "de");
t.add("profile.settings.password.error.format", "Passwort unsicher. Bitte w\u00e4hlen Sie mindestens 12 Zeichen.", "messages", "de");
t.add("customMeetings.delete.message", "Wollen Sie die Sitzung wirklich l\u00f6schen?", "messages", "de");
t.add("api.meetings.delete.success", "Sitzung erfolgreich gel\u00f6scht", "messages", "de");
t.add("api.meetings.delete.error", "Fehler beim L\u00f6schen der Sitzung.", "messages", "de");
t.add("layout.custom_meetings.time", "Sitzungsdatum", "messages", "de");
t.add("customMeetings.add_agenda.title", "Neues Traktandum erstellen", "messages", "de");
t.add("layout.custom_meetings.description", "Beschreibung", "messages", "de");
t.add("customMeetings.edit.title", "Bearbeiten", "messages", "de");
t.add("customMeetings.agenda_points.card.title", "Traktanden", "messages", "de");
t.add("customMeetings.participants.card.title", "Teilnehmer", "messages", "de");
t.add("api.affairs.get.error", "Fehler beim Abrufen der Gesch\u00e4fte.", "messages", "de");
t.add("customMeetings.agenda_points.empty", "Keine Traktanden erfasst", "messages", "de");
t.add("dashboard.updates.itemType.cantonalAffairInternalNote", "Notiz kantonales Gesch\u00e4ft", "messages", "de");
t.add("national.affairs.list.header.canton", "Kanton", "messages", "de");
t.add("national.affairs.list.header.actions", "Aktion", "messages", "de");
t.add("calendar.nav.prev", "zur\u00fcck", "messages", "de");
t.add("calendar.nav.next", "weiter", "messages", "de");
t.add("calendar.nav.today", "Heute", "messages", "de");
t.add("ui.select_input.options.none", "Nichts ausgew\u00e4hlt", "messages", "de");
t.add("reports.source.filters", "Filter", "messages", "de");
t.add("reports.config.save_as", "Neue Berichtsvorlage speichern", "messages", "de");
t.add("reports.affair.link", "Link", "messages", "de");
t.add("reports.affair.codeAndType", "Nr. \/ Art", "messages", "de");
t.add("reports.format.oldDocx", "Word DOCX", "messages", "de");
t.add("reports.format.oldPdf", "PDF", "messages", "de");
t.add("reports.tableOfContents", "Inhaltsverzeichnis", "messages", "de");
t.add("reports.groups.outputTableOfContents.label", "Inhaltsverzeichnis", "messages", "de");
t.add("reports.tableofcontents", "Inhaltsverzeichnis", "messages", "de");
t.add("api.legislative_periods.get.error", "Fehler beim Laden der Legislatur. Bitte kontaktieren Sie den Support.", "messages", "de");
t.add("api.councillor.stats.get.error", "Fehler beim Laden der Parlamentarier-Statistik. Bitte kontaktieren Sie den Support.", "messages", "de");
t.add("reports.config.delete", "Berichtsvorlage l\u00f6schen", "messages", "de");
t.add("reports.config.load", "Berichtsvorlagen", "messages", "de");
t.add("affair.NationalCommitteeProgramSpanNationalAffairEvent.tooltip.caption", "Status\u00e4nderung", "messages", "de");
t.add("affair.CustomAffairNoteCustomEvent.tooltip.caption", "Interne Notiz", "messages", "de");
t.add("api.file.delete.error", "Fehler beim L\u00f6schen des Bildes. Bitte kontaktieren Sie den Support.", "messages", "de");
t.add("reports.cantonalSessionProgram.title", "Kantonales Sessionsprogramm", "messages", "de");
t.add("affair.nationalAffairConsultationSpanEvent.tooltip.caption", "Nationale Vernehmlassung: Zeitraum", "messages", "de");
t.add("profile.settings.reports.affair.template", "Standard Berichtsvorlage Gesch\u00e4ft", "messages", "de");
t.add("api.reports.get.error", "Fehler beim Abrufen der Berichte. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("profile.settings.reports.affair.default", "Standard-Bericht ausw\u00e4hlen...", "messages", "de");
t.add("profile.edit.pictureFile.select", "Bild ausw\u00e4hlen...", "messages", "de");
t.add("profile.edit.pictureFile.not_selected", "Kein Bild ausgew\u00e4hlt.", "messages", "de");
t.add("affair.title.report.download", "Gesch\u00e4ft exportieren \/ herunterladen", "messages", "de");
t.add("profile.settings.commpany.user.delete.confirm", "Wirklich l\u00f6schen:", "messages", "de");
t.add("affair.NationalAffairNoteCustomEvent.tooltip.caption", "Notiz", "messages", "de");
t.add("affair.FederalCouncilResponseNationalAffairEvent.tooltip.caption", "Antwort des Bundesrates", "messages", "de");
t.add("affair.NationalCommitteeAffair.tooltip.caption", "Nationales Kommissionsgesch\u00e4ft", "messages", "de");
t.add("customMeetings.tree.customMeeting.goto", "Detail-Ansicht", "messages", "de");
t.add("reports.affair", "Einzelnes Gesch\u00e4ft", "messages", "de");
t.add("reports.affair.title", "Details zu Gesch\u00e4ft", "messages", "de");
t.add("api.reports.config.put.success", "Berichtsvorlage erfolgreich gespeichert.", "messages", "de");
t.add("reports.config.delete.confirm.message", "Wollen Sie die Berichtsvorlage wirklich l\u00f6schen?", "messages", "de");
t.add("forms.custom_note_event.delete.success", "Notiz erfolgreich gel\u00f6scht", "messages", "de");
t.add("reports.affairSelect.placeholder", "Gesch\u00e4ft ausw\u00e4hlen...", "messages", "de");
t.add("reports.affairSelect.label", "Gesch\u00e4ft", "messages", "de");
t.add("profile.settings.company.user_groups.titleDe", "Gruppen-Name De", "messages", "de");
t.add("profile.settings.company.user_groups.titleFr", "Gruppen-Name Fr", "messages", "de");
t.add("profile.settings.company.user_groups.titleIt", "Gruppen-Name It", "messages", "de");
t.add("user.company.settings.user_group.roles", "Berechtigungen", "messages", "de");
t.add("customDataRoles.customEvents", "interne Ereignisse", "messages", "de");
t.add("customDataRoles.customSpeakers", "Referenten", "messages", "de");
t.add("customDataRoles.affairGroups", "Gruppen", "messages", "de");
t.add("customDataRoles.customAffairs", "interne Gesch\u00e4fte", "messages", "de");
t.add("customDataRoles.meetings", "Sitzungen", "messages", "de");
t.add("customDataRoles.customLinks", "Verlinkte Gesch\u00e4fte", "messages", "de");
t.add("customDataRoles.internalFields", "Benutzerdefinierte Felder", "messages", "de");
t.add("customDataRoles.mediaObjects", "Dateien \/ Anh\u00e4nge", "messages", "de");
t.add("customDataRoles.userGroupItems", "Berechtigungen von Gesch\u00e4ftseintr\u00e4gen (Notizen etc.)", "messages", "de");
t.add("draft_0.title", "Botschaft \/ Bericht des Bundesrates", "messages", "de");
t.add("api.reports.confi.put.success", "Berichtsvorlage erfolgreich gespeichert", "messages", "de");
t.add(" api.reports.confi.delete.success", "Berichtsvorlage erfolgreich gel\u00f6scht", "messages", "de");
t.add("reports.affairSelect.placeholde\ufffcr", "Gesch\u00e4ft ausw\u00e4hlen...", "messages", "de");
t.add("affair.internalNote.edit", "Bearbeiten", "messages", "de");
t.add("affair.CantonalAffairInternalVoteCustomEvent.tooltip.caption", "Interne Abstimmung", "messages", "de");
t.add("profile.password.new.label", "Neues Passwort", "messages", "de");
t.add("user.company.settings.user_group.role.read", "Lesen", "messages", "de");
t.add("user.company.settings.user_group.role.write", "Schreiben", "messages", "de");
t.add("user.company.settings.user_group.role.none", "Keine", "messages", "de");
t.add("author.national_committee", "Urheber: Kommission", "messages", "de");
t.add("layout.search.nationalCommitteesWithPrograms", "Kommissionsprogramm", "messages", "de");
t.add("layout.search.nationalSessions", "Session Bund", "messages", "de");
t.add("reports.committee", "Kommissions-Bericht", "messages", "de");
t.add("groups.tree.group.deleteAffair.confirm", "M\u00f6chten Sie das Gesch\u00e4ft wirklich aus der Gruppe entfernen?", "messages", "de");
t.add("reports.nationalCommitteesPrograms.title", "Kommissions-Bericht", "messages", "de");
t.add("reports.cantonalSessionPrograms.label", "Kantonale Session", "messages", "de");
t.add("Reports.committee", "Kommissions-Bericht", "messages", "de");
t.add("api.timelines.get.error", "Fehler beim Abrufen der Zeitachse. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("api.parties.get.error", "Fehler beim Laden der Parteien. Melden Sie sich bitte beim Support.", "messages", "de");
t.add("api.factions.get.error", "Fehler beim Laden der Fraktionen. Melden Sie sich bitte beim Support.", "messages", "de");
t.add("api.parties.stats.get.error", "Fehler beim Laden der Partei-Statistiken. Melden Sie sich bitte beim Support.", "messages", "de");
t.add("api.factions.stats.get.error", "Fehler beim Laden der Fraktions-Statistiken. Melden Sie sich bitte beim Support.", "messages", "de");
t.add("affairGroup.archive", "Gruppe archivieren", "messages", "de");
t.add("affairGroup.archive.confirm", "Wollen Sie diese Gruppe wirklich archivieren?", "messages", "de");
t.add("customMeetings.archive", "Sitzung archivieren", "messages", "de");
t.add("customMeetings.archive.confirm", "Wollen Sie die Sitzung wirklich archivieren?", "messages", "de");
t.add("layout.search.subscribed.label", "Nur abonnierte Gesch\u00e4fte ber\u00fccksichtigen", "messages", "de");
t.add("Schreiben", "user.company.settings.user_group.role.write", "messages", "de");
t.add("api.groups.error.affair_delete", "Fehler beim L\u00f6schen von Gesch\u00e4ftszuweisungen einer Gruppe. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("event.reminders.error.get", "Fehler beim Laden der Erinnerungen. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("api.committees.get.error", "Fehler beim Laden der Kommissionen. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("affairGroups.archive", "Archivieren", "messages", "de");
t.add("menu.company.choose", "Organisation\/Firma wechseln", "messages", "de");
t.add("api.user.companies.get.error", "Fehler beim Laden der Organisationen\/Firmen. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("user.company.set.form.title", "Organisation\/Firma w\u00e4hlen", "messages", "de");
t.add("reports.title", "Einen Bericht erstellen", "messages", "de");
t.add("reports.sections.type.title", "Welchen Bericht wollen Sie erstellen?", "messages", "de");
t.add("reports.sections.groups.title", "Aus welchen Gruppen soll der Bericht erstellt werden?", "messages", "de");
t.add("reports.sections.official.title", "Welche offiziellen Angaben sollen die Gesch\u00e4fte im Bericht enthalten?", "messages", "de");
t.add("reports.sections.custom.title", "Welche eigenen Inhalte zu den Gesch\u00e4ften sollen im Bericht enthalten sein?", "messages", "de");
t.add("reports.sections.filters.title", "Welche Filter sollen f\u00fcr den Bericht angewendet werden?", "messages", "de");
t.add("reports.sections.format.title", "Welches Ausgabeformat soll der Bericht haben?", "messages", "de");
t.add("reports.sections.basic.title", "Welche Gestaltungselemente soll dieser Bericht haben?", "messages", "de");
t.add("reports.sections.presets.title", "Wollen Sie die Einstellungen dieses Berichts als Berichtsvorlage speichern?", "messages", "de");
t.add("reports.sections.submit.title", "Sind Sie bereit den Bericht zu generieren?", "messages", "de");
t.add("security.saml.loginError", "Fehler beim Login mit SAML.", "messages", "de");
t.add("author.CantonalCouncillor", "Autor\/in kantonales Gesch\u00e4ft", "messages", "de");
t.add("reports.action.header", "Gespeicherte Berichtsvorlagen", "messages", "de");
t.add("reports.action.label", "Aktuelle Vorlage", "messages", "de");
t.add("user.company.select_input.label", "Organisation\/Firma w\u00e4hlen", "messages", "de");
t.add("layout.search.contentType.custom_affair", "Interne Gesch\u00e4fte", "messages", "de");
t.add("footer.label", "Impressum und Datenschutz", "messages", "de");
t.add("reports.author.label", "Autor\/in", "messages", "de");
t.add("reports.subtitle.label", "Vorbemerkung", "messages", "de");
t.add("file_upload.no_file", "kein Logo hochgeladen", "messages", "de");
t.add("file_upload.btn_label", "Logo hochladen", "messages", "de");
t.add("reports.national_session", "Nationaler Sessionsbericht", "messages", "de");
t.add("reports.national_committee", "Nationaler Kommissionsbericht", "messages", "de");
t.add("profile.settings.company.title", "Einstellungen Organisation\/Firma", "messages", "de");
t.add("profile.settings.title", "Profil-Einstellungen", "messages", "de");
t.add("profile.settings.navigation.user_company_custom_fields", "Benutzerdefinierte Felder", "messages", "de");
t.add("profile.settings.navigation.user_company_user_groups", "Berechtigungen \/ Benutzergruppen", "messages", "de");
t.add("NoteCustomEvent.is_private.label", "private Notiz", "messages", "de");
t.add("profile.settings.company.shortcut", "E", "messages", "de");
t.add("profile.settings.company.affair_edit.label", "Bearbeiten Organisation\/Firma", "messages", "de");
t.add("reports.section.main_section", "Welche Kommissionen sollen ber\u00fccksichtigt werden?", "messages", "de");
t.add("customMeetings.meetingAgendaPoint.type.affairsWithSpeakerWithoutCustomNote.label", "Gesch\u00e4fte mit Referent\/in aber ohne Notizen", "messages", "de");
t.add("councillor.language.D", "Deutsch", "messages", "de");
t.add("councillor.language.F", "Franz\u00f6sisch", "messages", "de");
t.add("councillor.language.I", "Italienisch", "messages", "de");
t.add("api.reports.config.delete.success", "Berichtsvorlage erfolgreich gel\u00f6scht", "messages", "de");
t.add("profile.settings.company.meetings", "Sitzungen", "messages", "de");
t.add("ui.select.all", "Alle ausw\u00e4hlen", "messages", "de");
t.add("affairs.customEvents.is_private.label", "private Notiz (nur f\u00fcr mich sichtbar)", "messages", "de");
t.add("reports.national_council.label", "Referent\/in Nationalrat", "messages", "de");
t.add("reports.states_council.label", "Referent\/in St\u00e4nderat", "messages", "de");
t.add("ui.text_editor.link_label", "Link:", "messages", "de");
t.add("ui.text_editor.save_btn", "Speichern", "messages", "de");
t.add("not_found.title", "Die Seite, die Sie suchen, konnte leider nicht gefunden werden.", "messages", "de");
t.add("not_found.subtitle", "Die Seite, die Sie suchen, konnte nicht gefunden werden. Es kann sein, dass sie verschoben oder gel\u00f6scht wurde.", "messages", "de");
t.add("not_found.link.label", "Zur\u00fcck", "messages", "de");
t.add("layout.search.affairStatesNational", "Letztes Ereignis", "messages", "de");
t.add("vote.id", "Abstimmungs-Nr.", "messages", "de");
t.add("api.subscriptions.get.error", "Fehler beim Abrufen von abonnierten Gesch\u00e4ften. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("api.subscriptions.delete.error", "Fehler beim entfernen von abonnierten Gesch\u00e4ften. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("api.subscriptions.post.error", "Fehler beim Speichern von abonnierten Gesch\u00e4ften. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("reports.meeting_protocol", "Sitzungs-Protokolle", "messages", "de");
t.add("reports.meeting_invitation", "Sitzungs-Einladungen", "messages", "de");
t.add("reports.sections.meeting_invitation.title", "F\u00fcr welche Sitzung m\u00f6chten Sie die Einladung erstellen?", "messages", "de");
t.add("reports.sections.meeting_protocol.title", "F\u00fcr welche Sitzung m\u00f6chten Sie das Protokoll erstellen?", "messages", "de");
t.add("profile.settings.reports.invitation.template", "Standard Berichtsvorlage Sitzungs-Einladung", "messages", "de");
t.add("profile.settings.reports.protocol.template", "Standard Berichtsvorlage Sitzungs-Protokoll", "messages", "de");
t.add("select_input.not_selected", "Noch nichts ausgew\u00e4hlt...", "messages", "de");
t.add("layout.search.meetings.label", "Sitzung ausw\u00e4hlen", "messages", "de");
t.add("api.user.companies.put.success", "Firma\/Organisation erfolgreich gesetzt", "messages", "de");
t.add("affair.nationalAffairInternalVoteCustomEvent.tooltip.caption", "Interne Abstimmung", "messages", "de");
t.add("user.company_select_input.label", "Organisation\/Firma w\u00e4hlen", "messages", "de");
t.add("reports.section.session.title", "\u00dcber welche Session soll der Bericht erstellt werden?", "messages", "de");
t.add("reports.nationalCommitteeProgram.title", "Kommissions-Bericht", "messages", "de");
t.add("affair.actions_card.title", "Interne Erg\u00e4nzungen", "messages", "de");
t.add("file_upload.remove", "L\u00f6schen", "messages", "de");
t.add("reports.meetings.sections.meeting_invitation.title", "F\u00fcr welche Sitzung soll das Dokument erzeugt werden?", "messages", "de");
t.add("reports.meetings.sections.official.title", "Welche offiziellen Angaben sollen im Dokument enthalten sein?", "messages", "de");
t.add("reports.meetings.sections.custom.title", "Welche eigenen Inhalte sollen im Dokument enthalten sein?", "messages", "de");
t.add("reports.meetings.sections.filters.title", "Welche Filter sollen angewendet werden?", "messages", "de");
t.add("reports.meetings.sections.basic.title", "Welche Gestaltungselemente sollen ausgegeben werden?", "messages", "de");
t.add("reports.meetings.sections.format.title", "Welches Ausgabeformat soll das Dokument haben?", "messages", "de");
t.add("reports.meetings.sections.presets.title", "Wollen Sie die Einstellungen als Vorlage speichern?", "messages", "de");
t.add("reports.meetings.sections.submit.title", "Sind Sie bereit das Dokument zu generieren?", "messages", "de");
t.add("customMeetings.newMeeting.error", "Fehler beim Speichern. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("reports.nationalCommitteePrograms.title", "Kommissions-Bericht", "messages", "de");
t.add("dashboard.updates.itemType.nationalAffairCustomSpeaker", "Sprecher\/in", "messages", "de");
t.add("events.termine.categories.all", "alle", "messages", "de");
t.add("events.termine.categories.none", "keine", "messages", "de");
t.add("events.termine.categories.1", "Volksabstimmungen", "messages", "de");
t.add("events.termine.categories.2", "Wahlen", "messages", "de");
t.add("events.termine.categories.3", "Landsgemeinden", "messages", "de");
t.add("events.termine.categories.4", "Sessionen", "messages", "de");
t.add("events.termine.categories.5", "Kommissionen", "messages", "de");
t.add("events.termine.categories.6", "Weitere", "messages", "de");
t.add("affair_list.select.label", "Einreichungsdatum", "messages", "de");
t.add("layout.affair_groups.updated", "Letztes Ereignis", "messages", "de");
t.add("dashboard.updates.user", "Benutzer", "messages", "de");
t.add("file_upload.file_too_big.error", "Die Datei, die Sie hochladen m\u00f6chten, ist zu gross. Reduzieren Sie bitte die Dateigr\u00f6sse auf weniger als 2 MB.", "messages", "de");
t.add("affair.CantonalSessionProgramSpanCantonalAffairEvent.tooltip.caption", "Sessionsprogramm\/Tagesordnung", "messages", "de");
t.add("events.termine.categories", "Termin-Kategorie", "messages", "de");
t.add("calendar.type.select.option.general_dates", "Allgemeine Politik-Termine", "messages", "de");
t.add("api.rankings.get.error", "Fehler beim Abruf der Rankings. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("rankings.affairs", "Rankings", "messages", "de");
t.add("rankings.filter.group.label", "Gruppen", "messages", "de");
t.add("api.topics.get.error", "Fehler beim Abruf der Themen. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("rankings.list.empty", "Kein Ranking verf\u00fcgbar.", "messages", "de");
t.add("affair.CantonalSessionProgramEndCantonalAffairEvent.tooltip.caption", "Aufnahme Sessionsprogramm\/Tagesordnung", "messages", "de");
t.add("layout.search.orderBy.lastEventDate", "Aktuellstes Ereignis", "messages", "de");
t.add("profile.customMeetings.archived", "Archivierte Sitzungen", "messages", "de");
t.add("api.cantonal.councillor.get.error", "Fehler beim Abrufen der kantonalen Parlamentarier. Bitte wenden Sie sich an den Support.", "messages", "de");
t.add("profile.affairGroups.archived", "Archivierte Gruppen", "messages", "de");
t.add("councils.br", "Bundesrat", "messages", "de");
t.add("affair.FederalCouncilMediaReleaseNationalAffairEvent.tooltip.caption", "Medienmitteilung Bundesrat", "messages", "de");
t.add("affair.SdaMediaReleaseNationalAffairEvent.tooltip.caption", "Medienmitteilung SDA", "messages", "de");
t.add("profile.settings.company.custom_fields.html.labelDe", "Bezeichnung DE", "messages", "de");
t.add("profile.settings.company.custom_fields.html.labelFr", "Bezeichnung FR", "messages", "de");
t.add("profile.settings.company.custom_fields.html.labelIt", "Bezeichnung IT", "messages", "de");
t.add("ranking.against", "Abweichler \/ Gegen eigene Fraktion", "messages", "de");
t.add("api.cantonal.consultations.get.error", "Fehler beim Abruf von kantonalen Vernehmlassungen. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("reports.groups.outputAssignedGroups.label", "Zugewiesene Gruppen ausgeben", "messages", "de");
t.add("api.login.error", "Login nicht m\u00f6glich.", "messages", "de");
t.add("security.login.welcome", "Willkommen bei politik.ch", "messages", "de");
t.add("security.login.auth_code", "Auth-Code", "messages", "de");
t.add("cantonal.column", "Quelle", "messages", "de");
t.add("cantonal.dataQuality.sourceType.affairs", "G", "messages", "de");
t.add("cantonal.dataQuality.sourceType.consultations", "V", "messages", "de");
t.add("affairGroup.linkToAffairGroup", "Zur Gruppe", "messages", "de");
t.add("customMeetings.linkToDocument", "Zum Gesch\u00e4ft", "messages", "de");
t.add("not_found.suggested_searches", "Vorgeschlagene Gesch\u00e4fte", "messages", "de");
t.add("affair.results.bydissenters.none", "Ohne Abweichler", "messages", "de");
t.add("layout.search.contentType.national_councillor", "Parlamentarier national", "messages", "de");
t.add("layout.search.contentType.cantonal_councillor", "Parlamentarier kantonal", "messages", "de");
t.add("layout.search.contentType.national_affair_consultation", "Nationale Vernehmlassung", "messages", "de");
t.add("layout.search.contentType.cantonal_affair_consultation", "Kantonale Vernehmlassung", "messages", "de");
t.add("layout.search.filters", "Filter", "messages", "de");
t.add("affair.sdaMediaReleaseNationalAffairEvent.title.prefix", "SDA-Meldung", "messages", "de");
t.add("affair.federalCouncilMediaReleaseNationalAffairEvent.title.prefix", "Ausgangslage", "messages", "de");
t.add("layout.search.type.national", "Gesch\u00e4fte national", "messages", "de");
t.add("layout.search.type.cantonal", "Gesch\u00e4fte kantonal", "messages", "de");
t.add("layout.search.type.custom", "Interne Gesch\u00e4fte", "messages", "de");
t.add("not_found.no_similar_results_found", "Wir haben auch keine \u00e4hnlichen Gesch\u00e4fte gefunden.", "messages", "de");
t.add("parties.noMembersFound", "Keine", "messages", "de");
t.add("reports.groups.numberingTableOfContents.label", "Nummerierung", "messages", "de");
t.add("layout.search.orderBy.lastStatus", "Letzter Status", "messages", "de");
t.add("layout.search.orderBy.author", "Autor\/in", "messages", "de");
t.add("affair.actions_card.auto_save.label", "Wird gespeichert...", "messages", "de");
t.add("affairGroup.linkToDocument", "Zur Gruppen-Ansicht", "messages", "de");
t.add("vote.decision.others", "Enthalten\/Abwesend etc.", "messages", "de");
t.add("auth.password_reset.success", "Passwort erfolgreich zur\u00fcckgesetzt.", "messages", "de");
t.add("user.password.missingdata", "Fehlerhafte Daten", "messages", "de");
t.add("user.password.fieldsnotequal", "Werte stimmen nicht \u00fcberein", "messages", "de");
t.add("user.password.short", "Passwort zu kurz", "messages", "de");
t.add("consultation.endDate", "Endet am", "messages", "de");
t.add("security.login.submit", "Login", "messages", "de");
t.add("security.wrong_password", "Unbekannter Benutzer oder falsches Passwort", "messages", "de");
t.add("affair.nationalAffairConsultationOpinionsEvent.tooltip.caption", "Stellungnahme", "messages", "de");
t.add("affair.nationalAffairConsultationReportsEvent.tooltip.caption", "Ergebnis", "messages", "de");
t.add("profile.settings.language.it", "Italienisch \/ Italiano", "messages", "de");
t.add("profile.settings.language.en", "Englisch \/ Anglais", "messages", "de");
t.add("profile.settings.data_language.label", "Sprache f\u00fcr die Daten \/ Gesch\u00e4fte (sofern verf\u00fcgbar)", "messages", "de");
t.add("profile.settings.data_language.sameAsLanguage", "Gleich wie Benutzersprache", "messages", "de");
t.add("affair.NationalAffairCouncilDocumentsEvent.tooltip.caption", "Ratsunterlagen", "messages", "de");
t.add("national.affairs.status.added_to_session_program", "in Session traktandiert", "messages", "de");
t.add("national.affairs.status.removed_from_session_program", "Aus Sessionsprogramm entfernt\/verschoben", "messages", "de");
t.add("national.affairs.status.deposited", "Eingereicht", "messages", "de");
t.add("national.affairs.status.added_to_committee_program", "In Kommission traktandiert", "messages", "de");
t.add("national.affairs.status.removed_from_committee_program", "Aus Kommissionsprogramm entfernt\/verschoben", "messages", "de");
t.add("national.affairs.status.mentioned_in_press_release", "Medienmitteilung ver\u00f6ffentlicht (Kommission)", "messages", "de");
t.add("national.affairs.status.federal_council_proposal_added", "Antrag des Bundesrates ver\u00f6ffentlicht", "messages", "de");
t.add("national.affairs.status.federal_council_answer_added", "Antwort des Bundesrates ver\u00f6ffentlicht", "messages", "de");
t.add("national.affairs.status.federal_council_opionion_added", "Stellungnahme des Bundesrates ver\u00f6ffentlicht", "messages", "de");
t.add("national.affairs.status.preconsultation_added", "Vorberatung", "messages", "de");
t.add("national.affairs.status.federal_council_media_release", "Ausgangslage formuliert", "messages", "de");
t.add("national.affairs.status.sda_media_release", "SDA-Meldung ver\u00f6ffentlicht", "messages", "de");
t.add("national.affairs.status.council_documents_event", "Ratsunterlagen ver\u00f6ffentlicht", "messages", "de");
t.add("national.affairs.status.unreachable", "Nicht in der offiziellen Quelle verf\u00fcgbar", "messages", "de");
t.add("national.affairs.status.deleted", "Aus offizieller Quelle gel\u00f6scht", "messages", "de");
t.add("cantonal.affairs.type.vor.title", "Vorlage (Regierung)", "messages", "de");
t.add("cantonal.affairs.type.vop.title", "Vorlage (Parlament)", "messages", "de");
t.add("cantonal.affairs.type.mo.title", "Motion", "messages", "de");
t.add("cantonal.affairs.type.po.title", "Postulat", "messages", "de");
t.add("cantonal.affairs.type.ip.title", "Interpellation", "messages", "de");
t.add("cantonal.affairs.type.kla.title", "Kleine Anfrage \/ Einfache Anfrage", "messages", "de");
t.add("cantonal.affairs.type.vo.title", "Volksmotion", "messages", "de");
t.add("cantonal.affairs.type.au.title", "Auftrag", "messages", "de");
t.add("cantonal.affairs.type.pai.title", "Parlamentarische Initiative", "messages", "de");
t.add("cantonal.affairs.type.ktiv.title", "Standesinitiative", "messages", "de");
t.add("cantonal.affairs.type.wah.title", "Wahl", "messages", "de");
t.add("cantonal.affairs.type.pe.title", "Petition", "messages", "de");
t.add("cantonal.affairs.type.ber.title", "Bericht", "messages", "de");
t.add("cantonal.affairs.type.pae.title", "Parlamentarische Empfehlung", "messages", "de");
t.add("cantonal.affairs.type.an.title", "Antrag", "messages", "de");
t.add("cantonal.affairs.type.div.title", "Diverses", "messages", "de");
t.add("cantonal.affairs.type.in.title", "Initiative", "messages", "de");
t.add("cantonal.affairs.type.beg.title", "Begnadigung", "messages", "de");
t.add("cantonal.affairs.type.bes.title", "Beschwerde", "messages", "de");
t.add("cantonal.affairs.type.veg.title", "Vereidigung", "messages", "de");
t.add("cantonal.affairs.type.vem.title", "Vernehmlassung", "messages", "de");
t.add("cantonal.affairs.type.ver.title", "Verordnungen und Erlasse", "messages", "de");
t.add("cantonal.affairs.type.fra.title", "Fragestunde", "messages", "de");
t.add("cantonal.affairs.type.res.title", "Resolution", "messages", "de");
t.add("cantonal.affairs.type.ges.title", "Gesch\u00e4ftsbericht", "messages", "de");
t.add("cantonal.affairs.type.bre.title", "B\u00fcrgerrecht", "messages", "de");
t.add("cantonal.affairs.type.ive.title", "Interkantonale Vereinbarung", "messages", "de");
t.add("cantonal.affairs.type.ref.title", "Referendum", "messages", "de");
t.add("cantonal.affairs.type.bel.title", "Beschluss", "messages", "de");
t.add("cantonal.affairs.type.dek.title", "Dekret", "messages", "de");
t.add("cantonal.affairs.type.svo.title", "Sammelvorlage", "messages", "de");
t.add("cantonal.affairs.type.eiv.title", "Einzelinitiative", "messages", "de");
t.add("cantonal.affairs.type.vor.abbr", "Vo.R.", "messages", "de");
t.add("cantonal.affairs.type.vop.abbr", "Vo.P.", "messages", "de");
t.add("cantonal.affairs.type.mo.abbr", "Mo.", "messages", "de");
t.add("cantonal.affairs.type.po.abbr", "Po.", "messages", "de");
t.add("cantonal.affairs.type.ip.abbr", "Interpellation", "messages", "de");
t.add("cantonal.affairs.type.kla.abbr", "Kl.A.", "messages", "de");
t.add("cantonal.affairs.type.vo.abbr", "Vo.", "messages", "de");
t.add("cantonal.affairs.type.au.abbr", "Au.", "messages", "de");
t.add("cantonal.affairs.type.pai.abbr", "Pa.Iv.", "messages", "de");
t.add("cantonal.affairs.type.ktiv.abbr", "Kt.Iv.", "messages", "de");
t.add("cantonal.affairs.type.wah.abbr", "Wah.", "messages", "de");
t.add("cantonal.affairs.type.pe.abbr", "Pe.", "messages", "de");
t.add("cantonal.affairs.type.ber.abbr", "Ber.", "messages", "de");
t.add("cantonal.affairs.type.pae.abbr", "Pa.E.", "messages", "de");
t.add("cantonal.affairs.type.an.abbr", "An.", "messages", "de");
t.add("cantonal.affairs.type.div.abbr", "Div.", "messages", "de");
t.add("cantonal.affairs.type.in.abbr", "In.", "messages", "de");
t.add("cantonal.affairs.type.beg.abbr", "Beg.", "messages", "de");
t.add("cantonal.affairs.type.bes.abbr", "Bes.", "messages", "de");
t.add("cantonal.affairs.type.veg.abbr", "Veg.", "messages", "de");
t.add("cantonal.affairs.type.vem.abbr", "Vem.", "messages", "de");
t.add("cantonal.affairs.type.ver.abbr", "Ver.", "messages", "de");
t.add("cantonal.affairs.type.fra.abbr", "Fra.", "messages", "de");
t.add("cantonal.affairs.type.res.abbr", "Res.", "messages", "de");
t.add("cantonal.affairs.type.ges.abbr", "Ges.", "messages", "de");
t.add("cantonal.affairs.type.bre.abbr", "Bre.", "messages", "de");
t.add("cantonal.affairs.type.ive.abbr", "IVe.", "messages", "de");
t.add("cantonal.affairs.type.ref.abbr", "Ref.", "messages", "de");
t.add("cantonal.affairs.type.bel.abbr", "Bel.", "messages", "de");
t.add("cantonal.affairs.type.dek.abbr", "Dek.", "messages", "de");
t.add("cantonal.affairs.type.svo.abbr", "Svo.", "messages", "de");
t.add("cantonal.affairs.type.eiv.abbr", "E.Iv.", "messages", "de");
t.add("cantonal.affairs.status.first_reading", "1. Lesung", "messages", "de");
t.add("cantonal.affairs.status.second_reading", "2. Lesung", "messages", "de");
t.add("cantonal.affairs.status.rejected", "Ablehnung erfolgt", "messages", "de");
t.add("cantonal.affairs.status.rejected_not_definitely_supported", "Ablehnung - Nicht definitiv unterst\u00fctzt", "messages", "de");
t.add("cantonal.affairs.status.refusal_not_supported_for_the_time_being", "Ablehnung - Nicht vorl\u00e4ufig unterst\u00fctzt", "messages", "de");
t.add("cantonal.affairs.status.depreciation", "Abschreibung", "messages", "de");
t.add("cantonal.affairs.status.application", "Antrag", "messages", "de");
t.add("cantonal.affairs.status.proposal_for_rejection", "Antrag auf Ablehnung", "messages", "de");
t.add("cantonal.affairs.status.application_for_depreciation", "Antrag auf Abschreibung", "messages", "de");
t.add("cantonal.affairs.status.application_for_depreciation_in_the_annual_report", "Antrag auf Abschreibung im Gesch\u00e4ftsbericht", "messages", "de");
t.add("cantonal.affairs.status.proposal_for_discussion", "Antrag auf Diskussion", "messages", "de");
t.add("cantonal.affairs.status.proposal_for_urgency", "Antrag auf Dringlichkeit", "messages", "de");
t.add("cantonal.affairs.status.application_for_completion", "Antrag auf Erledigung", "messages", "de");
t.add("cantonal.affairs.status.application_for_extension_of_the_deadline", "Antrag auf Fristerstreckung", "messages", "de");
t.add("cantonal.affairs.status.proposal_for_consent", "Antrag auf Zustimmung", "messages", "de");
t.add("cantonal.affairs.status.application_bank_council", "Antrag Bankrat", "messages", "de");
t.add("cantonal.affairs.status.proposal_court", "Antrag Gericht", "messages", "de");
t.add("cantonal.affairs.status.proposal_commission", "Antrag durch Kommission", "messages", "de");
t.add("cantonal.affairs.status.proposal_government_council", "Antrag durch Regierungsrat", "messages", "de");
t.add("cantonal.affairs.status.response_management", "Antwort Gesch\u00e4ftsleitung", "messages", "de");
t.add("cantonal.affairs.status.government_council_answer", "von Regierung beantwortet", "messages", "de");
t.add("cantonal.affairs.status.commencement_of_consultations", "Aufnahme der Beratungen", "messages", "de");
t.add("cantonal.affairs.status.beginning_discussion", "Beginn Diskussion", "messages", "de");
t.add("cantonal.affairs.status.report_government_council", "Bericht Regierungsrat", "messages", "de");
t.add("cantonal.affairs.status.report_and_proposal_government_council", "Bericht und Antrag Regierungsrat", "messages", "de");
t.add("cantonal.affairs.status.definitely_supported", "Definitiv unterst\u00fctzt", "messages", "de");
t.add("cantonal.affairs.status.discussed", "Diskutiert", "messages", "de");
t.add("cantonal.affairs.status.urgency_approved", "Dringlich", "messages", "de");
t.add("cantonal.affairs.status.deposited", "Eingereicht", "messages", "de");
t.add("cantonal.affairs.status.receipt", "Entgegennahme", "messages", "de");
t.add("cantonal.affairs.status.supplementary_report", "Erg\u00e4nzungsbericht", "messages", "de");
t.add("cantonal.affairs.status.significantly_explained", "Erheblich erkl\u00e4rt", "messages", "de");
t.add("cantonal.affairs.status.done", "Erledigt", "messages", "de");
t.add("cantonal.affairs.status.continuation_of_consultations", "Fortsetzung der Beratungen", "messages", "de");
t.add("cantonal.affairs.status.continuation_of_detailed_advice", "Fortsetzung der Detailberatung", "messages", "de");
t.add("cantonal.affairs.status.deadline_extends", "Frist erstreckt", "messages", "de");
t.add("cantonal.affairs.status.perusal", "Kenntnisnahme", "messages", "de");
t.add("cantonal.affairs.status.urgency_not_approved", "Nicht dringlich erkl\u00e4rt", "messages", "de");
t.add("cantonal.affairs.status.do_not_enter", "Nichteintreten", "messages", "de");
t.add("cantonal.affairs.status.come_back_proposal", "R\u00fcckkommensantrag", "messages", "de");
t.add("cantonal.affairs.status.rejection", "R\u00fcckweisung", "messages", "de");
t.add("cantonal.affairs.status.retired", "R\u00fcckzug", "messages", "de");
t.add("cantonal.affairs.status.opinion_management", "Stellungnahme Gesch\u00e4ftsleitung", "messages", "de");
t.add("cantonal.affairs.status.opinion_government_council", "Stellungnahme Regierungsrat", "messages", "de");
t.add("cantonal.affairs.status.part_invalidation", "Teilung\u00fcltigerkl\u00e4rung", "messages", "de");
t.add("cantonal.affairs.status.referral_as_a_postulate", "\u00dcberweisung als Postulat", "messages", "de");
t.add("cantonal.affairs.status.transfer_to_the_management", "\u00dcberweisung an die Gesch\u00e4ftsleitung \/ Ratsb\u00fcro", "messages", "de");
t.add("cantonal.affairs.status.transfer_to_cantonal_council", "\u00dcberweisung an Kantonsrat", "messages", "de");
t.add("cantonal.affairs.status.transfer_to_government_council", "an Regierung \u00fcberwiesen", "messages", "de");
t.add("cantonal.affairs.status.transformation_into_a_postulate", "Umwandlung in ein Postulat", "messages", "de");
t.add("cantonal.affairs.status.invalid_declared", "Ung\u00fcltig erkl\u00e4rt", "messages", "de");
t.add("cantonal.affairs.status.varia", "Varia", "messages", "de");
t.add("cantonal.affairs.status.refusal_to_accept_section_25_3_krg", "Verweigerung Entgegennahme (\u00a7 25 Abs. 3 KRG)", "messages", "de");
t.add("cantonal.affairs.status.provisionally_supported", "Vorl\u00e4ufig unterst\u00fctzt", "messages", "de");
t.add("cantonal.affairs.status.choice", "Wahl", "messages", "de");
t.add("cantonal.affairs.status.loss", "Wegfall", "messages", "de");
t.add("cantonal.affairs.status.resumption", "Wiederaufnahme", "messages", "de");
t.add("cantonal.affairs.status.approval", "Zustimmung erfolgt", "messages", "de");
t.add("cantonal.affairs.status.consent_minority_proposal", "Zustimmung Minderheitsantrag", "messages", "de");
t.add("cantonal.affairs.status.on_session_agenda", "Traktandiert im Parlament", "messages", "de");
t.add("cantonal.affairs.status.unknown", "Nicht definiert", "messages", "de");
t.add("cantonal.affairs.status.divergent_minority_applications_from_the_commission", "Abweichende Minderheitsantr\u00e4ge der Kommission", "messages", "de");
t.add("cantonal.affairs.status.divergent_requests_from_the_commission", "Abweichende Antr\u00e4ge der Kommission", "messages", "de");
t.add("cantonal.affairs.status.interim_report", "Zwischenbericht", "messages", "de");
t.add("cantonal.affairs.status.different_applications", "Abweichende Antr\u00e4ge", "messages", "de");
t.add("cantonal.affairs.status.nonoccurrence_request", "Nichteintretensantrag", "messages", "de");
t.add("cantonal.affairs.status.activity_report_of_financial_control", "T\u00e4tigkeitsbericht der Finanzkontrolle", "messages", "de");
t.add("cantonal.affairs.status.validating", "G\u00fcltigerkl\u00e4rung", "messages", "de");
t.add("cantonal.affairs.status.enter", "Beginn der Debatte", "messages", "de");
t.add("cantonal.affairs.status.final_vote", "Schlussabstimmung", "messages", "de");
t.add("cantonal.affairs.status.transfer_to_commission", "an Kommission \u00fcberwiesen", "messages", "de");
t.add("cantonal.affairs.status.commission_report", "Bericht Kommission", "messages", "de");
t.add("cantonal.affairs.status.resignation", "R\u00fccktritt", "messages", "de");
t.add("cantonal.affairs.status.moved_up", "Nachr\u00fccken", "messages", "de");
t.add("cantonal.affairs.status.approved_with_changes", "Zustimmung mit \u00c4nderungen", "messages", "de");
t.add("cantonal.affairs.status.session_agenda", "auf Tagesordnung \/ Sessionsprogramm gesetzt ", "messages", "de");
t.add("cantonal.affairs.status.transfer", "\u00dcberweisung", "messages", "de");
t.add("cantonal.affairs.status.unmapped", "Neues Ereignis", "messages", "de");
t.add("cantonal.affairs.status.shift_advice_cantonal_council", "Verschiebung Beratung Kantonsrat", "messages", "de");
t.add("cantonal.affairs.status.decision_commission", "Beschluss Kommission", "messages", "de");
t.add("cantonal.affairs.status.depreciation_rejected", "Abschreibung abgelehnt", "messages", "de");
t.add("cantonal.affairs.status.embassy_submission_government_council", "Botschaft \/ Vorlage Regierungsrat", "messages", "de");
t.add("cantonal.affairs.status.decision_cantonal_council_grossrat_landrat", "Beschluss Kantonsrat \/ Grossrat \/ Landrat", "messages", "de");
t.add("cantonal.affairs.status.come_in_1st_reading", "Eintreten, 1. Lesung", "messages", "de");
t.add("cantonal.affairs.status.part_significantly_declaration", "Teil-Erheblicherkl\u00e4rung", "messages", "de");
t.add("cantonal.affairs.status.commission_appointed", "Kommission bestellt", "messages", "de");
t.add("cantonal.affairs.status.referendum_deadline", "Referendumsfrist am Laufen", "messages", "de");
t.add("cantonal.affairs.status.referendum_deadline_expired", "Referendumsfrist abgelaufen", "messages", "de");
t.add("cantonal.affairs.status.non_declaration_significantly", "Nicht-Erheblicherkl\u00e4rung", "messages", "de");
t.add("cantonal.affairs.status.come_into_effect", "Inkrafttreten", "messages", "de");
t.add("cantonal.affairs.status.plebiscite", "Volksabstimmung", "messages", "de");
t.add("cantonal.affairs.status.report", "Bericht ver\u00f6ffentlicht", "messages", "de");
t.add("cantonal.affairs.status.nomination", "Wahlvorschlag", "messages", "de");
t.add("cantonal.affairs.status.vote", "Abstimmung", "messages", "de");
t.add("cantonal.affairs.status.faction_request", "Antrag Fraktion", "messages", "de");
t.add("cantonal.affairs.status.accepted_vote", "Abstimmung angenommen", "messages", "de");
t.add("cantonal.affairs.status.rejected_vote", "Abstimmung abgelehnt", "messages", "de");
t.add("cantonal.affairs.status.sistiert", "sistiert", "messages", "de");
t.add("cantonal.affairs.status.medienmitteilung", "Medienmitteilung ver\u00f6ffentlicht", "messages", "de");
t.add("dashboard.updates.itemType.customAffairCustomSpeaker", "Sprecher\/in interne Notiz", "messages", "de");
t.add("dashboard.updates.itemType.cantonalAffairCustomSpeaker", "Sprecher\/in kantonales Gesch\u00e4ft", "messages", "de");
t.add("profile.settings.notificationPreference.keywords.german", "Suchbegriffe Deutsch", "messages", "de");
t.add("profile.settings.notificationPreference.keywords.french", "Suchbegriffe Franz\u00f6sisch", "messages", "de");
t.add("profile.settings.notificationPreference.keywords.italian", "Suchbegriffe Italienisch", "messages", "de");
t.add("layout.search.config.save_as", "Neue Suchvorlage speichern", "messages", "de");
t.add("layout.search.config.save", "Suchvorlage aktualisieren", "messages", "de");
t.add("layout.search.config.form.description", "Speichern Sie die Sucheinstellungen in einer Suchvorlage:", "messages", "de");
t.add("layout.search.config.form.title.input.label", "Bezeichnung", "messages", "de");
t.add("layout.search.config.form.title.error.message", "Fehler beim Speichern der Suchvorlage", "messages", "de");
t.add("layout.search.config.save.form.title", "Suchvorlage aktualisieren", "messages", "de");
t.add("layout.search.config.load", "Suchvorlagen", "messages", "de");
t.add("layout.search.config.delete", "Suchvorlage l\u00f6schen", "messages", "de");
t.add("layout.search.config.invalid", "Ung\u00fcltige Einstellungen f\u00fcr Suchvorlage", "messages", "de");
t.add("api.search.config.save.success", "Suchvorlage erfolgreich gespeichert", "messages", "de");
t.add("api.search.config.save.error", "Fehler beim Speichern der Suchvorlage", "messages", "de");
t.add("api.search.config.delete.success", "Suchvorlage erfolgreich gel\u00f6scht", "messages", "de");
t.add("api.search.config.delete.error", "Fehler beim L\u00f6schen der Suchvorlage", "messages", "de");
t.add("api.search.config.get.error", "Fehler beim Abrufen der Suchvorlagen", "messages", "de");
t.add("api.search.config.put.success", "Suchvorlage erfolgreich gespeichert", "messages", "de");
t.add("api.search.config.put.error", "Fehler beim Speichern der Suchvorlage", "messages", "de");
t.add("layout.search.config.action.header", "Gespeicherte Suchvorlagen", "messages", "de");
t.add("layout.search.config.action.label", "Aktuelle Suchvorlage", "messages", "de");
t.add("layout.search.config.delete.confirm.message", "Wollen Sie die Suchvorlage wirklich l\u00f6schen?", "messages", "de");
t.add("profile.settings.company.custom_fields.html.labelEn", "Bezeichnung EN", "messages", "de");
t.add("profile.settings.company.internal_fields.html.labelEn", "Bezeichnung EN", "messages", "de");
t.add("profile.settings.company.internal_fields.ratings.labelEn", "Bezeichnung EN", "messages", "de");
t.add("profile.settings.company.custom_fields.rating.labelEn", "Bezeichnung EN", "messages", "de");
t.add("cantonal.affairs.type.bot.title", "Botschaft", "messages", "de");
t.add("cantonal.affairs.type.bot.abbr", "Bot", "messages", "de");
t.add("untranslated.original.prefix", "[Originaltitel]", "messages", "de");
t.add("customMeetings.pdf.assignedUsers.label", "Zugewiesen an", "messages", "de");
t.add("affairGroup.export.header.assignedUsers", "Zugewiesen an", "messages", "de");
t.add("affairGroup.export.header.assignedUser", "Zugewiesen an", "messages", "de");
t.add("cantonal.affairs.status.initiationOfConsultation", "Einleitung des Vernehmlassungsverfahrens", "messages", "de");
t.add("cantonal.affairs.status.applicationFromCantonalManagement", "Antrag kantonale Direktion", "messages", "de");
t.add("cantonal.affairs.status.answerFromCantonalManagement", "Antwort kantonale Direktion", "messages", "de");
t.add("cantonal.affairs.status.reportFromCantonalManagement", "Bericht kantonale Direktion", "messages", "de");
t.add("layout.search.affairTypes.national", "Gesch\u00e4ftsart (national)", "messages", "de");
t.add("layout.search.affairTypes.cantonal", "Gesch\u00e4ftsart (kantonal)", "messages", "de");
t.add("reports.groups.outputInternalLink.label", "Link zu politik.ch", "messages", "de");
t.add("reports.affair.internalLink", "Link politik.ch", "messages", "de");
t.add("profile.settings.company.custom_fields.attachment.add", "Neues Datei-Feld", "messages", "de");
t.add("affair.internalAttachment.uploadFiles", "Dateien hinzuf\u00fcgen", "messages", "de");
t.add("affair.internalAttachment.renameModal.label", "Datei umbenennen", "messages", "de");
t.add("affair.internalAttachment.renameModal.description", "Wie soll der neue Dateiname lauten?", "messages", "de");
t.add("affair.internalAttachment.renameModal.input.label", "neuer Dateiname", "messages", "de");
t.add("affair.internalAttachment.renameModal.error.message", "Fehler beim Umbenennen der Datei.", "messages", "de");
t.add("affair.internalAttachment.renameModal.button.rename_attachment", "Umbenennen", "messages", "de");
t.add("api.profile.settings.company.internal_fields.attachment.put.success", "Datei erfolgreich entfernt.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.attachment.put.error", "Fehler beim Entfernen der Datei. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.attachment.post.success", "Datei erfolgreich gespeichert.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.attachment.post.error", "Fehler beim Speichern der Datei. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.attachment.rename.success", "Datei erfolgreich umbenannt.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.attachment.rename.error", "Fehler beim Umbenennen der Datei. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.attachments.get.error", "Fehler beim Abruf der Dateien. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("profile.settings.company.custom_fields.attachment.labelDe", "Bezeichnung DE", "messages", "de");
t.add("profile.settings.company.custom_fields.attachment.labelFr", "Bezeichnung FR", "messages", "de");
t.add("profile.settings.company.custom_fields.attachment.labelIt", "Bezeichnung IT", "messages", "de");
t.add("profile.settings.company.custom_fields.attachment.labelEn", "Bezeichnung EN", "messages", "de");
t.add("api.file.upload.error", "Fehler beim Hochladen der Datei.", "messages", "de");
t.add("reports.config.form.checkbox_private", "Private Vorlage (nicht f\u00fcr andere sichtbar)", "messages", "de");
t.add("councillor.citizenship", "B\u00fcrgerort", "messages", "de");
t.add("councillor.civil.status", "Zivilstand", "messages", "de");
t.add("councillor.kids", "Kinder", "messages", "de");
t.add("councillor.address", "Adresse", "messages", "de");
t.add("councillor.website", "Website", "messages", "de");
t.add("profile.settings.company.internal_fields.attachment.labelDe", "Bezeichnung DE", "messages", "de");
t.add("profile.settings.company.internal_fields.attachment.labelFr", "Bezeichnung FR", "messages", "de");
t.add("profile.settings.company.internal_fields.attachment.labelIt", "Bezeichnung IT", "messages", "de");
t.add("profile.settings.company.internal_fields.attachment.labelEn", "Bezeichnung EN", "messages", "de");
t.add("councillor.military.rank", "Milit\u00e4rischer Rang", "messages", "de");
t.add("api.profile.settings.company.internal_fields.attachment.delete.success", "L\u00f6schen erfolgreich", "messages", "de");
t.add("reports.config.form.title.error.message", "Fehler beim Speichern", "messages", "de");
t.add("councillor.personal.details", "Personalien", "messages", "de");
t.add("layout.search.start_search", "Suche starten", "messages", "de");
t.add("councillor.submitted", "Eingereicht", "messages", "de");
t.add("councillor.subscribed", "Abonniert", "messages", "de");
t.add("councillor.submitted.proposals", "Eingereichte Vorst\u00f6sse", "messages", "de");
t.add("button.show.all", "Alle anzeigen", "messages", "de");
t.add("councillor.advances.topics", "Themen der Vorst\u00f6sse", "messages", "de");
t.add("councillor.success.advances", "Erfolg der Vorst\u00f6sse", "messages", "de");
t.add("councillor.title.topics.success", "Themen und Erfolge", "messages", "de");
t.add("councillor.absences.and.abstentions", "Stimmverhalten", "messages", "de");
t.add("councillor.absences.from.votes", "Absenzen bei %count% Abstimmungen", "messages", "de");
t.add("councillor.parliamentary.group", "Parlamentarische Gruppen", "messages", "de");
t.add("councillor.actual.title", "Aktuell", "messages", "de");
t.add("councillor.previous.title", "Fr\u00fchere", "messages", "de");
t.add("affairs.assign_users.add_teammembers.button_title", "Benutzer zuweisen", "messages", "de");
t.add("affairs.assign_users.add_teammembers.label", "Benutzer zuweisen", "messages", "de");
t.add("affairs.assign_users.unassign_teammember.button_title", "Benutzer-Zuweisung entfernen", "messages", "de");
t.add("api.affair.assigned_users.get.error", "Zugewiesene Benutzer konnten nicht geladen werden.", "messages", "de");
t.add("affairs.assign_users.unassign_teammember.modal.title", "Sind Sie sicher, dass Sie folgenden Benutzer entfernen wollen: ", "messages", "de");
t.add("affairs.assign_users.form.title", "Benutzer zuweisen", "messages", "de");
t.add("affairs.assign_users.form.groups_more", "weitere", "messages", "de");
t.add("api.user.assign_affair.success", "Benutzer erfolgreich zugewiesen", "messages", "de");
t.add("api.user.assign_affair.error", "Fehler bei der Zuweisung von Benutzern. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("reports.groups.outputAssignedUsers.label", "Zugewiesene Benutzer", "messages", "de");
t.add("reports.assigned_users.label", "Zugewiesene Benutzer", "messages", "de");
t.add("api.basic_users.get.error", "Benutzer konnten nicht geladen werden. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("affair_list.select.assigned_users.label", "Zugewiesene Benutzer", "messages", "de");
t.add("councillor.aTotalOf", "insgesamt", "messages", "de");
t.add("affair_list.select.authors.label", "Autor\/in", "messages", "de");
t.add("reports.authors.label", "Autor\/in", "messages", "de");
t.add("national.consultations.details.cancelled", "Abgebrochene VNL", "messages", "de");
t.add("cantonal.affairs.status.inProgress", "in Bearbeitung \/ in Behandlung", "messages", "de");
t.add("cantonal.affairs.status.courtDecisions", "Beschluss Gericht", "messages", "de");
t.add("cantonal.affairs.status.disagreeWithConversion", "Mit Umwandlung nicht einverstanden", "messages", "de");
t.add("cantonal.affairs.status.meeting", "Sitzung", "messages", "de");
t.add("cantonal.affairs.status.treatmentByTheCouncils", "Behandlung durch die R\u00e4te", "messages", "de");
t.add("profile.settings.company.custom_fields.affairs_label", "Gesch\u00e4fte", "messages", "de");
t.add("profile.settings.company.custom_fields.meetings_label", "Sitzungen", "messages", "de");
t.add("profile.settings.compay.custom_meeting_html_fields_setting.delete.confirm", "L\u00f6schen?", "messages", "de");
t.add("profile.settings.company.custom_meetings_html_fields.add", "Hinzuf\u00fcgen", "messages", "de");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderDe", "Bezeichnung DE", "messages", "de");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderFr", "Bezeichnung FR", "messages", "de");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderIt", "Bezeichnung IT", "messages", "de");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderEn", "Bezeichnung EN", "messages", "de");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelDe", "Bezeichnung DE", "messages", "de");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelFr", "Bezeichnung FR", "messages", "de");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelIt", "Bezeichnung IT", "messages", "de");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelEn", "Bezeichnung EN", "messages", "de");
t.add("customMeetings.editMeeting.date.label", "Datum \/ Uhrzeit", "messages", "de");
t.add("customMeetings.editMeeting.subject.label", "Betreff", "messages", "de");
t.add("customMeetings.editMeeting.description.label", "Beschreibung", "messages", "de");
t.add("customMeetings.editMeeting.location.label", "Ort", "messages", "de");
t.add("customMeetings.editMeeting.president.label", "Vorsitz", "messages", "de");
t.add("customMeetings.editMeeting.protocol.label", "Protokollf\u00fchrung", "messages", "de");
t.add("customMeetings.editMeeting.guests.label", "G\u00e4ste", "messages", "de");
t.add("api.meeting.get.error", "Fehler beim Laden der Sitzungen. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("layout.custom_meetings.custom_fields", "Details", "messages", "de");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.get.error", "Fehler beim Laden der internen Felder der Sitzungen. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.put.success", "Erfolgreich gespeichert", "messages", "de");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.put.error", "Fehler beim Laden der internen Felder der Sitzungen. Bitte melden Sie sich beim Support.", "messages", "de");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.post.success", "Erfolgreich gespeichert", "messages", "de");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.post.error", "Fehler beim Speichern. Bitte versuchen Sie es erneut oder melden Sie sich beim Support: support@politik.ch", "messages", "de");
t.add("custom_meeting.internalHtmlField.save", "Speichern", "messages", "de");
t.add("custom_meeting.internalHtmlField.edit", "Bearbeiten", "messages", "de");
t.add("custom_meeting.internalHtmlField.error", "Fehler", "messages", "de");
t.add("cantonal.affairs.status.forProcessingByTheDepartment", "Zur Bearbeitung an Direktion", "messages", "de");
t.add("layout.reports.config.save_as", "Neue Berichtsvorlage speichern", "messages", "de");
t.add("layout.reports.update.config", "Berichtsvorlage aktualisieren", "messages", "de");
t.add("reports.affairStates.label", "Status nationales Gesch\u00e4ft", "messages", "de");
t.add("cantonal.affair.attachment", "Anhang", "messages", "de");
t.add("security.login.auth_msg", "Bitte geben Sie den Code Ihrer Authenticator-App ein.", "messages", "de");
t.add("security.login.trusted", "Diesem Ger\u00e4t 30 Tage lang vertrauen", "messages", "de");
t.add("profile.edit.2fa.status", "Status", "messages", "de");
t.add("profile.edit.2fa.activated", "ist aktiviert", "messages", "de");
t.add("affairs.actors.and.roles", "Mitunterzeichner", "messages", "de");
t.add("affairs.actor.and.role.cosigners", "Mitunterzeichner", "messages", "de");
t.add("affairs.actor.and.role.adapters", "\u00dcbernehmer", "messages", "de");
t.add("affairs.actor.and.role.opponents", "Bek\u00e4mpfer", "messages", "de");
t.add("cantonal.affairs.status.decision", "Beschluss", "messages", "de");
t.add("cantonal.affairs.status.nominations", "Nominierung", "messages", "de");
t.add("event.reminders.button.edit", "Erinnerungen bearbeiten", "messages", "de");
t.add("calendar.event.status.share", "Zum Kalender hinzuf\u00fcgen", "messages", "de");
t.add("calendar.event.tooltip.unshare", "Ereignis vom geteilten Kalender entfernen", "messages", "de");
t.add("calendar.event.tooltip.share", "Ereignis zum geteilten Kalender hinzuf\u00fcgen", "messages", "de");
t.add("calendar.share.error", "Fehler beim Teilen des Kalenders. Bitte wenden Sie sich an support@politik.ch", "messages", "de");
t.add("calendar.settings.title", "Einstellungen geteilter Kalender", "messages", "de");
t.add("calendar.settings.status.enabled", "Geteilter Kalender aktivieren", "messages", "de");
t.add("calendar.settings.status.disabled", "Geteilter Kalender deaktivieren", "messages", "de");
t.add("calendar.settings.placeholder", "Um die Adresse des geteilten Kalenders zu sehen, m\u00fcssen Sie diesen zuerst aktivieren.", "messages", "de");
t.add("profile.settings.navigation.calendar", "Geteilter Kalender", "messages", "de");
t.add("ui.link.copy", "Link kopieren", "messages", "de");
t.add("ui.link.copied", "Link kopiert", "messages", "de");
t.add("affair.events.custom.attachment.post.error", "Fehler beim Hochladen der Datei. Bitte versuchen Sie es erneut oder melden Sie sich bitte beim Support: support@politik.ch", "messages", "de");
t.add("affairs.customEvents.fields.note.attachments", "Dateien", "messages", "de");
t.add("affairs.customEvents.fields.note.uploadFiles", "Datei hochladen", "messages", "de");
t.add("national.parliamentaryGroup.membership.function.president", "Pr\u00e4sident", "messages", "de");
t.add("national.parliamentaryGroup.membership.function.coPresident", "Co-Pr\u00e4sident", "messages", "de");
t.add("national.parliamentaryGroup.membership.function.member", "Mitglied", "messages", "de");
t.add("interests.tooltip.organ.function", "Organ \/ Funktion", "messages", "de");
t.add("councillor.interests.title", "Interessenbindungen", "messages", "de");
t.add("councillor.title.current.mandate", "Aktuelle Parlamentsmandate", "messages", "de");
t.add("councillors.interests.paid", "bezahlt", "messages", "de");
t.add("councillors.interests.unpaid", "ehrenamtlich", "messages", "de");
t.add("national.notifications.preferences.label", "Legen Sie fest, ob und welche Benachrichtigungen Sie \u00fcber die Gesch\u00e4fte des Bundes erhalten m\u00f6chten.", "messages", "de");
t.add("cantonal.notifications.preferences.label", "Legen Sie fest, ob und welche Benachrichtigungen Sie \u00fcber die Gesch\u00e4fte der Kantone erhalten m\u00f6chten.", "messages", "de");
t.add("get.national.notifications.label", "Benachrichtigungen erhalten f\u00fcr den Bund", "messages", "de");
t.add("get.cantonal.notifications.label", "Benachrichtigungen erhalten f\u00fcr die Kantone", "messages", "de");
t.add("search.terms.notifications.preferences", "Nur \u00fcber meine Suchbegriffe benachrichtigen", "messages", "de");
t.add("search.terms.restore", "Wiederherstellen", "messages", "de");
t.add("search.terms.page.title", "Suchbegriffe", "messages", "de");
t.add("search.terms.page.define.message", "Definieren Sie hier alle Ihre relevanten Suchbegriffe. Wir werden Sie immer dann benachrichtigen, wenn diese in neuen Gesch\u00e4ften oder Updates erscheinen.", "messages", "de");
t.add("search.terms.select.all.types", "Alle Typen ausw\u00e4hlen", "messages", "de");
t.add("attachment.delete.confirm", "Sind Sie sicher, dass Sie diesen Anhang l\u00f6schen m\u00f6chten?", "messages", "de");
t.add("cantonal.affairs.status.answerCovenant", "Antwort Bund", "messages", "de");
t.add("councillor.parliamentary.groups", "Parlamentarische Gruppen", "messages", "de");
t.add("councillor.former.commissions", "Fr\u00fchere Parlamentsmandate", "messages", "de");
t.add("councillor.proposals.submitted", "Eingereicht", "messages", "de");
t.add("api.links.delete.success", "Die Verlinkung der Gesch\u00e4fte wurde gel\u00f6scht", "messages", "de");
t.add("api.links.delete.error", "Fehler beim L\u00f6schen von verlinkten Gesch\u00e4ften. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("groups.link.to_unarchived_groups.title", "Zu den Gruppen", "messages", "de");
t.add("groups.link.to_archived_groups.title", "Zu den archivierten Gruppen", "messages", "de");
t.add("affairGroups.unarchive", "Gruppe wiederherstellen", "messages", "de");
t.add("affairGroup.unarchive.confirm", "Sind Sie sicher, dass Sie die Archivierung dieser Gruppe aufheben wollen?", "messages", "de");
t.add("api.groups.put.unarchive.success", "Die Gruppe wurde erfolgreich wiederhergestellt.", "messages", "de");
t.add("api.groups.put.unarchive.error", "Die Gruppe konnte nicht wiederhergestellt werden. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("api.groups.put.archive.success", "Die Gruppe wurde archiviert.", "messages", "de");
t.add("api.groups.put.archive.error", "Die Gruppe konnte nicht archiviert werden. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("customMeetings.unarchive", "Sitzung wiederherstellen", "messages", "de");
t.add("api.meetings.put.unarchive.success", "Die Sitzung wurde erfolgreich wiederhergestellt.", "messages", "de");
t.add("api.meetings.put.unarchive.error", "Die Sitzung konnte nicht wiederhergestellt werden. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("api.meetings.put.archive.success", "Die Sitzung wurde archiviert.", "messages", "de");
t.add("api.meetings.put.archive.error", "Die Sitzung konnte nicht archiviert werden. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("customMeetings.link.to_unarchived_groups.title", "Zu den Sitzungen", "messages", "de");
t.add("customMeetings.link.to_archived_groups.title", "Zu den archivierten Sitzungen", "messages", "de");
t.add("calendar.modal.text.not.shared", "Das Ereignis wurde zu Ihrem geteilten Kalender hinzugef\u00fcgt. Um es sehen zu k\u00f6nnen, m\u00fcssen Sie den geteilten Kalender einschalten.\u2028 Sie k\u00f6nnen dies jetzt oder sp\u00e4ter tun, unter \"Ihr Name\" > Benutzerkonto > Geteilter Kalender.", "messages", "de");
t.add("calendar.modal.not.active", "Geteilter Kalender ist nicht aktiviert", "messages", "de");
t.add("calendar.modal.activate.later", "Sp\u00e4ter aktivieren", "messages", "de");
t.add("calendar.modal.activate.now", "Zu den Einstellungen", "messages", "de");
t.add("profile.settings.nationalNotificationPreferences.pressRelease", "Pressemitteilungen", "messages", "de");
t.add("affair.councilStatesDebate.tooltip.caption", "Debatten St\u00e4nderat", "messages", "de");
t.add("cosigners.empty.list", "Dieses Gesch\u00e4ft hat keine Mitunterzeichner", "messages", "de");
t.add("search.terms.apply.all.cantons", "Auf alle Kantone anwenden", "messages", "de");
t.add("cantons.settings.reset.confirm", "Damit werden die Einstellungen f\u00fcr alle Kantone \u00fcberschrieben. Wollen Sie damit fortfahren?", "messages", "de");
t.add("councillor.phone", "Telefonnummer", "messages", "de");
t.add("cantonal.overview.government", "Regierung", "messages", "de");
t.add("api.government.author.get.error", "Fehler beim Abrufen des Autors der Regierung", "messages", "de");
t.add("custom.affairs.form.edit.title", "Internes Gesch\u00e4ft bearbeiten", "messages", "de");
t.add("affair.actions_card.attachments_title", "Anh\u00e4nge", "messages", "de");
t.add("affair.actions_card.visual_report_section_title", "Visual Report", "messages", "de");
t.add("api.profile.settings.company.internal_fields.builtin.get.error", "Fehler beim Lesen. Bitte kontaktieren Sie den Support.", "messages", "de");
t.add("affair.builtin_fields.time_axis.beginning_label", "Am Anfang", "messages", "de");
t.add("affair.builtin_fields.time_axis.end_label", "Kurz vor Abschluss", "messages", "de");
t.add("affair.builtin_fields.need_for_action.low_prio_label", "Monitoring", "messages", "de");
t.add("affair.builtin_fields.need_for_action.high_prio_label", "Dringender Handlungsbedarf", "messages", "de");
t.add("api.profile.settings.company.internal_fields.ratings.put.sucess", "Das Feld wurde erfolgreich aktualisiert.", "messages", "de");
t.add("api.profile.settings.company.internal_fields.ratings.post.sucess", "Das Feld wurde erfolgreich gespeichert.", "messages", "de");
t.add("reports.visual_report", "Visual Report", "messages", "de");
t.add("reports.visual_report.title", "Visual Report erstellen", "messages", "de");
t.add("reports.sections.visible_internal_fields.title", "Welche internen Daten m\u00f6chten Sie sichtbar machen?", "messages", "de");
t.add("reports.sections.visible_internal_fields.description", "Bitte teilen Sie keine sensiblen Daten. Dieser Bericht wird \u00fcber eine \u00f6ffentliche URL sichtbar sein und Ihre internen Daten sind nicht gesch\u00fctzt. Die internen Datenfelder \"Status\", \"Dringlichkeit\" und \"Auswirkungen\" sind obligatorisch und werden f\u00fcr die Erstellung des visuellen Berichts ben\u00f6tigt. Die internen Felder, die Sie als Filter ausw\u00e4hlen, werden ebenfalls standardm\u00e4\u00dfig angezeigt.", "messages", "de");
t.add("reports.sections.visual_report_filter_fields.title", "Welche benutzerdefinierten Felder m\u00f6chten Sie als Filter verwenden? (max. 2 Filter)", "messages", "de");
t.add("reports.sections.make_report_public.toggle.title", "Bericht ver\u00f6ffentlichen", "messages", "de");
t.add("reports.sections.save_report.title", "Bericht speichern", "messages", "de");
t.add("form.visual_report.submit", "Visual Report speichern", "messages", "de");
t.add("reports.save_visual_report.error", "Beim Speichern des Visual Reports ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("reports.save_visual_report.success_modal.title", "Der Visual Report wurde erfolgreich erstellt.", "messages", "de");
t.add("reports.save_visual_report.success_modal.description", "Der Bericht <strong>%reportName%<\/strong> wurde erfolgreich mit <strong>%numberOfGroups% Gruppen<\/strong> und einer Gesamtzahl von <strong>%numberOfAffairs% Gesch\u00e4ften<\/strong> erstellt.", "messages", "de");
t.add("reports.save_visual_report.success_modal.public_url", "\u00d6ffentliche URL:", "messages", "de");
t.add("reports.save_visual_report.success_modal.copy_url_button.title", "URL kopieren", "messages", "de");
t.add("reports.save_visual_report.success_modal.open_report_button.title", "Report in neuem Fenster \u00f6ffnen", "messages", "de");
t.add("internalFieldDefinitions.builtIn.size.low.label", "Niedrig", "messages", "de");
t.add("internalFieldDefinitions.builtIn.size.medium.label", "Mittel", "messages", "de");
t.add("internalFieldDefinitions.builtIn.size.high.label", "Hoch", "messages", "de");
t.add("internalFieldDefinitions.builtIn.size.veryHigh.label", "Sehr hoch", "messages", "de");
t.add("cantonal.affairs.status.changeRequest", "\u00c4nderungsantrag", "messages", "de");
t.add("cantonal.affairs.status.cantonalInitiativeSubmission", "Einreichung Standesinitiative", "messages", "de");
t.add("vote.national_council.error", "Die Abstimmungen des Nationalrats konnten nicht abgerufen werden. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("vote.council_of_states.error", "Die Abstimmungen des St\u00e4nderats konnten nicht abgerufen werden. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("groups.tree.group.openGroupDetail", "Gehe zu Gruppenseite ", "messages", "de");
t.add("reports.save_visual_report.success_modal.copied_to_clipboard", "Link in Zwischenablage kopiert", "messages", "de");
t.add("committee.private.affairs", "Private Gesch\u00e4fte", "messages", "de");
t.add("reports.visual_report.not_complete_affairs", "Der Bericht enth\u00e4lt Gesch\u00e4fte, f\u00fcr die noch keine Angaben zu Relevanz, Status oder Handlungsbedarf gemacht wurden.", "messages", "de");
t.add("reports.visual_report.exceeding_limit", "Der Bericht enth\u00e4lt mehr als 35 Gesch\u00e4fte. F\u00fcr eine bessere \u00dcbersicht empfehlen wir Ihnen Ihre Auswahl einzuschr\u00e4nken.", "messages", "de");
t.add("reports.visual_report.create_anyway", "Ignorieren und fortfahren", "messages", "de");
t.add("reports.visual_report.back_to_reports", "Zur\u00fcck zu den Einstellungen", "messages", "de");
t.add("reports.sections.make_report_public.title", "M\u00f6chten Sie den Bericht jetzt ver\u00f6ffentlichen?", "messages", "de");
t.add("reports.sections.make_report_public.description", "Dieser Visual Report wird f\u00fcr alle sichtbar sein, mit denen Sie die \u00f6ffentliche URL geteilt haben. Sie k\u00f6nnen diese Einstellung jederzeit \u00e4ndern.", "messages", "de");
t.add("reports.sections.report_name.title", "Wie soll Ihr Visual Report heissen?", "messages", "de");
t.add("reports.report_name_input.label", "Reportname", "messages", "de");
t.add("api.reports.visual_repor_config.get.error", "Die letzten Visual Reports konnten nicht geladen werde. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("reports.visual_report_config.delete.confirm.message", "Sind Sie sicher, dass Sie diesen Visual Report l\u00f6schen m\u00f6chten?", "messages", "de");
t.add("api.reports.visual_report_config.delete.success", "Der Visual Report wurde gel\u00f6scht.", "messages", "de");
t.add("api.reports.visual_report_config.delete.error", "Beim L\u00f6schen des Visual Reports ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("reports.load_visual_report.error", "Der Visual Report konnte nicht geladen werden. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("reports.update_visual_report.error", "Der Visual Report konnte nicht aktualisiert werden. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("reports.update_visual_report.success", "Der Visual Report wurde aktualisiert.", "messages", "de");
t.add("cantonal.affairs.status.opinion", "Stellungnahme", "messages", "de");
t.add("internalFieldDefinitions.builtIn.y.label", "Handlungsbedarf", "messages", "de");
t.add("internalFieldDefinitions.builtIn.x.label", "Fortschritt", "messages", "de");
t.add("internalFieldDefinitions.builtIn.size.label", "Relevanz", "messages", "de");
t.add("visualiser.beginning", "Am Anfang", "messages", "de");
t.add("visualiser.end", "Kurz vor Abschluss", "messages", "de");
t.add("visualiser.monitoring", "Beobachten", "messages", "de");
t.add("visualiser.urgency", "Dringender Handlungsbedarf", "messages", "de");
t.add("dashboard.newest_affairs", "Neueste Gesch\u00e4fte", "messages", "de");
t.add("dashboard.updated_affairs", "Updates", "messages", "de");
t.add("dashboard.assigned_affairs", "Zugewiesen", "messages", "de");
t.add("dashboard.team_actions", "Team Updates", "messages", "de");
t.add("shared.affair.type", "Art des Gesch\u00e4fts", "messages", "de");
t.add("shared.affair.lastupdate", "Letztes Ereignis am", "messages", "de");
t.add("shared.affair.linkedaffairs", "Verkn\u00fcpfte Gesch\u00e4fte", "messages", "de");
t.add("shared.further.info", "Weitere Informationen", "messages", "de");
t.add("shared.affair.linkedaffairs.none", "Keine verkn\u00fcpften Gesch\u00e4fte", "messages", "de");
t.add("reports.visual_report_menu.header", "Neueste Visual Reports", "messages", "de");
t.add("cantonal.affairAuthorRole.signer", "Erstunterzeichnerin \/ Erstunterzeichner", "messages", "de");
t.add("cantonal.affairAuthorRole.co_signer", "Mitunterzeichnerin \/ Mitunterzeichner", "messages", "de");
t.add("cantonal.affairAuthorRole.commission", "Vorberatende Kommission", "messages", "de");
t.add("consultation.submittedByOn.exist", "Eingereicht von %councillors%. Er\u00f6ffnung am %date%", "messages", "de");
t.add("consultation.submittedByOn.none", "Er\u00f6ffnung am %date%", "messages", "de");
t.add("shared.affair.status.none", "Status nicht verf\u00fcgbar", "messages", "de");
t.add("customMeetings.tree.customMeeting.openMeeting", "Gehe zu Sitzungsseite", "messages", "de");
t.add("cantonal.affairs.status.urgentInterpellation", "Dringliche Interpellation", "messages", "de");
t.add("shared.affair.affairGroups", "Gruppe", "messages", "de");
t.add("reports.save_visual_report.success_copied_url_button.title", "Kopiert!", "messages", "de");
t.add("affair.customShortTitle.information", "Max. 40 Zeichen", "messages", "de");
t.add("customMeetings.unarchive.confirm", "M\u00f6chten Sie die Sitzung wiederherstellen?", "messages", "de");
t.add("cantonal.affairs.status.removedFromSource", "Gesch\u00e4ft wurde von kantonaler Webseite entfernt", "messages", "de");
t.add("internalFieldDefinitions.builtIn.shortTitle.label", "Kurztitel", "messages", "de");
t.add("share.filters.groups", "Gruppen", "messages", "de");
t.add("dashboard.updates.item_topics", "Themen", "messages", "de");
t.add("shared.filters.show.all", "Alle anzeigen", "messages", "de");
t.add("shared.filters.hide.all", "Alle ausblenden", "messages", "de");
t.add("dashboard.newest_affairs.tooltip", "Alle Gesch\u00e4fte, die in den letzten 7 Tagen importiert wurden und Ihren Suchbegriffen entsprechen. Wenn Sie bisher noch keine Suchbegriffe definiert haben zeigen wir Ihnen mindestens die 20 aktuellsten Gesch\u00e4fte", "messages", "de");
t.add("dashboard.updated_affairs.tooltip", "Aktualisierungen der letzten 4 Wochen, von Gesch\u00e4ften, denen Sie folgen oder die zu einer Gruppe hinzugef\u00fcgt wurden, der Sie folgen", "messages", "de");
t.add("dashboard.assigned_affairs.tooltip", "Alle Ihre zugewiesene Gesch\u00e4fte", "messages", "de");
t.add("dashboard.team_actions.tooltip", "Interne Gesch\u00e4fte, die innerhalb Ihrer Company erstellt wurden, und Gesch\u00e4fte, die einer Gruppe in Ihrer Company hinzugef\u00fcgt wurden", "messages", "de");
t.add("national.affairs.list.header.lastEventDate", "Enddatum", "messages", "de");
t.add("dashboard.custom.tooltip", "Benutzerdefinierter Quicklink", "messages", "de");
t.add("dashboard.quicklinks.failed_to_save", "Quicklink konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("dashboard.quicklinks.failed_to_fetch", "Quicklink konnte nicht ge\u00f6ffnet werden. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("dashboard.edit_customize", "Bearbeiten", "messages", "de");
t.add("dashboard.restore_default", "Wiederherstellen", "messages", "de");
t.add("dashboard.restore.default", "M\u00f6chten Sie den Quicklink auf die Standardeinstellungen zur\u00fccksetzen? Dies kann nicht r\u00fcckg\u00e4ngig gemacht werden.", "messages", "de");
t.add("dashboard.edit.shortcut", "Dashboard anpassen", "messages", "de");
t.add("dashboard.custom.quicklink.name", "Name Quicklink", "messages", "de");
t.add("dashboard.custom.quicklink.search.template", "W\u00e4hlen Sie eine Suchvorlage", "messages", "de");
t.add("dashboard.custom.quicklink.icon", "Icon", "messages", "de");
t.add("dashboard.custom.quicklink.show_updates", "Nur Updates anzeigen", "messages", "de");
t.add("dashboard.custom.quicklink.show_all", "Alle Gesch\u00e4fte anzeigen", "messages", "de");
t.add("national.swiss.affairs.consultations.caption", "Schweiz", "messages", "de");
t.add("share.filters", "Filter", "messages", "de");
t.add("dashboard.custom.quicklink.no.search.template", "Keine Suchvorlage vorhanden", "messages", "de");
t.add("cantonal.affairs.status.addedToCommitteeProgram", "In Kommissionsprogramm aufgenommen", "messages", "de");
t.add("cantonal.affairs.status.initiativeToTheFederalAssembly", "Initiative an die Bundesversammlung", "messages", "de");
t.add("cantonal.affairs.status.annualReport", "Jahresbericht", "messages", "de");
t.add("affair.event.pressRelease.link.tooltip", "Offizielle Quelle \u00f6ffnen", "messages", "de");
t.add("pages.access_restricted.no_permission", "Kein Zugriff auf den Inhalt dieser Seite", "messages", "de");
t.add("pages.access_restricted.contact_admin", "F\u00fcr Hilfe wenden Sie sich bitte an den Admin Ihres Unternehmens.", "messages", "de");
t.add("pages.access_restricted.interested_in_access", "Wenn Sie an einer Lizenz f\u00fcr politik.ch interessiert sind, stellen wir Ihnen sehr gerne unser Produkt vor und laden Sie ein, es zu testen.", "messages", "de");
t.add("pages.access_restricted.contact_us", "Bitte kontaktieren Sie uns per E-Mail unter info@politik.ch oder per Telefon (+41) 044 512 12 60.", "messages", "de");
t.add("cantonal.affairs.status.swearingIn", "Vereidigung", "messages", "de");
t.add("shared.filters.show.linked_affairs", "Zeige alle Verkn\u00fcpfungen zwischen Gesch\u00e4ften", "messages", "de");
t.add("reports.sections.report_name.error.name_exists", "Ein Bericht mit diesem Namen existiert bereits. Bitte w\u00e4hlen Sie einen anderen Namen.", "messages", "de");
t.add("dashboard.table.type.insert", "Einf\u00fcgen", "messages", "de");
t.add("dashboard.table.type.update", "Update", "messages", "de");
t.add("dashboard.table.type.remove", "Entfernen", "messages", "de");
t.add("layout.search.type.allAffairs", "Alle Gesch\u00e4fte", "messages", "de");
t.add("layout.search.config.delete.exists_in_dashboard.message", "Diese Suchvorlage wird f\u00fcr einen Quicklink im Dashboard verwendet. Sind Sie sicher, dass Sie sie l\u00f6schen m\u00f6chten? F\u00fcr den Dashboard-Quicklink wird dann der Standard wiederhergestellt.", "messages", "de");
t.add("shared.affair.commissions", "Einreichende Kommission", "messages", "de");
t.add("shared.affair.departments", "Zust\u00e4ndige Beh\u00f6rde", "messages", "de");
t.add("shared.affair.agenda", "Traktandiert", "messages", "de");
t.add("shared.affair.description", "Eingereichter Text ", "messages", "de");
t.add("visualizer_intro.label", "Begr\u00fc\u00dfungstext f\u00fcr den Visual Report", "messages", "de");
t.add("visualizer_intro_title.label", "Titel f\u00fcr den Intro-Text des Visual Report", "messages", "de");
t.add("reports.sections.sessions.title", "Auf welche Session soll sich dieser Bericht beziehen?", "messages", "de");
t.add("customField.setings.affairType.label", "Genutzt in Gesch\u00e4ftsarten", "messages", "de");
t.add("internalFields.TextField.label", "Textfeld", "messages", "de");
t.add("internalFields.OptionsField.label", "Auswahlfeld", "messages", "de");
t.add("internalFields.AttachmentField.label", "Angeh\u00e4ngte Dateien", "messages", "de");
t.add("layout.affair.stat.label", "Status des Gesch\u00e4fts", "messages", "de");
t.add("layout.affair.latestEvent.name", "Titel des letzten Ereignisses", "messages", "de");
t.add("reports.sessionProgram.council.notDefined", "Erstbehandelnder Rat nicht festgelegt", "messages", "de");
t.add("api.profile.settings.company.internal_fields.html.delete.success", "Das benutzerdefinierte Feld wurde gel\u00f6scht", "messages", "de");
t.add("api.profile.settings.company.internal_fields.html.delete.error", "Beim Versuch, das benutzerdefinierte Feld zu l\u00f6schen, ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.", "messages", "de");
t.add("panel.additionalInfoLink.label", "Details", "messages", "de");
t.add("outputInternalAffairAuthors", "interner Autor (Ersteller)", "messages", "de");
t.add("outputAuthors", "Autor", "messages", "de");
t.add("reports.sections.show_agenda.toggle.title", "Sessionsvorschau einblenden", "messages", "de");
t.add("shared.agenda.session_program.title", "W\u00e4hrend der Session traktandierte und behandelte Gesch\u00e4fte:", "messages", "de");
t.add("shared.agenda.deposited.title", "W\u00e4hrend der Session neu eingereichte Gesch\u00e4fte:", "messages", "de");
t.add("shared.agenda.confirm_dialog.title", "Gesch\u00e4ft kann nicht angezeigt werden", "messages", "de");
t.add("shared.agenda.confirm_dialog.description", "Dieses Gesch\u00e4ft wurde w\u00e4hrend der Session behandelt und vom Autor des Reports auch diesem Report zugeordnet. Es fehlen allerdings die notwendigen Daten um es innerhalb der Grafik anzuzeigen. dies kann nur vom Autor des Gesch\u00e4fts angepasst werden.", "messages", "de");
t.add("shared.agenda.confirm_dialog.button.text", "Alles klar!", "messages", "de");
t.add("shared.agenda.deposited.no_data_available.text", "Keines der hier dargestellten Gesch\u00e4fte wurde w\u00e4hrend der ausgew\u00e4hlten Session eingereicht. ", "messages", "de");
t.add("shared.agenda.session_program.no_data_available.text", "Keines der angezeigten Gesch\u00e4fte wurde w\u00e4hrend der Session behandelt.", "messages", "de");
t.add("reports.groups.outputInternalAffairAuthors.label", "Autoren\/Ersteller von internen Gesch\u00e4ften", "messages", "de");
t.add("affair.SRDebateEvent.tooltip.caption", "Debatte im St\u00e4nderat", "messages", "de");
t.add("cantonal.affairs.status.replyDemanded", "Antwort wird erwartet", "messages", "de");
t.add("affair.SRCouncilVote.tooltip.caption", "Abstimmung St\u00e4nderat", "messages", "de");
t.add("affair.NationalDebateEvent.tooltip.caption", "Debatte Nationalrat", "messages", "de");
t.add("affair.NRCouncilVote.tooltip.caption", "Abstimmung Nationalrat", "messages", "de");
t.add("custom.affairs.form.link", "Link zur Quelle", "messages", "de");
t.add("affair.NationalAffairConsultationNoteCustomEvent.tooltip.caption", "interne Notiz", "messages", "de");
t.add("affair.CantonalAffairConsultationNoteCustomEvent.tooltip.caption", "interne Notiz", "messages", "de");
t.add("cantonal.affairs.status.postponed", "Verschoben", "messages", "de");
t.add("api.links.post.success", "Gesch\u00e4ft erfolgreich verlinkt", "messages", "de");
t.add("cantonal.affairs.type.regbes.title", "Regierungsratsbeschluss", "messages", "de");
t.add("cantonal.media_releases.title", "Medienmitteilungen", "messages", "de");
t.add("cantonal.media_releases.table.header.title", "Titel", "messages", "de");
t.add("cantonal.media_releases.table.header.date", "Ver\u00f6ffentlicht am", "messages", "de");
t.add("cantonal.media_releases.table.header.link", "Link zur Kantonswebseite", "messages", "de");
t.add("cantonal.overview.mediaRelease", "Medienmitteilungen", "messages", "de");
t.add("api.cantonal_media_release.get.error", "Medienmitteilung konnte nicht geladen werden", "messages", "de");
t.add("notification.tableOfContents.cantonalMediaRelease.new", "Neue Medienmitteilung ver\u00f6ffentlich", "messages", "de");
t.add("notification.tableOfContents.cantonalMediaRelease.updated", "Medienmitteilung wurde aktualisiert", "messages", "de");
t.add("cantonal.mediaRelease.itemAdded", "Medienmitteilung zum Gesch\u00e4ft ver\u00f6ffentlicht", "messages", "de");
t.add("cantonal.affairs.type.regbes.abbr", "Regierungsratsbeschluss", "messages", "de");
t.add("national.affairs.author.published.by", "Ver\u00f6ffentlicht von", "messages", "de");
t.add("helpScout.suggestedForYou", "Antworten", "messages", "de");
t.add("helpScout.getInTouch", "Kontaktieren", "messages", "de");
t.add("helpScout.searchLabel", "Wie k\u00f6nnen wir dir helfen?", "messages", "de");
t.add("helpScout.tryAgain", "Erneut versuchen", "messages", "de");
t.add("helpScout.defaultMessageErrorText", "Beim Senden deiner Nachricht ist ein Problem aufgetreten. Bitte versuche es in K\u00fcrze erneut.", "messages", "de");
t.add("helpScout.beaconButtonClose", "Schliessen", "messages", "de");
t.add("helpScout.beaconButtonChatMinimize", "Chat minimieren", "messages", "de");
t.add("helpScout.beaconButtonChatOpen", "Chat \u00f6ffnen", "messages", "de");
t.add("helpScout.answer", "Antworten", "messages", "de");
t.add("helpScout.ask", "Fragen", "messages", "de");
t.add("helpScout.messageButtonLabel", "Email", "messages", "de");
t.add("helpScout.noTimeToWaitAround", "Keine Zeit zum Warten? Wir antworten in der Regel innerhalb weniger Stunden", "messages", "de");
t.add("helpScout.chatButtonLabel", "Chat", "messages", "de");
t.add("helpScout.chatButtonDescription", "Wir sind gerade online, sprich jetzt mit unserem Team", "messages", "de");
t.add("helpScout.wereHereToHelp", "Eine Konversation starten", "messages", "de");
t.add("helpScout.whatMethodWorks", "Welchen Kanal bevorzugst du?", "messages", "de");
t.add("helpScout.previousMessages", "Fr\u00fchere Konversationen", "messages", "de");
t.add("helpScout.cantFindAnswer", "Keine Antwort gefunden?", "messages", "de");
t.add("helpScout.relatedArticles", "Verwandte Artikel", "messages", "de");
t.add("helpScout.nothingFound", "Hmm...", "messages", "de");
t.add("helpScout.docsSearchEmptyText", "Wir konnten keine Artikel finden, die deiner Suche entsprechen.", "messages", "de");
t.add("helpScout.tryBroaderTerm", "Versuche, einen breiteren Begriff zu suchen, oder", "messages", "de");
t.add("helpScout.docsArticleErrorText", "Beim Abrufen dieses Artikels ist ein Problem aufgetreten. \u00dcberpr\u00fcfe bitte deine Internetverbindung und versuche es erneut.", "messages", "de");
t.add("helpScout.docsSearchErrorText", "Beim Abrufen der Artikel ist ein Problem aufgetreten. Bitte \u00fcberpr\u00fcfe deine Internetverbindung und versuche es noch einmal.", "messages", "de");
t.add("helpScout.escalationQuestionFeedback", "Wurde deine Frage damit beantwortet?", "messages", "de");
t.add("helpScout.escalationQuestionFeedbackNo", "Nein", "messages", "de");
t.add("helpScout.escalationQuestionFeedbackYes", "Ja", "messages", "de");
t.add("helpScout.escalationSearchText", "Durchsuche unsere Hilfedokumente nach einer Antwort auf deine Frage", "messages", "de");
t.add("helpScout.escalationSearchTitle", "Weiter suchen", "messages", "de");
t.add("helpScout.escalationTalkText", "Sprich mit einem freundlichen Mitglied unseres Support-Teams", "messages", "de");
t.add("helpScout.escalationTalkTitle", "Sprich mit uns", "messages", "de");
t.add("helpScout.escalationThanksFeedback", "Vielen Dank f\u00fcr dein Feedback", "messages", "de");
t.add("helpScout.escalationWhatNext", "Wie geht es weiter?", "messages", "de");
t.add("helpScout.sendAMessage", "Sende eine Nachricht", "messages", "de");
t.add("helpScout.firstAFewQuestions", "Lass uns mit ein paar Fragen beginnen", "messages", "de");
t.add("helpScout.howCanWeHelp", "Wie k\u00f6nnen wir dir helfen?", "messages", "de");
t.add("helpScout.responseTime", "Wir antworten in der Regel innerhalb von ein paar Stunden", "messages", "de");
t.add("helpScout.history", "Geschichte", "messages", "de");
t.add("helpScout.uploadAnImage", "Ein Bild hochladen", "messages", "de");
t.add("helpScout.attachAFile", "Eine Datei anh\u00e4ngen", "messages", "de");
t.add("helpScout.continueEditing", "Weiter schreiben...", "messages", "de");
t.add("helpScout.lastUpdated", "Zuletzt aktualisiert", "messages", "de");
t.add("helpScout.you", "Du", "messages", "de");
t.add("helpScout.nameLabel", "Name", "messages", "de");
t.add("helpScout.subjectLabel", "Betreff", "messages", "de");
t.add("helpScout.emailLabel", "E-Mail Adresse", "messages", "de");
t.add("helpScout.messageLabel", "Wie k\u00f6nnen wir helfen?", "messages", "de");
t.add("helpScout.messageSubmitLabel", "Eine Nachricht senden", "messages", "de");
t.add("helpScout.next", "Weiter", "messages", "de");
t.add("helpScout.weAreOnIt", "Wir sind dabei!", "messages", "de");
t.add("helpScout.messageConfirmationText", "Du wirst innerhalb weniger Stunden eine Antwort per E-Mail erhalten.", "messages", "de");
t.add("helpScout.viewAndUpdateMessage", "Du kannst deine Nachricht in der folgenden Rubrik einsehen und aktualisieren", "messages", "de");
t.add("helpScout.mayNotBeEmpty", "Darf nicht leer sein", "messages", "de");
t.add("helpScout.customFieldsValidationLabel", "Bitte f\u00fclle alle Felder aus", "messages", "de");
t.add("helpScout.emailValidationLabel", "Bitte verwende eine g\u00fcltige E-Mail-Adresse", "messages", "de");
t.add("helpScout.attachmentErrorText", "Beim Hochladen deines Anhangs ist ein Problem aufgetreten. Bitte versuche es in K\u00fcrze erneut.", "messages", "de");
t.add("helpScout.attachmentSizeErrorText", "Die Anh\u00e4nge d\u00fcrfen nicht gr\u00f6\u00dfer als 10 MB sein.", "messages", "de");
t.add("helpScout.addReply", "Eine Antwort hinzuf\u00fcgen", "messages", "de");
t.add("helpScout.addYourMessageHere", "F\u00fcge hier deine Nachricht hinzu...", "messages", "de");
t.add("helpScout.sendMessage", "Nachricht senden", "messages", "de");
t.add("helpScout.received", "Empfangen", "messages", "de");
t.add("helpScout.waitingForAnAnswer", "Warte auf Antwort", "messages", "de");
t.add("helpScout.previousMessageErrorText", "Beim Abrufen dieser Nachricht ist ein Problem aufgetreten. \u00dcberpr\u00fcfe bitte deine Internetverbindung und versuche es erneut.", "messages", "de");
t.add("helpScout.justNow", "Gerade jetzt", "messages", "de");
t.add("helpScout.chatHeadingTitle", "Chatte mit unserem Team", "messages", "de");
t.add("helpScout.chatHeadingSublabel", "Wir sind bald f\u00fcr dich da", "messages", "de");
t.add("helpScout.chatEndCalloutHeading", "Alles erledigt!", "messages", "de");
t.add("helpScout.chatEndCalloutMessage", "Eine Kopie dieses Gespr\u00e4chs wird in K\u00fcrze in deinem Posteingang landen.", "messages", "de");
t.add("helpScout.chatEndCalloutLink", "Zur\u00fcck nach Hause", "messages", "de");
t.add("helpScout.chatEndUnassignedCalloutHeading", "Das tut mir leid", "messages", "de");
t.add("helpScout.chatEndUnassignedCalloutMessage", "Es sieht so aus, als h\u00e4tte es niemand in deinen Chat geschafft. Wir werden dir so bald wie m\u00f6glich eine Antwort per E-Mail schicken.", "messages", "de");
t.add("helpScout.chatEndWaitingCustomerHeading", "Entschuldige bitte", "messages", "de");
t.add("helpScout.chatEndWaitingCustomerMessage", "Deine Frage wurde in unsere E-Mail-Warteschlange aufgenommen und wir werden uns in K\u00fcrze mit dir in Verbindung setzen.", "messages", "de");
t.add("helpScout.ending", "Beenden...", "messages", "de");
t.add("helpScout.endChat", "Chat beenden", "messages", "de");
t.add("helpScout.chatEnded", " hat den Chat beendet", "messages", "de");
t.add("helpScout.chatConnected", "Verbunden mit", "messages", "de");
t.add("helpScout.chatbotName", "Hilfe-Bot", "messages", "de");
t.add("helpScout.chatbotGreet", "Hallo, du kannst damit beginnen, deine Frage unten zu stellen. Jemand wird sich in K\u00fcrze um dich k\u00fcmmern.", "messages", "de");
t.add("helpScout.chatbotPromptEmail", "Ich hab's. Ganz schnell: Wie lautet deine E-Mail-Adresse? Wir verwenden sie f\u00fcr alle weiteren Nachrichten.", "messages", "de");
t.add("helpScout.chatbotConfirmationMessage", "Vielen Dank! Jemand aus unserem Team wird sich gleich in den Chat einschalten.", "messages", "de");
t.add("helpScout.chatbotGenericErrorMessage", "Beim Senden deiner Nachricht ist etwas schief gegangen. Bitte versuche es in ein paar Minuten noch einmal.", "messages", "de");
t.add("helpScout.chatbotInactivityPrompt", "Da der Chat nicht mehr aktiv ist, werde ich diesen Chat in ein paar Minuten beenden.", "messages", "de");
t.add("helpScout.chatbotInvalidEmailMessage", "Sieht aus, als h\u00e4ttest du eine ung\u00fcltige E-Mail-Adresse eingegeben. M\u00f6chtest du es noch einmal versuchen?", "messages", "de");
t.add("helpScout.chatbotAgentDisconnectedMessage", " hat die Verbindung zum Chat unterbrochen. Es ist m\u00f6glich, dass die Internetverbindung unterbrochen wurde, deshalb suche ich jemanden, der den Chat \u00fcbernimmt. Entschuldige die Verz\u00f6gerung.", "messages", "de");
t.add("helpScout.chatAvailabilityChangeMessage", "Die Verf\u00fcgbarkeit unseres Teams hat sich ge\u00e4ndert und es ist niemand mehr f\u00fcr den Chat verf\u00fcgbar. Schick uns stattdessen eine Nachricht und wir melden uns bei dir.", "messages", "de");
t.add("helpScout.emailHeading", "Der heutige Chat mit", "messages", "de");
t.add("helpScout.emailGreeting", "Hallo", "messages", "de");
t.add("helpScout.emailCopyOfDiscussion", "Hier ist eine Kopie eures Gespr\u00e4chs", "messages", "de");
t.add("helpScout.emailContinueConversation", "Wenn du weitere Fragen hast, kannst du auf \"Antworten\" klicken und die Unterhaltung fortsetzen.", "messages", "de");
t.add("helpScout.emailJoinedLineItem", " dem Chat beigetreten", "messages", "de");
t.add("helpScout.emailEndedLineItem", " beendete den Chat", "messages", "de");
t.add("helpScout.emailYou", "Du", "messages", "de");
t.add("Audit entry item class", "Audit entry item class", "admin", "de");
t.add("Audit entry item id", "Audit entry item id", "admin", "de");
t.add("Select audit entry item class", "Select audit entry item class", "admin", "de");
t.add("dataQuality.scopeType.national", "National", "admin", "de");
t.add("dataQuality.scopeType.cantonal", "Cantonal", "admin", "de");
t.add("dataQuality.scopeType.communal", "Communal", "admin", "de");
t.add("affairs.followingHaveBeenUpdated", "Die folgenden Gesch\u00e4fte wurden aktualisiert", "email", "de");
t.add("national.affairs.followingHaveBeenUpdated", "Die folgenden nationalen Gesch\u00e4fte wurden aktualisiert", "email", "de");
t.add("newAffairs.subject", "politik.ch - Updates kantonale Gesch\u00e4fte", "email", "de");
t.add("notification.openingGreeting", "Guten Tag", "email", "de");
t.add("votes.followingHaveBeenAdded", "Die folgenden Abstimmungen wurden auf politik.ch hinzugef\u00fcgt", "email", "de");
t.add("votes.followingHaveBeenUpdated", "Die folgenden Abstimmungen wurden auf politik.ch aktualisiert", "email", "de");
t.add("votes.votedOn", "abgestimmt am", "email", "de");
t.add("readMoreOn", "Lesen Sie mehr unter", "email", "de");
t.add("affairs.followingHaveBeenAdded", "Die folgenden Gesch\u00e4fte wurden auf politik.ch hinzugef\u00fcgt", "email", "de");
t.add("affairs.cantonal.itemAdded", "Kanton %abbr%: %title% (%type%)", "email", "de");
t.add("national.affairs.followingHaveBeenAdded", "Die folgenden Gesch\u00e4fte wurden auf politik.ch hinzugef\u00fcgt", "email", "de");
t.add("national.affairs.itemAdded", "%idParlService%: %title% (%type%)", "email", "de");
t.add("affairGroups.followingHaveBeenAdded", "Die folgenden Gruppen wurden auf politik.ch hinzugef\u00fcgt", "email", "de");
t.add("affairGroups.followingHaveBeenUpdated", "Die folgenden Gruppen wurden aktualisiert", "email", "de");
t.add("affairGroups.itemAdded", "%title%", "email", "de");
t.add("national.affairs.new.subject", "politik.ch - Updates nationale Gesch\u00e4fte", "email", "de");
t.add("national.affairConsultations.followingHaveBeenAdded", "Die folgenden nationalen Vernehmlassungen wurden neu hinzugef\u00fcgt", "email", "de");
t.add("national.affairConsultations.followingHaveBeenUpdated", "Die folgenden nationalen Vernehmlassungen wurden aktualisiert", "email", "de");
t.add("national.affairConsultations.itemAdded", "%title%", "email", "de");
t.add("national.affairConsultations.new.subject", "politik.ch - Neue Vernehmlassungen", "email", "de");
t.add("national.votes.nationalcouncil.new.subject", "politik.ch - Neue Abstimmungen Nationalrat", "email", "de");
t.add("national.votes.councilstates.new.subject", "politik.ch - Neue Abstimmungen St\u00e4nderat", "email", "de");
t.add("eventReminders.heading", "Wir m\u00f6chten Sie an die folgenden Ereignisse erinnern", "email", "de");
t.add("eventReminders.new.subject", "politik.ch - Neue Erinnerungen", "email", "de");
t.add("eventReminders.link.label", "Erinnerung an %eventTitle% am %dateTime%", "email", "de");
t.add("notification.operation.add", "hinzugef\u00fcgt", "email", "de");
t.add("notification.operation.replace", "ersetzt", "email", "de");
t.add("notification.operation.remove", "Gel\u00f6scht", "email", "de");
t.add("notification.author_full_name", "Autor\/in", "email", "de");
t.add("notification.title_de", "Titel DE", "email", "de");
t.add("notification.title_fr", "Titel FR", "email", "de");
t.add("notification.title_it", "Titel IT", "email", "de");
t.add("notification.description_de", "Beschreibung DE", "email", "de");
t.add("notification.description_fr", "Beschreibung FR", "email", "de");
t.add("notification.description_it", "Beschreibung IT", "email", "de");
t.add("notification.reason_de", "Begr\u00fcndung DE", "email", "de");
t.add("notification.reason_fr", "Begr\u00fcndung FR", "email", "de");
t.add("notification.reason_it", "Begr\u00fcndung IT", "email", "de");
t.add("notification.short_id_parl_service", "ID Parlamentsdienste", "email", "de");
t.add("notification.affair_type", "Typ", "email", "de");
t.add("notification.priority_council", "Rat", "email", "de");
t.add("notification.affair_state", "Status", "email", "de");
t.add("notification.national_council_votes", "Abstimmung Nationalrat", "email", "de");
t.add("notification.council_states_votes", "Abstimmung St\u00e4nderat", "email", "de");
t.add("notification.history_events", "Ereignis", "email", "de");
t.add("notification.history_events.status", "Status Ereignis", "email", "de");
t.add("notification.history_events.council", "Ereignis: Rat", "email", "de");
t.add("notification.history_events.status_text", "Text Ereignis", "email", "de");
t.add("notification.history_events.date_time", "Datum\/Zeit Ereignis", "email", "de");
t.add("notification.history_events.national_session", "Ereignis: Nationale Session", "email", "de");
t.add("notification.history_events.national_committee", "Ereignis: Nationale Kommission", "email", "de");
t.add("notification.drafts", "Entwurf", "email", "de");
t.add("notification.drafts.texts", "Text Entwurf", "email", "de");
t.add("notification.drafts.texts.value_de", "Text Entwurf DE", "email", "de");
t.add("notification.drafts.texts.value_fr", "Text Entwurf FR", "email", "de");
t.add("notification.drafts.texts.value_it", "Text Entwurf IT", "email", "de");
t.add("notification.drafts.related_departments", "Entwurf: Zust\u00e4ndige Departemente", "email", "de");
t.add("notification.drafts.resolutions", "Beschluss", "email", "de");
t.add("notification.drafts.resolutions.date", "Datum Beschluss", "email", "de");
t.add("notification.drafts.resolutions.resolution_text", "Beschluss Text", "email", "de");
t.add("notification.drafts.resolutions.resolution_text.text_de", "Beschluss Text DE", "email", "de");
t.add("notification.drafts.resolutions.resolution_text.text_fr", "Beschluss Text FR", "email", "de");
t.add("notification.drafts.resolutions.resolution_text.text_it", "Beschluss Text IT", "email", "de");
t.add("notification.drafts.pre_consultations", "Entwurf Vorberatung", "email", "de");
t.add("notification.drafts.references", "Entwurf", "email", "de");
t.add("notification.drafts.references.title_de", "Entwurf Referenz Titel DE", "email", "de");
t.add("notification.drafts.references.title_fr", "Entwurf Referenz Titel DE", "email", "de");
t.add("notification.drafts.references.title_it", "Entwurf Referenz Titel IT", "email", "de");
t.add("notification.drafts.federal_council_proposal", "Entwurf Bundesrat", "email", "de");
t.add("notification.drafts.federal_council_proposal.date", "Datum Entwurf Bundesrat", "email", "de");
t.add("notification.drafts.federal_council_proposal.summary_de", "Zusammenfassung Entwurf Bundesrat DE", "email", "de");
t.add("notification.drafts.federal_council_proposal.summary_fr", "Zusammenfassung Entwurf Bundesrat FR", "email", "de");
t.add("notification.drafts.federal_council_proposal.summary_it", "Zusammenfassung Entwurf Bundesrat IT", "email", "de");
t.add("notification.drafts.federal_council_proposal.text_de", "Entwurf Bundesrat DE", "email", "de");
t.add("notification.drafts.federal_council_proposal.text_fr", "Entwurf Bundesrat FR", "email", "de");
t.add("notification.drafts.federal_council_proposal.text_it", "Entwurf Bundesrat IT", "email", "de");
t.add("notification.title", "Titel", "email", "de");
t.add("notification.text", "Text", "email", "de");
t.add("notification.code", "Code", "email", "de");
t.add("notification.internal_id", "Interne ID", "email", "de");
t.add("notification.status", "Status Vernehmlassung", "email", "de");
t.add("notification.main_attachment", "Anhang", "email", "de");
t.add("notification.attachments", "Weiterer Anhang", "email", "de");
t.add("notification.attachments.real_hash", "Inhalt Anhang", "email", "de");
t.add("notification.authors", "Autor\/in", "email", "de");
t.add("notification.history_events.cantonal_session_program", "Kantonales Sessionsprogramm", "email", "de");
t.add("notification.history_events.attachments", "Anhang Ereignis", "email", "de");
t.add("notification.history_events.attachments.real_hash", "Dokument \/ Datei", "email", "de");
t.add("notification.affair_groups", "Zuweisung Gruppe", "email", "de");
t.add("notification.affair_groups.parentId", "Zuweisung \u00fcbergeordnete Gruppe", "email", "de");
t.add("notification.children", "Zuweisung Gruppe", "email", "de");
t.add("notification.documents", "Dokument Vernehmlassung", "email", "de");
t.add("notification.opinions", "Antworten Vernehmlassung", "email", "de");
t.add("notification.description", "Beschreibung Vernehmlassung", "email", "de");
t.add("notification.office", "Amt Vernehmlassung", "email", "de");
t.add("notification.author", "Autor\/in Vernehmlassung", "email", "de");
t.add("notification.author.full_name", "Autor\/in Vernehmlassung", "email", "de");
t.add("notification.author.type", "Autor\/in Typ Vernehmlassung", "email", "de");
t.add("notification.laws", "Gesetz Vernehmlassung", "email", "de");
t.add("notification.laws.law_id", "Gesetz Vernehmlassung", "email", "de");
t.add("notification.remarks", "Bemerkung Vernehmlassung", "email", "de");
t.add("notification.affair_groups.parent", "Zuweisung \u00fcbergeordnete Gruppe", "email", "de");
t.add("2fa.authCode.subject", "Ihr Auth-Code f\u00fcr politik.ch", "email", "de");
t.add("notification.custom_links", "Verlinktes Gesch\u00e4ft", "email", "de");
t.add("notification.tableOfContents.cantonalAffairConsultations.new", "Neue kantonale Vernehmlassung", "email", "de");
t.add("notification.tableOfContents.cantonalAffairConsultations.updated", "Kantonale Vernehmlassung aktualisiert", "email", "de");
t.add("affairConsultations.cantonal.itemAdded", "Kanton %abbr%: %title%", "email", "de");
t.add("notification.links", "notification.links", "email", "de");
t.add("notification.tableOfContents.cantonalAffairs.new", "Neue kantonale Gesch\u00e4fte", "email", "de");
t.add("notification.tableOfContents.cantonalAffairs.updated", "Aktualisierte kantonale Gesch\u00e4fte", "email", "de");
t.add("notification.tableOfContents.nationalAffairs.new", "Neue nationale Gesch\u00e4fte", "email", "de");
t.add("notification.tableOfContents.nationalAffairs.updated", "Aktualisierte nationale Gesch\u00e4fte", "email", "de");
t.add("notification.tableOfContents.nationalAffairConsultations.new", "Neue nationale Vernehmlassungen", "email", "de");
t.add("notification.tableOfContents.nationalAffairConsultations.updated", "Aktualisierte nationale Vernehmlassungen", "email", "de");
t.add("notification.tableOfContents.affairGroups.new", "Neue Gruppen", "email", "de");
t.add("notification.tableOfContents.affairGroups.updated", "Aktualisierte Gruppen", "email", "de");
t.add("notification.followingThemes", "Gerne informieren wir Sie zu folgenden Themen", "email", "de");
t.add("national.affairs.itemAddedLink", "%idParlService%", "email", "de");
t.add("national.affairs.itemAddedTitle", ": %title% (%type%)", "email", "de");
t.add("resetting.email.subject", "Passwort zur\u00fccksetzen", "email", "de");
t.add("resetting.email.message", "Hallo %username%!\n\nBesuchen Sie bitte folgende Seite, um Ihr Passwort zur\u00fcckzusetzen: %confirmationUrl%\n\nMit besten Gr\u00fcssen\npolitik.ch", "email", "de");
t.add("affairs.custom.itemAdded", "%title%", "email", "de");
t.add("notification.tableOfContents.eventReminders.new", "Erinnerungen", "email", "de");
t.add("notification.tableOfContents.nationalVotes.new", "Nationales Parlament: Abstimmungen", "email", "de");
t.add("notification.national_council_debates", "Debatte Nationalrat", "email", "de");
t.add("notification.council_states_debates", "Debatte St\u00e4nderat", "email", "de");
t.add("notification.assignedAffairs.itemAdded", "Benutzer %assignedUser% wurde zum Gesch\u00e4ft %affair% hinzugef\u00fcgt.", "email", "de");
t.add("notification.tableOfContents.assignedAffairs.new", "Neu zugewiesene Gesch\u00e4fte", "email", "de");
t.add("notification.tableOfContents.assignedAffairs.updated", "Aktualisierte zugewiesene Gesch\u00e4fte", "email", "de");
t.add("national.committeePressReleases.itemAdded", "%title%", "email", "de");
t.add("notification.tableOfContents.nationalCommitteePressReleases.new", "Neue Medienmitteilungen Kommissionen", "email", "de");
t.add("notification.tableOfContents.nationalCommitteePressReleases.updated", "Aktualisierte Medienmitteilungen Kommissionen", "email", "de");
t.add("notification.tableOfContents.nationalAffairsDepositTexts.new", "Nationale Gesch\u00e4fte mit neu eingereichtem Text", "email", "de");
t.add("notification.tableOfContents.cantonalMediaRelease.new", "Neue Medienmitteilung ver\u00f6ffentlich", "email", "de");
t.add("notification.tableOfContents.cantonalMediaRelease.updated", "Medienmitteilung wurde aktualisiert", "email", "de");
t.add("groups.form.title.cannotBeEmpty", "Titel kann nicht leer sein", "javascript", "de");
t.add("affair.internalNote.error", "Fehler Beschreibung", "javascript", "de");
t.add("modal.error.title", "Oh! Das ist ein Fehler!", "javascript", "de");
t.add("modal.error.message", "Soeben ist ein Fehler aufgetreten, wir bitten um Entschuldigung. Unsere Entwickler wurden bereits benachrichtigt. Bitte kontrollieren Sie diese Funktion sp\u00e4ter nochmals oder wenden Sie sich direkt an unseren Support: info@politik.ch oder 043 443 02 06.", "javascript", "de");
t.add("affairs.error.message", "Fehler beim Laden von Gesch\u00e4ften", "javascript", "de");
t.add("groups.error.message", "Fehler beim Laden der Gruppen", "javascript", "de");
t.add("groups.delete.message", "Sind Sie sicher, dass Sie die Gruppe l\u00f6schen m\u00f6chten?", "javascript", "de");
t.add("groups.delete.confirm", "L\u00f6schen", "javascript", "de");
t.add("groups.delete.cancel", "Abbrechen", "javascript", "de");
t.add("ranking.filter.groups.tooltip", "Es werden nur Gruppen angezeigt, welche mindestens ein Gesch\u00e4ft enthalten, das den anderen gew\u00e4hlten Filterkriterien (z.B. Gesch\u00e4ftsart) entspricht.", "javascript", "de");
t.add("affair.internalNote.saved", "Gespeichert", "javascript", "de");
t.add("datatables.language.emptyTable", "Keine Daten in der Tabelle vorhanden", "javascript", "de");
t.add("datatables.language.info", "_START_ bis _END_ von _TOTAL_ Eintr\u00e4gen", "javascript", "de");
t.add("datatables.language.infoEmpty", "Keine Daten vorhanden", "javascript", "de");
t.add("datatables.language.infoFiltered", "(gefiltert von _MAX_ Eintr\u00e4gen)", "javascript", "de");
t.add("datatables.language.lengthMenu", "_MENU_ Eintr\u00e4ge anzeigen", "javascript", "de");
t.add("datatables.language.loadingRecords", "Wird geladen ..", "javascript", "de");
t.add("datatables.language.processing", "Bitte warten ..", "javascript", "de");
t.add("datatables.language.search", "Suchen", "javascript", "de");
t.add("datatables.language.zeroRecords", "Keine Eintr\u00e4ge vorhanden", "javascript", "de");
t.add("datatables.language.paginate.first", "Erste", "javascript", "de");
t.add("datatables.language.paginate.last", "Letzte", "javascript", "de");
t.add("datatables.language.paginate.next", "N\u00e4chste", "javascript", "de");
t.add("datatables.language.paginate.previous", "Zur\u00fcck", "javascript", "de");
t.add("datatables.language.aria.", ": aktivieren, um Spalte absteigend zu sortieren", "javascript", "de");
t.add("charts.men", "Mann", "javascript", "de");
t.add("charts.women", "Frau", "javascript", "de");
t.add("charts.gender.title", "Geschlecht", "javascript", "de");
t.add("layout.logout", "Abmelden", "user", "de");
t.add("profile.edit.submit", "Benutzer aktualisieren", "user", "de");
t.add("security.login.email", "E-Mail", "user", "de");
t.add("security.login.password", "Passwort", "user", "de");
t.add("security.login.forgotpassword", "Passwort vergessen?", "user", "de");
t.add("security.login.remember_me", "An mich erinnern", "user", "de");
t.add("resetting.reset.submit", "Passwort \u00e4ndern", "user", "de");
t.add("resetting.request.username", "E-Mail-Adresse", "user", "de");
t.add("resetting.request.submit", "Passwort zur\u00fccksetzen", "user", "de");
t.add("change_password.submit", "Passwort \u00e4ndern", "user", "de");
})(Translator);
