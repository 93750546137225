(function (t) {
// it
t.add("vich_uploader.form_label.delete_confirm", "Rimuovere?", "messages", "it");
t.add("vich_uploader.link.download", "Scaricare", "messages", "it");
t.add("councillor.language.de", "Tedesco", "messages", "it");
t.add("councillor.language.en", "Inglese", "messages", "it");
t.add("councillor.language.fr", "Francese", "messages", "it");
t.add("councillor.language.it", "Italiano", "messages", "it");
t.add("councillor.activeTrue", "in carica", "messages", "it");
t.add("councillor.activeFalse", "Ex membro del Parlamento", "messages", "it");
t.add("councillor.birthDate", "Data di nascita", "messages", "it");
t.add("councillor.professions", "Professione", "messages", "it");
t.add("councillor.language", "Lingua", "messages", "it");
t.add("councillor.votes.absent", "Assenze", "messages", "it");
t.add("councillor.votes.abstain", "Astensioni", "messages", "it");
t.add("councillor.recentAffairs", "Interventi parlamentari depositati da %firstName% %lastName%", "messages", "it");
t.add("affair.depositDate", "Data del deposito", "messages", "it");
t.add("councillor.affairs", "Interventi parlamentari", "messages", "it");
t.add("councillor.affairTypes", "Tipi di intervento parlamentare", "messages", "it");
t.add("councillor.aTotalOfNAffairs", "Complessivamente %count% interventi parlamentari", "messages", "it");
t.add("councillor.topics", "Temi degli interventi parlamentari", "messages", "it");
t.add("councillor.affairs.success", "Successo degli interventi parlamentari", "messages", "it");
t.add("councillor.absences", "Assenze", "messages", "it");
t.add("councillor.absences.absentOn", "Nessuna partecipazione %count% su %total% votazioni", "messages", "it");
t.add("councillor.absences.absent", "Nessuna partecipazione", "messages", "it");
t.add("councillor.abstentions", "Astensioni", "messages", "it");
t.add("councillor.abstentions.abstainedOn", "Astensioni %count% su %total% votazioni", "messages", "it");
t.add("councillor.abstentions.abstained", "Astensioni", "messages", "it");
t.add("layout.source", "Fonte", "messages", "it");
t.add("layout.source.sentence", "Servizi parlamentari dell'Assemblea federale", "messages", "it");
t.add("layout.lastUpdate", "Ultimo aggiornamento", "messages", "it");
t.add("profile.settings.save", "Salvare", "messages", "it");
t.add("factions.title", "Gruppi parlamentari", "messages", "it");
t.add("factions.nationalCouncil.countMembers", "Consigliere\/a nazionale|Consiglieri nazionali", "messages", "it");
t.add("factions.councilOfStates.countMembers", "Deputato\/a Consiglio degli Stati| Deputati Consiglio degli Stati", "messages", "it");
t.add("cantons.title", "Cantoni", "messages", "it");
t.add("cantons.nationalCouncil.countMembers", "Consigliere\/a nazionale|Consiglieri nazionali", "messages", "it");
t.add("cantons.councilOfStates.countMembers", "Deputato\/a Consiglio degli Stati| Deputati Consiglio degli Stati", "messages", "it");
t.add("layout.account", "Profilo", "messages", "it");
t.add("layout.userdashboard", "Dashboard", "messages", "it");
t.add("layout.admindashboard", "Admin Dashboard", "messages", "it");
t.add("layout.admindashboard.shortcut", "AD", "messages", "it");
t.add("parties.title", "Partiti", "messages", "it");
t.add("parties.nationalCouncil.countMembers", "Consigliere\/a nazionale|Consiglieri nazionali", "messages", "it");
t.add("parties.councilOfStates.countMembers", "Deputato\/a Consiglio degli Stati| Deputati Consiglio degli Stati", "messages", "it");
t.add("cantonal.listCouncillors.title", "Parlamentari Canton %canton%", "messages", "it");
t.add("affair.status", "Stato oggetto", "messages", "it");
t.add("affair.genericAttachments", "Allegati ulteriori", "messages", "it");
t.add("affair.genericAttachments.download", "Scaricare allegato #%number%", "messages", "it");
t.add("affair.originalSource", "Al documento originale", "messages", "it");
t.add("cantonal.affairs", "Oggetti cantonali", "messages", "it");
t.add("cantonal.overview.affairs", "Oggetti", "messages", "it");
t.add("cantonal.overview.councillors", "Parlamentari", "messages", "it");
t.add("cantonal.listAffairs.title", "Oggetti Canton %canton%", "messages", "it");
t.add("cantons.nationalcouncil.members", "Consiglieri nazionali", "messages", "it");
t.add("cantons.councilofstates.members", "Deputati Consiglio degli Stati", "messages", "it");
t.add("layout.menu.nationalcouncil", "Consiglio nazionale", "messages", "it");
t.add("layout.menu.nationalcouncil.votes", "Votazioni CN", "messages", "it");
t.add("layout.menu.nationalcouncil.votes.shortcut", "V", "messages", "it");
t.add("layout.menu.nationalcouncil.programs", "Programma sessione CN", "messages", "it");
t.add("layout.menu.nationalcouncil.programs.shortcut", "P", "messages", "it");
t.add("layout.menu.nationalcouncil.rankings", "Statistiche CN", "messages", "it");
t.add("layout.menu.nationalcouncil.rankings.shortcut", "S", "messages", "it");
t.add("layout.menu.nationalcouncil.rankings.factions", "Coalizioni: Quali maggioranze hanno successo?", "messages", "it");
t.add("layout.menu.nationalcouncil.rankings.councillors", "Statistica votazioni parlamentari", "messages", "it");
t.add("layout.menu.nationalcouncil.rankings.votes", "Statistica votazioni: Votazioni risicate", "messages", "it");
t.add("layout.menu.councilofstates", "Consiglio degli Stati", "messages", "it");
t.add("layout.menu.councilofstates.votes", "Votazioni CSt", "messages", "it");
t.add("layout.menu.councilofstates.votes.shortcut", "V", "messages", "it");
t.add("layout.menu.councilofstates.programs", "Programma sessione CSt", "messages", "it");
t.add("layout.menu.councilofstates.programs.shortcut", "P", "messages", "it");
t.add("layout.menu.councilofstates.rankings", "Statistiche CSt", "messages", "it");
t.add("layout.menu.councilofstates.rankings.shortcut", "S", "messages", "it");
t.add("layout.menu.councilstates.rankings.councillors", "Statistica votazioni parlamentari", "messages", "it");
t.add("layout.menu.councilstates.rankings.votes", "Statistica votazioni: Votazioni risicate", "messages", "it");
t.add("layout.menu.councillors", "Parlamentari", "messages", "it");
t.add("layout.menu.cantonalAffairs", "Cantoni", "messages", "it");
t.add("layout.search.query", "Termine di ricerca", "messages", "it");
t.add("layout.search.submit", "Cercare", "messages", "it");
t.add("parties.nationalcouncil.members", "Membri per partito Consiglio nazionale", "messages", "it");
t.add("parties.councilofstates.members", "Membri per partito Consiglio degli Stati", "messages", "it");
t.add("factions.nationalcouncil.members", "Membri della frazione Consiglio nazionale", "messages", "it");
t.add("factions.councilofstates.members", "Membri della frazione Consiglio degli Stati", "messages", "it");
t.add("vote.decision.yes", "S\u00ec", "messages", "it");
t.add("vote.decision.no", "No", "messages", "it");
t.add("sessions.nocouncil.title", "Votazioni", "messages", "it");
t.add("form.session.label", "%title% (%from% fino a %to%)", "messages", "it");
t.add("vote.session_stats", "Statistica della sessione", "messages", "it");
t.add("vote.new_councillors", "Parlamentari appena insediati", "messages", "it");
t.add("session.dayFilter.all", "Tutti", "messages", "it");
t.add("session.novotes", "In questa sessione di questo Consiglio non ci sono votazioni.", "messages", "it");
t.add("session.nohistoryEvents", "Nessun evento", "messages", "it");
t.add("form.firstName", "Nome", "messages", "it");
t.add("form.lastName", "Cognome", "messages", "it");
t.add("form.company", "Azienda", "messages", "it");
t.add("layout.search.contentType.national_affair", "Ricerca nazionale", "messages", "it");
t.add("layout.search.contentType.cantonal_affair", "Oggetti cantonali", "messages", "it");
t.add("layout.search.type", "Ricerca nazionale \/ cantonale", "messages", "it");
t.add("profile.edit.firstName", "Nome", "messages", "it");
t.add("profile.edit.lastName", "Cognome", "messages", "it");
t.add("profile.edit.pictureFile", "Immagine profilo", "messages", "it");
t.add("profile.settings.notificationPreference.none", "Nessuna", "messages", "it");
t.add("profile.settings.notificationPreference.keywords", "Parole chiave", "messages", "it");
t.add("profile.settings.notificationPreference.all", "Tutte le notifiche", "messages", "it");
t.add("profile.settings.notificationPreference.yes", "S\u00ec", "messages", "it");
t.add("profile.settings.notificationPreference.no", "No", "messages", "it");
t.add("ranking.narrowvotes", "Votazioni risicate", "messages", "it");
t.add("ranking.absent", "Nessuna partecipazione al voto", "messages", "it");
t.add("ranking.coalitions", "Coalizioni: Quali maggioranze hanno successo?", "messages", "it");
t.add("ranking.voteagainst", "Contro la propria frazione (statistica dei deviazionisti)", "messages", "it");
t.add("ranking.age", "Anno di nascita (et\u00e0)", "messages", "it");
t.add("ranking.membership", "Durata carica (nella Camera attuale)", "messages", "it");
t.add("ranking.abstain", "Astensione al voto", "messages", "it");
t.add("vote.decision.eh", "Astenuto", "messages", "it");
t.add("vote.decision.nt", "Assente", "messages", "it");
t.add("vote.decision.es", "Scusato", "messages", "it");
t.add("vote.decision.p", "Presidente", "messages", "it");
t.add("global.dates.format", "d.m.Y", "messages", "it");
t.add("global.dates.dayMonthFormat", "d.m.", "messages", "it");
t.add("global.dates.dateTimeFormat", "d.m.Y H:i", "messages", "it");
t.add("councillor.gender.m", "Uomini", "messages", "it");
t.add("councillor.gender.f", "Donne", "messages", "it");
t.add("layout.login.error.duplicated", "Un altro dispositivo ha effettuato l'accesso con il suo nome utente e la sua password. Per accedere di nuovo inserisca i suoi dati di accesso di seguito. Si prega di notare che l'altro dispositivo verr\u00e0 disconnesso.", "messages", "it");
t.add("session.stats.totalVotes", "Totale Votazioni", "messages", "it");
t.add("session.stats.finalGovernmentVotes", "Votazioni finali", "messages", "it");
t.add("councillor.affairSuccess.noVotes", "Non ancora trattato", "messages", "it");
t.add("councillor.affairSuccess.positiveVotes", "Vincente", "messages", "it");
t.add("councillor.affairSuccess.negativeVotes", "Nessun successo", "messages", "it");
t.add("profile.settings.save.success", "Salvato con successo", "messages", "it");
t.add("affair.submittedBy", "Depositato da %name% il %date%", "messages", "it");
t.add("affair.description", "Testo depositato", "messages", "it");
t.add("affair.reason", "Motivazione", "messages", "it");
t.add("affair.nationalCouncilDebate.showmore", "Mostrare di pi\u00f9", "messages", "it");
t.add("affair.noVotes", "Nessuna votazione disponibile.", "messages", "it");
t.add("affair.councilOfStatesVote", "Votazione nel Consiglio degli Stati", "messages", "it");
t.add("affair.nationalCouncilVote", "Votazione nel Consiglio nazionale", "messages", "it");
t.add("affair.nationalCouncilDebate", "Dibattito in Consiglio nazionale", "messages", "it");
t.add("affair.nationalCouncilDebate.showless", "mostrare di meno", "messages", "it");
t.add("affairs.nationalCouncilDebate.otherAffairs", "Oggetti ulteriori", "messages", "it");
t.add("affair.nationalCouncilDebate.speeches", "Discorsi", "messages", "it");
t.add("affair.nationalCouncilDebate.speechesByCouncillor", "Discorsi per parlamentari", "messages", "it");
t.add("vote.date", "Data votazione", "messages", "it");
t.add("vote.submission", "Progetto", "messages", "it");
t.add("vote.division", "Oggetto", "messages", "it");
t.add("vote.meaning.yes", "Significato s\u00ec", "messages", "it");
t.add("vote.meaning.no", "Significato No", "messages", "it");
t.add("affair.filters", "Filtro", "messages", "it");
t.add("affair.filterby", "Filtrare per...", "messages", "it");
t.add("affair.filter.all", "Tutti i risultati", "messages", "it");
t.add("affair.filter.factions", "Gruppi parlamentari", "messages", "it");
t.add("affair.filter.cantons", "Cantoni", "messages", "it");
t.add("affair.filter.dissenters", "contro la propria frazione (deviazionista)", "messages", "it");
t.add("affair.filter.language", "Lingua", "messages", "it");
t.add("affair.filter.gender", "Sesso", "messages", "it");
t.add("affair.results.all", "Tutti i risultati", "messages", "it");
t.add("affair.results.byfactions", "Risultato per frazione", "messages", "it");
t.add("affair.results.faction", "Frazione", "messages", "it");
t.add("vote.decision.abstained", "AS", "messages", "it");
t.add("vote.decision.abs_exc", "S\/NV", "messages", "it");
t.add("vote.decision.total", "Totale", "messages", "it");
t.add("vote.allCouncillors", "Tutti", "messages", "it");
t.add("affair.results.bycantons", "Risultato per cantone", "messages", "it");
t.add("affair.results.canton", "Cantone", "messages", "it");
t.add("affair.results.bydissenters", "Risultato per dissidenti", "messages", "it");
t.add("affair.results.bylanguage", "Risultato per lingua", "messages", "it");
t.add("affair.results.language", "Lingua", "messages", "it");
t.add("affair.results.bygender", "Risultato per sesso", "messages", "it");
t.add("affair.results.gender", "Sesso", "messages", "it");
t.add("global.filter.placeholder", "Filtrare ordine d'uscita...", "messages", "it");
t.add("affair.councillor.name", "Nome", "messages", "it");
t.add("affair.councillor.party", "Partito", "messages", "it");
t.add("affair.councillor.vote", "Voto", "messages", "it");
t.add("affair.councillor.canton", "Cantone", "messages", "it");
t.add("vote.compact.yes", "S\u00ec", "messages", "it");
t.add("vote.compact.no", "No", "messages", "it");
t.add("vote.compact.abstained", "Astenuto", "messages", "it");
t.add("vote.compact.notPartecipating", "non ha partecipato", "messages", "it");
t.add("affair.results.detailedView", "Visualizzazione dettaglio", "messages", "it");
t.add("affair.results.compactView", "Visualizzazione compatta", "messages", "it");
t.add("rankings.stats", "%affairs% Oggetti, %votes% Votazioni", "messages", "it");
t.add("ranking.vacantSeat", "Seggio vacante di", "messages", "it");
t.add("ranking.membership.fromto", "Parlamentare da %from% fino a %to%", "messages", "it");
t.add("ranking.membership.from", "Parlamentare da %from%", "messages", "it");
t.add("ranking.membership.yearAndDays", "%years% anni e %days% giorni", "messages", "it");
t.add("rankings.title", "Statistiche votazioni %council%", "messages", "it");
t.add("form.submit", "Mostrare", "messages", "it");
t.add("ranking.abstain.in", "Astenuto da %hits% su %total% votazioni", "messages", "it");
t.add("ranking.absent.percentage", "Nessuna partecipazione", "messages", "it");
t.add("ranking.absent.in", "Nessuna partecipazione: %hits% su %total% votazioni", "messages", "it");
t.add("layout.loader.error", "Error loading data", "messages", "it");
t.add("ranking.age.yearAndDays", "%years% anni e %days% giorni", "messages", "it");
t.add("ranking.narrowvotes.difference", "Differenza", "messages", "it");
t.add("ranking.voteagainst.in", "%hits% del %total% contro propria frazione", "messages", "it");
t.add("ranking.voteagainst.percentage", "%hits%% dissidente", "messages", "it");
t.add("ranking.filter.submit", "Filtrare ordine d'uscita", "messages", "it");
t.add("profile.dashboard", "Dashboard", "messages", "it");
t.add("profile.dashboard.sessions", "Votazioni", "messages", "it");
t.add("profile.dashboard.parties", "Partiti: Parlamentari", "messages", "it");
t.add("profile.dashboard.rankings.councillors", "Analisi: Parlamentari", "messages", "it");
t.add("profile.dashboard.factions", "Gruppi parlamentari: Parlamentari", "messages", "it");
t.add("profile.dashboard.rankings.factions", "Analisi: Gruppi parlamentari", "messages", "it");
t.add("profile.dashboard.cantons", "Cantoni: Parlamentari", "messages", "it");
t.add("profile.dashboard.recentVotes", "Votazioni pi\u00f9 recenti CN", "messages", "it");
t.add("rankings.form.onlyfinalvotes", "Quali votazioni?", "messages", "it");
t.add("rankings.form.filter.on", "Attivato", "messages", "it");
t.add("rankings.form.filter.off", "Disattivato", "messages", "it");
t.add("rankings.form.legislativeperiod", "Periodo di legislatura", "messages", "it");
t.add("rankings.form.session", "Sessione", "messages", "it");
t.add("rankings.filter.faction.label", "Filtrare per frazione", "messages", "it");
t.add("rankings.filter.affairtype.label", "Filtrare per tipo di oggetto", "messages", "it");
t.add("rankings.filter.topic.label", "Tema", "messages", "it");
t.add("rankings.form.ranking", "Statistica", "messages", "it");
t.add("profile.show.username", "Nome utente", "messages", "it");
t.add("profile.show.email", "E-Mail", "messages", "it");
t.add("profile.show.firstName", "Nome", "messages", "it");
t.add("profile.show.lastName", "Cognome", "messages", "it");
t.add("profile.show.edit", "Modificare", "messages", "it");
t.add("layout.login", "Iscriversi", "messages", "it");
t.add("security.forgotpassword", "Password dimenticata?", "messages", "it");
t.add("profile.settings.keywords", "Parole chiave", "messages", "it");
t.add("results.results", "Risultati", "messages", "it");
t.add("results.noResultsFound", "Nessun risultato trovato", "messages", "it");
t.add("profile.settings.navigation.profile", "Profilo", "messages", "it");
t.add("profile.settings.navigation.password", "Password", "messages", "it");
t.add("profile.settings.navigation.notificationPreferences", "Termini di ricerca", "messages", "it");
t.add("profile.settings.defaultNotificationPreference", "Impostazione predefinita", "messages", "it");
t.add("profile.settings.nationalNotificationPreferences", "Impostazioni specifiche per la Confederazione", "messages", "it");
t.add("profile.settings.cantonalNotificationPreferences", "Impostazioni specifiche per i Cantoni", "messages", "it");
t.add("layout.menu.councilstates.rankings.factions", "Coalizioni: Quali maggioranze hanno successo?", "messages", "it");
t.add("cantonal.listCouncillors.deputies.title", "Supplenti parlamentari", "messages", "it");
t.add("councillor.role.deputy", "Supplente", "messages", "it");
t.add("layout.search.contentType.all", "Oggetti e parlamentari", "messages", "it");
t.add("layout.search.contentType.affair", "Solo oggetti", "messages", "it");
t.add("layout.search.contentType.councillor", "Solo parlamentari", "messages", "it");
t.add("layout.search.contentType", "Cercare per", "messages", "it");
t.add("layout.search.affairTypeNational", "Tipologia di oggetto (nazionale)", "messages", "it");
t.add("layout.search.affairTypeCantonal", "Tipologia di oggetto (cantonale)", "messages", "it");
t.add("layout.search.canton", "Cantone", "messages", "it");
t.add("layout.search.affairTypeNational.all", "Tutti", "messages", "it");
t.add("layout.search.affairTypeCantonal.all", "Tutti", "messages", "it");
t.add("layout.search.canton.all", "Tutti i cantoni", "messages", "it");
t.add("profile.settings.notificationPreference.default", "Impostazione predefinita", "messages", "it");
t.add("ranking.narrowvotesguilty", "Votazioni risicate perse", "messages", "it");
t.add("rankings.filter.narrowVotesGuilty.limit.label", "Cosa sono le votazioni risicate? Differenza di voti:", "messages", "it");
t.add("ranking.narrowvotesguilty.in", "Co-responsabile in %hits% su %total% votazioni", "messages", "it");
t.add("ranking.narrowvotesguilty.percentage", "%hits%% dissidente", "messages", "it");
t.add("ranking.narrowvotesguilty.abstain", "comprendere voto", "messages", "it");
t.add("ranking.narrowvotesguilty.absent", "Assente (No partecipazione)", "messages", "it");
t.add("ranking.narrowvotesguilty.against", "Contro la propria frazione", "messages", "it");
t.add("ranking.abstain.percentage", "Astenuto", "messages", "it");
t.add("profile.settings.baseSettings", "Impostazioni generali", "messages", "it");
t.add("global.filter.noResults", "Nessun evento trovato", "messages", "it");
t.add("layout.account.shortcut", "P", "messages", "it");
t.add("profile.show.edit.shortcut", "CaF", "messages", "it");
t.add("profile.settings.edit.shortcut", "TR", "messages", "it");
t.add("profile.settings.edit", "Impostazioni", "messages", "it");
t.add("layout.logout.shortcut", "D", "messages", "it");
t.add("layout.search", "Termine di ricerca", "messages", "it");
t.add("layout.menu.councillors.byCanton.shortcut", "C", "messages", "it");
t.add("layout.menu.councillors.byCanton", "per cantoni", "messages", "it");
t.add("layout.menu.councillors.byFaction.shortcut", "F", "messages", "it");
t.add("layout.menu.councillors.byFaction", "per gruppi parlamentari", "messages", "it");
t.add("layout.menu.councillors.byParty.shortcut", "P", "messages", "it");
t.add("layout.menu.councillors.byParty", "per partiti", "messages", "it");
t.add("rankings.stats.description", "Il numero di voti si riferisce alla differenza di voti selezionati di seguito.", "messages", "it");
t.add("sessionlist.session.dates", "%from% fino a %to%", "messages", "it");
t.add("sessionlist.session.votes", "Votazioni", "messages", "it");
t.add("sessionlist.session.historyEvents", "Oggetti", "messages", "it");
t.add("layout.search.orderBy.none", "Miglior risultato", "messages", "it");
t.add("layout.search.orderBy.deposit_date", "Data del deposito", "messages", "it");
t.add("layout.search.orderBy.title", "Titolo", "messages", "it");
t.add("layout.search.orderBy.type", "Tipo", "messages", "it");
t.add("layout.search.orderBy.first_name", "Nome", "messages", "it");
t.add("layout.search.orderBy.last_name", "Cognome", "messages", "it");
t.add("layout.search.orderDirection.ascending", "Crescente", "messages", "it");
t.add("layout.search.orderDirection.descending", "decrescente", "messages", "it");
t.add("layout.search.orderBy", "Ordinare per", "messages", "it");
t.add("layout.search.orderDirection", "Ordine di uscita", "messages", "it");
t.add("councillor.affairSuccess.unknownVotes", "non \u00e8 stato votato", "messages", "it");
t.add("rankings.cantonal.numberOfAffairs.title", "Numero di oggetti per cantone", "messages", "it");
t.add("layout.menu.cantonalRankings", "Statistiche cantoni", "messages", "it");
t.add("layout.politicalcalendar", "Calendario", "messages", "it");
t.add("session.stats.finalPersonalVotes", "Interventi parlamentari", "messages", "it");
t.add("affair.councilStatesDebate.debate", "Dibattito", "messages", "it");
t.add("affair.councilStatesDebate.speechesByCouncillor", "Voti Parlamentari", "messages", "it");
t.add("affair.councilStatesDebate.speeches", "Voti", "messages", "it");
t.add("affair.submittedByOn", "Depositato da %councillors% il %date%", "messages", "it");
t.add("rankings.filter.orderDirection.label", "Ordine di successione", "messages", "it");
t.add("rankings.filter.orderDirection.descending", "decrescente", "messages", "it");
t.add("rankings.filter.orderDirection.ascending", "Crescente", "messages", "it");
t.add("rankings.form.filter.all", "Tutti", "messages", "it");
t.add("rankings.form.filter.finalGovernment", "Solo votazioni finali", "messages", "it");
t.add("rankings.form.filter.finalPersonal", "Solo interventi parlamentari", "messages", "it");
t.add("rankings.form.filter.finalAll", "Solo votazioni finali e interventi parlamentari", "messages", "it");
t.add("affairGroups.assign.modal", "Assegnare ad un gruppo", "messages", "it");
t.add("affairGroups.newGroup.modal", "Creare nuovo gruppo", "messages", "it");
t.add("profile.affairGroups", "Gruppi", "messages", "it");
t.add("layout.affair_groups", "Gruppi", "messages", "it");
t.add("layout.affair_groups.shortcut", "G", "messages", "it");
t.add("layout.affairs_overview", "Oggetti", "messages", "it");
t.add("layout.menu.nationalcouncil.consultations", "Procedure di consultazione", "messages", "it");
t.add("affairGroups.newGroup.success", "Nuovo gruppo creato con successo", "messages", "it");
t.add("affairGroups.assign.select", "Assegnare gruppo", "messages", "it");
t.add("affairGroups.assign.success", "Assegnazioni ai gruppi aggiornate con successo.", "messages", "it");
t.add("affairGroups.assign.error", "Il gruppo non pu\u00f2 essere assegnato", "messages", "it");
t.add("affairGroups.assign.placeholder", "-", "messages", "it");
t.add("affairGroups.assign.label", "Assegnare gruppo", "messages", "it");
t.add("affairGroups.newGroup.title.label", "Nome", "messages", "it");
t.add("affairGroups.newGroup.description.label", "Descrizione", "messages", "it");
t.add("affairGroups.new.title", "Creare nuovo gruppo", "messages", "it");
t.add("affairGroups.new.save", "Salvare nuovo gruppo", "messages", "it");
t.add("affairGroups.affair.title", "Titolo dell'oggetto", "messages", "it");
t.add("affairGroups.assign.title", "Assegnare l'oggetto ad un gruppo", "messages", "it");
t.add("affairGroups.assign.save", "Salvare assegnazione", "messages", "it");
t.add("affairGroups.newGroup.parent.placeholder", "(nessun gruppo superiore)", "messages", "it");
t.add("affairGroups.newGroup.parent.label", "Gruppo superiore", "messages", "it");
t.add("affairGroups.affair.existingGroups", "Gruppi esistenti", "messages", "it");
t.add("profile.settings.nationalNotificationPreferences.affairs", "Oggetti", "messages", "it");
t.add("profile.settings.nationalNotificationPreferences.nationalCouncilVotes", "Votazioni Consiglio nazionale", "messages", "it");
t.add("profile.settings.nationalNotificationPreferences.councilStatesVotes", "Votazioni Consiglio degli Stati", "messages", "it");
t.add("affairGroups.edit.title", "Modificare gruppo", "messages", "it");
t.add("affairGroups.edit.save", "Salvare", "messages", "it");
t.add("affairGroups.editGroup.tooltip", "Modificare gruppo", "messages", "it");
t.add("affairGroups.assign.noGroups", "Assegnato a nessun gruppo", "messages", "it");
t.add("affairGroups.editGroup.success", "Gruppo salvato con successo", "messages", "it");
t.add("affair.customEvents.add.button", "Inserire annotazione\/evento", "messages", "it");
t.add("affair.customEvents.edit.title", "Modificare evento", "messages", "it");
t.add("affair.customEvent.button.submit", "Salvare", "messages", "it");
t.add("affair.customEvents.add.title", "Inserire evento", "messages", "it");
t.add("affair.customEvents.save.success", "Evento salvato con successo", "messages", "it");
t.add("affairs.customEvents.fields.note.title", "Titolo", "messages", "it");
t.add("affairs.customEvents.fields.note.abstract", "Riassunto", "messages", "it");
t.add("affairs.customEvents.fields.note.outputInReports", "Includere nei documenti (esportazioni, rapporti, inviti etc.)", "messages", "it");
t.add("affairs.customEvents.fields.note.text", "Testo", "messages", "it");
t.add("affairs.customEvents.fields.vote.caption", "Titolo", "messages", "it");
t.add("affairs.customEvents.fields.vote.description", "Descrizione", "messages", "it");
t.add("affairs.customEvents.fields.vote.yes", "S\u00ec", "messages", "it");
t.add("affairs.customEvents.fields.vote.no", "No", "messages", "it");
t.add("affairs.customEvents.fields.date", "Data", "messages", "it");
t.add("affairs.customEvents.success", "Salvato con successo", "messages", "it");
t.add("affair.councilOfStatesVote.tooltip.caption", "Votazione Consiglio degli Stati", "messages", "it");
t.add("affair.nationalCouncilVote.tooltip.caption", "Votazione Consiglio nazionale", "messages", "it");
t.add("affair.nationalCouncilDebate.tooltip.caption", "Dibattito Consiglio nazionale", "messages", "it");
t.add("affair.customNote.tooltip.caption", "Annotazione", "messages", "it");
t.add("affair.customInternalVote.tooltip.caption", "Votazione interna", "messages", "it");
t.add("affairs.customEvents.note", "Annotazione", "messages", "it");
t.add("affairs.customEvents.internalvote", "Votazione interna", "messages", "it");
t.add("cantonal.historyevent.deposit.tooltip.caption", "Data deposito", "messages", "it");
t.add("affair.authorCouncillor", "Depositato da", "messages", "it");
t.add("affairGroup.overview.noContent.title", "Nessun oggetto", "messages", "it");
t.add("affairGroup.overview.nationalEvents.title", "Oggetti nazionali", "messages", "it");
t.add("affairGroup.overview.cantonalEvents.title", "Oggetti cantonali", "messages", "it");
t.add("affairGroup.overview.customAffairs.title", "Oggetti interni", "messages", "it");
t.add("affairGroup.overview.children", "Sottogruppi", "messages", "it");
t.add("affairGroup.description.empty", "Nessuna descrizione", "messages", "it");
t.add("affairGroups.overviewLink.tooltip", "Aprire sommario gruppi", "messages", "it");
t.add("author.party.label", "Partito", "messages", "it");
t.add("author.faction.label", "Frazione", "messages", "it");
t.add("author.commission.label", "Commissione", "messages", "it");
t.add("author.misc.label", "Autore", "messages", "it");
t.add("councillor.affairSuccess.partialPositiveVotes", "Di successo in un Consiglio", "messages", "it");
t.add("affair.overview.nationalCouncilVote.title", "Votazione Consiglio nazionale", "messages", "it");
t.add("affair.overview.internalVoteEvent.title", "Votazione interna", "messages", "it");
t.add("affair.overview.noteEvent.title", "Annotazione", "messages", "it");
t.add("affair.overview.nationalCouncilDebateEvent.title", "Dibattito", "messages", "it");
t.add("affair.overview.councilStatesVote.title", "Votazione Consiglio degli Stati", "messages", "it");
t.add("affair.overview.cantonal.depositEvent.title", "Depositato", "messages", "it");
t.add("affair.overview.events.draft", "Dettaglio disegno", "messages", "it");
t.add("ranking.councillorscompare", "Confronto parlamentari", "messages", "it");
t.add("ranking.councillorscompare.noVotes", "Nessuna differenza \/ votazione", "messages", "it");
t.add("layout.menu.nationalcouncil.rankings.councillorscompare", "Confronto Consiglieri nazionali", "messages", "it");
t.add("layout.menu.councilstates.rankings.councillorscompare", "Confronto deputati Consiglio degli Stati", "messages", "it");
t.add("rankings.form.councillorsCompare.councillor1", "Parlamentare 1", "messages", "it");
t.add("rankings.form.councillorsCompare.councillor2", "Parlamentare 2", "messages", "it");
t.add("ranking.councillorscompare.topicsStats.title", "Tematiche delle votazioni con opinioni differenti", "messages", "it");
t.add("ranking.councillorscompare.votesAndAffairs", "%affairs% Oggetti, %votes% Votazioni", "messages", "it");
t.add("ranking.councillorscompare.overview.title", "Confronto parlamentari", "messages", "it");
t.add("ranking.councillorscompare.description", "Votazioni in cui i due parlamentari hanno votato in modo diverso: il grafico mostra chi ha votato s\u00ec, no o si \u00e8 astenuto in queste votazioni.", "messages", "it");
t.add("ranking.councillorscompare.howmany.yes", "%count% S\u00ec", "messages", "it");
t.add("ranking.councillorscompare.howmany.no", "%count% No", "messages", "it");
t.add("ranking.councillorscompare.howmany.eh", "%count% astenuto", "messages", "it");
t.add("ranking.councillorscompare.age", "Et\u00e0", "messages", "it");
t.add("ranking.councillorscompare.yearsInCouncil", "Durata carica nella Camera attuale", "messages", "it");
t.add("ranking.councillorscompare.absentRate", "Assenze legislatura in corso", "messages", "it");
t.add("ranking.councillorscompare.abstainRate", "Astensioni legislatura attuale", "messages", "it");
t.add("ranking.councillorscompare.affairsCount", "Numero di interventi parlamentari legislatura attuale", "messages", "it");
t.add("national.affair.overview.title", "Oggetto nazionale", "messages", "it");
t.add("sidebar.toggle.collapse", "Chiudere", "messages", "it");
t.add("sidebar.toggle.expand", "Aprire", "messages", "it");
t.add("cantonal.affair.overview.title", "Oggetto cantonale", "messages", "it");
t.add("councillor.faction", "Frazione", "messages", "it");
t.add("councillor.party", "Partito", "messages", "it");
t.add("councillor.commissionMemberships.current", "Appartenenze attuali Commissioni", "messages", "it");
t.add("councillor.commissionMemberships.past", "Appartenenze passate Commissioni", "messages", "it");
t.add("ranking.cantonal.councillorsAffairs", "Oggetti cantonali per parlamentare", "messages", "it");
t.add("rankings.cantonal.numberOfAffairs.stats", "Statistica numero di oggetti", "messages", "it");
t.add("rankings.cantonal.filter.canton.label", "Filtrare per cantone", "messages", "it");
t.add("rankings.filter.isEffectiveAmount.label", "Confrontare con", "messages", "it");
t.add("rankings.filter.isEffectiveAmount.yes", "Numero effettivo oggetti\/interventi parlamentari", "messages", "it");
t.add("rankings.filter.isEffectiveAmount.no", "Relativo al numero di oggetti nel cantone", "messages", "it");
t.add("rankings.filter.includeGovernmentAuthors.label", "Considerare oggetti del Governo", "messages", "it");
t.add("rankings.filter.includeGovernmentAuthors.yes", "S\u00ec", "messages", "it");
t.add("rankings.filter.includeGovernmentAuthors.no", "No", "messages", "it");
t.add("ranking.cantonal.numberOfAffairs.authored.effective", "Oggetti\/interventi parlamentari effettivi", "messages", "it");
t.add("ranking.cantonal.numberOfAffairs.authored.percentage", "Quota di", "messages", "it");
t.add("layout.menu.cantonal.rankings.affairsPerCanton", "Per cantone", "messages", "it");
t.add("cantonal.rankings.title", "Statistiche cantonali", "messages", "it");
t.add("layout.menu.cantonal.rankings.affairsPerCouncillor", "Parlamentari", "messages", "it");
t.add("cantonal.overview.commissions", "Commissioni", "messages", "it");
t.add("cantonal.listCommissions.title", "Commissione con oggetti propri %canton%", "messages", "it");
t.add("cantonal.commission.title", "Autore: %commission%", "messages", "it");
t.add("cantonal.commission.noAffairs", "Nessun oggetto della commissione disponibile", "messages", "it");
t.add("cantonal.commission.affairs.title", "Oggetti", "messages", "it");
t.add("cantonal.commission.councillors.title", "Parlamentari", "messages", "it");
t.add("cantonal.listCommissions.councillors.title", "Parlamentari", "messages", "it");
t.add("cantonal.listCommissions.parties.title", "Partiti", "messages", "it");
t.add("cantonal.listCommissions.factions.title", "Gruppi parlamentari", "messages", "it");
t.add("cantonal.overview.miscauthors", "Diversi autori", "messages", "it");
t.add("cantonal.listMiscAuthors.title", "Diversi autori %canton%", "messages", "it");
t.add("cantonal.miscauthor.affairs.title", "Oggetti", "messages", "it");
t.add("cantonal.miscAuthors.noAffairs", "Nessun oggetto disponibile per questo autore.", "messages", "it");
t.add("cantonal.miscauthors.title", "Oggetti %miscauthor%", "messages", "it");
t.add("cantonal.overview.factions", "Gruppi parlamentari", "messages", "it");
t.add("cantonal.factions.noFactions", "Nessuna frazione registrata per questo cantone", "messages", "it");
t.add("cantonal.listFactions.commissionMemberships.title", "Appartenenze commissioni", "messages", "it");
t.add("cantonal.faction.noAffairs", "Nessun oggetto per questa frazione", "messages", "it");
t.add("cantonal.faction.commissions.title", "Appartenenze commissioni", "messages", "it");
t.add("cantonal.faction.parties.title", "Partiti", "messages", "it");
t.add("cantonal.listFactions.title", "Frazioni %canton%", "messages", "it");
t.add("cantonal.faction.title", "%faction%", "messages", "it");
t.add("cantonal.overview.parties", "Partiti", "messages", "it");
t.add("cantonal.parties.noParties", "Nessun partito registrato per questo cantone", "messages", "it");
t.add("cantonal.parties.noAffairs", "Nessun oggetto di questo partito", "messages", "it");
t.add("cantonal.parties.commissions.title", "Appartenenza commissione", "messages", "it");
t.add("cantonal.party.factionMembership.title", "Appartenenza frazione", "messages", "it");
t.add("cantonal.party.affairs.title", "Oggetti", "messages", "it");
t.add("cantonal.party.title", "%party%", "messages", "it");
t.add("cantonal.listParties.title", "Partiti %canton%", "messages", "it");
t.add("groups.tree.search", "Ricerca...", "messages", "it");
t.add("groups.tree.search.next", "avanti", "messages", "it");
t.add("groups.tree.search.prev", "indietro", "messages", "it");
t.add("groups.tree.search.collapse", "chiudere tutti", "messages", "it");
t.add("groups.tree.search.expand", "aprire tutti", "messages", "it");
t.add("groups.tree.group.checkGroup", "Alla visualizzazione dei gruppi", "messages", "it");
t.add("groups.tree.group.deleteGroup", "Eliminare gruppo", "messages", "it");
t.add("groups.tree.group.deleteAffair", "Rimuovere oggetto dal gruppo", "messages", "it");
t.add("groups.tree.group.checkAffair", "All'oggetto", "messages", "it");
t.add("groups.tree.group.editGroup", "Modificare gruppo", "messages", "it");
t.add("groups.tree.sort", "Ordine", "messages", "it");
t.add("groups.tree.sort.alphabetically", "alfabetico", "messages", "it");
t.add("groups.tree.sort.custom", "Definito dall'utente", "messages", "it");
t.add("groups.tree.sort.default", "predefinito", "messages", "it");
t.add("groups.tree.sort.byDate", "Data", "messages", "it");
t.add("affairGroup.export.header.id", "ID", "messages", "it");
t.add("affairGroup.export.header.type", "Tipo", "messages", "it");
t.add("affairGroup.export.header.title", "Titolo", "messages", "it");
t.add("affairGroup.export.header.description", "Testo depositato", "messages", "it");
t.add("affairGroup.export.header.status", "Stato", "messages", "it");
t.add("affairGroup.export.header.link", "Fonte", "messages", "it");
t.add("groups.tree.group.exportGroup", "Esportazione Excel", "messages", "it");
t.add("affair.eventAttachments", "Allegati", "messages", "it");
t.add("affair.eventAttachments.download", "Scaricare allegato", "messages", "it");
t.add("groups.form.title.cannotBeEmpty", "Il titolo non pu\u00f2 essere vuoto", "messages", "it");
t.add("affair.internalNote.title", "Descrizione", "messages", "it");
t.add("affair.internalNote", "Descrizione", "messages", "it");
t.add("affair.affairOverview.groups", "Gruppi", "messages", "it");
t.add("affair.affairOverview.groups.empty", "Assegnato a nessun gruppo", "messages", "it");
t.add("affair.affairOverview.meetings.empty", "Assegnato a nessuna riunione", "messages", "it");
t.add("affair.internalNote.error", "Errore descrizione", "messages", "it");
t.add("rankings.filter.affairgroup.label", "Gruppo", "messages", "it");
t.add("affairGroups.editGroup.save", "Salvato", "messages", "it");
t.add("councillor.info.notTreated", "Non ancora trattato:", "messages", "it");
t.add("councillor.info.notTreated.text", "Intervento parlamentare depositato", "messages", "it");
t.add("councillor.info.successfully", "Vincente:", "messages", "it");
t.add("councillor.info.successfully.text", "L'intervento parlamentare \u00e8 stato approvato nel Consiglio nazionale e nel Consiglio degli Stati.", "messages", "it");
t.add("councillor.info.successfullyCouncil", "Di successo in un Consiglio:", "messages", "it");
t.add("councillor.info.successfullyCouncil.text", "L'intervento parlamentare \u00e8 stato approvato in una Camera. Il voto nella seconda Camera \u00e8 ancora aperto.", "messages", "it");
t.add("councillor.info.noSuccess", "Nessun successo:", "messages", "it");
t.add("councillor.info.noSuccess.text", "L'intervento parlamentare \u00e8 stato respinto almeno in una Camera.", "messages", "it");
t.add("councillor.info.noVote", "non \u00e8 stato votato:", "messages", "it");
t.add("councillor.info.noVote.text", "Le interpellanze o gli interventi parlamentari presentati durante il periodo di interrogazioni sono elencati qui.", "messages", "it");
t.add("affair.internalNote.save", "Salvare", "messages", "it");
t.add("national.affair.originalSource", "Fonte: Curia Vista", "messages", "it");
t.add("affairs.followingHaveBeenUpdated", "I seguenti oggetti sono stati aggiornati", "messages", "it");
t.add("national.affairs.followingHaveBeenUpdated", "I seguenti oggetti nazionali sono stati aggiornati", "messages", "it");
t.add("affair.customSpeaker.cantonalCouncillor.label", "Referente", "messages", "it");
t.add("affair.customSpeaker.nationalCouncilCouncillor.label", "Referente Consiglio nazionale", "messages", "it");
t.add("affair.customSpeaker.councilStatesCouncillor.label", "Referente Consiglio degli Stati", "messages", "it");
t.add("affair.customSpeaker.button.submit", "Salvare", "messages", "it");
t.add("affair.customSpeaker.save.success", "Referente salvato\/a", "messages", "it");
t.add("notification.subscription.button.affair.tooltip", "abbonarsi a oggetto \/ disiscriversi", "messages", "it");
t.add("customMeetings.newMeeting.success", "Salvato con successo", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.modal", "Creare nuova trattanda", "messages", "it");
t.add("customMeetings.assignAgendaPoint.modal", "Aggiungere alla trattanda", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.save", "Salvare", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.success", "Salvato con successo", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.noMeetings", "Nessuna riunione", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.error", "Errore durante il salvataggio", "messages", "it");
t.add("customMeetings.editMeeting.title", "Modificare riunione", "messages", "it");
t.add("customMeetings.editMeeting.save", "Salvare", "messages", "it");
t.add("customMeetings.editMeeting.success", "Salvato con successo", "messages", "it");
t.add("customMeetings.editMeetingAgendaPoint.success", "Salvato con successo", "messages", "it");
t.add("customMeetings.assignAgendaPoint.title", "Aggiungere alla trattanda", "messages", "it");
t.add("customMeetings.assignAgendaPoint.placeholder", "selezionare trattanda", "messages", "it");
t.add("customMeetings.assignAgendaPoint.label", "Trattanda", "messages", "it");
t.add("customMeetings.assignAgendaPoint.error", "Errore durante l'aggiunta", "messages", "it");
t.add("customMeetings.assignAgendaPoint.success", "Assegnazioni alle riunioni aggiornate con successo.", "messages", "it");
t.add("customMeetings.assignAgendaPoint.save", "Salvare", "messages", "it");
t.add("customMeetings.newMeeting.date.label", "Data", "messages", "it");
t.add("customMeetings.newMeeting.modal", "Creare nuova riunione", "messages", "it");
t.add("customMeetings.newMeeting.title", "Creare nuova riunione", "messages", "it");
t.add("customMeetings.newMeeting.title.label", "Titolo", "messages", "it");
t.add("customMeetings.newMeeting.subject.label", "Oggetto", "messages", "it");
t.add("customMeetings.newMeeting.description.label", "Descrizione", "messages", "it");
t.add("customMeetings.newMeeting.location.label", "Luogo", "messages", "it");
t.add("customMeetings.newMeeting.president.label", "Presidenza", "messages", "it");
t.add("customMeetings.newMeeting.protocol.label", "Stesura verbale", "messages", "it");
t.add("customMeetings.newMeeting.save", "Salvare", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.title", "Creare nuova trattanda", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.title.label", "Creare nuova trattanda", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.description.label", "Descrizione", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.parent.placeholder", "Selezionare riunione", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.parent.label", "Riunione", "messages", "it");
t.add("customMeetings.tree.search.expand", "aprire tutti", "messages", "it");
t.add("customMeetings.tree.search.collapse", "chiudere tutti", "messages", "it");
t.add("customMeetings.tree.search", "Ricerca", "messages", "it");
t.add("customMeetings.tree.search.prev", "Indietro", "messages", "it");
t.add("customMeetings.tree.search.next", "Avanti", "messages", "it");
t.add("customMeetings.form.title.cannotBeEmpty", "Si prega di inserire un valore", "messages", "it");
t.add("customMeetings.tree.customMeeting.createInvitationPDF", "Generare invito (PDF)", "messages", "it");
t.add("customMeetings.tree.customMeeting.createProtocolPDF", "Generare verbale (PDF)", "messages", "it");
t.add("customMeetings.tree.sort.default", "predefinito", "messages", "it");
t.add("customMeetings.tree.sort.alphabetically", "alfabetico", "messages", "it");
t.add("customMeetings.tree.sort.byDate", "Data", "messages", "it");
t.add("customMeetings.tree.sort.custom", "Definito dall'utente", "messages", "it");
t.add("customMeetings.tree.customMeeting.checkAffair", "All'oggetto", "messages", "it");
t.add("customMeetings.editCustomMeeting.tooltip", "Modificare", "messages", "it");
t.add("customMeetings.editCustomMeeting.save", "Salvare", "messages", "it");
t.add("customMeetings.tree.customMeeting.editCustomMeeting", "Modificare riunione", "messages", "it");
t.add("customMeetings.tree.customMeeting.deleteCustomMeeting", "Eliminare riunione", "messages", "it");
t.add("customMeetings.newAgendaPoint.tooltip", "Aggiungere trattanda", "messages", "it");
t.add("customMeetings.tree.customMeeting.newAgendaPoint", "Aggiungere trattanda", "messages", "it");
t.add("customMeetings.tree.customMeeting.deleteAffair", "Rimuovere oggetto", "messages", "it");
t.add("customMeetings.editCustomMeetingAgendaPoint.tooltip", "Modificare trattanda", "messages", "it");
t.add("customMeetings.tree.customMeeting.editCustomMeetingAgendaPoint", "Modificare trattanda", "messages", "it");
t.add("customMeetings.tree.customMeeting.deleteCustomMeetingAgendaPoint", "Eliminare trattanda", "messages", "it");
t.add("customMeetings.deleteCustomMeeting.message", "Eliminare riunione?", "messages", "it");
t.add("customMeetings.deleteCustomMeeting.confirm", "Confermare", "messages", "it");
t.add("customMeetings.deleteCustomMeeting.cancel", "Annullare", "messages", "it");
t.add("customMeetings.deleteCustomMeeting.success", "Eliminato con successo", "messages", "it");
t.add("customMeetings.deleteCustomMeeting.error", "Errore durante l'eliminazione", "messages", "it");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.message", "Eliminare trattanda?", "messages", "it");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.confirm", "Confermare", "messages", "it");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.cancel", "Annullare", "messages", "it");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.error", "Errore durante la cancellazione. Si prega di contattare il team di supporto su support@politica.ch", "messages", "it");
t.add("customMeetings.deleteCustomMeetingAgendaPoint.success", "Eliminato con successo", "messages", "it");
t.add("customMeetings.error.message", "Errore", "messages", "it");
t.add("layout.custom_meetings", "Riunioni", "messages", "it");
t.add("layout.custom_meetings.shortcut", "R", "messages", "it");
t.add("layout.custom_affairs", "Oggetti interni", "messages", "it");
t.add("layout.custom_affairs.shortcut", "OI", "messages", "it");
t.add("layout.menu.management", "funzioni", "messages", "it");
t.add("affair.affairOverview.meetings", "Riunioni", "messages", "it");
t.add("customMeetings.affair.existingMeetings", "Riunioni", "messages", "it");
t.add("profile.customMeetings", "Riunioni", "messages", "it");
t.add("profile.settings.navigation.companySettings", "Impostazioni", "messages", "it");
t.add("profile.settings.company.pictureFile.label", "Logo", "messages", "it");
t.add("profile.settings.company.settings.label", "Referenti", "messages", "it");
t.add("profile.settings.company.download.label", "Scaricare logo", "messages", "it");
t.add("profile.settings.company.delete.label", "Eliminare logo", "messages", "it");
t.add("custom_speakers_enabled.label", "Mostrare referente", "messages", "it");
t.add("affair.customSpeakers.save.success", "Salvato con successo", "messages", "it");
t.add("custom.affairs.list.pagetitle", "Oggetti interni", "messages", "it");
t.add("custom.affairs.list.title", "Oggetti interni", "messages", "it");
t.add("custom.affairs.edit.pagetitle", "Modificare oggetto interno", "messages", "it");
t.add("custom.affairs.edit.title", "Modificare oggetto interno", "messages", "it");
t.add("custom.affairs.backToList", "indietro", "messages", "it");
t.add("custom.affairs.list.header.title", "Titolo", "messages", "it");
t.add("custom.affairs.list.header.internalId", "ID interno", "messages", "it");
t.add("custom.affairs.list.header.createdAt", "Generato il", "messages", "it");
t.add("custom.affairs.list.header.actions", "Azioni", "messages", "it");
t.add("custom.affairs.list.edit.label", "modificare", "messages", "it");
t.add("custom.affairs.list.delete.label", "eliminare", "messages", "it");
t.add("custom.affairs.noaffairs.alert", "Nessun oggetto interno disponibile", "messages", "it");
t.add("custom.affairs.delete.confirm", "Siete sicuri di voler eliminare l'oggetto interno?", "messages", "it");
t.add("custom.affairs.form.title", "Titolo", "messages", "it");
t.add("custom.affairs.form.internalId", "ID interno", "messages", "it");
t.add("custom.affairs.form.text", "Testo", "messages", "it");
t.add("custom.affairs.form.button.submit", "Salvare", "messages", "it");
t.add("custom.affairs.overview.title", "Oggetto interno", "messages", "it");
t.add("custom.affairs.form.create.title", "Creare nuovo oggetto interno", "messages", "it");
t.add("custom.affairs.new.title", "Creare nuovo oggetto interno", "messages", "it");
t.add("custom.affairs.new.error", "Errore durante il salvataggio", "messages", "it");
t.add("custom.affairs.new.pagetitle", "Creare nuovo oggetto interno", "messages", "it");
t.add("cantonalAffairs.deposited.tooltip.title", "Depositato", "messages", "it");
t.add("customAffairs.deposited.tooltip.title", "Generato", "messages", "it");
t.add("national.affair.draft.federal.council.proposal.tooltip.title", "Disegno Consiglio federale", "messages", "it");
t.add("affairGroup.export.buttonlabel", "Esportazione", "messages", "it");
t.add("customMeetings.editMeetingAgendaPoint.title", "Modificare trattanda", "messages", "it");
t.add("customMeetings.editMeetingAgendaPoint.save", "Salvare", "messages", "it");
t.add("customMeetings.assignAgendaPoint.noMeetings", "Nessuna riunione registrata", "messages", "it");
t.add("customMeetings.assignAgendaPoint.noAgendaPointsLabel", "Ancora nessuna trattanda registrata", "messages", "it");
t.add("customMeetings.assignAgendaPoint.empty", "Nessuna riunione \/ trattativa registrata", "messages", "it");
t.add("customMeetings.replicateMeeting.copyOf", "Copiare struttura riunione (trattande)", "messages", "it");
t.add("customMeetings.replicateMeeting.success", "Vincente", "messages", "it");
t.add("customMeetings.tree.customMeeting.newCustomAffair", "Registrare nuovo oggetto interno", "messages", "it");
t.add("customMeetings.tree.customMeeting.replicateCustomMeeting", "Duplicare", "messages", "it");
t.add("councillor.topic.count.title", "%topic%: %count% interventi parlamentari", "messages", "it");
t.add("canton.label", "Impostazioni riunione: cantone predefinito", "messages", "it");
t.add("cantonal_faction.label", "Impostazioni riunione: frazione predefinita", "messages", "it");
t.add("is_private.label", "Gruppo privato (non visibile a tutti)", "messages", "it");
t.add("affairGroups.newGroup.settings.label", "Impostazioni gruppi", "messages", "it");
t.add("affair.relatedDepartments.title", "Dipartimenti", "messages", "it");
t.add("affair.relatedDepartments.desc", "Dipartimenti", "messages", "it");
t.add("affair.relatedDepartments.empty", "Nessuna assegnazione", "messages", "it");
t.add("affairs.editor.placeholder", "(Per favore cliccare qui per registrare una descrizione interna)", "messages", "it");
t.add("selectpicker.noneSelectedText", "Nessuna selezione effettuata...", "messages", "it");
t.add("custom.affairs.form.html", "Testo", "messages", "it");
t.add("customMeetings.newMeeting.participants.label", "Presenti", "messages", "it");
t.add("customMeetings.newMeeting.guests.label", "Ospiti", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.reducedOutputAffairs.label", "Emissione ridotta sui rapporti\/inviti (senza storia)", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.type.label", "tipo di trattanda", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.type.regular.label", "predefinito", "messages", "it");
t.add("customMeetings.newMeetingAgendaPoint.type.affairsWithoutSpeaker.label", "Oggetti senza referente", "messages", "it");
t.add("affair.daysummary", "Riassunto giorno", "messages", "it");
t.add("profile.notifications", "Notifiche", "messages", "it");
t.add("profile.notifications.edit.shortcut", "N", "messages", "it");
t.add("profile.notifications.edit", "Notifiche", "messages", "it");
t.add("profile.notifications.markAllRead", "Segnare tutti come letti", "messages", "it");
t.add("profile.notifications.loadMore", "Mostrare di pi\u00f9", "messages", "it");
t.add("profile.notifications.empty", "Nessuna notifica", "messages", "it");
t.add("profile.notifications.noMore", "Nessuna notifica", "messages", "it");
t.add("notification.subscription.button.subscribed", "Abbonato\/a", "messages", "it");
t.add("notification.subscription.button.unSubscribed", "non abbonato\/a", "messages", "it");
t.add("custom.affairs.form.copiedWithMeeting", "replicare con la riunione", "messages", "it");
t.add("customMeetings.pdf.customSpeakers.label", "Referente", "messages", "it");
t.add("national.committees.list", "Lista commissioni nazionali", "messages", "it");
t.add("national.committees.show", "Commissione nazionale", "messages", "it");
t.add("layout.menu.nationalcouncil.commissions", "Commissioni CN", "messages", "it");
t.add("layout.menu.nationalcouncil.commissions.shortcut", "C", "messages", "it");
t.add("layout.menu.councilofstates.commissions", "Commissioni CSt", "messages", "it");
t.add("layout.menu.councilofstates.commissions.shortcut", "C", "messages", "it");
t.add("customMeetings.pdf.protocol.title", "Verbale", "messages", "it");
t.add("cantonal.recentInsertedAffairs", "Oggetti cantonali pi\u00f9 recenti", "messages", "it");
t.add("customMeetings.pdf.invitation.title", "Invito", "messages", "it");
t.add("vote.novotes", "Nessun risultato di votazione dettagliato possibile", "messages", "it");
t.add("national.affairs.overview.title", "Oggetti nazionali", "messages", "it");
t.add("national.affairs.list.header.title", "Titolo", "messages", "it");
t.add("national.affairs.list.header.author", "Autore", "messages", "it");
t.add("national.affairs.list.header.topics", "Temi", "messages", "it");
t.add("national.affairs.list.header.status", "Stato", "messages", "it");
t.add("national.affairs.list.header.depositDate", "Data deposito", "messages", "it");
t.add("reports.overview.title", "Rapporti", "messages", "it");
t.add("reports.source.label", "Fonte dati", "messages", "it");
t.add("reports.from.label", "Emissione oggetti: Con quale oggetto (numero) vuole iniziare? Predefinito: vuoto = 1", "messages", "it");
t.add("reports.to.label", "Emissione oggetti: fino a quale oggetto (numero) bisogna emettere? Predefinito: vuoto = fino all'ultimo oggetto (tutti gli oggetti)", "messages", "it");
t.add("reports.source.placeholder", "Si prega di scegliere la fonte...", "messages", "it");
t.add("reports.firstNumber.label", "Inizio numerazione oggetti nel PDF (vuoto = 1)", "messages", "it");
t.add("reports.groups", "Gruppi", "messages", "it");
t.add("reports.groups.label", "Gruppo(i)", "messages", "it");
t.add("reports.error.creation", "Errore nella generazione del rapporto. Si prega di contattare il team di supporto.", "messages", "it");
t.add("layout.menu.reports", "Rapporti", "messages", "it");
t.add("reports.session.canton.zh", "Oggetti seduta Gran Consiglio ZH", "messages", "it");
t.add("affair.cantonalHistoryeventOnSessionAgenda.tooltip.caption", "Evento cantonale", "messages", "it");
t.add("affair.cantonalHistoryeventStatuschange.tooltip.caption", "Cambiamento dello stato", "messages", "it");
t.add("custom.affairs.new.success", "Oggetto interno generato con successo", "messages", "it");
t.add("custom.affairs.edit.success", "Oggetto interno salvato con successo", "messages", "it");
t.add("custom.affairs.delete.success", "Oggetto interno cancellato con successo", "messages", "it");
t.add("custom.affairs.delete.error", "Errore durante l'eliminazione", "messages", "it");
t.add("affair.draftReference.title", "Fonte bozza", "messages", "it");
t.add("affair.draftPreConsultation.title", "Disegno consultazione preliminare", "messages", "it");
t.add("affair.draftPreConsultation.tooltip.caption", "Disegno consultazione preliminare", "messages", "it");
t.add("dashboard.updates.update_name", "Titolo", "messages", "it");
t.add("dashboard.updates.item_type", "status quo", "messages", "it");
t.add("dashboard.updates.update_type", "Aggiornamento", "messages", "it");
t.add("dashboard.updates.update_time", "Data di pubblicazione", "messages", "it");
t.add("dashboard.updates.type.insert", "Nuovo", "messages", "it");
t.add("dashboard.updates.type.update", "Aggiornato", "messages", "it");
t.add("dashboard.updates.type.remove", "Rimosso", "messages", "it");
t.add("dashboard.updates.title", "Aggiornamenti oggetti", "messages", "it");
t.add("dashboard.custom_updates.title", "Aggiornamenti eventi interni", "messages", "it");
t.add("dashboard.updates.itemType.cantonalAffair", "Oggetto cantonale", "messages", "it");
t.add("dashboard.updates.itemType.councilStatesVote", "Votazione Consiglio degli Stati", "messages", "it");
t.add("dashboard.updates.itemType.nationalAffair", "Oggetto nazionale", "messages", "it");
t.add("dashboard.updates.itemType.nationalCouncilVote", "Votazione Consiglio nazionale", "messages", "it");
t.add("dashboard.updates.itemType.customAffair", "Oggetto interno", "messages", "it");
t.add("dashboard.updates.itemType.customSpeaker", "Referente", "messages", "it");
t.add("dashboard.updates.itemType.affairGroup", "Gruppo", "messages", "it");
t.add("dashboard.updates.itemType.customMeeting", "Riunione", "messages", "it");
t.add("dashboard.updates.itemType.customMeetingAgendaPoint", "trattanda riunione", "messages", "it");
t.add("affair.timeline", "Cronologia", "messages", "it");
t.add("affair.event.details", "Dettagli evento", "messages", "it");
t.add("actions.default", "Azioni", "messages", "it");
t.add("groups.tree.type_select.all", "Tutti i gruppi", "messages", "it");
t.add("groups.tree.type_select.public", "Solo gruppi non privati", "messages", "it");
t.add("groups.tree.type_select.private", "Solo gruppi privati", "messages", "it");
t.add("groups.tree.sort_select.alpha", "In ordine alfabetico", "messages", "it");
t.add("groups.tree.sort_select.date", "Per data di creazione", "messages", "it");
t.add("affairGroups.deleteGroup.success", "Gruppo eliminato con successo", "messages", "it");
t.add("layout.affairs_overview.Shortcut", "G", "messages", "it");
t.add("layout.menu.nationalcouncil.consultations.shortcut", "V", "messages", "it");
t.add("dashboard.updates.itemType.internalNote", "Nota interna", "messages", "it");
t.add("dashboard.updates.itemType.internalVoteCustomEvent", "Votazione interna", "messages", "it");
t.add("affair.custom.description", "Descrizione", "messages", "it");
t.add("affair.events", "Eventi", "messages", "it");
t.add("national.consultations.overview.title", "Procedure di consultazione nazionali", "messages", "it");
t.add("national.consultations.details.show_all", "mostrare tutte", "messages", "it");
t.add("national.consultations.details.planned", "Previste", "messages", "it");
t.add("national.consultations.details.in_progress", "In corso", "messages", "it");
t.add("national.consultations.details.closed", "Concluse", "messages", "it");
t.add("national.consultations.details.title", "Procedura di consultazione in dettaglio", "messages", "it");
t.add("national.consultations.details.author", "Autore", "messages", "it");
t.add("national.consultations.documents", "Documenti", "messages", "it");
t.add("national.consultations.opinions", "Pareri procedure di consultazione", "messages", "it");
t.add("national.consultations.details.empty_list", "Nessuna procedura di consultazione", "messages", "it");
t.add("affair.error.get_event", "Errore durante la lettura degli eventi", "messages", "it");
t.add("affair.customEvents.error", "Errore durante la lettura delle annotazioni", "messages", "it");
t.add("national.consultations.error", "Errore durante la lettura delle procedure di consultazione", "messages", "it");
t.add("dashboard.updates.itemType.noteCustomEvent", "Annotazione", "messages", "it");
t.add("affairs.overview.title", "Oggetti", "messages", "it");
t.add("affair.customEvent.button.delete", "Eliminare", "messages", "it");
t.add("notifications.not_seen.description", "NUOVO", "messages", "it");
t.add("notifications.not_seen.shortcut", "N", "messages", "it");
t.add("ui.modal.warning", "Avviso", "messages", "it");
t.add("affair_detail.modals.event_delete.text", "Volete eliminare l'evento?", "messages", "it");
t.add("ui.confirm", "S\u00ec", "messages", "it");
t.add("ui.abort", "Annullare", "messages", "it");
t.add("ui.breadcrumb.home", "Inizio", "messages", "it");
t.add("ui.affair.managed", "nel gruppo", "messages", "it");
t.add("affair.type.abbr", "Abbreviazione", "messages", "it");
t.add("affair.code", "Codice", "messages", "it");
t.add("affair.shortIdParlService", "Numero Curia Vista", "messages", "it");
t.add("affair.badge.private", "Oggetto commissione", "messages", "it");
t.add("commission_detail.events.empty", "Nessuna voce", "messages", "it");
t.add("commission_detail.lastProgramLink", "Link dell'ultimo programma (PDF)", "messages", "it");
t.add("commission_detail.overviewPageLink", "Link della pagina ufficiale della commissione", "messages", "it");
t.add("affairs.list.empty", "Nessuna voce", "messages", "it");
t.add("affair_list.select.type.label", "Tipo", "messages", "it");
t.add("affair_list.type_select.type.all", "Tutti", "messages", "it");
t.add("affair_list.select.type.national", "Oggetti nazionali", "messages", "it");
t.add("affair_list.select.type.cantonal", "Oggetti cantonali", "messages", "it");
t.add("affair_list.select.date.last6months", "Ultimi 6 mesi", "messages", "it");
t.add("affair_list.select.date.last12months", "Ultimi 12 mesi", "messages", "it");
t.add("affair_list.select.date_after", "Data del deposito", "messages", "it");
t.add("affair_list.select.date_before", "Data successiva a", "messages", "it");
t.add("ui.select.groups", "Gruppi", "messages", "it");
t.add("ui.select.groups.placeholder", "Gruppi", "messages", "it");
t.add("affair_list.select.events.label", "Note \/ votazioni interne", "messages", "it");
t.add("affair_list.select.events.all", "Tutti", "messages", "it");
t.add("affair_list.select.events.note", "Con annotazioni", "messages", "it");
t.add("affair_list.select.events.internal_vote", "Con votazioni interne", "messages", "it");
t.add("ui.select.status.placeholder", "Filtrare per stato...", "messages", "it");
t.add("ui.select.status.national", "Stato nazionale", "messages", "it");
t.add("ui.select.status.cantonal", "Stato cantonale", "messages", "it");
t.add("panel.detailsLink.label", "Dettagli", "messages", "it");
t.add("timeline.tooltip.affair_link", "All'oggetto", "messages", "it");
t.add("ui.table.sort.asc", "Crescente", "messages", "it");
t.add("ui.table.sort.desc", "decrescente", "messages", "it");
t.add("ui.table.sort.asc.desc", "Ordine decrescente", "messages", "it");
t.add("dashboard.error.get_entries", "Errore durante il caricamento delle voci", "messages", "it");
t.add("notifications.error.get", "Errore durante il caricamento delle notifiche.", "messages", "it");
t.add("notifications.error.set_seen", "Errore durante l'impostazione dello stato di notifica", "messages", "it");
t.add("councillors.error.get", "Errore durante il caricamento dei parlamentari", "messages", "it");
t.add("ui.select.councillors.placeholder", "Scegliere parlamentare", "messages", "it");
t.add("ui.select.councillors.label", "Parlamentari", "messages", "it");
t.add("commissions.link.commission", "Alla commissione", "messages", "it");
t.add("commissions.link.overview", "Alla commissione", "messages", "it");
t.add("commissions.link.program", "alla pianificazione della riunione", "messages", "it");
t.add("calendar.events.empty", "Nessuna voce", "messages", "it");
t.add("security.saml.discovery", "SSO-Login", "messages", "it");
t.add("textEditor.small", "Piccolo", "messages", "it");
t.add("textEditor.large", "Normale", "messages", "it");
t.add("textEditor.huge", "Grande", "messages", "it");
t.add("affair.nationalAffairDraftResolution.tooltip.caption", "Disegno documento", "messages", "it");
t.add("affair.nationalAffairDraftPreConsultation.tooltip.caption", "Preconsultazione", "messages", "it");
t.add("event.calendar.title", "Calendario", "messages", "it");
t.add("layout.search.error", "Errore durante la ricerca", "messages", "it");
t.add("layout.search.recent_affairs_national", "Oggetti nazionali pi\u00f9 recenti", "messages", "it");
t.add("layout.search.recent_affairs_cantonal", "Oggetti cantonali pi\u00f9 recenti", "messages", "it");
t.add("timeline.tooltip.event_link", "All'oggetto", "messages", "it");
t.add("affair.nationalHistoryeventStatuschange.tooltip.caption", "Stato cambiato", "messages", "it");
t.add("affair.nationalAffairDraftReference.tooltip.caption", "Disegno documento", "messages", "it");
t.add("affair.nationalHistoryeventSessionProgram.tooltip.caption", "Pianificato: Trattazione nella sessione", "messages", "it");
t.add("affair.nationalCouncilAffairDebate.tooltip.caption", "Dibattito Consiglio nazionale", "messages", "it");
t.add("affair.nationalAffairDraftFederalCouncilProposal.tooltip.caption", "Disegno Consiglio federale", "messages", "it");
t.add("affair.nationalHistoryeventCommitteeProgram.tooltip.caption", "Pianificato: Trattazione nella commissione", "messages", "it");
t.add("event.reminders.title", "Promemoria", "messages", "it");
t.add("event.reminders.button.save", "Salvare", "messages", "it");
t.add("event.reminders.button.delete", "Eliminare", "messages", "it");
t.add("event.reminders.button.add", "Aggiungere", "messages", "it");
t.add("event.reminders.button.subscribed", "Promemoria impostato", "messages", "it");
t.add("event.reminders.button.unsubscribed", "Impostare promemoria", "messages", "it");
t.add("api.error.get_event_reminder", "Errore durante la lettura dei promemoria. Si prega di contattare il team di supporto: support@politica.ch", "messages", "it");
t.add("api.groups.get.error", "Errore durante la lettura dei gruppi. Si prega di contattare il team di supporto: support@politica.ch", "messages", "it");
t.add("event.reminders.edit.success", "Salvato con successo", "messages", "it");
t.add("event.reminders.edit.error", "Errore durante il salvataggio del promemoria. Si prega di contattare il team di supporto: support@politica.ch", "messages", "it");
t.add("event.reminders.create.error", "Errore durante il salvataggio del promemoria. Si prega di contattare il team di supporto: support@politica.ch", "messages", "it");
t.add("event.reminders.delete.error", "Errore durante la cancellazione del promemoria. Si prega di contattare il team di supporto: support@politica.ch", "messages", "it");
t.add("event.reminders.empty", "Nessun promemoria", "messages", "it");
t.add("event.reminders.input.date", "Data", "messages", "it");
t.add("event.reminders.input.data", "Promemoria impostato", "messages", "it");
t.add("search.error.get_cantons", "Errore durante l'impostazione della ricerca. Si prega di contattare il team di supporto: support@politica.ch", "messages", "it");
t.add("search.error.get_national_affair_types", "Errore durante l'impostazione della ricerca. Si prega di contattare il team di supporto: support@politica.ch", "messages", "it");
t.add("search.error.get_cantonal_affair_types", "Errore durante l'impostazione della ricerca. Si prega di contattare il team di supporto: support@politica.ch", "messages", "it");
t.add("layout.search.pagination.previous", "precedente", "messages", "it");
t.add("layout.search.pagination.next", "Prossime", "messages", "it");
t.add("affairs.search.title", "Ricerca", "messages", "it");
t.add("national.consultations.list", "Procedure di consultazione", "messages", "it");
t.add("national.councillors.list", "Parlamentari", "messages", "it");
t.add("affair.events.custom.create.error", "Errore durante il salvataggio. Si prega di contattare il team di supporto: support@politica.ch", "messages", "it");
t.add("affair.events.custom.create.success", "Evento salvato con successo.", "messages", "it");
t.add("reports.groups.placeholder", "Gruppo(i)", "messages", "it");
t.add("eventReminder.event.notFound", "Evento non trovato", "messages", "it");
t.add("reports.groups.outputDescription.label", "Testo depositato", "messages", "it");
t.add("reports.groups.outputStatus.label", "Stato", "messages", "it");
t.add("reports.groups.outputLink.label", "Link alla fonte", "messages", "it");
t.add("reports.groups.outputSpeaker.label", "Referente Consiglio nazionale\/Consiglio degli Stati", "messages", "it");
t.add("reports.groups.outputInternalDescription.label", "Descrizione", "messages", "it");
t.add("reports.groups.outputHistory.label", "Cronologia degli eventi", "messages", "it");
t.add("affairGroup.export.header.speaker", "Referente", "messages", "it");
t.add("affairGroup.export.header.internalDescription", "Descrizione", "messages", "it");
t.add("affairGroup.export.header.history", "Eventi", "messages", "it");
t.add("reports.groups.outputHistory.none", "Nessun evento", "messages", "it");
t.add("reports.groups.outputHistory.customEvents", "Solo eventi interni (note, votazioni)", "messages", "it");
t.add("reports.groups.outputHistory.all", "Tutti gli eventi", "messages", "it");
t.add("reports.formats.label", "Formato", "messages", "it");
t.add("reports.format.excel", "Excel (XLS)", "messages", "it");
t.add("reports.format.pdf", "PDF", "messages", "it");
t.add("affairgroups.pdf.title", "Rapporto", "messages", "it");
t.add("customMeetingAgendaPoints.form.title.cannotBeEmpty", "Titolo deve essere impostato", "messages", "it");
t.add("commission_detail.affairs", "Oggetti della commissione", "messages", "it");
t.add("cantonal.commissions.empty", "Nessuna commissione", "messages", "it");
t.add("api.councillors.get.error", "Errore durante il caricamento dei parlamentari. Si prega di contattare il team di supporto.", "messages", "it");
t.add("profile.show.language", "Lingua", "messages", "it");
t.add("profile.settings.label", "Impostazioni", "messages", "it");
t.add("email_footer.label", "Email di notifica a pi\u00e8 pagina", "messages", "it");
t.add("show_company_logo_and_name.label", "App-Branding (angolo in alto a sinistra)", "messages", "it");
t.add("show_company_logo_and_name.both", "Mostrare logo e nome", "messages", "it");
t.add("show_company_logo_and_name.logo", "Solo logo", "messages", "it");
t.add("show_company_logo_and_name.name", "Solo nome", "messages", "it");
t.add("profile.settings.language.label", "Lingua", "messages", "it");
t.add("profile.settings.language.de", "Tedesco \/ Deutsch", "messages", "it");
t.add("profile.settings.language.fr", "Francese \/ Fran\u00e7ais", "messages", "it");
t.add("affair.title.ratsunterlagen", "Documentazione del Consiglio", "messages", "it");
t.add("layout.maintenance.siteIsDown", "Stiamo eseguendo una manutenzione programmata.", "messages", "it");
t.add("layout.maintenance.beBackSoon", "politik.ch sar\u00e0 di nuovo disponibile in breve tempo. Se dovesse avere domande, la preghiamo di contattare %email% in qualsiasi momento. La ringraziamo per la comprensione.", "messages", "it");
t.add("consultations.nohistoryEvents", "Nessun evento", "messages", "it");
t.add("ui.filter.all", "Tutti", "messages", "it");
t.add("customMeetings.editMeeting.error", "Errore durante la modifica delle riunioni. Si prega di contattare il team di supporto.", "messages", "it");
t.add("profile.settings.navigation.auth", "Autenticazione a 2 fattori", "messages", "it");
t.add("profile.edit.2fa.authMode.label", "scegliere il secondo fattore", "messages", "it");
t.add("profile.edit.2fa.none", "Nessuna (2FA disattivata)", "messages", "it");
t.add("profile.edit.2fa.email", "E-Mail", "messages", "it");
t.add("profile.edit.2fa.google", "OTP (App di autenticazione, p.es. Google Authenticator)", "messages", "it");
t.add("profile.edit.2fa.pleaseReadThisQRCode", "Si prega di scansionare il codice QR:", "messages", "it");
t.add("profile.edit.2fa.googleCode.label", "Si prega di inserire un codice attuale", "messages", "it");
t.add("profile.edit.2fa.thisIsYourGoogleAuthenticationCode", "Codice Backup:", "messages", "it");
t.add("profile.edit.2fa.pleaseStoreItInASafePlace", "Si prega di salvare questo codice in un luogo sicuro. Il codice pu\u00f2 essere utilizzato per accedere, nel caso in cui l'accesso ai codici personali tramite l'app non dovesse essere disponibile.", "messages", "it");
t.add("affairs.list.no_access", "Nessun accesso", "messages", "it");
t.add("profile.edit.2fa.invalidGoogleCode", "Codice non valido. Si prega di inserire un codice valido dall'app di autenticazione.", "messages", "it");
t.add("national.consultations.events.start", "Inizio procedura di consultazione", "messages", "it");
t.add("national.consultations.events.end", "Fine procedura di consultazione", "messages", "it");
t.add("ranking.councillorscompare.noVotesInSelectedTimespan", "Nessuna votazione nel periodo di tempo selezionato.", "messages", "it");
t.add("reports.reportType", "Fonti per il rapporto", "messages", "it");
t.add("layout.search.contentType.affairConsultations", "Procedure di consultazione", "messages", "it");
t.add("rankings.form.wholeLegislativeperiod", "Votazioni dell'intera legislatura", "messages", "it");
t.add("affairGroup.overview.consultations.title", "Procedure di consultazione", "messages", "it");
t.add("dashboard.updates.default_title", "Titolo", "messages", "it");
t.add("dashboard.updates.itemType.nationalAffairConsultation", "Procedura di consultazione nazionale", "messages", "it");
t.add("ui.table.sort.asc.asc", "Ordine crescente", "messages", "it");
t.add("ui.date_picker.future", "tutti i futuri", "messages", "it");
t.add("ui.date_picker.past", "tutti i passati", "messages", "it");
t.add("ui.date_picker.calendar.ranges.12months", "12 mesi", "messages", "it");
t.add("ui.date_picker.calendar.ranges.6months", "6 mesi", "messages", "it");
t.add("ui.date_picker.calendar.ranges", "Settore", "messages", "it");
t.add("national.affairs.list.header.filter", "filtrare", "messages", "it");
t.add("ui.text_editor.image.exceeds", "La dimensione dell'immagine super il massimo di 5 MB.", "messages", "it");
t.add("profile.settings.placeholder", "-", "messages", "it");
t.add("layout.search.contentType.affairConsultation", "Procedure di consultazione", "messages", "it");
t.add("links.type.linker.mentions", "menzionato", "messages", "it");
t.add("links.type.linked.isMentionedBy", "\u00e8 stato menzionato", "messages", "it");
t.add("links.type.linker.precedes", "Oggetto precedente", "messages", "it");
t.add("links.type.linked.succeeds", "Oggetto successivo", "messages", "it");
t.add("links.type.linker.relates", "Si riferisce a", "messages", "it");
t.add("links.type.linked.relates", "\u00e8 stato fatto riferimento", "messages", "it");
t.add("links.public", "Ufficiale", "messages", "it");
t.add("affair_detail.links.title", "Oggetti collegati", "messages", "it");
t.add("ui.delete", "eliminare", "messages", "it");
t.add("api.links.get.error", "Errore durante il caricamento dei link degli oggetti. Si prega di contattare il team di supporto.", "messages", "it");
t.add("links.edit_form.title", "Modificare oggetti collegati", "messages", "it");
t.add("links.edit_form.list_empty", "Nessun oggetto collegato", "messages", "it");
t.add("api.link_types.get.error", "Errore durante il caricamento dei link degli oggetti. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.search.error", "Errore durante il caricamento dei risultati della ricerca. Si prega di contattare il team di supporto.", "messages", "it");
t.add("links.edit_form.input.public.label", "Ufficiale?", "messages", "it");
t.add("links.edit_form.input.public.true", "S\u00ec, ufficialmente", "messages", "it");
t.add("links.edit_form.input.public.false", "No, solamente interno (organizzazione\/azienda)", "messages", "it");
t.add("links.edit_form.input.link_type.label", "Tipo di collegamento", "messages", "it");
t.add("links.edit_form.input.entity.label", "Collegare con", "messages", "it");
t.add("ui.submit", "Salvare", "messages", "it");
t.add("links.delete.confirm", "Volete davvero eliminare il collegamento?", "messages", "it");
t.add("ui.select.loading", "sta caricando...", "messages", "it");
t.add("affair.nationalHistoryeventCommitteePressRelease.tooltip.caption", "Comunicato stampa commissione", "messages", "it");
t.add("committee.press_releases.title", "Comunicati stampa commissione", "messages", "it");
t.add("committee.pressRelease", "Comunicato stampa", "messages", "it");
t.add("api.press_releases.get.error", "Errore nel caricamento dei comunicati stampa. Si prega di contattare il team di supporto.", "messages", "it");
t.add("press_release.originalSource", "Fonte", "messages", "it");
t.add("affair_detail.actions.entityLinks", "Collegare oggetto", "messages", "it");
t.add("affair.nationalConsultationEndEvent.tooltip.caption", "Fine procedura di consultazione", "messages", "it");
t.add("affair.nationalConsultationStartEvent.tooltip.caption", "Inizio procedura di consultazione", "messages", "it");
t.add("fos_user.password.isPwned", "Password non sicura. Si prega di scegliere un'altra password.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.html.get.error", "Errore durante la lettura. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.html.put.success", "Salvato con successo.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.html.put.error", "Errore durante il salvataggio. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.html.post.success", "Salvato con successo", "messages", "it");
t.add("api.profile.settings.company.internal_fields.html.post.error", "Errore durante il salvataggio. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.ratings.get.error", "Errore durante la lettura. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.ratings.put.error", "Errore durante il salvataggio. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.ratings.post.error", "Errore durante il salvataggio. Si prega di contattare il team di supporto.", "messages", "it");
t.add("profile.settings.comapny.custom_fields.html.labelDe", "Etichetta tedesco", "messages", "it");
t.add("profile.settings.comapny.custom_fields.html.labelFr", "Etichetta francese", "messages", "it");
t.add("profile.settings.comapny.custom_fields.html.labelIt", "Etichetta italiano", "messages", "it");
t.add("profile.settings.comapny.custom_fields.rating.labelDe", "Etichetta tedesco", "messages", "it");
t.add("profile.settings.comapny.custom_fields.rating.labelFr", "Etichetta francese", "messages", "it");
t.add("profile.settings.comapny.custom_fields.rating.labelIt", "Etichetta italiano", "messages", "it");
t.add("profile.settings.company.internal_fields.ratings.choice.default", "Valore predefinito", "messages", "it");
t.add("ui.add", "Aggiungere", "messages", "it");
t.add("profile.settings.save.failure", "Errore durante il salvataggio. Si prega di contattare il team di supporto.", "messages", "it");
t.add("profile.settings.compay.internal_fields.delete.confirm", "Volete davvero eliminare questo campo?", "messages", "it");
t.add("profile.settings.company.custom_fields.label", "Campi definiti dall'utente", "messages", "it");
t.add("affair.internal_fields.title", "Altri", "messages", "it");
t.add("profile.settings.company.custom_fields.html.add", "Nuovo campo testo", "messages", "it");
t.add("profile.settings.company.custom_fields.rating.add", "Nuovo campo opzioni", "messages", "it");
t.add("profile.settings.company.internal_fields.rating.choices.add", "Inserire opzione", "messages", "it");
t.add("profile.settings.compay.internal_fields.ratings.option.delete.confirm", "Volete davvero eliminare l'opzione?", "messages", "it");
t.add("layout.search.contentType.affair_consultation", "Procedure di consultazione", "messages", "it");
t.add("api.cantons.get.error", "Errore durante l'impostazione della ricerca. Si prega di contattare il team di supporto: support@politica.ch", "messages", "it");
t.add("ui.select.agenda_points", "Riunioni", "messages", "it");
t.add("affair_detail.links.empty", "Nessun oggetto collegato", "messages", "it");
t.add("forms.speakers.input.councillor.national_council", "Consiglio nazionale", "messages", "it");
t.add("forms.speakers.input.councillor.states_council", "Consiglio degli Stati", "messages", "it");
t.add("profile.settings.company.logo.upload.error.size", "L'immagine caricata \u00e8 troppo grande. Si prega di utilizzare una risoluzione pi\u00f9 bassa.", "messages", "it");
t.add("profile.settings.company.internal_fields.ratings.labelDe", "Etichetta DE", "messages", "it");
t.add("profile.settings.company.internal_fields.ratings.labelFr", "Etichetta FR", "messages", "it");
t.add("profile.settings.company.internal_fields.ratings.labelIt", "Etichetta IT", "messages", "it");
t.add("profile.settings.company.internal_fields.html.labelDe", "Etichetta DE", "messages", "it");
t.add("profile.settings.company.internal_fields.html.labelFr", "Etichetta FR", "messages", "it");
t.add("profile.settings.company.internal_fields.html.labelIt", "Etichetta IT", "messages", "it");
t.add("form.speakers.input.councillor.cantonal", "Gran Consiglio", "messages", "it");
t.add("national.consultations.description.header", "Descrizione", "messages", "it");
t.add("timeline.settings.timespans", "Eventi plurigiornalieri", "messages", "it");
t.add("timeline.settings.timespans.showend", "mostrare", "messages", "it");
t.add("timeline.settings.timespans.hideend", "non mostrare", "messages", "it");
t.add("national.consultations.events.start.timespan", "Periodo procedura di consultazione", "messages", "it");
t.add("custom.affairs.table.caption", "Oggetti interni", "messages", "it");
t.add("affair.councilStatesVote.tooltip.caption", "Votazione Consiglio degli Stati", "messages", "it");
t.add("form.current_password", "Password attuale", "messages", "it");
t.add("form.new_password", "Nuova password", "messages", "it");
t.add("form.new_password_confirmation", "Confermare nuova password", "messages", "it");
t.add("affair.nationalHistoryeventCommitteeProgramStart.tooltip.caption", "Pianificato: Trattazione nella commissione", "messages", "it");
t.add("affair.nationalAffairConsultationEndEvent.tooltip.caption", "Procedura di consultazione nazionale: Fine", "messages", "it");
t.add("affair.nationalAffairConsultationStartEvent.tooltip.caption", "Procedura di consultazione nazionale: Inizio", "messages", "it");
t.add("ui.select.meetings.placeholder", "Riunioni", "messages", "it");
t.add("affair.nationalAffairNoteCustomEvent.tooltip.caption", "Nota interna", "messages", "it");
t.add("affair.statusChangeNationalAffairEvent.tooltip.caption", "Cambiamento dello stato", "messages", "it");
t.add("affair.nationalCommitteeProgramStartNationalAffairEvent.tooltip.caption", "Inizio valutazione nella commissione", "messages", "it");
t.add("affair.nationalCommitteePressReleaseNationalAffairEvent.tooltip.caption", "Comunicato stampa commissione", "messages", "it");
t.add("affair.nationalHistoryeventCommitteeProgramEnd.tooltip.caption", "Pianificato: Trattazione nella commissione (fine)", "messages", "it");
t.add("affair.nationalCommitteeProgramEndNationalAffairEvent.tooltip.caption", "Pianificato: Trattazione nella commissione (fine)", "messages", "it");
t.add("affair.nationalSessionProgramNationalAffairEvent.tooltip.caption", "Programma della sessione nazionale", "messages", "it");
t.add("affair.nationalCommitteeProgramNationalAffairEvent.tooltip.caption", "Programma della commissione nazionale", "messages", "it");
t.add("affair.NationalAffairInternalVoteCustomEvent.tooltip.caption", "Votazione interna", "messages", "it");
t.add("affair.nationalHistoryeventCommitteeProgramRemoved.tooltip.caption", "Rimosso dal programma della commissione", "messages", "it");
t.add("user.password.isPwned", "Password non sicura. Si prega di scegliere una nuova password.", "messages", "it");
t.add("cantonal.overview.sessions", "Sedute \/ sessioni Parlamento", "messages", "it");
t.add("cantonal.listSessions.title", "Sedute \/ sessioni Parlamento", "messages", "it");
t.add("cantonal.sessions.table.date", "Data", "messages", "it");
t.add("cantonal.sessions.table.canton", "Cantone", "messages", "it");
t.add("cantonal.sessions.table.link", "Link fonte", "messages", "it");
t.add("cantonal.sessions.table.events", "Numero di oggetti", "messages", "it");
t.add("cantonal.sessions.title", "Sessione \/ riunione in dettaglio", "messages", "it");
t.add("forms.speakers.input.councillor.cantonal", "Referente", "messages", "it");
t.add("councils.nr", "in Consiglio Nazionale", "messages", "it");
t.add("councils.sr", "nel Consiglio degli Stati", "messages", "it");
t.add("cantonal.session.events.empty", "Nessun oggetto", "messages", "it");
t.add("api.cantonal.sessions.get.error", "Errore durante il caricamento delle sessioni\/riunioni cantonali", "messages", "it");
t.add("cantonal.faction.affairs.title", "Oggetti della frazione", "messages", "it");
t.add("cantonal.affairs.year.select.label", "Selezione anno", "messages", "it");
t.add("cantonal.consultations.attachments", "Allegati", "messages", "it");
t.add("affair.CantonalAffairConsultationEndEvent.tooltip.caption", "Fine procedura di consultazione nazionale", "messages", "it");
t.add("affair.CantonalAffairConsultationStartEvent.tooltip.caption", "Inizio procedura di consultazione cantonale", "messages", "it");
t.add("cantonal.consultations.description.header", "Descrizione", "messages", "it");
t.add("cantonal.consultations.get.error", "Errore durante il caricamento delle procedure di consultazione cantonali", "messages", "it");
t.add("dashboard.updates.itemType.cantonalAffairConsultationInternalNote", "Nota interna procedura di consultazione cantonale", "messages", "it");
t.add("links.edit_form.input.entity.placeholder", "Selezionare l'oggetto...", "messages", "it");
t.add("ui.forms.confirm.title", "Titolo confermato", "messages", "it");
t.add("event.reminders.create.success", "Promemoria salvato con successo", "messages", "it");
t.add("cantonal.overview.consultations.planned", "Procedure di consultazione: previste", "messages", "it");
t.add("cantonal.overview.consultations.in_progress", "Procedure di consultazione: in corso", "messages", "it");
t.add("cantonal.overview.consultations.closed", "Procedure di consultazione: concluse", "messages", "it");
t.add("cantonal.consultations.overview.title", "Procedure di consultazione cantonali", "messages", "it");
t.add("cantonal.consultations.list", "Procedure di consultazione cantonali", "messages", "it");
t.add("settings.company.affair_meetings_show.label", "mostrare \/ usare riunioni?", "messages", "it");
t.add("userGroups.builtIn.companyAdmins.title", "Amministratore", "messages", "it");
t.add("userGroups.builtIn.ro.title", "Gruppo sola lettura", "messages", "it");
t.add("userGroups.builtIn.rw.title", "Gruppo Lettura\/Scrittura", "messages", "it");
t.add("committee.press_releases.table.header.title", "Titolo", "messages", "it");
t.add("committee.press_releases.table.header.date", "Data", "messages", "it");
t.add("committee.press_releases.table.header.link", "Link", "messages", "it");
t.add("cantonal.councillors.table.name", "Nome", "messages", "it");
t.add("cantonal.councillors.table.party", "Partito", "messages", "it");
t.add("cantonal.councillors.table.faction", "Frazione", "messages", "it");
t.add("affair.nationalCommitteeAffair.tooltip.caption", "Oggetto commissione", "messages", "it");
t.add("affair.nationalCommitteeProgramRemovedNationalAffairEvent.tooltip.caption", "Oggetto rimosso dal programma della commissione", "messages", "it");
t.add("affairGroup.export.header.internalFields", "Campi definiti dall'utente", "messages", "it");
t.add("timeline.settings.order.asc", "Dal meno recente", "messages", "it");
t.add("timeline.settings.order.desc", "Dal pi\u00f9 recente", "messages", "it");
t.add("reports.nationalSessions.label", "Sessione", "messages", "it");
t.add("reports.format.docx", "Word DOCX", "messages", "it");
t.add("reports.form.error.oneAffairGroupOrNationalSessio", "Si prega di scegliere almeno un gruppo o una sessione.", "messages", "it");
t.add("timeline.settings.order", "Ordine", "messages", "it");
t.add("committee.press_releases.table.header.author", "Autore", "messages", "it");
t.add("dashboard.updates.itemType.nationalAffairConsultationInternalNote", "Annotazione procedura di consultazione nazionale", "messages", "it");
t.add("resetting.check_email", "Nel caso in cui il link esista, il link per resettare la password \u00e8 stato inviato.", "messages", "it");
t.add("reports.nationalsessions.placeholder", "Nessuna selezione effettuata...", "messages", "it");
t.add("reports.nationSessions.label", "Sessione", "messages", "it");
t.add("api.keywords.update.success", "Parole chiave aggiornate con successo.", "messages", "it");
t.add("ui.select.user_groups.label", "Gruppi utente", "messages", "it");
t.add("reports.nationalSessionProgram.title", "Rapporto della sessione", "messages", "it");
t.add("reports.nationalSessionProgram.affairInGroups", "Gruppo", "messages", "it");
t.add("reports.nationalSessionProgram", "Rapporto della sessione", "messages", "it");
t.add("layout.affair_groups.created", "Creazione gruppo", "messages", "it");
t.add("form.label.delete", "Eliminare", "messages", "it");
t.add("affair.statusChangeCantonalAffairEvent.tooltip.caption", "Cambiamento dello stato", "messages", "it");
t.add("ui.table.header.actions.group_assign.description", "Assegnare gruppo\/i", "messages", "it");
t.add("ui.table.header.actions.group_assign.manage_groups", "Modificare gruppi", "messages", "it");
t.add("ui.table.header.actions.group_assign", "Aggiungere ai gruppi", "messages", "it");
t.add("api.user.edit.error", "Errore durante il salvataggio", "messages", "it");
t.add("api.user.edit.success", "Modificato con successo", "messages", "it");
t.add("reports.form.error.oneAffairGroupOrNationalSession", "Si prega di scegliere un gruppo o una sessione", "messages", "it");
t.add("cantonal.parties.table.party.label", "Partito", "messages", "it");
t.add("cantonal.parties.table.councillors.label", "Parlamentari", "messages", "it");
t.add("timeline.actions.expand", "aprire", "messages", "it");
t.add("ui.table.filter.empty", "Nessun valore impostato", "messages", "it");
t.add("cantonal.consultations.details.title", "Procedura di consultazione cantonale", "messages", "it");
t.add("affair_list.search", "Ricerca", "messages", "it");
t.add("timeline.actions.collapse", "chiudere", "messages", "it");
t.add("profile.show.actions.download_agb", "Scaricare CGC", "messages", "it");
t.add("committee.events.title", "Commissione", "messages", "it");
t.add("committee.events.table.header.date", "Data", "messages", "it");
t.add("committee.events.table.header.title", "Titolo", "messages", "it");
t.add("committee.events.table.header.status", "Stato", "messages", "it");
t.add("ui.date_picker.calendar.options.clear", "resettare", "messages", "it");
t.add("reports.groups.outputLinkedAffairs.label", "Includere oggetti collegati", "messages", "it");
t.add("reports.groups.outputLinkedAffairs.none", "Non includere", "messages", "it");
t.add("reports.groups.outputLinkedAffairs.reference", "Solo con referenza", "messages", "it");
t.add("reports.groups.outputLinkedAffairs.full", "Completo", "messages", "it");
t.add("reports.affair.section.linkedAffairs.title", "Oggetti collegati", "messages", "it");
t.add("reports.affair.section.customLinkedAffairs.title", "Oggetti collegati internamente", "messages", "it");
t.add("ui.date_picker.calendar.input_end.label", "Data di termine", "messages", "it");
t.add("ui.date_picker.calendar.input_start.label", "Data di inizio", "messages", "it");
t.add("cantonal.councillors.table.first_name", "Nome", "messages", "it");
t.add("cantonal.councillors.table.last_name", "Cognome", "messages", "it");
t.add("reports.groups.outputEmptyGroups.label", "Gruppi senza contenuto", "messages", "it");
t.add("reports.groups.outputId.label", "ID", "messages", "it");
t.add("reports.groups.outputCanton.label", "Cantone", "messages", "it");
t.add("reports.groups.outputTitle.label", "Titolo", "messages", "it");
t.add("reports.groups.outputAuthors.label", "Autore", "messages", "it");
t.add("reports.groups.outputDepartment.label", "Dipartimento", "messages", "it");
t.add("reports.groups.outputDepositDate.label", "Data del deposito", "messages", "it");
t.add("reports.groups.outputLastEventDate.label", "Data dell'ultimo evento", "messages", "it");
t.add("reports.depositDate.label", "Data del deposito", "messages", "it");
t.add("reports.lastEventDate.label", "Data dell'ultimo evento", "messages", "it");
t.add("reports.affairTypes.label", "Tipi di oggetto", "messages", "it");
t.add("reports.affairTypes.nationalAffair", "Oggetti nazionali", "messages", "it");
t.add("reports.affairTypes.nationalAffairConsultation", "Procedure di consultazione nazionali", "messages", "it");
t.add("reports.affairTypes.cantonalAffairConsultation", "Procedure di consultazione cantonali", "messages", "it");
t.add("reports.affairTypes.cantonalAffair", "Oggetti cantonali", "messages", "it");
t.add("reports.affairTypes.customAffair", "Oggetti interni", "messages", "it");
t.add("reports.affair.depositDate", "Data del deposito", "messages", "it");
t.add("reports.affair.lastEventDate", "Data dell'ultimo evento", "messages", "it");
t.add("profile.settings.company.user_groups.label", "Diritti \/ Gruppi utente", "messages", "it");
t.add("profile.settings.user_groups.user_select", "Utenti assegnati", "messages", "it");
t.add("profile.settings.company.user_groups.add", "Nuovo gruppo utente", "messages", "it");
t.add("reports.customAffair.type", "Oggetto interno", "messages", "it");
t.add("reports.affair.authors", "Autore", "messages", "it");
t.add("reports.affair.department", "Dipartimento", "messages", "it");
t.add("reports.section.customHistory", "Note interne", "messages", "it");
t.add("reports.section.internalFields", "Campi definiti dall'utente", "messages", "it");
t.add("dashboard.updates.itemType.cantonalAffairConsultation", "Procedura di consultazione cantonale", "messages", "it");
t.add("dashboard.updates.itemType.nationalAffairInternalNote", "Annotazione", "messages", "it");
t.add("profile.settings.company.terms_of_service.label", "Pagina iniziale della finestra dei suggerimenti", "messages", "it");
t.add("dashboard.terms_of_service.label", "Benvenuti! Clicchi qui per leggere il testo di accoglienza e i termini e le condizioni.", "messages", "it");
t.add("groups.tree.sort.alphabetical", "alfabetico", "messages", "it");
t.add("groups.tree.sort.date", "data", "messages", "it");
t.add("customMeetings.tree.sort.alphabetical", "alfabetico", "messages", "it");
t.add("customMeetings.tree.sort.date", "Data", "messages", "it");
t.add("calendar.type.select.option.full", "Calendario", "messages", "it");
t.add("calendar.type.select.option.day", "Liste", "messages", "it");
t.add("calendar.day.load_history_events", "Caricare voci precedenti", "messages", "it");
t.add("calendar.day.events.no_more", "Nessun'altra voce", "messages", "it");
t.add("consultation.submittedByOn", "Depositato da %councillors%. Inizia il %date%", "messages", "it");
t.add("calendar.type.select.label", "Visualizzazione", "messages", "it");
t.add("dashboard.updates.itemType.customAffairInternalNote", "Annotazione", "messages", "it");
t.add("affair.cantonalSessionProgramStartCantonalAffairEvent.tooltip.caption", "Registrazione programma della sessione\/ordine del giorno", "messages", "it");
t.add("affair.event.date.label", "Data", "messages", "it");
t.add("ui.table.filter.all", "selezionare tutti", "messages", "it");
t.add("ui.table.filter.clear", "non selezionare nulla", "messages", "it");
t.add("ui.reject", "Interrompere", "messages", "it");
t.add("layout.affair_groups.description", "Descrizione", "messages", "it");
t.add("draft.title", "Disegno", "messages", "it");
t.add("reports.cantonalsessions.placeholder", "Scegliere sessione\/riunione cantonale...", "messages", "it");
t.add("reports.cantonal.sessions", "Sessione\/riunione cantonale", "messages", "it");
t.add("api.groups.delete.success", "Gruppo cancellato con successo", "messages", "it");
t.add("affair_groups_sorting.label", "Gruppi: Ordine predefinito", "messages", "it");
t.add("affair_groups_sorting.default", "Impostazione predefinita", "messages", "it");
t.add("affair_groups_sorting.alphabetical", "In ordine alfabetico", "messages", "it");
t.add("affair_groups_sorting.custom", "Ordine definito dall'utente", "messages", "it");
t.add("affair_groups_sorting.date", "Per data di creazione", "messages", "it");
t.add("profile.settings.company.terms_of_service_title.label", "Titolo", "messages", "it");
t.add("national.affair.drafts.title", "Disegni", "messages", "it");
t.add("ui.table.header.actions.size.label", "mostrare numero massimo di elementi", "messages", "it");
t.add("list_size.label", "Numero voci", "messages", "it");
t.add("event.reminders.delete.success", "Promemoria cancellato con successo", "messages", "it");
t.add("reports.internal_fields.label", "Campi definiti dall'utente", "messages", "it");
t.add("affair.preConsultation.tooltip.caption", "Preconsultazione", "messages", "it");
t.add("reports.sessionProgram.affairInGroups", "Gruppo", "messages", "it");
t.add("profile.show.list_size", "Lunghezza lista predefinita", "messages", "it");
t.add("profile.edit.logo.download", "Scaricare immagine profilo", "messages", "it");
t.add("api.sessions.get.error", "Errore durante il caricamento delle sessioni. Si prega di contattare il team di supporto.", "messages", "it");
t.add("reports.source.output_format", "Formato download", "messages", "it");
t.add("reports.config.save.form.title", "Aggiornare modello di rapporto", "messages", "it");
t.add("reports.config.form.description", "Salvi le impostazioni del rapporto in un modello", "messages", "it");
t.add("reports.config.form.title.input.label", "Etichetta", "messages", "it");
t.add("reports.source.elements", "Elementi", "messages", "it");
t.add("reports.config.form.output_format", "Formato ordine d'uscita", "messages", "it");
t.add("reports.title.label", "Titolo", "messages", "it");
t.add("reports.date.label", "Data", "messages", "it");
t.add("reports.config.save", "Aggiornare modello di rapporto", "messages", "it");
t.add("api.reports.config.post.success", "Modello di rapporto salvato con successo", "messages", "it");
t.add("api.reports.config.post.error", "Errore durante il salvataggio del modello di rapporto. Si prega di contattare il team di supporto.", "messages", "it");
t.add("ui.select_multiple.placeholder", "Nessuna selezione", "messages", "it");
t.add("reports.nationalAffairConsultation.status.closed", "concluso", "messages", "it");
t.add("reports.nationalAffairConsultation.in_progres", "in corso", "messages", "it");
t.add("affair.custom.submittedByOn", "Generato", "messages", "it");
t.add("affair.CouncilStatesVote", "Votazione nel Consiglio degli Stati", "messages", "it");
t.add("affair.NationalCouncilVote", "Votazione nel Consiglio nazionale", "messages", "it");
t.add("profile.settings.nationalNotificationPreferences.NationalCouncilVotes", "Votazioni Consiglio nazionale", "messages", "it");
t.add("profile.settings.nationalNotificationPreferences.CouncilStatesVotes", "Votazioni Consiglio degli Stati", "messages", "it");
t.add("affair.CouncilStatesVote.tooltip.caption", "Votazione Consiglio degli Stati", "messages", "it");
t.add("affair.NationalCouncilVote.tooltip.caption", "Votazione Consiglio nazionale", "messages", "it");
t.add("affair.overview.NationalCouncilVote.title", "Votazione Consiglio nazionale", "messages", "it");
t.add("affair.overview.CouncilStatesVote.title", "Votazione Consiglio degli Stati", "messages", "it");
t.add("affair.StatusChangeCantonalAffairEvent.tooltip.caption", "Cambiamento dello stato", "messages", "it");
t.add("dashboard.updates.itemType.CouncilStatesVote", "Votazione Consiglio degli Stati", "messages", "it");
t.add("dashboard.updates.itemType.NationalCouncilVote", "Votazione Consiglio nazionale", "messages", "it");
t.add("affair.NationalAffairDraftResolution.tooltip.caption", "Disegno documento", "messages", "it");
t.add("affair.PreConsultationNationalAffairEvent.tooltip.caption", "Preconsultazione", "messages", "it");
t.add("affair.StatusChangeNationalAffairEvent.tooltip.caption", "Stato cambiato", "messages", "it");
t.add("affair.NationalAffairDraftReference.tooltip.caption", "Disegno documento", "messages", "it");
t.add("affair.NationalSessionProgramNationalAffairEvent.tooltip.caption", "Pianificato: Trattazione nella sessione", "messages", "it");
t.add("affair.NationalCouncilDebateEvent.tooltip.caption", "Dibattito Consiglio nazionale", "messages", "it");
t.add("affair.FederalCouncilProposalNationalAffairEvent.tooltip.caption", "Disegno Consiglio federale", "messages", "it");
t.add("affair.NationalCommitteePressReleaseNationalAffairEvent.tooltip.caption", "Comunicato stampa commissione", "messages", "it");
t.add("affair.NationalAffairConsultationEndEvent.tooltip.caption", "Fine procedura di consultazione", "messages", "it");
t.add("affair.NationalAffairConsultationStartEvent.tooltip.caption", "Inizio procedura di consultazione", "messages", "it");
t.add("affair.NationalCommitteeProgramEndNationalAffairEvent.tooltip.caption", "Pianificato: Trattazione nella commissione (fine)", "messages", "it");
t.add("affair.NationalCommitteeProgramStartNationalAffairEvent.tooltip.caption", "Pianificato: Trattazione nella commissione", "messages", "it");
t.add("affair.NationalCommitteeProgramRemovedNationalAffairEvent.tooltip.caption", "Rimosso dal programma della commissione", "messages", "it");
t.add("affair.CantonalSessionProgramStartCantonalAffairEvent.tooltip.caption", "Registrazione programma della sessione\/ordine del giorno", "messages", "it");
t.add("layout.search.content_type", "Tipo", "messages", "it");
t.add("reports.source.content", "Quali elementi devono essere inseriti nel rapporto?", "messages", "it");
t.add("national.affairs.list.header.date", "Data di deposito", "messages", "it");
t.add("affair.NationalAffairConsultationSpanEvent.tooltip.caption", "Procedura di consultazione nazionale: Periodo", "messages", "it");
t.add("affair.CantonalAffairConsultationSpanEvent.tooltip.caption", "Periodo procedura di consultazione cantonale", "messages", "it");
t.add("ui.error.crash.title", "Si \u00e8 verificato un errore imprevisto", "messages", "it");
t.add("ui.error.crash.message", "Purtroppo si \u00e8 verificato un errore. Si prega di contattare il team di supporto per ulteriori dettagli: support@politica.ch", "messages", "it");
t.add("ui.error.crash.goto_dashboard", "Alla Dashboard", "messages", "it");
t.add("ui.error.crash.reload", "Ricaricare questa pagina", "messages", "it");
t.add("api.2fa.put.success", "Impostazione dell'autenticazione a 2 fattori salvata con successo.", "messages", "it");
t.add("profile.edit.2fa.title", "Autenticazione a 2 fattori", "messages", "it");
t.add("profile.edit.2fa.google.secret_received", "Chiave", "messages", "it");
t.add("api.2fa.put.error", "Errore durante la lettura dell'API (2FA-Auth)", "messages", "it");
t.add("profile.settings.password.error.match", "Le password non corrispondono", "messages", "it");
t.add("profile.settings.password.error.api", "La password non \u00e8 sicura. Per favore scegliere un'altra Password.", "messages", "it");
t.add("profile.password_reset.form.title", "Ripristinare password", "messages", "it");
t.add("layout.search.orderBy.canton", "Cantone", "messages", "it");
t.add("layout.search.show_advanced", "Pi\u00f9 opzioni", "messages", "it");
t.add("layout.search.hide_advanced", "Meno opzioni", "messages", "it");
t.add("layout.search.filters.internal_fields", "Campi definiti dall'utente", "messages", "it");
t.add("profile.password.submit", "Salvare password", "messages", "it");
t.add("profile.settings.password.error.format", "Password non sicura. Si prega di scegliere almeno 12 caratteri.", "messages", "it");
t.add("customMeetings.delete.message", "Eliminare", "messages", "it");
t.add("api.meetings.delete.success", "Riunione cancellata con successo", "messages", "it");
t.add("api.meetings.delete.error", "Errore durante l'eliminazione della riunione.", "messages", "it");
t.add("layout.custom_meetings.time", "Data della riunione", "messages", "it");
t.add("customMeetings.add_agenda.title", "Creare nuova trattanda", "messages", "it");
t.add("layout.custom_meetings.description", "Descrizione", "messages", "it");
t.add("customMeetings.edit.title", "Modificare", "messages", "it");
t.add("customMeetings.agenda_points.card.title", "Trattande", "messages", "it");
t.add("customMeetings.participants.card.title", "Partecipanti", "messages", "it");
t.add("api.affairs.get.error", "Errore durante il caricamento degli oggetti.", "messages", "it");
t.add("customMeetings.agenda_points.empty", "Nessuna trattativa registrata", "messages", "it");
t.add("dashboard.updates.itemType.cantonalAffairInternalNote", "Annotazione oggetto cantonale", "messages", "it");
t.add("national.affairs.list.header.canton", "Cantone", "messages", "it");
t.add("national.affairs.list.header.actions", "Azione", "messages", "it");
t.add("calendar.nav.prev", "indietro", "messages", "it");
t.add("calendar.nav.next", "avanti", "messages", "it");
t.add("calendar.nav.today", "oggi", "messages", "it");
t.add("ui.select_input.options.none", "Nessuna selezione", "messages", "it");
t.add("reports.source.filters", "Filtro", "messages", "it");
t.add("reports.config.save_as", "Salvare nuovo modello di rapporto", "messages", "it");
t.add("reports.affair.link", "Link", "messages", "it");
t.add("reports.affair.codeAndType", "Nr. \/ Art", "messages", "it");
t.add("reports.format.oldDocx", "Word DOCX", "messages", "it");
t.add("reports.format.oldPdf", "PDF", "messages", "it");
t.add("reports.tableOfContents", "Indice", "messages", "it");
t.add("reports.groups.outputTableOfContents.label", "Indice", "messages", "it");
t.add("reports.tableofcontents", "Indice", "messages", "it");
t.add("api.legislative_periods.get.error", "Errore durante il caricamento della legislatura. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.councillor.stats.get.error", "Errore durante il caricamento della statistica del parlamentare. Si prega di contattare il team di supporto.", "messages", "it");
t.add("reports.config.delete", "Eliminare modello di rapporto", "messages", "it");
t.add("reports.config.load", "Modelli di rapporto", "messages", "it");
t.add("affair.NationalCommitteeProgramSpanNationalAffairEvent.tooltip.caption", "Cambiamento dello stato", "messages", "it");
t.add("affair.CustomAffairNoteCustomEvent.tooltip.caption", "Nota interna", "messages", "it");
t.add("api.file.delete.error", "Errore durante l'eliminazione dell'immagine. Si prega di contattare il team di supporto.", "messages", "it");
t.add("reports.cantonalSessionProgram.title", "Programma della sessione cantonale", "messages", "it");
t.add("affair.nationalAffairConsultationSpanEvent.tooltip.caption", "Procedura di consultazione nazionale: Periodo", "messages", "it");
t.add("profile.settings.reports.affair.template", "Oggetto: modello di rapporto predefinito", "messages", "it");
t.add("api.reports.get.error", "Errore durante il caricamento dei rapporti. Si prega di contattare il team di supporto.", "messages", "it");
t.add("profile.settings.reports.affair.default", "Selezionare rapporto predefinito...", "messages", "it");
t.add("profile.edit.pictureFile.select", "Selezionare immagine...", "messages", "it");
t.add("profile.edit.pictureFile.not_selected", "Nessuna immagine selezionata.", "messages", "it");
t.add("affair.title.report.download", "Esportare \/ scaricare oggetto", "messages", "it");
t.add("profile.settings.commpany.user.delete.confirm", "Eliminare davvero:", "messages", "it");
t.add("affair.NationalAffairNoteCustomEvent.tooltip.caption", "Annotazione", "messages", "it");
t.add("affair.FederalCouncilResponseNationalAffairEvent.tooltip.caption", "Risposta del Consiglio federale", "messages", "it");
t.add("affair.NationalCommitteeAffair.tooltip.caption", "Oggetto della commissione nazionale", "messages", "it");
t.add("customMeetings.tree.customMeeting.goto", "Visualizzazione in dettaglio", "messages", "it");
t.add("reports.affair", "Oggetto singolo", "messages", "it");
t.add("reports.affair.title", "Dettagli sull'oggetto", "messages", "it");
t.add("api.reports.config.put.success", "Modello di rapporto salvato con successo.", "messages", "it");
t.add("reports.config.delete.confirm.message", "Volete davvero eliminare il modello di rapporto?", "messages", "it");
t.add("forms.custom_note_event.delete.success", "Annotazione eliminata con successo", "messages", "it");
t.add("reports.affairSelect.placeholder", "Selezionare oggetto...", "messages", "it");
t.add("reports.affairSelect.label", "Oggetto", "messages", "it");
t.add("profile.settings.company.user_groups.titleDe", "Nome gruppi DE", "messages", "it");
t.add("profile.settings.company.user_groups.titleFr", "Nome gruppi FR", "messages", "it");
t.add("profile.settings.company.user_groups.titleIt", "Nome gruppi IT", "messages", "it");
t.add("user.company.settings.user_group.roles", "Diritti", "messages", "it");
t.add("customDataRoles.customEvents", "Eventi interni", "messages", "it");
t.add("customDataRoles.customSpeakers", "Referenti", "messages", "it");
t.add("customDataRoles.affairGroups", "Gruppi", "messages", "it");
t.add("customDataRoles.customAffairs", "Oggetti interni", "messages", "it");
t.add("customDataRoles.meetings", "Riunioni", "messages", "it");
t.add("customDataRoles.customLinks", "Oggetti collegati", "messages", "it");
t.add("customDataRoles.internalFields", "Campi definiti dall'utente", "messages", "it");
t.add("customDataRoles.mediaObjects", "Files \/ Allegati", "messages", "it");
t.add("customDataRoles.userGroupItems", "Autorizzazioni di voci degli oggetti (annotazioni etc.)", "messages", "it");
t.add("draft_0.title", "Messaggio \/ Rapporto del Consiglio federale", "messages", "it");
t.add("api.reports.confi.put.success", "Modello di rapporto salvato con successo", "messages", "it");
t.add(" api.reports.confi.delete.success", "Modello di rapporto cancellato con successo", "messages", "it");
t.add("reports.affairSelect.placeholde\ufffcr", "Selezionare oggetto...", "messages", "it");
t.add("affair.internalNote.edit", "Modificare", "messages", "it");
t.add("affair.CantonalAffairInternalVoteCustomEvent.tooltip.caption", "Votazione interna", "messages", "it");
t.add("profile.password.new.label", "Nuova password", "messages", "it");
t.add("user.company.settings.user_group.role.read", "Leggere", "messages", "it");
t.add("user.company.settings.user_group.role.write", "Scrivere", "messages", "it");
t.add("user.company.settings.user_group.role.none", "Nessuna", "messages", "it");
t.add("author.national_committee", "Autore: Commissione", "messages", "it");
t.add("layout.search.nationalCommitteesWithPrograms", "Programma della commissione", "messages", "it");
t.add("layout.search.nationalSessions", "Sessione Confederazione", "messages", "it");
t.add("reports.committee", "Rapporto della commissione", "messages", "it");
t.add("groups.tree.group.deleteAffair.confirm", "Volete davvero eliminare l'oggetto dal gruppo?", "messages", "it");
t.add("reports.nationalCommitteesPrograms.title", "Rapporto della commissione", "messages", "it");
t.add("reports.cantonalSessionPrograms.label", "Sessione cantonale", "messages", "it");
t.add("Reports.committee", "Rapporto della commissione", "messages", "it");
t.add("api.timelines.get.error", "Errore durante il caricamento della cronologia. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.parties.get.error", "Errore durante il caricamento dei partiti. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.factions.get.error", "Errore durante il caricamento dei gruppi parlamentari. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.parties.stats.get.error", "Errore durante il caricamento delle statistiche del partito. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.factions.stats.get.error", "Errore durante il caricamento delle statistiche della frazione. Si prega di contattare il team di supporto.", "messages", "it");
t.add("affairGroup.archive", "Archiviare gruppo", "messages", "it");
t.add("affairGroup.archive.confirm", "Volete davvero archiviare questo gruppo?", "messages", "it");
t.add("customMeetings.archive", "Archiviare riunione", "messages", "it");
t.add("customMeetings.archive.confirm", "Volete davvero archiviare la riunione?", "messages", "it");
t.add("layout.search.subscribed.label", "Solamente oggetti a cui si \u00e8 abbonati", "messages", "it");
t.add("Schreiben", "user.company.settings.user_group.role.write", "messages", "it");
t.add("api.groups.error.affair_delete", "Errore durante l'eliminazione delle assegnazioni degli oggetti ad un gruppo. Si prega di contattare il team di supporto.", "messages", "it");
t.add("event.reminders.error.get", "Errore durante il caricamento delle notifiche. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.committees.get.error", "Errore durante il caricamento delle commissioni. Si prega di contattare il team di supporto.", "messages", "it");
t.add("affairGroups.archive", "Archiviare", "messages", "it");
t.add("menu.company.choose", "Cambiare organizzazione\/azienda", "messages", "it");
t.add("api.user.companies.get.error", "Errore durante il caricamento delle aziende\/organizzazioni. Si prega di contattare il team di supporto.", "messages", "it");
t.add("user.company.set.form.title", "Scegliere organizzazione\/azienda", "messages", "it");
t.add("reports.title", "Redigere un rapporto", "messages", "it");
t.add("reports.sections.type.title", "Che tipo di rapporto volete generare?", "messages", "it");
t.add("reports.sections.groups.title", "A partire da quali gruppi volete generare il rapporto?", "messages", "it");
t.add("reports.sections.official.title", "Quali informazioni ufficiali devono contenere gli oggetti nel rapporto?", "messages", "it");
t.add("reports.sections.custom.title", "Quali contenuti specifici degli oggetti devono essere inclusi nel rapporto?", "messages", "it");
t.add("reports.sections.filters.title", "Quali filtri sono da applicare al rapporto?", "messages", "it");
t.add("reports.sections.format.title", "Che tipo di formato deve avere il rapporto?", "messages", "it");
t.add("reports.sections.basic.title", "Quali elementi devono essere inseriti nel rapporto?", "messages", "it");
t.add("reports.sections.presets.title", "Volete salvare le impostazioni di questo rapporto come modello?", "messages", "it");
t.add("reports.sections.submit.title", "Generare il rapporto?", "messages", "it");
t.add("security.saml.loginError", "Errore durante il login con SAML.", "messages", "it");
t.add("author.CantonalCouncillor", "Autore oggetto cantonale", "messages", "it");
t.add("reports.action.header", "Modelli di rapporto salvati", "messages", "it");
t.add("reports.action.label", "Modello attuale", "messages", "it");
t.add("user.company.select_input.label", "Scegliere organizzazione\/azienda", "messages", "it");
t.add("layout.search.contentType.custom_affair", "Oggetti interni", "messages", "it");
t.add("footer.label", "Informazioni legali e protezione dati", "messages", "it");
t.add("reports.author.label", "Autore", "messages", "it");
t.add("reports.subtitle.label", "Osservazione preliminare", "messages", "it");
t.add("file_upload.no_file", "Nessun logo caricato", "messages", "it");
t.add("file_upload.btn_label", "Caricare logo", "messages", "it");
t.add("reports.national_session", "Rapporto della sessione nazionale", "messages", "it");
t.add("reports.national_committee", "Rapporto della commissione nazionale", "messages", "it");
t.add("profile.settings.company.title", "Impostazioni organizzazione \/ azienda", "messages", "it");
t.add("profile.settings.title", "Impostazioni del profilo", "messages", "it");
t.add("profile.settings.navigation.user_company_custom_fields", "Campi definiti dall'utente", "messages", "it");
t.add("profile.settings.navigation.user_company_user_groups", "Diritti \/ Gruppi utente", "messages", "it");
t.add("NoteCustomEvent.is_private.label", "annotazione privata", "messages", "it");
t.add("profile.settings.company.shortcut", "I", "messages", "it");
t.add("profile.settings.company.affair_edit.label", "Modificare azienda\/organizzazione", "messages", "it");
t.add("reports.section.main_section", "Quali commissioni sono da considerate?", "messages", "it");
t.add("customMeetings.meetingAgendaPoint.type.affairsWithSpeakerWithoutCustomNote.label", "Oggetti con referente ma senza annotazioni", "messages", "it");
t.add("councillor.language.D", "Tedesco", "messages", "it");
t.add("councillor.language.F", "Francese", "messages", "it");
t.add("councillor.language.I", "Italiano", "messages", "it");
t.add("api.reports.config.delete.success", "Modello di rapporto cancellato con successo", "messages", "it");
t.add("profile.settings.company.meetings", "Riunioni", "messages", "it");
t.add("ui.select.all", "selezionare tutti", "messages", "it");
t.add("affairs.customEvents.is_private.label", "annotazione privata (visibile solo a me)", "messages", "it");
t.add("reports.national_council.label", "Referente Consiglio nazionale", "messages", "it");
t.add("reports.states_council.label", "Referente Consiglio degli Stati", "messages", "it");
t.add("ui.text_editor.link_label", "Link:", "messages", "it");
t.add("ui.text_editor.save_btn", "Salvare", "messages", "it");
t.add("not_found.title", "La pagina che sta cercando non \u00e8 stata trovata.", "messages", "it");
t.add("not_found.subtitle", "La pagina che sta cercando non \u00e8 stata trovata. Questa potrebbe essere stata spostata o cancellata.", "messages", "it");
t.add("not_found.link.label", "Indietro", "messages", "it");
t.add("layout.search.affairStatesNational", "Ultimo evento", "messages", "it");
t.add("vote.id", "Nr. Votazione", "messages", "it");
t.add("api.subscriptions.get.error", "Errore durante il caricamento degli oggetti a cui si \u00e8 abbonati. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.subscriptions.delete.error", "Errore durante la rimozione degli oggetti sottoscritti. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.subscriptions.post.error", "Errore durante il salvataggio degli oggetti sottoscritti. Si prega di contattare il team di supporto.", "messages", "it");
t.add("reports.meeting_protocol", "Verbali della riunione", "messages", "it");
t.add("reports.meeting_invitation", "Inviti alla riunione", "messages", "it");
t.add("reports.sections.meeting_invitation.title", "Per quale riunione vuole creare l'invito?", "messages", "it");
t.add("reports.sections.meeting_protocol.title", "Per quale riunione vuole creare il verbale?", "messages", "it");
t.add("profile.settings.reports.invitation.template", "Invito alla riunione: modello di rapporto predefinito", "messages", "it");
t.add("profile.settings.reports.protocol.template", "Verbale della riunione: modello di rapporto predefinito", "messages", "it");
t.add("select_input.not_selected", "Ancora nessuna selezione...", "messages", "it");
t.add("layout.search.meetings.label", "Selezionare riunione", "messages", "it");
t.add("api.user.companies.put.success", "Azienda\/organizzazione impostata con successo", "messages", "it");
t.add("affair.nationalAffairInternalVoteCustomEvent.tooltip.caption", "Votazione interna", "messages", "it");
t.add("user.company_select_input.label", "Scegliere organizzazione\/azienda", "messages", "it");
t.add("reports.section.session.title", "Per quale sessione deve essere generato il rapporto?", "messages", "it");
t.add("reports.nationalCommitteeProgram.title", "Rapporto della commissione", "messages", "it");
t.add("affair.actions_card.title", "Informazioni complementari", "messages", "it");
t.add("file_upload.remove", "Eliminare", "messages", "it");
t.add("reports.meetings.sections.meeting_invitation.title", "Per quale riunione deve essere generato il documento?", "messages", "it");
t.add("reports.meetings.sections.official.title", "Quali informazioni ufficiali devono essere contenute nel documento?", "messages", "it");
t.add("reports.meetings.sections.custom.title", "Quali contenuti specifici devono essere inclusi nel documento?", "messages", "it");
t.add("reports.meetings.sections.filters.title", "Quali filtri devono essere applicati?", "messages", "it");
t.add("reports.meetings.sections.basic.title", "Quali elementi di design devono essere emessi?", "messages", "it");
t.add("reports.meetings.sections.format.title", "Che tipo di formato deve avere il documento?", "messages", "it");
t.add("reports.meetings.sections.presets.title", "Vuole salvare le impostazioni come modello?", "messages", "it");
t.add("reports.meetings.sections.submit.title", "Generare il documento?", "messages", "it");
t.add("customMeetings.newMeeting.error", "Errore durante il salvataggio. Si prega di contattare il team di supporto.", "messages", "it");
t.add("reports.nationalCommitteePrograms.title", "Rapporto della commissione", "messages", "it");
t.add("dashboard.updates.itemType.nationalAffairCustomSpeaker", "Relatore", "messages", "it");
t.add("events.termine.categories.all", "Tutte", "messages", "it");
t.add("events.termine.categories.none", "Nessuna", "messages", "it");
t.add("events.termine.categories.1", "Votazioni popolari", "messages", "it");
t.add("events.termine.categories.2", "Elezioni", "messages", "it");
t.add("events.termine.categories.3", "Comunit\u00e0 rurali", "messages", "it");
t.add("events.termine.categories.4", "Sessioni", "messages", "it");
t.add("events.termine.categories.5", "Commissioni", "messages", "it");
t.add("events.termine.categories.6", "Altre", "messages", "it");
t.add("affair_list.select.label", "Data del deposito", "messages", "it");
t.add("layout.affair_groups.updated", "Ultimo evento", "messages", "it");
t.add("dashboard.updates.user", "Utente", "messages", "it");
t.add("file_upload.file_too_big.error", "Il file che volete caricare \u00e8 troppo pesante. Si prega di ridurre le dimensioni del file o di contattare il team di supporto.", "messages", "it");
t.add("affair.CantonalSessionProgramSpanCantonalAffairEvent.tooltip.caption", "Programma della sessione\/ordine del giorno", "messages", "it");
t.add("events.termine.categories", "Categoria evento", "messages", "it");
t.add("calendar.type.select.option.general_dates", "Appuntamenti politici generali", "messages", "it");
t.add("api.rankings.get.error", "Errore durante il caricamento delle statistiche. Si prega di contattare il team di supporto.", "messages", "it");
t.add("rankings.affairs", "Statistiche", "messages", "it");
t.add("rankings.filter.group.label", "Gruppi", "messages", "it");
t.add("api.topics.get.error", "Errore durante il caricamento dei temi. Si prega di contattare il team di supporto.", "messages", "it");
t.add("rankings.list.empty", "Nessuna statistica disponibile.", "messages", "it");
t.add("affair.CantonalSessionProgramEndCantonalAffairEvent.tooltip.caption", "Registrazione programma della sessione\/ordine del giorno", "messages", "it");
t.add("layout.search.orderBy.lastEventDate", "Evento pi\u00f9 recente", "messages", "it");
t.add("profile.customMeetings.archived", "Riunioni archiviate", "messages", "it");
t.add("api.cantonal.councillor.get.error", "Errore durante il caricamento dei parlamentari cantonali. Si prega di contattare il team di supporto.", "messages", "it");
t.add("profile.affairGroups.archived", "Gruppi archiviati", "messages", "it");
t.add("councils.br", "Consiglio federale", "messages", "it");
t.add("affair.FederalCouncilMediaReleaseNationalAffairEvent.tooltip.caption", "Comunicato stampa del Consiglio federale", "messages", "it");
t.add("affair.SdaMediaReleaseNationalAffairEvent.tooltip.caption", "Comunicato stampa dell'ATS", "messages", "it");
t.add("profile.settings.company.custom_fields.html.labelDe", "Etichetta DE", "messages", "it");
t.add("profile.settings.company.custom_fields.html.labelFr", "Etichetta FR", "messages", "it");
t.add("profile.settings.company.custom_fields.html.labelIt", "Etichetta IT", "messages", "it");
t.add("ranking.against", "Dissidente \/ Contro la propria frazione", "messages", "it");
t.add("api.cantonal.consultations.get.error", "Errore durante il caricamento delle procedure in consultazione cantonali. Si prega di contattare il team di supporto.", "messages", "it");
t.add("reports.groups.outputAssignedGroups.label", "Includere gruppi assegnati", "messages", "it");
t.add("api.login.error", "Login impossibile.", "messages", "it");
t.add("security.login.welcome", "Benvenuti da politik.ch", "messages", "it");
t.add("security.login.auth_code", "Codice di autenticazione", "messages", "it");
t.add("cantonal.column", "Fonte", "messages", "it");
t.add("cantonal.dataQuality.sourceType.affairs", "O", "messages", "it");
t.add("cantonal.dataQuality.sourceType.consultations", "PC", "messages", "it");
t.add("affairGroup.linkToAffairGroup", "Alla visualizzazione dei gruppi", "messages", "it");
t.add("customMeetings.linkToDocument", "All'oggetto", "messages", "it");
t.add("not_found.suggested_searches", "Oggetti proposti", "messages", "it");
t.add("affair.results.bydissenters.none", "Senza dissidenti", "messages", "it");
t.add("layout.search.contentType.national_councillor", "Parlamentari nazionali", "messages", "it");
t.add("layout.search.contentType.cantonal_councillor", "Parlamentari cantonali", "messages", "it");
t.add("layout.search.contentType.national_affair_consultation", "Procedure di consultazione nazionali", "messages", "it");
t.add("layout.search.contentType.cantonal_affair_consultation", "Procedure di consultazione cantonali", "messages", "it");
t.add("layout.search.filters", "Filtro", "messages", "it");
t.add("affair.sdaMediaReleaseNationalAffairEvent.title.prefix", "Annuncio ATS", "messages", "it");
t.add("affair.federalCouncilMediaReleaseNationalAffairEvent.title.prefix", "Situazione iniziale", "messages", "it");
t.add("layout.search.type.national", "Oggetti nazionali", "messages", "it");
t.add("layout.search.type.cantonal", "Oggetti cantonali", "messages", "it");
t.add("layout.search.type.custom", "Oggetti interni", "messages", "it");
t.add("not_found.no_similar_results_found", "Non abbiamo trovato nemmeno oggetti simili.", "messages", "it");
t.add("parties.noMembersFound", "Nessuno", "messages", "it");
t.add("reports.groups.numberingTableOfContents.label", "Numerazione", "messages", "it");
t.add("layout.search.orderBy.lastStatus", "Ultimo stato", "messages", "it");
t.add("layout.search.orderBy.author", "Autore", "messages", "it");
t.add("affair.actions_card.auto_save.label", "Viene salvato...", "messages", "it");
t.add("affairGroup.linkToDocument", "Alla visualizzazione dei gruppi", "messages", "it");
t.add("vote.decision.others", "Astenuto\/Assente etc.", "messages", "it");
t.add("auth.password_reset.success", "Password resettata con successo.", "messages", "it");
t.add("user.password.missingdata", "Dati sbagliati", "messages", "it");
t.add("user.password.fieldsnotequal", "I valori non coincidono", "messages", "it");
t.add("user.password.short", "Password troppo corta", "messages", "it");
t.add("consultation.endDate", "Termina il", "messages", "it");
t.add("security.login.submit", "Login", "messages", "it");
t.add("security.wrong_password", "utente sconosciuto o password sbagliata", "messages", "it");
t.add("affair.nationalAffairConsultationOpinionsEvent.tooltip.caption", "Presa di posizione", "messages", "it");
t.add("affair.nationalAffairConsultationReportsEvent.tooltip.caption", "Risultato", "messages", "it");
t.add("profile.settings.language.it", "Italiano", "messages", "it");
t.add("profile.settings.language.en", "Inglese \/ English", "messages", "it");
t.add("profile.settings.data_language.label", "Lingua per i dati (se disponibile)", "messages", "it");
t.add("profile.settings.data_language.sameAsLanguage", "Uguale alla lingua dell'utente", "messages", "it");
t.add("affair.NationalAffairCouncilDocumentsEvent.tooltip.caption", "Documentazione del Consiglio", "messages", "it");
t.add("national.affairs.status.added_to_session_program", "all'ordine del giorno della sessione", "messages", "it");
t.add("national.affairs.status.removed_from_session_program", "Rimosso\/spostato dal programma della sessione", "messages", "it");
t.add("national.affairs.status.deposited", "Depositato", "messages", "it");
t.add("national.affairs.status.added_to_committee_program", "all'ordine del giorno della commissione", "messages", "it");
t.add("national.affairs.status.removed_from_committee_program", "Rimosso\/spostato dal programma della commissione", "messages", "it");
t.add("national.affairs.status.mentioned_in_press_release", "Comunicato stampa (commissione) pubblicato", "messages", "it");
t.add("national.affairs.status.federal_council_proposal_added", "Proposta del Consiglio Federale", "messages", "it");
t.add("national.affairs.status.federal_council_answer_added", "Risposta del Consiglio Federale pubblicata", "messages", "it");
t.add("national.affairs.status.federal_council_opionion_added", "Parere del Consiglio Federale pubblicato", "messages", "it");
t.add("national.affairs.status.preconsultation_added", "Consultazione preliminare", "messages", "it");
t.add("national.affairs.status.federal_council_media_release", "Situazione iniziale formulata", "messages", "it");
t.add("national.affairs.status.sda_media_release", "Annuncio ATS pubblicato", "messages", "it");
t.add("national.affairs.status.council_documents_event", "Documentazione del Consiglio pubblicata", "messages", "it");
t.add("national.affairs.status.unreachable", "Non disponibile nella fonte ufficiale", "messages", "it");
t.add("national.affairs.status.deleted", "Eliminato dalla fonte ufficiale", "messages", "it");
t.add("cantonal.affairs.type.vor.title", "Disegno (Governo)", "messages", "it");
t.add("cantonal.affairs.type.vop.title", "Disegno (Parlamento)", "messages", "it");
t.add("cantonal.affairs.type.mo.title", "Mozione", "messages", "it");
t.add("cantonal.affairs.type.po.title", "Postulato", "messages", "it");
t.add("cantonal.affairs.type.ip.title", "Interpellanza", "messages", "it");
t.add("cantonal.affairs.type.kla.title", "Interrogazione semplice", "messages", "it");
t.add("cantonal.affairs.type.vo.title", "Mozione popolare", "messages", "it");
t.add("cantonal.affairs.type.au.title", "Mandato", "messages", "it");
t.add("cantonal.affairs.type.pai.title", "Iniziativa parlamentare", "messages", "it");
t.add("cantonal.affairs.type.ktiv.title", "Iniziativa cantonale", "messages", "it");
t.add("cantonal.affairs.type.wah.title", "Elezione", "messages", "it");
t.add("cantonal.affairs.type.pe.title", "Petizione", "messages", "it");
t.add("cantonal.affairs.type.ber.title", "Rapporto", "messages", "it");
t.add("cantonal.affairs.type.pae.title", "Raccomandazione parlamentare", "messages", "it");
t.add("cantonal.affairs.type.an.title", "Proposta", "messages", "it");
t.add("cantonal.affairs.type.div.title", "Diversi", "messages", "it");
t.add("cantonal.affairs.type.in.title", "Iniziativa", "messages", "it");
t.add("cantonal.affairs.type.beg.title", "Grazia", "messages", "it");
t.add("cantonal.affairs.type.bes.title", "Ricorso", "messages", "it");
t.add("cantonal.affairs.type.veg.title", "Giuramento", "messages", "it");
t.add("cantonal.affairs.type.vem.title", "Procedura di consultazione", "messages", "it");
t.add("cantonal.affairs.type.ver.title", "Ordinanze e atti", "messages", "it");
t.add("cantonal.affairs.type.fra.title", "Ora delle domande", "messages", "it");
t.add("cantonal.affairs.type.res.title", "Risoluzione", "messages", "it");
t.add("cantonal.affairs.type.ges.title", "Rapporto di gestione", "messages", "it");
t.add("cantonal.affairs.type.bre.title", "Cittadinanza", "messages", "it");
t.add("cantonal.affairs.type.ive.title", "Accordo intercantonale", "messages", "it");
t.add("cantonal.affairs.type.ref.title", "Referendum", "messages", "it");
t.add("cantonal.affairs.type.bel.title", "Decisione", "messages", "it");
t.add("cantonal.affairs.type.dek.title", "Decreto", "messages", "it");
t.add("cantonal.affairs.type.svo.title", "Progetto unico", "messages", "it");
t.add("cantonal.affairs.type.eiv.title", "Iniziativa indiviudale", "messages", "it");
t.add("cantonal.affairs.type.vor.abbr", "Pr. G.", "messages", "it");
t.add("cantonal.affairs.type.vop.abbr", "Pr. Pa.", "messages", "it");
t.add("cantonal.affairs.type.mo.abbr", "Mo.", "messages", "it");
t.add("cantonal.affairs.type.po.abbr", "Po.", "messages", "it");
t.add("cantonal.affairs.type.ip.abbr", "Interpellanza", "messages", "it");
t.add("cantonal.affairs.type.kla.abbr", "I.", "messages", "it");
t.add("cantonal.affairs.type.vo.abbr", "Mo.pop.", "messages", "it");
t.add("cantonal.affairs.type.au.abbr", "Man.", "messages", "it");
t.add("cantonal.affairs.type.pai.abbr", "Iv. Pa.", "messages", "it");
t.add("cantonal.affairs.type.ktiv.abbr", "Iv. Ct.", "messages", "it");
t.add("cantonal.affairs.type.wah.abbr", "El.", "messages", "it");
t.add("cantonal.affairs.type.pe.abbr", "Pet.", "messages", "it");
t.add("cantonal.affairs.type.ber.abbr", "Rap.", "messages", "it");
t.add("cantonal.affairs.type.pae.abbr", "Rac. Pa.", "messages", "it");
t.add("cantonal.affairs.type.an.abbr", "Prop.", "messages", "it");
t.add("cantonal.affairs.type.div.abbr", "Div.", "messages", "it");
t.add("cantonal.affairs.type.in.abbr", "Iv.", "messages", "it");
t.add("cantonal.affairs.type.beg.abbr", "Gr.", "messages", "it");
t.add("cantonal.affairs.type.bes.abbr", "Ric.", "messages", "it");
t.add("cantonal.affairs.type.veg.abbr", "Giur.", "messages", "it");
t.add("cantonal.affairs.type.vem.abbr", "P.C.", "messages", "it");
t.add("cantonal.affairs.type.ver.abbr", "O.", "messages", "it");
t.add("cantonal.affairs.type.fra.abbr", "O.d.", "messages", "it");
t.add("cantonal.affairs.type.res.abbr", "Ris.", "messages", "it");
t.add("cantonal.affairs.type.ges.abbr", "Rap.gest.", "messages", "it");
t.add("cantonal.affairs.type.bre.abbr", "Citt.", "messages", "it");
t.add("cantonal.affairs.type.ive.abbr", "Acc.ict.", "messages", "it");
t.add("cantonal.affairs.type.ref.abbr", "Ref.", "messages", "it");
t.add("cantonal.affairs.type.bel.abbr", "Dec.", "messages", "it");
t.add("cantonal.affairs.type.dek.abbr", "D.", "messages", "it");
t.add("cantonal.affairs.type.svo.abbr", "Pr.u.", "messages", "it");
t.add("cantonal.affairs.type.eiv.abbr", "Iv.ind.", "messages", "it");
t.add("cantonal.affairs.status.first_reading", "Prima lettura", "messages", "it");
t.add("cantonal.affairs.status.second_reading", "Seconda lettura", "messages", "it");
t.add("cantonal.affairs.status.rejected", "Rifiuto", "messages", "it");
t.add("cantonal.affairs.status.rejected_not_definitely_supported", "Rifiuto - Non supportato definitivamente", "messages", "it");
t.add("cantonal.affairs.status.refusal_not_supported_for_the_time_being", "Rifiuto - Non supportato provvisoriamente", "messages", "it");
t.add("cantonal.affairs.status.depreciation", "Stralcio", "messages", "it");
t.add("cantonal.affairs.status.application", "Proposta", "messages", "it");
t.add("cantonal.affairs.status.proposal_for_rejection", "Proposta di rifiuto", "messages", "it");
t.add("cantonal.affairs.status.application_for_depreciation", "Proposta di stralcio", "messages", "it");
t.add("cantonal.affairs.status.application_for_depreciation_in_the_annual_report", "Proposta di stralcio nel rapporto di gestione", "messages", "it");
t.add("cantonal.affairs.status.proposal_for_discussion", "Proposta di discussione", "messages", "it");
t.add("cantonal.affairs.status.proposal_for_urgency", "Proposta d'urgenza", "messages", "it");
t.add("cantonal.affairs.status.application_for_completion", "Proposta di evasione\/liquidazione", "messages", "it");
t.add("cantonal.affairs.status.application_for_extension_of_the_deadline", "Proposta di proroga del termine", "messages", "it");
t.add("cantonal.affairs.status.proposal_for_consent", "Proposta di adesione", "messages", "it");
t.add("cantonal.affairs.status.application_bank_council", "Proposta Consiglio di banca", "messages", "it");
t.add("cantonal.affairs.status.proposal_court", "Proposta Tribunale", "messages", "it");
t.add("cantonal.affairs.status.proposal_commission", "Proposta Commissione", "messages", "it");
t.add("cantonal.affairs.status.proposal_government_council", "Proposta Consiglio di Stato", "messages", "it");
t.add("cantonal.affairs.status.response_management", "Risposta Direzione", "messages", "it");
t.add("cantonal.affairs.status.government_council_answer", "Risposta Consiglio di Stato", "messages", "it");
t.add("cantonal.affairs.status.commencement_of_consultations", "Registrazione delle deliberazioni", "messages", "it");
t.add("cantonal.affairs.status.beginning_discussion", "Inizio discussione", "messages", "it");
t.add("cantonal.affairs.status.report_government_council", "Rapporto Consiglio di Stato", "messages", "it");
t.add("cantonal.affairs.status.report_and_proposal_government_council", "Rapporto e proposta Consiglio di Stato", "messages", "it");
t.add("cantonal.affairs.status.definitely_supported", "Supportato definitivamente", "messages", "it");
t.add("cantonal.affairs.status.discussed", "Discusso", "messages", "it");
t.add("cantonal.affairs.status.urgency_approved", "Urgente", "messages", "it");
t.add("cantonal.affairs.status.deposited", "Depositato", "messages", "it");
t.add("cantonal.affairs.status.receipt", "Presa d'atto", "messages", "it");
t.add("cantonal.affairs.status.supplementary_report", "Rapporto complementare", "messages", "it");
t.add("cantonal.affairs.status.significantly_explained", "Dichiarato pertinente\/conforme", "messages", "it");
t.add("cantonal.affairs.status.done", "Evaso\/liquidato", "messages", "it");
t.add("cantonal.affairs.status.continuation_of_consultations", "Continuazione delle deliberazioni", "messages", "it");
t.add("cantonal.affairs.status.continuation_of_detailed_advice", "Continuazione della deliberazione in dettaglio", "messages", "it");
t.add("cantonal.affairs.status.deadline_extends", "Termine prorogato", "messages", "it");
t.add("cantonal.affairs.status.perusal", "Presa d'atto", "messages", "it");
t.add("cantonal.affairs.status.urgency_not_approved", "Dichiarato non urgente", "messages", "it");
t.add("cantonal.affairs.status.do_not_enter", "Non entrata in materia", "messages", "it");
t.add("cantonal.affairs.status.come_back_proposal", "Proposta di nuova deliberazione", "messages", "it");
t.add("cantonal.affairs.status.rejection", "Rinvio", "messages", "it");
t.add("cantonal.affairs.status.retired", "Ritiro", "messages", "it");
t.add("cantonal.affairs.status.opinion_management", "Parere della direzione", "messages", "it");
t.add("cantonal.affairs.status.opinion_government_council", "Parere Consiglio di Stato", "messages", "it");
t.add("cantonal.affairs.status.part_invalidation", "Dichiarazione di invalidit\u00e0 parziale", "messages", "it");
t.add("cantonal.affairs.status.referral_as_a_postulate", "Rinvio come postulato", "messages", "it");
t.add("cantonal.affairs.status.transfer_to_the_management", "Rinvio alla direzione \/ ufficio della Camera", "messages", "it");
t.add("cantonal.affairs.status.transfer_to_cantonal_council", "Rinvio al Gran Consiglio", "messages", "it");
t.add("cantonal.affairs.status.transfer_to_government_council", "Rinvio al Consiglio di Stato", "messages", "it");
t.add("cantonal.affairs.status.transformation_into_a_postulate", "Conversione in postulato", "messages", "it");
t.add("cantonal.affairs.status.invalid_declared", "Dichiarato\/a invalido\/a", "messages", "it");
t.add("cantonal.affairs.status.varia", "Varia", "messages", "it");
t.add("cantonal.affairs.status.refusal_to_accept_section_25_3_krg", "Rifiuto presa d'atto (\u00a7 25 Abs. 3 KRG)", "messages", "it");
t.add("cantonal.affairs.status.provisionally_supported", "Supportato provvisoriamente", "messages", "it");
t.add("cantonal.affairs.status.choice", "Elezione", "messages", "it");
t.add("cantonal.affairs.status.loss", "Soppressione", "messages", "it");
t.add("cantonal.affairs.status.resumption", "Ripresa", "messages", "it");
t.add("cantonal.affairs.status.approval", "Adesione", "messages", "it");
t.add("cantonal.affairs.status.consent_minority_proposal", "Adesione proposta di minoranza", "messages", "it");
t.add("cantonal.affairs.status.on_session_agenda", "Trattato in Parlamento (all'ordine del giorno)", "messages", "it");
t.add("cantonal.affairs.status.unknown", "Non definito", "messages", "it");
t.add("cantonal.affairs.status.divergent_minority_applications_from_the_commission", "Proposte divergenti della minoranza della commissione", "messages", "it");
t.add("cantonal.affairs.status.divergent_requests_from_the_commission", "Proposte divergenti della commissione", "messages", "it");
t.add("cantonal.affairs.status.interim_report", "Rapporto intermedio", "messages", "it");
t.add("cantonal.affairs.status.different_applications", "Proposte divergenti", "messages", "it");
t.add("cantonal.affairs.status.nonoccurrence_request", "Proposta di non entrata in materia", "messages", "it");
t.add("cantonal.affairs.status.activity_report_of_financial_control", "Rapporto di attivit\u00e0 del controllo finanze", "messages", "it");
t.add("cantonal.affairs.status.validating", "Dichiarazione di validit\u00e0", "messages", "it");
t.add("cantonal.affairs.status.enter", "Entrata in materia", "messages", "it");
t.add("cantonal.affairs.status.final_vote", "Votazione finale", "messages", "it");
t.add("cantonal.affairs.status.transfer_to_commission", "Rinvio alla commissione", "messages", "it");
t.add("cantonal.affairs.status.commission_report", "Rapporto commissione", "messages", "it");
t.add("cantonal.affairs.status.resignation", "Dimissione", "messages", "it");
t.add("cantonal.affairs.status.moved_up", "Successione\/sostituzione", "messages", "it");
t.add("cantonal.affairs.status.approved_with_changes", "Adesione con modifiche", "messages", "it");
t.add("cantonal.affairs.status.session_agenda", "Ordine del giorno \/ Programma della sessione", "messages", "it");
t.add("cantonal.affairs.status.transfer", "Rinvio", "messages", "it");
t.add("cantonal.affairs.status.unmapped", "Non definito", "messages", "it");
t.add("cantonal.affairs.status.shift_advice_cantonal_council", "Posticipazione deliberazione Gran Consiglio", "messages", "it");
t.add("cantonal.affairs.status.decision_commission", "Decisione commissione", "messages", "it");
t.add("cantonal.affairs.status.depreciation_rejected", "Stralcio rifiutato", "messages", "it");
t.add("cantonal.affairs.status.embassy_submission_government_council", "Messaggio \/ Progetto Consiglio di Stato", "messages", "it");
t.add("cantonal.affairs.status.decision_cantonal_council_grossrat_landrat", "Decisione Gran Consiglio", "messages", "it");
t.add("cantonal.affairs.status.come_in_1st_reading", "Entrata in materia, 1. Lettura", "messages", "it");
t.add("cantonal.affairs.status.part_significantly_declaration", "Dichiarazione di pertinenza\/conformit\u00e0 parziale", "messages", "it");
t.add("cantonal.affairs.status.commission_appointed", "Commissione designata", "messages", "it");
t.add("cantonal.affairs.status.referendum_deadline", "Termine referendario in corso", "messages", "it");
t.add("cantonal.affairs.status.referendum_deadline_expired", "Termine referendario scaduto", "messages", "it");
t.add("cantonal.affairs.status.non_declaration_significantly", "Dichiarazione di non pertinenza\/conformit\u00e0", "messages", "it");
t.add("cantonal.affairs.status.come_into_effect", "Entrata in vigore", "messages", "it");
t.add("cantonal.affairs.status.plebiscite", "Votazione popolare", "messages", "it");
t.add("cantonal.affairs.status.report", "Rapporto", "messages", "it");
t.add("cantonal.affairs.status.nomination", "Proposta d'elezione", "messages", "it");
t.add("cantonal.affairs.status.vote", "Votazione", "messages", "it");
t.add("cantonal.affairs.status.faction_request", "Proposta frazione", "messages", "it");
t.add("cantonal.affairs.status.accepted_vote", "Votazione accettata", "messages", "it");
t.add("cantonal.affairs.status.rejected_vote", "Votazione respinta", "messages", "it");
t.add("cantonal.affairs.status.sistiert", "Sospeso", "messages", "it");
t.add("cantonal.affairs.status.medienmitteilung", "Comunicato stampa pubblicato", "messages", "it");
t.add("dashboard.updates.itemType.customAffairCustomSpeaker", "Relatore nota interna", "messages", "it");
t.add("dashboard.updates.itemType.cantonalAffairCustomSpeaker", "Relatore oggetto cantonale", "messages", "it");
t.add("profile.settings.notificationPreference.keywords.german", "Parole chiave in tedesco", "messages", "it");
t.add("profile.settings.notificationPreference.keywords.french", "Parole chiave in francese", "messages", "it");
t.add("profile.settings.notificationPreference.keywords.italian", "Parole chiave in italiano", "messages", "it");
t.add("layout.search.config.save_as", "Salvare il nuovo modello di ricerca", "messages", "it");
t.add("layout.search.config.save", "Aggiornare modello di ricerca", "messages", "it");
t.add("layout.search.config.form.description", "Salvate le impostazioni di ricerca come modello di ricerca:", "messages", "it");
t.add("layout.search.config.form.title.input.label", "Etichetta", "messages", "it");
t.add("layout.search.config.form.title.error.message", "Errore durante il salvataggio del modello di ricerca", "messages", "it");
t.add("layout.search.config.save.form.title", "Aggiornare modello di ricerca", "messages", "it");
t.add("layout.search.config.load", "Modelli di ricerca", "messages", "it");
t.add("layout.search.config.delete", "Eliminare modello di ricerca", "messages", "it");
t.add("layout.search.config.invalid", "Impostazioni non valide per il modello di ricerca", "messages", "it");
t.add("api.search.config.save.success", "Modello di ricerca salvato con successo", "messages", "it");
t.add("api.search.config.save.error", "Errore durante il salvataggio del modello di ricerca", "messages", "it");
t.add("api.search.config.delete.success", "Modello di ricerca eliminato con successo", "messages", "it");
t.add("api.search.config.delete.error", "Errore durante la cancellazione del modello di ricerca", "messages", "it");
t.add("api.search.config.get.error", "Errore durante il caricamento dei modelli di ricerca", "messages", "it");
t.add("api.search.config.put.success", "Modello di ricerca salvato con successo", "messages", "it");
t.add("api.search.config.put.error", "Errore durante il salvataggio del modello di ricerca", "messages", "it");
t.add("layout.search.config.action.header", "Modelli di ricerca salvati", "messages", "it");
t.add("layout.search.config.action.label", "Modello di ricerca attuale", "messages", "it");
t.add("layout.search.config.delete.confirm.message", "Volete davvero eliminare il modello di ricerca?", "messages", "it");
t.add("profile.settings.company.custom_fields.html.labelEn", "Etichetta EN", "messages", "it");
t.add("profile.settings.company.internal_fields.html.labelEn", "Etichetta EN", "messages", "it");
t.add("profile.settings.company.internal_fields.ratings.labelEn", "Etichetta EN", "messages", "it");
t.add("profile.settings.company.custom_fields.rating.labelEn", "Etichetta EN", "messages", "it");
t.add("cantonal.affairs.type.bot.title", "Messaggio", "messages", "it");
t.add("cantonal.affairs.type.bot.abbr", "Bot", "messages", "it");
t.add("untranslated.original.prefix", "[Titolo originale]", "messages", "it");
t.add("customMeetings.pdf.assignedUsers.label", "Utenti assegnati", "messages", "it");
t.add("affairGroup.export.header.assignedUsers", "Assegnato a", "messages", "it");
t.add("affairGroup.export.header.assignedUser", "Assegnato a", "messages", "it");
t.add("cantonal.affairs.status.initiationOfConsultation", "Avvio del processo della procedura di consultazione", "messages", "it");
t.add("cantonal.affairs.status.applicationFromCantonalManagement", "Proposta direzione cantonale", "messages", "it");
t.add("cantonal.affairs.status.answerFromCantonalManagement", "Risposta direzione cantonale", "messages", "it");
t.add("cantonal.affairs.status.reportFromCantonalManagement", "Rapporto direzione cantonale", "messages", "it");
t.add("layout.search.affairTypes.national", "Tipologia di oggetto (nazionale)", "messages", "it");
t.add("layout.search.affairTypes.cantonal", "Tipologia di oggetto (cantonale)", "messages", "it");
t.add("reports.groups.outputInternalLink.label", "Link a politica.ch", "messages", "it");
t.add("reports.affair.internalLink", "Link politik.ch", "messages", "it");
t.add("profile.settings.company.custom_fields.attachment.add", "Nuovo campo file", "messages", "it");
t.add("affair.internalAttachment.uploadFiles", "Aggiungere files", "messages", "it");
t.add("affair.internalAttachment.renameModal.label", "Rinominare file", "messages", "it");
t.add("affair.internalAttachment.renameModal.description", "Come volete rinominare il file?", "messages", "it");
t.add("affair.internalAttachment.renameModal.input.label", "Nuovo nome del file", "messages", "it");
t.add("affair.internalAttachment.renameModal.error.message", "Errore nel rinominare il file.", "messages", "it");
t.add("affair.internalAttachment.renameModal.button.rename_attachment", "Rinominare", "messages", "it");
t.add("api.profile.settings.company.internal_fields.attachment.put.success", "File rimosso con successo.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.attachment.put.error", "Errore durante la rimozione del file. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.attachment.post.success", "File salvato con successo.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.attachment.post.error", "Errore durante il salvataggio del file. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.attachment.rename.success", "File rinominato con successo.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.attachment.rename.error", "Errore nel rinominare il file. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.attachments.get.error", "Errore durante il caricamento dei files. Si prega di contattare il team di supporto.", "messages", "it");
t.add("profile.settings.company.custom_fields.attachment.labelDe", "Etichetta DE", "messages", "it");
t.add("profile.settings.company.custom_fields.attachment.labelFr", "Etichetta FR", "messages", "it");
t.add("profile.settings.company.custom_fields.attachment.labelIt", "Etichetta IT", "messages", "it");
t.add("profile.settings.company.custom_fields.attachment.labelEn", "Etichetta EN", "messages", "it");
t.add("api.file.upload.error", "Errore durante il caricamento del file.", "messages", "it");
t.add("reports.config.form.checkbox_private", "Progetto privato (non visibile agli altri)", "messages", "it");
t.add("councillor.citizenship", "Cittadinanza", "messages", "it");
t.add("councillor.civil.status", "Stato civile", "messages", "it");
t.add("councillor.kids", "Figli", "messages", "it");
t.add("councillor.address", "Indirizzo", "messages", "it");
t.add("councillor.website", "Sito Web", "messages", "it");
t.add("profile.settings.company.internal_fields.attachment.labelDe", "Etichetta DE", "messages", "it");
t.add("profile.settings.company.internal_fields.attachment.labelFr", "Etichetta FR", "messages", "it");
t.add("profile.settings.company.internal_fields.attachment.labelIt", "Etichetta IT", "messages", "it");
t.add("profile.settings.company.internal_fields.attachment.labelEn", "Etichetta EN", "messages", "it");
t.add("councillor.military.rank", "Grado militare", "messages", "it");
t.add("api.profile.settings.company.internal_fields.attachment.delete.success", "Eliminazione avvenuta con successo", "messages", "it");
t.add("reports.config.form.title.error.message", "Errore durante il salvataggio", "messages", "it");
t.add("councillor.personal.details", "Generalit\u00e0", "messages", "it");
t.add("layout.search.start_search", "Inizia la ricerca", "messages", "it");
t.add("councillor.submitted", "Depositato", "messages", "it");
t.add("councillor.subscribed", "Abbonato", "messages", "it");
t.add("councillor.submitted.proposals", "Interventi depositati", "messages", "it");
t.add("button.show.all", "Mostrare tutti", "messages", "it");
t.add("councillor.advances.topics", "Temi degli interventi", "messages", "it");
t.add("councillor.success.advances", "Successo degli interventi", "messages", "it");
t.add("councillor.title.topics.success", "Temi e successi", "messages", "it");
t.add("councillor.absences.and.abstentions", "Comportamento di voto", "messages", "it");
t.add("councillor.absences.from.votes", "Assenze per il %count% delle votazioni", "messages", "it");
t.add("councillor.parliamentary.group", "Gruppi parlamentari", "messages", "it");
t.add("councillor.actual.title", "Attuale", "messages", "it");
t.add("councillor.previous.title", "Precedente", "messages", "it");
t.add("affairs.assign_users.add_teammembers.button_title", "Assegnare utente", "messages", "it");
t.add("affairs.assign_users.add_teammembers.label", "Assegnare utente", "messages", "it");
t.add("affairs.assign_users.unassign_teammember.button_title", "Rimuovere l'assegnazione a utente", "messages", "it");
t.add("api.affair.assigned_users.get.error", "Utenti assegnati non possono essere caricati.", "messages", "it");
t.add("affairs.assign_users.unassign_teammember.modal.title", "Siete sicuri di volere eliminare i seguenti utenti:", "messages", "it");
t.add("affairs.assign_users.form.title", "Assegnare utente", "messages", "it");
t.add("affairs.assign_users.form.groups_more", "altri", "messages", "it");
t.add("api.user.assign_affair.success", "Utente assegnato con successo", "messages", "it");
t.add("api.user.assign_affair.error", "Errore durante l'assegnazione degli utenti. Si prega di contattare il team di supporto.", "messages", "it");
t.add("reports.groups.outputAssignedUsers.label", "Utenti assegnati", "messages", "it");
t.add("reports.assigned_users.label", "Utenti assegnati", "messages", "it");
t.add("api.basic_users.get.error", "Utente non pu\u00f2 essere caricato. Si prega di contattare il team di supporto.", "messages", "it");
t.add("affair_list.select.assigned_users.label", "Utenti assegnati", "messages", "it");
t.add("councillor.aTotalOf", "nell'insieme", "messages", "it");
t.add("affair_list.select.authors.label", "Autore", "messages", "it");
t.add("reports.authors.label", "Autore", "messages", "it");
t.add("national.consultations.details.cancelled", "Interrotte", "messages", "it");
t.add("cantonal.affairs.status.inProgress", "in revisione \/ in trattamento", "messages", "it");
t.add("cantonal.affairs.status.courtDecisions", "Decisione Tribunale", "messages", "it");
t.add("cantonal.affairs.status.disagreeWithConversion", "In disaccordo con la modifica", "messages", "it");
t.add("cantonal.affairs.status.meeting", "Riunione", "messages", "it");
t.add("cantonal.affairs.status.treatmentByTheCouncils", "Trattazione da parte dei Consigli", "messages", "it");
t.add("profile.settings.company.custom_fields.affairs_label", "Oggetti", "messages", "it");
t.add("profile.settings.company.custom_fields.meetings_label", "Riunioni", "messages", "it");
t.add("profile.settings.compay.custom_meeting_html_fields_setting.delete.confirm", "Eliminare?", "messages", "it");
t.add("profile.settings.company.custom_meetings_html_fields.add", "Aggiungere", "messages", "it");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderDe", "Etichetta DE", "messages", "it");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderFr", "Etichetta FR", "messages", "it");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderIt", "Etichetta IT", "messages", "it");
t.add("profile.settings.company.custom_meetings_html_fields.placeholderEn", "Etichetta EN", "messages", "it");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelDe", "Etichetta DE", "messages", "it");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelFr", "Etichetta FR", "messages", "it");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelIt", "Etichetta IT", "messages", "it");
t.add("profile.settings.company.custom_meeting_html_fields_setting.labelEn", "Etichetta EN", "messages", "it");
t.add("customMeetings.editMeeting.date.label", "Data \/ ora", "messages", "it");
t.add("customMeetings.editMeeting.subject.label", "Oggetto", "messages", "it");
t.add("customMeetings.editMeeting.description.label", "Descrizione", "messages", "it");
t.add("customMeetings.editMeeting.location.label", "Luogo", "messages", "it");
t.add("customMeetings.editMeeting.president.label", "Presidenza", "messages", "it");
t.add("customMeetings.editMeeting.protocol.label", "Protocollo", "messages", "it");
t.add("customMeetings.editMeeting.guests.label", "Ospiti", "messages", "it");
t.add("api.meeting.get.error", "Errore durante il caricamento delle riunioni. Si prega di contattare il team di supporto.", "messages", "it");
t.add("layout.custom_meetings.custom_fields", "Dettagli", "messages", "it");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.get.error", "Errore durante il caricamento dei campi interni delle riunioni. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.put.success", "Salvato con successo", "messages", "it");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.put.error", "Errore durante il caricamento dei campi interni delle riunioni. Si prega di contattare il team di supporto.", "messages", "it");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.post.success", "Salvato con successo", "messages", "it");
t.add("api.profile.settings.company.custom_meeting_internal_fields.html.post.error", "Errore durante il salvataggio. Si prega di contattare il team di supporto.", "messages", "it");
t.add("custom_meeting.internalHtmlField.save", "Salvare", "messages", "it");
t.add("custom_meeting.internalHtmlField.edit", "modificare", "messages", "it");
t.add("custom_meeting.internalHtmlField.error", "Errore", "messages", "it");
t.add("cantonal.affairs.status.forProcessingByTheDepartment", "alla direzione per revisione", "messages", "it");
t.add("layout.reports.config.save_as", "Salvare nuovo modello di rapporto", "messages", "it");
t.add("layout.reports.update.config", "Aggiornare modello di rapporto", "messages", "it");
t.add("reports.affairStates.label", "Stato dell'oggetto", "messages", "it");
t.add("cantonal.affair.attachment", "Allegato", "messages", "it");
t.add("security.login.auth_msg", "Si prega di inserire il codice della propria app di autentificazione.", "messages", "it");
t.add("security.login.trusted", "Fidarsi di questo dispositivo per 30 giorni", "messages", "it");
t.add("profile.edit.2fa.status", "Stato", "messages", "it");
t.add("profile.edit.2fa.activated", "\u00e9 attivata", "messages", "it");
t.add("affairs.actors.and.roles", "Cofirmatario", "messages", "it");
t.add("affairs.actor.and.role.cosigners", "Cofirmatario", "messages", "it");
t.add("affairs.actor.and.role.adapters", "Assumente", "messages", "it");
t.add("affairs.actor.and.role.opponents", "Opposto", "messages", "it");
t.add("cantonal.affairs.status.decision", "Decisione", "messages", "it");
t.add("cantonal.affairs.status.nominations", "Nomina", "messages", "it");
t.add("event.reminders.button.edit", "Modificare notifiche", "messages", "it");
t.add("calendar.event.status.share", "Aggiungere al calendario", "messages", "it");
t.add("calendar.event.tooltip.unshare", "Rimuovere evento dal calendario condiviso", "messages", "it");
t.add("calendar.event.tooltip.share", "Inserire evento nel calendario condiviso", "messages", "it");
t.add("calendar.share.error", "Errore durante la condivisione del calendario. Si prega di contattare il team di supporto.", "messages", "it");
t.add("calendar.settings.title", "Impostazioni calendario condiviso", "messages", "it");
t.add("calendar.settings.status.enabled", "Attivare calendario condiviso", "messages", "it");
t.add("calendar.settings.status.disabled", "Disattivare calendario condiviso", "messages", "it");
t.add("calendar.settings.placeholder", "Per vedere l'indirizzo del calendario condiviso, deve prima attivarlo.", "messages", "it");
t.add("profile.settings.navigation.calendar", "Calendario condiviso", "messages", "it");
t.add("ui.link.copy", "Copiare link", "messages", "it");
t.add("ui.link.copied", "Link copiato", "messages", "it");
t.add("affair.events.custom.attachment.post.error", "Errore durante il caricamento del file. Si prega di contattare il team di supporto: support@politik.ch", "messages", "it");
t.add("affairs.customEvents.fields.note.attachments", "Files", "messages", "it");
t.add("affairs.customEvents.fields.note.uploadFiles", "Caricare file", "messages", "it");
t.add("national.parliamentaryGroup.membership.function.president", "Presidente", "messages", "it");
t.add("national.parliamentaryGroup.membership.function.coPresident", "Co-presidente", "messages", "it");
t.add("national.parliamentaryGroup.membership.function.member", "Membro", "messages", "it");
t.add("interests.tooltip.organ.function", "Organo \/ Funzione", "messages", "it");
t.add("councillor.interests.title", "Legami d'interesse", "messages", "it");
t.add("councillor.title.current.mandate", "Mandati parlamentari attuali", "messages", "it");
t.add("councillors.interests.paid", "pagato", "messages", "it");
t.add("councillors.interests.unpaid", "volontario", "messages", "it");
t.add("national.notifications.preferences.label", "Specifichi se e quali notifiche vuole ricevere per gli oggetti federali.", "messages", "it");
t.add("cantonal.notifications.preferences.label", "Specifichi se e quali notifiche vuole ricevere per gli oggetti dei cantoni.", "messages", "it");
t.add("get.national.notifications.label", "Notifiche ricevute per il Consiglio federale", "messages", "it");
t.add("get.cantonal.notifications.label", "Notifiche ricevute per i cantoni", "messages", "it");
t.add("search.terms.notifications.preferences", "Notifica solo i miei termini di ricerca", "messages", "it");
t.add("search.terms.restore", "Ripristinare", "messages", "it");
t.add("search.terms.page.title", "Termini di ricerca", "messages", "it");
t.add("search.terms.page.define.message", "Definisca qui tutti i suoi termini di ricerca rilevanti. La avviseremo ogni volta che appariranno in nuovi oggetti o aggiornamenti.", "messages", "it");
t.add("search.terms.select.all.types", "Selezionare tutti i tipi", "messages", "it");
t.add("attachment.delete.confirm", "Siete sicuri di voler cancellare questo allegato?", "messages", "it");
t.add("cantonal.affairs.status.answerCovenant", "Risposta Consiglio federale", "messages", "it");
t.add("councillor.parliamentary.groups", "Gruppi parlamentari", "messages", "it");
t.add("councillor.former.commissions", "Mandati parlamentari precedenti", "messages", "it");
t.add("councillor.proposals.submitted", "Depositato", "messages", "it");
t.add("api.links.delete.success", "L'oggetto collegato \u00e8 stato eliminato.", "messages", "it");
t.add("api.links.delete.error", "Errore nell'eliminazione degli oggetti collegati. Si prega di riprovare.", "messages", "it");
t.add("groups.link.to_unarchived_groups.title", "Ai gruppi", "messages", "it");
t.add("groups.link.to_archived_groups.title", "Ai gruppi archiviati", "messages", "it");
t.add("affairGroups.unarchive", "Ripristinare gruppo", "messages", "it");
t.add("affairGroup.unarchive.confirm", "Siete sicuri di voler togliere questo gruppo dall'archivio?", "messages", "it");
t.add("api.groups.put.unarchive.success", "Il gruppo \u00e8 stato ripristinato con successo.", "messages", "it");
t.add("api.groups.put.unarchive.error", "Il gruppo non \u00e8 potuto essere ripristinato. Si prega di riprovare.", "messages", "it");
t.add("api.groups.put.archive.success", "Il gruppo \u00e8 stato archiviato.", "messages", "it");
t.add("api.groups.put.archive.error", "Il gruppo non \u00e8 potuto essere archiviato. Si prega di riprovare.", "messages", "it");
t.add("customMeetings.unarchive", "Ripristinare la riunione", "messages", "it");
t.add("api.meetings.put.unarchive.success", "La riunione \u00e8 stata ripristinata con successo.", "messages", "it");
t.add("api.meetings.put.unarchive.error", "Non \u00e8 stato possibile ripristinare la riunione. Si prega di riprovare.", "messages", "it");
t.add("api.meetings.put.archive.success", "La riunione \u00e8 stata archiviata.", "messages", "it");
t.add("api.meetings.put.archive.error", "La riunione non ha potuto essere archiviata. Si prega di riprovare.", "messages", "it");
t.add("customMeetings.link.to_unarchived_groups.title", "Alle riunioni", "messages", "it");
t.add("customMeetings.link.to_archived_groups.title", "Alle riunioni archiviate", "messages", "it");
t.add("calendar.modal.text.not.shared", "L'evento \u00e8 stato aggiunto al suo calendario condiviso. Per vederlo, \u00e8 necessario attivare il calendario condiviso.\u2028 Potete farlo ora o pi\u00f9 tardi, sotto \"Il vostro nome\" > Account utente > Calendario condiviso.", "messages", "it");
t.add("calendar.modal.not.active", "Il calendario condiviso non \u00e8 attivato", "messages", "it");
t.add("calendar.modal.activate.later", "Archiviare pi\u00f9 tardi", "messages", "it");
t.add("calendar.modal.activate.now", "Alle impostazioni", "messages", "it");
t.add("profile.settings.nationalNotificationPreferences.pressRelease", "Comunicato stampa", "messages", "it");
t.add("affair.councilStatesDebate.tooltip.caption", "Dibattiti Consiglio degli Stati", "messages", "it");
t.add("cosigners.empty.list", "Questo oggetto non ha alcun firmatario", "messages", "it");
t.add("search.terms.apply.all.cantons", "Applicare a tutti i cantoni", "messages", "it");
t.add("cantons.settings.reset.confirm", "In questo modo le impostazioni per tutti i cantoni saranno sovrascritte. Vuole continuare ?", "messages", "it");
t.add("councillor.phone", "Numero di telefono", "messages", "it");
t.add("cantonal.overview.government", "Governo", "messages", "it");
t.add("api.government.author.get.error", "Errore durante il recupero dell'autore del governo", "messages", "it");
t.add("custom.affairs.form.edit.title", "Modificare oggetto interno", "messages", "it");
t.add("affair.actions_card.attachments_title", "Allegati", "messages", "it");
t.add("affair.actions_card.visual_report_section_title", "Rapporto grafico", "messages", "it");
t.add("api.profile.settings.company.internal_fields.builtin.get.error", "Errore durante la lettura. Si prega di contattare il team di supporto.", "messages", "it");
t.add("affair.builtin_fields.time_axis.beginning_label", "All'inizio", "messages", "it");
t.add("affair.builtin_fields.time_axis.end_label", "Poco prima della fine", "messages", "it");
t.add("affair.builtin_fields.need_for_action.low_prio_label", "Monitoraggio", "messages", "it");
t.add("affair.builtin_fields.need_for_action.high_prio_label", "Urgente necessit\u00e0 di azione", "messages", "it");
t.add("api.profile.settings.company.internal_fields.ratings.put.sucess", "Il campo \u00e8 stato aggiornato con successo.", "messages", "it");
t.add("api.profile.settings.company.internal_fields.ratings.post.sucess", "Il campo \u00e8 stato salvato con successo.", "messages", "it");
t.add("reports.visual_report", "Rapporto grafico", "messages", "it");
t.add("reports.visual_report.title", "Creare un rapporto grafico", "messages", "it");
t.add("reports.sections.visible_internal_fields.title", "Quali dati interni volete rendere visibili?", "messages", "it");
t.add("reports.sections.visible_internal_fields.description", "Si prega di non condividere dati sensibili. Questo rapporto sar\u00e0 visibile tramite un URL pubblico e i vostri dati interni non saranno protetti. I campi di dati interni \"Stato\", \"Urgenza\" e \"Impatto\" sono obbligatori e saranno necessari per costruire il rapporto grafico. I campi interni che seleziona come filtri saranno anch'essi mostrati di default.", "messages", "it");
t.add("reports.sections.visual_report_filter_fields.title", "Quali campi definiti dall'utente vuole usare come filtro? (max. 2 filtri)", "messages", "it");
t.add("reports.sections.make_report_public.toggle.title", "Pubblicare rapporto", "messages", "it");
t.add("reports.sections.save_report.title", "Salvare rapporto", "messages", "it");
t.add("form.visual_report.submit", "Salvare rapporto grafico", "messages", "it");
t.add("reports.save_visual_report.error", "Si \u00e8 verificato un errore durante il salvataggio del rapporto grafico. Si prega di riprovare.", "messages", "it");
t.add("reports.save_visual_report.success_modal.title", "Il rapporto grafico \u00e8 stato creato con successo.", "messages", "it");
t.add("reports.save_visual_report.success_modal.description", "Il rapporto grafico <strong>%reportName%<\/strong> \u00e8 stato creato con successo con <strong>%numberOfGroups% groups.<\/strong>", "messages", "it");
t.add("reports.save_visual_report.success_modal.public_url", "URL pubblico:", "messages", "it");
t.add("reports.save_visual_report.success_modal.copy_url_button.title", "Copiare URL", "messages", "it");
t.add("reports.save_visual_report.success_modal.open_report_button.title", "Aprire il rapporto in una nuova finestra", "messages", "it");
t.add("internalFieldDefinitions.builtIn.size.low.label", "Bassa", "messages", "it");
t.add("internalFieldDefinitions.builtIn.size.medium.label", "Media", "messages", "it");
t.add("internalFieldDefinitions.builtIn.size.high.label", "Alta", "messages", "it");
t.add("internalFieldDefinitions.builtIn.size.veryHigh.label", "Molto alta", "messages", "it");
t.add("cantonal.affairs.status.changeRequest", "Proposta di modifica", "messages", "it");
t.add("cantonal.affairs.status.cantonalInitiativeSubmission", "Inoltro iniziativa cantonale", "messages", "it");
t.add("vote.national_council.error", "I voti del Consiglio nazionale non sono potuti essere recuperati. Si prega di riprovare.", "messages", "it");
t.add("vote.council_of_states.error", "I voti del Consiglio degli Stati non sono potuti essere recuperati. Si prega di riprovare.", "messages", "it");
t.add("groups.tree.group.openGroupDetail", "Alla pagina dei gruppi", "messages", "it");
t.add("reports.save_visual_report.success_modal.copied_to_clipboard", "Link copiato negli appunti", "messages", "it");
t.add("committee.private.affairs", "Oggetti privati", "messages", "it");
t.add("reports.visual_report.not_complete_affairs", "Il rapporto contiene oggetti per i quali non sono ancora state fornite informazioni sulla rilevanza, lo stato o la necessit\u00e0 di azione.", "messages", "it");
t.add("reports.visual_report.exceeding_limit", "Il rapporto contiene pi\u00f9 di 35 oggetti. Per una migliore visione d'insieme, vi consigliamo di limitare la vostra selezione.", "messages", "it");
t.add("reports.visual_report.create_anyway", "Ignorare e continuare", "messages", "it");
t.add("reports.visual_report.back_to_reports", "Ritornare alle impostazioni", "messages", "it");
t.add("reports.sections.make_report_public.title", "Volete pubblicare ora il rapporto?", "messages", "it");
t.add("reports.sections.make_report_public.description", "Questo rapporto grafico sar\u00e0 visibile a tutti coloro con cui avete condiviso l'URL pubblico. Potete cambiare questa impostazione in qualsiasi momento.", "messages", "it");
t.add("reports.sections.report_name.title", "Come volete nominare il vostro rapporto grafico?", "messages", "it");
t.add("reports.report_name_input.label", "Nome del rapporto", "messages", "it");
t.add("api.reports.visual_repor_config.get.error", "Non \u00e8 stato possibile caricare l'ultimo rapporto grafico. Si prega di riprovare.", "messages", "it");
t.add("reports.visual_report_config.delete.confirm.message", "Volete davvero eliminare questo rapporto grafico?", "messages", "it");
t.add("api.reports.visual_report_config.delete.success", "Il rapporto grafico \u00e8 stato eliminato.", "messages", "it");
t.add("api.reports.visual_report_config.delete.error", "Si \u00e8 verificato un errore durante l'eliminazione del rapporto grafico. Si prega di riprovare.", "messages", "it");
t.add("reports.load_visual_report.error", "Il rapporto grafico non pu\u00f2 essere caricato. Si prega di riprovare.", "messages", "it");
t.add("reports.update_visual_report.error", "Non \u00e8 stato possibile aggiornare il rapporto grafico. Si prega di riprovare.", "messages", "it");
t.add("reports.update_visual_report.success", "Il rapporto grafico \u00e8 stato aggiornato.", "messages", "it");
t.add("cantonal.affairs.status.opinion", "Parere", "messages", "it");
t.add("internalFieldDefinitions.builtIn.y.label", "Necessit\u00e0 di azione", "messages", "it");
t.add("internalFieldDefinitions.builtIn.x.label", "Progresso", "messages", "it");
t.add("internalFieldDefinitions.builtIn.size.label", "Rilevanza", "messages", "it");
t.add("visualiser.beginning", "All'inizio", "messages", "it");
t.add("visualiser.end", "Poco prima della fine", "messages", "it");
t.add("visualiser.monitoring", "Osservare", "messages", "it");
t.add("visualiser.urgency", "Urgente necessit\u00e0 di azione", "messages", "it");
t.add("dashboard.newest_affairs", "Oggetti recenti", "messages", "it");
t.add("dashboard.updated_affairs", "Aggiornamenti", "messages", "it");
t.add("dashboard.assigned_affairs", "Assegnato", "messages", "it");
t.add("dashboard.team_actions", "Aggiornamenti Team", "messages", "it");
t.add("shared.affair.type", "Tipo di oggetto", "messages", "it");
t.add("shared.affair.lastupdate", "Ultimo evento il", "messages", "it");
t.add("shared.affair.linkedaffairs", "Oggetti collegati", "messages", "it");
t.add("shared.further.info", "Ulteriori informazioni", "messages", "it");
t.add("shared.affair.linkedaffairs.none", "Nessun oggetto collegato", "messages", "it");
t.add("reports.visual_report_menu.header", "Ultimi rapporti grafici", "messages", "it");
t.add("cantonal.affairAuthorRole.signer", "Prima firmataria \/ primo firmatario", "messages", "it");
t.add("cantonal.affairAuthorRole.co_signer", "Cofirmataria \/ Cofirmatario", "messages", "it");
t.add("cantonal.affairAuthorRole.commission", "Commissione preliminare", "messages", "it");
t.add("consultation.submittedByOn.exist", "Depositato da %councillors%. Inizia il %date%", "messages", "it");
t.add("consultation.submittedByOn.none", "Depositato da %councillors%. Inizia il %date%", "messages", "it");
t.add("shared.affair.status.none", "Stato non disponibile", "messages", "it");
t.add("customMeetings.tree.customMeeting.openMeeting", "Alla pagina della riunione", "messages", "it");
t.add("cantonal.affairs.status.urgentInterpellation", "Interpellanza urgente", "messages", "it");
t.add("shared.affair.affairGroups", "Gruppo", "messages", "it");
t.add("reports.save_visual_report.success_copied_url_button.title", "Copiato!", "messages", "it");
t.add("affair.customShortTitle.information", "Max. 40 caratteri", "messages", "it");
t.add("customMeetings.unarchive.confirm", "Volete ripristinare la riunione?", "messages", "it");
t.add("cantonal.affairs.status.removedFromSource", "L'oggetto \u00e8 stato rimosso dal sito web cantonale", "messages", "it");
t.add("internalFieldDefinitions.builtIn.shortTitle.label", "Titolo abbreviato", "messages", "it");
t.add("share.filters.groups", "Gruppi", "messages", "it");
t.add("dashboard.updates.item_topics", "Temi", "messages", "it");
t.add("shared.filters.show.all", "Mostrare tutto", "messages", "it");
t.add("shared.filters.hide.all", "Nascondere tutto", "messages", "it");
t.add("dashboard.newest_affairs.tooltip", "Tutti gli oggetti importati negli ultimi 7 giorni che corrispondono ai vostri termini di ricerca. Se non avete ancora definito alcun termine di ricerca, vi mostreremo almeno i 20 oggetti pi\u00f9 recenti.", "messages", "it");
t.add("dashboard.updated_affairs.tooltip", "Aggiornamenti delle ultime 4 settimane, per oggetti che seguite o che sono stati aggiunti a un gruppo che seguite", "messages", "it");
t.add("dashboard.assigned_affairs.tooltip", "Tutti i suoi oggetti assegnati", "messages", "it");
t.add("dashboard.team_actions.tooltip", "Oggetti interni creati all'interno dell'azienda e oggetti aggiunti a un gruppo dell'azienda.", "messages", "it");
t.add("national.affairs.list.header.lastEventDate", "Data di termine", "messages", "it");
t.add("dashboard.custom.tooltip", "Quicklink definito dall'utente", "messages", "it");
t.add("dashboard.quicklinks.failed_to_save", "Non \u00e8 stato possibile salvare il Quicklink. Si prega di riprovare.", "messages", "it");
t.add("dashboard.quicklinks.failed_to_fetch", "Non \u00e8 stato possibile attivare il Quicklink. Si prega di riprovare.", "messages", "it");
t.add("dashboard.edit_customize", "Modificare", "messages", "it");
t.add("dashboard.restore_default", "Ripristinare", "messages", "it");
t.add("dashboard.restore.default", "Volete ripristinare le impostazioni predefinite per il Quicklink? Questa operazione non pu\u00f2 essere annullata.", "messages", "it");
t.add("dashboard.edit.shortcut", "Quicklink definito dall'utente", "messages", "it");
t.add("dashboard.custom.quicklink.name", "Nome Quicklink", "messages", "it");
t.add("dashboard.custom.quicklink.search.template", "Selezionare un modello di ricerca", "messages", "it");
t.add("dashboard.custom.quicklink.icon", "Icona", "messages", "it");
t.add("dashboard.custom.quicklink.show_updates", "Mostrare solo aggiornamenti", "messages", "it");
t.add("dashboard.custom.quicklink.show_all", "Mostrare tutti gli oggetti", "messages", "it");
t.add("national.swiss.affairs.consultations.caption", "Svizzera", "messages", "it");
t.add("share.filters", "Filtro", "messages", "it");
t.add("dashboard.custom.quicklink.no.search.template", "Nessun modello di ricerca disponibile", "messages", "it");
t.add("cantonal.affairs.status.addedToCommitteeProgram", "Inserito nel programma della commissione", "messages", "it");
t.add("cantonal.affairs.status.initiativeToTheFederalAssembly", "Iniziativa all'Assemblea federale", "messages", "it");
t.add("cantonal.affairs.status.annualReport", "Rapporto annuale", "messages", "it");
t.add("affair.event.pressRelease.link.tooltip", "Aprire la fonte ufficiale", "messages", "it");
t.add("pages.access_restricted.no_permission", "Nessun accesso al contenuto di questa pagina", "messages", "it");
t.add("pages.access_restricted.contact_admin", "Per l'assistenza, contattare l'amministrazione della propria azienda.", "messages", "it");
t.add("pages.access_restricted.interested_in_access", "Se siete interessati a una licenza per politica.ch, vi presentiamo volentieri il nostro prodotto e vi invitiamo a testarlo.", "messages", "it");
t.add("pages.access_restricted.contact_us", "Vi preghiamo di contattarci per email all'indirizzo info@politica.ch o telefonicamente allo (+41) 044 512 12 60.", "messages", "it");
t.add("cantonal.affairs.status.swearingIn", "Giuramento", "messages", "it");
t.add("shared.filters.show.linked_affairs", "Mostra tutti i collegamenti tra gli oggetti", "messages", "it");
t.add("reports.sections.report_name.error.name_exists", "Esiste gi\u00e0 un rapporto con questo nome. Si prega di scegliere un altro nome.", "messages", "it");
t.add("dashboard.table.type.insert", "Aggiungere", "messages", "it");
t.add("dashboard.table.type.update", "Aggiornamento", "messages", "it");
t.add("dashboard.table.type.remove", "Rimuovere", "messages", "it");
t.add("layout.search.type.allAffairs", "Tutti gli oggetti", "messages", "it");
t.add("layout.search.config.delete.exists_in_dashboard.message", "Questo modello di ricerca viene utilizzato nei collegamenti rapidi della dashboard. Siete sicuri di volerlo eliminare? Per il collegamento rapido della dashboard verr\u00e0 ripristinato il valore predefinito.", "messages", "it");
t.add("shared.affair.commissions", "Depositato da", "messages", "it");
t.add("shared.affair.departments", "Dipartimento responsabile", "messages", "it");
t.add("shared.affair.agenda", "In agenda", "messages", "it");
t.add("shared.affair.description", "Testo depositato", "messages", "it");
t.add("visualizer_intro.label", "Testo di apertura per il rapporto grafico", "messages", "it");
t.add("visualizer_intro_title.label", "Titolo per il testo introduttivo del rapporto grafico", "messages", "it");
t.add("reports.sections.sessions.title", "A quale sessione si riferisce il rapporto?", "messages", "it");
t.add("customField.setings.affairType.label", "Utilizzato nella tipologia di oggetto", "messages", "it");
t.add("internalFields.TextField.label", "Campo testo", "messages", "it");
t.add("internalFields.OptionsField.label", "Campo opzioni", "messages", "it");
t.add("internalFields.AttachmentField.label", "Files allegati", "messages", "it");
t.add("layout.affair.stat.label", "Stato dell'oggetto", "messages", "it");
t.add("layout.affair.latestEvent.name", "Titolo dell'ultimo evento", "messages", "it");
t.add("reports.sessionProgram.council.notDefined", "Consiglio di prima istanza non specificato", "messages", "it");
t.add("api.profile.settings.company.internal_fields.html.delete.success", "Il campo \u00e8 stato eliminato", "messages", "it");
t.add("api.profile.settings.company.internal_fields.html.delete.error", "Si \u00e8 verificato un errore durante l'eliminazione del campo. Si prega di riprovare.", "messages", "it");
t.add("panel.additionalInfoLink.label", "Dettagli", "messages", "it");
t.add("outputInternalAffairAuthors", "Autore interno (creatore)", "messages", "it");
t.add("outputAuthors", "Autore", "messages", "it");
t.add("reports.sections.show_agenda.toggle.title", "Mostrare anteprima della sessione", "messages", "it");
t.add("shared.agenda.session_program.title", "Oggetti all'ordine del giorno e trattati durante la sessione:", "messages", "it");
t.add("shared.agenda.deposited.title", "Nuovi oggetti depositati durante la sessione:", "messages", "it");
t.add("shared.agenda.confirm_dialog.title", "L'oggetto non pu\u00f2 essere visualizzato", "messages", "it");
t.add("shared.agenda.confirm_dialog.description", "Questo oggetto \u00e8 stato trattato durante la sessione ed \u00e8 stato assegnato a questo rapporto dall'autore di quest'ultimo. Tuttavia, mancano i dati necessari per visualizzarlo all'interno del grafico, che possono essere modificati esclusivamente dall'autore dell'oggetto stesso.", "messages", "it");
t.add("shared.agenda.confirm_dialog.button.text", "Ho capito!", "messages", "it");
t.add("shared.agenda.deposited.no_data_available.text", "Nessuno degli oggetti qui mostrati \u00e8 stato presentato durante la sessione selezionata.", "messages", "it");
t.add("shared.agenda.session_program.no_data_available.text", "Nessuno degli oggetti mostrati \u00e8 stato trattato durante la sessione.", "messages", "it");
t.add("reports.groups.outputInternalAffairAuthors.label", "Autori\/Creatori di oggetti interni", "messages", "it");
t.add("affair.SRDebateEvent.tooltip.caption", "Dibattito Consiglio degli Stati", "messages", "it");
t.add("cantonal.affairs.status.replyDemanded", "Risposta richiesta", "messages", "it");
t.add("affair.SRCouncilVote.tooltip.caption", "Votazione Consiglio degli Stati", "messages", "it");
t.add("affair.NationalDebateEvent.tooltip.caption", "Dibattito Consiglio Nazionale", "messages", "it");
t.add("affair.NRCouncilVote.tooltip.caption", "Votazione Consiglio nazionale", "messages", "it");
t.add("custom.affairs.form.link", "Link alla fonte", "messages", "it");
t.add("affair.NationalAffairConsultationNoteCustomEvent.tooltip.caption", "Nota interna", "messages", "it");
t.add("affair.CantonalAffairConsultationNoteCustomEvent.tooltip.caption", "Nota interna", "messages", "it");
t.add("cantonal.affairs.status.postponed", "Rimandato", "messages", "it");
t.add("api.links.post.success", "Oggetto collegato con successo", "messages", "it");
t.add("cantonal.affairs.type.regbes.title", "Decisione del Consiglio di Stato", "messages", "it");
t.add("cantonal.media_releases.title", "Comunicati stampa", "messages", "it");
t.add("cantonal.media_releases.table.header.title", "Titolo", "messages", "it");
t.add("cantonal.media_releases.table.header.date", "Pubblicato il", "messages", "it");
t.add("cantonal.media_releases.table.header.link", "Link al sito del Cantone", "messages", "it");
t.add("cantonal.overview.mediaRelease", "Comunicati stampa", "messages", "it");
t.add("api.cantonal_media_release.get.error", "Comunicato stampa non pu\u00f2 essere caricato", "messages", "it");
t.add("notification.tableOfContents.cantonalMediaRelease.new", "Nuovo comunicato stampa pubblicato", "messages", "it");
t.add("notification.tableOfContents.cantonalMediaRelease.updated", "Il comunicato stampa \u00e8 stato aggiornato", "messages", "it");
t.add("cantonal.mediaRelease.itemAdded", "Comunicato stampa pubblicato", "messages", "it");
t.add("cantonal.affairs.type.regbes.abbr", "Decisione del Consiglio di Stato", "messages", "it");
t.add("national.affairs.author.published.by", "Pubblicato da", "messages", "it");
t.add("helpScout.suggestedForYou", "Risposte immediate", "messages", "it");
t.add("helpScout.getInTouch", "Entra in contatto", "messages", "it");
t.add("helpScout.searchLabel", "In cosa possiamo aiutarti?", "messages", "it");
t.add("helpScout.tryAgain", "Riprova", "messages", "it");
t.add("helpScout.defaultMessageErrorText", "Si \u00e8 verificato un problema nell'invio del messaggio. Riprova tra un attimo.", "messages", "it");
t.add("helpScout.beaconButtonClose", "Chiudi", "messages", "it");
t.add("helpScout.beaconButtonChatMinimize", "Minimizza la chat", "messages", "it");
t.add("helpScout.beaconButtonChatOpen", "Apri la chat", "messages", "it");
t.add("helpScout.answer", "Risposte", "messages", "it");
t.add("helpScout.ask", "Chiudi", "messages", "it");
t.add("helpScout.messageButtonLabel", "Email", "messages", "it");
t.add("helpScout.noTimeToWaitAround", "Non hai tempo di aspettare? Di solito rispondiamo entro poche ore", "messages", "it");
t.add("helpScout.chatButtonLabel", "Chat", "messages", "it");
t.add("helpScout.chatButtonDescription", "Siamo online in questo momento, parla con il nostro team in tempo reale", "messages", "it");
t.add("helpScout.wereHereToHelp", "Inizia una conversazione", "messages", "it");
t.add("helpScout.whatMethodWorks", "Quale canale preferisci?", "messages", "it");
t.add("helpScout.previousMessages", "Conversazioni precedenti", "messages", "it");
t.add("helpScout.cantFindAnswer", "Non riesci a trovare una risposta?", "messages", "it");
t.add("helpScout.relatedArticles", "Articoli correlati", "messages", "it");
t.add("helpScout.nothingFound", "Hmm...", "messages", "it");
t.add("helpScout.docsSearchEmptyText", "Non abbiamo trovato articoli che corrispondano alla tua ricerca.", "messages", "it");
t.add("helpScout.tryBroaderTerm", "Prova a cercare un termine pi\u00f9 ampio, oppure", "messages", "it");
t.add("helpScout.docsArticleErrorText", "Si \u00e8 verificato un problema nel recupero di questo articolo. Controlla la tua connessione a Internet e riprova.", "messages", "it");
t.add("helpScout.docsSearchErrorText", "Si \u00e8 verificato un problema nel recupero degli articoli. Controlla la tua connessione a Internet e riprova.", "messages", "it");
t.add("helpScout.escalationQuestionFeedback", "Questo articolo ha risposto alla tua domanda?", "messages", "it");
t.add("helpScout.escalationQuestionFeedbackNo", "No", "messages", "it");
t.add("helpScout.escalationQuestionFeedbackYes", "S\u00ec", "messages", "it");
t.add("helpScout.escalationSearchText", "Cerca una risposta alla tua domanda nei documenti della guida.", "messages", "it");
t.add("helpScout.escalationSearchTitle", "Continua a cercare", "messages", "it");
t.add("helpScout.escalationTalkText", "Parla con un membro del nostro team di supporto", "messages", "it");
t.add("helpScout.escalationTalkTitle", "Parla con noi", "messages", "it");
t.add("helpScout.escalationThanksFeedback", "Grazie per il feedback", "messages", "it");
t.add("helpScout.escalationWhatNext", "Cosa fare dopo?", "messages", "it");
t.add("helpScout.sendAMessage", "Invia un messaggio", "messages", "it");
t.add("helpScout.firstAFewQuestions", "Iniziamo con alcune domande", "messages", "it");
t.add("helpScout.howCanWeHelp", "Come possiamo aiutarti?", "messages", "it");
t.add("helpScout.responseTime", "Di solito rispondiamo in poche ore", "messages", "it");
t.add("helpScout.history", "Storia", "messages", "it");
t.add("helpScout.uploadAnImage", "Carica un'immagine", "messages", "it");
t.add("helpScout.attachAFile", "Allega un file", "messages", "it");
t.add("helpScout.continueEditing", "Continua a scrivere...", "messages", "it");
t.add("helpScout.lastUpdated", "Ultimo aggiornamento", "messages", "it");
t.add("helpScout.you", "Tu", "messages", "it");
t.add("helpScout.nameLabel", "Nome", "messages", "it");
t.add("helpScout.subjectLabel", "Oggetto", "messages", "it");
t.add("helpScout.emailLabel", "Indirizzo e-mail", "messages", "it");
t.add("helpScout.messageLabel", "Come possiamo aiutarti?", "messages", "it");
t.add("helpScout.messageSubmitLabel", "Invia un messaggio", "messages", "it");
t.add("helpScout.next", "Avanti", "messages", "it");
t.add("helpScout.weAreOnIt", "Ci stiamo lavorando!", "messages", "it");
t.add("helpScout.messageConfirmationText", "Riceverai una risposta via e-mail entro poche ore.", "messages", "it");
t.add("helpScout.viewAndUpdateMessage", "\u00c8 possibile visualizzare e aggiornare il messaggio in", "messages", "it");
t.add("helpScout.mayNotBeEmpty", "Potrebbe non essere vuoto", "messages", "it");
t.add("helpScout.customFieldsValidationLabel", "Compila tutti i campi", "messages", "it");
t.add("helpScout.emailValidationLabel", "Si prega di utilizzare un indirizzo e-mail valido", "messages", "it");
t.add("helpScout.attachmentErrorText", "Si \u00e8 verificato un problema nel caricamento dell'allegato. Riprova tra poco.", "messages", "it");
t.add("helpScout.attachmentSizeErrorText", "Gli allegati non possono essere pi\u00f9 grandi di 10MB", "messages", "it");
t.add("helpScout.addReply", "Aggiungi una risposta", "messages", "it");
t.add("helpScout.addYourMessageHere", "Aggiungi il tuo messaggio qui...", "messages", "it");
t.add("helpScout.sendMessage", "Invia il messaggio", "messages", "it");
t.add("helpScout.received", "Ricevuto", "messages", "it");
t.add("helpScout.waitingForAnAnswer", "In attesa di una risposta", "messages", "it");
t.add("helpScout.previousMessageErrorText", "Si \u00e8 verificato un problema nel recupero di questo messaggio. Controlla la tua connessione a Internet e riprova.", "messages", "it");
t.add("helpScout.justNow", "Proprio ora", "messages", "it");
t.add("helpScout.chatHeadingTitle", "Chatta con il nostro team", "messages", "it");
t.add("helpScout.chatHeadingSublabel", "Saremo presto da te", "messages", "it");
t.add("helpScout.chatEndCalloutHeading", "Tutto fatto!", "messages", "it");
t.add("helpScout.chatEndCalloutMessage", "Una copia di questa conversazione arriver\u00e0 a breve nella tua casella di posta elettronica.", "messages", "it");
t.add("helpScout.chatEndCalloutLink", "Torna alla home", "messages", "it");
t.add("helpScout.chatEndUnassignedCalloutHeading", "Siamo spiacenti", "messages", "it");
t.add("helpScout.chatEndUnassignedCalloutMessage", "Sembra che nessuno sia riuscito a partecipare alla tua chat. Ti invieremo una risposta via e-mail il prima possibile.", "messages", "it");
t.add("helpScout.chatEndWaitingCustomerHeading", "Siamo spiacenti", "messages", "it");
t.add("helpScout.chatEndWaitingCustomerMessage", "La tua domanda \u00e8 stata aggiunta alla nostra coda di e-mail e ti risponderemo al pi\u00f9 presto.", "messages", "it");
t.add("helpScout.ending", "Fine...", "messages", "it");
t.add("helpScout.endChat", "Termina la chat", "messages", "it");
t.add("helpScout.chatEnded", " ha concluso la conversazione", "messages", "it");
t.add("helpScout.chatConnected", "Collegato a", "messages", "it");
t.add("helpScout.chatbotName", "Bot di aiuto", "messages", "it");
t.add("helpScout.chatbotGreet", "Salve! Puoi iniziare a porre la tua domanda qui sotto. Qualcuno sar\u00e0 da te a breve.", "messages", "it");
t.add("helpScout.chatbotPromptEmail", "Ho capito. In breve, qual \u00e8 il tuo indirizzo e-mail? Lo useremo per eventuali messaggi di seguito.", "messages", "it");
t.add("helpScout.chatbotConfirmationMessage", "Grazie! Qualcuno del nostro team entrer\u00e0 presto in chat.", "messages", "it");
t.add("helpScout.chatbotGenericErrorMessage", "L'invio del messaggio non \u00e8 andato a buon fine, riprova tra qualche minuto.", "messages", "it");
t.add("helpScout.chatbotInactivityPrompt", "Poich\u00e9 la chat \u00e8 inattiva, la chiuder\u00f2 tra qualche minuto.", "messages", "it");
t.add("helpScout.chatbotInvalidEmailMessage", "Sembra che tu abbia inserito un indirizzo e-mail non valido. Vuoi riprovare?", "messages", "it");
t.add("helpScout.chatbotAgentDisconnectedMessage", " si \u00e8 disconnesso dalla chat. \u00c8 possibile che abbia perso la connessione a Internet, quindi sto cercando qualcun altro per sostituirlo. Ci scusiamo per il ritardo!", "messages", "it");
t.add("helpScout.chatAvailabilityChangeMessage", "La disponibilit\u00e0 del nostro team \u00e8 cambiata e non c'\u00e8 pi\u00f9 nessuno disponibile per la chat. Inviaci un messaggio e ti risponderemo.", "messages", "it");
t.add("helpScout.emailHeading", "La chat di oggi \u00e8 con", "messages", "it");
t.add("helpScout.emailGreeting", "Ciao", "messages", "it");
t.add("helpScout.emailCopyOfDiscussion", "Ecco una copia della conversazione", "messages", "it");
t.add("helpScout.emailContinueConversation", "Se hai altre domande, rispondi pure e continua la conversazione.", "messages", "it");
t.add("helpScout.emailJoinedLineItem", " si \u00e8 unito alla chat", "messages", "it");
t.add("helpScout.emailEndedLineItem", " ha concluso la conversazione", "messages", "it");
t.add("helpScout.emailYou", "Tu", "messages", "it");
t.add("affairs.followingHaveBeenUpdated", "I seguenti oggetti sono stati aggiornati", "email", "it");
t.add("national.affairs.followingHaveBeenUpdated", "I seguenti oggetti nazionali sono stati aggiornati", "email", "it");
t.add("newAffairs.subject", "politik.ch - Aggiornamenti oggetti cantonali", "email", "it");
t.add("notification.openingGreeting", "Buongiorno", "email", "it");
t.add("votes.followingHaveBeenAdded", "Le seguenti votazioni sono state aggiunte a politik.ch", "email", "it");
t.add("votes.followingHaveBeenUpdated", "Le seguenti votazioni sono state aggiornate su politik.ch", "email", "it");
t.add("votes.votedOn", "votato il", "email", "it");
t.add("readMoreOn", "Per saperne di pi\u00f9: continuare a leggere", "email", "it");
t.add("affairs.followingHaveBeenAdded", "I seguenti oggetti sono stati aggiunti a politik.ch", "email", "it");
t.add("affairs.cantonal.itemAdded", "Cantone %abbr%: %title% (%type%)", "email", "it");
t.add("national.affairs.followingHaveBeenAdded", "I seguenti oggetti sono stati aggiunti a politik.ch", "email", "it");
t.add("national.affairs.itemAdded", "%idParlService%: %title% (%type%)", "email", "it");
t.add("affairGroups.followingHaveBeenAdded", "I seguenti gruppi sono stati aggiunti a politik.ch", "email", "it");
t.add("affairGroups.followingHaveBeenUpdated", "I seguenti gruppi sono stati aggiornati", "email", "it");
t.add("affairGroups.itemAdded", "%title%", "email", "it");
t.add("national.affairs.new.subject", "politik.ch - Aggiornamenti oggetti nazionali", "email", "it");
t.add("national.affairConsultations.followingHaveBeenAdded", "Le seguenti nuove procedure di consultazione sono state aggiunte", "email", "it");
t.add("national.affairConsultations.followingHaveBeenUpdated", "Le seguenti procedure di consultazione sono state aggiornate", "email", "it");
t.add("national.affairConsultations.itemAdded", "%title%", "email", "it");
t.add("national.affairConsultations.new.subject", "politik.ch - Nuove procedure di consultazione", "email", "it");
t.add("national.votes.nationalcouncil.new.subject", "politik.ch - Nuove votazioni Consiglio nazionale", "email", "it");
t.add("national.votes.councilstates.new.subject", "politik.ch - Nuove votazioni Consiglio degli Stati", "email", "it");
t.add("eventReminders.heading", "Vorremmo ricordarvi i seguenti eventi", "email", "it");
t.add("eventReminders.new.subject", "politik.ch - Nuovi promemoria", "email", "it");
t.add("eventReminders.link.label", "Promemoria per %eventTitle% il %dateTime%", "email", "it");
t.add("notification.operation.add", "aggiunto", "email", "it");
t.add("notification.operation.replace", "sostituito", "email", "it");
t.add("notification.operation.remove", "eliminato", "email", "it");
t.add("notification.author_full_name", "Autore", "email", "it");
t.add("notification.title_de", "Titolo DE", "email", "it");
t.add("notification.title_fr", "Titolo FRA", "email", "it");
t.add("notification.title_it", "Titolo ITA", "email", "it");
t.add("notification.description_de", "Descrizione DE", "email", "it");
t.add("notification.description_fr", "Descrizione FR", "email", "it");
t.add("notification.description_it", "Descrizione IT", "email", "it");
t.add("notification.reason_de", "Motivazione DE", "email", "it");
t.add("notification.reason_fr", "Motivazione FR", "email", "it");
t.add("notification.reason_it", "Motivazione IT", "email", "it");
t.add("notification.short_id_parl_service", "ID Servizi parlamentari", "email", "it");
t.add("notification.affair_type", "Tipo", "email", "it");
t.add("notification.priority_council", "Camera", "email", "it");
t.add("notification.affair_state", "Stato", "email", "it");
t.add("notification.national_council_votes", "Votazione Consiglio nazionale", "email", "it");
t.add("notification.council_states_votes", "Votazione Consiglio degli Stati", "email", "it");
t.add("notification.history_events", "Evento", "email", "it");
t.add("notification.history_events.status", "Stato evento", "email", "it");
t.add("notification.history_events.council", "Evento: Consiglio", "email", "it");
t.add("notification.history_events.status_text", "Testo evento", "email", "it");
t.add("notification.history_events.date_time", "Data\/periodo evento", "email", "it");
t.add("notification.history_events.national_session", "Evento: Sessione nazionale", "email", "it");
t.add("notification.history_events.national_committee", "Evento: Commissione nazionale", "email", "it");
t.add("notification.drafts", "Disegno", "email", "it");
t.add("notification.drafts.texts", "Bozza testo", "email", "it");
t.add("notification.drafts.texts.value_de", "Testo bozza DE", "email", "it");
t.add("notification.drafts.texts.value_fr", "Bozza testo FR", "email", "it");
t.add("notification.drafts.texts.value_it", "Bozza testo IT", "email", "it");
t.add("notification.drafts.related_departments", "Disegno: Dipartimento responsabile", "email", "it");
t.add("notification.drafts.resolutions", "Decisione", "email", "it");
t.add("notification.drafts.resolutions.date", "Data decisione", "email", "it");
t.add("notification.drafts.resolutions.resolution_text", "Testo decisione", "email", "it");
t.add("notification.drafts.resolutions.resolution_text.text_de", "Testo decisione DE", "email", "it");
t.add("notification.drafts.resolutions.resolution_text.text_fr", "Testo decisione FR", "email", "it");
t.add("notification.drafts.resolutions.resolution_text.text_it", "Testo decisione ITA", "email", "it");
t.add("notification.drafts.pre_consultations", "Disegno consultazione preliminare", "email", "it");
t.add("notification.drafts.references", "Disegno", "email", "it");
t.add("notification.drafts.references.title_de", "Bozza referenza titolo DE", "email", "it");
t.add("notification.drafts.references.title_fr", "Bozza referenza titolo DE", "email", "it");
t.add("notification.drafts.references.title_it", "Bozza referenza titolo IT", "email", "it");
t.add("notification.drafts.federal_council_proposal", "Disegno Consiglio federale", "email", "it");
t.add("notification.drafts.federal_council_proposal.date", "Data disegno Consiglio federale", "email", "it");
t.add("notification.drafts.federal_council_proposal.summary_de", "Riassunto Progetto Consiglio federale DE", "email", "it");
t.add("notification.drafts.federal_council_proposal.summary_fr", "Riassunto disegno Consiglio federale FR", "email", "it");
t.add("notification.drafts.federal_council_proposal.summary_it", "Riassunto disegno Consiglio federale IT", "email", "it");
t.add("notification.drafts.federal_council_proposal.text_de", "Disegno Consiglio federale DE", "email", "it");
t.add("notification.drafts.federal_council_proposal.text_fr", "Disegno Consiglio federale FR", "email", "it");
t.add("notification.drafts.federal_council_proposal.text_it", "Disegno Consiglio federale IT", "email", "it");
t.add("notification.title", "Titolo", "email", "it");
t.add("notification.text", "Testo", "email", "it");
t.add("notification.code", "Codice", "email", "it");
t.add("notification.internal_id", "ID interno", "email", "it");
t.add("notification.status", "Stato procedura di consultazione", "email", "it");
t.add("notification.main_attachment", "Allegato", "email", "it");
t.add("notification.attachments", "Allegato ulteriore", "email", "it");
t.add("notification.attachments.real_hash", "Contenuto allegato", "email", "it");
t.add("notification.authors", "Autore", "email", "it");
t.add("notification.history_events.cantonal_session_program", "Programma della sessione cantonale", "email", "it");
t.add("notification.history_events.attachments", "Allegato evento", "email", "it");
t.add("notification.history_events.attachments.real_hash", "Documento \/ File", "email", "it");
t.add("notification.affair_groups", "Assegnazione gruppo", "email", "it");
t.add("notification.affair_groups.parentId", "Assegnazione gruppo superiore", "email", "it");
t.add("notification.children", "Assegnazione gruppo", "email", "it");
t.add("notification.documents", "Documento procedura di consultazione", "email", "it");
t.add("notification.opinions", "Risposte procedura di consultazione", "email", "it");
t.add("notification.description", "Descrizione procedura di consultazione", "email", "it");
t.add("notification.office", "Ufficio consultazione", "email", "it");
t.add("notification.author", "Autore procedura di consultazione", "email", "it");
t.add("notification.author.full_name", "Autore procedura di consultazione", "email", "it");
t.add("notification.author.type", "Autore Tipo Procedura di consultazione", "email", "it");
t.add("notification.laws", "Procedura di consultazione legge", "email", "it");
t.add("notification.laws.law_id", "Procedura di consultazione legge", "email", "it");
t.add("notification.remarks", "Osservazione procedura di consultazione", "email", "it");
t.add("notification.affair_groups.parent", "Assegnazione gruppo superiore", "email", "it");
t.add("2fa.authCode.subject", "Il suo codice di autenticazione per politik.ch", "email", "it");
t.add("notification.custom_links", "Oggetto collegato", "email", "it");
t.add("notification.tableOfContents.cantonalAffairConsultations.new", "Nuova procedura di consultazione cantonale", "email", "it");
t.add("notification.tableOfContents.cantonalAffairConsultations.updated", "Procedura di consultazione cantonale aggiornata", "email", "it");
t.add("affairConsultations.cantonal.itemAdded", "Cantone %abbr%: %title%", "email", "it");
t.add("notification.links", "notification.links", "email", "it");
t.add("notification.tableOfContents.cantonalAffairs.new", "Nuovi oggetti cantonali", "email", "it");
t.add("notification.tableOfContents.cantonalAffairs.updated", "Oggetti cantonali aggiornati", "email", "it");
t.add("notification.tableOfContents.nationalAffairs.new", "Nuovi oggetti nazionali", "email", "it");
t.add("notification.tableOfContents.nationalAffairs.updated", "Oggetti nazionali aggiornati", "email", "it");
t.add("notification.tableOfContents.nationalAffairConsultations.new", "Nuove procedure di consultazione nazionali", "email", "it");
t.add("notification.tableOfContents.nationalAffairConsultations.updated", "Procedure di consultazione nazionali aggiornate", "email", "it");
t.add("notification.tableOfContents.affairGroups.new", "Nuovi gruppi", "email", "it");
t.add("notification.tableOfContents.affairGroups.updated", "Gruppi aggiornati", "email", "it");
t.add("notification.followingThemes", "Siamo lieti di informarla riguardo i seguenti temi", "email", "it");
t.add("national.affairs.itemAddedLink", "%idParlService%", "email", "it");
t.add("national.affairs.itemAddedTitle", ": %title% (%type%)", "email", "it");
t.add("resetting.email.subject", "Reimpostare la password", "email", "it");
t.add("resetting.email.message", "Salve %username%!\n\nVisiti la seguente pagina per resettare la sua password: %confirmationUrl%\n\nCordialmente\npolitik.ch", "email", "it");
t.add("affairs.custom.itemAdded", "%title%", "email", "it");
t.add("notification.tableOfContents.eventReminders.new", "Promemoria", "email", "it");
t.add("notification.tableOfContents.nationalVotes.new", "Parlamento nazionale: Votazioni", "email", "it");
t.add("notification.national_council_debates", "Dibattito Consiglio nazionale", "email", "it");
t.add("notification.council_states_debates", "Dibattito Consiglio degli Stati", "email", "it");
t.add("notification.assignedAffairs.itemAdded", "L'utente %assignedUser% \u00e8 stato aggiunto all'oggetto %affair%.", "email", "it");
t.add("notification.tableOfContents.assignedAffairs.new", "Nuovi oggetti assegnati", "email", "it");
t.add("notification.tableOfContents.assignedAffairs.updated", "Oggetti assegnati aggiornati", "email", "it");
t.add("national.committeePressReleases.itemAdded", "%title%", "email", "it");
t.add("notification.tableOfContents.nationalCommitteePressReleases.new", "Nuovi comunicati stampa Commissioni", "email", "it");
t.add("notification.tableOfContents.nationalCommitteePressReleases.updated", "Comunicati stampa aggiornati Commissioni", "email", "it");
t.add("notification.tableOfContents.nationalAffairsDepositTexts.new", "Oggetti nazionali con un nuovo testo depositato", "email", "it");
t.add("notification.tableOfContents.cantonalMediaRelease.new", "Nuovo comunicato stampa pubblicato", "email", "it");
t.add("notification.tableOfContents.cantonalMediaRelease.updated", "Il comunicato stampa \u00e8 stato aggiornato", "email", "it");
t.add("groups.form.title.cannotBeEmpty", "Il titolo non pu\u00f2 essere vuoto", "javascript", "it");
t.add("affair.internalNote.error", "Errore descrizione", "javascript", "it");
t.add("modal.error.title", "Oh! Questo \u00e8 un errore!", "javascript", "it");
t.add("modal.error.message", "Si \u00e8 appena verificato un errore, ci scusiamo. I nostri sviluppatori sono gi\u00e0 stati avvisati. Controlli nuovamente questa funzione pi\u00f9 tardi o contatti direttamente il nostro team di supporto: info@politik.ch oppure 044 512 12 60.", "javascript", "it");
t.add("affairs.error.message", "Errore durante il caricamento degli oggetti", "javascript", "it");
t.add("groups.error.message", "Errore durante il caricamento dei gruppi", "javascript", "it");
t.add("groups.delete.message", "Siete sicuri di voler eliminare il gruppo?", "javascript", "it");
t.add("groups.delete.confirm", "Eliminare", "javascript", "it");
t.add("groups.delete.cancel", "Annullare", "javascript", "it");
t.add("ranking.filter.groups.tooltip", "Vengono visualizzati solo i gruppi che contengono almeno un oggetto che corrisponde agli altri criteri del filtro selezionato (ad esempio tipo di oggetto).", "javascript", "it");
t.add("affair.internalNote.saved", "Salvato", "javascript", "it");
t.add("datatables.language.emptyTable", "Nessun dato disponibile nella tabella", "javascript", "it");
t.add("datatables.language.info", "_START_ fino a _END_ su _TOTAL_ voci", "javascript", "it");
t.add("datatables.language.infoEmpty", "Nessun dato disponibile", "javascript", "it");
t.add("datatables.language.infoFiltered", "(filtrato secondo _MAX_ voci)", "javascript", "it");
t.add("datatables.language.lengthMenu", "_MENU_ mostrare voci", "javascript", "it");
t.add("datatables.language.loadingRecords", "sta caricando...", "javascript", "it");
t.add("datatables.language.processing", "Attendere prego...", "javascript", "it");
t.add("datatables.language.search", "Cercare", "javascript", "it");
t.add("datatables.language.zeroRecords", "Nessuna voce presente", "javascript", "it");
t.add("datatables.language.paginate.first", "Primo", "javascript", "it");
t.add("datatables.language.paginate.last", "Ultimi", "javascript", "it");
t.add("datatables.language.paginate.next", "Prossime", "javascript", "it");
t.add("datatables.language.paginate.previous", "Indietro", "javascript", "it");
t.add("datatables.language.aria.", ": attivare per ordinare la colonna in ordine decrescente", "javascript", "it");
t.add("charts.men", "Uomo", "javascript", "it");
t.add("charts.women", "Donna", "javascript", "it");
t.add("charts.gender.title", "Sesso", "javascript", "it");
t.add("layout.logout", "Disconnettersi", "user", "it");
t.add("profile.edit.submit", "Aggiornare utente", "user", "it");
t.add("security.login.email", "E-Mail", "user", "it");
t.add("security.login.password", "Password", "user", "it");
t.add("security.login.forgotpassword", "Password dimenticata?", "user", "it");
t.add("security.login.remember_me", "Ricordarmi", "user", "it");
t.add("resetting.reset.submit", "Cambiare password", "user", "it");
t.add("resetting.request.username", "Indirizzo E-Mail", "user", "it");
t.add("resetting.request.submit", "Reimpostare la password", "user", "it");
t.add("change_password.submit", "Cambiare password", "user", "it");
})(Translator);
